export const LinkingConstants = {
    GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT: 5,
    CHILD_LINK_TYPE: { value: "1", title: "Is child of" },
    PARENT_LINK_TYPE: { value: "2", title: "Is parent of" },
    LINKED_DOCUMENTS_HEADER_ID: "js-savedDocumentsTitle",
    CONNECTED_QUERIES_HEADER_ID: "js-connectedQueriesTitle",
    LINKED_ITEMS_HEADER_ID: "js-linkGraphTitle",
    PAGE_COMMENTS_HEADER_ID: "js-pageCommentsTitle",
    MATURITY_RADAR_HEADER_ID: "js-maturityRadarTitle",
    REQUIREMENTS_TABLE_HEADER_ID: "js-requirementsTableTitle",
} as const;
