import axios from "axios";
import { ObjectTypeEnum } from "Enums";
import { AxiosHelperSingleton } from "Helpers";
import { TAxiosParams, TDocumentReferencesDTO, TObjectReferencesDTO, TUpdateReferencedByDTO } from "Types";

export class ReferenceController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/reference`;

    public async getObjectDocumentReferencesAsync(objectId: string, objectType: ObjectTypeEnum): 
            Promise<TObjectReferencesDTO | undefined> {
        try {
            // Retrieve the document references for the object
            const response = await axios
                .get<TObjectReferencesDTO>(`${this._resourcePath}/${objectId}/${objectType}/documents`);
            // Return the document references if they were found
            if(response && response.data) {
                return response.data;
            }
            // Return undefined if document references were not found
            return undefined;
        } catch {
            return undefined;
        }
    }

    public async getDocumentReferencesAsync(isInbox: boolean, connectedToObjectId?: string, isCreatedByMe?: true,
            doOnlyGetConnectedToObjectId?: boolean): Promise<TDocumentReferencesDTO[]> {
        try {
            const url = isInbox ? `${this._resourcePath}/inbox` : `${this._resourcePath}/documents`;
            const axiosParams: TAxiosParams = {};

            // if connectedToObjectId is set
            if(connectedToObjectId) {
                // append the query string parameter
                axiosParams.connectedToObjectId = connectedToObjectId;
            }
            // if doOnlyGetConnectedToObjectId is set
            if (doOnlyGetConnectedToObjectId !== undefined) {
                // append the query string parameter
                axiosParams.doOnlyGetConnectedToObjectId = doOnlyGetConnectedToObjectId;
            }
            // if isCreatedByMe is true
            if(isCreatedByMe && !isInbox) {
                // append the query string parameter
                axiosParams.isCreatedByMe = isCreatedByMe;
            }

            // Retrieve the document references for the inbox
            const response = await axios.get<TDocumentReferencesDTO[]>(url, {
                params: axiosParams
            });
            // Return the document references if they were found
            if(response && response.data) {
                return response.data;
            }
            // Return an empty array if the document references were not found
            return [];
        } catch {
            return [];
        }
    }

    public async getHighlightDocumentReferencesAsync(connectedToObjectId?: string, isCreatedByMe?: true, 
            doOnlyGetConnectedToObjectId?: boolean): Promise<TDocumentReferencesDTO[]> {
        try {
            const url = `${this._resourcePath}/highlightdocuments`;
            const axiosParams: TAxiosParams = {};

            // if connectedToObjectId is set
            if(connectedToObjectId) {
                // append the query string parameter
                axiosParams.connectedToObjectId = connectedToObjectId;
            }
            // if doOnlyGetConnectedToObjectId is set
            if (doOnlyGetConnectedToObjectId !== undefined) {
                // append the query string parameter
                axiosParams.doOnlyGetConnectedToObjectId = doOnlyGetConnectedToObjectId;
            }
            // if isCreatedByMe is true
            if(isCreatedByMe) {
                // append the query string parameter
                axiosParams.isCreatedByMe = isCreatedByMe;
            }

            // Retrieve the document references for the inbox
            const response = await axios.get<TDocumentReferencesDTO[]>(url, {
                params: axiosParams
            });
            // Return the document references if they were found
            if(response && response.data) {
                return response.data;
            }
            // Return an empty array if the document references were not found
            return [];
        } catch {
            return [];
        }
    }

    public async updateReferencedByAsync(objectId: string, objectType: ObjectTypeEnum, referencedIds: string[]): Promise<boolean> {
        try {
            // safety-checks
            if(!objectId || !objectType || !referencedIds) {
                return false;
            }

            // create update referenced by dto
            const updateReferencedByDTO: TUpdateReferencedByDTO = {
                referenceeId: objectId,
                referenceeType: objectType,
                referencedIds: referencedIds
            };

            // update the referenced by
            const result = await axios
                .put<boolean>(`${this._resourcePath}/referencedby`, updateReferencedByDTO);
            // Return true if the referenced by were updated successfully
            if(result && result.data) {
                return true;
            } else {
                return false;
            }
        } catch {
            // Return false if the referenced by were not updated successfully
            return false;
        }
    }
}

export const ReferenceControllerSingleton = new ReferenceController();
