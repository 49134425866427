// node_modules
import { FC, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Components
import { MaturityLevel, ObjectChip } from "Components";
// Components
import { MaturityRadarControllerSingleton } from "Controllers";
// Helpers
import { ObjectTypeHelperSingleton, ToastHelperSingleton, UserHelperSingleton } from "Helpers";
// Types
import { TUpdateAssessmentScoreDTO } from "Types";
// Styles
import styles from "./maturityLevelsPopover.module.scss";
// Contexts
import { AuthContext } from "Providers";

type TMaturityLevelsPopoverItemProps = {
    min: number,
    max: number,
    assessmentId: string,
    sourceId: string,
    sourceTitle: string,
    sourceType: ObjectTypeEnum,
    onChange: (forSourceId: string, forSourceType: ObjectTypeEnum, newMin: number, newMax: number) => void
};

export const MaturityLevelsPopoverItem: FC<TMaturityLevelsPopoverItemProps> = ({ min, max, assessmentId, sourceId, sourceType, sourceTitle, onChange }: TMaturityLevelsPopoverItemProps) => {
    // Hooks
    const navigate = useNavigate();

    // Contexts
    const { auth, isUserExternal } = useContext(AuthContext);

    // Logic
    const onMaturityLevelChangeAsync = useCallback(async (newMin: number, newMax: number): Promise<void> => {
        // init update assessment 
        const updateAssessment: TUpdateAssessmentScoreDTO = {
            lowScore: newMin,
            highScore: newMax
        };

        // update assessment score
        const isSuccess: boolean = await MaturityRadarControllerSingleton
            .updateAssessmentScoreAsync(assessmentId, updateAssessment);

        // safety-checks
        if (!isSuccess) {
            // show toast error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update maturity level.");
            // stop execution, return
            return;
        }

        // call on change prop
        onChange(sourceId, sourceType, newMin, newMax);
    }, [assessmentId, onChange, sourceId, sourceType]);

    // Render
    return (
        <div className={styles.maturityLevelsPopoverItem}>
            <MaturityLevel
                min={min}
                max={max}
                isReadOnly={isUserExternal} 
                onChange={onMaturityLevelChangeAsync}
                extraClassNames={{ container: styles.maturityLevel }}
            />
            <ObjectChip
                isChipClickable={!UserHelperSingleton.isSharingRestrictedToObject(auth)}
                object={{
                    id: sourceId,
                    name: sourceTitle,
                    type: ObjectTypeHelperSingleton.getObjectTypeDisplayName(sourceType),
                    objectType: sourceType,
                }}
                onClick={UserHelperSingleton.isSharingRestrictedToObject(auth) ? undefined : () => { ObjectTypeHelperSingleton.navigateBasedOnObjectType(sourceType, sourceId, navigate); }} />
        </div>
    );
};