// React
import { FC, ReactNode, useContext, useMemo } from "react";
// Enums
import { PermissionsEnum } from "Enums";
// Providers
import { AuthContext } from "Providers";

type TPermissionsCheckerProps = {
    permissions: PermissionsEnum[],
    children: ReactNode,
};

export const PermissionsChecker: FC<TPermissionsCheckerProps> = ({ permissions = [], children }) => {
    // Contexts
    const { auth } = useContext(AuthContext);

    // Memo
    const permissionsSet = useMemo(() => {
        // convert the permissions to a set of strings
        return new Set(permissions.map(permission => permission.toString()));
    }, [permissions]);

    const isAuthorized = useMemo(() => {
        // if auth is not defined, then return false
        if(!auth) return false;

        // return true if any of the user permissions is in the defined permissions
        return auth.permissions.some(permission => permissionsSet.has(permission.toString()));
    }, [auth, permissionsSet]);

    // if user is not authorized, return null
    if (!isAuthorized) return null;

    // otherwise, return the children
    return (
        <>
            {children}
        </>
    );
};