// React
import { faTrashRestore } from "@fortawesome/free-solid-svg-icons";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
// Enums
import { ObjectTypeEnum, OrderByEnum, SortTypeEnum, ToastTypeEnum } from "Enums";
// Controllers
import { StudyControllerSingleton } from "Controllers";
// Types
import { TButtonDefinition, TStudiesDTO } from "Types";
// Interfaces
import { IStudyDTO } from "Interfaces";
// Styles
import entityLikeListStyles from "Styles/EntityLikeList/entityLikeList.module.scss";
import styles from "./deletedArchive.module.scss";
// Shared Components
import { ListHeader, StudyItem } from "Components";
// Custom hooks
import { useCheckboxedList, useObjectReferenceModal } from "Hooks";
// Constants
import { StudyConstants } from "Constants";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";

export const DeletedStudiesArchive: FC = () => {

    // State
    const [studies, setStudies] = useState<IStudyDTO[]>([]);
    const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
    const [totalStudiesCount, setTotalStudiesCount] = useState<number>(0);
    const [lastPaginationFromDates, setLastPaginationFromDates] = useState<Date[]>([]);

    // Hooks
    const { selectedItems: selectedStudies, setSelectedItems: setSelectedStudies,
        areAllItemsSelected: areAllStudiesSelected, isAnyItemSelected: isAnyStudySelected,
        onSelectAllItems, onSelectItem
    } = useCheckboxedList<IStudyDTO>(studies.length, "Study", "Studies", 
        study => ({ id: study.id, type: "Study", name: study.title, objectType: ObjectTypeEnum.Study }));
    const { referenceModal, closeReferenceModal, referenceModalProps, setReferenceModalProps } = useObjectReferenceModal({
        title: "Restore",
        icon: faTrashRestore,
        onClick: () => {  onRestoreButtonClick(referenceModalProps.id); }
    });

    const refreshStudiesAsync = async (fromDate: Date | undefined, currentSortType: SortTypeEnum): Promise<void> => {
        const orderByType = currentSortType === SortTypeEnum.Oldest ? OrderByEnum.Ascending : OrderByEnum.Descending;
        const newStudies: TStudiesDTO = await StudyControllerSingleton.getDeletedAsync(orderByType, fromDate);

        // set state variables
        setStudies(newStudies.studies);
        setTotalStudiesCount(newStudies.totalStudiesCount);
    };

    const onSelectAllCheckboxChange = (isChecked: boolean) => {
        onSelectAllItems(isChecked, studies);
    };

    const onStudyCheckboxChange = (isChecked: boolean, id: string): void => {
        const currentStudy = studies.find(study => study.id === id);
        if(!currentStudy) { return; }
        onSelectItem(isChecked, currentStudy, id);
    };

    const onPaginatePreviousAsync = async (): Promise<void> => {
        // get new from date
        let fromDate: Date | undefined = undefined;
        if (lastPaginationFromDates && lastPaginationFromDates.length > 0) { 
            const lastPaginationFromDate: Date | undefined = lastPaginationFromDates.pop();
            if (lastPaginationFromDates.length > 1) {
                if (lastPaginationFromDate) {
                    fromDate = lastPaginationFromDate;
                }
            }
            setLastPaginationFromDates([...lastPaginationFromDates]);
        }

        // update studies list
        await refreshStudiesAsync(
            fromDate,
            sortType
        );

        // reset selected studies
        setSelectedStudies([]);

        // log
        LogHelperSingleton.log("GoToPreviousDeletedStudiesArchivePage");
    };

    const onPaginateNextAsync = async (): Promise<void> => {
        // get new from date
        let fromDate: Date | undefined = undefined;
        if (studies && studies.length > 0) { 
            const lastPaginationFromDate: Date = studies[studies.length -1].dateAdded;
            fromDate = lastPaginationFromDate;
            lastPaginationFromDates.push(fromDate);
            setLastPaginationFromDates([...lastPaginationFromDates]);
        }

        // update studies list
        await refreshStudiesAsync(
            fromDate, sortType
        );

        // reset selected studies
        setSelectedStudies([]);

        // log
        LogHelperSingleton.log("GoToNextDeletedStudiesArchivePage");
    };

    const updateSortTypeAsync = async (newSortType: SortTypeEnum): Promise<void>  => {   
        // reset last pagination from dates
        setLastPaginationFromDates([]);

        // safety-checks
        if (newSortType === sortType) { return; }
        
        // set new sort type
        setSortType(newSortType);

        // update studies list
        await refreshStudiesAsync(
            undefined,
            newSortType,
        );

        // reset selected studies
        setSelectedStudies([]);

        // log
        LogHelperSingleton.log("SortDeletedStudiesArchive");
    };
    
    const onStudyClick = (entity: IStudyDTO): void => {
        setReferenceModalProps({
            isOpen: true,
            id: entity.id,
            type: ObjectTypeEnum.Study,
            doIgnoreIsDeleted: true
        });
    };

    const onRestoreButtonClick = useCallback(async (id: string | undefined): Promise<void> => {
        let selectedIds: string[];
        if (id) {
            selectedIds = [id];
        } else {
            // Get selected study ids
            selectedIds = selectedStudies.map(selectedStudy => selectedStudy.id);
        }

        // Check if any studies are selected
        if(selectedIds.length === 0) { 
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "No studies selected to restore");
            return;
        }

        // restore studies
        const isSuccess = await StudyControllerSingleton.undeleteAsync(selectedIds);

        // If not successful, show error toast
        if(!isSuccess) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to restore studies");
            return;
        }

        // Remove restored studies from list
        setStudies((prevStudies) => {
            return prevStudies.filter(prevStudy => !selectedIds.includes(prevStudy.id));
        });

        // Close referenceModal if study is restored from the modal
        if (id) {
            closeReferenceModal();
        }
    }, [closeReferenceModal, selectedStudies]);

    const studiesArchiveButtons = useMemo(() => {
        return [{
            title: "Restore",
            onClick: () => {onRestoreButtonClick(undefined);},
            icon: faTrashRestore,
            className: styles.restoreButton
        }] as TButtonDefinition[];
    }, [onRestoreButtonClick]);

    useEffect(() => {
        refreshStudiesAsync(undefined, SortTypeEnum.Newest);
    }, []);

    return (
        <div className={entityLikeListStyles.entityLikeContainer}>
            {/* <div className={[entityLikeListStyles.entityLikeItems, entityLikeListStyles.archiveList].join(" ")}> */}
            <div className={styles.listItemsContainer}>
                <ListHeader
                    isAllListItemsSelected={areAllStudiesSelected}
                    isAnyListItemSelected={isAnyStudySelected}
                    listItemCountInterval={StudyConstants.MAXIMUM_STUDIES_TO_RETRIEVE}
                    totalListItemCount={totalStudiesCount}
                    sortType={sortType}
                    updateSortType={updateSortTypeAsync}
                    onPaginateNext={onPaginateNextAsync}
                    onPaginatePrevious={onPaginatePreviousAsync}
                    onSelectAllCheckboxChange={onSelectAllCheckboxChange}
                    buttonDefinitions={studiesArchiveButtons}
                />
                <div className={entityLikeListStyles.entityLikeItemsList}>
                    {studies.map((study: IStudyDTO) => {
                        const isSelected = selectedStudies.find(selectedStudy => selectedStudy.id === study.id) !== undefined;
                        return (
                            <StudyItem 
                                key={`studyitem-${study.id}`} 
                                study={study} 
                                isSelected={isSelected}
                                onCheckboxChange={onStudyCheckboxChange}
                                onItemClickOverride={onStudyClick}
                                hideMoreActionsDropdownButton
                            />
                        );
                    })}
                </div>
            </div>
            {referenceModal}
        </div>
    );
};