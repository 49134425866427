// node_modules
import axios, { AxiosResponse } from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import {
  TChangePasswordDTO,
  TChangePasswordResultDTO,
  TLoginOptionsDTO,
  TLoginResponseDTO,
  TOAuthLoginResultDTO,
} from "Types";

export class AuthController {
  private resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/v2/authentication`;

  public async getLoginOptionsAsync(
    email: string
  ): Promise<TLoginOptionsDTO | undefined> {
    try {
      const response: AxiosResponse<TLoginOptionsDTO> =
        await axios.get<TLoginOptionsDTO>(
          `${this.resourcePath}/login-options`,
          {
            params: {
              email: email,
            },
          }
        );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async verifyAsync(): Promise<TLoginResponseDTO | undefined> {
    try {
      const response = await axios.get<TLoginResponseDTO>(
        `${this.resourcePath}/verify`
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async validateCallbackToken(code: string, state: string) {
    try {
      const response: AxiosResponse<TLoginOptionsDTO> =
        await axios.post<TLoginOptionsDTO>(
          `${this.resourcePath}/callback/verify`,
          {
            code,
            state,
          }
        );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async OAuthLogin(
    code: string,
    state: string,
    isRememberMeEnabled: boolean
  ): Promise<TOAuthLoginResultDTO | undefined> {
    try {
      const response: AxiosResponse<TOAuthLoginResultDTO> =
        await axios.post<TOAuthLoginResultDTO>(
          `${this.resourcePath}/sign-in-with-oauth`,
          {
            code,
            state,
            isRememberMeEnabled,
          }
        );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async loginAsync(
    email: string,
    password: string,
    isRememberMeEnabled: boolean
  ): Promise<TLoginResponseDTO | undefined> {
    try {
      const response = await axios.post<TLoginResponseDTO>(
        `${this.resourcePath}/login`,
        {
          email,
          password,
          isRememberMeEnabled,
        }
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async requestMagicLinkAsync(
    email: string,
    isRememberMeEnabled: boolean,
    sharedObjectURL: string
  ): Promise<boolean> {
    try {
      const response = await axios.post<void>(
        `${this.resourcePath}/request-magic-link`,
        {
          email,
          isRememberMeEnabled,
          sharedObjectURL,
        }
      );

      if (response && response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async loginWithMagicLink(
    magicLinkToken: string,
    email: string,
    isRememberMeEnabled: boolean
  ): Promise<TLoginResponseDTO | undefined> {
    try {
      const response = await axios.post<TLoginResponseDTO | undefined>(
        `${this.resourcePath}/login`,
        {
          magicLinkToken,
          email,
          isRememberMeEnabled,
        }
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async loginWith2FA(
    email: string,
    twoFactorAuthenticationToken: string,
    isRememberMeEnabled: boolean
  ): Promise<TLoginResponseDTO | undefined> {
    try {
      const response = await axios.post<TLoginResponseDTO | undefined>(
        `${this.resourcePath}/login`,
        {
          email,
          twoFactorAuthenticationToken,
          isRememberMeEnabled,
        }
      );

      if (response && response.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }

  public async logoutAsync(): Promise<boolean> {
    try {
      const response = await axios.post<void>(`${this.resourcePath}/logout`);

      if (response && response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async requestFirstTimePasswordCode(): Promise<boolean> {
    try {
      const response = await axios.post<void>(
        `${this.resourcePath}/firstPasswordCode`
      );

      if (response && response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  public async changePasswordAsync(
    currentPassword: string | null,
    newPassword: string,
    firstTimeSixDigitCode?: string | null
  ): Promise<TChangePasswordResultDTO> {
    try {
      const response = await axios.post<TChangePasswordResultDTO>(
        `${this.resourcePath}/changePassword`,
        {
          currentPassword,
          newPassword,
          firstTimeSixDigitCode,
        } as TChangePasswordDTO
      );

      if (response && response.data) {
        return response.data;
      } else {
        return {
          isSuccess: false,
          errors: ["An error occurred while changing your password."],
        };
      }
    } catch {
      return {
        isSuccess: false,
        errors: ["An error occurred while changing your password."],
      };
    }
  }

  public async refreshToken(
    accessToken: string | undefined,
    refreshToken: string | undefined
  ) {
    const response: AxiosResponse<TLoginOptionsDTO> =
      await axios.post<TLoginOptionsDTO>(`${this.resourcePath}/refresh`, {
        params: {
          accessToken,
          refreshToken,
        },
      });

    if (response && response.data) {
      return response.data;
    } else {
      return undefined;
    }
  }
}

export const AuthControllerSingleton = new AuthController();
