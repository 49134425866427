// React
import { FC, useEffect, useState } from "react";
// Font Awesome
// Components
import { ObjectChip } from "Components";
// Controllers
import { SharedToControllerSingleton } from "Controllers";
// Reference Modal
import { UserIcon } from "Components";
// Styles
import styles from "./sharedObjects.module.scss";
// Types
import { TIdNameTypeObjectType, TUserWithSharedPagesDTO } from "Types";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";
import { useObjectReferenceModal } from "Hooks";

export const SharedObjects: FC = () => {

    // State
    const [usersWithAllSharedObjects, setUsersWithAllSharedObjects] = useState<TUserWithSharedPagesDTO[]>([]);
    const [, setTotalUsersWithAllSharedObjectsCount] = useState<number>(0);

    // Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    const refreshUsersWithAllSharedObjectsAsync = async (): Promise<void> => {
        const currentUsersWithAllSharedObjects = await SharedToControllerSingleton.getUserWithSharedObjectsAsync();
        setUsersWithAllSharedObjects(currentUsersWithAllSharedObjects);
    };

    const refreshUsersWithAllShardObjecstCountAsync = async (): Promise<void> => {
        const amount = await SharedToControllerSingleton.getAmountOfUsersWithAllSharedObjectsAsync();

        if (!amount) {
            setTotalUsersWithAllSharedObjectsCount(0);
            return;
        }

        setTotalUsersWithAllSharedObjectsCount(amount);
    };

    useEffect(() => {
        refreshUsersWithAllSharedObjectsAsync();
        refreshUsersWithAllShardObjecstCountAsync();
    }, []);

    const onObjectClicked = (clickedObject: TIdNameTypeObjectType) => {
        setReferenceModalProps({
            isOpen: true,
            id: clickedObject.id,
            type: clickedObject.objectType,
            doIgnoreIsDeleted: false
        });
    };

    const onRemoveShared = async (clickedObject: TIdNameTypeObjectType, userId: string) => {
        // Remove the share for the user
        const isRemoved = await SharedToControllerSingleton.unshareToUserAsync(clickedObject.id, userId);

        // If it failed, show a toast
        if (!isRemoved) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to remove stop sharing with user.");
            return;
        }

        // If it succeeded, remove the page from the user
        refreshUsersWithAllSharedObjectsAsync();
        refreshUsersWithAllShardObjecstCountAsync();
    };

    return (
        <>
            <div className={styles.sharedObjectsContainer}>
                <div className={styles.listItemsContainer}>
                    <div className={styles.dataHeader}>
                        <div className={styles.dataEmailHeaderItem}>
                            <h4>External user email</h4>
                            {/* <FontAwesomeIcon className={styles.filtersIcon} icon={faBarsFilter} /> */}
                        </div>
                        <div className={styles.dataHeaderItem}>
                            <h4>Access</h4>
                            {/* <FontAwesomeIcon className={styles.filtersIcon} icon={faBarsFilter} /> */}
                        </div>
                    </div>
                    <div className={styles.sharedUsers}>
                        {usersWithAllSharedObjects.map((userWithAllSharedObjects) => {
                            return (
                                <div className={styles.sharedUser} key={userWithAllSharedObjects.userId}>
                                    <div className={styles.userDetails}>
                                        <UserIcon extraClassName={styles.userIcon} email={userWithAllSharedObjects.username} />
                                        <span title={userWithAllSharedObjects.username} className={styles.username}>{userWithAllSharedObjects.username}</span>
                                    </div>
                                    <div className={styles.sharedObjectsContainer}>
                                        {userWithAllSharedObjects.sharedObjects.map((sharedObject) => {
                                            return (
                                                <ObjectChip
                                                    isChipClickable
                                                    removeIconTitle="Remove access"
                                                    key={sharedObject.id}
                                                    object={sharedObject}
                                                    onClick={() => { onObjectClicked(sharedObject); }}
                                                    onRemoveClick={() => { onRemoveShared(sharedObject, userWithAllSharedObjects.userId); }}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {referenceModal}
        </>
    );
};