// React
import { useContext, useEffect } from "react";
// Providers
import { PubSubContext } from "Providers";
// Enums
import { PubSubEventTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";

export const useSpecificLinkAddedListener = (mainObjectId: string, onLinkAdded: (linkedObject: TIdNameTypeObjectType) => void) => {
    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);

    useEffect(() => {
        pubSubHandler.subscribeToEvent(`${PubSubEventTypeEnum.AddLinkTo}-${mainObjectId}`, onLinkAdded);

        return () => {
            pubSubHandler.unsubscribeFromEvent(`${PubSubEventTypeEnum.AddLinkTo}-${mainObjectId}`, onLinkAdded);
        };
    }, [mainObjectId, onLinkAdded, pubSubHandler]);
};