// node_modules
import { FC } from "react";
// Styles
import styles from "./createNewModal.module.scss";
import { IconDefinition as regularIconDefinition } from "@fortawesome/free-regular-svg-icons";
import { IconDefinition as solidIconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TCreateNewModalCardProps = {
    title: string,
    description: string,
    iconName: regularIconDefinition | solidIconDefinition,
    children?: React.ReactNode,
    extraClassName?: string
};

export const CreateNewModalCard: FC<TCreateNewModalCardProps> = ({title, description, iconName, children, extraClassName}: TCreateNewModalCardProps) => {
    // Render
    return (
        <div className={styles.createNewCard}>
            <div className={[styles.cardHeader, extraClassName ? extraClassName : null].join(" ")}>
                <div className={styles.cardTitle}>
                    <FontAwesomeIcon icon={iconName} />
                    <h2>{title}</h2>
                </div>
                <p className={styles.cardDescription}>{description}</p>
            </div>
            <div className={styles.cardContent}>
                {children}
            </div>
        </div>
    );
};