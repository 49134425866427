// Enums
import { PermissionsEnum, RolesEnum, SharedToSettingsEnum } from "Enums";
// Types
import { TAuth } from "Types";

export class UserHelper {
    public getFindestRoles(): RolesEnum[] {
        return [RolesEnum.FindestAdministrator, RolesEnum.FindestScout];
    }

    public isUserFindestAdministrator(auth: TAuth) {
        return auth.roles.indexOf(RolesEnum.FindestAdministrator) !== -1;
    }

    public isUserExternalByAuth(auth: TAuth) {
        return this.isUserExternalByRoles(auth.roles);
    }

    public isUserExternalByRoles(roles: string[]) {
        return roles.indexOf(RolesEnum.External) !== -1;
    }

    public isSharingRestrictedToObject(auth: TAuth) {
        // if user is external and shared to settings is only object
        if (this.isUserExternalByAuth(auth) && auth.sharedToSettings === SharedToSettingsEnum.OnlyObject) {
            // return true
            return true;
        }

        // otherwise, return false
        return false;
    }

    public isUserAtLeastViewer(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserFindestScout(auth) || this.isUserAdministrator(auth)
            || this.isUserOwner(auth) || this.isUserContributor(auth)
            || this.isUserViewer(auth);
    }

    public isUserAtLeastContributor(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserFindestScout(auth) || this.isUserAdministrator(auth)
            || this.isUserOwner(auth) || this.isUserContributor(auth);
    }

    public isUserFindestScout(auth: TAuth) {
        return auth.roles.indexOf(RolesEnum.FindestScout) !== -1;
    }

    public isUserAdministrator(auth: TAuth) {
        return auth.roles.indexOf(RolesEnum.Administrator) !== -1;
    }

    public isUserOwner(auth: TAuth) {
        return auth.roles.indexOf(RolesEnum.Owner) !== -1;
    }

    public isUserViewer(auth: TAuth) {
        return auth.roles.indexOf(RolesEnum.Viewer) !== -1;
    }

    public isUserContributor(auth: TAuth) {
        return auth.roles.indexOf(RolesEnum.Contributor) !== -1;
    }

    public hasAdvanced(permissions: PermissionsEnum[]): boolean {
        // check if permissions has advanced permission
        return permissions.indexOf(PermissionsEnum.Advanced) !== -1;
    }

    public hasAccessToRatings(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserFindestScout(auth) || this.isUserOwner(auth) || this.isUserAdministrator(auth) || this.isUserContributor(auth) || this.isUserViewer(auth);
    }

    public hasAccessToUsers(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserAdministrator(auth) || this.isUserOwner(auth);
    }

    public hasAccessToGalaxies(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserOwner(auth);
    }

    public hasAccessToShared(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserAdministrator(auth) || this.isUserOwner(auth) 
            || this.isUserContributor(auth);
    }

    public hasAccessToArchive(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserAdministrator(auth) || this.isUserOwner(auth)
            || this.isUserContributor(auth);
    }

    public hasAccessToAllLocked(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserAdministrator(auth) || this.isUserOwner(auth);
    }

    public hasAccessToTypeManagement(auth: TAuth) {
        return this.isUserFindestAdministrator(auth) || this.isUserOwner(auth);
    }
}

export const UserHelperSingleton = new UserHelper();