// React
import { useContext, useEffect } from "react";
// Providers
import { PubSubContext } from "Providers";
// Enums
import { PubSubEventTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";

export const useObjectLinkedListener = (onObjectLinked: (fromObject: TIdNameTypeObjectType, toObject: TIdNameTypeObjectType) => void) => {
    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);

    useEffect(() => {
        pubSubHandler.subscribeToEvent(PubSubEventTypeEnum.ObjectLinked, onObjectLinked);

        return () => {
            pubSubHandler.unsubscribeFromEvent(PubSubEventTypeEnum.ObjectLinked, onObjectLinked);
        };
    }, [onObjectLinked, pubSubHandler]);
};