// https://github.com/floating-ui/floating-ui/blob/master/packages/react/src/hooks/useMergeRefs.ts
import { Ref, RefCallback, useMemo, MutableRefObject } from "react";

export function useMergeRefs<Instance>(
    refs: Array<Ref<Instance>>
): RefCallback<Instance> | null {
    return useMemo(() => {
        if (refs.every((ref) => ref == null)) {
            return null;
        }

        return (value) => {
            refs.forEach((ref) => {
                if (typeof ref === "function") {
                    ref(value);
                } else if (ref != null) {
                    (ref as MutableRefObject<Instance | null>).current = value;
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, refs);
}