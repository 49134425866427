// React
import { FC, useState } from "react";
// Components
import { FindestButton, SelectableTextList } from "Components/Shared";
import { GalaxyControllerSingleton } from "Controllers";
import { TGalaxyDTO } from "Types";

export const Galaxies: FC = () => {

    const [galaxies, setGalaxies] = useState<TGalaxyDTO[]>([]);

    const testGalaxies = async () => {
        const galaxies = await GalaxyControllerSingleton.getGalaxies();
        setGalaxies(galaxies);
    };

    return (
        <div>
            <div>
                <SelectableTextList<string>
                    items={galaxies.map(galaxy => ({ key: galaxy.id, keyAsString: galaxy.id, name: galaxy.name }))}
                    onItemSelect={() => { }}
                    selectedItemKey={undefined} />
                <div>
                    <FindestButton title="Create Galaxy" />
                </div>
            </div>
            <div>
                
            </div>
        </div>
    );
};