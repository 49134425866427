// Enums
import { MessageTypeEnum } from "Enums";
// Interfaces
import { IMessage } from "Interfaces";

const extensionId = "pknmlohaomajmnhllapbbdejgkklbdld";

export class ExtensionCommunicationHelper {
    public async resetAuthenticationAsync() {
        if(typeof window.chrome !== "undefined") {
            try {
                await chrome.runtime.sendMessage(extensionId, 
                    { type: MessageTypeEnum.RESET_AUTHENTICATION } as IMessage);
            } catch (error: unknown) {
                // console error
                console.error(error);
            }
        }
    }
}

export const ExtensionCommunicationHelperSingleton = new ExtensionCommunicationHelper();