// node_modules
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
// Controllers
import { QueryControllerSingleton } from "Controllers";
// Interfaces
import { IQueryDTO } from "Interfaces";

type TQueryContext = {
    query?: IQueryDTO,
    setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>
};

const defaultQueryContext: TQueryContext = {
    query: undefined,
    setQuery: () => { return undefined; }
};

type TQueryProviderProps = {
    children?: ReactNode
};

export const QueryContext = createContext<TQueryContext>(defaultQueryContext);

export const QueryProvider = ({ children }: TQueryProviderProps) => {
    // State
    const [query, setQuery] = useState<IQueryDTO | undefined>(undefined);

    // Hooks
    const { queryId } = useParams();

    // Logic
    useEffect(() => {
        if (!queryId) return;

        (async () => {
            const retrievedQuery = await QueryControllerSingleton.getByIdAsync(queryId);
            setQuery(retrievedQuery);
        })();
    }, [queryId]);

    const providerValue = useMemo(() => {
        return { query, setQuery };
    }, [query, setQuery]);

    // Render
    return (
        <QueryContext.Provider value={providerValue}>
            {children}
        </QueryContext.Provider>
    );
};