import { faArrowDownLong, faArrowUpLong, faChevronDown, faChevronUp, faCut, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Node, NodeType } from "prosemirror-model";
import { FC, useEffect, useMemo, useRef, useState } from "react";
// Styles
import styles from "./editorActionsBar.module.scss";
// Types
import { NullableProseMirrorNode, TBlockContainerProps, TCommentDTO, TEditorMenuProps } from "Types";
// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";
// Components
import { CommentsCount, CommentsPopover, Popover } from "Components";
// Constants
import { FeatureToggleConstants } from "Constants";
// Enums
import { CustomInlineNameEnum, TopDepthMarkdownCustomBlockNameEnum } from "Enums";
// Custom hooks
import { useClickOutsideRef } from "Hooks";

type TProps = {
    editorMenuProps: TEditorMenuProps,
    onReferenceCommentsUpdated: (node: NullableProseMirrorNode | undefined, 
        nodeType: NodeType | undefined, comments: TCommentDTO[]) => void,
    turnBlockReferenceToInlineReference?: (node: Node) => void,
    turnInlineReferenceToBlockReference?: (node: Node) => void
} & TBlockContainerProps;

export const EditorActionsBar: FC<TProps> = ({ 
        currentlySelectedNode, removeBlock, moveBlockUp, editorMenuProps,
        moveBlockDown, cutBlock, onReferenceCommentsUpdated, comments, turnBlockReferenceToInlineReference, turnInlineReferenceToBlockReference }: TProps) => {
    // Ref
    const tableOptionsReferenceRef = useRef<HTMLDivElement>(null);
    const referenceDisplayTypeContainerRef = useRef<HTMLDivElement>(null);
    const commentsContainerRef = useRef<HTMLDivElement>(null);

    // State
    const [isCommentsPopoverShown, setIsCommentsPopoverShown] = useState<boolean>(false);
    const [commentsPopoverElement, setCommentsPopoverElement] = useState<HTMLHeadElement | null>(null);
    const [tableOptionsButtonElement, setTableOptionsButtonElement] = useState<HTMLButtonElement | null>(null);
    const [referenceDisplayTypeOptionButtonElement, setReferenceDisplayTypeOptionButtonElement] = useState<HTMLButtonElement | null>(null);
    const [isReferenceDisplayTypeOptionsPopoverShown, setIsReferenceDisplayTypeOptionsPopoverShown] = useState<boolean>(false);
    const [isTableOptionsPopoverShown, setIsTableOptionsPopoverShown] = useState<boolean>(false);

    // Memos
    const isTableSelected = useMemo(() => editorMenuProps.isSelectionInTable, [editorMenuProps.isSelectionInTable]);
    const isMoveDisabled = useMemo(() => editorMenuProps.isSelectionInList || editorMenuProps.isSelectionInTable || (currentlySelectedNode && currentlySelectedNode.marks.some(mark => mark.type.name === CustomInlineNameEnum.InlineReference)), 
        [currentlySelectedNode, editorMenuProps.isSelectionInList, editorMenuProps.isSelectionInTable]);
    const isCutActionShown = useMemo(() => currentlySelectedNode && !currentlySelectedNode.marks.some(mark => mark.type.name === CustomInlineNameEnum.InlineReference), [currentlySelectedNode]);
    const isRemoveActionShown = useMemo(() => currentlySelectedNode && !currentlySelectedNode.marks.some(mark => mark.type.name === CustomInlineNameEnum.InlineReference), [currentlySelectedNode]);
    const isReferenceDisplayTypeDropdownShown = useMemo((): boolean => {
        // safety-checks
        if (!FeatureToggleConstants.BlockReferencing) {
            return false;
        }

        if (!currentlySelectedNode) {
            // if currently selected node is null, then do not show reference display type dropdown
            return false;
        }

        // if currently selected node is not an entity or a study reference
        // or does not have a mark which is an inline reference mark
        // then do not show reference display type dropdown
        if (currentlySelectedNode.type.name !== TopDepthMarkdownCustomBlockNameEnum.EntityReference && 
                    currentlySelectedNode.type.name !== TopDepthMarkdownCustomBlockNameEnum.StudyReference &&
                    !currentlySelectedNode.marks.some(mark => mark.type.name === CustomInlineNameEnum.InlineReference)) {
            return false;
        }

        // otherwise, show reference display type dropdown
        return true;
    }, [currentlySelectedNode]);

    // Custom hooks
    useClickOutsideRef(tableOptionsReferenceRef, () => { setIsTableOptionsPopoverShown(false); });
    useClickOutsideRef(referenceDisplayTypeContainerRef, () => { setIsReferenceDisplayTypeOptionsPopoverShown(false); });
    useClickOutsideRef(commentsContainerRef, () => { setIsCommentsPopoverShown(false); });

    // Logic
    useEffect(() => {
        // if currently selected node is null
        if (!currentlySelectedNode || !currentlySelectedNode.type) {
            // hide comments popover, reference display type options popover dropdown and table options popover dropdown
            setIsCommentsPopoverShown(false);
            setIsReferenceDisplayTypeOptionsPopoverShown(false);
            setIsTableOptionsPopoverShown(false);
        }
    }, [currentlySelectedNode]);

    // Memo
    // get if editor actions bar has any content
    const doesEditorActionsBarHaveAnyContent = useMemo(() => {
        return (FeatureToggleConstants.TableOptions && isTableSelected) || 
            (isReferenceDisplayTypeDropdownShown && turnBlockReferenceToInlineReference && turnInlineReferenceToBlockReference) ||
            (isReferenceDisplayTypeDropdownShown && (!isMoveDisabled || isCutActionShown || isRemoveActionShown) ||
            (!isMoveDisabled) || 
            (isCutActionShown) ||
            (isRemoveActionShown) ||
            (comments && comments.length > 0 && currentlySelectedNode && currentlySelectedNode.type));
    }, [comments, currentlySelectedNode, isCutActionShown, isMoveDisabled, isReferenceDisplayTypeDropdownShown, isRemoveActionShown, isTableSelected, turnBlockReferenceToInlineReference, turnInlineReferenceToBlockReference]);

    return (
        doesEditorActionsBarHaveAnyContent ? 
            <div className={styles.customBlockOptionsContainer}>
                {FeatureToggleConstants.TableOptions && isTableSelected &&
                    <div ref={tableOptionsReferenceRef} className={styles.tableOptionsContainer}>
                        <button className={styles.tableOptionsButton} ref={setTableOptionsButtonElement} type="button" title="Table options" 
                                onClick={() => { setIsTableOptionsPopoverShown(!isTableOptionsPopoverShown); }}>
                            <p>Table options</p>
                            <FontAwesomeIcon icon={isTableOptionsPopoverShown ? faChevronUp : faChevronDown} />
                        </button>
                        {isTableOptionsPopoverShown &&
                            <Popover 
                                referenceEl={tableOptionsButtonElement}
                                extraClassName={styles.optionsPopover}>
                                <div className={styles.option}>
                                    <p onClick={() => editorMenuProps.applyAddColumn("AFTER")}>Insert column right</p>
                                </div>
                                <div className={styles.option}>
                                    <p onClick={() => editorMenuProps.applyAddRow("AFTER")}>Insert row below</p>
                                </div>
                                <div className={styles.option}>
                                    <p onClick={() => editorMenuProps.applyDeleteColumn()}>Delete column</p>
                                </div>
                                <div className={styles.option}>
                                    <p onClick={() => editorMenuProps.applyDeleteRow()}>Delete row</p>
                                </div>
                            </Popover>
                        }
                        {(isReferenceDisplayTypeDropdownShown || !isMoveDisabled || isCutActionShown || isRemoveActionShown) && <div className={styles.editorSpacer}></div>}
                    </div>
                }

                {(isReferenceDisplayTypeDropdownShown && turnBlockReferenceToInlineReference && turnInlineReferenceToBlockReference) && (
                    <div className={styles.referenceDisplayOptionsContainer} ref={referenceDisplayTypeContainerRef}>
                        <button className={styles.referenceDisplayOptionsButton} ref={setReferenceDisplayTypeOptionButtonElement} 
                                type="button" 
                                title="Reference display type" 
                                onClick={() => { setIsReferenceDisplayTypeOptionsPopoverShown(!isReferenceDisplayTypeOptionsPopoverShown); }}>
                            {(currentlySelectedNode && (currentlySelectedNode.type.name === TopDepthMarkdownCustomBlockNameEnum.EntityReference || 
                                    currentlySelectedNode.type.name === TopDepthMarkdownCustomBlockNameEnum.StudyReference)) && (
                                <p>Card</p>
                            )}
                            {(currentlySelectedNode && currentlySelectedNode.marks.some(mark => mark.type.name === CustomInlineNameEnum.InlineReference)) && (
                                <p>Inline</p>

                            )}
                            <FontAwesomeIcon icon={isReferenceDisplayTypeOptionsPopoverShown ? faChevronUp : faChevronDown} />
                        </button>
                        {isReferenceDisplayTypeOptionsPopoverShown &&
                            <Popover extraClassName={styles.optionsPopover} referenceEl={referenceDisplayTypeOptionButtonElement}>
                                {(currentlySelectedNode && (currentlySelectedNode.type.name === TopDepthMarkdownCustomBlockNameEnum.EntityReference || 
                                        currentlySelectedNode.type.name === TopDepthMarkdownCustomBlockNameEnum.StudyReference)) && (
                                    <div className={styles.option}>
                                        <p onClick={() => { turnBlockReferenceToInlineReference(currentlySelectedNode); }}>Inline</p>
                                    </div>
                                )}
                                {(currentlySelectedNode && currentlySelectedNode.marks.some(mark => mark.type.name === CustomInlineNameEnum.InlineReference)) && (
                                    <div className={styles.option}>
                                        <p onClick={() => { turnInlineReferenceToBlockReference(currentlySelectedNode); }}>Card</p>
                                    </div>
                                )}
                            </Popover>
                        }
                    </div>
                )}

                {(isReferenceDisplayTypeDropdownShown && (!isMoveDisabled || isCutActionShown || isRemoveActionShown)) && <div className={styles.editorSpacer}></div>}

                {!isMoveDisabled &&
                    <>
                        <button type="button" title="Move up" onClick={() => {moveBlockUp(currentlySelectedNode);}}>
                            <FontAwesomeIcon icon={faArrowUpLong} />
                        </button>
                        <button type="button" title="Move down" onClick={() => {moveBlockDown(currentlySelectedNode);}}>
                            <FontAwesomeIcon icon={faArrowDownLong} />
                        </button>
                    </>
                }

                {(!isMoveDisabled) && <div className={styles.editorSpacer}></div>}

                {isCutActionShown && (
                    <button type="button" title="Cut element" onClick={() => {cutBlock(currentlySelectedNode);}}>
                        <FontAwesomeIcon icon={faCut} />
                    </button>
                )}
                {isCutActionShown && (
                    <div className={styles.editorSpacer}></div>
                )}
                {isRemoveActionShown && (
                    <button type="button" title="Remove element" onClick={() => {removeBlock(currentlySelectedNode);}}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )}
                {(comments && comments.length > 0 && currentlySelectedNode && currentlySelectedNode.type) &&
                    <div className={styles.commentsContainer} ref={commentsContainerRef}>
                        <div className={styles.editorSpacer}></div>
                        <CommentsCount 
                            containerRef={setCommentsPopoverElement} 
                            onMouseEnter={() => { setIsCommentsPopoverShown(true); }} 
                            comments={comments} />
                        <CommentsPopover 
                            onCommentsUpdated={(newComments: TCommentDTO[]) => onReferenceCommentsUpdated(currentlySelectedNode, currentlySelectedNode.type, newComments)}
                            referenceId={ProseMirrorHelperSingleton.getCustomBlockId(currentlySelectedNode, currentlySelectedNode.type) ?? ""}
                            isPopoverShown={isCommentsPopoverShown}
                            onMouseLeave={() => { setIsCommentsPopoverShown(false); }}
                            comments={comments}
                            referenceEl={commentsPopoverElement} />
                    </div>
                }
            </div>
        :
            null
    );
};