// node_modules
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, FC, FocusEvent, MouseEvent, SetStateAction, useCallback, useState } from "react";
// Types
import { TActionObjectDTO } from "Types";
// Components
import { Checkbox, SearchTermHitsCount } from "Components";
import { SelectSearchPriority } from "../SearchPriority/SelectSearchPriority";
import { SearchSubTerm } from "../SearchSubTerm/SearchSubTerm";
// Enums
import { SearchPriorityEnum, SearchSubTermTypeEnum, SynonymTypeEnum } from "Enums";
// Styles
import styles from "./actionObject.module.scss";
// Interfaces
import { IQueryDTO } from "Interfaces";

type TActionObjectProps = {
    actionObject: TActionObjectDTO,
    updateActionObjectSearchPriorityAsync: (actionObjectId: number, actionObjectUpdatedSearchPriority: SearchPriorityEnum) => void,
    deleteActionObjectAsync: (actionObjectId: number) => void,
    updateActionObjectValueAsync: (actionObjectId: number, searchSubTermUpdatedValue: string, searchSubTermType: SearchSubTermTypeEnum) => void,
    query: IQueryDTO,
    setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>,
    hitsCount: number,
    isSearchTermPriorityDropdown: boolean
}

export const ActionObject: FC<TActionObjectProps> = ({ actionObject, updateActionObjectSearchPriorityAsync,
    deleteActionObjectAsync, updateActionObjectValueAsync, query, setQuery, hitsCount, isSearchTermPriorityDropdown }: TActionObjectProps) => {
    // State
    const [isActionSynonymsSectionShown, setIsActionSynonymsSectionShown] = useState<boolean>(false);
    const [isObjSynonymsSectionShown, setIsObjSynonymsSectionShown] = useState<boolean>(false);
    const [clickedActionSearchSubTerm, setClickedActionSearchSubTerm] = useState<EventTarget | null>(null);
    const [clickedObjectSearchSubTerm, setClickedObjectSearchSubTerm] = useState<EventTarget | null>(null);
    const [actionSynonymsHelperValue, setActionSynonymsHelperValue] = useState<string>(actionObject.action);
    const [actionSynonymsHelperId, setActionSynonymsHelperId] = useState<number>(actionObject.id);
    const [objectSynonymsHelperValue, setObjectSynonymsHelperValue] = useState<string>(actionObject.dobject);
    const [objectSynonymsHelperId, setObjectSynonymsHelperId] = useState<number>(actionObject.id);
    
    // Logic
    const openActionSynonymsSection = useCallback((event: MouseEvent): void => {
        setIsActionSynonymsSectionShown(true);
        setIsObjSynonymsSectionShown(false);
        setClickedActionSearchSubTerm(event.target);
        setActionSynonymsHelperValue(actionObject.action);
        setActionSynonymsHelperId(actionObject.id);
    }, [actionObject.action, actionObject.id]);

    const closeSynonymsModal = useCallback(() => {
        setIsActionSynonymsSectionShown(false);
        setIsObjSynonymsSectionShown(false);
    }, []);

    const openObjSynonymsSection = useCallback((event: MouseEvent): void => {
        setIsObjSynonymsSectionShown(true);
        setIsActionSynonymsSectionShown(false);
        setClickedObjectSearchSubTerm(event.target);
        setObjectSynonymsHelperValue(actionObject.dobject);
        setObjectSynonymsHelperId(actionObject.id);
    }, [actionObject.dobject, actionObject.id]);

    const handleOnCheckboxChange = (checked: boolean) => {
        if (checked) {
            updateActionObjectSearchPriorityAsync(actionObject.id, SearchPriorityEnum.Should);
        } else {
            updateActionObjectSearchPriorityAsync(actionObject.id, SearchPriorityEnum.Disabled);
            setIsObjSynonymsSectionShown(false);
            setIsActionSynonymsSectionShown(false);
        }
    };

    const handleSearchTermChange = useCallback((searchSubTermUpdatedValue: string, searchSubTermType: SearchSubTermTypeEnum) => {
        updateActionObjectValueAsync(actionObject.id, searchSubTermUpdatedValue, searchSubTermType);
        if (searchSubTermType === SearchSubTermTypeEnum.Action) {
            setActionSynonymsHelperValue(searchSubTermUpdatedValue);
        } else if (searchSubTermType === SearchSubTermTypeEnum.Object) {
            setObjectSynonymsHelperValue(searchSubTermUpdatedValue);
        }
    }, [actionObject.id, updateActionObjectValueAsync]);

    return (
        <div className={`${styles.findestActionObjectContainer} ${isSearchTermPriorityDropdown ? styles.searchTermPriorityDropdownStyle : styles.searchTermPriorityButtonStyle}`}>
            <div className={actionObject.searchPriority === SearchPriorityEnum.Disabled ? [styles.findestActionObject, styles.actionObjectDisabled].join(" ") : styles.findestActionObject}>
                <div className={styles.priorityContainer}>
                    <Checkbox
                        isChecked={actionObject.searchPriority !== SearchPriorityEnum.Disabled}
                        onCheckboxChange={handleOnCheckboxChange}
                        title={actionObject.searchPriority !== SearchPriorityEnum.Disabled ? "Disable function" : "Enable function"}
                    />
                    <div className={styles.prioritySelection}>
                        <SelectSearchPriority
                            searchPriority={actionObject.searchPriority}
                            onChange={(newSearchPriority: SearchPriorityEnum) => updateActionObjectSearchPriorityAsync(actionObject.id, newSearchPriority)} 
                            isSearchTermPriorityDropdown={isSearchTermPriorityDropdown} />
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.valueContainer}>
                    <SearchSubTerm
                        searchSubTermSearchPriority={actionObject.searchPriority}
                        searchSubTerm={actionObject.action}
                        searchSubTermSynonyms={actionObject.actionSynonyms}
                        updateSearchSubTermValue={handleSearchTermChange}
                        searchSubTermType={SearchSubTermTypeEnum.Action}
                        handleOpenSynonymsSection={openActionSynonymsSection}
                        handleCloseSynonymsModal={closeSynonymsModal}
                        isDisabled={actionObject.searchPriority === SearchPriorityEnum.Disabled} 
                        onInputFocus={(event: FocusEvent) => { event.preventDefault(); event.stopPropagation(); setActionSynonymsHelperId(actionObject.id); setActionSynonymsHelperValue(actionObject.action); setClickedActionSearchSubTerm(event.currentTarget); } }
                        synonyms={actionObject.actionSynonyms} 
                        synonymsType={SynonymTypeEnum.Action}
                        fieldId={actionObject.id}
                        synonymsHelperId={actionSynonymsHelperId}
                        setSynonymsHelperId={setActionSynonymsHelperId}
                        synonymsHelperValue={actionSynonymsHelperValue}
                        setSynonymsHelperValue={setActionSynonymsHelperValue}
                        query={query}
                        setQuery={setQuery} 
                        clickedFieldElement={clickedActionSearchSubTerm} 
                        doOpenSynonymsHelperPopup={isActionSynonymsSectionShown}
                    />
                    <div className={styles.separator}></div>
                    <SearchSubTerm
                        searchSubTermSearchPriority={actionObject.searchPriority}
                        searchSubTerm={actionObject.dobject}
                        searchSubTermSynonyms={actionObject.objectSynonyms}
                        updateSearchSubTermValue={handleSearchTermChange}
                        searchSubTermType={SearchSubTermTypeEnum.Object}
                        handleOpenSynonymsSection={openObjSynonymsSection}
                        handleCloseSynonymsModal={closeSynonymsModal}
                        isDisabled={actionObject.searchPriority === SearchPriorityEnum.Disabled} 
                        onInputFocus={(event: FocusEvent) => { event.preventDefault(); event.stopPropagation(); setObjectSynonymsHelperId(actionObject.id); setObjectSynonymsHelperValue(actionObject.dobject); setClickedObjectSearchSubTerm(event.currentTarget); } }
                        synonyms={actionObject.objectSynonyms} 
                        synonymsType={SynonymTypeEnum.Object}
                        fieldId={actionObject.id} 
                        synonymsHelperId={objectSynonymsHelperId}
                        setSynonymsHelperId={setObjectSynonymsHelperId}
                        synonymsHelperValue={objectSynonymsHelperValue}
                        setSynonymsHelperValue={setObjectSynonymsHelperValue}
                        query={query}
                        setQuery={setQuery} 
                        clickedFieldElement={clickedObjectSearchSubTerm}
                        doOpenSynonymsHelperPopup={isObjSynonymsSectionShown}
                    />
                </div>
                <div className={styles.separator}></div>
                <SearchTermHitsCount isDisabled={actionObject.searchPriority === SearchPriorityEnum.Disabled} hitsCount={hitsCount} />
                <div className={styles.deleteContainer} title="Delete keyword" onClick={() => deleteActionObjectAsync(actionObject.id)}> 
                    <FontAwesomeIcon icon={faTrashAlt} />
                </div>
            </div>
        </div>
    );
};
