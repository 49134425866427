// node_modules
import { Placement } from "@floating-ui/react-dom";
import { FC } from "react";
// Styles
import styles from "./tooltip.module.scss";
// Components
import { Popover } from "Components";

export type TooltipProps = {
    referenceEl: Element | null,
    placement?: Placement | undefined,
    isOpen: boolean,
    tooltipText: string,
    popoverOffset?: number
};

export const Tooltip: FC<TooltipProps> = ({ referenceEl, placement, isOpen, tooltipText, popoverOffset }) => {
    return (
        <Popover
            referenceEl={referenceEl}
            isOpen={isOpen}
            placement={placement}
            extraClassName={styles.tooltip}
            showInPortal
            popoverOffset={popoverOffset}
        >
            <h4>{tooltipText}</h4>
        </Popover>
    );
};