// node_modules
import { ReactNode, createContext, useContext, useMemo } from "react";
// Helpers
import { WebsocketPubSubHandler } from "Helpers";
// Contexts
import { AuthContext, PubSubProvider, WebsocketContext } from "Providers";

type TWebsocketPubSubProviderProps = {
    children?: ReactNode
};

export const WebsocketPubSubContext = createContext(undefined);

export const WebsocketPubSubProvider = ({ children }: TWebsocketPubSubProviderProps) => {
    // Contexts
    const { webSocketController } = useContext(WebsocketContext);
    const { isUserConnected } = useContext(AuthContext);

    // Memo
    const handler = useMemo(() => {
        // if user is connected
        if (isUserConnected) {
            // build signalR connection
            webSocketController.buildSignalRConnection();
        }

        return new WebsocketPubSubHandler(webSocketController);
    }, [isUserConnected, webSocketController]);

    // Render
    return (
        <PubSubProvider pubSubHandler={handler}>
            {children}
        </PubSubProvider>
    );
};