// Controllers
import { StudyControllerSingleton } from "Controllers";
// Enums
import { StudyTypeEnum } from "Enums";
// Types
import { TOption, TOptions } from "Types";
// Constants
import { StudyConstants } from "Constants";

export class StudyTypeHelper {
    private studyTypeStringToEnumMapping: { [name: string]: StudyTypeEnum } = {
        "": StudyTypeEnum.Undefined,
        "undefined": StudyTypeEnum.Undefined,
        "no type": StudyTypeEnum.Undefined,
        "newproductdevelopmentproject": StudyTypeEnum.NewProductDevelopmentProject,
        "researchproject": StudyTypeEnum.ResearchProject,
        "ideation": StudyTypeEnum.Ideation,
        "literaturestudy": StudyTypeEnum.LiteratureStudy,
        "technologyscouting": StudyTypeEnum.TechnologyScouting,
        "experimentalstudy": StudyTypeEnum.ExperimentalStudy,
        "characterization": StudyTypeEnum.Characterization,
        "feasibilitystudy": StudyTypeEnum.FeasibilityStudy,
        "marketresearch": StudyTypeEnum.MarketResearch,
        "troubleshooting": StudyTypeEnum.Troubleshooting,
        "custom": StudyTypeEnum.Custom,
        "studytypecustom": StudyTypeEnum.Custom,
    };

    private getStudyNameTypePairs(): TOption<StudyTypeEnum>[] {
        return Object
            .keys(StudyTypeEnum)
            .filter((key) => Number.isNaN(Number(key)))
            .map((key: string) => ({
                value: this.studyTypeStringToEnum(key),
                title: this.getStudyTypeDisplayName(this.studyTypeStringToEnum(key))
            }));
    }

    public defaultStudyTypeOption: TOption<StudyTypeEnum> = {
        value: StudyTypeEnum.Undefined,
        title: this.getStudyTypeDisplayName(StudyTypeEnum.Undefined)
    };

    public async getCustomTypeDropdownOptionsGroupAsync(doIncludeCreateCustomTypeOption: boolean, 
            doIncludeConvertToEntity: boolean): Promise<TOptions<StudyTypeEnum>[]> {
        let allStudyTypeOptions = this.studyTypesDropdownOptions;

        const getCustomTypeNamesResult: string[] | undefined = await StudyControllerSingleton.getCustomTypeNamesAsync();
        const customTypeNames: string[] = getCustomTypeNamesResult ?? [];
        const customTypeOptions: TOption<StudyTypeEnum>[] = customTypeNames.map((customTypeName: string) => ({
            value: StudyTypeEnum.Custom,
            title: customTypeName
        }));

        const customTypeDropdownOptionsGroup: TOptions<StudyTypeEnum> = {
            group: "Custom study types",
            options: customTypeOptions
        };

        if (doIncludeCreateCustomTypeOption) {
            customTypeDropdownOptionsGroup.options.push({
                value: StudyTypeEnum.Custom,
                title: StudyConstants.CREATE_CUSTOM_TYPE_OPTION
            });
        }

        const otherStudyTypeDropdownOptionsGroup: TOptions<StudyTypeEnum> = {
            group: "Other study types",
            options: [
                this.defaultStudyTypeOption
            ]
        };
          
        if(doIncludeConvertToEntity) {
            otherStudyTypeDropdownOptionsGroup.options.push({
                value: StudyTypeEnum.ConvertToEntity,
                title: StudyConstants.CONVERT_TO_ENTITY_OPTION
            });
        }

        allStudyTypeOptions = [...allStudyTypeOptions, otherStudyTypeDropdownOptionsGroup];
        if (doIncludeCreateCustomTypeOption) {
            allStudyTypeOptions = [customTypeDropdownOptionsGroup, ...allStudyTypeOptions];
        }
        return allStudyTypeOptions;
    }

    
    public getStudyTypeDisplayName(studyType: StudyTypeEnum, customTypeName?: string): string {
        switch (studyType) {
            case StudyTypeEnum.Undefined:
                return "Study";
            case StudyTypeEnum.NewProductDevelopmentProject: 
                return "New Product Development Project";
            case StudyTypeEnum.ResearchProject: 
                return "Research Project";
            case StudyTypeEnum.Ideation: 
                return "Ideation";
            case StudyTypeEnum.LiteratureStudy: 
                return "Literature Study";
            case StudyTypeEnum.TechnologyScouting: 
                return "Technology Scouting";
            case StudyTypeEnum.ExperimentalStudy: 
                return "Experimental Study";
            case StudyTypeEnum.Characterization: 
                return "Characterization";
            case StudyTypeEnum.FeasibilityStudy: 
                return "Feasibility Study";
            case StudyTypeEnum.MarketResearch: 
                return "Market Research";
            case StudyTypeEnum.Troubleshooting: 
                return "Troubleshooting";
            case StudyTypeEnum.Custom:
                return customTypeName ?? StudyConstants.CREATE_CUSTOM_TYPE_OPTION;
            default:
                return "Study";
        }
    }

    public studyTypeStringToEnum(studyType: string): StudyTypeEnum {
        return this.studyTypeStringToEnumMapping[studyType.toLowerCase()];
    }

    
    public studyTypesDropdownOptions: TOptions<StudyTypeEnum>[] = [
        {
            group: "Project types",
            options: this.getStudyNameTypePairs().slice(2, 4)
        },
        {
            group: "Basic study types",
            options: this.getStudyNameTypePairs().slice(4, 12)
        },
    ];

    public allStudyTypes: StudyTypeEnum[] = Object.values(StudyTypeEnum) as StudyTypeEnum[];

    public enumToNumber(studyType: StudyTypeEnum): number {
        switch (studyType) {
            case StudyTypeEnum.Undefined:
                return 0;
            case StudyTypeEnum.Custom:
                return 1;
            case StudyTypeEnum.NewProductDevelopmentProject:
                return 2;
            case StudyTypeEnum.ResearchProject:
                return 3;   
            case StudyTypeEnum.Ideation:
                return 4;   
            case StudyTypeEnum.LiteratureStudy:
                return 5;
            case StudyTypeEnum.TechnologyScouting:
                return 6;
            case StudyTypeEnum.ExperimentalStudy:
                return 7;
            case StudyTypeEnum.Characterization:
                return 8;
            case StudyTypeEnum.FeasibilityStudy:
                return 9;
            case StudyTypeEnum.MarketResearch:
                return 10;
            case StudyTypeEnum.Troubleshooting:
                return 11;
            default:
                return 0;
        }
    }
}

export const StudyTypeHelperSingleton = new StudyTypeHelper();