// node_modules
import axios from "axios";
// Types
import { TEnvironmentVariableDTO } from "Types";
// Enums
import { LogFeatureNameEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton, LogHelperSingleton } from "Helpers";

export class EnvironmentVariableController {

    public async createAsync(queryId: string, environmentVariableToCreate: TEnvironmentVariableDTO): Promise<TEnvironmentVariableDTO | undefined> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/environmentvariables`;

            // make request
            const response = await axios.post<TEnvironmentVariableDTO>(url, environmentVariableToCreate);

            // deal with response
            if (response && response.data) {
                LogHelperSingleton.log(`${LogFeatureNameEnum.AdvancedSearch}-AddKeywordToQuery`);
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateAsync(queryId: string, environmentVariable: TEnvironmentVariableDTO): Promise<TEnvironmentVariableDTO | undefined> {
        try {
        // build url
        const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/environmentvariables/${environmentVariable.id}`;

        // make request
        const response = await axios.put<TEnvironmentVariableDTO>(url, environmentVariable);

            // deal with response
            if (response && response.data) {
                LogHelperSingleton.log(`${LogFeatureNameEnum.AdvancedSearch}-UpdateKeywordOnQuery`);
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async deleteAsync(queryId: string, environmentVariableId: number): Promise<boolean> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/environmentvariables/${environmentVariableId}`;

            // make request
            const response = await axios.delete<void>(url);

            // deal with response
            if (response && response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const EnvironmentVariableControllerSingleton = new EnvironmentVariableController(); 