// React
import { FC, useCallback, useEffect, useMemo, useState } from "react";
// Components
import { ConnectedObject, Modal, MainTitle, Dropdown, LoadingStatusIndicator, ObjectSearchPopupContent, Popover, FindestButton } from "Components";
// Helpers
import { EntityTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import { TIdNameTypeObjectType, TOption, TOptions } from "Types";
// Constants
import { LinkingConstants } from "Constants";
// Enums
import { EntityTypeEnum, ToastTypeEnum } from "Enums";
// Interfaces
import { IEntityDTO } from "Interfaces";
// Styles
import commonDropdownStyles from "Styles/Common/dropdown.module.scss";
import styles from "./linkCreatedEntityModal.module.scss";

// Component props type
type LinkCreatedEntityModalProps = {
    onClose?: () => void,
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    creatingEntity?: IEntityDTO,
    onCreateEntityClickAsync: (entity: IEntityDTO, linkType: string, linkedObject?: TIdNameTypeObjectType) => Promise<void>,
    initialLinkedObjects?: TIdNameTypeObjectType[],
};

// Constants
const defaultEntityType = { 
    title: EntityTypeHelperSingleton.getEntityTypeDisplayName(EntityTypeEnum.Undefined), 
    value: EntityTypeEnum.Undefined 
};

// Component
export const LinkCreatedEntityModal: FC<LinkCreatedEntityModalProps> = ({
        creatingEntity, onClose, isOpen, setIsOpen, onCreateEntityClickAsync, initialLinkedObjects }) => {
    // State
    const [allEntityTypesDropdownOptions, setAllEntityTypesDropdownOptions] = useState<TOptions<EntityTypeEnum>[]>([]);
    const [currentEntityType, setCurrentEntityType] = useState<TOption<EntityTypeEnum>>(defaultEntityType);
    const [linkType, setLinkType] = useState<TOption<string>>(LinkingConstants.CHILD_LINK_TYPE);
    const [isSearchPopupOpen, setIsSearchPopupOpen] = useState<boolean>(false);
    const [connectionsSpanElementReference, setConnectionsSpanElementReference] = useState<HTMLSpanElement | null>(null);
    const [currentSelectedObjectToLink, setCurrentSelectedObjectToLink] = useState<TIdNameTypeObjectType | undefined>(undefined);
    const [creatingEntityTitle, setCreatingEntityTitle] = useState<string>(creatingEntity ? creatingEntity.title : "");
    const [isCreatingEntity, setIsCreatingEntity] = useState<boolean>(false);

    // Memo
    const isCreateNewEntityButtonDisabled = useMemo(() => {
        return !creatingEntityTitle;
    }, [creatingEntityTitle]);

    // useEffect
    useEffect(() => {
        // if creatingEntity changes and is defined
        if(creatingEntity) {
            // set the title
            setCreatingEntityTitle(creatingEntity.title);
        }
    }, [creatingEntity]);

    const handleClose = () => {
        // if isCreatingEntity is true
        if(isCreatingEntity) {
            // stop execution, return
            return;
        }

        setIsOpen(false);
        setCurrentEntityType(defaultEntityType);
        setCurrentSelectedObjectToLink(undefined);
        if(onClose) onClose();
    };

    const onCreateEntityAsync = async (currentCreatingEntityTitle: string): Promise<void> => {
        // if entity title is not set
        if(!currentCreatingEntityTitle) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Please enter a title for the entity.");
            // stop execution, return
            return;
        }

        // set isCreatingEntity to true
        setIsCreatingEntity(true);

        // create entity object
        const entity = {    
            title: currentCreatingEntityTitle,
            type: currentEntityType.value,
            customTypeName: currentEntityType.value === EntityTypeEnum.Custom ? currentEntityType.title : null,
        } as IEntityDTO;

        // call onCreateEntityClickAsync
        await onCreateEntityClickAsync(entity, linkType.value, currentSelectedObjectToLink);

        // set isCreatingEntity to false
        setIsCreatingEntity(false);
    }; 

    const onClickSearchResult = (result: TIdNameTypeObjectType) => {
        setIsSearchPopupOpen(false);
        setCurrentSelectedObjectToLink(result);
    };

    const refreshCustomEntityTypesAsync = useCallback(async (): Promise<void> => {
        const allEntityTypeDropdownOptionsGroups = await EntityTypeHelperSingleton
            .getCustomTypeDropdownOptionsGroupAsync(false, false);
        setAllEntityTypesDropdownOptions(allEntityTypeDropdownOptionsGroups);
    }, []);

    useEffect(() => {
        refreshCustomEntityTypesAsync();
    }, [refreshCustomEntityTypesAsync]);

    if(!creatingEntity) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose} extraClassNames={{container: styles.linkCreateEntityModal, header: styles.linkCreateEntityModalHeader }}>
            <div className={`${styles.entityTypeDropdownContainer} ${isCreatingEntity ? `${styles.isDisabled}` : ""}`}>
                <Dropdown
                    isEditable={true}    
                    selectedOption={{ value: currentEntityType.value, title: EntityTypeHelperSingleton.getEntityTypeDisplayName(currentEntityType.value, currentEntityType.title)}}
                    handleOptionSelect={(option: TOption<EntityTypeEnum>) => { setCurrentEntityType(option); }}
                    options={allEntityTypesDropdownOptions}
                    placeholderText="Select entity type"
                    className={commonDropdownStyles.commonDropdown}
                    classNameSelect={commonDropdownStyles.grayDropdownSelect} />
            </div>
            <MainTitle 
                extraClassName={isCreatingEntity ? `${styles.isDisabled}` : ""}
                placeholder="Enter entity title"
                title={creatingEntityTitle} 
                isEditable={true}
                onUpdateTitle={(newTitle: string) => { setCreatingEntityTitle(newTitle); }} />
            <div className={`${styles.connectedObjectContainer} ${isCreatingEntity ? `${styles.isDisabled}` : ""}`}>
                <h6>Connections</h6>
                <div className={styles.connectedObject}>
                    <Dropdown
                        selectedOption={linkType}
                        handleOptionSelect={(option) => setLinkType(option)}
                        options={
                            [{ options: [LinkingConstants.CHILD_LINK_TYPE, LinkingConstants.PARENT_LINK_TYPE] }]
                        }
                        placeholderText="Select link type"
                    />
                    {!currentSelectedObjectToLink &&
                        <>
                            <div ref={setConnectionsSpanElementReference} onClick={() => { setIsSearchPopupOpen(true); }} className={styles.connectToNewObject}>+ Select entity or study</div>
                            <Popover referenceEl={connectionsSpanElementReference} 
                                    isOpen={isSearchPopupOpen} 
                                    placement="bottom-start" 
                                    onClickOutside={() => { setIsSearchPopupOpen(false); }}
                                    extraClassName={styles.objectSearchPopupContainer}>
                                <ObjectSearchPopupContent 
                                    currentObjectId={""}
                                    onElementClick={onClickSearchResult} 
                                    doShowRecentActivity={true} 
                                    initialLinkedObjects={initialLinkedObjects}
                                    initialLinkedObjectsTitle="Query Connections" />
                            </Popover>
                        </>
                    }
                    {currentSelectedObjectToLink &&
                        <ConnectedObject
                            key={currentSelectedObjectToLink.id}
                            connectedObject={currentSelectedObjectToLink}
                            onRemoveConnectionClickAsync={async () => setCurrentSelectedObjectToLink(undefined)} />
                    }
                </div>
            </div>
            <div className={styles.actionbuttonsContainer}>
                <FindestButton 
                    extraClassName={isCreatingEntity ? `${styles.isDisabled}` : ""}
                    isDisabled={isCreateNewEntityButtonDisabled}
                    title="Create new entity" 
                    onClick={() => { onCreateEntityAsync(creatingEntityTitle); }} />
                <FindestButton 
                    extraClassName={isCreatingEntity ? `${styles.isDisabled}` : ""}
                    title="Cancel" 
                    onClick={handleClose} 
                    buttonType={"secondary"} />
                {(isCreatingEntity) && (<LoadingStatusIndicator size={32} status={1} />)}
            </div>
        </Modal>
    );
};