import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning, faClose } from "@fortawesome/free-solid-svg-icons";
import { useMaintenanceStatus } from "Hooks";
import { FindestButton } from "Components";
import { DateHelperSingleton } from "Helpers";
import styles from "./maintenanceStatusBanner.module.scss";

export const MaintenanceStatusBanner: React.FC = () => {
    const { maintenanceData, handleAcknowledge } = useMaintenanceStatus();

    return (
        <>
            {maintenanceData && maintenanceData.length > 0 ? (
                <div className={styles.maintenanceBanner}>
                    <div className={styles.maintenanceBannerInformationSection}>
                        <div className={styles.iconWrapper}>
                            <FontAwesomeIcon icon={faWarning} color="black" />
                        </div>
                        {maintenanceData.map(status => (
                            <div key={status.id}>
                                <p className={styles.maintenanceText}>
                                    Due to maintenance activity, your <strong>Universe will be unavailable from {DateHelperSingleton.formatUTCDate(status.startsAt)} to {status.endsAt ? DateHelperSingleton.formatUTCDate(status.endsAt) : "unknown"}</strong>
                                </p>
                                <p>
                                    You will be logged out, and need to log in after maintenance is over. All your work will be saved automatically.
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className={styles.bannerRight}>
                        <a target="_blank" href="https://status.findest.com" className={styles.moreInformation} rel="noreferrer">More information</a>
                        <FindestButton
                            leftIconName={faClose}
                            styleProps={["noWrap"]}
                            extraClassName={styles.dismissButton}
                            onClick={handleAcknowledge}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};
