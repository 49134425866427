// React
import { FC, useState } from "react";
// Types
import { TUserIconSize } from "Types";
// Components
import { UserIcon } from "Components";
// Styles
import styles from "./createdByAccount.module.scss";

type TCreatedByAccountProps = {
    email?: string,
    createdDate?: string,
    userIconSize?: TUserIconSize,
    extraClassNames?: { createdByAccountContainer: string }
}

export const CreatedByAccount: FC<TCreatedByAccountProps> = ({ email, createdDate, userIconSize, extraClassNames }: TCreatedByAccountProps) => {
    const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);

    if(!email) return null;

    return (
        <div className={`${styles.createdByAccountContainer} ${extraClassNames?.createdByAccountContainer || ""}`} onMouseEnter={() => { setIsDropdownShown(true); } } onMouseLeave={() => { setIsDropdownShown(false); } }>
            <UserIcon email={email} extraClassName={styles.userIcon} size={userIconSize}/>
            {isDropdownShown ? 
                <div className={styles.dropdownContainer}>
                    <p>Created by</p>
                    <p>{email}</p>
                    {createdDate && <p>{createdDate}</p>}
                </div>
            : null }
        </div>
    );
};