// node_modules
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { faLink, faTrashCan } from "@fortawesome/free-solid-svg-icons";
// Components
import { LinkingModal, ListHeader } from "Components";
import { EntityItem } from "./EntityItem";
// Enums
import { EntityTypeEnum, OrderByEnum, ObjectTypeEnum, OwnershipEnum, SortTypeEnum } from "Enums";
// Controllers
import { EntityControllerSingleton } from "Controllers";
// Types
import { TButtonDefinition, TEntitiesDTO, TOption, TOptions } from "Types";
// Styles
import styles from "./entities.module.scss";
import listHeaderStyles from "Components/Shared/Lists/ListHeader/listHeader.module.scss";
// Constants
import { EntityConstants, LinkingConstants } from "Constants";
// Helpers
import { EntityTypeHelperSingleton, FilterHelperSingleton, LogHelperSingleton, OwnershipHelperSingleton, SavedFiltersHelperSingleton } from "Helpers";
// Contexts
import { ElementVisibilityContext } from "Providers";
// Interfaces
import { IEntityDTO } from "Interfaces";
// Custom hooks
import { useCheckboxedList, useEntityNameChangeListener } from "Hooks";

// TODO: make one refresh function that takes in all the parameters
export const Entities: FC = () => {
    // Context
    const { canUserEdit } = useContext(ElementVisibilityContext);

    // State
    const [entities, setEntities] = useState<IEntityDTO[]>([]);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<(TOption<EntityTypeEnum | OwnershipEnum>)[]>([]);
    const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
    const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
    const [totalEntitiesCount, setTotalEntitiesCount] = useState<number>(0);
    const [lastPaginationFromDates, setLastPaginationFromDates] = useState<Date[]>([]);
    const [entityTypesDropdownOptionsWithoutCreate, setEntityTypesDropdownOptionsWithoutCreate] = useState<TOptions<EntityTypeEnum>[]>([]);

    // Memos
    const availableFilterOptions = useMemo(() => {
        let result: TOptions<EntityTypeEnum | OwnershipEnum>[] = [...entityTypesDropdownOptionsWithoutCreate];

        if(canUserEdit) {
            result = result.concat(...OwnershipHelperSingleton.ownershipFilterDropdownOptions);
        }

        return result;
    }, [entityTypesDropdownOptionsWithoutCreate, canUserEdit]);
    
    // Hooks
    const { selectedItems: selectedEntities, setSelectedItems: setSelectedEntities,
        areAllItemsSelected: areAllEntitiesSelected, isAnyItemSelected: isAnyEntitySelected,
        onSelectAllItems, onSelectItem
    } = useCheckboxedList<IEntityDTO>(entities.length, "Entity", "Entities",
        entity => ({ id: entity.id, type: EntityTypeHelperSingleton.getEntityTypeDisplayName(entity.type, entity.customTypeName), 
            name: entity.title, objectType: ObjectTypeEnum.Entity }));

    // Logic
    useEffect(() => {
        // get saved filters in local storage
        const savedFilters: TOption<(EntityTypeEnum | OwnershipEnum)>[] =
            SavedFiltersHelperSingleton.getEntitiesFilters();

        // if there are saved filters, set them as selected
        if (savedFilters.length > 0) {
            setSelectedFilterOptions(savedFilters);
        }

        (async () => {
            await refreshEntitiesAsync(
                undefined, 
                getSelectedTypeFilterOptions(savedFilters),
                SortTypeEnum.Newest,
                FilterHelperSingleton.getIsCreatedByMeSelected(savedFilters),
                getSelectedCustomTypeNames(savedFilters)
            );
        })();

        // log 
        LogHelperSingleton.log("DisplayEntities");

    }, []);

    useEffect(() => {
        (async () => {
            const entityTypesDropdownOptionsGroupsWithoutCreate = 
                await EntityTypeHelperSingleton.getCustomTypeDropdownOptionsGroupAsync(false, false);
            setEntityTypesDropdownOptionsWithoutCreate(entityTypesDropdownOptionsGroupsWithoutCreate);
        })();
    }, []);
    
    const selectedFilterOptionsForDropdown = useMemo(() => {
        const newSelectedFilterDropdownOptions: TOption<(EntityTypeEnum | OwnershipEnum)>[] = [];
        
        for (const selectedFilterOption of selectedFilterOptions) {
            if (EntityTypeHelperSingleton.allEntityTypes.includes(selectedFilterOption.value as EntityTypeEnum)) {
                newSelectedFilterDropdownOptions.push({value: selectedFilterOption.value, 
                    title: EntityTypeHelperSingleton
                        .getEntityTypeDisplayName(selectedFilterOption.value as EntityTypeEnum,
                            selectedFilterOption.title)});
            } else if ((selectedFilterOption.value as OwnershipEnum) === OwnershipEnum.Me) {
                newSelectedFilterDropdownOptions.push({
                    value: selectedFilterOption.value, 
                    title: OwnershipHelperSingleton.getOwnershipDisplayName(selectedFilterOption.value as OwnershipEnum),
                    customTitle: OwnershipHelperSingleton.getOwnershipCustomTitle(selectedFilterOption.value as OwnershipEnum)
                });
            }
        }

        return newSelectedFilterDropdownOptions;
    }, [selectedFilterOptions]);

    const getSelectedTypeFilterOptions = (filterOptions: TOption<(EntityTypeEnum | OwnershipEnum)>[]) => {
        const newSelectedTypesFilterOptionsForDropdown: EntityTypeEnum[] = [];

        for (const selectedFilterOption of filterOptions) {
            if (EntityTypeHelperSingleton.allEntityTypes.includes(selectedFilterOption.value as EntityTypeEnum) &&
                    !newSelectedTypesFilterOptionsForDropdown.includes(selectedFilterOption.value as EntityTypeEnum)) {
                newSelectedTypesFilterOptionsForDropdown.push(selectedFilterOption.value as EntityTypeEnum);
            }
        }

        return newSelectedTypesFilterOptionsForDropdown;
    };

    const getSelectedCustomTypeNames = (filterOptions: TOption<(EntityTypeEnum | OwnershipEnum)>[]) => {
        const currentSelectedCustomTypeNames: string[] = [];

        for (const filterOption of filterOptions) {
            if (filterOption.value === EntityTypeEnum.Custom) {
                currentSelectedCustomTypeNames.push(filterOption.title);
            }
        }

        return currentSelectedCustomTypeNames;
    };

    const onSelectAllCheckboxChange = (isChecked: boolean) => {
        onSelectAllItems(isChecked, entities);
    };

    const onEntityCheckboxChange = (isChecked: boolean, id: string): void => {
        const currentEntity = entities.find(entity => entity.id === id);
        if(!currentEntity) { return; }
        onSelectItem(isChecked, currentEntity, id);
    };

    const updateSortTypeAsync = async (newSortType: SortTypeEnum): Promise<void>  => {   
        // reset last pagination from dates
        setLastPaginationFromDates([]);

        // safety-checks
        if (newSortType === sortType) { return; }
        
        // set new sort type
        setSortType(newSortType);

        // update entities list
        await refreshEntitiesAsync(
            undefined, 
            getSelectedTypeFilterOptions(selectedFilterOptions),
            newSortType,
            FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
            getSelectedCustomTypeNames(selectedFilterOptions)
        );

        // reset selected entities
        setSelectedEntities([]);

        // log
        LogHelperSingleton.log("SortEntities");
    };

    const updateFilterOptionsAsync = async (action: "add" | "remove", 
            option: TOption<EntityTypeEnum | OwnershipEnum>): Promise<void> => {
        // reset last pagination from dates
        setLastPaginationFromDates([]);
        
        let newFilterOptions = [];
        
        if (action === "add") {
            newFilterOptions = [...selectedFilterOptions].concat([option]);
        } else {
            for (const selectedFilterOption of selectedFilterOptions) {
                if (option.value === EntityTypeEnum.Custom) { 
                    if (selectedFilterOption.value === option.value &&
                            selectedFilterOption.title === option.title) {
                        continue;
                    }
                } else {
                    if (selectedFilterOption.value === option.value) {
                        continue;
                    }
                }
                newFilterOptions.push(selectedFilterOption);
            }
        }

        setSelectedFilterOptions(newFilterOptions);
        
        // save filters in local storage
        SavedFiltersHelperSingleton.saveEntitiesFilters(newFilterOptions);

        // update entities list
        await refreshEntitiesAsync(
            undefined, 
            getSelectedTypeFilterOptions(newFilterOptions),
            sortType,
            FilterHelperSingleton.getIsCreatedByMeSelected(newFilterOptions),
            getSelectedCustomTypeNames(newFilterOptions)
        );

        // reset selected entities
        setSelectedEntities([]);

        // log
        LogHelperSingleton.log("FilterEntities");
    };

    const onPaginatePreviousAsync = async (): Promise<void> => {
        // get new from date
        let fromDate: Date | undefined = undefined;
        if (lastPaginationFromDates && lastPaginationFromDates.length > 0) { 
            lastPaginationFromDates.pop();
            if (lastPaginationFromDates.length >= 1) {
                fromDate = lastPaginationFromDates[lastPaginationFromDates.length - 1];
            }
            setLastPaginationFromDates(lastPaginationFromDates);
        }

        // update entities list
        await refreshEntitiesAsync(
            fromDate, 
            getSelectedTypeFilterOptions(selectedFilterOptions),
            sortType,
            FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
            getSelectedCustomTypeNames(selectedFilterOptions)
        );

        // reset selected entities
        setSelectedEntities([]);

        // log
        LogHelperSingleton.log("GoToPreviousEntitiesPage");
    };

    const onPaginateNextAsync = async (): Promise<void> => {
        // get new from date
        let fromDate: Date | undefined = undefined;
        if (entities && entities.length > 0) { 
            const lastPaginationFromDate: Date = entities[entities.length -1].dateAdded;
            fromDate = lastPaginationFromDate;
            lastPaginationFromDates.push(fromDate);
            setLastPaginationFromDates(lastPaginationFromDates);
        }

        // update entities list
        await refreshEntitiesAsync(
            fromDate, 
            getSelectedTypeFilterOptions(selectedFilterOptions),
            sortType,
            FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
            getSelectedCustomTypeNames(selectedFilterOptions)
        );

        // reset selected entities
        setSelectedEntities([]);

        // log
        LogHelperSingleton.log("GoToNextEntitiesPage");
    };

    const onDeleteEntitiesAsync = useCallback(async (entitiesToDelete: IEntityDTO[]): Promise<void> => {
        // safety-checks
        if (!entitiesToDelete || entitiesToDelete.length < 1) { return; }

        // Confirm with the user that they want to delete the entities
        if (entitiesToDelete.length === 1) {
            if (!confirm(EntityConstants.DELETE_ENTITY_CONFIRMATION)) return;
        } else {
            if (!confirm(EntityConstants.DELETE_ENTITIES_CONFIRMATION)) return;
        }

        // bulk delete entities
        await EntityControllerSingleton
            .bulkDeleteAsync(entitiesToDelete.map(entity => entity.id));

        // update entities list
        await refreshEntitiesAsync(
            undefined, 
            getSelectedTypeFilterOptions(selectedFilterOptions),
            sortType,
            FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
            getSelectedCustomTypeNames(selectedFilterOptions)
        );

        // reset selected entities
        setSelectedEntities([]);
        
        // set total entities count
        setTotalEntitiesCount(totalEntitiesCount - entitiesToDelete.length);

        // log
        LogHelperSingleton.log("RemoveEntity(ies)");
    }, [selectedFilterOptions, setSelectedEntities, sortType, totalEntitiesCount]);

    const onDeleteClickAsync = useCallback(async (): Promise<void> => {
        // safety-checks
        if (selectedEntities.length === 0) { return; }
        
        // deleted selected entities
        await onDeleteEntitiesAsync(entities.filter(entity => 
            selectedEntities.find(selectedEntity => selectedEntity.id === entity.id) !== undefined));
    }, [entities, onDeleteEntitiesAsync, selectedEntities]);

    const refreshEntitiesAsync = async (fromDate: Date | undefined,  
            entityTypes: EntityTypeEnum[], currentSortType: SortTypeEnum,
            createdByMe: boolean, customTypeNames: string[]): Promise<void> => {
            
        const newEntities: TEntitiesDTO = await EntityControllerSingleton.getAsync(
            currentSortType === SortTypeEnum.Oldest ? OrderByEnum.Ascending : OrderByEnum.Descending,
            fromDate,
            entityTypes,
            createdByMe,
            customTypeNames
        );

        // set state variables
        setEntities(newEntities.entities);
        setTotalEntitiesCount(newEntities.totalEntitiesCount);
    };

    const onLinkEntity = (entity: IEntityDTO) => {
        setSelectedEntities([{ id: entity.id, name: entity.title, 
            type: EntityTypeHelperSingleton.getEntityTypeDisplayName(entity.type, entity.customTypeName),
            objectType: ObjectTypeEnum.Entity }]);
        setIsLinkModalOpen(true);
        // log
        LogHelperSingleton.log("StartLinkingEntity");
    };

    const onLinkEntitiesClick = () => {
        setIsLinkModalOpen(true);
        // log
        LogHelperSingleton.log("StartLinkingEntity(ies)");
    };

    const onLinkingDoneAsync = useCallback(async (isLinkingDone: boolean): Promise<void> => {
        // if linking is done
        if (isLinkingDone) {
            // refresh entities list
            await refreshEntitiesAsync(lastPaginationFromDates[lastPaginationFromDates.length-1], 
                getSelectedTypeFilterOptions(selectedFilterOptions), 
                sortType,
                FilterHelperSingleton.getIsCreatedByMeSelected(selectedFilterOptions),
                getSelectedCustomTypeNames(selectedFilterOptions));
            // set selected entities to empty
            setSelectedEntities([]);
        }
    }, [lastPaginationFromDates, selectedFilterOptions, setSelectedEntities, sortType]);

    // Hooks live update the Entities name
    useEntityNameChangeListener(setEntities);

    const entitiesHeaderButtons = useMemo(() => {
        return [
            {
                title: "Link",
                icon: faLink,
                onClick: onLinkEntitiesClick,
                className: listHeaderStyles.linkIcon
            },
            {
                title: "Delete",
                icon: faTrashCan,
                onClick: onDeleteClickAsync,
                className: listHeaderStyles.trashIcon
            }
        ] as TButtonDefinition[];
    }, [onDeleteClickAsync]);

    return (
        <div className={styles.entitiesContainer}>
            <div className={styles.entities}>
                <ListHeader
                    isAllListItemsSelected={areAllEntitiesSelected}
                    isAnyListItemSelected={isAnyEntitySelected}
                    onSelectAllCheckboxChange={canUserEdit ? onSelectAllCheckboxChange : undefined}
                    selectedFilterOptions={selectedFilterOptionsForDropdown}
                    updateFilterOptions={updateFilterOptionsAsync}
                    sortType={sortType}
                    updateSortType={updateSortTypeAsync}
                    totalListItemCount={totalEntitiesCount}
                    onPaginatePrevious={onPaginatePreviousAsync}
                    onPaginateNext={onPaginateNextAsync}
                    filterOptions={availableFilterOptions}
                    listItemCountInterval={EntityConstants.MAXIMUM_ENTITIES_TO_RETRIEVE}
                    buttonDefinitions={entitiesHeaderButtons}
                />
                <div className={styles.entitiesList}>
                    {entities.map((entity: IEntityDTO) => {
                        const isSelected = selectedEntities.find(selectedEntity => selectedEntity.id === entity.id) !== undefined;
                        return (
                            <EntityItem
                                key={entity.id}
                                entity={entity}
                                isSelected={isSelected}
                                onCheckboxChange={canUserEdit ? onEntityCheckboxChange : undefined}
                                onLinkEntityClick={canUserEdit ? onLinkEntity : undefined}
                                onDeleteEntityClick={canUserEdit ? (entityToDelete: IEntityDTO) => onDeleteEntitiesAsync([entityToDelete]) : undefined}
                            />
                        );
                    })}
                </div>
            </div>
            <LinkingModal 
                isOpen={isLinkModalOpen}
                setIsOpen={setIsLinkModalOpen}
                selectedObjects={selectedEntities}
                onLinkingDoneAsync={onLinkingDoneAsync} 
                defaultLinkType={LinkingConstants.CHILD_LINK_TYPE} />
        </div>
    );
};