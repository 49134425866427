// node_modules
import { FC, useCallback, useEffect, useState } from "react";
// Components
import { Popover } from "Components";
import { ObjectsRatingItem } from "./ObjectsRatingItem";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Controllers
import { RatingControllerSingleton } from "Controllers";
// Types
import { TObjectDetailDTO, TObjectsByUserIdDTO } from "Types";
// Styles
import styles from "./objectsRatingsPopover.module.scss";

// Props type
type TObjectsRatingsPopoverProps = {
    onNewAverageRating: (newRatings: TObjectDetailDTO[]) => void,
    objectId: string,
    objectType: ObjectTypeEnum,
    isOpen: boolean,
    currentUserEmail: string,
    ratingStarRef: HTMLDivElement | null,
    onMouseEnter?: () => void,
    onMouseLeave?: () => void
};

// Component
export const ObjectsRatingsPopover: FC<TObjectsRatingsPopoverProps> = ({ onNewAverageRating, objectId, objectType, isOpen, currentUserEmail, ratingStarRef, onMouseEnter, onMouseLeave }: TObjectsRatingsPopoverProps) => {
    // State
    const [ratings, setRatings] = useState<TObjectDetailDTO[]>([]);

    // Custom Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    // Logic
    const refreshRatings = useCallback(async (id: string, type: ObjectTypeEnum) => {
        // get ratings
        const entityRatings: TObjectsByUserIdDTO | undefined = await RatingControllerSingleton
            .getObjectByUserIdAndTargetIdAsync(id, ObjectTypeHelperSingleton.getObjectTypeDisplayName(type).toLowerCase());

        // safety-checks
        if (!entityRatings || entityRatings.sources.length === 0) {
            // do nothing, return
            return;
        }

        // set ratings
        setRatings(entityRatings.sources);
    }, []);

    useEffect(() => {
        // refresh ratings when object id and type changes
        refreshRatings(objectId, objectType);
    }, [objectId, objectType, refreshRatings]);


    const onNewAverageRatingHandler = useCallback((prevRatings: TObjectDetailDTO[], forSourceId: string, newCount: number, newScore: number, newIsRatingNeeded: boolean) => {
        // go through ratings
        for (const prevRating of prevRatings) {
            // if prev rating id is for source id
            if (prevRating.id === forSourceId) {
                // update rating
                prevRating.averageRating.count = newCount;
                prevRating.averageRating.score = newScore;
                prevRating.isRatedByCurrentUser = !newIsRatingNeeded;
            }
        }
        
        // set ratings
        setRatings(prevRatings);

        // call on new average rating
        onNewAverageRating(prevRatings);
    }, [onNewAverageRating]);

    const openReferenceModal = (oId: string, oType: ObjectTypeEnum) => {
        setReferenceModalProps((previousReferenceModalProps) => {
            return {
                ...previousReferenceModalProps,
                isOpen: true,
                id: oId,
                type: oType
            };
        });
    };

    // Render
    return (
        <>
            <Popover
                showInPortal
                referenceEl={ratingStarRef}
                isOpen={isOpen}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                popoverOffset={0}
                placement="bottom-start"
                extraClassName={styles.objectRatingPopoverContainer}
            >
                <div className={styles.objectRatingPopover}>
                    <h3 className={styles.title}>Ratings</h3>
                    {ratings.map((rating: TObjectDetailDTO) => {
                        return (
                            <ObjectsRatingItem
                                key={rating.id}
                                ratings={ratings}
                                rating={rating}
                                currentUserEmail={currentUserEmail}
                                objectId={objectId}
                                onNewAverageRatingHandler={onNewAverageRatingHandler}
                                onMoreActionsOptionClick={onMouseLeave}
                                openReferenceModal={openReferenceModal}
                            />
                        );
                    })}
                </div>
            </Popover>
            {referenceModal}
        </>
    );
};
