// node_modules
import { FC } from "react";
// Styles
import styles from "./radioButton.module.scss";

type TRadioButtonProps = {
    checked: boolean,
    onChange: () => void,
    text?: string,
    value: any
};

export const RadioButton: FC<TRadioButtonProps> = ({
    onChange,
    checked,
    text,
    value
}: TRadioButtonProps) => (
    <label className={styles.container}>
        {text && <span className={styles.text}>{text}</span>}
        <div className={styles.buttonContainer}>
            <input value={value} onChange={onChange} type="radio" checked={checked} name="radio" />
            <span className={styles.checkmark}></span>
        </div>
    </label>
);