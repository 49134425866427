export const FeatureToggleConstants = {
    CreateDocumentFromFile: false, // Disabled to make adding random documents from modal a "quick" win
    SynonymGroups: false,
    UnitSearching: false,
    TableOptions: true,
    GroupedSearch: false,
    ReferenceModal: true,
    DeletedArchive: true,
    SearchBarCount: false,
    InlineReferencing: true,
    OverviewTable: true,
    BlockReferencing: false,
    MaturityRadar: true,
    RequirementsTable: true,
    TIAutomation: true,
    USEFeatures: true,
    // User management related features
    AdminUserManagement: true,
    AdminManageSharedPage: true,
    AdminLockedObjectsManagement: true,
    AdminTypeManagement: false,
    PageLocking: true,
    // Galaxy related features
    GalaxiesManagement: false,
    // New features we are not working on yet
    QueryPreview: false,
    QuerySearchResultsButton: false,
} as const;