// React
import { FC } from "react";
// Types
import { TRecentSidebarActivityItemDTO, fromTRecentSidebarActivityItemDTO } from "Types";
// Components
import { ListObjectItem } from "Components";
// Helpers
import { ActivityTypeHelperHelperSingleton } from "Helpers";
// Enums
import { ObjectTypeEnum } from "Enums";

type TSearchRecentEntityActivitiesProps = {
    headerName: string,
    keyName: string,
    navigationPathPrefix?: string,
    mySimpleRecentActivity: TRecentSidebarActivityItemDTO[],
    resetSearchBar: (doHideSearchResults: boolean, clearTextbox: boolean) => void,
    onObjectClick: (id: string, objectType: ObjectTypeEnum, objectName: string) => void,
    extraClassname?: string,
    openReferenceModal?: (objectId: string, objectType: ObjectTypeEnum) => void,
    navigateCallback?: () => void,
    moreActionsDropdownPopoverDataIdentifier?: string,
    moreActionsDropdownOnCloseCallback?: () => void
};

export const SearchRecentActivities: FC<TSearchRecentEntityActivitiesProps> = ({ headerName,
        mySimpleRecentActivity, onObjectClick, keyName, extraClassname,
        openReferenceModal, navigateCallback, moreActionsDropdownPopoverDataIdentifier,
        moreActionsDropdownOnCloseCallback }) => {
    return (
        <>  
            <h6>Recent {headerName} activity</h6>
            {mySimpleRecentActivity.map((recentActivity: TRecentSidebarActivityItemDTO) => {
                return (
                    <ListObjectItem 
                        key={`recent-${keyName}-${recentActivity.id}`}
                        onClick={() => onObjectClick(recentActivity.id, recentActivity.objectType, recentActivity.name)}
                        iconHasColor={true}
                        object={fromTRecentSidebarActivityItemDTO(recentActivity)}
                        subItemType={ActivityTypeHelperHelperSingleton.activityEnumToDisplayString(recentActivity.activityType)}
                        extraClassName={extraClassname}
                        openReferenceModal={openReferenceModal}
                        moreActionsDropdownPopoverDataIdentifier={moreActionsDropdownPopoverDataIdentifier}
                        moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                        navigateCallback={navigateCallback}
                    />
                );
            })}
        </>
    );
};