// Enums
import { PermissionsEnum, RolesEnum } from "Enums";
// Types
import { TTenantDTO, TUserDetailsUser } from "Types";

export type TUserDTO = {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    username: string,
    galaxyIds: string[],
    roles: RolesEnum[],
    tenants: TTenantDTO[],
    permissions: PermissionsEnum[]
}

export const fromTUserDetailsUserToTUserDTO = (user: TUserDetailsUser): TUserDTO => {
    // init user dto
    const userDTO: TUserDTO = {
        ...user,
        roles: user.roles as RolesEnum[],
        tenants: []
    };

    // return user dto
    return userDTO;
};
