// React
import { FC, useRef, useState } from "react";
// Modal
import { FindestButton, FindestTextBox, Modal, UserDetailsTable } from "Components";
// Controllers
import { UserControllerSingleton } from "Controllers";
// Enums
import { PermissionsEnum, ToastTypeEnum, UniverseRolesEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Types
import { TCreateUserDTO, TUserDetailsUser, fromTUserDetailsUserToTCreateUserDTO, fromTCreateUserDTOToTUserDetailsUser } from "Types";
// Styles
import styles from "../../../Settings/Users/users.module.scss";

export type TCreateUserModalProps = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    onCreateCallback?: () => void
};

export const CreateUserModal: FC<TCreateUserModalProps> = ({ isOpen, setIsOpen, onCreateCallback }) => {
    // Ref
    const formRef = useRef<HTMLFormElement>(null);
    // State
    const [usersToCreate, setUsersToCreate] = useState<TCreateUserDTO[]>([]);
    const [newUserEmail, setNewUserEmail] = useState<string>("");

    const resetModalStateAndClose = () => {
        setIsOpen(false);
        setUsersToCreate([]);
        setNewUserEmail("");
    };

    const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        addUserButtonClick();
    };

    const onRoleChange = (user: TCreateUserDTO, newRole: UniverseRolesEnum) => {
        setUsersToCreate(usersToCreate.map((userToCreate) => {
            if(userToCreate.email === user.email) {
                userToCreate.role = newRole;
            }
            return userToCreate;
        }));
    };

    const onAdvancedCheckboxChange = async (isChecked: boolean, user: TCreateUserDTO) => {
        // set users to create
        setUsersToCreate(usersToCreate.map((userToCreate) => {
            // if the user is the same as the one we are looking for
            if(userToCreate.email === user.email) {
                // depending on is checked
                if(isChecked) {
                    // add the permission
                    userToCreate.permissions = [{
                        value: PermissionsEnum.Advanced
                    }];
                } else {
                    // remove the permission
                    userToCreate.permissions = [];
                }
            }
            // return the user
            return userToCreate;
        }));
    };
    
    const addUserButtonClick = () => {
        if(!formRef.current) return;

        // Check if the inserted email is valid
        const isEmailValid = formRef.current.checkValidity();

        // If the email is not valid, show an error message
        if(!isEmailValid) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Please insert a valid email address.");
            return;
        }

        // Check if the email is already in the list
        const isEmailAlreadyInList = usersToCreate.some((user) => 
            user.email.toLowerCase().trim() === newUserEmail.toLowerCase().trim());

        // If the email is already in the list, show an error message
        if(isEmailAlreadyInList) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Email already in list.");
            return;
        }
      
        // Add the new user to the list
        setUsersToCreate([...usersToCreate, { email: newUserEmail, permissions: [], role: UniverseRolesEnum.Viewer } as TCreateUserDTO]);
        setNewUserEmail("");
    };

    const onCreateUsers = async () => {
        // Check if all users have a role
        const isEveryUserHasRole = usersToCreate.every((user) => user.role);

        // Check if not every user has a role, then show an error message
        if(!isEveryUserHasRole) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Please select a role for every user.");
            return;
        }

        // Variable to hold emails of accounts that were not created
        const failedToCreateEmails = new Set<string>();

        // Loop over the users and try to create all of them
        for(const user of usersToCreate) {
            // Create the user and check if it was successful
            const isUserCreated = await UserControllerSingleton.createUserAsync(user.email, user.role, user.permissions);
            if(!isUserCreated) {
                failedToCreateEmails.add(user.email);
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, `Failed to create user ${user.email}.`);
            }
        }

        // Only keep the failed to create users in the list
        setUsersToCreate(usersToCreate.filter((user) => failedToCreateEmails.has(user.email)));

        // Refresh the users
        if(onCreateCallback) onCreateCallback();

        // If all users were created, close the modal
        if(failedToCreateEmails.size === 0) resetModalStateAndClose();
    };

    // Convert TCreateUserDTO to TUserDetailsUser to match the user type in UserDetailsTable component
    const convertTCreateUsersDTOToTUserDetailsUsers = (users: TCreateUserDTO[]): TUserDetailsUser[] => {
        return users.map((user) => fromTCreateUserDTOToTUserDetailsUser(user));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={resetModalStateAndClose} 
            header="Add new user"
            extraClassNames={{ container: styles.createUserModalContainer }}
        >
            <div className={styles.modalBody}>
                <div className={styles.addPeopleFormContainer}>
                    <form className={styles.addPeopleContainer} ref={formRef} onSubmit={onFormSubmit}>
                        <FindestTextBox placeholder="Add people by email" value={newUserEmail} onChange={setNewUserEmail} type={"email"} extraClassNameInputElement={styles.emailInput}/>
                        <FindestButton extraClassName={styles.addEmailButton} isDisabled={newUserEmail.length === 0} title="Add" onClick={addUserButtonClick}/> 
                    </form>
                </div>
                <UserDetailsTable
                    extraClassNames={{ container: styles.userDetailsTableContainer }}
                    users={convertTCreateUsersDTOToTUserDetailsUsers(usersToCreate)}
                    onRoleChange={(user, role) => onRoleChange(fromTUserDetailsUserToTCreateUserDTO(user), role)}
                    onAdvancedCheckboxChange={(isChecked, user) => { onAdvancedCheckboxChange(isChecked, fromTUserDetailsUserToTCreateUserDTO(user)); }}
                />
            </div>
            <div className={styles.modalFooter}>
                <FindestButton isDisabled={usersToCreate.length === 0} title="Create" onClick={onCreateUsers} />
                <FindestButton extraClassName={styles.cancelButton} buttonType="secondary" title="Cancel" onClick={resetModalStateAndClose} />
            </div>
        </Modal>
    );
};