// node_modules
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// Components
import {
  Dropdown,
  DropdownButton,
  FindestButton,
  ListObjectItem,
  SuggestingTextbox,
} from "Components/Shared";
// Controllers
import {
  ActivityControllerSingleton,
  SearchControllerSingleton,
} from "Controllers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType, TOption } from "Types";
// Components
import { Modal } from "../Modal";
// Styles
import sharedModalStyles from "../modal.module.scss";
import styles from "./linkingModal.module.scss";
// Helpers
import {
  LinkingHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
} from "Helpers";
// Contexts
import { DocumentContext } from "Providers";
// Constants
import { LinkingConstants } from "Constants";

type TLinkingModalProps = {
  linkToName?: string;
  onClose?: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onLinkingDoneAsync?: (
    isLinkingDone: boolean,
    selectedLinkToObject?: TIdNameTypeObjectType | undefined,
    linkType?: string | undefined
  ) => Promise<void>;
  selectedObjects: TIdNameTypeObjectType[];
  excludedSearchTypes?: ObjectTypeEnum[];
  defaultLinkType: TOption<string>;
  forcedLinkType?: TOption<string>;
  onSaveClickCallback?: (selectedLinkToObject: TIdNameTypeObjectType) => void;
};

const allSearchTypes = [ObjectTypeEnum.Study, ObjectTypeEnum.Entity];

export const LinkingModal: FC<TLinkingModalProps> = ({
  linkToName,
  onClose,
  isOpen,
  setIsOpen,
  onLinkingDoneAsync,
  excludedSearchTypes,
  selectedObjects,
  defaultLinkType,
  forcedLinkType,
  onSaveClickCallback,
}: TLinkingModalProps) => {
  // Contexts
  const { refreshDocumentsNotLinkedCount } = useContext(DocumentContext);

  // State
  const [suggestions, setSuggestions] = useState<TIdNameTypeObjectType[]>([]);
  const [isRecentActivityVisible, setIsRecentActivityVisible] =
    useState<boolean>(false);
  const [linkType, setLinkType] = useState<TOption<string>>(
    forcedLinkType ?? defaultLinkType
  );
  const [selectedLinkToObject, setSelectedLinkToObject] =
    useState<TIdNameTypeObjectType | undefined>(undefined);
  const [
    isSuggestingTextboxSuggestionsShown,
    setIsSuggestingTextboxSuggestionsShown,
  ] = useState<boolean>(false);

  // Ref
  const currentNameRef = useRef<string>("");

  // Logic
  // Retrieve recent activity on open
  useEffect(() => {
    if (forcedLinkType) {
      setLinkType(forcedLinkType);
    } else {
      setLinkType(defaultLinkType);
    }
  }, [forcedLinkType, defaultLinkType]);

  useEffect(() => {
    if (isOpen && !linkToName) {
      retrieveRecentActivityAsync();
      setIsRecentActivityVisible(true);
    }
  }, [isOpen, linkToName]);

  // Logic
  const searchTypes = useMemo(() => {
    let filteredSearchTypes: ObjectTypeEnum[] = allSearchTypes;
    if (excludedSearchTypes) {
      filteredSearchTypes = allSearchTypes.filter(
        (type) => !excludedSearchTypes.includes(type)
      );
    }
    return filteredSearchTypes;
  }, [excludedSearchTypes]);

  const searchTypeStrings = useMemo(() => {
    return searchTypes.map((type) =>
      ObjectTypeHelperSingleton.getObjectTypeDropdownButtonAction(type)
    );
  }, [searchTypes]);

  const getIsCreateButtonEnabled = useCallback((currentName: string) => {
    return currentName.length > 0;
  }, []);

  const retrieveRecentActivityAsync = async () => {
    const recentActivity =
      await ActivityControllerSingleton.getMySimpleActivityAsync();
    if (!recentActivity) {
      setSuggestions([]);
      return;
    }

    setSuggestions(recentActivity);
  };

  const runSuggestionsSearchAsync = useCallback(
    async (
      suggestionValue: string,
      currentSearchTypes: ObjectTypeEnum[]
    ): Promise<void> => {
      // set current name state variable
      setIsRecentActivityVisible(false);

      // set current name ref
      currentNameRef.current = suggestionValue;

      // safety-checks
      if (!suggestionValue) {
        // show recent activity results when suggestionValue is empty
        retrieveRecentActivityAsync();
        setIsRecentActivityVisible(true);
        return;
      }

      // search for suggestions
      const foundSuggestions =
        await SearchControllerSingleton.searchMultipleObjectsAsync(
          suggestionValue,
          currentSearchTypes
        );

      // safety-checks
      if (!foundSuggestions) {
        // reset suggestions state variable
        setSuggestions([]);
        return;
      }

      // set suggestions state variable
      setSuggestions(foundSuggestions);
    },
    []
  );

  // if linkToName is set, use it as the current name and trigger suggestions search
  useEffect(() => {
    // if linkToName is set
    if (linkToName) {
      // set current name ref variable
      currentNameRef.current = linkToName;
      // trigger suggestions search
      runSuggestionsSearchAsync(linkToName, searchTypes);
    }
  }, [linkToName, runSuggestionsSearchAsync, searchTypes]);

  const onSuggestionClick = (suggestion: TIdNameTypeObjectType) => {
    // safety-checks
    if (!selectedObjects) return;

    setSelectedLinkToObject(suggestion);
  };

  const onModalRequestClose = () => {
    if (isSuggestingTextboxSuggestionsShown) {
      return;
    }
    resetModalStateAndCloseAsync(false);
  };

  const resetModalStateAndCloseAsync = async (
    isLinkingDone: boolean,
    currentSelectedLinkToObject?: TIdNameTypeObjectType
  ) => {
    // call parent callbacks
    if (onLinkingDoneAsync) {
      onLinkingDoneAsync(
        isLinkingDone,
        currentSelectedLinkToObject,
        linkType.value
      );
    }
    setIsOpen(false);

    // reset component state
    setSuggestions([]);
    currentNameRef.current = "";
    setSelectedLinkToObject(undefined);

    // refresh documents not linked count
    refreshDocumentsNotLinkedCount();

    // log
    LogHelperSingleton.log("StopLinking");

    // if onClose is set, call it
    if (onClose) {
      onClose();
    }
  };

  const clickCreateOption = async (option: string) => {
    if (!selectedObjects) return;
    option = option.toLowerCase();

    // create object
    const createdObject: TIdNameTypeObjectType | undefined =
      await ObjectTypeHelperSingleton.createObject(
        option,
        currentNameRef.current
      );

    // safety-checks
    if (!createdObject) {
      // stop execution, return
      return;
    }

    // link selected objects and created object
    const isLinkingDone: boolean = await linkingFromModalAsync(
      createdObject.id,
      createdObject.objectType
    );

    // reset state and close modal
    await resetModalStateAndCloseAsync(isLinkingDone, createdObject);

    if (onSaveClickCallback) {
      onSaveClickCallback(createdObject);
    }
  };

  const linkingFromModalAsync = async (
    objectId: string,
    objectType: ObjectTypeEnum
  ): Promise<boolean> => {
    return await LinkingHelperSingleton.linkAsync(
      objectId,
      objectType,
      selectedObjects,
      linkType.value,
      () => {
        setIsOpen(false);
      }
    );
  };

  const onSaveClick = async () => {
    // safety-checks
    if (selectedLinkToObject) {
      // link selected objects and suggestion
      const isLinkingDone: boolean = await linkingFromModalAsync(
        selectedLinkToObject.id,
        selectedLinkToObject.objectType
      );

      // log
      LogHelperSingleton.log("StructureGraphLinkFromModal");
      LogHelperSingleton.log("CreatedLinkBetweenExistingFromLinkingModal");

      // reset state and close modal
      await resetModalStateAndCloseAsync(isLinkingDone, selectedLinkToObject);

      // if onSaveClickCallback is set, call it
      if (onSaveClickCallback) {
        onSaveClickCallback(selectedLinkToObject);
      }
    }
  };

  const doForceShowSuggestions = useMemo((): boolean => {
    // do force show suggestions if recent activity is visible
    // or linkToName is set (means we opened the linking modal through save as entity menu option in SelectedTextActionsPopup)
    if (isRecentActivityVisible || linkToName) {
      return true;
    } else {
      return false;
    }
  }, [isRecentActivityVisible, linkToName]);

  const forcedSuggestionValue = useMemo((): string | undefined => {
    // if linkToName is set, use it as forced suggestion value
    return linkToName ?? undefined;
  }, [linkToName]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => resetModalStateAndCloseAsync(false)}
      onRequestClose={onModalRequestClose}
      header="Add link"
    >
      <div className={sharedModalStyles.section}>
        <div className={sharedModalStyles.title}>Add a link from</div>
        <div className={styles.objectsContainer}>
          <div className={styles.objectsSecondContainer}>
            {selectedObjects?.map((object, index) => (
              <ListObjectItem
                key={object.id}
                object={object}
                iconHasColor={true}
                subItemType={object.type}
                extraClassName={styles.objectItem}
                navigateCallback={() => resetModalStateAndCloseAsync(false)}
              >
                <>
                  {index === 0 && selectedObjects.length - 1 > 0 && (
                    <span className={styles.otherSelectedObjectsCount}>
                      {`+ ${selectedObjects.length - 1}`}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  )}
                </>
              </ListObjectItem>
            ))}
          </div>
        </div>
      </div>
      <div className={sharedModalStyles.section}>
        <div className={sharedModalStyles.title}>Link Type</div>
        <Dropdown
          selectedOption={forcedLinkType ?? linkType}
          handleOptionSelect={(option) => setLinkType(option)}
          options={
            forcedLinkType
              ? [{ group: "1", options: [forcedLinkType] }]
              : [
                  {
                    group: "1",
                    options: [
                      LinkingConstants.CHILD_LINK_TYPE,
                      LinkingConstants.PARENT_LINK_TYPE,
                    ],
                  },
                ]
          }
          placeholderText="Select link type"
          extraClassNames={{
            groupedList: {
              groupTitle: styles.dropdownGroupTitle,
            },
          }}
        />
      </div>
      <div className={sharedModalStyles.section}>
        <div className={sharedModalStyles.title}>Name</div>
        <SuggestingTextbox
          forcedSuggestionValue={forcedSuggestionValue}
          placeholder="Enter name"
          title={
            isRecentActivityVisible
              ? "Recently updated objects"
              : "Universe suggestions"
          }
          onValueChangeHandler={(value) => (currentNameRef.current = value)}
          refreshSuggestionsAsync={(newValue: string) =>
            runSuggestionsSearchAsync(newValue, searchTypes)
          }
          suggestions={suggestions}
          handleSuggestionClick={onSuggestionClick}
          doForceShowSuggestions={doForceShowSuggestions}
          selectedOption={selectedLinkToObject}
          setSelectedOption={setSelectedLinkToObject}
          updateParentIsSuggestionsShown={
            setIsSuggestingTextboxSuggestionsShown
          }
          navigateCallback={() => resetModalStateAndCloseAsync(false)}
        />
      </div>
      <div className={sharedModalStyles.footer}>
        {selectedLinkToObject ? (
          <FindestButton
            extraClassName={styles.saveButton}
            title="Save"
            onClick={onSaveClick}
          />
        ) : (
          <DropdownButton
            isButtonEnabled={getIsCreateButtonEnabled(currentNameRef.current)}
            optionLabels={searchTypeStrings}
            onClickOption={clickCreateOption}
            extraClassNames={{ dropdownButton: styles.createButton }}
            buttonText="create new"
          />
        )}
        <FindestButton
          buttonType="cancel"
          title="Cancel"
          onClick={() => resetModalStateAndCloseAsync(false)}
          extraClassName={styles.cancelButton}
        />
      </div>
    </Modal>
  );
};
