// React
import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
// Enums
import { QueryViewOptionsEnum } from "Enums";
// Helpers
import { QueryViewOptionsHelperSingleton } from "Helpers";
// Types
import { TQueryViewOptions } from "Types";

type TQueryViewOptionsContext = {
    allQueryViewOptions: TQueryViewOptions,
    selectedViewOptions: Set<QueryViewOptionsEnum>,
    setSelectedViewOptions: (selectedViewOptions: Set<QueryViewOptionsEnum>) => void
};

export const defaultQueryViewOptionsContext: TQueryViewOptionsContext = {
    allQueryViewOptions: {
        isAffiliationViewOptionChecked: true,
        isAssigneeViewOptionChecked: true,
        isAuthorViewOptionChecked: true,
        isCitationScoreViewOptionChecked: true,
        isFilingDateViewOptionChecked: true,
        isInventorViewOptionChecked: true,
        isPatentNumberViewOptionChecked: true,
        isPatentCountryViewOptionChecked: true,
        isMatchedTermsViewOptionChecked: true,
        isPublicationDatePatentViewOptionChecked: true,
        isPublicationDateScienceViewOptionChecked: true,
        isRelevanceScoreViewOptionChecked: true,
        isAlreadyReadViewOptionChecked: true,
        isPublicationDateViewOptionChecked: true
    },
    selectedViewOptions: new Set(),
    setSelectedViewOptions: () => { return; }
};

type TQueryViewOptionsProviderProps = {
    children?: ReactNode
};

export const QueryViewOptionsContext = createContext<TQueryViewOptionsContext>(defaultQueryViewOptionsContext);

export const QueryViewOptionsProvider = ({children}: TQueryViewOptionsProviderProps) => {
    // State
    const [selectedViewOptions, setSelectedViewOptions] = useState<Set<QueryViewOptionsEnum>>(new Set());
    // Memos
    const allQueryViewOptions = useMemo(() => QueryViewOptionsHelperSingleton.getAllViewOptions(selectedViewOptions), 
        [selectedViewOptions]);

    // UseEffect
    useEffect(() => {
        setSelectedViewOptions(new Set(QueryViewOptionsHelperSingleton.getActivatedViewOptions()));
    }, []);

    // Create provider value
    const providerValue: TQueryViewOptionsContext = useMemo(() => {
        return {
            allQueryViewOptions,
            selectedViewOptions,
            setSelectedViewOptions
        };
    }, [allQueryViewOptions, selectedViewOptions, setSelectedViewOptions]);

    // Render
    return (
        <QueryViewOptionsContext.Provider value={providerValue}>
            {children}
        </QueryViewOptionsContext.Provider>
    );
};