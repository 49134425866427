// node_modules
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useEffect, useRef, useState } from "react";
// Custom hooks
import { useClickOutsideRef } from "Hooks";
// Types
import { T2DCoordinates } from "Types";
// Styles
import styles from "./divDropdown.module.scss";

type TDivDropdownProps<T> = {
    className?: string,
    overridenStyle?: { [key: string]: string },
    children?: (JSX.Element)[],
    objectList?: T[],
    selectedObjectIndex?: number,
    newObjectSelected?: (newSelectedObject: T | undefined) => void,
    isFirstItemTitle?: boolean,
};

export function DivDropdown<T>({ className, overridenStyle, children, objectList, selectedObjectIndex,
    isFirstItemTitle, newObjectSelected }: TDivDropdownProps<T>) {
    // Ref
    const dropDownRef = useRef<HTMLDivElement>(null);

    // State
    const [selectedChildIndex, setSelectedChildIndex] = useState(selectedObjectIndex ? selectedObjectIndex : 0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownListPosition, setDropdownListPosition] = useState<T2DCoordinates>({ x: 0, y: 0 });

    useEffect(() => {
        setSelectedChildIndex(selectedObjectIndex ? selectedObjectIndex : 0);
    }, [selectedObjectIndex]);

    // Custom hooks
    useClickOutsideRef(dropDownRef, () => { setIsDropdownOpen(false); });

    // Logic
    useEffect(() => {
        if (selectedObjectIndex !== undefined) { setSelectedChildIndex(selectedObjectIndex); }
    }, [selectedObjectIndex]);

    const selectChild = (index: number) => {
        if (!isFirstItemTitle) setSelectedChildIndex(index);
        setIsDropdownOpen(false);

        if (objectList && newObjectSelected) {
            newObjectSelected(objectList[index]);
        }
    };

    const calculateDropdownListPosition = (event: React.MouseEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        const selectElement = event.currentTarget;
        setDropdownListPosition({ x: selectElement.getBoundingClientRect().left, y: selectElement.getBoundingClientRect().bottom });
    };

    const getDropdownListPosition = (): CSSProperties => {
        const dropdownListPositionCss: CSSProperties = {};
        dropdownListPositionCss.left = `${dropdownListPosition.x}px`;
        dropdownListPositionCss.top = `${dropdownListPosition.y}px`;
        if (isDropdownOpen) {
            dropdownListPositionCss.display = "block";
        } else {
            dropdownListPositionCss.display = "none";
        }
        return dropdownListPositionCss;
    };

    return (
        <div ref={dropDownRef} className={`${overridenStyle ? overridenStyle.overrideDivDropdown : styles.divDropdown} ${className ? className : ""}`} onClick={(event: React.MouseEvent) => { setIsDropdownOpen(!isDropdownOpen); calculateDropdownListPosition(event); }} >
            <div className={`${overridenStyle ? overridenStyle.divDropdownSelect : styles.divDropdownSelect} dropdownSelect`} >
                {children && children.length > 0 ? children[selectedChildIndex] : <div>{"Select"}</div>}
                <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
            </div>
            {children && children.length > 1 ?
                <div style={getDropdownListPosition()} className={`${overridenStyle ? overridenStyle.divDropdownList : styles.divDropdownList} dropdownList`} >
                    {
                        children.map((child, index) => {
                            if (index === selectedChildIndex) { return null; }

                            return (
                                <div key={index} onClick={() => { selectChild(index); }}>
                                    {child}
                                </div>
                            );
                        })
                    }
                </div>
                :
                <div style={getDropdownListPosition()} className={overridenStyle ? overridenStyle.divDropdownList : styles.divDropdownList} >
                    <div>
                        No further results.
                    </div>
                </div>
            }
        </div>
    );
}