import axios from "axios";
// Helpers
import { AxiosHelperSingleton, StudyTypeHelperSingleton } from "Helpers";
// Enums
import { EntityTypeEnum, OtherTemplateTypeEnum, StudyTypeEnum } from "Enums";

export class TemplateController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/template`;

    public async getEntityTemplateAsync(entityType: EntityTypeEnum): Promise<string | undefined> {
        try {
            const response = await axios.get<string>(`${this._resourcePath}/Entity/${entityType}`);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getStudyTemplateAsync(studyType: StudyTypeEnum): Promise<string | undefined> {
        try {
            const numberStudyType = StudyTypeHelperSingleton.enumToNumber(studyType);
            const response = await axios.get<string>(`${this._resourcePath}/Study/${numberStudyType}`);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getOtherTemplateAsync(otherTemplateType: OtherTemplateTypeEnum): Promise<string | undefined> {
        try {
            // get other template
            const response = await axios.get<string>(`${this._resourcePath}/other/${otherTemplateType}`);

            // if response and response.data are defined, return response.data, else return undefined
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            // error return undefined
            return undefined;
        }
    }
}
    
export const TemplateControllerSingleton = new TemplateController();