// Enums
import { HighlightTypeEnum } from "Enums";
// Types
import { THighlightDTO, TOption, TOptions } from "Types";

export class HighlightTypeHelper {
    private highlightTypeStringToEnumMapping: { [name: string]: HighlightTypeEnum } = {
        "": HighlightTypeEnum.Undefined,
        "undefined": HighlightTypeEnum.Undefined,
        "no type": HighlightTypeEnum.Undefined,
        "property": HighlightTypeEnum.Property,
        "advantage": HighlightTypeEnum.Advantage,
        "disadvantage": HighlightTypeEnum.Disadvantage,
        "custom": HighlightTypeEnum.Custom
    };

    private highlightTypeStringToGroupTitleMapping: { [name: string]: string } = {
        "": "Highlights",
        "undefined": "Highlights",
        "no type": "Highlights",
        "property": "Properties",
        "advantage": "Advantages",
        "disadvantage": "Disadvantages",
        "custom": "Custom highlights"
    };

    private getHighlightNameTypePairs(): TOption<HighlightTypeEnum>[] {
        return Object
            .keys(HighlightTypeEnum)
            .filter((key) => Number.isNaN(Number(key)))
            .map((key: string) => ({
                value: this.highlightTypeStringToEnum(key),
                title: key === "Undefined" ? "Highlight" : key
            }));
    }

    public getHighlightTypeDisplayName(highlight: THighlightDTO): string {
        // safety-checks
        if (!highlight) { return "Highlight"; }

        switch (highlight.type) {
            case HighlightTypeEnum.Undefined:
                return "Highlight";
            case HighlightTypeEnum.Property:
                return "Property";
            case HighlightTypeEnum.Advantage:
                return "Advantage";
            case HighlightTypeEnum.Disadvantage:
                return "Disadvantage";
            case HighlightTypeEnum.Custom:
                return highlight.customTypeName ? highlight.customTypeName : "Highlight";
            default:
                return "Highlight";
        }
    }

    public highlightTypeStringToEnum(highlightType: string): HighlightTypeEnum {
        return this.highlightTypeStringToEnumMapping[highlightType.toLowerCase()];
    }

    public highlightTypesDropdownOptions: TOptions<HighlightTypeEnum>[] = [
        {
            group: "Types",
            options: this.getHighlightNameTypePairs().slice(0, -1)
        }
    ];

    public getHighlightsGroupTitle(highlightType: string): string {
        return this.highlightTypeStringToGroupTitleMapping[highlightType.toLowerCase()];
    }
}

export const HighlightTypeHelperSingleton = new HighlightTypeHelper();