// node_modules
import { FC, useEffect, useMemo, useRef, useState } from "react";
// Components
import { ListObjectItem, Popover } from "Components";
import { FindestRefTextBox } from "../FindestTextBox";
// Enums
import { ObjectTypeEnum } from "Enums";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Types
import { TIdNameTypeObjectType } from "Types";
// Styles 
import styles from "./suggestingTextbox.module.scss";

export type TSuggestingTextboxProps = {
    forcedSuggestionValue?: string,
    placeholder: string,
    title: string,
    suggestions: TIdNameTypeObjectType[],
    doForceShowSuggestions?: boolean,
    onValueChangeHandler?: (newValue: string) => void,
    refreshSuggestionsAsync: (newValue: string) => Promise<void>,
    handleSuggestionClick: (suggestion: TIdNameTypeObjectType) => void,
    selectedOption?: TIdNameTypeObjectType | undefined,
    setSelectedOption?: React.Dispatch<React.SetStateAction<undefined | TIdNameTypeObjectType>>,
    updateParentIsSuggestionsShown?: (isShown: boolean) => void,
    doAutoFocus?: boolean,
    navigateCallback?: () => void
}

export const SuggestingTextbox: FC<TSuggestingTextboxProps> = ({
        forcedSuggestionValue,
        placeholder, 
        title, 
        suggestions, 
        doForceShowSuggestions,
        onValueChangeHandler,
        refreshSuggestionsAsync,
        handleSuggestionClick,
        selectedOption,
        setSelectedOption,
        updateParentIsSuggestionsShown,
        doAutoFocus = true,
        navigateCallback
    }: TSuggestingTextboxProps) => {

    // State
    const [isSuggestionsShown, setIsSuggestionsShown] = useState<boolean>(doForceShowSuggestions ? doForceShowSuggestions : false);
    const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);

    // Ref
    const lastValueRef = useRef<string>("");
    const isHandleValueChangeRunningRef = useRef<boolean>(false);
    const currentValueRef = useRef<string>("");
    const textboxRef = useRef<HTMLDivElement>(null);

    // Memo
    const isInputSeen = useMemo((): boolean => {
        return selectedOption ? false : true;
    }, [selectedOption]);

    // Custom Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    // Logic
    useEffect(() => { 
        // if forcedSuggestionValue is provided (not undefined, not null or not empty)
        if (forcedSuggestionValue) {
            // then set it as current value
            currentValueRef.current = forcedSuggestionValue;
        }
    }, [forcedSuggestionValue]);
    
    useEffect(() => {
        if(doForceShowSuggestions) {
            setIsSuggestionsShown(true);
        }
    }, [doForceShowSuggestions]);

    useEffect(() => {
        if (updateParentIsSuggestionsShown) {
            updateParentIsSuggestionsShown(isSuggestionsShown);
        }
    }, [isSuggestionsShown, updateParentIsSuggestionsShown]);

    const onValueChangedAsync = async (newValue: string): Promise<void> => {
        // if set selected option is provided, then reset it
        if (setSelectedOption) {
            setSelectedOption(undefined);
        }
        
        // call on value change handler
        if(onValueChangeHandler) onValueChangeHandler(newValue);
        
        // safety-checks 
        if (!newValue) {
            resetSuggestionTextbox();
        }

        // set current value ref
        currentValueRef.current = newValue;

        // if handler is already running, do not run another one
        // and keep the last value
        if (isHandleValueChangeRunningRef.current) {
            lastValueRef.current = newValue;
            return;
        }

        // set is handler running to true
        isHandleValueChangeRunningRef.current = true;
        // set last value ref to empty string
        lastValueRef.current = "";

        // run handler
        await runOnValueChangeHandlerAsync(newValue);
    };

    const runOnValueChangeHandlerAsync = async (newValue: string): Promise<void> => {
        // safety-checks
        if (!newValue) { 
            resetSuggestionTextbox();
        }
        
        // run handler
        await refreshSuggestionsAsync(newValue);

        // show suggestions
        setIsSuggestionsShown(true);

        // set is handler running to false
        isHandleValueChangeRunningRef.current = false;

        // if a last value is set and different from the last one executed
        if (lastValueRef.current && lastValueRef.current !== newValue) {
            // then run the handler again with the last value
            await onValueChangedAsync(lastValueRef.current);
        }
    };

    const resetSuggestionTextbox = () => {
        setIsSuggestionsShown(false);
        isHandleValueChangeRunningRef.current = false;
        currentValueRef.current = "";
        lastValueRef.current = "";
    };

    const getSuggestionDisplayType = (suggestion: TIdNameTypeObjectType): string => {
        return suggestion.customTypeName ? suggestion.customTypeName : suggestion.type;
    };

    const handleSuggestionClickInside = (suggestion: TIdNameTypeObjectType) => {
        currentValueRef.current = suggestion.name;
        handleSuggestionClick(suggestion);
        setIsSuggestionsShown(false);
    };

    const handleSelectedOptionOnClick = () => {
        if (selectedOption && setSelectedOption) {
            lastValueRef.current = selectedOption.name;
            setIsSuggestionsShown(true);
            textboxRef?.current?.click();
        }
    };

    const onInputElementFocus = () => {
        setIsSuggestionsShown(true);
    };

    const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
        setReferenceModalProps((previousReferenceModalProps) => {
            return {
                ...previousReferenceModalProps,
                isOpen: true,
                id: objectId,
                type: objectType
            };
        });
    };
    
    return (
        <>
            <div className={styles.suggestingTextbox}>
                <span ref={setReferenceElement}>
                    {!isInputSeen && selectedOption && (
                        <ListObjectItem
                            object={selectedOption}
                            iconHasColor={true}
                            subItemType={getSuggestionDisplayType(selectedOption)}
                            extraClassName={`${styles.suggestedSuggestion} ${styles.selectedOption} ${isInputSeen ? styles.hidden : ""}`}
                            onClick={handleSelectedOptionOnClick}
                            navigateCallback={navigateCallback}
                            moreActionsDropdownPopoverDataIdentifier="suggested-suggestion-more-actions-dropdown"
                        />
                    )}
                    <FindestRefTextBox 
                        extraClassName={isInputSeen ? "" : styles.hidden} 
                        ref={textboxRef} 
                        value={currentValueRef.current} 
                        onChange={onValueChangedAsync} 
                        onClick={onInputElementFocus} 
                        placeholder={placeholder ? 
                        placeholder : "Start typing..."} 
                        doAutoFocus={doAutoFocus}/>
                </span>
                {isSuggestionsShown && suggestions && suggestions.length > 0 && (
                    <Popover
                        exceptionDataIdentifiter="suggested-suggestion-more-actions-dropdown"
                        referenceEl={referenceElement}
                        extraClassName={styles.suggestionPopup}
                        onClickOutside={() => { setIsSuggestionsShown(false); }}
                    >
                        <div className={styles.suggestionTitle}>{title}</div>
                        <ul className={styles.suggestedSuggestions}>
                            {suggestions.map((suggestion: TIdNameTypeObjectType) => {
                                return (
                                    <ListObjectItem
                                        key={suggestion.id}
                                        onClick={() => { handleSuggestionClickInside(suggestion); }}
                                        object={suggestion}
                                        subItemType={getSuggestionDisplayType(suggestion)}
                                        iconHasColor
                                        navigateCallback={navigateCallback}
                                        openReferenceModal={openReferenceModal}
                                        moreActionsDropdownPopoverDataIdentifier="suggested-suggestion-more-actions-dropdown"
                                    />
                                );
                            })}
                        </ul>
                    </Popover>
                )}
            </div>
            {referenceModal}
        </>
    );
};
