// node_modules
import debounce from "lodash.debounce";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Components
import { DocumentView } from "Components";
// Constants
import { EventConstants } from "Constants";
// Controllers
import { DocumentControllerSingleton } from "Controllers";
// Types
import { THighlightDTO, TIdNameTypeObjectType, TImageDTO, TUseFetch } from "Types";
// Enums
import { ObjectTypeEnum, SavedDocumentTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ConnectedObjectsHelperSingleton, ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Custom hooks
import { useFetch } from "Hooks";
// Contexts
import { AuthContext, ElementVisibilityContext, PubSubContext } from "Providers";
// Interfaces
import { ISavedDocumentDTO, fromISavedDocumentDTO } from "Interfaces";

export const DocumentDetails: FC = () => {
    // Hooks
    const { documentId } = useParams();
    const navigate = useNavigate();

    // Context
    const { isUserExternal } = useContext(AuthContext);
    const { canUserEdit } = useContext(ElementVisibilityContext);
    const { pubSubHandler } = useContext(PubSubContext);
    
    // State
    const [currentDocument, setCurrentDocument] = useState<ISavedDocumentDTO | null>(null);
    
    // Retrieve document information
    const { fetchedData: fetchedDocument }: TUseFetch<ISavedDocumentDTO> = useFetch(`api/saveddocument/${documentId}`);

    useEffect(() => {
        if(fetchedDocument) {
            setCurrentDocument(fetchedDocument);
        }
    }, [fetchedDocument]);

    // Memos
    const isLocalFile = useMemo(() => {
        if(!currentDocument || (!currentDocument.fullUrl && !currentDocument.url)) return false;
        const currentUrl = currentDocument.fullUrl ? currentDocument.fullUrl : currentDocument.url;
        return currentUrl.includes("file://");
    }, [currentDocument]);

    const doShowGoToUrlButton = useMemo(() => {
        return !!(fetchedDocument && (fetchedDocument.fullUrl || fetchedDocument.url))
            && !(isLocalFile && isUserExternal);
    }, [fetchedDocument, isLocalFile, isUserExternal]);

    const updateHighlights = useCallback(async (newHighlights: THighlightDTO[]) => {
        // If the user is readonly then do nothing
        if (!canUserEdit || !currentDocument) return;
        // Update the current document with new highlights
        setCurrentDocument({
            ...currentDocument,
            highlights: [...newHighlights]
        });
    }, [currentDocument, canUserEdit]);

    const handleNewDocumentTitleAsync = useCallback(async (newTitle: string): Promise<void> => {
        if (!currentDocument || currentDocument.savedDocumentType !== SavedDocumentTypeEnum.Weblink) return;

        setCurrentDocument({
            ...currentDocument,
            title: newTitle
        });
        
        await DocumentControllerSingleton.updateDocumentTitle(currentDocument.id, newTitle);
    }, [currentDocument]);

    // debounce the handleNewDocumentTitleAsync function
    const debouncedHandleNewDocumentTitleAsync = useMemo(() => debounce(handleNewDocumentTitleAsync, EventConstants.UPDATE_OBJECT_NAME_DEFAULT_MS_DELAY),
    [handleNewDocumentTitleAsync]);

    if (!documentId) {
        navigate("/library/documents/");
        return null;
    }

    if(!currentDocument) return (<div></div>);

    const onAddImage = (newImage: TImageDTO) => {
        // If the image was added successfully then add it to the current document
        setCurrentDocument({
            ...currentDocument,
            images: [...currentDocument.images, newImage]
        });
    };

    const onDeleteImage = (image: TImageDTO) => {
        setCurrentDocument({
            ...currentDocument,
            images: currentDocument.images.filter(i => i.id !== image.id)
        });
    };

    const onSaveElementClickAsync = async (element: TIdNameTypeObjectType, closeSavePopupCallback?: () => void): Promise<void> => {
        // if fetchedDocument is not set
        if (!fetchedDocument) {
            // show error message
            ToastHelperSingleton    
                .showToast(
                    ToastTypeEnum.Error,
                    `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(element.objectType).toLowerCase()} to document.`
                );
            // stop execution, return
            return;
        }

        // call close save popup callback if it set
        if (closeSavePopupCallback) closeSavePopupCallback();

        // get document object type from fetched document
        const documentObjectType: ObjectTypeEnum = ObjectTypeHelperSingleton.documentTypeToObjectType(fetchedDocument.savedDocumentType);
        
        // add object to current document
        await ConnectedObjectsHelperSingleton
            .addObjectToObjectAsync(element, pubSubHandler, fetchedDocument.id, documentObjectType);
    };

    return (
        <DocumentView
            document={fromISavedDocumentDTO(currentDocument)}
            onSaveElementClick={async (element: TIdNameTypeObjectType, closeSavePopupCallback?: () => void) => { await onSaveElementClickAsync(element, closeSavePopupCallback); }}
            doShowGoToUrlButton={doShowGoToUrlButton}
            isMainTitleEditable={canUserEdit && currentDocument.savedDocumentType === SavedDocumentTypeEnum.Weblink}
            onAddImage={onAddImage}
            onDeleteImage={onDeleteImage}
            updateHighlights={updateHighlights}
            onUpdateTitle={debouncedHandleNewDocumentTitleAsync}
        />
    );
};
