// node_modules
import { FC, useContext } from "react";
import { faClock } from "@fortawesome/free-regular-svg-icons";
// Contexts
import { RecentActivityContext } from "Providers";
// Components
import { TopbarDropdownButton } from "../TopbarDropdownButton/TopbarDropdownButton";
// Types
import { TIdNameTypeObjectType, TRecentSidebarActivityItemDTO, fromTRecentSidebarActivityItemDTO } from "Types";
// Constants
import { EmptyStateMessageConstants } from "Constants";
// Helpers
import { ActivityTypeHelperHelperSingleton } from "Helpers";

// Component
export const Activities: FC = () => {
    // Contexts
    const { mySimpleRecentActivity, refreshMySimpleRecentActivityAsync } = useContext(RecentActivityContext);

    /** Display sub item type in dropdown */
    const displaySubItemType = (item: TIdNameTypeObjectType): string | undefined => {
        // get related activity
        const relatedActivity:  TRecentSidebarActivityItemDTO | undefined = mySimpleRecentActivity.find((msra: TRecentSidebarActivityItemDTO) => { return msra.id === item.id; });

        // return sub item type to display
        return relatedActivity ? ActivityTypeHelperHelperSingleton.activityEnumToDisplayString(relatedActivity.activityType) : undefined;
    };

    // Render
    return (
        <TopbarDropdownButton 
            buttonIcon={faClock}
            emptyItemsMessage={EmptyStateMessageConstants.EMPTY_RECENT_ITEMS}
            items={mySimpleRecentActivity.map((msra: TRecentSidebarActivityItemDTO) => { return fromTRecentSidebarActivityItemDTO(msra); })}
            displaySubItemType={displaySubItemType}
            tooltipText="Recent activities"
            onClickCallback={refreshMySimpleRecentActivityAsync} />
    );
};