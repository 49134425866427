// React
import { FC, useEffect, useState } from "react";
// Constants
import { LinkingConstants } from "Constants";
// Components
import { PageCommentsThread } from "../PageCommentsThread/PageCommentsThread";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Controllers
import { CommentControllerSingleton } from "Controllers";
// Types
import { TCommentv2DTO } from "Types";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Styles
import styles from "../pageComments.module.scss";

export type TPageCommentsProps = {
    objectId: string,
    objectType: ObjectTypeEnum,
    currentUsername: string
};

export const PageComments: FC<TPageCommentsProps> = ({ objectId, objectType, currentUsername }) => {
    const [pageComments, setPageComments] = useState<TCommentv2DTO[] | undefined>(undefined);

    useEffect(() => {
        const fetchComments = async () => {
            // get page comments
            const fetchedComments = await CommentControllerSingleton
            .getv2Async(objectType, objectId);

            // safety-checks
            if (!fetchedComments) {
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to fetch object comments.");
                return;
            }
            // set page comments
            setPageComments(fetchedComments);
        };

        fetchComments().catch((error) => { console.warn(error); });
    }, [objectId, objectType]);

    return (
        <div className={styles.pageCommentsContainer}>
            <h1 id={`${LinkingConstants.PAGE_COMMENTS_HEADER_ID}_${objectId}`}>Page comments</h1>
            <PageCommentsThread currentUsername={currentUsername} comments={pageComments} objectType={objectType} objectId={objectId} />
        </div>
    );
};