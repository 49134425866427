// node_modules
import axios from "axios";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TAddRatingDTO, TAddRatingResponseDTO, TGetAllRatingsOfObjectDTO, TGetRatingOfObjectByIdDTO, TObjectsByUserIdDTO, TPredefineRatingDTO, TRemoveRatingDTO } from "Types";

export class RatingController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api`;

    public async predefineRatingAsync(sourceId: string, sourceType: ObjectTypeEnum, targetId: string[]): Promise<boolean> {
        try {
            // build TPredefineRatingDTO
            const predefineRatingDTO: TPredefineRatingDTO = {
                sourceId,
                sourceType,
                targetId
            };

            // build url
            const url = `${this._resourcePath}/rating/predefine`;

            // predefine rating
            const response = await axios.post(url, predefineRatingDTO);

            // deal with response
            if(response && response.data) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async addRatingAsync(score: number, sourceType: ObjectTypeEnum, sourceId: string, targetType: ObjectTypeEnum, targetId: string): Promise<TAddRatingResponseDTO | undefined> {
        try {
            // build TAddRatingDTO
            const addRatingDTO: TAddRatingDTO = {
                score,
                sourceType,
                sourceId,
                targetType,
                targetId
            };

            // build url
            const url = `${this._resourcePath}/rating`;

            // add rating
            const response = await axios.put(url, addRatingDTO);

            // deal with response
            if(response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }



    public async getOverviewAsync(objectType: string, id: string): Promise<TGetAllRatingsOfObjectDTO | undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/rating/${objectType}/${id}`;

            // get overview
            const response = await axios.get(url);

            // deal with response
            if(response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getRatingAsync(id: string, objectType: string, entityId: string): Promise<TGetRatingOfObjectByIdDTO | undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/rating/${objectType}/${id}/entity/${entityId}`;

            // get rating
            const response = await axios.get(url);

            // deal with response
            if(response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getObjectByUserIdAndTargetIdAsync(targetId: string, objectType: string): Promise<TObjectsByUserIdDTO | undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/rating/by-user/${objectType}/${targetId}`;

            // get object by user id and target id
            const response = await axios.get(url);

            // deal with response
            if(response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async removeRatingAsync(id: number): Promise<TRemoveRatingDTO | undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/rating/${id}`;

            // remove rating
            const response = await axios.delete(url);

            // deal with response
            if(response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }
}

export const RatingControllerSingleton = new RatingController();