// node_modules
import { FC, useContext, useEffect, useState } from "react";
// Constants
import { EmptyStateMessageConstants, TextConstants } from "Constants";
// Controller
import { ActivityControllerSingleton } from "Controllers";
// Components
import { InformationBox, LoadingStatusIndicator, ObjectItem } from "Components";
// Types
import { TRecentSidebarActivityItemDTO, fromTRecentSidebarActivityItemDTO } from "Types";
// Styles
import styles from "./recentActivity.module.scss";
// Contexts
import { AuthContext, ElementVisibilityContext } from "Providers";

export const RecentActivity: FC = () => {
    // Context
    const { isUserExternal } = useContext(AuthContext);
    const { canUserEdit } = useContext(ElementVisibilityContext);

    const [recentActivity, setRecentActivity] = useState<TRecentSidebarActivityItemDTO[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            const recentActiveItems = await ActivityControllerSingleton.getMyActivityAsync(10);
            if (recentActiveItems) {
                setRecentActivity(recentActiveItems);
            }
            setIsLoading(false);
        })();
    }, []);

    // Render
    return (
        <div className={styles.recentActivity}>
            {recentActivity.length > 0 && recentActivity.map((recentSidebarActivityItem: TRecentSidebarActivityItemDTO) => (
                <ObjectItem
                    extraClassName={styles.objectItem}
                    key={recentSidebarActivityItem.id} 
                    objectItem={fromTRecentSidebarActivityItemDTO(recentSidebarActivityItem)} 
                    isEditable={false}
                    isUserExternal={isUserExternal}
                />
            ))}
            {recentActivity.length === 0 && !isLoading && (
                <div className={styles.emptyContentContainer}>
                    <InformationBox
                        title={EmptyStateMessageConstants.EMPTY_RECENT_ITEMS}
                        subTitle={canUserEdit ? TextConstants.START_CREATING_BY_CLICKING_CREATE_BUTTON : TextConstants.RECENT_ACTIVITY_OF_YOU_WILL_BE_DISPLAYED_HERE}
                        type="information"
                    />
                </div>
            )}
            {isLoading && <LoadingStatusIndicator shouldCenter size={32} status={1} />}
        </div>
    );
};