// node_modules
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
// Types"
import { TCaseDTO, TIdNameTypeObjectType, TOrganizationDTO, TTenantDTO } from "Types";
// Components
import { SuggestingTextbox } from "Components";
// Controllers
import { TenantControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Constants
// Styles
import styles from "./caseSelection.module.scss";

type TCaseSelectionProps = {
    selectedFromTenant: TIdNameTypeObjectType | undefined,
    selectedFromOrganization: TIdNameTypeObjectType | undefined,
    selectedCase: TIdNameTypeObjectType | undefined,
    selectedToTenant: TIdNameTypeObjectType | undefined,
    setSelectedFromTenant: Dispatch<SetStateAction<TIdNameTypeObjectType | undefined>>,
    setSelectedFromOrganization: Dispatch<SetStateAction<TIdNameTypeObjectType | undefined>>,
    setSelectedCase: Dispatch<SetStateAction<TIdNameTypeObjectType | undefined>>,
    setSelectedToTenant: Dispatch<SetStateAction<TIdNameTypeObjectType | undefined>>
}

export const CaseSelection: FC<TCaseSelectionProps> = ({
    selectedFromTenant,
    selectedFromOrganization,
    selectedCase,
    selectedToTenant,
    setSelectedFromTenant,
    setSelectedFromOrganization,
    setSelectedCase,
    setSelectedToTenant
}: TCaseSelectionProps) => {
    // State
    const [, setFromTenantCurrentName] = useState<string>("");
    const [, setFromOrganizationCurrentName] = useState<string>("");
    const [, setCaseCurrentTitle] = useState<string>("");
    const [, setToTenantCurrentName] = useState<string>("");
    const [fromTenantSuggestions, setFromTenantSuggestions] = useState<TIdNameTypeObjectType[]>([]);
    const [fromOrganizationSuggestions, setFromOrganizationSuggestions] = useState<TIdNameTypeObjectType[]>([]);
    const [caseSuggestions, setCaseSuggestions] = useState<TIdNameTypeObjectType[]>([]);
    const [toTenantSuggestions, setToTenantSuggestions] = useState<TIdNameTypeObjectType[]>([]);

    // Logic
    const tenantsToIdNameTypeObjectsConverter = (tenants: TTenantDTO[]): TIdNameTypeObjectType[] => {
        return tenants.map((tenant: TTenantDTO) => {
            return {
                id: tenant.id,
                name: tenant.name,
                type: "Tenant",
                objectType: ObjectTypeEnum.Tenant
            };
        });
    };

    const organizationsToIdNameTypeObjectsConverter = (organizations: TOrganizationDTO[]): TIdNameTypeObjectType[] => {
        return organizations.map((organization: TOrganizationDTO) => {
            return {
                id: `${organization.id}`,
                name: organization.name,
                type: "Organization",
                objectType: ObjectTypeEnum.Organization
            };
        });
    };

    const casesToIdNameTypeObjectsConverter = (cases: TCaseDTO[]): TIdNameTypeObjectType[] => {
        return cases.map((caseObject: TCaseDTO) => {
            return {
                id: `${caseObject.id}`,
                name: caseObject.title,
                type: "Case",
                objectType: ObjectTypeEnum.Case
            };
        });
    };

    const onFromTenantSuggestionSelectedAsync = async (suggestion: TIdNameTypeObjectType) => {
        // set selected from tenant
        setSelectedFromTenant(suggestion);

        // set selected from organization to undefined
        setSelectedFromOrganization(undefined);

        // get default organizations
        await refreshFromOrganizationSuggestionsAsync(suggestion.id, "");
    };

    const onToTenantSuggestionSelectedAsync = async (suggestion: TIdNameTypeObjectType) => {
        // set selected to tenant
        setSelectedToTenant(suggestion);
    };

    const onFromOrganizationSuggestionSelectedAsync = async (tenantId: string, suggestion: TIdNameTypeObjectType) => {
        // set selected from organization
        setSelectedFromOrganization(suggestion);

        // set selected case to undefined
        setSelectedCase(undefined);

        // get default organizations
        await refreshCaseSuggestionsAsync(tenantId, suggestion.id, "");
    };

    const refreshFromTenantSuggestionsAsync = useCallback(async (newValue: string): Promise<void> =>{
        // get tenants
        const tenants: TTenantDTO[] = await TenantControllerSingleton.getAsync(newValue, false);

        // set from tenant suggestions
        setFromTenantSuggestions([...tenantsToIdNameTypeObjectsConverter(tenants)]);
    }, []);

    const refreshToTenantSuggestionsAsync = useCallback(async (newValue: string): Promise<void> =>{
        // get tenants
        const tenants: TTenantDTO[] = await TenantControllerSingleton.getAsync(newValue, true);

        // set to tenant suggestions
        setToTenantSuggestions([...tenantsToIdNameTypeObjectsConverter(tenants)]);
    }, []);

    useEffect(() => {
        (async () => {
            await refreshFromTenantSuggestionsAsync("");
        })();
    }, [refreshFromTenantSuggestionsAsync]);

    useEffect(() => {
        (async () => {
            if (selectedFromTenant && selectedFromOrganization && selectedCase && !selectedToTenant) {
                await refreshToTenantSuggestionsAsync("");
            }
        })();
    }, [refreshToTenantSuggestionsAsync, selectedCase, selectedFromOrganization, selectedFromTenant, selectedToTenant]);

    const refreshFromOrganizationSuggestionsAsync = async (tenantId: string, newValue: string): Promise<void> => {
        // get organizations
        const organizations: TOrganizationDTO[] = await TenantControllerSingleton.getOrganizationsAsync(tenantId, newValue);

        // set from organization suggestions
        setFromOrganizationSuggestions([...organizationsToIdNameTypeObjectsConverter(organizations)]);
    };

    const refreshCaseSuggestionsAsync = async (tenantId: string, organizationId: string, newValue: string): Promise<void> => {
        // get cases
        const cases: TCaseDTO[] = await TenantControllerSingleton
            .getCasesAsync(tenantId, organizationId, newValue);

        // set case suggestions
        setCaseSuggestions([...casesToIdNameTypeObjectsConverter(cases)]);
    };
    
    // Render
    return (
        <div className={styles.caseSelectionContainer}>
            <div className={styles.section}>
                <h3>Choose the tenant you want to migrate a case from:</h3>
                <SuggestingTextbox
                    placeholder="Tenant name"
                    title={"Suggested tenants"}
                    onValueChangeHandler={setFromTenantCurrentName}
                    refreshSuggestionsAsync={refreshFromTenantSuggestionsAsync}
                    suggestions={fromTenantSuggestions}
                    handleSuggestionClick={onFromTenantSuggestionSelectedAsync}
                    selectedOption={selectedFromTenant}
                    doForceShowSuggestions={true}
                    setSelectedOption={setSelectedFromTenant}
                />
            </div>
            {selectedFromTenant ?
                    <div className={styles.section}>
                        <h3>Choose the organization within the tenant you want to migrate a case from:</h3>
                        <SuggestingTextbox
                            placeholder="Organization name"
                            title={"Suggested organizations"}
                            onValueChangeHandler={setFromOrganizationCurrentName}
                            refreshSuggestionsAsync={(newValue: string) => refreshFromOrganizationSuggestionsAsync(selectedFromTenant.id, newValue)}
                            suggestions={fromOrganizationSuggestions}
                            handleSuggestionClick={(suggestion: TIdNameTypeObjectType) => onFromOrganizationSuggestionSelectedAsync(selectedFromTenant.id, suggestion)}
                            selectedOption={selectedFromOrganization}
                            doForceShowSuggestions={true}
                            setSelectedOption={setSelectedFromOrganization} />
                    </div>
                :
                    null
            }
            {selectedFromTenant && selectedFromOrganization ?
                    <div className={styles.section}>
                        <h3>Choose the case you want to migrate:</h3>
                        <SuggestingTextbox
                            placeholder="Case title"
                            title={"Suggested cases"}
                            onValueChangeHandler={setCaseCurrentTitle}
                            refreshSuggestionsAsync={(newValue: string) => refreshCaseSuggestionsAsync(selectedFromTenant.id, selectedFromOrganization.id, newValue)}
                            suggestions={caseSuggestions}
                            handleSuggestionClick={(suggestion: TIdNameTypeObjectType) => setSelectedCase(suggestion)}
                            selectedOption={selectedCase}
                            doForceShowSuggestions={true}
                            setSelectedOption={setSelectedCase}
                        />
                    </div>
                :
                    null
            }
            {selectedFromTenant && selectedFromOrganization && selectedCase ?
                    <div className={styles.section}>
                        <h3>Choose the tenant you want to migrate a case to:</h3>
                        <SuggestingTextbox
                            placeholder="Tenant name"
                            title={"Suggested tenants"}
                            onValueChangeHandler={setToTenantCurrentName}
                            refreshSuggestionsAsync={refreshToTenantSuggestionsAsync}
                            suggestions={toTenantSuggestions}
                            handleSuggestionClick={onToTenantSuggestionSelectedAsync}
                            selectedOption={selectedToTenant}
                            doForceShowSuggestions={true}
                            setSelectedOption={setSelectedToTenant}
                        />
                    </div>
                :
                    null
            }
        </div>
    );
};