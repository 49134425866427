// node_modules
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { ObjectChip, RatingStar, RatingsPopover } from "Components";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { ObjectTypeHelperSingleton, UserHelperSingleton } from "Helpers";
// Types
import { TObjectDetailDTO } from "Types";
// Styles
import styles from "./objectsRatingsPopover.module.scss";
// Contexts
import { AuthContext } from "Providers";

// Props type
type TObjectsRatingItemProps = {
    rating: TObjectDetailDTO,
    ratings: TObjectDetailDTO[],
    onNewAverageRatingHandler: (prevRatings: TObjectDetailDTO[], forSourceId: string, newCount: number, newScore: number, newIsRatingNeeded: boolean) => void,
    currentUserEmail: string,
    objectId: string,
    onMoreActionsOptionClick?: () => void,
    openReferenceModal: (objectId: string, objectType: ObjectTypeEnum) => void
};

// Component
export const ObjectsRatingItem: FC<TObjectsRatingItemProps> = ({ rating, ratings, onNewAverageRatingHandler, currentUserEmail, objectId, onMoreActionsOptionClick, openReferenceModal }: TObjectsRatingItemProps) => {
    // State
    const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
    const [isRatingsPopoverOpen, setIsRatingsPopoverOpen] = useState<boolean>(false);
    const [objectIdToShowRatingsFor, setObjectIdToShowRatingsFor] = useState<string | undefined>(undefined);
    const [objectTypeToShowRatingsFor, setObjectTypeToShowRatingsFor] = useState<ObjectTypeEnum | undefined>(undefined);

    // Contexts
    const { auth } = useContext(AuthContext);

    // Hooks
    const navigate = useNavigate();

    // show ratings popover
    const showRatingsPopover = (id: string, type: ObjectTypeEnum) => {
        // set object id to show ratings for
        setObjectIdToShowRatingsFor(id);
        // set object type to show ratings for
        setObjectTypeToShowRatingsFor(type);

        // set is ratings popover open
        setIsRatingsPopoverOpen(true);
    };

    const onNewAverageRating = (forSourceId: string, _: string, newCount: number, newScore: number, newIsRatingNeeded: boolean) => {
        // set object id to show ratings for
        setObjectIdToShowRatingsFor(undefined);

        // set object type to show ratings for
        setObjectTypeToShowRatingsFor(undefined);

        // set is ratings popover open
        setIsRatingsPopoverOpen(false);

        onNewAverageRatingHandler(ratings, forSourceId, newCount, newScore, newIsRatingNeeded);
    };

    // Render
    return (
        <div className={styles.objectRatingContainer} ref={setPopoverRef} key={rating.id}>
            <RatingStar
                extraClassNames={{ container: styles.objectRatingStar }}
                size="large"
                rating={rating.averageRating.score ?? 0}
                isRatingNeeded={!rating.isRatedByCurrentUser}
                isRatingShown={true}
                onMouseOverHandler={() => { showRatingsPopover(rating.id, rating.type); }}
                onMouseOutHandler={() => { setIsRatingsPopoverOpen(false); }} />
            <ObjectChip
                isChipClickable={!UserHelperSingleton.isSharingRestrictedToObject(auth)}
                object={{
                    id: rating.id,
                    name: rating.title,
                    type: ObjectTypeHelperSingleton.getObjectTypeDisplayName(rating.type),
                    objectType: rating.type,
                }}
                onClick={UserHelperSingleton.isSharingRestrictedToObject(auth) ? undefined : () => { ObjectTypeHelperSingleton.navigateBasedOnObjectType(rating.type, rating.id, navigate); }}
                onMoreActionsOptionClickCallback={onMoreActionsOptionClick}
                openReferenceModal={openReferenceModal}
            />
            {(objectIdToShowRatingsFor && objectTypeToShowRatingsFor && isRatingsPopoverOpen && rating.id === objectIdToShowRatingsFor) && (
                <RatingsPopover
                    popoverOffset={-19}
                    onNewAverageRating={onNewAverageRating}
                    onMouseEnter={() => { setIsRatingsPopoverOpen(true); }}
                    onMouseLeave={() => { setIsRatingsPopoverOpen(false); }}
                    hideRatingsPopover={() => { setIsRatingsPopoverOpen(false); }}
                    currentUserEmail={currentUserEmail}
                    forSourceId={objectIdToShowRatingsFor}
                    forSourceType={objectTypeToShowRatingsFor}
                    forTargetId={objectId}
                    isOpen={isRatingsPopoverOpen}
                    firstPopoverRef={popoverRef}
                />
            )}
        </div>
    );
};