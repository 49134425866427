// node_modules
import { IconDefinition, faChevronDown, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FC, useMemo } from "react";
// Types
import { TEditorHeader } from "Types";
// Components
import { SidebarItem } from "../../Sidebar/SidebarItem";
// Styles
import styles from "./editorHeadersMenuItem.module.scss";

type TEditorHeadersMenuItemProps = {
    editorHeader: TEditorHeader,
    onEditorHeaderClick: (editorHeader: TEditorHeader) => void,
    onChevronClick: (editorHeader: TEditorHeader) => void,
    onMouseEnterHandler? : () => void,
    onMouseLeaveHandler? : () => void
}

export const EditorHeadersMenuItem: FC<TEditorHeadersMenuItemProps> = ({editorHeader, onEditorHeaderClick, onChevronClick, onMouseEnterHandler, onMouseLeaveHandler}: TEditorHeadersMenuItemProps) => {
    // get editor header right content
    const editorHeaderRightContent = useMemo((): {icon?: IconDefinition, color?: string} => {
        // if is confirmed is undefined or null and isRatingNeededInResultsOverviewTable is undefined, null or false
        if ((editorHeader.isConfirmed === undefined || editorHeader.isConfirmed === null) &&
                (editorHeader.isRatingNeededInResultsOverviewTable === undefined || editorHeader.isRatingNeededInResultsOverviewTable === null ||
                !editorHeader.isRatingNeededInResultsOverviewTable)) {
            // no icon and no color
            return {icon: undefined, color: undefined};
        }
        // if is rating needed in results overview table true
        if (editorHeader.isRatingNeededInResultsOverviewTable) {
            // return circle icon with color #F24343 (red)
            return {icon: faCircle, color: "#F24343"};
        } else {
            // otherwise, return circle icon with color #4CD964 (green) or #007AFF (blue)
            return {icon: faCircle, color: editorHeader.isConfirmed ? "#4CD964" : "#007AFF"};
        }
    }, [editorHeader.isConfirmed, editorHeader.isRatingNeededInResultsOverviewTable]);

    // Render
    return (
        <SidebarItem
            isCollapsed={false}
            activeSidebarItem={location.pathname}
            sidebarItem={{ 
                title: editorHeader.title, 
                icon: editorHeader.hasChildren ? (editorHeader.isCollapsed ? faChevronRight : faChevronDown): undefined, 
                navigateTo: "", 
                isSelected: editorHeader.isSelected,
                rightContent: editorHeaderRightContent.icon,
                rightContentColor: editorHeaderRightContent.color
            }}
            extraClassNames={{
                sidebarListItem: `${styles.editorHeadersMenuItem} ${styles[`heading-${editorHeader.level}`]}`,
                sidebarListItemIcon: styles.editorHeadersMenuItemIcon,
                sidebarListItemTitle: styles.editorHeadersMenuItemTitle,
                sidebarListItemIconContainer: styles.editorHeadersMenuItemIconContainer,
                listItem: styles.editorHeadersMenuItemListItem,
                listItemLeft: styles.editorHeadersMenuItemLeft
            }}
            onItemClick={() => { onEditorHeaderClick(editorHeader); }}
            onIconClick={() => { onChevronClick(editorHeader); }}
            showInsideAnchor={false}
            onMouseEnterHandler={onMouseEnterHandler}
            onMouseLeaveHandler={onMouseLeaveHandler} />
    );
};