// Types
import { TExplorerObjectItem, TLinkGraphNodeDTO } from "Types";

export class LinkGraphHelper {
    // recursive function to get children ids in link graph nodes
    public getChildrenIds(linkGraphNodes: TLinkGraphNodeDTO[], doOnlyFirstLevel = false): Set<string> {
        // go through each link graph node
        return linkGraphNodes.reduce((objectIds: Set<string>, linkGraphNode: TLinkGraphNodeDTO) => {
            // add the current link graph node id to the object ids
            objectIds.add(linkGraphNode.id);

            // if we only want the first level object ids, return the object ids
            if (doOnlyFirstLevel) return objectIds;

            // get object ids in explorer for the lower level nodes of the current link graph node
            this.getChildrenIds(linkGraphNode.lowerLevelNodes).forEach(id => objectIds.add(id));

            // return the object ids
            return objectIds;
        }, new Set<string>());
    }

    public getDeepestLevelNumberFromLinkGraphNode = (linkGraphNode: TLinkGraphNodeDTO, currentDepth: number): number => {
        // safety-checks
        if (!linkGraphNode.lowerLevelNodes) {
            // return 0
            return currentDepth;
        }
    
        // init deepest level number
        let maxDeepestLevelNumber = currentDepth;
    
        // for each lower level node
        linkGraphNode.lowerLevelNodes.forEach((lowerLevelNode: TLinkGraphNodeDTO) => {
            // get deepest level number from lower level node
            const deepestLevelNumber = this.getDeepestLevelNumberFromLinkGraphNode(lowerLevelNode, currentDepth + 1);
    
            // if deepest level number is greater than max deepest level number
            if (deepestLevelNumber > maxDeepestLevelNumber) {
                // set max deepest level number to deepest level number
                maxDeepestLevelNumber = deepestLevelNumber;
            }
        });
    
        // return max deepest level number
        return maxDeepestLevelNumber;
    };

    public setLowerLevelNodesCountPerNodeId = (linkGraphNode: TLinkGraphNodeDTO, 
        lowerLevelNodesCountPerNodeId: Map<string, number>): void => {
        // safety-checks
        if (!linkGraphNode.lowerLevelNodes) {
            // do nothing, return
            return;
        }

        // set lower level nodes count per node id for link graph node
        lowerLevelNodesCountPerNodeId.set(linkGraphNode.id, linkGraphNode.lowerLevelNodes.length);

        // for each lower level node
        linkGraphNode.lowerLevelNodes.forEach((lowerLevelNode: TLinkGraphNodeDTO) => {
            // set lower level nodes count per node id for lower level node
            lowerLevelNodesCountPerNodeId.set(lowerLevelNode.id, lowerLevelNode.lowerLevelNodes ? lowerLevelNode.lowerLevelNodes.length : 0);

            // set lower level nodes count per node id for lower level node
            this.setLowerLevelNodesCountPerNodeId(lowerLevelNode, lowerLevelNodesCountPerNodeId);
        });
    };

    /** Add child to parent */ 
    public addChildToParent(currentItems: TExplorerObjectItem[], parentId: string, child: TExplorerObjectItem) {
        // go through each current item
        return currentItems.map((currentItem: TExplorerObjectItem) => {
            // if the current item is the one we are looking for
            if (currentItem.id === parentId && !currentItem.lowerLevelNodes.find((lln: TExplorerObjectItem) => lln.id === child.id)) {
                // add the child to the current item's lower level nodes
                currentItem.lowerLevelNodes = [...currentItem.lowerLevelNodes, child];
                // set the current item as expanded since it has at least one child now
                currentItem.isCollapsed = false;
            } else {
                // add child to the current item's children
                currentItem.lowerLevelNodes = this.addChildToParent(currentItem.lowerLevelNodes, parentId, child);
            }

            // return the current item
            return currentItem;
        });
    }

    public removeChildFromParent(currentItems: TLinkGraphNodeDTO[], parentId: string, childId: string) {
        // go through each current item
        return currentItems.map((currentItem: TLinkGraphNodeDTO) => {
            // if the current item is the one we are looking for
            if (currentItem.id === parentId) {
                // remove the child from the current item's lower level nodes
                currentItem.lowerLevelNodes = [...currentItem.lowerLevelNodes.filter((lln: TLinkGraphNodeDTO) => lln.id !== childId)];
            } else {
                // remove child from the current item's children
                currentItem.lowerLevelNodes = this.removeChildFromParent(currentItem.lowerLevelNodes, parentId, childId);
            }

            // return the current item
            return currentItem;
        });
    }

    /** function to get explorer object item by id */
    public getExplorerObjectItemById(id: string | undefined, explorerObjectItems: TExplorerObjectItem[] | undefined): TExplorerObjectItem | undefined {
        // try to find explorer object item by id on the current level
        const explorerObjectItemToFind: TExplorerObjectItem | undefined = explorerObjectItems?.find((eoi: TExplorerObjectItem) => eoi.id === id);

        // if explorer object item is not found
        if (!explorerObjectItemToFind) {
            // go through each explorer object item
            for (const explorerObjectItem of explorerObjectItems ?? []) {
                // recursively search in lower level nodes
                const explorerObjectItemFound = this.getExplorerObjectItemById(id, explorerObjectItem.lowerLevelNodes);

                // if explorer object item is found, return it
                if (explorerObjectItemFound) return explorerObjectItemFound;
            }
        }

        // return explorer object item
        return explorerObjectItemToFind;
    }
}

export const LinkGraphHelperSingleton = new LinkGraphHelper();