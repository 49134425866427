// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TAxiosParams, TCaseDTO, TIgorCaseMigrationDTO, TMigrateCaseResultDTO, TOrganizationDTO, TPhaseDTO, TTenantDTO } from "Types";

export class TenantController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/tenant`;

    public async createAsync(tenantName: string): Promise<TTenantDTO | undefined> {
        try {
            const response = await axios.post<TTenantDTO>(`${this._resourcePath}`, {
                name: tenantName
            });

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getAsync(tenantName?: string, hasAccessToUniverse?: boolean, doShowPendingMigrations = false, doFilterOutFindestMigrationTenant = false): Promise<TTenantDTO[]> {
        try {
            // prepare url
            const url = `${this._resourcePath}`;

            // Initialize params to give to axios
            const axiosParams: TAxiosParams = {};

            // add tenantName if provided
            if (tenantName) {
                axiosParams.tenantName = tenantName;
            }
            // add hasAccessToUniverse if provided
            if (hasAccessToUniverse) {
                axiosParams.hasAccessToUniverse = hasAccessToUniverse;
            }

            // add doShowPendingMigrations
            axiosParams.doShowPendingMigrations = doShowPendingMigrations;
            // add doFilterOutFindestMigrationTenant
            axiosParams.doFilterOutFindestMigrationTenant = doFilterOutFindestMigrationTenant;

            const response = await axios.get<TTenantDTO[]>(url, {
                params: axiosParams
            });

            if (response) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getOrganizationsAsync(tenantId: string, organizationName?: string): Promise<TOrganizationDTO[]> {
        try {
            // prepare url
            const url = `${this._resourcePath}/${tenantId}/organizations`;
            const axiosParams: TAxiosParams = {};

            // add organizationName if provided
            if (organizationName) {
                axiosParams.organizationName = organizationName;
            }

            // get organizations
            const response = await axios.get<TOrganizationDTO[]>(url, {
                params: axiosParams
            });

            // return organizations
            if (response) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getCasesAsync(tenantId: string, organizationId: string, caseTitle?: string): Promise<TCaseDTO[]> {
        try {
            // prepare url
            const url = `${this._resourcePath}/${tenantId}/organizations/${organizationId}/cases`;
            const axiosParams: TAxiosParams = {};

            // add caseTitle if provided
            if (caseTitle) {
                axiosParams.caseTitle = caseTitle;
            }

            // get cases
            const response = await axios.get<TCaseDTO[]>(url, {
                params: axiosParams
            });

            // return cases
            if (response) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getPhasesAsync(tenantId: string, organizationId: string, caseId: string): Promise<TPhaseDTO[]> {
        try {
            // prepare url
            const url = `${this._resourcePath}/${tenantId}/organizations/${organizationId}/cases/${caseId}/phases`;

            // get phases
            const response = await axios.get<TPhaseDTO[]>(url);

            // return phases
            if (response) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async migrateCaseAsync(toTenantId: string,
                igorCaseMigration: TIgorCaseMigrationDTO): Promise<TMigrateCaseResultDTO> {
        // init error message
        const errorMessage = "Failed to migrate case";
        try {
            // prepare url
            const url = `${this._resourcePath}/${toTenantId}/migratecase`;

            // migrate case
            const response = await axios.post<TMigrateCaseResultDTO>(url, igorCaseMigration);

            // return success or failure
            if (response) {
                return response.data;
            } else {
                return {
                    isSuccess: false,
                    message: errorMessage
                };
            }
        } catch {
            return {
                isSuccess: false,
                message: errorMessage
            };
        }
    }
    
    public async deleteAsync(tenantId: string): Promise<boolean> {
        try {
            const response = await axios.delete<TTenantDTO>(`${this._resourcePath}/${tenantId}`);

            return(!!response);
        } catch {
            return false;
        }
    }

    public async applyMigrationsAsync(tenantId: string): Promise<TTenantDTO | undefined> {
        try {
            const response = await axios.post<TTenantDTO>(`${this._resourcePath}/${tenantId}/ApplyMigrations`);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }
}

export const TenantControllerSingleton = new TenantController();