// node_modules
import { Mark, Node } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
// Components
import { findestSchema } from "Components";
// Enums
import { SpecialBlockClassNameEnum } from "Enums";

export const hasInlineStarsPlugin = () => {
    return new Plugin({
        props: {
            decorations: (state: EditorState) => {
                // init decorations array
                const decorations: Decoration[] = [];

                // define decorate function
                const decorate = (node: Node, pos: number) => {
                    // if a node is td
                    if (node.type.name === findestSchema.nodes.td.name) {
                        // go through td descendant nodes
                        node.descendants((tdNode: Node) => {
                            // if a node is a text node and has a inlineStars mark
                            if (tdNode.isText && 
                                    tdNode.marks.some((mark: Mark) => 
                                        mark.type.name === findestSchema.marks.inlineStars.name)) {
                                // add has inline stars class
                                decorations.push(
                                    Decoration.node(pos, pos + node.nodeSize, {
                                        class: `${SpecialBlockClassNameEnum.HasInlineStars}`
                                    })
                                );
                            }   
                        });
                    } else {
                        // remove has inline stars class
                        decorations.push(
                            Decoration.node(pos, pos + node.nodeSize, {
                                class: ""
                            })
                        );
                    }
                };

                // apply decorate function to doc descendants
                state.doc.descendants(decorate);

                // return decorations added while applying the decorate function
                return DecorationSet.create(state.doc, decorations);
            }
        }
    });
};