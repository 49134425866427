// node_modules
import { toast } from "react-hot-toast";
// Enums
import { ToastTypeEnum } from "Enums";
// Constants
import { ErrorConstants } from "Constants";

class ToastHelper {
    public defaultErrorMessage = ErrorConstants.DEFAULT_ERROR_MESSAGE;

    public showToast(toastType: ToastTypeEnum, message: string): void {
        if (!toastType || !message) toast.error(this.defaultErrorMessage);

        switch (toastType) {
            case ToastTypeEnum.Error:
                toast.error(message);
                break;
            case ToastTypeEnum.Success:
                toast.success(message);
                break;
            case ToastTypeEnum.Information:
                toast(message, {
                    icon: "ℹ️",
                });
                break;
            default:
                toast.error(this.defaultErrorMessage);
                break;
        }
    }
}

export const ToastHelperSingleton = new ToastHelper();
