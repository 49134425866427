// node_modules
import { ChangeEvent, FC } from "react";
// Components
import { Checkbox, FindestButton } from "Components";
// Styles
import styles from "./loginOptions.module.scss";

type TLoginOptionsProps = {
    email: string,
    onEmailInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
    isRememberMeChecked: boolean,
    onIsRememberMeCheckboxChange: (isChecked: boolean) => void,
    onNextButtonClickAsync: () => void
}

export const LoginOptions: FC<TLoginOptionsProps> = ({email, onEmailInputChange, isRememberMeChecked, onIsRememberMeCheckboxChange, onNextButtonClickAsync}) => {
    // Render
    return (
        <div className={styles.loginBox}>
            <h3 className={styles.title}>Login</h3>
            <div className={styles.emailBox}>
                <input
                    className={styles.emailInput}
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={onEmailInputChange}
                />
                <div className={styles.checkboxContainer}>
                    <Checkbox
                        isChecked={isRememberMeChecked}
                        onCheckboxChange={onIsRememberMeCheckboxChange}
                        id="rememberMe"
                        theme=""
                    />
                    <label htmlFor="rememberMe">Remember me</label>
                </div>
            </div>
            <div className={styles.footer}>
                <FindestButton extraClassName={[styles.button, styles.nextButton].join(" ")} title="Next" onClick={onNextButtonClickAsync} />
            </div>
        </div>
    );
};