// node_modules
import { faArrowDown, faArrowUp, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, MouseEvent, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
// Components
import { Checkbox, FiveRatingStars, LoadingStatusIndicator, MaturityLevel, RatingStar, RatingsPopover, RolesChecker, StopOnClickEventPropagation } from "Components";
// Enums
import { ObjectTypeEnum, RolesEnum, ScoutingServiceTableTypeEnum } from "Enums";
// Types
import { TRatingModelDTO, TScoutingServiceTableObject, TUpdateAssessmentScoreDTO } from "Types";
// Helpers
import { ScoutingServiceTableHelperSingleton, UserHelperSingleton } from "Helpers";
// Styles
import styles from "../scoutingServiceOptionTable.module.scss";
// Contexts
import { AuthContext } from "Providers";
import { ScoutingServiceTableCellTextContent } from "./ScoutingServiceTableCellTextContent";

// Component props type
type TScoutingServiceTableCellProps = {
    type: ScoutingServiceTableTypeEnum,
    tableObjectData: (TScoutingServiceTableObject | null)[][],
    tableCell: TScoutingServiceTableObject | null,
    rowIndex: number,
    columnIndex: number,
    requirementSummaryRequestedPerTableCell: Set<string>,
    headerTitles: string[],
    recursivelyGetAllBottomChildrenCount: (tableObject: TScoutingServiceTableObject, doSkipIsNotChecked?: boolean) => number,
    setTableObjectData?: Dispatch<SetStateAction<(TScoutingServiceTableObject | null)[][]>>,
    isNumbered: boolean,
    refreshRowHandler?: (objectData: TScoutingServiceTableObject | null) => void,
    refreshCellHandler?: (headerTitle: string, rowIndex: number) => void,
    selectedRowOrCell: string,
    setSelectedRowOrCell: Dispatch<SetStateAction<string>>,
    resetTableCellSelection: () => void,
    isRequestingRequirementSummary: boolean,
    isRatingEnabled?: boolean,
    getRatingStarRating?: (layerIndex: number) => number,
    objectIdEdited?: string,
    objectTypeEdited?: ObjectTypeEnum,
    isCellEditable?: boolean,
    maturityLevelPerEntityId?: Map<string, TUpdateAssessmentScoreDTO>,
    setMaturityLevelPerEntityId?: Dispatch<SetStateAction<Map<string, TUpdateAssessmentScoreDTO>>>,
    extraClassNames?: { cellActionsContainer?: string }
};

export function ScoutingServiceTableCell({ type, tableObjectData, tableCell, rowIndex, columnIndex,
    requirementSummaryRequestedPerTableCell, headerTitles, recursivelyGetAllBottomChildrenCount,
    setTableObjectData, isNumbered, refreshRowHandler, refreshCellHandler, selectedRowOrCell, setSelectedRowOrCell,
    resetTableCellSelection, isRequestingRequirementSummary, isRatingEnabled, getRatingStarRating,
    objectIdEdited, objectTypeEdited, isCellEditable = true, maturityLevelPerEntityId, setMaturityLevelPerEntityId, extraClassNames={} }: TScoutingServiceTableCellProps) {
    // Contexts
    const { auth } = useContext(AuthContext);

    // State
    const [isRatingsPopoverOpen, setIsRatingsPopoverOpen] = useState<boolean>(false);
    const [ratingScore, setRatingScore] = useState<number>(0);
    const [ratingCount, setRatingCount] = useState<number>(0);
    const [isRatingNeeded, setIsRatingNeeded] = useState<boolean>(false);
    const [ratingsPopoverReferenceElement, setRatingsPopoverReferenceElement] = useState<HTMLDivElement | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    // Ref
    const ratingStarsHoverTimer = useRef<NodeJS.Timeout | null>(null);

    // Memo
    const maturityLevelMin = useMemo((): number => {
        // safety-checks
        if (!tableCell || !maturityLevelPerEntityId || !setMaturityLevelPerEntityId || !tableCell.objectId ||
                !maturityLevelPerEntityId.has(tableCell.objectId)) {
            // stop execution, return 1
            return 1;
        }

        // get maturity level per entity id
        const maturityLevel: TUpdateAssessmentScoreDTO | undefined = 
            maturityLevelPerEntityId.get(tableCell.objectId);

        // safety-checks
        if (!maturityLevel) {
            // stop execution, return 1
            return 1;
        }

        // otherwise, return maturity level min
        return maturityLevel.lowScore;
    }, [maturityLevelPerEntityId, setMaturityLevelPerEntityId, tableCell]);

    const maturityLevelMax = useMemo((): number => {
        // safety-checks
        if (!tableCell || !maturityLevelPerEntityId || !setMaturityLevelPerEntityId || !tableCell.objectId ||
                !maturityLevelPerEntityId.has(tableCell.objectId)) {
            // stop execution, return 2
            return 2;
        }

        // get maturity level per entity id
        const maturityLevel: TUpdateAssessmentScoreDTO | undefined = 
            maturityLevelPerEntityId.get(tableCell.objectId);

        // safety-checks
        if (!maturityLevel) {
            // stop execution, return 2
            return 2;
        }

        // otherwise, return maturity level max
        return maturityLevel.highScore;
    }, [maturityLevelPerEntityId, setMaturityLevelPerEntityId, tableCell]);

    const isFiveStarsRatingNeeded = useMemo((): boolean => {
        // if user does not have access to ratings
        if (!UserHelperSingleton.hasAccessToRatings(auth)) {
            // return false
            return false;
        }

        // otherwise, return isRatingNeeded
        return isRatingNeeded;
    }, [auth, isRatingNeeded]);

    const doAllHeaderTitlesHaveValue = useMemo((): boolean => {
        // go through all header titles
        for (let index = 0; index < headerTitles.length; index++) {
            // get current header title
            const currentHeaderTitle = headerTitles[index];

            // if current header title is not defined
            if (!currentHeaderTitle) {
                // return false
                return false;
            }
        }

        // if not, return true
        return true;
    }, [headerTitles]);

    const relatedHeaderTitle = useMemo((): string => {
        // get and return header title
        return headerTitles[columnIndex];
    }, [columnIndex, headerTitles]);

    const doesRelatedHeaderTitleHaveDuplicates = useMemo((): boolean => {
        // go through all header titles
        for (let index = 0; index < headerTitles.length; index++) {
            // if index is different from columnIndex and it is not the first column
            if (index !== columnIndex && index !== 0){
                // get current header title
                const currentHeaderTitle = headerTitles[index];

                // if current header title is equal to header title
                if (currentHeaderTitle === relatedHeaderTitle) {
                    // if so, return true
                    return true;
                }
            }
        }

        // if not, return false
        return false;
    }, [columnIndex, headerTitles, relatedHeaderTitle]);

    const isThereAnyDuplicatedHeaderTitle = useMemo((): boolean => {
        // go through all header titles
        for (let index = 0; index < headerTitles.length; index++) {
            // get current header title
            const currentHeaderTitle = headerTitles[index];
            
            // go through all header titles
            for (let index2 = 0; index2 < headerTitles.length; index2++) {
                // if index is different from index2 and they are both not the first column
                if (index !== index2 && index !== 0 && index2 !== 0) {
                    // get current header title 2
                    const currentHeaderTitle2 = headerTitles[index2];

                    // if current header title is equal to current header title 2
                    if (currentHeaderTitle === currentHeaderTitle2) {
                        // if so, return true
                        return true;
                    }
                }
            }
        }

        // if not, return false
        return false;
    }, [headerTitles]);

    const isRefreshCellHandlerShown = useMemo((): boolean => {
        // show refresh cell handler if related header title is defined, does not have duplicates,
        // cell is checked and is not requesting requirement summary
        if (relatedHeaderTitle && !doesRelatedHeaderTitleHaveDuplicates && tableCell?.isChecked && !isRequestingRequirementSummary) {
            // return true
            return true;
        } else {
            // otherwise, return false
            return false;
        }
    }, [doesRelatedHeaderTitleHaveDuplicates, isRequestingRequirementSummary, relatedHeaderTitle, tableCell?.isChecked]);

    const isRefreshRowHandlerShown = useMemo((): boolean => {
        // show refresh row handler if all header titles have value, there is no duplicated header title,
        // cell is checked and is not requesting requirement summary
        if (!isThereAnyDuplicatedHeaderTitle && doAllHeaderTitlesHaveValue && tableCell?.isChecked && !isRequestingRequirementSummary) {
            // return true
            return true;
        } else {
            // otherwise, return false
            return false;
        }
    }, [doAllHeaderTitlesHaveValue, isRequestingRequirementSummary, isThereAnyDuplicatedHeaderTitle, tableCell?.isChecked]);

    const doShowTableCell = useMemo((): boolean => {
        // safety-checks
        if (!tableCell) {
            // stop execution, return false
            return false;
        }

        // if row index is greater than 0 (first row is header)
        // and table cell is not requirement value
        if (rowIndex > 0 && !tableCell.isRequirementValue) {
            // get table cell above
            const tableCellAbove = tableObjectData[rowIndex - 1][columnIndex];

            // if table cell above exists and table cell above and table cell have the same numbering and name
            if (tableCellAbove && ((tableCellAbove.numbering !== "" && tableCell.numbering !== "" &&
                tableCellAbove.numbering === tableCell.numbering) ||
                (tableCellAbove.numbering === "" && tableCell.numbering === "" &&
                    tableCellAbove.name === tableCell.name))) {
                // if table cell above has a parent and table cell has a parent and table cell above parent id is the same as table cell parent id
                if (tableCellAbove?.parent?.objectId && tableCellAbove?.parent?.objectId === tableCell?.parent?.objectId) {
                    // return false
                    return false;
                }
            }
        }

        // return true
        return true;
    }, [columnIndex, rowIndex, tableCell, tableObjectData]);

    const rowSpan = useMemo((): number => {
        // safety-checks
        if (!tableCell) {
            // stop execution, return 0
            return 0;
        }

        // calculate the size of the row span
        return recursivelyGetAllBottomChildrenCount(tableCell);
    }, [recursivelyGetAllBottomChildrenCount, tableCell]);

    const isLoading = useMemo((): boolean => {
        // get first object data of the row
        const firstObjectDataOfRow: TScoutingServiceTableObject | null = tableObjectData[rowIndex][0];

        // check if table cell is loading
        return firstObjectDataOfRow !== null &&
            firstObjectDataOfRow.objectId !== undefined &&
            columnIndex > 0 &&
            requirementSummaryRequestedPerTableCell.size > 0 &&
            requirementSummaryRequestedPerTableCell.has(`${headerTitles[columnIndex]}-${firstObjectDataOfRow.objectId}`);
    }, [columnIndex, headerTitles, requirementSummaryRequestedPerTableCell, rowIndex, tableObjectData]);

    const isEntity = useMemo((): boolean => {
        // safety-checks
        if (!tableCell) {
            // stop execution, return false
            return false;
        }

        // check if the object is an entity
        return tableCell.objectType === ObjectTypeEnum.Entity;
    }, [tableCell]);

    const tableCellSiblings = useMemo((): TScoutingServiceTableObject[] | undefined => {
        // safety-checks
        if (!tableCell) {
            // stop execution, return undefined
            return undefined;
        }

        // get table cell siblings
        return tableCell.parent?.children;
    }, [tableCell]);

    const isFirstCellOfParent = useMemo((): boolean => {
        // safety-checks
        if (!tableCell) {
            // stop execution, return false
            return false;
        }

        // check if the is the first cell of the parent
        let newIsFirstCellOfParent = rowIndex === 0 ? true : false;

        // if the table cell has a parent
        if (tableCellSiblings) {
            newIsFirstCellOfParent = newIsFirstCellOfParent || tableCellSiblings && tableCell.objectId === tableCellSiblings[0].objectId;
        }

        // if table cell has numbering
        if (tableCell.numbering) {
            newIsFirstCellOfParent = newIsFirstCellOfParent || tableCell.numbering === "1.";
        }

        // return new is first cell of parent
        return newIsFirstCellOfParent;
    }, [rowIndex, tableCell, tableCellSiblings]);

    const isLastCellOfParent = useMemo((): boolean => {
        // safety-checks
        if (!tableCell) {
            // stop execution, return false
            return false;
        }

        // check if is last cell of parent
        return rowIndex === tableObjectData.length - 1 || (tableCellSiblings !== undefined && tableCell.objectId === tableCellSiblings[tableCellSiblings.length - 1].objectId);
    }, [rowIndex, tableCell, tableCellSiblings, tableObjectData.length]);

    const getIsCheckboxShown = useCallback((currentTableCell: TScoutingServiceTableObject | null): boolean => {
        // if current table cell is not defined
        if (!currentTableCell) {
            // checkbox is not shown
            return false;
        }

        // if type of scouting service table is requirements table or maturity radar and current table cell is a study
        if ((type === ScoutingServiceTableTypeEnum.RequirementsTable || type === ScoutingServiceTableTypeEnum.MaturityRadar) && currentTableCell.objectType === ObjectTypeEnum.Study) {
            // checkbox is no shown
            return false;
        }

        // if current table cell is not requirement value and set table object data defined
        if (!currentTableCell.isRequirementValue && setTableObjectData) {
            // checkbox is shown
            return true;
        } else {
            // otheriwse, checkbox is not shown
            return false;
        }
    }, [setTableObjectData, type]);

    const getIsMaturityLevelShown = useCallback((currentTableCell: TScoutingServiceTableObject | null): boolean => {
        // if current table cell is not defined or is not checked
        if (!currentTableCell || !currentTableCell.isChecked) {
            // maturity level is not shown
            return false;
        }

        // if type of scouting service table is maturity radar and table cell is an entity
        if (type === ScoutingServiceTableTypeEnum.MaturityRadar && isEntity) {
            // maturity level is shown
            return true;
        } else {
            // maturity level is not shown
            return false;
        }
    }, [isEntity, type]);

    const getIsRatingStarShown = useCallback((currentTableCell: TScoutingServiceTableObject | null): boolean => {
        // if current table cell is not defined  or is not checked
        if (!currentTableCell || !currentTableCell.isChecked) {
            // rating star is not shown
            return false;
        }

        // if is rating enabled and is entity and get rating star rating defined
        if (isRatingEnabled && isEntity && getRatingStarRating) {
            // rating star is shown
            return true;
        } else {
            // rating star is not shown
            return false;
        }
    }, [getRatingStarRating, isEntity, isRatingEnabled]);

    // useEffect
    useEffect(() => {
        // safety-checks
        if (!tableCell || !tableCell.averageRating || !tableCell.ratings) {
            // stop execution, return
            return;
        }

        // set rating state values
        setRatingScore(tableCell.averageRating.score);
        setRatingCount(tableCell.averageRating.count);
        // init new is rating needed
        let newIsRatingNeeded = false;
        tableCell.ratings.map((tableCellRating: TRatingModelDTO) => {
            // if tableCellRating.user.fullname is equal to auth.userEmail
            // and tableCellRating.score is less than or equal to 0
            if (tableCellRating.user.fullname === auth.userEmail && tableCellRating.score <= 0) {
                // set new is rating needed to true
                newIsRatingNeeded = true;
            }
        });
        setIsRatingNeeded(newIsRatingNeeded);
    }, [auth.userEmail, tableCell]);

    // Logic
    const selectCurrentRowOrCell = useCallback((cellId: string, e: MouseEvent, hasOnlyOneAction: boolean | undefined) => {
        const target = e.target as HTMLElement;
        const currentTdElement = target.tagName === "td" ? target : target.closest("td");
        const tableElement = currentTdElement?.closest("table");
        const tableElementRect = tableElement?.getBoundingClientRect();
        const lastChild = currentTdElement?.lastChild as HTMLElement;

        const actionsBar = currentTdElement?.querySelector("div[id^='actionsBar_']") as HTMLElement;
        if (currentTdElement && tableElementRect && lastChild) {
            const currentRect = currentTdElement.getBoundingClientRect();

            resetTableCellSelection();
            lastChild.dataset.currentSelectionBox = "1";
            lastChild.style.display = "block";
            lastChild.style.height = `${Math.round(currentRect.height)}px`;
            const selectionBoxWidth = Math.round(tableElementRect.width - (currentRect.left - tableElementRect.left));
            lastChild.style.width = `${selectionBoxWidth}px`;
            if (actionsBar) {
                const actionsBarWidth = hasOnlyOneAction ? 116 : 238;
                actionsBar.style.left = `${selectionBoxWidth / 2 - actionsBarWidth / 2}px`;
            }
        }
        setSelectedRowOrCell(cellId);
    }, [resetTableCellSelection, setSelectedRowOrCell]);

    const onCellClick = useCallback((e: MouseEvent, currentRowIndex: number, currentColumnIndex: number, currentIsFirstCellOfParent: boolean | undefined, currentIsLastCellOfParent: boolean | undefined) => {
        if (!(currentIsFirstCellOfParent && currentIsLastCellOfParent)) {
            selectCurrentRowOrCell(`table-cell-${currentRowIndex}-${currentColumnIndex}`, e, currentIsFirstCellOfParent || currentIsLastCellOfParent);
        }
    }, [selectCurrentRowOrCell]);

    const moveUp = useCallback((currentRowIndex: number, currentColumnIndex: number): void => {
        // safety-checks
        if (!setTableObjectData) {
            // stop execution, return
            return;
        }

        // set table object data
        setTableObjectData(ScoutingServiceTableHelperSingleton.moveUp(tableObjectData, currentRowIndex, currentColumnIndex));
    }, [setTableObjectData, tableObjectData]);

    const moveDown = useCallback((currentRowIndex: number, currentColumnIndex: number): void => {
        // safety-checks
        if (!setTableObjectData) {
            // stop execution, return
            return;
        }

        // set table object data
        setTableObjectData(ScoutingServiceTableHelperSingleton.moveDown(tableObjectData, currentRowIndex, currentColumnIndex));
    }, [setTableObjectData, tableObjectData]);

    const onTableCheckboxChange = useCallback((currentRowIndex: number, currentColumnIndex: number) => {
        // safety-checks
        if (!setTableObjectData) {
            // stop execution, return
            return;
        }

        // set table object data
        setTableObjectData(ScoutingServiceTableHelperSingleton.onTableCheckboxChange(tableObjectData, currentRowIndex, currentColumnIndex));
    }, [setTableObjectData, tableObjectData]);

    const onNewAverageRating = useCallback((newCount: number, newScore: number, newIsRatingNeeded: boolean): void => {
        // set rating state values
        setRatingScore(newScore);
        setRatingCount(newCount);
        setIsRatingNeeded(newIsRatingNeeded);
    }, []);

    const onFiveRatingStarsMouseEnter = useCallback((): void => {
        // if user does not have access to ratings
        if (!UserHelperSingleton.hasAccessToRatings(auth)) {
            // stop execution, return
            return;
        }

        // otherwise, set is ratings popover open to true
        ratingStarsHoverTimer.current = setTimeout(() => {
            setIsRatingsPopoverOpen(true);
        }, 500);
    }, [auth]);

    const onFiveRatingStarsMouseLeave = useCallback((): void => {
        // if user does not have access to ratings
        if (!UserHelperSingleton.hasAccessToRatings(auth)) {
            // stop execution, return
            return;
        }

        // clear rating stars hover timer
        if (ratingStarsHoverTimer.current) {
            clearTimeout(ratingStarsHoverTimer.current);
            ratingStarsHoverTimer.current = null;
        }
        // otherwise, set is ratings popover open to false
        setIsRatingsPopoverOpen(false);
    }, [auth]);

    const onMaturityLevelChange = useCallback((newMin: number, newMax: number): void => {
        // safety-checks
        if (!setMaturityLevelPerEntityId || !tableCell || !tableCell.objectId) {
            // stop execution, return
            return;
        }

        // otherwise, set maturity level per entity id
        setMaturityLevelPerEntityId((previousMaturityLevelPerEntityId: Map<string, TUpdateAssessmentScoreDTO>) => {
            // safety-checks
            if (!tableCell || !tableCell.objectId) {
                // stop execution, return
                return previousMaturityLevelPerEntityId;
            }

            // init new entry
            const newEntry: TUpdateAssessmentScoreDTO = {
                lowScore: newMin,
                highScore: newMax
            };

            // init new maturity level per entity id
            const newMaturityLevelPerEntityId = new Map<string, TUpdateAssessmentScoreDTO>(previousMaturityLevelPerEntityId);

            // set new maturity level per entity id
            newMaturityLevelPerEntityId.set(tableCell.objectId, newEntry);

            // return new maturity level per entity id
            return newMaturityLevelPerEntityId;
        });
    }, [setMaturityLevelPerEntityId, tableCell]);

    const hideActionsBar = useCallback((): void => {
        // reset table cell selection
        resetTableCellSelection();

        // set selected row or cell to empty string
        setSelectedRowOrCell("");
    }, [resetTableCellSelection, setSelectedRowOrCell]);
    
    const onScoutingServiceTableCellTextContentChange = useCallback((newName: string): void => {
        // safety-checks
        if (!setTableObjectData) {
            return;
        }

        // set new table object data
        setTableObjectData((previousTableObjectData: (TScoutingServiceTableObject | null)[][]) => {
            // set new name on table cell
            previousTableObjectData[rowIndex][columnIndex] = {
                ...previousTableObjectData[rowIndex][columnIndex],
                name: newName
            };

            // return new table object data
            return [...previousTableObjectData];
        });
    }, [columnIndex, rowIndex, setTableObjectData]);

    // Render
    return (
        <>
            {tableCell ?
                isLoading ?
                    <td key={`table-cell-${rowIndex}-${columnIndex}`}>
                        <div className={styles.cellContent}>
                            <LoadingStatusIndicator size={24} status={1} />
                        </div>
                    </td>
                    :
                    doShowTableCell ?
                        <td onClick={tableCell.isRequirementValue || !isCellEditable ? undefined : (e) => { onCellClick(e, rowIndex, columnIndex, isFirstCellOfParent, isLastCellOfParent); }}
                            className={`${tableCell.isRequirementValue ? styles.useAbsolutePositioningForActions : ""} ${tableCell.isChecked ? "" : styles.disabled} ${((refreshCellHandler || refreshRowHandler) && (isRefreshCellHandlerShown || isRefreshRowHandlerShown)) ? styles.refreshable : ""}`}
                            rowSpan={rowSpan === 0 ? 1 : rowSpan} key={`table-cell-${rowIndex}-${columnIndex}`}
                        >
                            <div className={styles.cellContent}>
                                {(getIsCheckboxShown(tableCell)) && (
                                    <StopOnClickEventPropagation onClick={hideActionsBar}>
                                        <Checkbox isChecked={tableCell.isChecked} onCheckboxChange={() => { onTableCheckboxChange(rowIndex, columnIndex); }} />
                                    </StopOnClickEventPropagation>
                                )}
                                <ScoutingServiceTableCellTextContent
                                    isEditing={isEditing}
                                    setIsEditing={setIsEditing}
                                    isTableCellEditable={isCellEditable}
                                    onSourceChange={onScoutingServiceTableCellTextContentChange}
                                    isNumbered={isNumbered}
                                    tableCell={tableCell}
                                    isObjectChipClickable={!UserHelperSingleton.isSharingRestrictedToObject(auth)}
                                />
                                {(tableCell.averageRating && tableCell.ratings && isRatingEnabled && tableCell.objectId && type === ScoutingServiceTableTypeEnum.RequirementsTable) && (
                                    <StopOnClickEventPropagation extraClassNames={{ container: styles.fiveRatingStarsContainer }} onClick={hideActionsBar}>
                                        <div ref={setRatingsPopoverReferenceElement}>
                                            <FiveRatingStars
                                                isRatingEditable={false}
                                                count={ratingCount}
                                                isRatingNeeded={isFiveStarsRatingNeeded}
                                                score={ratingScore}
                                                onMouseEnter={onFiveRatingStarsMouseEnter}
                                                onMouseLeave={onFiveRatingStarsMouseLeave}>
                                                <RolesChecker roles={[RolesEnum.External]} isExcluding={true}>
                                                    <RatingsPopover
                                                        popoverPlacement="right-start"
                                                        firstPopoverRef={ratingsPopoverReferenceElement}
                                                        currentUserEmail={auth.userEmail}
                                                        isOpen={isRatingsPopoverOpen}
                                                        hideRatingsPopover={onFiveRatingStarsMouseLeave}
                                                        onMouseEnter={onFiveRatingStarsMouseEnter}
                                                        onMouseLeave={onFiveRatingStarsMouseLeave}
                                                        forSourceId={objectIdEdited}
                                                        forSourceType={objectTypeEdited}
                                                        forTargetId={tableCell.objectId}
                                                        onNewAverageRating={(_: string, __: string, newCount: number, newScore: number, newIsRatingNeeded: boolean) => { onNewAverageRating(newCount, newScore, newIsRatingNeeded); }} />
                                                </RolesChecker>
                                            </FiveRatingStars>
                                        </div>
                                    </StopOnClickEventPropagation>
                                )}
                                {(getIsMaturityLevelShown(tableCell)) && (
                                    <StopOnClickEventPropagation extraClassNames={{ container: styles.maturityLevelContainer }} onClick={hideActionsBar}>
                                        <MaturityLevel 
                                            isReadOnly={false}
                                            min={maturityLevelMin}
                                            max={maturityLevelMax}
                                            targetId={tableCell.objectId}
                                            targetType={tableCell.objectType} 
                                            onChange={onMaturityLevelChange}
                                        />
                                    </StopOnClickEventPropagation>
                                )}
                                {(isCellEditable && !isEditing) && (
                                    <StopOnClickEventPropagation
                                        onClick={hideActionsBar}
                                        extraClassNames={{ container: `${extraClassNames?.cellActionsContainer ?? ""} ${styles.cellActionsContainer}` }}
                                    >
                                        {(getIsRatingStarShown(tableCell) && getRatingStarRating) && <RatingStar size="medium" extraClassNames={{ container: styles.ratingStarContainer }} rating={getRatingStarRating(columnIndex)} />}
                                        {(isEntity) ?
                                            (refreshRowHandler && isRefreshRowHandlerShown) && (
                                                <div onClick={() => { refreshRowHandler(tableCell); }}
                                                    className={styles.tableHeaderCellRefreshContainer}>
                                                    <FontAwesomeIcon icon={faRotateRight} />
                                                </div>
                                            )
                                            :
                                            (refreshCellHandler && isRefreshCellHandlerShown) && (
                                                <div onClick={() => { refreshCellHandler(headerTitles[columnIndex], rowIndex); }}
                                                    className={styles.tableHeaderCellRefreshContainer}>
                                                    <FontAwesomeIcon icon={faRotateRight} />
                                                </div>
                                            )
                                        }
                                    </StopOnClickEventPropagation>
                                )}
                            </div>
                            {
                                (!tableCell.isRequirementValue && isCellEditable) && (
                                    <>
                                        <div id={`actionsBar_${rowIndex}_${columnIndex}`} className={`${styles.actionsBar} ${!(isFirstCellOfParent && isLastCellOfParent) && selectedRowOrCell === `table-cell-${rowIndex}-${columnIndex}` ? "" : styles.hidden}`}>
                                            {(isFirstCellOfParent || !moveUp) ? null : (
                                                <div className={styles.action} onClick={(e) => { e.stopPropagation(); resetTableCellSelection(); setSelectedRowOrCell(""); moveUp(rowIndex, columnIndex); }}>
                                                    <FontAwesomeIcon icon={faArrowUp} />
                                                    <span>Move up</span>
                                                </div>
                                            )}
                                            {(isLastCellOfParent || !moveDown) ? null : (
                                                <div className={styles.action} onClick={(e) => { e.stopPropagation(); resetTableCellSelection(); setSelectedRowOrCell(""); moveDown(rowIndex, columnIndex); }}>
                                                    <FontAwesomeIcon icon={faArrowDown} />
                                                    <span>Move down</span>
                                                </div>
                                            )}
                                        </div>
                                        <div id={`selectionBox_${rowIndex}_${columnIndex}`} className={styles.selectionBox} />
                                    </>
                                )
                            }
                        </td >
                        :
                        null
                :
                <td key={`table-cell-${rowIndex}-${columnIndex}`}></td>
            }
        </>
    );
}