// node_modules
import { IconDefinition, faBookOpenReader, faCircleQuestion, faDiceD6, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useMemo } from "react";
// Enums
import { LinksWindowTabsEnum, ObjectTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType, TUseDragAndDrop } from "Types";
// Styles
import styles from "./draggableNewObject.module.scss";

type TDraggableNewObjectProps = {
    linksWindowSelectedTab: LinksWindowTabsEnum,
    objectType: ObjectTypeEnum,
    useDragAndDropProps: TUseDragAndDrop
};

export const DraggableNewObject: FC<TDraggableNewObjectProps> = ({ objectType, useDragAndDropProps }: TDraggableNewObjectProps) => {
    // Memo
    // get new object icon depending on object type given
    const newObjectIcon = useMemo((): IconDefinition => {
        // switch on object type and return corresponding icon
        switch (objectType) {
            case ObjectTypeEnum.Entity:
                return faDiceD6;
            case ObjectTypeEnum.Study:
                return faBookOpenReader;
            default:
                return faCircleQuestion;
        }
    }, [objectType]);

    // get new object name depending on object type given
    const newObjectName = useMemo((): string => {
        // init object name
        let objectName = "New ";

        // switch on object type and set corresponding object name
        switch (objectType) {
            case ObjectTypeEnum.Entity:
                objectName += "entity";
                break;
            case ObjectTypeEnum.Study:
                objectName += "study";
                break;
            default:
                objectName += "unknown";
                break;
        }

        // return object name
        return objectName;
    }, [objectType]);

    const newObject = useMemo((): TIdNameTypeObjectType => {
        // init new object
        const object: TIdNameTypeObjectType = {
            id: "",
            name: newObjectName,
            objectType: objectType,
            type: "",
            customTypeName: ""
        };

        // return new object
        return object;
    }, [newObjectName, objectType]);

    // Render
    return (
        <div 
            draggable="true"
            onDragStart={() => { useDragAndDropProps.onDragStart(newObject, undefined); }}
            className={`${styles.draggableNewObjectContainer} ${objectType === ObjectTypeEnum.Entity ? styles.newEntity : ""} ${objectType === ObjectTypeEnum.Study ? styles.newStudy : ""}`}>
            <div>
                <div className={styles.typeContainer}>
                    <div className={styles.iconContainer}><FontAwesomeIcon className={styles.objectIcon} icon={newObjectIcon} /></div>
                    <h6 className={styles.typeText}>Type</h6>
                </div>
                <p className={styles.objectName}>{newObjectName}</p>
            </div>
            <FontAwesomeIcon className={styles.plusIcon} icon={faPlus} />
        </div>
    );
};