// node_modules
import { Dispatch, FC, SetStateAction } from "react";
// Styles
import styles from "./pubSubExplorerObjectSwitcher.module.scss";
// Components
import { ObjectSwitcher } from "Components";
// Types
import { TExplorerObjectItem, TIdNameTypeObjectType } from "Types";
// Custom hooks
import { useExplorerObjectNameChangeListener,useExplorerObjectConversionListener } from "Hooks";
// Helpers
import { LinkGraphHelperSingleton } from "Helpers";

// Component props type
type TPubSubExplorerObjectSwitcherProps = {
    objects?: TExplorerObjectItem[],
    setObjects: Dispatch<SetStateAction<TExplorerObjectItem[] | undefined>>,
    objectIdEdited?: string,
    selectedObjectId?: string,
    setSelectedObjectId: Dispatch<SetStateAction<string | undefined>>
}

// Component
export const PubSubExplorerObjectSwitcher: FC<TPubSubExplorerObjectSwitcherProps> = ({ objects, setObjects, objectIdEdited, selectedObjectId, setSelectedObjectId }: TPubSubExplorerObjectSwitcherProps) => {
    /** Listen to object name changes using websockets */
    useExplorerObjectNameChangeListener(setObjects);
    useExplorerObjectConversionListener(setObjects);
    
    // Render
    return (
            (objects && objects.length > 1) ? (
                <div className={styles.objectSwitcherContainer}>
                    <ObjectSwitcher
                        objects={objects ?? []}
                        objectIdEdited={objectIdEdited}
                        selectedObject={LinkGraphHelperSingleton.getExplorerObjectItemById(selectedObjectId, objects)}
                        onObjectSelect={(object: TIdNameTypeObjectType) => { setSelectedObjectId(object.id); }}
                    />
                </div>
            )
        :
            null
    );
};