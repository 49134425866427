// node_modules
import { Dispatch, SetStateAction, useCallback } from "react";
import cloneDeep from "lodash.clonedeep";
// Types
import { TExplorerObjectItem } from "Types";
// Hooks
import { useObjectNameChangeListener } from "Hooks";

export const useExplorerObjectNameChangeListener = (setItems: Dispatch<SetStateAction<TExplorerObjectItem[] | undefined>>) => {
    /** Object name changes handler */
    const onSingleNameChange = useCallback((objectId: string, name: string): void => {
        // find and update the object name
        const findItemAndUpdateName = (currentItems: TExplorerObjectItem[], objectIdToUpdate: string, updatedName: string) => {
            // go through each current item
            return currentItems.map((currentItem: TExplorerObjectItem) => {
                // if the current item is the one we are looking for
                if (currentItem.id === objectIdToUpdate) {
                    // update its name
                    currentItem.name = updatedName;
                }

                // if the item has children
                if (currentItem.lowerLevelNodes) {
                    // find and update the children
                    currentItem.lowerLevelNodes = findItemAndUpdateName(currentItem.lowerLevelNodes, objectIdToUpdate, updatedName);
                }

                // return the current item
                return currentItem;
            });
        };

        setItems((prevItems: TExplorerObjectItem[] | undefined) => {
            // if prev items is set
            if (prevItems) {
                // find and update the object name in prev items
                return cloneDeep(findItemAndUpdateName(prevItems, objectId, name));
            }

            // return prev items
            return prevItems;
        });
    }, [setItems]);
    
    /** Listen to object name changes using websockets */
    useObjectNameChangeListener(onSingleNameChange);
};