/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Components
import { FindestButton, MainTitle, RequirementsTableModal, RolesChecker, ScoutingServiceOptionTable } from "Components";
// Controllers
import { RequirementsTableControllerSingleton } from "Controllers";
// Constants
import { LinkingConstants } from "Constants";
// Enums
import { ObjectTypeEnum, RolesEnum, ScoutingServiceTableTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { DateHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import { TRequirementsTableEntryDTO, TScoutingServiceTableObject } from "Types";
// Styles
import styles from "./requirementsTable.module.scss";
// Contexts
import { EditorContext, LinksContext } from "Providers";

type TRequirementsTableProps = {
    objectType: ObjectTypeEnum,
    objectId: string,
    shouldShowEditingOptions?: boolean,
}

export const RequirementsTable: FC<TRequirementsTableProps> = ({ objectType, objectId, shouldShowEditingOptions }: TRequirementsTableProps) => {
    // Context
    const { isRequirementsTableModalOpen, setIsRequirementsTableModalOpen } = useContext(EditorContext);
    const { linkGraphForObjectEdited, requirementsTable, setRequirementsTable, initialRequirementsTableProps, refreshRequirementsTableAsync } = useContext(LinksContext); 
    
    // State
    const [isEditingOptionsVisible, setIsEditingOptionsVisible] = useState<boolean>(false);
    
    // Memo
    // convert requirementsTable to tableObjectData
    const convertedRequirementsTable = useMemo((): TScoutingServiceTableObject[][] | undefined => {
        // safety-checks
        if (!requirementsTable) {
            // stop execution, return undefined
            return undefined;
        }

        // init new requirementsTable
        const newRequirementsTable: TScoutingServiceTableObject[][] = [];

        // go through each table row
        for (let rowIndex = 0; rowIndex < requirementsTable.tableRows.length; rowIndex++) {
            // get table row
            const tableRow = requirementsTable.tableRows[rowIndex];

            // init new requirementsTableRow
            const newRequirementsTableRow: TScoutingServiceTableObject[] = [];

            // go through each table row entry
            for (const tableRowEntry of tableRow) {
                newRequirementsTableRow.push({
                    name: tableRowEntry.value.value ?? "",
                    isRequirementValue: tableRowEntry.value.objectType ? false : true,
                    averageRating: tableRowEntry.value.averageRating,
                    ratings: tableRowEntry.value.ratings,
                    objectType: tableRowEntry.value.objectType,
                    objectId: tableRowEntry.value.objectId,
                    isChecked: true,
                    numbering: tableRowEntry.value.objectType ? `${rowIndex + 1}.` : ""
                });
            }

            // add new requirementsTableRow to new requirementsTable
            newRequirementsTable.push(newRequirementsTableRow);
        }

        // return new requirementsTable
        return newRequirementsTable;
    }, [requirementsTable]);

    const doesObjectHaveRequirementsTable = useMemo(() => {
        // if requirements table is defined, requirementsTable.tableRows is defined, requirementsTable.tableRows length is greater than 0 and convertedRequirementsTable is defined
        return requirementsTable !== undefined && requirementsTable.tableRows && requirementsTable.tableRows.length > 0 && convertedRequirementsTable !== undefined;
    }, [convertedRequirementsTable, requirementsTable]);

    const shownHeaderTitles = useMemo((): string[] => {
        // safety-checks
        if (!requirementsTable || !requirementsTable.tableRows || !requirementsTable.tableRows[0]) {
            // return empty array
            return [];
        }

        // get all first entry in each entry of first row (i.e. initial table header titles)
        return requirementsTable.tableRows[0].map((firstRowEntry: TRequirementsTableEntryDTO) => firstRowEntry.key);
    }, [requirementsTable]);

    // get is editing requirements table
    const isEditingRequirementsTable = useMemo((): boolean => {
        //  if requirements table modal is open and object has requirements table
        return isRequirementsTableModalOpen && doesObjectHaveRequirementsTable;
    }, [doesObjectHaveRequirementsTable, isRequirementsTableModalOpen]);

    // Logic
    useEffect( () => {
        // safety-checks
        if (!linkGraphForObjectEdited) {
            // stop execution, return
            return;
        }

        // get requirements table
        (async () => {
            // refresh new requirements table
            await refreshRequirementsTableAsync(objectId, objectType, linkGraphForObjectEdited);
        })();

        return () => {
            // when component unmounts, close requirements table modal if it is open
            if (isRequirementsTableModalOpen) {
                setIsRequirementsTableModalOpen(false);
            }
        };
    }, [linkGraphForObjectEdited, objectId, objectType, refreshRequirementsTableAsync, setIsRequirementsTableModalOpen, isRequirementsTableModalOpen]);

    const changeRequirementsTableTitle = (newTitle: string) => {
        // safety-checks
        if (!requirementsTable) return;

        // update requirements table title
        requirementsTable.title = newTitle;

        // update requirements table 
        setRequirementsTable({ ...requirementsTable });
    };

    const onDeleteRequirementsTableClickAsync = useCallback(async (id: string, forObjectId: string, forObjectType: ObjectTypeEnum): Promise<boolean> => {
        // confirm delete
        if (!confirm("Are you sure you want to delete this requirements table?") || !linkGraphForObjectEdited) {
            // stop execution, return false
            return false;
        }

        // delete requirements table
        const isSuccess: boolean = await RequirementsTableControllerSingleton
            .deleteAsync(id);

        // safety-checks
        if (!isSuccess) {
            // show error message
            ToastHelperSingleton    
                .showToast(ToastTypeEnum.Error, "Could not delete requirements table.");
            // stop execution, return false
            return false;
        }

        // refresh requirements table
        await refreshRequirementsTableAsync(forObjectId, forObjectType, linkGraphForObjectEdited);

        // return true
        return true;
    }, [linkGraphForObjectEdited, refreshRequirementsTableAsync]);

    const showEditingOptions = () => {
        // safety-checks
        if (!shouldShowEditingOptions) return;

        // set is editing options visible
        setIsEditingOptionsVisible(true);
    };

    const hideEditingOptions = () => {
        // safety-checks
        if (!shouldShowEditingOptions) return;

        // set is editing options visible
        setIsEditingOptionsVisible(false);
    };
    
    return (
        <>
            {(doesObjectHaveRequirementsTable) && (
                <div
                    onMouseEnter={showEditingOptions}
                    onMouseLeave={hideEditingOptions}
                    className={styles.requirementsTableContainer}
                >
                    <div id={`${LinkingConstants.REQUIREMENTS_TABLE_HEADER_ID}_${objectId}`} className={styles.requirementsTableHeader}>
                        <MainTitle
                            title={requirementsTable!.title}
                            onUpdateTitle={changeRequirementsTableTitle}
                            shouldEditableInputAutoGrow
                            extraClassName={styles.requirementsTableTitle}
                        />
                        {shouldShowEditingOptions && (
                            <div className={styles.requirementsTableEditingDetails}>
                                {isEditingOptionsVisible && (
                                    <RolesChecker roles={[RolesEnum.Viewer, RolesEnum.External]} isExcluding={true}>
                                        <FindestButton title="Edit" leftIconName={faPencil} onClick={() => { setIsRequirementsTableModalOpen(true); }} buttonType="secondary" />
                                        <FindestButton title="Delete" leftIconName={faTrash} onClick={() => { onDeleteRequirementsTableClickAsync(requirementsTable!.id, objectId, objectType); }} buttonType="secondary" />
                                    </RolesChecker>
                                )}
                                <div className={styles.lastUpdatedDate}>
                                    <span>Last update</span>
                                    <div className={styles.dateAdded}>{DateHelperSingleton.getShortenedDate(requirementsTable!.updatedAt ?? requirementsTable!.createdAt)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <ScoutingServiceOptionTable
                        isTableEditable={false}
                        newColumnHeaderPlaceholder={""}
                        type={ScoutingServiceTableTypeEnum.RequirementsTable}
                        headerTitles={shownHeaderTitles}
                        isRatingEnabled={requirementsTable!.isRatingEnabled}
                        isRatingEnabledPerLayerIndex={new Map([[0, requirementsTable!.isRatingEnabled]])}
                        tableObjectData={convertedRequirementsTable!}
                        isNumbered={requirementsTable?.isNumbered ?? false}
                        recursivelyGetAllBottomChildrenCount={() => { return 1; }}
                        objectIdEdited={objectId}
                        objectTypeEdited={objectType}
                    />
                </div>
            )}
            <RequirementsTableModal
                objectIdEdited={objectId}
                objectTypeEdited={objectType}
                isOpen={isRequirementsTableModalOpen}
                setIsOpen={setIsRequirementsTableModalOpen}
                isEditing={isEditingRequirementsTable}
                initialTableProps={initialRequirementsTableProps} 
                onDeleteRequirementsTableClickAsync={doesObjectHaveRequirementsTable ? onDeleteRequirementsTableClickAsync : undefined}
                refreshRequirementsTableAsync={refreshRequirementsTableAsync} />
        </>
    );
};