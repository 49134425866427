// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TAddMaturityRadarDTO, TMaturityRadarDTO, TMaturityRadarsDTO, TUpdateAssessmentScoreDTO } from "Types";
// Enums
import { ObjectTypeEnum } from "Enums";

export class MaturityRadarController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/maturity-radar`;

    public async getAsync(objectId: string, 
            objectType: ObjectTypeEnum): Promise<TMaturityRadarDTO | undefined> {
        try {
            // get maturity radar
            const response = await axios.get<TMaturityRadarDTO>(`${this._resourcePath}/${objectType}/${objectId}`);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getAssessmentsAsync(objectId: string, objectType: ObjectTypeEnum): Promise<TMaturityRadarsDTO  | undefined> {
        try {
            // get maturity radar assessments
            const response = await axios.get<TMaturityRadarsDTO>(`${this._resourcePath}/${objectType}/${objectId}/assessments`);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async createAsync(addMaturityRadar: TAddMaturityRadarDTO): Promise<TMaturityRadarDTO | undefined> {
        try {
            // create maturity radar
            const response = await axios.post<TMaturityRadarDTO>(`${this._resourcePath}`, addMaturityRadar);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateAsync(maturityRadar: TMaturityRadarDTO): Promise<TMaturityRadarDTO | undefined> {
        try {
            // update maturity radar
            const response = await axios.patch<TMaturityRadarDTO>(`${this._resourcePath}/${maturityRadar.id}`, maturityRadar);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateAssessmentScoreAsync(id: string, updateAssessmentScore: TUpdateAssessmentScoreDTO): Promise<boolean> {
        try {
            // update assessment score
            const response = await axios.patch<TMaturityRadarDTO>(`${this._resourcePath}/${id}/change-score`, updateAssessmentScore);

            // deal with response
            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async deleteAsync(id: string): Promise<boolean> {
        try {
            // delete maturity radar
            const response = await axios.delete(`${this._resourcePath}/${id}`);

            // deal with response
            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const MaturityRadarControllerSingleton = new MaturityRadarController();
