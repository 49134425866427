// node_modules
import { FC, useCallback, useEffect, useState } from "react";
// Controllers
import { MaturityRadarControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Types
import { TMaturityRadarDTO, TMaturityRadarsDTO } from "Types";
// Components
import { MaturityLevel } from "Components";
// Helpers
import { MaturityLevelHelperSingleton, ToastHelperSingleton } from "Helpers";

// Component props type
type TEntityMaturityLevelsProps = {
    entityId: string,
    extraClassNames?: { container?: string }
};

export const EntityMaturityLevels: FC<TEntityMaturityLevelsProps> = ({ entityId, extraClassNames }: TEntityMaturityLevelsProps) => {
    // State
    const [maturityLevels, setMaturityLevels] = useState<TMaturityRadarDTO[]>([]);
    const [averageMin, setAverageMin] = useState<number | undefined>(undefined);
    const [averageMax, setAverageMax] = useState<number | undefined>(undefined);

    // Logic
    const refreshEntityMaturityLevelsAsync = useCallback(async (id: string): Promise<void> => {
        // get new maturity levels
        const newMaturityLevels: TMaturityRadarsDTO | undefined = await MaturityRadarControllerSingleton
            .getAssessmentsAsync(id, ObjectTypeEnum.Entity);

        // safety-checks
        if (!newMaturityLevels) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Failed to get maturity levels.");
            // stop execution, return
            return; 
        }

        // get new average
        const { averageMin: newAverageMin, averageMax: newAverageMax } = MaturityLevelHelperSingleton
            .getAverage(newMaturityLevels.maturityRadars, id);

        // get sanitized values
        const { newSafeMin: newSafeAverageMin, newSafeMax: newSafeAverageMax } = MaturityLevelHelperSingleton
            .sanitizeValues(newAverageMin, newAverageMax);
        
        // set average min and max
        setAverageMin(newSafeAverageMin);
        setAverageMax(newSafeAverageMax);

        // set maturity levels
        setMaturityLevels(newMaturityLevels.maturityRadars);
    }, []);

    useEffect(() => {
        (async () => {
            // refresh maturity levels
            await refreshEntityMaturityLevelsAsync(entityId);
        })();
    }, [entityId, refreshEntityMaturityLevelsAsync]);

    // Render
    return (
        (maturityLevels && maturityLevels.length > 0 && averageMin && averageMax) ?
                <MaturityLevel
                    min={averageMin}
                    max={averageMax}
                    showStepsTexts
                    isReadOnly={true}
                    targetId={entityId}
                    targetType={ObjectTypeEnum.Entity}
                    maturityLevels={maturityLevels}
                    setMaturityLevels={setMaturityLevels}
                    extraClassNames={extraClassNames} />
            :
                null
    );
};