// Extensions
import StateInline from "markdown-it/lib/rules_inline/state_inline";
import Token from "markdown-it/lib/token";
// Enums
import { CustomDOMAttributes, CustomDOMTag, CustomInlineMarkerEnum, CustomInlineNameEnum, CustomMarkdownSeparatorEnum, SpecialBlockClassNameEnum } from "Enums";
// Extensions
import { CommonInlineMarkExtension } from "./CommonInlineMarkExtension";

const parseContentBetweenMarkers = (stateInline: StateInline, content: string): boolean => {
    // get last added token 
    const lastToken = stateInline.tokens[stateInline.tokens.length - 1];

    // safety-checks 
    if(!content || !lastToken || lastToken.type !== "inlineStars_open") { 
        return false; 
    }

    // try to get source id from content
    const sourceIdIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_STARS_SOURCE_ID);
    let sourceId: string | undefined | null = undefined;
    // if sourceIdIndex was found
    if (sourceIdIndex >= 0) {
        // try to get the source id
        sourceId = content.slice(sourceIdIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_SOURCE_ID, sourceIdIndex + 1));
    }
    // remove source id from content
    content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_SOURCE_ID) + 1);

    // try to get target id from content
    const targetIdIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_STARS_TARGET_ID);
    let targetId: string | undefined | null = undefined;
    // if target id was found
    if (targetIdIndex >= 0) {
        // try to get the target id
        targetId = content.slice(targetIdIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_TARGET_ID, targetIdIndex + 1));
    }
    // remove target id from content
    content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_TARGET_ID) + 1);

    // try to get rating from content
    const ratingIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_STARS_RATING);
    let rating: string | undefined | null = undefined;
    // if rating was found
    if (ratingIndex >= 0) {
        // try to get the rating
        rating = content.slice(ratingIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_RATING, ratingIndex + 1));
    }
    // remove rating from content
    content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_RATING) + 1);

    // try to get number of raters
    const numberOfRatersIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_STARS_NUMBER_OF_RATERS);
    let numberOfRaters: string | undefined | null = undefined;
    // if number of raters was found
    if (numberOfRatersIndex >= 0) {
        // try to get the number of raters
        numberOfRaters = content.slice(numberOfRatersIndex + 2, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_NUMBER_OF_RATERS, numberOfRatersIndex + 2));
    }
    // remove number of raters from content
    content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_NUMBER_OF_RATERS) + 2);

    // try to get is rating needed from content
    const isRatingNeededIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_STARS_IS_RATING_NEEDED);
    let isRatingNeeded: string | undefined | null = undefined;
    // if is rating needed was found
    if (isRatingNeededIndex >= 0) {
        // try to get the is rating needed
        isRatingNeeded = content.slice(isRatingNeededIndex + 2, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_IS_RATING_NEEDED, isRatingNeededIndex + 2));
    }
    // remove is rating needed from content
    content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_STARS_IS_RATING_NEEDED) + 2);

    // safety-checks
    if (sourceId === null || sourceId === undefined || targetId === null || targetId === undefined || rating === null || rating === undefined || numberOfRaters === null || numberOfRaters === undefined || isRatingNeeded === null || isRatingNeeded === undefined) {
        return false;
    }

    // add attributes to last token
    lastToken.attrs = [
        ["class", `${SpecialBlockClassNameEnum.Stars}`],
        ["min", "0"],
        ["max", "5"],
        [`${CustomDOMAttributes.StarsRating}`, `${rating}`],
        [`${CustomDOMAttributes.StarsSourceId}`, `${sourceId}`],
        [`${CustomDOMAttributes.StarsTargetId}`, `${targetId}`],
        [`${CustomDOMAttributes.StarsNumberOfRaters}`, `${numberOfRaters}`],
        [`${CustomDOMAttributes.StarsIsRatingNeeded}`, `${isRatingNeeded}`]
    ];

    const contentToken = stateInline.push("text", "", 0);
    contentToken.content = `${numberOfRaters}`;

    // return true
    return true;
};

const renderStartCommonMark = (tokens: Token[], index: number): string => {
    // get token
    const token = tokens[index];

    // safety-checks
    if(!token || token.type !== `${CustomInlineNameEnum.InlineStars}_open`) {
        return CustomDOMTag.Meter;
    }

    // get attributes from "inlineStars_open" token
    const attributes = token.attrs;

    // safety-checks
    if(!attributes) {
        return CustomDOMTag.Meter;
    }

    // get value of each attribute
    const classValue: string | null | undefined = attributes.find((attribute) => attribute[0] === "class")?.[1];
    const min: string | null | undefined = attributes.find((attribute) => attribute[0] === "min")?.[1];
    const max: string | null | undefined = attributes.find((attribute) => attribute[0] === "max")?.[1];
    const rating: string | null | undefined = attributes.find((attribute) => attribute[0] === `${CustomDOMAttributes.StarsRating}`)?.[1];
    const sourceId: string | null | undefined = attributes.find((attribute) => attribute[0] === `${CustomDOMAttributes.StarsSourceId}`)?.[1];
    const targetId: string | null | undefined = attributes.find((attribute) => attribute[0] === `${CustomDOMAttributes.StarsTargetId}`)?.[1];
    const numberOfRaters: string | null | undefined = attributes.find((attribute) => attribute[0] === `${CustomDOMAttributes.StarsNumberOfRaters}`)?.[1];
    const isRatingNeeded: string | null | undefined = attributes.find((attribute) => attribute[0] === `${CustomDOMAttributes.StarsIsRatingNeeded}`)?.[1];

    // safety-checks
    if (classValue === null || classValue === undefined || min === null || min === undefined || max === null || max === undefined || rating === null || 
            rating === undefined || sourceId === null || sourceId === undefined || targetId === null || targetId === undefined || numberOfRaters === null || 
            numberOfRaters === undefined || isRatingNeeded === null || isRatingNeeded === undefined) {
        return CustomDOMTag.Meter;
    }

    // return custom tag with attributes
    return `<${CustomDOMTag.Meter}
        class = "${classValue}"
        min = "${min}"
        max = "${max}"
        ${CustomDOMAttributes.StarsRating} = "${rating}"
        ${CustomDOMAttributes.StarsSourceId} = "${sourceId}"
        ${CustomDOMAttributes.StarsTargetId} = "${targetId}"
        ${CustomDOMAttributes.StarsNumberOfRaters} = "${numberOfRaters}"
        ${CustomDOMAttributes.StarsIsRatingNeeded} = "${isRatingNeeded}">`;
};

export const inlineStarsExtension = CommonInlineMarkExtension(CustomInlineNameEnum.InlineStars, CustomInlineMarkerEnum.Stars, CustomDOMTag.Meter, parseContentBetweenMarkers, renderStartCommonMark);