import React, { useState, useRef, useContext } from "react";
import styles from "./tenantSelector.module.scss";
//icons
import {
  faGlobe,
  faChevronDown,
  faSearch,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//components
import { FindestTextBox, FindestButton } from "Components";
//providers
import { AuthContext } from "Providers";

type TenantSelectorProps = {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  text: string;
  setText: (text: string) => void;
};

const TenantSelector: React.FC<TenantSelectorProps> = ({
  isEditing,
  setIsEditing,
  setText,
}) => {
  const { auth } = useContext(AuthContext);

  const handleInputChange = (text: string) => {
    setIsEditing(true);
    setText(text);
  };

  return (
    <div className={styles.editableComboBox}>
      {isEditing ? (
        <div className={styles.inputContainer}>
          <FindestTextBox
            placeholder=""
            doAutoFocus={true}
            onChange={handleInputChange}
            value={""}
            extraClassNameInputElement={styles.input}
            extraClassNames={{ iconContainerLeft: styles.iconContainerLeft }}
            leftIcon={faSearch}
          />
          <FindestButton
            leftIconName={faClose}
            extraClassName={styles.clearButton}
            data-testid="clear-icon"
            onClick={() => {
              setText("");
              setIsEditing(false);
            }}
          ></FindestButton>
        </div>
      ) : (
        <div
          className={styles.placeholder}
          onClick={() => {
            setIsEditing(true);
          }}
          aria-hidden="true"
        >
          <FontAwesomeIcon icon={faGlobe} className={styles.leftIcon} />
          <p className={styles.tenantText}>{auth.tenantName}</p>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={styles.rightIcon}
            onClick={() => {
              setText("");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TenantSelector;
