// node_modules
import axios from "axios";
// Types
import { TMonitoringStatusDTO } from "Types";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class StatusController {

    /**
     * Fetches the current active maintenance status.
     */
    public async fetchCurrentStatusAsync(): Promise<TMonitoringStatusDTO | undefined> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/status/maintenance-report`;

            // make request
            const response = await axios.get<TMonitoringStatusDTO>(url);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }
}

export const StatusControllerSingleton = new StatusController();