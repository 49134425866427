import { TTKeyAndName } from "Types";
import styles from "./selectableTextList.module.scss";

type SelectableTextListProps<T> = {
    items: TTKeyAndName<T>[],
    onItemSelect: (key: T) => void,
    selectedItemKey?: T,
}

export function SelectableTextList<T>( { items, onItemSelect, selectedItemKey } :SelectableTextListProps<T>) {

    return (
        <div className={styles.selectableTextList}>
            {items.map(item => {
                return (
                    <div
                        className={`${styles.selectableTextListItem} ${selectedItemKey === item.key ? styles.selected : ""}`}
                        key={item.keyAsString}
                        onClick={() => { onItemSelect(item.key); }}
                    >
                        {item.name}
                    </div>
                );
            })}
        </div>
    );
}