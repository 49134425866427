// React
import { FC } from "react";
// Types
import { TLinkedToDTO, TListItem } from "Types";
import { ListItem } from "../Lists";

type TLinkedObjectItemProps = {
    linkedTo: TLinkedToDTO,
    isSelected?: boolean,
    onUnlinkItemClick?: (linkedTo: TLinkedToDTO) => void,
}

export const LinkedObjectItem: FC<TLinkedObjectItemProps> = ({linkedTo, 
        isSelected, onUnlinkItemClick}: TLinkedObjectItemProps) => {
    
    const linkedObjectListItem: TListItem = {
        id: linkedTo.id,
        title: linkedTo.name,
        objectType: linkedTo.objectType,
        description: linkedTo.description,
        conclusion: linkedTo.conclusion,
        linkedCounts: linkedTo.linkedCounts,
        dateAdded: linkedTo.dateAdded,
        topText: linkedTo.type,
        createdByUsername: linkedTo.createdByUsername ? linkedTo.createdByUsername : "",
    };

    return (
        <ListItem<TLinkedToDTO> itemObject={linkedTo} listItem={linkedObjectListItem} 
            isSelected={isSelected} onUnlinkItemClick={onUnlinkItemClick} />
    );
};