// node_modules
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  MouseEvent,
} from "react";
import {
  faChevronDown,
  faChevronUp,
  faArrowLeftLong,
  faArrowUpWideShort,
  faDownload,
} from "@fortawesome/pro-solid-svg-icons";
// Constants
import { WebsocketFunctionNames } from "Constants";
// Enums
import {
  QueryResultViewTypeEnum,
  QuerySortOptionsEnum,
  QueryViewOptionsEnum,
  SearchQueryTypeEnum,
} from "Enums";
// Helpers
import {
  QuerySortOptionsHelperSingleton,
  QueryViewOptionsHelperSingleton,
} from "Helpers";
// Hooks
import { useClickOutsideRef, usePagination } from "Hooks";
// Contexts
import { QueryViewOptionsContext, WebsocketContext } from "Providers";
// Types
import {
  TGroupedDocumentSearchResult,
  TGroupedDocumentSearchResults,
  TPublicationsPerYearDTO,
  TReadDocumentDTO,
  TSearchTermHitsCountsDTO,
  TUniverseAffiliationResult,
  TUniverseDocumentSearchStrategyResult,
} from "Types";
// Components
import {
  Checkbox,
  FindestButton,
  Paginator,
  Popover,
  LoadingStatusIndicator,
  ExportSearchResultsButton,
} from "Components";
import {
  AffiliationsList,
  DocumentSearchResult,
  PublicationsPerYear,
  AffiliationsGroupedDirectory,
} from "./SearchResults";
import { SearchMetadataBubble } from "./SearchResults/SearchMetadataBubble";
import { SortButton } from "./SortOptions/SortButton";
// Interfaces
import { IDocumentSearchResult, IQueryDTO } from "Interfaces";
// Styles
import styles from "./querySearchResults.module.scss";
// Controllers
import { ReadDocumentsControllerSingleton } from "Controllers";

export type TQuerySearchResultsProps = {
  query: IQueryDTO;
  isSearching: boolean;
  setIsSearching: (isSearching: boolean) => void;
  searchResultAmount: number;
  pageNumber: number;
  onPaginate: (
    isToNextPage: boolean,
    alreadyPagedNames: string[],
    sortQuery?: string
  ) => void;
  setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>;
  isQueryShown: boolean;
  searchQueryType: SearchQueryTypeEnum;
  setSearchQueryType: Dispatch<SetStateAction<SearchQueryTypeEnum>>;
  onSearchClick: (
    currentSearchQueryType: SearchQueryTypeEnum,
    showSearchResults: boolean,
    isGroupedSearchParam?: boolean,
    filteredResultNames?: string[],
    groupName?: string,
    sortQuery?: string
  ) => void;
  setRunningSearchId: (runningSearchId: string | undefined) => void;
  cancelSearch: () => void;
};

export const QuerySearchResults: FC<TQuerySearchResultsProps> = ({
  query,
  isSearching,
  setIsSearching,
  pageNumber,
  searchResultAmount,
  onPaginate,
  setQuery,
  isQueryShown,
  searchQueryType,
  setSearchQueryType,
  onSearchClick,
  setRunningSearchId,
  cancelSearch,
}) => {
  // Context
  const { webSocketController } = useContext(WebsocketContext);
  const { allQueryViewOptions, setSelectedViewOptions, selectedViewOptions } =
    useContext(QueryViewOptionsContext);

  // State
  const [documentResults, setDocumentResults] = useState<
    IDocumentSearchResult[]
  >([]);
  const [groupedDocumentResults, setGroupedDocumentResults] = useState<
    TGroupedDocumentSearchResult[]
  >([]);
  const [publicationsPerYear, setPublicationsPerYear] =
    useState<TPublicationsPerYearDTO | undefined>(undefined);
  const [isViewOptionsDropdownShown, setIsViewOptionsDropdownShown] =
    useState<boolean>(false);
  const [alreadyPagedNames, setAlreadyPagedNames] = useState<string[]>([]);
  const [isScrollPositionTop, setIsScrollPositionTop] = useState<boolean>(true);
  const [affiliationSearchResults, setAffiliationSearchResults] =
    useState<TUniverseAffiliationResult | null>(null);
  const [title, setTitle] = useState<string>("Top 10 affiliations");
  const [queryResultView, setQueryResultView] =
    useState<QueryResultViewTypeEnum>(QueryResultViewTypeEnum.DocumentResult);
  const [selectedGroupName, setSelectedGroupName] = useState<string>("");
  const [isSortOptionsDropdownShown, setIsSortOptionsDropdownShown] =
    useState<boolean>(false);
  const [sort, setSort] = useState<string>(
    QuerySortOptionsHelperSingleton.getQuerySortOptionSearchValue(
      QuerySortOptionsEnum.Relevance
    )
  );
  const [querySearchSortTitle, setQuerySearchSortTitle] = useState<string>(
    QuerySortOptionsHelperSingleton.getQuerySortOptionDisplayValue(
      QuerySortOptionsEnum.Relevance
    )
  );
  const [filteredDocumentResults, setFilteredDocumentResults] = useState<
    IDocumentSearchResult[]
  >([]);

  // Ref
  const querySearchResultsViewOptionsContainer = useRef<HTMLDivElement>(null);
  const sortOptionsDropdownContainer = useRef<HTMLDivElement>(null);

  // Constants
  // dropdown sort options
  const sortOptions = [
    QuerySortOptionsEnum.Relevance,
    QuerySortOptionsEnum.PublicationDate,
    QuerySortOptionsEnum.CitationScore,
  ];

  // Custom pagination hook
  const onPaginateResultsAsync = useCallback(
    async (isToNextPage: boolean): Promise<void> => {
      // Get the current page names
      let newAlreadyPagedNames = alreadyPagedNames;

      if (!isToNextPage) {
        // If we are going back, we need to drop the last paged names
        // If the amount of already paged names is a multiple of the search result amount, we need to drop the
        // search result amount, otherwise we need to drop the remainder
        const amountToDrop =
          alreadyPagedNames.length % searchResultAmount === 0
            ? searchResultAmount
            : alreadyPagedNames.length % searchResultAmount;
        newAlreadyPagedNames = newAlreadyPagedNames.slice(
          0,
          newAlreadyPagedNames.length - amountToDrop
        );
      } else {
        // If we are going forward, we need to add the new paged names
        newAlreadyPagedNames = [
          ...newAlreadyPagedNames,
          ...groupedDocumentResults.map(
            (groupedResult) => groupedResult.groupName
          ),
        ];
      }

      // Paginate and save the new paged names
      onPaginate(isToNextPage, newAlreadyPagedNames, sort);
      setAlreadyPagedNames(newAlreadyPagedNames);
    },
    [
      alreadyPagedNames,
      onPaginate,
      sort,
      searchResultAmount,
      groupedDocumentResults,
    ]
  );

  const {
    paginateToNextPageAsync,
    paginateToPreviousPageAsync,
    setTotalCount,
    currentPageNumber,
    isLastPage,
    paginationIndicator,
    setCurrentPageNumber,
    isLoading,
  } = usePagination(searchResultAmount, 0, onPaginateResultsAsync);
  // If the user presses the search button the page is reset to 1, so we need to reset the current page number
  useEffect(() => {
    if (pageNumber === 1) {
      setCurrentPageNumber(1);
    }
  }, [pageNumber, setCurrentPageNumber]);

  // Custom hooks
  useClickOutsideRef(querySearchResultsViewOptionsContainer, () => {
    setIsViewOptionsDropdownShown(false);
  });

  useClickOutsideRef(sortOptionsDropdownContainer, () => {
    setIsSortOptionsDropdownShown(false);
  });

  const setIsAlreadyReadOnDocumentResultsAsync = useCallback(
    async (
      onDocumentResults: IDocumentSearchResult[]
    ): Promise<IDocumentSearchResult[]> => {
      // get current user read documents
      const readDocuments: TReadDocumentDTO[] =
        await ReadDocumentsControllerSingleton.getMyAsync();

      // set is already read on on document results
      const newOnDocumentResults = onDocumentResults.map(
        (onDocumentResult: IDocumentSearchResult) => {
          // check if on document result is in read documents
          const isAlreadyRead = readDocuments.some((readDocument) => {
            return readDocument.documentId === onDocumentResult.documentId;
          });

          // return new on document result
          return {
            ...onDocumentResult,
            isAlreadyRead,
          };
        }
      );

      // return new on document results
      return newOnDocumentResults;
    },
    []
  );

  // Handlers
  const onReceiveDocumentSearchResults = useCallback(
    async (data: TUniverseDocumentSearchStrategyResult) => {
      setIsSearching(false);
      setRunningSearchId(undefined);
      if (!data.documentResults) {
        return;
      }

      // set is already read on document results
      data.documentResults = await setIsAlreadyReadOnDocumentResultsAsync(
        data.documentResults
      );

      // set document results
      setDocumentResults(data.documentResults);
      // set filtered document results
      setFilteredDocumentResults(data.documentResults);
    },
    [setIsSearching, setRunningSearchId, setIsAlreadyReadOnDocumentResultsAsync]
  );

  const onReceiveGroupedSearchResults = useCallback(
    (data: TGroupedDocumentSearchResults) => {
      setIsSearching(false);
      setRunningSearchId(undefined);
      if (!data.groupedResults) {
        return;
      }
      data.groupedResults.sort((a, b) => b.documentCount - a.documentCount);
      setGroupedDocumentResults(data.groupedResults);
    },
    [setIsSearching, setGroupedDocumentResults, setRunningSearchId]
  );

  useEffect(() => {
    if (isSearching) {
      setDocumentResults([]);
      setGroupedDocumentResults([]);
      setFilteredDocumentResults([]);
      setPublicationsPerYear(undefined);
      setAffiliationSearchResults(null);
    }
  }, [isSearching]);

  const updateDocument = (document: IDocumentSearchResult) => {
    const newDocumentResults = documentResults.map((doc) => {
      if (doc.documentId === document.documentId) {
        return document;
      }
      return {
        ...doc,
      };
    });
    setDocumentResults(newDocumentResults);
    const newFilteredDocumentResults = filteredDocumentResults.map((doc) => {
      if (doc.documentId === document.documentId) {
        return document;
      }
      return {
        ...doc,
      };
    });
    setFilteredDocumentResults(newFilteredDocumentResults);
  };

  const onReceiveDocumentCount = useCallback(
    (documentCount: number) => {
      setTotalCount(documentCount);
    },
    [setTotalCount]
  );

  const onReceiveSearchTermHitCounts = useCallback(
    (searchTermHitsCounts: TSearchTermHitsCountsDTO) => {
      // set search term hit counts on the query
      setQuery((prevQuery: IQueryDTO | undefined) => {
        // safety-checks
        if (!prevQuery) {
          return prevQuery;
        }
        // set the search term hit counts
        return {
          ...prevQuery,
          searchTermHitsCounts,
        };
      });
    },
    [setQuery]
  );

  const onReceiveAffiliationSearchResults = useCallback(
    (data: TUniverseAffiliationResult) => {
      setAffiliationSearchResults(data);
    },
    [setAffiliationSearchResults]
  );

  const onReceiveSearchId = useCallback(
    (searchId: string) => {
      setRunningSearchId(searchId);
    },
    [setRunningSearchId]
  );

  const onReceivePublicationsPerYearSearchResults = useCallback(
    (data?: TPublicationsPerYearDTO) => {
      // set publications per year using the data
      setPublicationsPerYear(data);
    },
    [setPublicationsPerYear]
  );

  const filterDocumentResults = (doKeepReadResults: boolean) => {
    if (doKeepReadResults) {
      setFilteredDocumentResults(documentResults);
    } else {
      setFilteredDocumentResults(
        documentResults.filter((doc) => !doc.isAlreadyRead)
      );
    }
  };

  useEffect(() => {
    const documentSearchResultsListenName = `${WebsocketFunctionNames.ReceiveDocumentSearchResults}-${query.guid}`;
    const documentCountListenName = `${WebsocketFunctionNames.ReceiveDocumentCount}-${query.guid}`;
    const groupedDocumentSearchResultsListenName = `${WebsocketFunctionNames.GroupedDocumentSearchResults}-${query.guid}`;
    const searchTermHitCountsListenName = `${WebsocketFunctionNames.ReceiveSearchTermHitCounts}-${query.guid}`;
    const receiveSearchIdListenName = `${WebsocketFunctionNames.ReceiveSearchId}-${query.guid}`;
    const receivePublicationsPerYearSearchResults = `${WebsocketFunctionNames.ReceivePublicationsPerYearSearchResults}-${query.guid}`;
    const affiliationSearchResultListenName = `${WebsocketFunctionNames.ReceiveAffiliationSearchResults}-${query.guid}`;

    webSocketController.addHandler(
      documentSearchResultsListenName,
      onReceiveDocumentSearchResults
    );
    webSocketController.addHandler(
      documentCountListenName,
      onReceiveDocumentCount
    );
    webSocketController.addHandler(
      groupedDocumentSearchResultsListenName,
      onReceiveGroupedSearchResults
    );
    webSocketController.addHandler(
      searchTermHitCountsListenName,
      onReceiveSearchTermHitCounts
    );
    webSocketController.addHandler(
      receiveSearchIdListenName,
      onReceiveSearchId
    );
    webSocketController.addHandler(
      receivePublicationsPerYearSearchResults,
      onReceivePublicationsPerYearSearchResults
    );
    webSocketController.addHandler(
      affiliationSearchResultListenName,
      onReceiveAffiliationSearchResults
    );

    return () => {
      webSocketController.removeHandler(
        documentSearchResultsListenName,
        onReceiveDocumentSearchResults
      );
      webSocketController.removeHandler(
        documentCountListenName,
        onReceiveDocumentCount
      );
      webSocketController.removeHandler(
        groupedDocumentSearchResultsListenName,
        onReceiveGroupedSearchResults
      );
      webSocketController.removeHandler(
        searchTermHitCountsListenName,
        onReceiveSearchTermHitCounts
      );
      webSocketController.removeHandler(
        receiveSearchIdListenName,
        onReceiveSearchId
      );
      webSocketController.removeHandler(
        receivePublicationsPerYearSearchResults,
        onReceivePublicationsPerYearSearchResults
      );
      webSocketController.removeHandler(
        affiliationSearchResultListenName,
        onReceiveAffiliationSearchResults
      );
    };
  }, [
    onReceiveDocumentCount,
    onReceiveDocumentSearchResults,
    onReceiveGroupedSearchResults,
    onReceiveAffiliationSearchResults,
    onReceivePublicationsPerYearSearchResults,
    onReceiveSearchId,
    onReceiveSearchTermHitCounts,
    query.guid,
    webSocketController,
  ]);

  // when searchQueryType changes
  useEffect(() => {
    // set title based on search query type
    switch (searchQueryType) {
      case SearchQueryTypeEnum.UniverseScienceArticles:
        setTitle("Top 10 affiliations");
        break;
      case SearchQueryTypeEnum.UniversePatents:
        setTitle("Top 10 assignees");
        break;
      default:
        break;
    }
  }, [searchQueryType]);

  const toggleViewOptions = () => {
    setIsViewOptionsDropdownShown(!isViewOptionsDropdownShown);
  };

  const toggleSortOptions = () => {
    setIsSortOptionsDropdownShown(!isSortOptionsDropdownShown);
  };

  const sortBy = useCallback(
    (sortOption: QuerySortOptionsEnum) => {
      onSearchClick(
        searchQueryType,
        true,
        undefined,
        undefined,
        undefined,
        QuerySortOptionsHelperSingleton.getQuerySortOptionSearchValue(
          sortOption
        )
      );
      setSort(
        QuerySortOptionsHelperSingleton.getQuerySortOptionSearchValue(
          sortOption
        )
      );
      setIsSortOptionsDropdownShown(false);
      setQuerySearchSortTitle(
        QuerySortOptionsHelperSingleton.getQuerySortOptionDisplayValue(
          sortOption
        )
      );
    },
    [onSearchClick, searchQueryType]
  );

  const setViewOptions = useCallback(
    (isChecked: boolean, viewOption: QueryViewOptionsEnum) => {
      const newViewOptions = new Set(selectedViewOptions);
      if (isChecked) {
        newViewOptions.add(viewOption);
      } else {
        newViewOptions.delete(viewOption);
      }

      QueryViewOptionsHelperSingleton.saveActivatedViewOptions(
        Array.from(newViewOptions)
      );
      setSelectedViewOptions(newViewOptions);
    },
    [selectedViewOptions, setSelectedViewOptions]
  );

  const toggleSearchResultsDocumentType = () => {
    // set publications per year to undefined
    setPublicationsPerYear(undefined);
    // set affiliation search results to null
    setAffiliationSearchResults(null);

    if (searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) {
      setSearchQueryType(SearchQueryTypeEnum.UniversePatents);
      onSearchClick(
        SearchQueryTypeEnum.UniversePatents,
        true,
        undefined,
        undefined,
        undefined,
        sort
      );
    } else if (searchQueryType === SearchQueryTypeEnum.UniversePatents) {
      setSearchQueryType(SearchQueryTypeEnum.UniverseScienceArticles);
      onSearchClick(
        SearchQueryTypeEnum.UniverseScienceArticles,
        true,
        undefined,
        undefined,
        undefined,
        sort
      );
    } else {
      return;
    }
  };

  const showAllClicked = async () => {
    setIsSearching(true);
    setQueryResultView(QueryResultViewTypeEnum.AffiliationResult);
    onSearchClick(searchQueryType, true, true, undefined, undefined, sort);
  };

  const scrollEvent = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollTop > 0) {
      setIsScrollPositionTop(false);
    } else {
      setIsScrollPositionTop(true);
    }
  };

  /** get query dates based on the search query type */
  const getQueryDates = (): { startDate: string; endDate: string } => {
    switch (searchQueryType) {
      case SearchQueryTypeEnum.UniverseScienceArticles:
        return {
          startDate: query.filters.scienceFilters.startDate ?? "",
          endDate: query.filters.scienceFilters.endDate ?? "",
        };
      case SearchQueryTypeEnum.UniversePatents:
        return {
          startDate: query.filters.patentFilters.startDate ?? "",
          endDate: query.filters.patentFilters.endDate ?? "",
        };
      default:
        return {
          startDate: "",
          endDate: "",
        };
    }
  };

  const onBackClicked = () => {
    if (queryResultView == QueryResultViewTypeEnum.GroupedDocumentResult) {
      setQueryResultView(QueryResultViewTypeEnum.AffiliationResult);
      showAllClicked();
    } else {
      setQueryResultView(QueryResultViewTypeEnum.DocumentResult);
      onSearchClick(searchQueryType, true);
    }
  };

  const onGroupNameClicked = async (groupName: string) => {
    //create an array of resultNames without selected
    setIsSearching(true);
    setQueryResultView(QueryResultViewTypeEnum.GroupedDocumentResult);
    const resultNames = groupedDocumentResults?.map(
      (result) => result.groupName
    );
    const index = resultNames?.indexOf(groupName);
    const filteredResultNames = resultNames?.filter((name, i) => i !== index);
    setSelectedGroupName(groupName);
    const documentSearchResultsListenName = `${WebsocketFunctionNames.ReceiveGroupedDocuments}-${groupName}-${query.guid}`;
    await webSocketController.addHandler(
      documentSearchResultsListenName,
      onReceiveDocumentSearchResults
    );
    onSearchClick(
      searchQueryType,
      true,
      false,
      filteredResultNames,
      groupName,
      undefined
    );

    return () => {
      webSocketController.removeHandler(
        documentSearchResultsListenName,
        onReceiveDocumentSearchResults
      );
    };
  };

  return (
    <div
      className={[
        styles.querySearchResultsContainer,
        isQueryShown ? "" : styles.active,
      ].join(" ")}
    >
      {queryResultView === QueryResultViewTypeEnum.AffiliationResult && (
        <div className={styles.queryAffiliationsResult}>
          <div className={styles.queryAffiliationHeader}>
            <FindestButton
              title="Back"
              buttonType="quarternary"
              extraClassName={styles.backButton}
              leftIconName={faArrowLeftLong}
              onClick={onBackClicked}
            />
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>Top Affiliations</h1>
              {!isSearching && (
                <SearchMetadataBubble
                  text={groupedDocumentResults.length}
                  isSmall
                />
              )}
            </div>
          </div>
          <div className={styles.divider}></div>
          {isSearching && (
            <div className={styles.loadingIndicatorContainer}>
              <LoadingStatusIndicator size={60} status={1} />
              <FindestButton
                onClick={cancelSearch}
                buttonType="tertiary"
                title="Cancel search"
              />
            </div>
          )}
          <AffiliationsGroupedDirectory
            groupedDocumentSearchResult={groupedDocumentResults}
            handleGroupedDocumentClick={onGroupNameClicked}
          />
        </div>
      )}
      {queryResultView === QueryResultViewTypeEnum.DocumentResult && (
        <>
          <div
            className={[
              styles.querySearchResultsContentHeader,
              isSearching ? styles.isSearching : "",
            ].join(" ")}
          >
            <div
              className={[
                styles.scrollingShadow,
                isScrollPositionTop ? "" : styles.isScrolling,
              ].join(" ")}
            ></div>
            <div className={styles.querySearchResultsTypeTabs}>
              <div
                className={[
                  styles.querySearchResultsTypeTab,
                  searchQueryType ===
                  SearchQueryTypeEnum.UniverseScienceArticles
                    ? styles.active
                    : null,
                ].join(" ")}
                onClick={toggleSearchResultsDocumentType}
              >
                <h4>Science</h4>
              </div>
              <div
                className={[
                  styles.querySearchResultsTypeTab,
                  searchQueryType === SearchQueryTypeEnum.UniversePatents
                    ? styles.active
                    : null,
                ].join(" ")}
                onClick={toggleSearchResultsDocumentType}
              >
                <h4>Patents</h4>
              </div>
            </div>
            <div className={styles.querySearchResultsContentOptions}>
              <ExportSearchResultsButton
                icon={faDownload}
                searchResults={filteredDocumentResults}
                buttonType={"tertiary"}
                tooltipText={"Export search results to CSV"}
                filename={"search_results.csv"}
              />
              <div
                ref={querySearchResultsViewOptionsContainer}
                className={styles.querySearchResultsViewOptions}
              >
                <FindestButton
                  title="View options"
                  buttonType={"tertiary"}
                  rightIconName={
                    isViewOptionsDropdownShown ? faChevronUp : faChevronDown
                  }
                  onClick={toggleViewOptions}
                />
                <Popover
                  referenceEl={querySearchResultsViewOptionsContainer.current}
                  isOpen={isViewOptionsDropdownShown}
                  placement="bottom-end"
                >
                  <div className={styles.querySearchResultsViewOptionsDropdown}>
                    <h6>General</h6>
                    <Checkbox
                      text="Matched terms"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isMatchedTermsViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.MatchedTerms
                        );
                      }}
                    />
                    <Checkbox
                      text="Relevance Score"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isRelevanceScoreViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.RelevanceScore
                        );
                      }}
                    />
                    <Checkbox
                      text="Publication date"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isPublicationDateViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.PublicationDate
                        );
                      }}
                    />
                    <Checkbox
                      text="Read results"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isAlreadyReadViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.AlreadyRead
                        );
                        filterDocumentResults(isChecked);
                      }}
                    />
                    <h6>Science</h6>
                    <Checkbox
                      text="Citation score"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isCitationScoreViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.CitationScore
                        );
                      }}
                    />
                    <Checkbox
                      text="Affiliation"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isAffiliationViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.Affiliation
                        );
                      }}
                    />
                    <Checkbox
                      text="Author"
                      theme="textLabel"
                      isChecked={allQueryViewOptions.isAuthorViewOptionChecked}
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(isChecked, QueryViewOptionsEnum.Author);
                      }}
                    />
                    <h6>Patents</h6>
                    <Checkbox
                      text="Filing date"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isFilingDateViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.FilingDate
                        );
                      }}
                    />
                    <Checkbox
                      text="Patent number"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isPatentNumberViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.PatentNumber
                        );
                      }}
                    />
                    <Checkbox
                      text="Patent country"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isPatentCountryViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.PatentCountry
                        );
                      }}
                    />
                    <Checkbox
                      text="Assignee"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isAssigneeViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.Assignee
                        );
                      }}
                    />
                    <Checkbox
                      text="Inventor"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isInventorViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.Inventor
                        );
                      }}
                    />
                  </div>
                </Popover>
              </div>
              <div
                ref={sortOptionsDropdownContainer}
                className={styles.querySearchResultSortBy}
              >
                <FindestButton
                  title={querySearchSortTitle}
                  leftIconName={faArrowUpWideShort}
                  rightIconName={
                    isSortOptionsDropdownShown ? faChevronUp : faChevronDown
                  }
                  buttonType="tertiary"
                  onClick={toggleSortOptions}
                />
                <Popover
                  referenceEl={sortOptionsDropdownContainer.current}
                  isOpen={isSortOptionsDropdownShown}
                  placement="bottom-end"
                >
                  <div>
                    <h6>Sort by</h6>
                    {sortOptions.map((sortOption) => (
                      <SortButton
                        key={sortOption}
                        sortOption={sortOption}
                        currentSort={sort}
                        onSortBy={sortBy}
                      />
                    ))}
                  </div>
                </Popover>
              </div>
              <Paginator
                isPaginating={isLoading}
                paginationIndicator={paginationIndicator}
                currentPageNumber={currentPageNumber}
                isLastPage={isLastPage}
                paginateToNextPage={paginateToNextPageAsync}
                paginateToPreviousPage={paginateToPreviousPageAsync}
              />
            </div>
          </div>
          <div className={styles.querySearchResultLayout}>
            <div
              className={styles.querySearchResultsContentContainer}
              onScroll={scrollEvent}
            >
              {isSearching && (
                <div className={styles.loadingIndicatorContainer}>
                  <LoadingStatusIndicator size={60} status={1} />
                  <FindestButton
                    onClick={cancelSearch}
                    buttonType="tertiary"
                    title="Cancel search"
                  />
                </div>
              )}
              {filteredDocumentResults.map((document) => {
                return (
                  <DocumentSearchResult
                    key={document.documentId}
                    document={document}
                    doIncludeSaveButton={true}
                    updateDocument={updateDocument}
                    queryViewOptions={allQueryViewOptions}
                  />
                );
              })}
            </div>
            <div className={styles.querySearchResultsExtraContentContainer}>
              <PublicationsPerYear
                startDate={getQueryDates().startDate}
                endDate={getQueryDates().endDate}
                publicationsPerYear={publicationsPerYear}
              />
              <AffiliationsList
                affiliationSearchResults={affiliationSearchResults}
                title={title}
                showAllClicked={showAllClicked}
              />
            </div>
          </div>
        </>
      )}
      {queryResultView === QueryResultViewTypeEnum.GroupedDocumentResult && (
        <div className={styles.queryAffiliationsResult}>
          <div className={styles.queryAffiliationHeader}>
            <FindestButton
              title="Back"
              buttonType="quarternary"
              extraClassName={styles.backButton}
              leftIconName={faArrowLeftLong}
              onClick={onBackClicked}
            />
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>{selectedGroupName}</h1>
              {!isSearching && (
                <SearchMetadataBubble
                  text={`${filteredDocumentResults.length} ${
                    filteredDocumentResults.length === 1
                      ? "document"
                      : "documents"
                  }`}
                  isSmall
                />
              )}
            </div>
          </div>
          <div className={styles.querySearchResultLayout}>
            <div
              className={`${styles.querySearchResultsContentContainer} ${styles.groupedDocumentResults}`}
              onScroll={scrollEvent}
            >
              {isSearching && (
                <div className={styles.loadingIndicatorContainer}>
                  <LoadingStatusIndicator size={60} status={1} />
                  <FindestButton
                    onClick={cancelSearch}
                    buttonType="tertiary"
                    title="Cancel search"
                  />
                </div>
              )}
              {filteredDocumentResults.map((document) => {
                return (
                  <DocumentSearchResult
                    key={document.documentId}
                    document={document}
                    doIncludeSaveButton={true}
                    updateDocument={updateDocument}
                    queryViewOptions={allQueryViewOptions}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
