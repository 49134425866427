// node_modules
import { ChangeEvent, FC } from "react";
// Components
import { FindestButton } from "Components";
// Styles
import styles from "./loginWithPassword.module.scss";

type TLoginWithPasswordProps = {
    password: string,
    onPasswordInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
    onForgotYourPasswordButtonClickAsync: () => Promise<void>,
    onLoginButtonClickAsync: () => Promise<void>
}

export const LoginWithPassword: FC<TLoginWithPasswordProps> = ({password, onPasswordInputChange, onForgotYourPasswordButtonClickAsync, onLoginButtonClickAsync}) => {
    // Render
    return (
        <div className={styles.loginBox}>
            <h3 className={styles.title}>Login with password</h3>
            <div className={styles.body}>
                <input placeholder="Password" className={styles.emailInput} type="password" value={password} onChange={onPasswordInputChange} />
                <div className={styles.forgotYourPasswordContainer}>
                    <button type="button" className={styles.forgotYourPasswordButton} onClick={onForgotYourPasswordButtonClickAsync}>Forgot your password?</button>
                </div>
            </div>
            <div className={styles.footer}>
                <FindestButton extraClassName={[styles.button, styles.nextButton].join(" ")} title="Login" onClick={onLoginButtonClickAsync} />
            </div>
        </div>
    );
};