export enum OtherMarkdownCustomBlockNameEnum {
    ReferenceLink = "reference_link",
    ImageReferenceImage = "image_reference_img",
    ImageReferenceFigcaption = "image_reference_figcaption",
    ImageReferenceFigcaptionTextContainer = "image_reference_figcaption_text_container",
    ImageReferenceFigcaptionText = "image_reference_figcaption_text",
    FileReferenceLink = "file_reference_link",
    FileReferenceLinkTitle = "file_reference_link_title",
    FileReferenceLinkExtension  = "file_reference_link_extension",
    IntakeSheetConfirmationAccepted = "intake_sheet_confirmation_accepted",
    IntakeSheetConfirmationNotAccepted = "intake_sheet_confirmation_not_accepted",
    Title = "title",
    Button = "button"
}

export const OtherMarkdownCustomBlockNameEnumValues = Object.values(OtherMarkdownCustomBlockNameEnum);
export const OtherMarkdownCustomBlockNameEnumStrings = Object.values(OtherMarkdownCustomBlockNameEnum).map(v => v.toString());