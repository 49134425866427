// node_modules
import { ChangeEvent, FC, FocusEvent, useEffect, useState } from "react";

type TEditableTextInputProps = {
    label: string,
    placeholder?: string,
    isReadonly?: boolean,
    onLabelChange: (newLabelValue: string, oldLabelValue: string) => void,
    onInputFocus?: (event: FocusEvent) => void
}

export const EditableTextInput: FC<TEditableTextInputProps> = ({label, placeholder, isReadonly,
        onLabelChange, onInputFocus}: TEditableTextInputProps) => {
    // State
    const [currentLabel, setCurrentLabel] = useState<string>(label);

    useEffect(() => {
        setCurrentLabel(label);
    }, [label]);

    useEffect(() => {
        // when current label state is edited,
        // after 500 ms do:
        const delayDebounceFn = setTimeout(() => {
            // remove white spaces at the start and end of the new label
            const newCurrentLabel = currentLabel.trim();
            // if new label is null/empty/undefined, set new current label to the old one
            if (newCurrentLabel === null || newCurrentLabel === undefined) {
                setCurrentLabel(label);
                return;
            }
            // if the new label is the same as the old one, do nothing
            if (newCurrentLabel.trim().toLowerCase() === label.trim().toLowerCase()) return;
            // set label state
            setCurrentLabel(newCurrentLabel);
            // call parent on label change
            onLabelChange(newCurrentLabel, label);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [currentLabel, label, onLabelChange]);

    const handleOnLabelChange = (newLabelValue: string): void => {
        setCurrentLabel(newLabelValue);
    };
    
    return (
        <input type="text" placeholder={placeholder} value={currentLabel} disabled={isReadonly}
            onChange={(event: ChangeEvent<HTMLInputElement>) => { handleOnLabelChange(event.target.value); }} 
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => { if (onInputFocus) onInputFocus(event); }} />
    );
};