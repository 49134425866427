// node_modules
import { Dispatch, DragEvent, FC, SetStateAction, useCallback, useContext } from "react";
// Contexts
import { AuthContext, ElementVisibilityContext, LinkGraphContext, RecentActivityContext } from "Providers";
// Components
import { CollapsibleLinksList, ObjectItem } from "Components";
// Types
import { TCheckedLinks, TLinkGraphNodeDTO, TUseDragAndDrop, fromTLinkGraphNodeDTO, fromTRecentSidebarActivityItemDTO } from "Types";
// Styles
import { ObjectTypeEnum } from "Enums";
// Helpers
import { LogHelperSingleton } from "Helpers";
// Styles
import styles from "./linksListView.module.scss";

type TLinksListViewProps = {
    useDragAndDropProps: TUseDragAndDrop,
    onReanchorClick: (newObjectIdEdited?: string, newObjectTypeEdited?: ObjectTypeEnum) => void,
    setCheckedLinks: Dispatch<SetStateAction<TCheckedLinks[]>>,
}

export const LinksListView: FC<TLinksListViewProps> = ({ useDragAndDropProps, onReanchorClick, setCheckedLinks }: TLinksListViewProps) => {
    // Context 
    const { linkGraphForFocusedNode, openObjectReference } = useContext(LinkGraphContext);
    const { isUserExternal} = useContext(AuthContext);
    const { mySimpleRecentActivity } = useContext(RecentActivityContext);
    const { canUserEdit } = useContext(ElementVisibilityContext);

    // Logic
    // add to checked links
    const addToCheckedLinks = useCallback((from: TLinkGraphNodeDTO, to: TLinkGraphNodeDTO) => {
        // add entry to checked links
        setCheckedLinks((previousCheckedLinks: TCheckedLinks[]) => {
            return [...previousCheckedLinks, { from, to }];
        });
    }, [setCheckedLinks]);

    // remove from checked links
    const removeFromCheckedLinks = useCallback((from: TLinkGraphNodeDTO, to: TLinkGraphNodeDTO) => {
        // remove entry from checked links
        setCheckedLinks((previousCheckedLinks: TCheckedLinks[]) => {
            return previousCheckedLinks.filter((currentCheckedLink: TCheckedLinks) => {
                return (currentCheckedLink.from.id !== from.id || currentCheckedLink.to.id !== to.id);
            });
        });
    }, [setCheckedLinks]);

    const openItemInModal = useCallback((objectId: string, objectType: ObjectTypeEnum) => {
        // call open object reference context method to open object in modal
        openObjectReference(objectId, objectType);

        // log
        LogHelperSingleton.log("LinksListViewClickNavigateToItem");
    }, [openObjectReference]);

    // Render
    // get links list view starting from link graph for focused node
    return (
        <div className={styles.linkedListContainer} onDragLeave={useDragAndDropProps.onDragLeave}>
            {(linkGraphForFocusedNode && linkGraphForFocusedNode.focusedNode) ? 
                <ul onDragOver={(dragEvent: DragEvent) => { useDragAndDropProps.onDragOver(dragEvent, linkGraphForFocusedNode.focusedNode); }}>
                    <li
                        onDragOver={(dragEvent: DragEvent) => { useDragAndDropProps.onDragOver(dragEvent, linkGraphForFocusedNode.focusedNode); }}
                        onDrop={useDragAndDropProps.onDrop}
                        className={`${useDragAndDropProps.isObjectDraggedOver(linkGraphForFocusedNode.focusedNode.id) ? styles.isDraggedOver : undefined} ${styles.linkedListContent}`}>
                        <div className={styles.focusedObjectItem}>
                            <ObjectItem 
                                key={`linksListView_focusedObjectItem_${linkGraphForFocusedNode.focusedNode.id}`}
                                objectItem={fromTLinkGraphNodeDTO(linkGraphForFocusedNode.focusedNode)}
                                extraClassName={styles.objectItem}
                                onDoubleClickEditable={() => { openItemInModal(linkGraphForFocusedNode.focusedNode.id, linkGraphForFocusedNode.focusedNode.objectType); }}
                                onDoubleClick={() => { onReanchorClick(linkGraphForFocusedNode.focusedNode.id, linkGraphForFocusedNode.focusedNode.objectType); }}
                                isNavigateToObjectItemDisabled={true}
                                isEditable={canUserEdit && !isUserExternal}
                                isUserExternal={!canUserEdit || isUserExternal}
                            />
                        </div>
                        {(linkGraphForFocusedNode.lowerLevelNodes && linkGraphForFocusedNode.lowerLevelNodes.length > 0) && (
                            <CollapsibleLinksList 
                                upperLevelNode={linkGraphForFocusedNode.focusedNode}
                                linkGraphNodes={linkGraphForFocusedNode.lowerLevelNodes} 
                                addToCheckedLinks={addToCheckedLinks} 
                                removeFromCheckedLinks={removeFromCheckedLinks} 
                                useDragAndDropProps={useDragAndDropProps}
                                isUserExternal={!canUserEdit || isUserExternal}
                                openItemInModal={openItemInModal}
                            />
                        )}
                    </li>
                </ul>
            :
                <div className={styles.noFocusContainer}>
                    <h6>Please navigate or select one of your recent active studies and entities below to load the list view.</h6>
                    {mySimpleRecentActivity.map((recentActivity) => (
                        <ObjectItem
                            key={recentActivity.id}
                            objectItem={fromTRecentSidebarActivityItemDTO(recentActivity)}
                            onClick={ () => onReanchorClick(recentActivity.id, recentActivity.objectType) }
                            isEditable={false}
                            isUserExternal={!canUserEdit || isUserExternal}
                            extraClassName={styles.noFocusRecentActive} />
                    ))}
                </div>
            }
        </div>
    );
};