// Controllers
import { EntityControllerSingleton } from "Controllers";
// Enums
import { EntityTypeEnum } from "Enums";
// Types
import { TOption, TOptions } from "Types";
// Constants
import { EntityConstants } from "Constants";

export class EntityTypeHelper {
    private entityTypeStringToEnumMapping: { [name: string]: EntityTypeEnum } = {
        "": EntityTypeEnum.Undefined,
        "undefined": EntityTypeEnum.Undefined,
        "no type": EntityTypeEnum.Undefined,
        "technology": EntityTypeEnum.Technology,
        "material": EntityTypeEnum.Material,
        "process": EntityTypeEnum.Process,
        "method": EntityTypeEnum.Method,
        "ingredient": EntityTypeEnum.Ingredient,
        "chemical": EntityTypeEnum.Chemical,
        "product": EntityTypeEnum.Product,
        "partner": EntityTypeEnum.Partner,
        "supplier": EntityTypeEnum.Supplier,
        "competitor": EntityTypeEnum.Competitor,
        "create custom type": EntityTypeEnum.Custom,
        "custom": EntityTypeEnum.Custom
    };

    private getEntityNameTypePairs(): TOption<EntityTypeEnum>[] {
        return Object
            .keys(EntityTypeEnum)
            .filter((key) => Number.isNaN(Number(key)))
            .map((key: string) => ({
                value: this.entityTypeStringToEnum(key),
                title: this.getEntityTypeDisplayName(this.entityTypeStringToEnum(key))
            }));
    }

    public defaultEntityTypeOption: TOption<EntityTypeEnum> = {
        value: EntityTypeEnum.Undefined,
        title: this.getEntityTypeDisplayName(EntityTypeEnum.Undefined)
    };

    public async getCustomTypeDropdownOptionsGroupAsync(doIncludeCreateCustomTypeOption: boolean, 
            doIncludeConvertToStudy: boolean): Promise<TOptions<EntityTypeEnum>[]> {
        let allEntityTypeOptions = this.entityTypesDropdownOptions;

        const getCustomTypeNamesResult: string[] | undefined = await EntityControllerSingleton.getCustomTypeNamesAsync();
        const customTypeNames: string[] = getCustomTypeNamesResult ?? [];
        const customTypeOptions: TOption<EntityTypeEnum>[] = customTypeNames.map((customTypeName: string) => ({
            value: EntityTypeEnum.Custom,
            title: customTypeName
        }));

        const customEntityTypeDropdownOptionsGroup: TOptions<EntityTypeEnum> = {
            group: "Custom entity types",
            options: customTypeOptions
        };

        if (doIncludeCreateCustomTypeOption) {
            customEntityTypeDropdownOptionsGroup.options.push({
                value: EntityTypeEnum.Custom,
                title: EntityConstants.CREATE_CUSTOM_TYPE_OPTION
            });
        }

        const otherEntityTypeDropdownOptionsGroup: TOptions<EntityTypeEnum> = {
            group: "Other entity types",
            options: [
                this.defaultEntityTypeOption
            ]
        };

        if(doIncludeConvertToStudy) {
            otherEntityTypeDropdownOptionsGroup.options.push({
                value: EntityTypeEnum.ConvertToStudy,
                title: EntityConstants.CONVERT_TO_STUDY_OPTION
            });
        }

        allEntityTypeOptions = [customEntityTypeDropdownOptionsGroup, ...allEntityTypeOptions, otherEntityTypeDropdownOptionsGroup];

        return allEntityTypeOptions;
    }

    public getEntityTypeDisplayName(entityType: EntityTypeEnum, customTypeName?: string): string {
        switch (entityType) {
            case EntityTypeEnum.Undefined:
                return "Entity";
            case EntityTypeEnum.Technology:
                return "Technology";
            case EntityTypeEnum.Material:
                return "Material";
            case EntityTypeEnum.Process:
                return "Process";
            case EntityTypeEnum.Method:
                return "Method";
            case EntityTypeEnum.Ingredient:
                return "Ingredient";
            case EntityTypeEnum.Chemical:
                return "Chemical";
            case EntityTypeEnum.Product:
                return "Product";
            case EntityTypeEnum.Partner:
                return "Partner";
            case EntityTypeEnum.Supplier:
                return "Supplier";
            case EntityTypeEnum.Competitor:
                return "Competitor";
            case EntityTypeEnum.Custom:
                return customTypeName ?? EntityConstants.CREATE_CUSTOM_TYPE_OPTION;
            default:
                return "Entity";
        }
    }

    public entityTypeStringToEnum(entityType: string): EntityTypeEnum {
        return this.entityTypeStringToEnumMapping[entityType.toLowerCase()];
    }

    public entityTypesDropdownOptions: TOptions<EntityTypeEnum>[] = [
        {
            group: "Basic",
            options: this.getEntityNameTypePairs().slice(1, 8)
        },
        {
            group: "Organization",
            options: this.getEntityNameTypePairs().slice(8, 11)
        }
    ];

    public allEntityTypes: EntityTypeEnum[] = Object.values(EntityTypeEnum) as EntityTypeEnum[];
}

export const EntityTypeHelperSingleton = new EntityTypeHelper();