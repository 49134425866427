// node_modules
import { FC } from "react";
// Components
import { DocumentView, Modal } from "Components";
// Interfaces
import { IDocumentDetails } from "Interfaces";
// Types
import { THighlightDTO, TIdNameTypeObjectType, TImageDTO } from "Types";
// Styles
import styles from "./documentModal.module.scss";

type TDocumentModalProps = {
    onClose?: () => void,
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    document: IDocumentDetails,
    onSaveElementClick: (element: TIdNameTypeObjectType, closeSavePopupCallback?: () => void) => void,
    updateDocument?: (document: IDocumentDetails) => void,
    refreshDocuments?: () => void,
    updateHighlights?: (highlights:  THighlightDTO[]) => void,
    onDeleteImage?: (image: TImageDTO) => void,
    onAddImage?: (image: TImageDTO, caption?: string) => void
}

export const DocumentModal: FC<TDocumentModalProps> = ({ onClose, setIsOpen, isOpen, ...props }: TDocumentModalProps) => {

    const resetModalStateAndCloseAsync = async () => {
        // Close the modal
        setIsOpen(false);
        // setHighlightedContent(undefined);

        // Call onclose callback if it exists
        if (onClose) onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={resetModalStateAndCloseAsync} extraClassNames={{container: styles.documentModal, header: styles.documentModalHeader }}>
            <DocumentView {...props} isInModal />
        </Modal>
    );
};
