// node_modules
import { FC, Fragment } from "react";
// Components
import { CollapsibleListItem } from "../CollapsibleListItem/CollapsibleListItem";
// Types
import { TCollapsibleListItem } from "Types";
// Styles
import styles from "./collapsibleList.module.scss";

type TCollapsibleListProps = {
    listItems: TCollapsibleListItem[],
    toggleIsCollapsed: (listItem: TCollapsibleListItem) => void,
    render: (listItem: TCollapsibleListItem) => React.ReactNode,
    extraClassNames?: {
        collapsibleList?: string,
        collapsibleListItemClassNames?: { chevronIconContainer?: string, onlyChildIconContainer?: string, chevronIcon?: string, onlyChildIcon?: string } }
}
export const CollapsibleList: FC<TCollapsibleListProps> = ({
    render,
    listItems,
    toggleIsCollapsed,
    extraClassNames = {}
}: TCollapsibleListProps) => {

    return (
        <ul className={`${styles.collapsibleList} ${extraClassNames.collapsibleList ?? ""}`}>
            {listItems.map((listItem: TCollapsibleListItem) => (
                <Fragment key={listItem.id}>
                    <CollapsibleListItem
                        listItem={listItem}
                        key={listItem.id}
                        toggleIsCollapsed={() => { toggleIsCollapsed(listItem); }}
                        extraClassNames={extraClassNames?.collapsibleListItemClassNames ?? {}}
                        shouldShowOnlyChildObjectIcon
                    >
                        {render(listItem)}
                    </CollapsibleListItem>
                    {listItem.isCollapsed === false && listItem.lowerLevelNodes && listItem.lowerLevelNodes.length > 0 && (
                        <div className={styles.lowerLevelNodes} key={`${listItem.id}_lowerLevelNodes`}>
                            <CollapsibleList
                                listItems={listItem.lowerLevelNodes}
                                toggleIsCollapsed={toggleIsCollapsed}
                                render={render}
                                extraClassNames={{ collapsibleListItemClassNames: extraClassNames.collapsibleListItemClassNames }}
                            />
                        </div>
                    )}
                </Fragment>
            ))}
        </ul>
    );
};
