// node_modules
import { Component, ErrorInfo, ReactNode } from "react";
// Components
import { ErrorFallback } from "./ErrorFallback";

// Types
type TErrorHandlingProps = {
    children?: ReactNode;
    errorMessage: string;
}

type TErrorHandlingState = {
    hasError: boolean;
}

export class ErrorHandling extends Component<TErrorHandlingProps, TErrorHandlingState> {
    // init state
    public errorHandlingState: TErrorHandlingState = {
        hasError: false
    };

    // Logic
    public static getDerivedStateFromError(_: Error): TErrorHandlingState {
        console.error(_);
        // update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // TODO: log error in Pendo
        console.error("Uncaught error:", error, errorInfo);
    }

    // Render
    public render() {
        if (this.errorHandlingState.hasError) {
            return <ErrorFallback errorMessage={this.props.errorMessage} />;
        }

        return this.props.children;
    }
}
