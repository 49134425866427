// Node modules
import { IconDefinition as regularIconDefinition } from "@fortawesome/free-regular-svg-icons";
import { faBookOpenReader, faCopy, faDiceD6, faEye, faHighlighter, faImage, faLink, faLock, faPen, faTrash, faUnlock, faUserGroup, faUpRightAndDownLeftFromCenter, faExpand, faSitemap, IconDefinition as solidIconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faListTree } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placement } from "@floating-ui/react-dom";
import { FC, useRef, useState } from "react";
// Components
import { Popover } from "Components";
// Helpers
import { StringHelperSingleton } from "Helpers";
// Hooks
import { useClickOutsideRef } from "Hooks";
// Types
import { TDropdownButtonOption } from "Types";
// Styles
import styles from "./dropdownButton.module.scss";

type TDropdownButtonProps = {
    isButtonEnabled: boolean,
    optionLabels: TDropdownButtonOption[],
    onClickOption: (optionName: TDropdownButtonOption) => void,
    onButtonClick?: () => void,
    extraClassNames?: {
        dropdownButton?: string,
        optionText?: string,
        dropdownButtonHover?: string,
        button?: string,
        rightIcon?: string,
        buttonIconContainer?: string,
        optionsContainer?: string,
        buttonText?: string,
        optionsPopover?: string
    },
    buttonText?: string,
    iconNameLeft?: regularIconDefinition | solidIconDefinition,
    iconNameRight?: regularIconDefinition | solidIconDefinition,
    titleText?: string,
    showDropdownInPortal?: boolean,
    showMenuOnlyOnClick?: boolean,
    popoverDataIdentifier?: string,
    popoverPlacement?: Placement,
    onCloseCallback?: () => void
}

export const DropdownButton: FC<TDropdownButtonProps> = ({
    isButtonEnabled,
    optionLabels, 
    extraClassNames = {},
    onClickOption,
    onButtonClick,
    buttonText,
    iconNameLeft,
    iconNameRight,
    titleText,
    showDropdownInPortal,
    showMenuOnlyOnClick = false,
    popoverDataIdentifier,
    popoverPlacement,
    onCloseCallback
}: TDropdownButtonProps) => {
    // State
    const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);
    const [buttonElementReference, setButtonElementReference] = useState<HTMLButtonElement | null>(null);

    //Ref
    const dropdownContentRef = useRef<HTMLDivElement>(null);

    // Custom hooks
    useClickOutsideRef(dropdownContentRef, () => { if (showMenuOnlyOnClick) { closeDropdown(); } });

    // close dropdown
    const closeDropdown = () => {
        // set is dropdown shown to false
        setIsDropdownShown(false);

        // call the onCloseCallback if it exists
        if (onCloseCallback) {
            onCloseCallback();
        }
    };

    const optionMap: { [label: string]: { icon?: solidIconDefinition, customClassName?: string } } = {
        "add image": { icon: faImage },
        "add highlight":  { icon: faHighlighter },
        "create link": { icon: faLink },
        "share": { icon: faUserGroup },
        "delete": { icon: faTrash, customClassName: styles.deleteOption },
        "study": { icon: faBookOpenReader},
        "entity": { icon: faDiceD6 },
        "edit mode": { icon: faPen },
        "view mode": { icon: faEye },
        "intake sheet": {},
        "maturity radar": {},
        "results overview table": {},
        "requirements table": {},
        "create new query": {},
        "connect existing query": {},
        "lock page": { icon: faLock },
        "unlock page": { icon: faUnlock },
        "duplicate": { icon: faCopy },
        "open page": { icon: faUpRightAndDownLeftFromCenter },
        "open preview": {icon: faExpand },
        "open in tree view": { icon: faSitemap},
        "open in list view": { icon: faListTree },
        "delete connection": { icon: faTrash, customClassName: styles.deleteOption },
        "remove access": { icon: faTrash, customClassName: styles.deleteOption }
    };

    const renderDropdownContent = () => {
        return (
            <div ref={dropdownContentRef} className={`${styles.optionsContainer} ${isDropdownShown ? "": styles.hidden } ${extraClassNames.optionsContainer || ""}`}>
                {optionLabels.map((label) => {
                    const option = optionMap[label.toLowerCase()] ?? {};
                    return (
                        <div className={`${option.icon ? "" : styles.hasOnlyText} ${option.customClassName ?? ""}`} key={label} onClick={() => { onClickOption(label); closeDropdown(); }}>
                            {option.icon && (
                                <div className={styles.iconContainer}>
                                    <FontAwesomeIcon icon={option.icon} />
                                </div>
                            )}
                            <div className={extraClassNames.optionText || ""}>{StringHelperSingleton.capitalizeFirstLetter(label)}</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <div
                className={`${styles.dropdownButton} ${extraClassNames.dropdownButton || ""}`}
                title={titleText ? titleText : undefined}
                {...(showMenuOnlyOnClick ? {
                    onClick: (e) => { if(isButtonEnabled) { e.preventDefault(); setIsDropdownShown(!isDropdownShown); if (onButtonClick) { onButtonClick(); }  } }
                } : {
                    onMouseEnter: () => { if(isButtonEnabled) { setIsDropdownShown(true); } },
                    onMouseLeave: () => { setIsDropdownShown(false); }
                })}
            >
                <button
                    ref={setButtonElementReference}
                    type="button"
                    title={buttonText}
                    className={`${extraClassNames.button || ""} ${isDropdownShown && extraClassNames.dropdownButtonHover ? extraClassNames.dropdownButtonHover : ""} ${isButtonEnabled ? "" : styles.disabled} ${styles.iconButton} ${iconNameLeft ? styles.iconLeft : ""} ${iconNameRight ? styles.iconRight : ""}`}
                    onClick={() => { if(isButtonEnabled) { setIsDropdownShown(true); } }}
                >
                    {iconNameLeft ?
                        <div className={[styles.iconContainer, styles.left, extraClassNames.buttonIconContainer || ""].join(" ")}>
                            <FontAwesomeIcon icon={iconNameLeft} />
                        </div>
                    : null }
                    {buttonText ? <div className={`${styles.buttonText} ${extraClassNames.buttonText || ""}`}>{buttonText}</div> : null}
                    {iconNameRight ? 
                        <div className={[styles.iconContainer, styles.right, extraClassNames.rightIcon || "", extraClassNames.buttonIconContainer || ""].join(" ")}>
                            <FontAwesomeIcon icon={iconNameRight} />
                        </div>
                    : null }
                </button>
                {!showDropdownInPortal && optionLabels.length > 0 && renderDropdownContent()}
            </div>
            {showDropdownInPortal && optionLabels.length > 0 && (
                <Popover
                    {...(!showMenuOnlyOnClick ? {
                        onMouseEnter: () => { if (isButtonEnabled) setIsDropdownShown(true); },
                        onMouseLeave: () => { if (isButtonEnabled) setIsDropdownShown(false); }
                    }: {})}
                    popoverOffset={showMenuOnlyOnClick ? 10 : undefined}
                    isOpen={isDropdownShown}
                    placement={popoverPlacement ?? "bottom-end"}
                    showInPortal 
                    extraClassName={`${styles.optionsPopover} ${extraClassNames?.optionsPopover ?? ""} ${isDropdownShown ? "": styles.hidden }`}
                    referenceEl={buttonElementReference}
                    dataIdentifier={popoverDataIdentifier}
                >
                    {renderDropdownContent() }
                </Popover>
            )}
        </>
    );

};