// Enums
import { LinkStatusEnum, SavedDocumentTypeEnum } from "Enums";
// Types
import { TOption, TOptions } from "Types";

export class LinkStatusHelper {
    private linkStatusStringToEnumMapping: { [name: string]: LinkStatusEnum } = {
        "notlinked": LinkStatusEnum.NotLinked
    };

    public getLinkStatusDisplayName(linkStatus: LinkStatusEnum): string {
        switch (linkStatus) {
            case LinkStatusEnum.NotLinked:
                return "Not linked";
            default:
                return "";
        }
    }

    private getLinkStatusNamePairs(): TOption<LinkStatusEnum>[] {
        return Object
            .keys(LinkStatusEnum)
            .map((key: string) => ({
                value: this.linkStatusStringToEnum(key),
                title: this.getLinkStatusDisplayName(this.linkStatusStringToEnum(key))
            }));
    }

    public linkStatusStringToEnum(linkStatus: string): LinkStatusEnum {
        return this.linkStatusStringToEnumMapping[linkStatus.toLowerCase()];
    }

    public linkStatusFilterDropdownOptions: TOptions<LinkStatusEnum>[] = [
        {
            group: "Relations",
            options: this.getLinkStatusNamePairs()
        }
    ];

    public getIsNotLinkedSelected(filterOptions: TOption<(SavedDocumentTypeEnum | LinkStatusEnum)>[]): boolean {
        for (const filterOption of filterOptions) {
            if (filterOption.value === LinkStatusEnum.NotLinked) {
                return true;
            }
        }
        
        return false;
    }
}

export const LinkStatusHelperSingleton = new LinkStatusHelper();