// node_modules
import { Dispatch, SetStateAction } from "react";
// Controllers
import { EnvironmentVariableControllerSingleton } from "Controllers";
// Enums
import { SearchPriorityEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Types
import { TEnvironmentVariableDTO } from "Types";
// Interfaces
import { IQueryDTO } from "Interfaces";

export class EnvironmentVariableHelper {
    public async addEnvironmentVariableAsync(environmentVariable: string, currentQuery: IQueryDTO, 
            setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>): Promise<void> {
        // create environment variable dto
        const environmentVariableToCreateDTO: TEnvironmentVariableDTO = {
            id: 0,
            environment: environmentVariable,
            searchPriority: SearchPriorityEnum.Should,
            synonyms: []
        };

        // create environment variable
        const createdEnvironmentVariable: TEnvironmentVariableDTO | undefined = await EnvironmentVariableControllerSingleton
            .createAsync(currentQuery.guid, environmentVariableToCreateDTO);

        // safety-checks
        if (!createdEnvironmentVariable) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not create environment variable.");
            return;
        }

        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }

            // return query
            // add environment variable to query
            return {
                ...prevQuery,
                environmentVariables: [...prevQuery.environmentVariables, createdEnvironmentVariable]
            };
        });
    }
}

export const EnvironmentVariableHelperSingleton = new EnvironmentVariableHelper();
