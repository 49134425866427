// node_modules
import { FC, useState } from "react";
// Components
import { FindestButton, FindestTextBox, Modal } from "Components";
// Styles
import sharedModalStyles from "../../../Modals/modal.module.scss";
import styles from "./addLinkModal.module.scss";

type TAddLinkModalProps = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    addLinkHandler: (url: string, title: string) => void
}

export const AddLinkModal: FC<TAddLinkModalProps> = ({isOpen, setIsOpen, addLinkHandler}: TAddLinkModalProps) => {
    const [title, setTitle] = useState<string>("");
    const [url, setUrl] = useState<string>("");

    return (
        <Modal  header="Insert external link" isOpen={isOpen} onClose={() => {setIsOpen(false);}}>
            <div className={sharedModalStyles.section}>
                <div className={sharedModalStyles.title}>Text to display</div>
                <FindestTextBox onChange={setTitle} />
            </div>
            <div className={sharedModalStyles.section}>
                <div className={sharedModalStyles.title}>URL to link to</div>
                <FindestTextBox onChange={setUrl} />
            </div>
            <div className={sharedModalStyles.footer}>
                <FindestButton title="Save" onClick={() => {addLinkHandler(url, title); setIsOpen(false);}} />
                <FindestButton extraClassName={styles.cancelButton} title="Cancel" onClick={() => {setIsOpen(false);}} />
            </div>
        </Modal>
    );
};