// Node Modules
import { $enum } from "ts-enum-util";
// Enums
import { UniverseRolesEnum } from "Enums";
// Types
import { TOption } from "Types";

const allUniverseRoles = new Set<string>($enum(UniverseRolesEnum).getValues());

export class RolesEnumHelper {

    public getUniverseRoleDropdownOptions() {
        const options: TOption<UniverseRolesEnum>[] = $enum(UniverseRolesEnum).getValues()
            .filter((role) => { return role !== UniverseRolesEnum.External; })
            .map((role) => {
                return {
                    title: role.toString(),
                    value: role,
                };
            });
        
        return [{ options: options }];
    }

    public getUniverseRoleFromRoles(roles: string[]): UniverseRolesEnum | undefined {
        const universeRole = roles.find(role => allUniverseRoles.has(role));
        if(universeRole) {
            return universeRole as UniverseRolesEnum;
        }
        return undefined;
    }

}

export const RolesEnumHelperSingleton = new RolesEnumHelper();