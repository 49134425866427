// node_modules
import MarkdownIt from "markdown-it";
import StateBlock from "markdown-it/lib/rules_block/state_block";
// Enums
import { CustomBlockIdAttributeEnum, CustomBlockMarkerEnum, CustomDOMTag, CustomMarkdownSeparatorEnum, OtherMarkdownCustomBlockNameEnum, SpecialBlockClassNameEnum, TopDepthMarkdownCustomBlockNameEnum } from "Enums";
// Extensions
import { commonCustomBlockExtension } from "./CommonCustomBlockExtension";
// Helpers
import { MarkdownItHelperSingleton } from "Helpers";

export function fileReferenceBlockExtension(md: MarkdownIt): void {
    const parseFileReferenceBlockContent = (state: StateBlock, startLine: number, content: string, closeFileReferenceBlockMarker: string, fileId?: string): boolean => {
        // safety-checks
        if (!fileId) {
            return false;
        }
        
        // Create a variable to store the next line index
        const nextLine = startLine;

        // Remove the file id from the full content
        content = content.slice(38);

        // Check if the current line contains the end marker
        let haveEndMarker = false;
        const endMarkerIndex = content.indexOf(closeFileReferenceBlockMarker);
        if(endMarkerIndex >= 0) {
            // If the end marker was found then remove it from the full content
            // and set the haveEndMarker flag to true
            content = content.slice(0, endMarkerIndex);
            haveEndMarker = true;
        }

        // Update the line of the state with the last line we checked and depending
        // on if we found the end marker add one to the line index
        state.line = nextLine + (haveEndMarker ? 1 : 0);

        // If the end marker was found then add it to the tokens in the state
        if(haveEndMarker) {
            // try to get file url from content
            const curlyBracketIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_URL);
            let fileUrl: string | undefined = undefined;
            // if curly bracket was found
            if (curlyBracketIndex >= 0) {
                // try to get the file url
                fileUrl = content.slice(curlyBracketIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_URL, curlyBracketIndex + 1));
            }

            // try to get extension from content
            const lessThanIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_EXTENSION);
            let extension: string | undefined = undefined;
            // if less than was found
            if (lessThanIndex >= 0) {
                // try to get the extension
                extension = content.slice(lessThanIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_EXTENSION, lessThanIndex + 1));
            }
            
            // try to get title from content
            const titleSpecialCharsIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_TITLE);
            let title: string | undefined = undefined;
            // if bracket was found
            if (titleSpecialCharsIndex >= 0) {
                // try to get the title
                title = content.slice(titleSpecialCharsIndex + 2, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_TITLE, titleSpecialCharsIndex + 2));
            }
            
            // if file url, extension or title was not found
            if (!fileUrl || !extension || !title) {
                // return false
                return false;
            }

            // clean file reference title
            title = MarkdownItHelperSingleton
                .cleanFileReferenceTitle(title, fileUrl, extension);

            // Add a token to indicate the start of the file reference block
            const startToken = state.push(`${TopDepthMarkdownCustomBlockNameEnum.FileReference}_open`, `${CustomDOMTag.FileReference}`, 1);
            startToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.FileReference}`],
                [`${CustomBlockIdAttributeEnum.FileReference}`, fileId],
                ["selected", "false"],
                ["id", `${crypto.randomUUID()}`]
            ];

            // Increase the depth level of the state
            state.level++;

            // Add a token to indicate the start of the reference link
            const linkToken = state.push(`${OtherMarkdownCustomBlockNameEnum.FileReferenceLink}_open`, `${CustomDOMTag.FileReferenceLink}`, 1);
            linkToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.FileReferenceLink}`],
                ["href", fileUrl], 
                ["target", "_blank"],
                ["rel", "noopener noreferrer"]
            ];

            // Increase the depth level of the state
            state.level++;

            // add span token
            const linkTitleToken = state.push(`${OtherMarkdownCustomBlockNameEnum.FileReferenceLinkTitle}_open`, `${CustomDOMTag.FileReferenceLinkTitle}`, 1);
            linkTitleToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.FileReferenceLinkTitle}`]
            ];

            // Add the content of the file reference block
            const titleToken = state.push("inline", "", 0);
            titleToken.content = title;
            titleToken.map = [startLine, state.line];
            titleToken.children = [];

            state.push(`${OtherMarkdownCustomBlockNameEnum.FileReferenceLinkTitle}_close`, `${CustomDOMTag.FileReferenceLinkTitle}`, -1);

            // add span token
            const linkExtensionToken = state.push(`${OtherMarkdownCustomBlockNameEnum.FileReferenceLinkExtension}_open`, `${CustomDOMTag.FileReferenceLinkExtension}`, 1);
            linkExtensionToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.FileReferenceLinkExtension}`]
            ];

            // Add the content of the file reference block
            const extensionToken = state.push("inline", "", 0);
            extensionToken.content = `.${extension}`;
            extensionToken.map = [startLine, state.line];
            extensionToken.children = [];

            state.push(`${OtherMarkdownCustomBlockNameEnum.FileReferenceLinkExtension}_close`, `${CustomDOMTag.FileReferenceLinkExtension}`, -1);

            // Decrease the depth level of the state
            state.level--;

            // Add a token to indicate the end of the file link
            state.push(`${OtherMarkdownCustomBlockNameEnum.FileReferenceLink}_close`, `${CustomDOMTag.FileReferenceLink}`, -1);

            // Decrease the depth level of the state
            state.level--;

            // Add a token to indicate the end of the file reference block
            state.push(`${TopDepthMarkdownCustomBlockNameEnum.FileReference}_close`, `${CustomDOMTag.FileReference}`, -1);
        }

        return haveEndMarker;
    };

    
    // execute the file reference block function after the image reference function
    md.block.ruler.after(
        `${TopDepthMarkdownCustomBlockNameEnum.ImageReference}`, 
        `${TopDepthMarkdownCustomBlockNameEnum.FileReference}`, 
        (state: StateBlock, startLine: number) => commonCustomBlockExtension(state, startLine, `${CustomBlockMarkerEnum.FileReference}`, parseFileReferenceBlockContent)
    );
}