// React
import { FC } from "react";
import { useNavigate } from "react-router-dom";
// Enums
import { ObjectTypeEnum } from "Enums";
// Font awesome
import { faArrowUpRightAndArrowDownLeftFromCenter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Styles
import styles from "./navigateToButton.module.scss";
type TNavigateToButtonProps = {
    id: string,
    type: ObjectTypeEnum,
    closeOnClick?: () => void,
    extraClassName?: string
};
export const NavigateToButton: FC<TNavigateToButtonProps> = ({ id, type, closeOnClick, extraClassName }) => {    
    // Hooks
    const navigate = useNavigate();
    const navigateToObject = () => {
        if (type === ObjectTypeEnum.Entity) {
            navigate(`/library/entities/${id}`);
        } else if(type === ObjectTypeEnum.Study) {
            navigate(`/library/studies/${id}`);
        }

        if (closeOnClick) {
            closeOnClick();
        }     
    };
    
    return (
        <div className={`${styles.navigateToButton} ${extraClassName ? extraClassName : ""}`} title="Full screen" onClick={navigateToObject}>
            <FontAwesomeIcon icon={faArrowUpRightAndArrowDownLeftFromCenter} />
        </div>
    );
};
