// node_modules
import { Component } from "react";
// Styles
import styles from "./errorFallback.module.scss";
// Constants
import { ErrorConstants } from "Constants";

type TErrorFallbackProps = {
    errorMessage: string
}

export class ErrorFallback extends Component<TErrorFallbackProps> {
    // Render
    public render() {
        return (
            <div className={styles.errorFallbackContainer}>
                <p>{this.props.errorMessage}</p>
                <span>{ErrorConstants.SUPPORT_EMAIL}</span>
            </div>
        );
    }
}
