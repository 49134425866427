import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useState } from "react";
// Components
import { AddReferencePopover } from "./AddReferencePopover";
// Helpers
import { ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import { TIdNameTypeObjectType, TReferenceModalProps } from "Types";
// Styles
import styles from "./commonObjectReference.module.scss";
// Enums
import { ObjectTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Hooks
import { useObjectReferenceModal } from "Hooks";

type TCommonObjectReferenceProps = {
    objectIdEdited: string,
    objectTypeEdited: ObjectTypeEnum,
    objectInformation: TIdNameTypeObjectType,
    applyInsertObjectReference: (object: TIdNameTypeObjectType) => void,
    isReferenceUsedAlready: boolean,
    onLinkingIconClick: (isCurrentlyConnected: boolean) => Promise<boolean>
};

export const CommonObjectReference: FC<TCommonObjectReferenceProps> = ({ objectIdEdited, 
    objectTypeEdited, objectInformation, applyInsertObjectReference, 
    isReferenceUsedAlready, onLinkingIconClick }: TCommonObjectReferenceProps) => {
    // State
    const [refPopoverReferenceElement, setRefPopoverReferenceElement] = useState<HTMLHeadElement | null>(null);
    const [isAddReferencePopoverShown, setIsAddReferencePopoverShown] = useState<boolean>(false);
    const [isObjectConnected, setIsObjectConnected] = useState<boolean | undefined>(objectInformation.isConnected);
    const [isReferenceModalOpen, setIsReferenceModalOpen] = useState<boolean>(false);

    // Logic
    useEffect(() => {
        // update state if objectInformation.isConnected prop changes
        setIsObjectConnected(objectInformation.isConnected);
    }, [objectInformation.isConnected]);

    const handleOnLinkingIconClickAsync = useCallback(async (isCurrentlyConnected: boolean): Promise<void> => {
        // call the onLinkingIconClick function
        const isSuccess = await onLinkingIconClick(isCurrentlyConnected);

        if (isSuccess) {
            // update state
            setIsObjectConnected(!isCurrentlyConnected);
        } else {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, `Failed to ${isCurrentlyConnected ? "unlink" : "link"}.`);
        }
    }, [onLinkingIconClick]);
    
    const getLinkingIcon = useCallback((isCurrentlyConnected?: boolean) => {
        if (isCurrentlyConnected === undefined || isCurrentlyConnected === null) {
            return null;
        }

        return <div className={`${styles.addLinkContainer} ${isCurrentlyConnected ? styles.isLinked : ""}`} onClick={() => handleOnLinkingIconClickAsync(isCurrentlyConnected)} title={isCurrentlyConnected ? "Unlink" : "Link"}><FontAwesomeIcon icon={faLink} /></div>;
    }, [handleOnLinkingIconClickAsync]);

    // handle insert object reference
    const insertObjectAsReferenceAsync = useCallback(async (object: TIdNameTypeObjectType, 
            applyInsertObjectReferenceInCallback: (objectInCallback: TIdNameTypeObjectType) => void,
            objectIdToInsert: string, objectTypeToInsert: ObjectTypeEnum, isObjectCurrentlyConnected?: boolean, ) => {
        // if object was not connected, connect it
        if(!isObjectCurrentlyConnected) {
            setIsObjectConnected(true);
        }

        // then link object to object edited
        const webRequestStatus: WebRequestStatusEnum = await LinkingControllerSingleton
            .createToAsync(object.id, object.objectType, objectIdToInsert, objectTypeToInsert);

        // safety-checks on the result
        if (webRequestStatus !== WebRequestStatusEnum.Success && webRequestStatus !== WebRequestStatusEnum.AlreadyExists) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Failed to link object to object edited.");
        }

        // apply insert object reference
        applyInsertObjectReferenceInCallback(object);
    }, []);

    const openObjectModal = () => {
        setIsReferenceModalOpen(true);
        setIsAddReferencePopoverShown(false);
        setReferenceModalProps((prevProps: TReferenceModalProps) => ({
            ...prevProps,
            isOpen: true,
            id: objectInformation.id,
            type: objectInformation.objectType
        }));
    };

    // On reference modal close
    const onReferenceModalClose = () => {
        // set is reference modal open to false
        setIsReferenceModalOpen(false);
    };

    // Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal(undefined, onReferenceModalClose);

    return (
        <div onMouseEnter={() => { isReferenceModalOpen ? setIsAddReferencePopoverShown(false) : setIsAddReferencePopoverShown(true); }} onMouseLeave={() => { setIsAddReferencePopoverShown(false); }} ref={setRefPopoverReferenceElement} className={`${styles.commonObjectReference} ${isAddReferencePopoverShown ? styles.hover : ""} ${isReferenceUsedAlready ? styles.isUsed : ""}`}>
            {isAddReferencePopoverShown && <AddReferencePopover
                refPopoverReferenceElement={refPopoverReferenceElement}
                handleOnReferenceClick={() => { insertObjectAsReferenceAsync(objectInformation, applyInsertObjectReference, objectIdEdited, objectTypeEdited, isObjectConnected); }}
                insertAsReferenceText={`Insert ${ObjectTypeHelperSingleton.getObjectTypeEndpointName(objectInformation.objectType).toLowerCase()} as reference`}
            />}
            {getLinkingIcon(isObjectConnected)}
            <div className={styles.referenceObjectDetails} onClick={openObjectModal}>
                <div className={styles.referenceObjectTypeDetails}>
                    <FontAwesomeIcon className={`${styles.referenceObjectTypeIcon} ${styles[objectInformation.objectType]}`} icon={ObjectTypeHelperSingleton.getObjectTypeIcon(objectInformation.objectType)} />
                    <div className={styles.objectType}>{objectInformation.type}</div>
                </div>
                <div title={objectInformation.name} className={styles.objectName}>{objectInformation.name}</div>
            </div>
            {referenceModal}
        </div>
    );
};