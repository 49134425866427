/* eslint-disable camelcase */

export class IntercomHelper {
    startIntercom(username) {
        if(!username || username.length === 0) return;

        window.Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "le53kr3y",
            name: username,
            email: username
        });
    }

    updateIntercom() {
        window.Intercom("update");
    }
}

export const IntercomHelperSingleton = new IntercomHelper();