// node_modules
import { EditorView } from "prosemirror-view";
import { FC, useCallback, useEffect, useState } from "react";
// Components
import { Popover, RatingStar } from "Components";
// Styles
import styles from "./ratingProgressPopover.module.scss";
// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";
// Types
import { TRatingsData } from "Types";

// Props type
type TRatingProgressPopoverProps = {
    editorView?: EditorView,
    isOpen: boolean,
    referenceElement: HTMLDivElement | null,
    onMouseEnterHandler: () => void,
    onMouseLeaveHandler: () => void
};

// Component
export const RatingProgressPopover: FC<TRatingProgressPopoverProps> = ({
    isOpen, referenceElement, editorView, onMouseEnterHandler, onMouseLeaveHandler
}: TRatingProgressPopoverProps) => {
    // State
    const [averageRatingInPercent, setAverageRatingInPercent] = useState<number>(0);
    const [totalNumberOfRatings, setTotalNumberOfRatings] = useState<number>(0);
    const [numberOfRatingsDone, setNumberOfRatingsDone] = useState<number>(0);

    // Logic
    const refreshObjectRating = useCallback((currentEditorView: EditorView) => {
        // get ratings data in results overview table
        const ratingsData: TRatingsData = ProseMirrorHelperSingleton.getRatingsDataInResultsOverviewTable(currentEditorView.state.doc);
    
        // set total number of ratings
        setTotalNumberOfRatings(ratingsData.totalNumberOfRatings);

        // set number of ratings done
        setNumberOfRatingsDone(ratingsData.numberOfRatingsDone);

        // init new average rating in percent, ratingsData.totalNumberOfRatings being 100 %, calculate average rating in percent
        const newAverageRatingInPercent = ((ratingsData.numberOfRatingsDone / ratingsData.totalNumberOfRatings) * 100) / 100;
        // set average rating in percent
        setAverageRatingInPercent(isNaN(newAverageRatingInPercent) ? 0 : newAverageRatingInPercent);
    }, []);

    // useEffect on objectId and objectType
    useEffect(() => {
        // if popover is not open or editorView is not defined
        if (!isOpen || !editorView) {
            // do nothing, return
            return;
        }

        // refresh object rating
        refreshObjectRating(editorView);
    }, [isOpen, editorView, refreshObjectRating]);

    // Render
    return (
        <Popover
            extraClassName={styles.ratingProgressPopover}
            popoverOffset={-5}
            placement="right-start"
            referenceEl={referenceElement}
            isOpen={isOpen}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
        >
            <h3>Rating progress</h3>
            <RatingStar
                extraClassNames={{ container: styles.ratingStarContainer }}
                size="xlarge"
                rating={averageRatingInPercent}
                isRatingShown={true} 
                doShowRatingAsPercentage={true} />
            <h6>{`${numberOfRatingsDone}/${totalNumberOfRatings} RATINGS`}</h6>
        </Popover>
    );
};