// node_modules
import { BarElement, CategoryScale, Chart, ChartData, ChartOptions, LinearScale, Tooltip } from "chart.js";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
// Components
import { DropdownButton, FindestButton, FindestRefTextBox, LoadingStatusIndicator, Modal, SuggestingTextbox } from "Components";
// Controllers
import { ActivityControllerSingleton, SearchControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";
// Styles
import styles from "./publicationsPerYearModal.module.scss";
// Custom hooks
import { useSaveGraphAsImage } from "Hooks";

// Register Chart elements
Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip
);

// Component props type
type TPublicationsPerYearModalProps = {
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>
    barChartData: ChartData<"bar", number[], string>,
    barChartOptions: ChartOptions<"bar">
};

// Component
export const PublicationsPerYearModal: FC<TPublicationsPerYearModalProps> = ({ isOpen, setIsOpen, barChartData, barChartOptions }: TPublicationsPerYearModalProps) => {
    // State
    const [caption, setCaption] = useState<string>("");
    const [areRecentActivitiesVisible, setAreRecentActivitiesVisible] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<TIdNameTypeObjectType[]>([]);
    const [selectedObject, setSelectedObject] = useState<TIdNameTypeObjectType | undefined>(undefined);

    // Ref
    const barChartRef = useRef<Chart<"bar">>(null);
    const captionInputRef = useRef<HTMLInputElement>(null);
    const objectNameRef = useRef<string>("");

    /** on close modal handler */
    const onClose = (): void => {
        // if is saving graph as image
        if (isSavingGraphAsImage) {
            // stop execution, return
            return;
        }

        // set is open to false
        setIsOpen(false);
        // set are recent activities visible to false
        setAreRecentActivitiesVisible(false);
        // set suggestions to empty array
        setSuggestions([]);
        // set selected object to undefined
        setSelectedObject(undefined);
        // set object name ref to empty string
        objectNameRef.current = "";
        // set caption to empty string
        setCaption("");
    };

    // Custom hooks
    const { isSavingGraphAsImage, saveGraphAsImageAsync } = useSaveGraphAsImage(barChartRef, captionInputRef, onClose);

    /** Run search for suggestions */
    const runSuggestionsSearchAsync = async (suggestionValue: string): Promise<void> => {
        // set are recent activities visible to false
        setAreRecentActivitiesVisible(false);

        // set object name ref to suggestion value
        objectNameRef.current = suggestionValue;

        // if suggestionValue is not set
        if (!suggestionValue) {
            // retrieve recent activities
            retrieveRecentActivitiesAsync();
            // set are recent activities visible to true
            setAreRecentActivitiesVisible(true);
            // stop execution, return
            return;
        }

        // search for suggestions
        const foundSuggestions: TIdNameTypeObjectType[] = await SearchControllerSingleton.searchMultipleObjectsAsync(
            suggestionValue,
            [ObjectTypeEnum.Entity, ObjectTypeEnum.Study]
        );

        // if no suggestions found
        if (!foundSuggestions) {
            // set suggestions to empty array
            setSuggestions([]);
            // stop execution, return
            return;
        }

        // update suggestions to found suggestions
        setSuggestions([...foundSuggestions]);
    };

    /** retrieve recent activities */
    const retrieveRecentActivitiesAsync = useCallback(async () => {
        // retrieve recent activities
        const recentActivities: TIdNameTypeObjectType[] = await ActivityControllerSingleton.getMySimpleActivityAsync();

        // if recentActivities is not set
        if (!recentActivities) {
            // set suggestions to empty array
            setSuggestions([]);
            // stop execution, return
            return;
        }

        // update suggestions to recent activities
        setSuggestions(recentActivities);
    }, []);

    /** when isOpen changes */
    useEffect(() => {
        // if modal is open
        if (isOpen) {
            // retrieve recent activities
            retrieveRecentActivitiesAsync();
            // set are recent activities visible to true
            setAreRecentActivitiesVisible(true);
            // set suggestions to empty array
            setSuggestions([]);
            // set selected object to undefined
            setSelectedObject(undefined);
            // set object name ref to empty string
            objectNameRef.current = "";
            // set caption to empty string
            setCaption("");
        }
    }, [isOpen, retrieveRecentActivitiesAsync]);

    /** Get is create button enabled */
    const getIsCreateButtonEnabled = useCallback((objectName: string) => {
        return objectName.length > 0 && !isSavingGraphAsImage;
    }, [isSavingGraphAsImage]);

    // Render
    return (
        <Modal 
                header="Publications per year"
                extraClassNames={{ container: styles.publicationsPerYearModalContainer }}
                isOpen={isOpen}
                onClose={onClose}>
            <div className={`${styles.publicationsPerYearContainer} ${isSavingGraphAsImage ? styles.isDisabled : ""}`}>
                <Bar 
                    ref={barChartRef}
                    options={barChartOptions}
                    data={barChartData} 
                    className={styles.publicationsPerYearContent} 
                    onClick={() => { captionInputRef.current?.focus(); }}/>
                <div className={styles.saveGraphAsImageTitle}>Save graph as image</div>
                <FindestRefTextBox
                    placeholder="Caption"
                    parentInputRef={captionInputRef}
                    extraClassName={styles.captionTextBox}
                    onChange={setCaption} />
                <div className={styles.suggestionsContainer}>
                    <SuggestingTextbox
                        placeholder="Object name"
                        title={areRecentActivitiesVisible ? "Recently updated objects" : "Universe suggestions"}
                        refreshSuggestionsAsync={(newValue: string) => runSuggestionsSearchAsync(newValue)}
                        suggestions={suggestions}
                        onValueChangeHandler={(newValue: string) => { objectNameRef.current = newValue; }}
                        handleSuggestionClick={setSelectedObject}
                        selectedOption={selectedObject}
                        setSelectedOption={setSelectedObject}
                        doAutoFocus={false} />
                </div>
            </div>
            <div className={styles.buttonsContainer}>
                {selectedObject ? (
                    <FindestButton 
                        title="Save" 
                        titleAttribute="Save"
                        isDisabled={isSavingGraphAsImage}
                        onClick={() => { saveGraphAsImageAsync(caption, "", selectedObject, objectNameRef.current); }} />
                ) : (
                    <DropdownButton 
                        showDropdownInPortal
                        isButtonEnabled={getIsCreateButtonEnabled(objectNameRef.current)} 
                        optionLabels={["Entity", "Study"]}
                        onClickOption={(optionName: string) => { saveGraphAsImageAsync(caption, optionName, selectedObject, objectNameRef.current); }}
                        buttonText="Create new" 
                        extraClassNames={{ dropdownButton: styles.createButton }}/>
                )}
                {(isSavingGraphAsImage) && (<LoadingStatusIndicator shouldCenter size={32} status={1} />)}
            </div>
        </Modal>
    );
};