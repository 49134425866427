// React
import { FC, useMemo } from "react";
// Components
import { Checkbox, Dropdown, IsFindestAdministrator, ToggleSwitch, UserIcon } from "Components";
// Constants
import { FeatureToggleConstants } from "Constants";
// Enums
import { RolesEnum, UniverseRolesEnum } from "Enums";
// Helpers
import { RolesEnumHelperSingleton, UserHelperSingleton } from "Helpers";
// Types
import { TOption, TOptions, TUserDetailsUser } from "Types";
// Styles
import styles from "./userDetailsTable.module.scss";

export type TUserDetailsTableProps = {
    users: TUserDetailsUser[],
    isSelectCheckboxDisabled?: (email: string) => boolean,
    selectedUserEmails?: Set<string>,
    onRoleChange: (user: TUserDetailsUser, newRole: UniverseRolesEnum) => void,
    onAdvancedCheckboxChange: (isChecked: boolean, user: TUserDetailsUser) => void,
    isAdvancedCheckboxDisabled?: (user: TUserDetailsUser) => boolean,
    onUserCheckboxChange?: (email: string) => void,
    isExistingUsers?: boolean,
    extraClassNames?: { container?: string }
};

export const UserDetailsTable: FC<TUserDetailsTableProps> = ({
    users, onRoleChange, onAdvancedCheckboxChange, isExistingUsers, selectedUserEmails,
    isSelectCheckboxDisabled, onUserCheckboxChange, isAdvancedCheckboxDisabled = () => false,
    extraClassNames = {}
}) => {
    // Memo
    const allUniverseRoleDropdownOptions: TOptions<UniverseRolesEnum>[] = useMemo(() => {
        return RolesEnumHelperSingleton.getUniverseRoleDropdownOptions();
    }, []);

    return (
        <div className={`${styles.userDetailsTableContainer} ${extraClassNames?.container ?? ""}`}>
            <table className={styles.userDetailsTable}>
                <thead className={styles.userDetailsTableHead}>
                    <tr className={styles.row}>
                        {isExistingUsers && (
                            <th className={styles.selectHeader}>
                                <h4>Select</h4>
                            </th>
                        )}
                        <th className={styles.userEmailHeader}>
                            <h4>User email</h4>
                        </th>
                        <th className={styles.userRoleHeader}>
                            <h4>User role</h4>
                        </th>
                        <IsFindestAdministrator>
                            <th className={styles.advancedToggleHeader}>
                                <h4>Advanced</h4>
                            </th>
                        </IsFindestAdministrator>
                        {FeatureToggleConstants.GalaxiesManagement &&
                            <th className={styles.dataHeaderItem}>
                                <h4>Galaxy access</h4>
                            </th>
                        }
                    </tr>
                </thead>
                <tbody className={styles.usersContainer}>
                {users.map((user) => {
                    let selectedUserRole: undefined | TOption<UniverseRolesEnum | RolesEnum> = undefined;
                    if (user.roles && user.roles.length > 0) {
                        if (isExistingUsers) {
                            const userUniverseRole = RolesEnumHelperSingleton.getUniverseRoleFromRoles(user.roles);
                            selectedUserRole = allUniverseRoleDropdownOptions[0].options.find((option) => option.value === userUniverseRole);
                        } else {
                            selectedUserRole = {
                                title: user.roles[0],
                                value: user.roles[0]
                            };
                        }
                    }
                
                    const isCheckboxChecked = selectedUserEmails && selectedUserEmails.has(user.email);
                    return (
                        <tr className={`${styles.dataItem} ${isCheckboxChecked ? styles.selected : ""}`} key={user.email}>
                            {isExistingUsers && isSelectCheckboxDisabled && onUserCheckboxChange && (
                                <td>
                                    <div className={styles.dataItemCheckbox}>
                                        <Checkbox 
                                            isDisabled={isSelectCheckboxDisabled(user.email)}
                                            isChecked={isCheckboxChecked} 
                                            onCheckboxChange={() => { onUserCheckboxChange(user.email); }} />
                                    </div>
                                </td>
                            )}
                            <td>
                                <div className={styles.userDetails}>
                                    <UserIcon extraClassName={styles.userIcon} email={user.email} />
                                    <span title={user.email} className={styles.username}>{user.email}</span>
                                </div>
                            </td>
                            <td>
                                <div className={styles.dropdownContainer}>
                                    <Dropdown
                                        classNameSelect={styles.dropdownSelect}
                                        placeholderText={isExistingUsers ? undefined : "Select role"}
                                        options={allUniverseRoleDropdownOptions}
                                        handleOptionSelect={(option) => { onRoleChange(user, option.value as UniverseRolesEnum); }}
                                        selectedOption={selectedUserRole}
                                    />
                                </div>
                            </td>
                            <IsFindestAdministrator>
                                <td>
                                    <div className={styles.toggleContainer}>
                                        <ToggleSwitch
                                            checked={user.permissions && UserHelperSingleton.hasAdvanced(user.permissions)}
                                            readOnly={isAdvancedCheckboxDisabled(user)}
                                            onChange={async (isChecked: boolean) => { onAdvancedCheckboxChange(isChecked, user); }}
                                        />
                                    </div>
                                </td>
                            </IsFindestAdministrator>
                            {FeatureToggleConstants.GalaxiesManagement &&
                                <td>
                                    <span>{user.galaxyIds}</span>
                                </td>
                            }
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};