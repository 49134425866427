// node_modules
import { FC } from "react";
// Components
import { FindestButton } from "Components";
// Styles
import styles from "./loginWithEmail.module.scss";

type TLoginWithEmailProps = {
    requestMagicLinkAsync: () => Promise<void>
}

export const LoginWithEmail: FC<TLoginWithEmailProps> = ({requestMagicLinkAsync}) => {
    // Render
    return (
        <div className={styles.loginBox}>
            <h3 className={styles.title}>Login with email</h3>
            <div className={styles.body}>
                <h4>Securely login without a password.</h4>
                <p>
                    We will send a &ldquo;magic link&rdquo; to your inbox.
                </p>
                <p>
                    No password needed. Just click the link to login instantly.
                </p>
            </div>
            <div className={styles.footer}>
                <FindestButton extraClassName={[styles.button, styles.magicLinkButton].join(" ")} title="Send magic link" onClick={requestMagicLinkAsync} />
            </div>
        </div>
    );
};