// node_modules
import {
    BaseType,
    D3DragEvent,
    ForceLink,
    Selection,
    Simulation,
    create as d3Create,
    drag as d3Drag,
    forceLink as d3ForceLink,
    map as d3Map,
    select as d3Select,
    extent,
    forceManyBody,
    forceSimulation,
    forceX,
    forceY,
	forceCollide,
    zoom,
    zoomIdentity
} from "d3";
// Enums
import { GraphViewContainerTypeEnum, ObjectTypeEnum } from "Enums";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Types
import { ILink, INode, TDataObject } from "Types";

type TForceGraphOptions = {
	nodeId: (d: INode) => string,
	nodeTitle: undefined | ((d: INode, i: number) => string),
	width?: number,
	height?: number,
	nodeStroke?: string,
	nodeFill?: string,
	nodeStrokeWidth?: number,
	nodeStrokeOpacity?: number,
	nodeRadius?: number,
	nodeStrength?: undefined | number,
	linkSource?: ({ source }: { source: string }) => string,
	linkTarget?: ({ target }: { target: string }) => string,
	linkStroke?: string,
	linkStrokeOpacity?: number,
	linkStrokeWidth?: number,
	linkStrokeLinecap?: string,
	linkStrength?: undefined | number,
	tooltipClassName: string,
	navigateToObject: (object: { objectTypeEnum: ObjectTypeEnum, id: string }) => void,
	showPopover: (object: { objectTypeEnum: ObjectTypeEnum, id: string }, currentNode: SVGCircleElement | BaseType) => void,
	hidePopover: () => void,
	containerType: GraphViewContainerTypeEnum
}

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/disjoint-force-directed-graph
export function ForceGraphHelper({
	nodes, // an iterable of node objects (typically [{id}, …])
	links // an iterable of link objects (typically [{source, target}, …])
}: { nodes: INode[], links: ILink[] }, {
	nodeId = (d: INode) => d.id, // given d in nodes, returns a unique identifier (string)
	nodeTitle = undefined, // given d in nodes, a title string
	nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
	nodeStroke = "#fff", // node stroke color
	nodeStrokeWidth = 1.5, // node stroke width, in pixels
	nodeStrokeOpacity = 1, // node stroke opacity
	nodeRadius = 25, // node radius, in pixels
	nodeStrength = undefined,
	linkSource = ({ source }: { source: string }) => source, // given d in links, returns a node identifier string
	linkTarget = ({ target }: { target: string }) => target, // given d in links, returns a node identifier string
	linkStroke = "#CCCCCC", // link stroke color
	linkStrokeOpacity = 1, // link stroke opacity
	linkStrokeWidth = 3, // given d in links, returns a stroke width in pixels
	linkStrokeLinecap = "round", // link stroke linecap
	linkStrength = undefined,
	width = 3000, // outer width, in pixels
	height = 3000, // outer height, in pixels,
	tooltipClassName,
	navigateToObject,
	showPopover,
	hidePopover,
	containerType
	// invalidation = undefined // when this promise resolves, stop the simulation
}: TForceGraphOptions) {
	// Compute values.
	const N = d3Map(nodes, nodeId);
	const LS = d3Map(links, linkSource);
	const LT = d3Map(links, linkTarget);
	let clickedNodeId = "";
	if (nodeTitle === undefined) nodeTitle = (_, i) => N[i];

	// Replace the input nodes and links with mutable objects for the simulation.
	nodes = d3Map(nodes, (_, i) => ({ ...nodes[i], id: N[i] }));
	links = d3Map(links, (_, i) => ({ source: LS[i], target: LT[i], value: "0" }));
	let hasBeenFirstZoomed = false;
	let hasBeenSecondZoomed = false;
	const maximumNodeRadius = 30;

	// Construct the forces.
	const forceNode = forceManyBody().strength(function(d, i) {
		return i == 0 ? -2000 : -1000;
	});
	const forceLink = d3ForceLink(links).distance(100).id(node => (node as INode).id);

	if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
	if (linkStrength !== undefined) forceLink.strength(linkStrength);

	let simulation = forceSimulation(nodes)
		.force("link", forceLink)
		.force("charge", forceNode)
		.force("x", forceX())
		.force("y", forceY())
		.force("collision", forceCollide().radius(maximumNodeRadius).strength(1))
		.on("tick", ticked);

	const svg = d3Create("svg")
		.attr("preserveAspectRatio", "xMidYMid meet")
		.attr("viewBox", [0, 0, width, height])
		.attr("style", "max-width: 100%; max-height: 100%; height: auto; height: intrinsic; width: 100%; overflow: visible;")
		.on("click", (event) => {
			if (event.target.tagName !== "circle") {
				hidePopover();
				clickedNodeId = "";
			}
		});

	const zoomContainer = svg.call(zoom().on("zoom", zoomed) as any)
		.append("g");

	const zoom1 = zoom()
		.scaleExtent([-10 / 2, 5])
		.on("zoom", zoomed);
		
	svg.call(zoom1 as any);

	let link = zoomContainer.append("g")
		.attr("stroke", linkStroke)
		.attr("stroke-opacity", linkStrokeOpacity)
		.attr("stroke-width", typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null)
		.attr("stroke-linecap", linkStrokeLinecap)
		.selectAll("line")
		.data(links)
		.join("line");

	const tooltipContainer = d3Select(`#${containerType}`)
		.append("div")
		.attr("class", tooltipClassName)
		.style("position", "absolute")
		.style("background-color", "white")
		.style("z-index", "10")
		.style("visibility", "hidden");

	const objectTypeContainer = tooltipContainer
		.append("div")
		.style("display", "flex")
		.style("align-items", "center");

	const objectTypeIcon = objectTypeContainer
		.append("xhtml:svg")
		.style("font-size", "14px")
		.style("margin-bottom", "2px")
		.style("margin-right", "8px");

	const objectTypeText = objectTypeContainer
		.append("div")
		.style("font-size", "10px")
		.style("text-transform", "uppercase")
		.style("color", "#252525")
		.style("font-weight", "500")
		.style("line-height", "1rem")
		.style("letter-spacing", "1.5px");

	const objectTitleContainer = tooltipContainer
		.append("div")
		.style("font-size", "20px");

	let node = zoomContainer.append("g")
		.attr("fill", nodeFill)
		.attr("stroke", nodeStroke)
		.attr("stroke-opacity", nodeStrokeOpacity)
		.attr("stroke-width", nodeStrokeWidth)
		.selectAll("circle")
		.data(nodes)
		.join("circle")
		.attr("r", nodeRadius)
		.attr("fill", d => ObjectTypeHelperSingleton.getObjectTypeColor(d.data.objectTypeEnum))
		.style("cursor", "pointer")
		.call(drag(simulation) as (selection: Selection<BaseType | SVGCircleElement, INode, SVGGElement, undefined>, ...args: unknown[]) => void);

	// Add legend
	[ObjectTypeEnum.Entity, ObjectTypeEnum.Study].forEach((objectTypeEnum, index) => {
		svg.append("circle").attr("cx",80).attr("cy", index * 26 + 20).attr("r", 5).style("fill", ObjectTypeHelperSingleton.getObjectTypeColor(objectTypeEnum));
		svg.append("text").attr("x", 100).attr("y", index * 26 + 22).text(ObjectTypeHelperSingleton.getObjectTypeEndpointName(objectTypeEnum)).style("font-size", "14px").attr("alignment-baseline", "middle");
	});
	// Handle invalidation.
	// if (invalidation != null) invalidation.then(() => simulation.stop());

	function getMinScale() {
		// set up zoom transform:
		const xExtent = extent(node.data(), function(d) { return d.x; }) as [number, number];
		const yExtent = extent(node.data(), function(d) { return d.y; }) as [number, number];
		// get scales:    
		const xScale = width / (xExtent[1] - xExtent[0]) * 0.95;
		const yScale = height / (yExtent[1] - yExtent[0]) * 0.95;

		return Math.min(xScale, yScale);
	}

	function getMaxZoomDivision() {
		if(nodes.length < 5) {
			return 5.0;
		} else if(nodes.length < 10) {
			return 4.0;
		} else if(nodes.length < 20) {
			return 3.0;
		} else {
			return 2.5;
		}
	}

	function zoomed(event: any) {
		hasBeenFirstZoomed = true;
		hasBeenSecondZoomed = true;

		// get most restrictive scale
		const minScale = getMinScale() / getMaxZoomDivision();

		if(event.transform.k < minScale) {
			event.transform.k = minScale;
		}

		zoomContainer.attr("transform", event.transform);
	}

	function zoomToFit() {
		const minScale = getMinScale();
		const xExtent = extent(node.data(), function(d) { return d.x; }) as [number, number];
		const yExtent = extent(node.data(), function(d) { return d.y; }) as [number, number];
		const transform = zoomIdentity.translate(width / 2, height / 2)
			.scale(minScale / 2)
			.translate(-(xExtent[0] + xExtent[1]) / 2, -(yExtent[0] + yExtent[1]) / 2);
		svg.call(zoom1.transform as any, transform);
	}

	function ticked(this: Simulation<INode, undefined>) {
		if(this.alpha() < 1.0 && !hasBeenFirstZoomed) {
			zoomToFit();
		}

		if (this.alpha() < 0.4 && !hasBeenFirstZoomed) {
			zoomToFit();
			hasBeenFirstZoomed = true;
		}
		if (this.alpha() < 0.2 && !hasBeenSecondZoomed) {
			zoomToFit();
			hasBeenSecondZoomed = true;
		}

		link
			.attr("x1", (d: ILink) => (d.source as unknown as INode).x as number)
			.attr("y1", (d: ILink) => (d.source as unknown as INode).y as number)
			.attr("x2", (d: ILink) => (d.target as unknown as INode).x as number)
			.attr("y2", (d: ILink) => (d.target as unknown as INode).y as number);

		node
			.attr("cx", (d: INode) => d.x ?? 0)
			.attr("cy", (d: INode) => d.y ?? 0);
	}

	function drag(currSimulation: Simulation<INode, ILink>) {
		function dragstarted(event: D3DragEvent<Element, INode, INode>) {
			if (!event.active) currSimulation.alphaTarget(0.3).restart();
			event.subject.fx = event.subject.x;
			event.subject.fy = event.subject.y;
		}

		function dragged(event: D3DragEvent<Element, INode, INode>) {
			event.subject.fx = event.x;
			event.subject.fy = event.y;
		}

		function dragended(event: D3DragEvent<Element, INode, INode>) {
			if (!event.active) currSimulation.alphaTarget(0);
			event.subject.fx = null;
			event.subject.fy = null;
		}

		return d3Drag()
			.on("start", dragstarted)
			.on("drag", dragged)
			.on("end", dragended);
	}

	const isReferencePopoverOpen = (currentNodeId: string) => {
		const referencePopover = document.querySelector("[class*='referenceModal_referencePopover']");
		return !!referencePopover && currentNodeId === clickedNodeId;
	};

	const chart = Object.assign(svg.node() as Node, {
		update({ nodes: currentNodes, links: currentLinks }: TDataObject) {

			// Make a shallow copy to protect against mutation, while
			// recycling old nodes to preserve position and velocity.
			const old = new Map(node.data().map(d => [d.id, d]));
			nodes = currentNodes.map(d => Object.assign(old.get(d.id) || {}, d));
			links = currentLinks.map(d => Object.assign({}, d));

			simulation = simulation.nodes(nodes);
			simulation.force<ForceLink<INode, ILink>>("link")?.links(links);
			simulation = simulation.alpha(1).restart();

			node = node
				.data(nodes, d => d.id)
				.join(enter => enter.append("circle")
					.attr("r", nodeRadius)
					.style("cursor", "pointer"))
				.call(drag(simulation) as (selection: Selection<BaseType | SVGCircleElement, INode, SVGGElement, undefined>, ...args: unknown[]) => void)
				.on("click", function(_event: MouseEvent, d: INode) {
					if (isReferencePopoverOpen(d.data.id)) {
						navigateToObject({ objectTypeEnum: d.data.objectTypeEnum, id: d.data.id });
					} else {
						showPopover({ objectTypeEnum: d.data.objectTypeEnum, id: d.data.id }, this);
						clickedNodeId = d.id;
					}
				})
				.on("dblclick", function(_event: MouseEvent, d: INode) {
					navigateToObject({ objectTypeEnum: d.data.objectTypeEnum, id: d.data.id });
				})
				.on("mouseover", function(_event, currentNode) {
					objectTitleContainer.text(currentNode.data.text);
					objectTypeIcon.style("color", ObjectTypeHelperSingleton.getObjectTypeColor(currentNode.data.objectTypeEnum));
					objectTypeIcon.attr("class", ObjectTypeHelperSingleton.getObjectTypeIconClassName(currentNode.data.objectTypeEnum));
					objectTypeText.text(currentNode.data.objectType);
					return tooltipContainer.style("visibility", "visible");
				})
				.on("mousemove", function(event) {
					if (containerType === GraphViewContainerTypeEnum.Overview) {
						return tooltipContainer.style("top", `${event.pageY - 10}px`).style("left", `${event.pageX + 20}px`);
					} else if (containerType === GraphViewContainerTypeEnum.LinksWindow) {
						return tooltipContainer.style("top", `${event.pageY - 42}px`).style("left", `${event.pageX - 69}px`);
					}
				})
				.on("mouseout", function() {
					return tooltipContainer.style("visibility", "hidden");
				});

			node.attr("fill", d => { return d.data.isHighlighted ? ObjectTypeHelperSingleton.getObjectTypeColor(d.data.objectTypeEnum) : "#CCCCCC"; });
			node.attr("r", d => { return d.data.isHighlighted ? 30 : nodeRadius; });
			
			link = link.data(links).join("line");
			link.attr("stroke", d => { return (d.source as unknown as INode).data.isHighlighted || (d.target as unknown as INode).data.isHighlighted ? "#007AFF" : linkStroke; });
			link.attr("stroke-width", d => { return (d.source as unknown as INode).data.isHighlighted || (d.target as unknown as INode).data.isHighlighted ? 6 : linkStrokeWidth; });
		}
	});

	return chart;
}