// node_modules
import MarkdownIt from "markdown-it";
import StateInline from "markdown-it/lib/rules_inline/state_inline";
import Token from "markdown-it/lib/token";

export function CommonInlineMarkExtension(name: string, marker: string, htmlTagText: string, parseContentBetweenMarkers?: (stateInline: StateInline, content: string) => void, 
        renderStartCommonMark?: (tokens: Token[], index: number) => string) {
    const upperCaseMarker = marker.toUpperCase();
    const lowerCaseHtmlTagText = htmlTagText.toLowerCase();
    const commonOpenMarker = `%F%OPEN%${upperCaseMarker}%F%`;
    const commonCloseMarker = `%F%CLOSE%${upperCaseMarker}%F%`;
    const commonOpenToken = `${name}_open`;
    const commonCloseToken = `${name}_close`;

    return function commonInlineMarkExtension(md: MarkdownIt): void {
        const renderStart = (): string => {
            return `<${lowerCaseHtmlTagText}>`;
        };
    
        const renderEnd = (): string => {
            return `</${lowerCaseHtmlTagText}>`;
        };

        const commonInlineMarkExtension = (state: StateInline, silent: boolean, parseContentBetweenMarkers?: (stateInline: StateInline, content: string) => void): boolean => {
            const max = state.posMax;
            const pos = state.pos;
            const currentLine = state.src.slice(pos, max);
            
            if (state.src.charCodeAt(pos) !== 37) { return false; }
            if (silent) { return false; } // don't run any pairs in validation mode
            if (pos + 2 >= max) { return false; }

            if(currentLine.startsWith(commonOpenMarker)) {
                // get content between markers
                const content = currentLine.substring(commonOpenMarker.length, currentLine.indexOf(commonCloseMarker));
                // set next pos to current pos + length of commonOpenMarker + length of content (to skip over content)
                state.pos = pos + commonOpenMarker.length + content.length;
                state.posMax = max;
                
                // push open token
                state.push(commonOpenToken, lowerCaseHtmlTagText, 1);
                
                // if parseContentBetweenMarkers is defined, parse content with provided function
                if(parseContentBetweenMarkers) {
                    parseContentBetweenMarkers(state, content);
                } else {
                    // otherwise, push content token as text token
                    // push content token
                    const contentToken = state.push("text", "", 0);
                    contentToken.content = content;
                }
            } else if(currentLine.startsWith(commonCloseMarker)) {
                // set next pos to current pos + length of commonCloseMarker (to skip over marker)
                state.pos = pos + commonCloseMarker.length;
                state.posMax = max;

                // push close token
                state.push(commonCloseToken, lowerCaseHtmlTagText, -1);
            } else {
                return false;
            }

            return true;
        };

        md.inline.ruler.push(name, (stateInline: StateInline, silent: boolean) => commonInlineMarkExtension(stateInline, silent, parseContentBetweenMarkers));
        md.renderer.rules[commonOpenToken] = renderStartCommonMark ?? renderStart;
        md.renderer.rules[commonCloseToken] = renderEnd;
    };
}