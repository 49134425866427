// React
import { FC, useContext, useMemo, useState } from "react";
// Node modules
import { DeletedArchive } from "./DeletedArchive/DeletedArchive";
import { SharedObjects } from "./SharedObjects";
import { LockedObjects } from "./LockedObjects";
import { FeatureToggleConstants } from "Constants";
import { SelectableTextList } from "Components/Shared";
import styles from "../tabWithSubTabs.module.scss";
import { AuthContext } from "Providers";
import { UserHelperSingleton } from "Helpers";

enum ObjectSettingsTabsEnum {
    Archive = "Archive",
    Locked = "Locked",
    Shared = "Shared",
    Types = "Types",
}

export const ObjectSettings: FC = () => {
    // Context
    const { auth } = useContext(AuthContext);

    // Memo needed for the tabs
    const possibleObjectSettingsTabs = useMemo(() => {
        const settingsTabs = [];
        if(FeatureToggleConstants.DeletedArchive && UserHelperSingleton.hasAccessToArchive(auth)) {
            settingsTabs.push(ObjectSettingsTabsEnum.Archive);
        }
        if(FeatureToggleConstants.AdminLockedObjectsManagement && UserHelperSingleton.hasAccessToAllLocked(auth)) {
            settingsTabs.push(ObjectSettingsTabsEnum.Locked);
        }
        if(FeatureToggleConstants.AdminManageSharedPage && UserHelperSingleton.hasAccessToShared(auth)) {
            settingsTabs.push(ObjectSettingsTabsEnum.Shared);
        }
        if(FeatureToggleConstants.AdminTypeManagement && UserHelperSingleton.hasAccessToTypeManagement(auth)) {
            settingsTabs.push(ObjectSettingsTabsEnum.Types);
        }
        return settingsTabs;
    }, [auth]);

    const [activeTab, setActiveTab] = useState<ObjectSettingsTabsEnum>(possibleObjectSettingsTabs[0]);

    const onTabChange = (tab: ObjectSettingsTabsEnum) => {
        setActiveTab(tab);
    };

    return (
        <div className={styles.tabSettings}>
            <div className={styles.selectableTextListContainer}>
                <SelectableTextList<ObjectSettingsTabsEnum>
                    items={possibleObjectSettingsTabs.map(tab => ({ key: tab, keyAsString: tab, name: tab }))}
                    onItemSelect={(key) => { onTabChange(key); }}
                    selectedItemKey={activeTab} />
            </div>
            <div className={styles.tabContent}>
                {activeTab === ObjectSettingsTabsEnum.Archive && <DeletedArchive />}
                {activeTab === ObjectSettingsTabsEnum.Locked && <LockedObjects />}
                {activeTab === ObjectSettingsTabsEnum.Shared && <SharedObjects />}
                {activeTab === ObjectSettingsTabsEnum.Types && <div>Types</div>}
            </div>
        </div>
    );
};