// React
import { FC, useState } from "react";
// Components
import { FindestButton, MarkdownItComponent, RolesChecker, SaveableEditableMarkdown, UserIcon } from "Components";
// Helpers
import { DateHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import { TCommentv2DTO } from "Types";
// Enums
import { RolesEnum, ToastTypeEnum } from "Enums";
// Controllers
import { CommentControllerSingleton } from "Controllers";
// Styles
import styles from "../pageComments.module.scss";

export type TPageCommentProps = {
    comment: TCommentv2DTO,
    handleDelete: (comment: TCommentv2DTO) => void
};

export const PageComment: FC<TPageCommentProps> = ({ comment, handleDelete }) => {
    // State
    const [isEditing, setIsEditing] = useState<boolean>(false);

    // Logic
    const onSaveClick = async (newComment: string): Promise<void> => {
        // safety-checks
        if (!newComment) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Value can't be empty.");
            return;
        }

        // update text of comment
        comment.text = newComment;

        // update comment in db
        const updatedComment: TCommentv2DTO | undefined = await CommentControllerSingleton.updatev2Async(comment.id, comment);

        // safety-checks
        if (!updatedComment) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to update.");
            return;
        }

        // reset is editing state
        setIsEditing(false);
    };

    const onDeleteClickAsync = async (): Promise<void> => {
            // prevent accidental deletion
            if(!confirm("Are you sure you want to delete this comment? It will delete all replies as well.")) return;

            // remove comment in db (as well as related replies)
            const isSuccess = await CommentControllerSingleton
                .deletev2Async(comment.id);
    
            // if not successful, show error toast
            if (!isSuccess) {
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Was not able to delete comment.");
                return;
            }
    
            // call handleDelete function prop
            handleDelete(comment);
    };

    return (
        <div className={styles.pageComment}>
            <div className={styles.pageCommentHeader}>
                <div className={styles.pageCommentMetadataContainer}>
                    <UserIcon email={comment.username} extraClassName={styles.pageCommentIcon} size="large" />
                    <div className={styles.pageCommentMetadata}>
                        <h5 className={styles.pageCommentEmail}>{comment.username}</h5>
                        <h5 className={styles.pageCommentDate}>{DateHelperSingleton.getDateWithYear(comment.dateAdded)}</h5>
                    </div>
                    
                </div>
                <RolesChecker roles={[RolesEnum.External]} isExcluding={true}>
                    {!isEditing && (
                        <div className={styles.pageCommentActionsContainer}>
                            <FindestButton title="edit" buttonType="secondary" onClick={() => setIsEditing(true)} />
                            <FindestButton title="delete" buttonType="secondary" onClick={onDeleteClickAsync}/>
                        </div>
                    )}
                </RolesChecker>
            </div>
            <div className={styles.pageCommentContent}>
                {!isEditing ?
                    <MarkdownItComponent source={comment.text} />
                :
                    <SaveableEditableMarkdown 
                        source={comment.text}
                        noSourcePlaceholder="Type your comment here."
                        isEditing={isEditing}
                        onSaveClick={onSaveClick}
                        onCancelClick={() => setIsEditing(false)}
                        isHighlighted
                        hasContentBorder
                    />
                }
            </div>
        </div>
    );
};