export class RegexHelper {

    public ContainsDigit(value: string): boolean {
        return /\d/.test(value);
    }

    public ContainsLowercaseLetter(value: string): boolean {
        return /[a-z]/.test(value);
    }

    public ContainsUppercaseLetter(value: string): boolean {
        return /[A-Z]/.test(value);
    }

    public ContainsSpecialCharacter(value: string): boolean {
        return /[^a-zA-Z\d]/.test(value);
    }
}

export const RegexHelperSingleton = new RegexHelper();