// Node Modules
import { FC, ReactNode, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMinimize, faXmark } from "@fortawesome/free-solid-svg-icons";
// Components
import { Modal, Tooltip } from "Components";
// Providers
import { WindowingContext } from "Providers";
// Styles
import styles from "./findestWindow.module.scss";

type TFindestWindowProps = {
    openCount: number,
    minimizeCount: number,
    windowId: string,
    children?: ReactNode,
    isHidden: boolean,
    isGraphWindow?: boolean
};

export const FindestWindow: FC<TFindestWindowProps> = ({ openCount, minimizeCount, windowId,
        children, isHidden, isGraphWindow }: TFindestWindowProps) => {
    // Context
    const { deleteWindow } = useContext(WindowingContext);
    // State
    const [isMinimizedState, setIsMinimizedState] = useState<boolean>(false);
    const [lastMinimizeCount, setLastMinimizeCount] = useState<number>(0);
    const [isMinimizeButtonTooltipOpen, setIsMinimizeButtonTooltipOpen] = useState<boolean>(false);
    const [isCloseButtonTooltipOpen, setIsCloseButtonTooltipOpen] = useState<boolean>(false);
    const [minimizeButtonReference, setMinimizeButtonReference] = useState<HTMLDivElement | null>(null);
    const [closeButtonReference, setCloseButtonReference] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        setIsMinimizedState(false);
    }, [openCount]);

    useEffect(() => {
        if(minimizeCount > lastMinimizeCount) {
            setLastMinimizeCount(minimizeCount);
            setIsMinimizedState(true);
        }
    }, [lastMinimizeCount, minimizeCount]);

    const handleMinimize = () => {
        setIsMinimizedState(true);
        resetReferenceModalDisplay();
    };

    const handleClose = () => {
        resetReferenceModalDisplay();
        deleteWindow(windowId);
    };

    const showMinimizeButtonTooltip = () => {
        setIsMinimizeButtonTooltipOpen(true);
    };

    const hideMinimizeButtonTooltip = () => {
        setIsMinimizeButtonTooltipOpen(false);
    };

    const showCloseButtonTooltip = () => {
        setIsCloseButtonTooltipOpen(true);
    };

    const hideCloseButtonTooltip = () => {
        setIsCloseButtonTooltipOpen(false);
    };


    const resetReferenceModalDisplay = (): void => {
        document.querySelectorAll('[class*="referenceModal_referenceModalOverlay__"]').forEach((elem: Element) => {
            (elem as HTMLElement).style.display = "block";
        });
    };
    
    return (
        <Modal
            isOpen
            onRequestClose={handleMinimize}
            extraClassNames={{ container: styles.findestWindowModalContainer, overlay: `${styles.findestWindowModalOverlay} ${isMinimizedState || isHidden ? styles.hidden : ""}` }}
        >
            <div className={`${styles.windowActionButtons} ${isGraphWindow && isGraphWindow ? styles.graphWindow : ""}`}>
                <div
                    onMouseEnter={showMinimizeButtonTooltip}
                    onMouseLeave={hideMinimizeButtonTooltip}
                    ref={setMinimizeButtonReference}
                    className={`${styles.windowActionButton} ${styles.minimize}`}
                    onClick={handleMinimize}
                >
                    <FontAwesomeIcon icon={faWindowMinimize} />
                </div>
                <Tooltip
                    referenceEl={minimizeButtonReference}
                    isOpen={isMinimizeButtonTooltipOpen}
                    tooltipText="Minimize window"
                />
                <div
                    onMouseEnter={showCloseButtonTooltip}
                    onMouseLeave={hideCloseButtonTooltip}
                    ref={setCloseButtonReference}
                    className={`${styles.windowActionButton} ${styles.close}`}
                    onClick={handleClose}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </div>
                <Tooltip
                    referenceEl={closeButtonReference}
                    isOpen={isCloseButtonTooltipOpen}
                    tooltipText="Close window"
                />
            </div>
            <div className={styles.modalContent}>
                {children}
            </div>
        </Modal>
    );
};