// node_modules
import { FC, useContext } from "react";
// Components
import { HighlightsGroups } from "Components";
// Contexts
import { AuthContext, ElementVisibilityContext } from "Providers";
// Types
import { THighlightDTO } from "Types";
// Styles
import "Styles/documentHighlights.scss";
import styles from "./documentHighlights.module.scss";
// Interfaces
import { IDocumentDetails } from "Interfaces";

// Component props type
type TDocumentHighlightsProps = {
    document: IDocumentDetails,
    updateHighlights: (highlights: THighlightDTO[]) => void
}

// Component
export const DocumentHighlights: FC<TDocumentHighlightsProps> = ({ document, updateHighlights }) => {
    // Contexts
    const { isUserExternal } = useContext(AuthContext);
    const { canUserEdit } = useContext(ElementVisibilityContext);

    // Render
    return (
        document.highlights.length > 0 ? 
                <div className={styles.rightContentItem}>
                    <h3 className={styles.rightContentItemTitle}>Highlights</h3>
                    <HighlightsGroups 
                        updateHighlights={updateHighlights}
                        highlights={document.highlights}
                        isEditable={canUserEdit && !isUserExternal}
                    />
                </div>
            :
                null
    );
};