// React
import { FC, useContext, useMemo } from "react";
// Enums
import { RolesEnum } from "Enums";
// Providers
import { AuthContext } from "Providers";

type TRolesCheckerProps = {
    roles: RolesEnum[],
    isExcluding?: boolean,
    children: React.ReactNode,
};

export const RolesChecker: FC<TRolesCheckerProps> = ({ roles = [], isExcluding = false, children }) => {
    // Contexts
    const { auth } = useContext(AuthContext);

    // Memo
    const rolesSet = useMemo(() => {
        // convert the roles to a set of strings
        return new Set(roles.map(role => role.toString()));
    }, [roles]);

    const isAuthorized = useMemo(() => {
        // if auth is not defined, then return false
        if(!auth) return false;

        // depending on isExcluding
        if (isExcluding) {
            // return true if none of the user roles are in the defined roles
            return !auth.roles.some(role => rolesSet.has(role.toString()));
        } else {
            // return true if any of the user roles is in the defined roles
            return auth.roles.some(role => rolesSet.has(role.toString()));
        }
    }, [auth, isExcluding, rolesSet]);

    // if user is not authorized, return null
    if (!isAuthorized) return null;

    // otherwise, return the children
    return (
        <>
            {children}
        </>
    );
};