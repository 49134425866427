import axios from "axios";
import { OrderByEnum, StudyStatusEnum, StudyTypeEnum } from "Enums";
import { AxiosHelperSingleton, StudyTypeHelperSingleton } from "Helpers";
import { TAxiosParams, TIdNameType, TIntakeSheetConfirmationDTO, TStudiesDTO } from "Types";
// Interfaces
import { IStudyDTO } from "Interfaces";

export class StudyController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/study`;

    public async createAsync(title: string, description: string, type: StudyTypeEnum, customTypeName?: string): Promise<IStudyDTO | undefined> {
        const studyToCreate = { title, description, type, customTypeName } as IStudyDTO;

        try {
            const response = await axios.post<IStudyDTO>(`${this._resourcePath}`, studyToCreate);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateAsync(study: IStudyDTO): Promise<boolean> {
        try {
            const response = await axios.put<IStudyDTO>(`${this._resourcePath}/${study.id}`, study);

            return response.status === 200;
        } catch {
            return false;
        }
    }

    public async updateTypeAsync(studyId: string, type: StudyTypeEnum, customTypeName?: string): Promise<IStudyDTO | undefined> { 
        try {
            const formData = new FormData();
            // Convert type from string to number
            const typeNumber = StudyTypeHelperSingleton.enumToNumber(type);
            formData.append("type", `${typeNumber}`);
            if (customTypeName) { formData.append("customTypeName", customTypeName); }

            const response = await axios.put<IStudyDTO | undefined>(`${this._resourcePath}/${studyId}/type`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateTitleAsync(studyId: string, title: string): Promise<IStudyDTO | undefined> { 
        try {
            const formData = new FormData();
            formData.append("title", title);

            const response = await axios.put<IStudyDTO | undefined>(`${this._resourcePath}/${studyId}/title`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getCustomTypeNamesAsync(): Promise<string[] | undefined> { 
        try {
            const response = await axios.get<string[] | undefined>(`${this._resourcePath}/type/custom`);
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getAsync(orderBy: OrderByEnum,
            fromDate: Date | undefined, studyStatuses: StudyStatusEnum[],
            studyTypes: StudyTypeEnum[], isCreatedByMe = false,
            customTypeNames: string[] | undefined = undefined,
            connectedToObjectId?: string,
            doOnlyGetConnectedToObjectId?: boolean): Promise<TStudiesDTO> {
        try {
            // init url
            const url = `${this._resourcePath}`;
            const axiosParams: TAxiosParams = {
                orderBy: orderBy
            };
            // put fromDate in url if it is defined
            if (fromDate) {
                axiosParams.fromDate = fromDate;
            }
            // put createdByMe in url if it is defined
            if (isCreatedByMe !== undefined) {
                axiosParams.createdByMe = isCreatedByMe;
            }

            // put studyStatuses in url if it is defined
            if(studyStatuses.length > 0) {
                axiosParams.statuses = studyStatuses;
            }

            // put studyTypes in url if it is defined
            if(studyTypes.length > 0) {
                axiosParams.types = studyTypes;
            }
            
            // put custom type names in url if it is defined
            if (customTypeNames && customTypeNames.length > 0) {	
                axiosParams.customTypeNames = customTypeNames;
            }

            // put connectedToObjectId in url if it is defined
            if (connectedToObjectId) {
                axiosParams.connectedToObjectId = connectedToObjectId;
            }

            // put doOnlyGetConnectedToObjectId in url if it is defined
            if (doOnlyGetConnectedToObjectId !== undefined) {
                axiosParams.doOnlyGetConnectedToObjectId = doOnlyGetConnectedToObjectId;
            }
            
            // get studies
            const response = await axios.get<TStudiesDTO>(url, {
                params: axiosParams
            });

            if (response && response.data) {
                return response.data;
            } else {
                return {
                    studies: [],
                    totalStudiesCount: 0
                };
            }
        } catch {
            return {
                studies: [],
                totalStudiesCount: 0
            };
        }
    }

    public async getByIdAsync(studyId: string, doGetPlainDescription?: boolean): Promise<IStudyDTO | undefined> {
        try {
            // init url
            const url = `${this._resourcePath}/${studyId}`;
            const axiosParams: TAxiosParams = {};

            // if doGetPlainDescription is set
            if (doGetPlainDescription !== undefined) {
                // append the query string parameter
                axiosParams.doGetPlainDescription = doGetPlainDescription;
            }

            const response = await axios.get<IStudyDTO>(url, {
                params: axiosParams
            });

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async deleteAsync(id: string): Promise<boolean> { 
        try {
            const response = await axios.delete(`${this._resourcePath}/${id}`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async bulkDeleteAsync(ids: string[]): Promise<boolean> { 
        try {
            const response = await axios.delete(`${this._resourcePath}`, {
                params: {
                    ids: ids
                }
            });

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async convertToEntityAsync(studyId: string): Promise<boolean> {
        try {
            const response = await axios.post(`${this._resourcePath}/${studyId}/convertToEntity`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async updateStudyIntakeSheetConfirmationAsync(studyId: string, isConfirmed: boolean, 
            doChangeDescription?: boolean): Promise<string | undefined> {
        try {
            // build intake sheet confirmation dto
            const intakeSheetConfirmation: TIntakeSheetConfirmationDTO = {
                isConfirmed,
                doChangeDescription: doChangeDescription ? doChangeDescription : false
            };

            // send request
            const response = await axios.post<string>(`${this._resourcePath}/${studyId}/intakesheetconfirmation`, 
                intakeSheetConfirmation);
            
            // deal with response
            if (response?.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            // deal with error
            return undefined;
        }
    }

    public async getAllCompactAsync(): Promise<TIdNameType[]> {
        try {
            // get studies
            const response = await axios.get<TIdNameType[]>(`${this._resourcePath}/all/compact`);

            if (response && response.data) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getDeletedAsync(orderBy: OrderByEnum, fromDate: Date | undefined): Promise<TStudiesDTO> {
        try {
            // init url
            const url = `${this._resourcePath}/deleted`;
            const axiosParams: TAxiosParams = {
                orderBy: orderBy
            };

            // put fromDate in url if it is defined
            if (fromDate) {
                axiosParams.fromDate = fromDate;
            }

            // get studies
            const response = await axios.get<TStudiesDTO>(url, {
                params: axiosParams
            });

            if (response && response.data) {
                return response.data;
            } else {
                return {
                    studies: [],
                    totalStudiesCount: 0
                };
            }
        } catch {
            return {
                studies: [],
                totalStudiesCount: 0
            };
        }
    }

    public async getLockedAsync(orderBy: OrderByEnum, fromDate: Date | undefined): Promise<TStudiesDTO> {
        try {
            // init url
            const url = `${this._resourcePath}/locked`;
            const axiosParams: TAxiosParams = {
                orderBy: orderBy
            };

            // put fromDate in url if it is defined
            if (fromDate) {
                axiosParams.fromDate = fromDate;
            }

            // get studies
            const response = await axios.get<TStudiesDTO>(url, {
                params: axiosParams
            });

            if (response && response.data) {
                return response.data;
            } else {
                return {
                    studies: [],
                    totalStudiesCount: 0
                };
            }
        } catch {
            return {
                studies: [],
                totalStudiesCount: 0
            };
        }
    }

    public async undeleteAsync(ids: string[]): Promise<boolean> {
        try {
            // init url
            const url = `${this._resourcePath}`;

            const response = await axios.post(`${url}/undelete`, undefined, {
                params: {
                    ids: ids
                }
            });

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}
    
export const StudyControllerSingleton = new StudyController();