// node_modules
import { CustomBlockMarkerEnum } from "Enums";
import StateBlock from "markdown-it/lib/rules_block/state_block";

export function commonCustomBlockExtension(state: StateBlock, startLine: number, 
        marker: string, parseCustomBlockContent: (state: StateBlock, startLine: number, content: string, closeBlockMarker: string, customBlockId?: string) => boolean): boolean {
    // init markers
    const CUSTOM_BLOCK_OPEN_BLOCK_MARKER = `%F%OPEN%${marker}%F%`;
    const CUSTOM_BLOCK_CLOSE_BLOCK_MARKER = `%F%CLOSE%${marker}%F%`;
    
    // Get the start of the current line
    const pos = state.bMarks[startLine] + state.tShift[startLine];
    // Get the end of the current line
    const max = state.eMarks[startLine];
    // Get the current line from the full source
    const currentLine = state.src.slice(pos, max);

    // Try to find the start marker of the custom block
    const startMarkerIndex = currentLine.indexOf(CUSTOM_BLOCK_OPEN_BLOCK_MARKER);

    // If the marker was not found then return false, indicating that no custom block was found
    if(startMarkerIndex < 0) return false;

    // init custom block id
    let customBlockId: string | undefined = undefined;
    // init full content
    let fullContent: string = currentLine;

    // if marker is not intake sheet confirmation then get the custom block id
    if (marker !== CustomBlockMarkerEnum.IntakeSheetConfirmation && marker !== CustomBlockMarkerEnum.Stars) {
        // Get the current line from the end of the marker to the end of the line
        fullContent = currentLine.slice(startMarkerIndex + CUSTOM_BLOCK_OPEN_BLOCK_MARKER.length);
        if(!(fullContent.startsWith("[") && fullContent.charAt(37) === "]" && (fullContent.charAt(38) === "{" ||
                fullContent.charAt(38) === "["))) {
            // If no custom block id was found then return false, indicating that no custom block was found
            return false;
        }
        // Get the custom block id
        customBlockId = fullContent.slice(1, 37);
    }

    // parse specific custom block content with the given function
    return parseCustomBlockContent(state, startLine, fullContent, CUSTOM_BLOCK_CLOSE_BLOCK_MARKER, customBlockId);
}