// React
import { useContext, useEffect } from "react";
// Providers
import { PubSubContext } from "Providers";
// Enums
import { PubSubEventTypeEnum } from "Enums";

export const useAnyLinkRemovedListener = (onLinkRemoved: (fromId: string, toId: string) => void) => {
    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);
    useEffect(() => {
        pubSubHandler.subscribeToEvent(PubSubEventTypeEnum.RemoveLink, onLinkRemoved);

        return () => {
            pubSubHandler.unsubscribeFromEvent(PubSubEventTypeEnum.RemoveLink, onLinkRemoved);
        };
    }, [onLinkRemoved, pubSubHandler]);
};