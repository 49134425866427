// node_modules
import { faBookOpenReader, faDiceD6, faExpand, faSitemap, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { faListTree } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
// Interfaces
import { IEntityDTO, IObject, IStudyDTO } from "Interfaces";
// Components
import { EditableMarkdown, FindestButton, Popover } from "Components";
// Contexts
import { LinkGraphContext, WindowingContext } from "Providers";
// Controllers
import { EntityControllerSingleton, StudyControllerSingleton } from "Controllers";
// Enums
import { LinksWindowTabsEnum, ObjectTypeEnum } from "Enums";
// Helpers
import { EntityTypeHelperSingleton, ObjectTypeHelperSingleton, StudyTypeHelperSingleton } from "Helpers";
// Types
import { TReferenceModalProps } from "Types";
// Styles
import referenceModalStyles from "./referenceModal.module.scss";

type TReferencePopoverProps = TReferenceModalProps & {
    hideReferencePopover: () => void,
    setModalProps: Dispatch<SetStateAction<TReferenceModalProps>>,
    onMouseEnter?: () => void,
    onMouseLeave?: () => void,
    popoverOffset?: number,
    showInPortal?: boolean,
    hideRefencePopoverOnOutsideClick?: boolean
}

export const ReferencePopover: FC<TReferencePopoverProps> = ({ isOpen, id, type, 
    doIgnoreIsDeleted, styles, hideReferencePopover,
    setModalProps, referenceElement,
    onMouseEnter, onMouseLeave, popoverOffset, showInPortal, hideRefencePopoverOnOutsideClick
}: TReferencePopoverProps) => {
    // State
    const [reference, setReference] = useState<IObject | undefined>(undefined);
    const [referenceType, setReferenceType] = useState<ObjectTypeEnum | undefined>(undefined);

    // Contexts
    const { onReanchorClick } = useContext(LinkGraphContext);
    const { openGraph, minimizeAllWindows } = useContext(WindowingContext);

    // Hooks
    const navigate = useNavigate();

    // Logic
    useEffect(() => {
        // safety-checks
        if (!id || !type || !isOpen) {
            // set reference to undefined
            setReference(undefined);
            // set reference type to undefined
            setReferenceType(undefined);
            // stop, do not continue
            return;
        }

        (async () => {
            // based on type, fetch reference
            if (type === ObjectTypeEnum.Entity) {
                // fetch entity
                const entity: IObject | undefined = await EntityControllerSingleton
                    .getByIdAsync(id, true, doIgnoreIsDeleted);
                // set reference
                setReference(entity);
                // set reference type
                setReferenceType(ObjectTypeEnum.Entity);
            } else if (type === ObjectTypeEnum.Study) {
                // fetch study
                const study: IObject | undefined = await StudyControllerSingleton
                    .getByIdAsync(id, true);
                // set reference
                setReference(study);
                // set reference type
                setReferenceType(ObjectTypeEnum.Study);
            }
        })();
    }, [doIgnoreIsDeleted, id, isOpen, styles, type]);

    const getReferenceTypeDisplay = (currentReference: IObject, currentReferenceType: ObjectTypeEnum): string => {
        // init reference type display
        let referenceTypeDisplay = "";

        // based on reference type, set reference type display
        switch (currentReferenceType) {
            case ObjectTypeEnum.Entity:
                referenceTypeDisplay = EntityTypeHelperSingleton
                    .getEntityTypeDisplayName((currentReference as IEntityDTO).type, (currentReference as IEntityDTO).customTypeName);
                break;
            case ObjectTypeEnum.Study:
                referenceTypeDisplay = StudyTypeHelperSingleton
                    .getStudyTypeDisplayName((currentReference as IStudyDTO).type, (currentReference as IStudyDTO).customTypeName);
                break;
            default:
                break;
        }

        // return reference type display
        return referenceTypeDisplay;
    };

    const onViewPageClick = useCallback(() => {
        // safety-checks
        if (!reference || !referenceType) {
            // stop, do not continue
            return;
        }

        setModalProps((prevProps: TReferenceModalProps) => ({
            ...prevProps,
            isOpen: true,
            id,
            type
        }));
        
        hideReferencePopover();
    }, [hideReferencePopover, id, reference, referenceType, setModalProps, type]);

    const navigateToObject = useCallback(() => {
        if (!reference || !referenceType) return;

        hideReferencePopover();

        ObjectTypeHelperSingleton
            .navigateBasedOnObjectType(referenceType, reference.id, navigate);
        
        if (minimizeAllWindows) {
            minimizeAllWindows();
        }
    }, [minimizeAllWindows, navigate, referenceType, reference, hideReferencePopover]);

    const onOpenInTreeViewClick = () => {
        if (!reference) return;

        hideReferencePopover();
        
        onReanchorClick(reference.id, referenceType, false);
        openGraph(LinksWindowTabsEnum.TreeView);
    };

    const onOpenInListViewClick = () => {
        if (!reference) return;

        hideReferencePopover();

        onReanchorClick(reference.id, referenceType, false);
        openGraph(LinksWindowTabsEnum.ListView);
    };

    if (!referenceElement) return null;

    return (
        <Popover
            popoverOffset={popoverOffset !== undefined ? popoverOffset : 8}
            extraClassName={`${referenceModalStyles.referencePopoverComponent}`}
            placement="bottom-end"
            referenceEl={referenceElement ?? null}
            onMouseEnter={onMouseEnter}
            onMouseLeave={hideRefencePopoverOnOutsideClick ? undefined : (onMouseLeave ?? hideReferencePopover)}
            showInPortal={showInPortal}
            onClickOutside={hideRefencePopoverOnOutsideClick ? hideReferencePopover : undefined}
        >
            <div className={`${referenceModalStyles.referencePopover}`}>
                {(reference && referenceType) && (
                    <div className={referenceModalStyles.popoverContent}>
                        <div className={referenceModalStyles.section}>
                            <div className={referenceModalStyles.properties}>
                                <div className={referenceModalStyles.type}>
                                    <FontAwesomeIcon className={`${referenceModalStyles.objectIcon} ${referenceModalStyles[referenceType]}`} icon={referenceType === ObjectTypeEnum.Entity ? faDiceD6 : faBookOpenReader} />
                                    <h6>{getReferenceTypeDisplay(reference, referenceType)}</h6>
                                </div>
                                <div className={`${referenceModalStyles.title} ${referenceType === ObjectTypeEnum.Entity ? referenceModalStyles.entity : ""}`}>
                                    <h2 className={referenceModalStyles.titleText}>{reference.title}</h2>
                                </div>
                            </div>
                        </div>
                        <div className={referenceModalStyles.popoverBody}>
                            <div className={referenceModalStyles.markdownContainer}>
                                <EditableMarkdown
                                    objectIdEdited={reference.id}
                                    objectTypeEdited={referenceType}
                                    source={reference.description ?? ""}
                                    noSourcePlaceholder="No description"
                                    isEditMode={false}
                                    isEditable={false}
                                />
                            </div>
                            <div className={referenceModalStyles.entityLikeImageContainer}>
                                {reference.images && reference.images.length > 0 && (
                                    <div className={referenceModalStyles.entityLikeImageAspectRatioBox}>
                                        <div className={referenceModalStyles.entityLikeImageAspectRatioBoxContent}>
                                            { <img className={referenceModalStyles.entityLikeImage} src={reference.images[0].path} alt={reference.images[0].caption} />}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={referenceModalStyles.moreActionsButtons}>
                            <FindestButton
                                styleProps={["noWrap"]}
                                buttonType="secondary"
                                title="Open page"
                                onClick={navigateToObject}
                                leftIconName={faUpRightAndDownLeftFromCenter}
                            />
                            <FindestButton
                                styleProps={["noWrap"]}
                                buttonType="secondary"
                                title="Open preview"
                                onClick={onViewPageClick}
                                leftIconName={faExpand}
                            />
                            <FindestButton
                                styleProps={["noWrap"]}
                                buttonType="secondary"
                                title="Open in tree view"
                                onClick={onOpenInTreeViewClick}
                                leftIconName={faSitemap}
                            />
                            <FindestButton
                                styleProps={["noWrap"]}
                                buttonType="secondary"
                                title="Open in list view"
                                onClick={onOpenInListViewClick}
                                leftIconName={faListTree}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Popover>
    );
};