// Node modules
import axios from "axios";
import { LogFeatureNameEnum, OrderByEnum, SortTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton, LogHelperSingleton } from "Helpers";
// Types
import { TAxiosParams, TBulkDeleteQueriesDTO, TCreateQueryDTO, TQueriesDTO, TQueryFiltersDTO } from "Types";
// Interfaces
import { IQueryDTO } from "Interfaces";

export class QueryController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/query`;
    
    public async getAsync(isCreatedByMe = false, orderBy = SortTypeEnum.Newest,
        fromDate: Date | undefined): Promise<TQueriesDTO> {
        try {
            const url = `${this._resourcePath}`;
            const axiosParams: TAxiosParams = {
                isCreatedByMe: isCreatedByMe,
                orderBy: orderBy === SortTypeEnum.Newest ? OrderByEnum.Descending : OrderByEnum.Ascending
            };
            // put fromDate in url if it is defined
            if (fromDate) {
                axiosParams.fromDate = fromDate;
            }

            const response = await axios.get<TQueriesDTO>(url, {
                params: axiosParams
            });

            if (response && response.data) {
                return response.data;
            } else {
                return {
                    queries: [],
                    totalQueriesCount: 0
                };
            }
        } catch {
            return {
                queries: [],
                totalQueriesCount: 0
            };
        }
    }

    public async getByIdAsync(id: string): Promise<IQueryDTO | undefined> {
        try {
            const response = await axios.get<IQueryDTO>(`${this._resourcePath}/${id}`);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async createAsync(name: string, actionOrigin: string): Promise<IQueryDTO | undefined> {
        try {
            // safety-checks
            if (!name) {
                return undefined;
            }

            // build create query dto
            const createQueryDTO: TCreateQueryDTO = {
                name
            };

            // create query
            const response = await axios.post<IQueryDTO>(this._resourcePath, createQueryDTO);

            // safety-checks
            if (response && response.data) {
                LogHelperSingleton.logWithProperties("CreateQuery", actionOrigin ? { ActionOrigin: actionOrigin } : {});
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateFiltersAsync(queryId: string, queryFilters: TQueryFiltersDTO): Promise<boolean> {
        try {
            // build url
            const url = `${this._resourcePath}/${queryId}/filters`;

            // update filters
            const response = await axios.put(url, queryFilters);

            // safety-checks
            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async updateAsync(queryId: string, query: IQueryDTO): Promise<IQueryDTO | undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/${queryId}`;

            // update query
            const response = await axios.put<IQueryDTO | undefined>(url, query);

            // safety-checks
            if (response && response.data) {
                // return updated query
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async bulkDeleteAsync(queryIds: string[]): Promise<boolean> {
        try {

            // safety-checks
            if (!queryIds || queryIds.length === 0) {
                return false;
            }

            // build url
            const url = `${this._resourcePath}/delete/bulk`;

            // create TBulkDeleteQueriesDTO
            const bulkDeleteQueriesDTO: TBulkDeleteQueriesDTO = {
                queryIds
            };

            // delete
            const response = await axios.post<boolean>(url, bulkDeleteQueriesDTO);

            // safety-checks
            if (response) {
                return response.data;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async duplicateAsync(queryId: string): Promise<IQueryDTO | undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/${queryId}/duplicate`;

            // duplicate
            const response = await axios.post<IQueryDTO>(url);

            // safety-checks
            if (response && response.data) {
                LogHelperSingleton.log("DuplicateQuery");
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }
}


export const QueryControllerSingleton = new QueryController();