// Enums
import { LinksWindowTabsEnum } from "Enums";

export class LinksWindowTabsHelper {
    public getLinksWindowTabsDisplayValue(linksWindowTabsEnum: LinksWindowTabsEnum): string {
        switch (linksWindowTabsEnum) {
            case LinksWindowTabsEnum.TreeView:
                return "tree";
            case LinksWindowTabsEnum.ListView:
                return "list";
            default:
                return "";
        }
    }
}

export const LinksWindowTabsHelperSingleton = new LinksWindowTabsHelper();