// node_modules
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
// Styles
import styles from "./toggleButton.module.scss";

export type ToggleButtonProps = {
    toggleTextLeft?: string,
    toggleIconLeft?: IconDefinition,
    toggleTitleAttributeLeft?: string,
    toggleTextRight?: string,
    toggleIconRight?: IconDefinition,
    toggleTitleAttributeRight?: string,
    buttonWidth?: string,
    onClickToggleButton: (leftToggleActive: boolean) => void,
    leftToggleActive: boolean,
    extraClassName?: string
};

export const ToggleButton: FC<ToggleButtonProps> = ({ toggleTextLeft, toggleIconLeft, toggleTitleAttributeLeft, toggleTextRight, toggleIconRight, toggleTitleAttributeRight, buttonWidth, onClickToggleButton, leftToggleActive, extraClassName }) => {
    
    return (
        <div style={buttonWidth ? { width: buttonWidth } : undefined} className={`${styles.toggleButtonContainer} ${extraClassName ? extraClassName : ""}`}>
            <div className={`${styles.toggleButton} ${leftToggleActive ? styles.active : ""}`} onClick={() => onClickToggleButton(true)} title={toggleTitleAttributeLeft ? toggleTitleAttributeLeft : undefined}>
                {toggleIconLeft && <FontAwesomeIcon icon={toggleIconLeft} /> }
                {toggleTextLeft && <p>{toggleTextLeft}</p>}
            </div>
            <div className={`${styles.toggleButton} ${!leftToggleActive ? styles.active : ""}`} onClick={() => onClickToggleButton(false)} title={toggleTitleAttributeRight ? toggleTitleAttributeRight : undefined}>
                {toggleIconRight && <FontAwesomeIcon icon={toggleIconRight} />}
                {toggleTextRight && <p>{toggleTextRight}</p>}
            </div>
        </div>
    );
};