/* eslint-disable @typescript-eslint/no-explicit-any */
// node_modules
import {
    RefObject,
    useEffect
} from "react";

// We do use any here in order to deal with the fact that the event target can be anything (HTMLElement or SVGElement for instance)
export const useClickOutsideRef = (ref: RefObject<any>, handleClickOutsideRef: () => void, 
    exceptionElementRefs: RefObject<any>[] = [], exceptionDataIdentifiter?: string): void => {
    // - the exception element refs array is used to allow for certain elements to be clicked without triggering the handleClickOutsideRef
    // for example, when dealing with modals (which are usually positioned on top of the page)
    // - the exceptionDataIdentifiter is used to prevent the handleClickOutsideRef from being triggered when any elements with the data-identifier attribute provided exist in the DOM
    // for example when the component using this hook has a Popover component child.
    // then, when rendered, the Popover component is not in the sub-DOM of the component using this hook, so the handleClickOutsideRef would be triggered
    useEffect(() => {
        const onMouseDownListener = (mouseEvent: MouseEvent): void => {
            // get from document any elements that have the data-identifier attribute equal to the exceptionDataIdentifiter
            const exceptionElements: NodeListOf<Element> = document.querySelectorAll(`[data-identifier="${exceptionDataIdentifiter}"]`);

            // check if all conditions are met to trigger the handleClickOutsideRef
            if (ref.current !== null && 
                    ref.current !== undefined &&
                    mouseEvent.target !== null && 
                    mouseEvent.target !== undefined &&
                    !exceptionElementRefs.some((exceptionElementRef: RefObject<any>) => 
                        exceptionElementRef.current !== null && exceptionElementRef.current !== undefined && exceptionElementRef.current.contains(mouseEvent.target)) &&
                    !ref.current.contains(mouseEvent.target) &&
                    exceptionElements.length < 1) {
                handleClickOutsideRef();
            }
        };

        // bind the event listener
        document.addEventListener("mousedown", onMouseDownListener);

        return () => {
            // unbind the event listener on clean up
            document.removeEventListener("mousedown", onMouseDownListener);
        };
    }, [ref, handleClickOutsideRef, exceptionElementRefs, exceptionDataIdentifiter]);
};
