// node_modules
import MarkdownIt from "markdown-it";
import StateBlock from "markdown-it/lib/rules_block/state_block";
// Enums
import { CustomBlockIdAttributeEnum, CustomBlockMarkerEnum, CustomDOMTag, CustomMarkdownSeparatorEnum, OtherMarkdownCustomBlockNameEnum, SpecialBlockClassNameEnum, TopDepthMarkdownCustomBlockNameEnum } from "Enums";
// Extensions
import { commonCustomBlockExtension } from "./CommonCustomBlockExtension";
// Helpers
import { StringHelperSingleton } from "Helpers";

export function studyReferenceBlockExtension(md: MarkdownIt): void {
    const parseStudyReferenceBlockContent = (state: StateBlock, startLine: number, content: string, closeStudyReferenceBlockMarker: string, studyId?: string): boolean => {
        // safety-checks
        if (!studyId) {
            return false;
        }
        
        // create a variable to store the next line index
        const nextLine = startLine;

        // remove the study id from the full content
        content = content.slice(38);

        // get study url
        const studyUrl = content.slice(1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_BLOCK_REFERENCE_URL));
        content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_BLOCK_REFERENCE_URL) + 1);

        // check if the current line contains the end marker
        let haveEndMarker = false;
        const endMarkerIndex = content.indexOf(closeStudyReferenceBlockMarker);
        if(endMarkerIndex >= 0) {
            // if the end marker was found then remove it from the full content
            // and set the haveEndMarker flag to true
            content = content.slice(0, endMarkerIndex);
            haveEndMarker = true;
        }

        // get study title from full content (between less than and greater than)
        const lessThanIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_BLOCK_REFERENCE_TITLE);
        let studyTitle = content.slice(lessThanIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_BLOCK_REFERENCE_TITLE, lessThanIndex + 1));

        // remove new lines from study title
        // see #6229
        studyTitle = StringHelperSingleton
            .removeNewLines(studyTitle);

        // update the line of the state with the last line we checked and depending
        // on if we found the end marker add one to the line index
        state.line = nextLine + (haveEndMarker ? 1 : 0);

        // if the end marker was found then add it to the tokens in the state
        if(haveEndMarker) {
            // add a token to indicate the start of the study reference block
            const startToken = state.push(`${TopDepthMarkdownCustomBlockNameEnum.StudyReference}_open`, `${CustomDOMTag.StudyReference}`, 1);
            startToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.StudyReference}`],
                [`${CustomBlockIdAttributeEnum.StudyReference}`, studyId],
                ["selected", "false"],
                ["id", `${crypto.randomUUID()}`]
            ];

            // increase the depth level of the state
            state.level++;

            // add a token to indicate the start of the reference link
            const linkToken = state.push(`${OtherMarkdownCustomBlockNameEnum.ReferenceLink}_open`, `${CustomDOMTag.ReferenceLink}`, 1);
            linkToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.ReferenceLink}`],
                ["href", studyUrl], 
                ["target", "_blank"],
                ["rel", "noopener noreferrer"]
            ];

            // add the content of the object reference block
            const titleToken = state.push("inline", "", 0);
            titleToken.content = studyTitle;
            titleToken.map = [startLine, state.line];
            titleToken.children = [];

            // add a token to indicate the end of the reference link
            state.push(`${OtherMarkdownCustomBlockNameEnum.ReferenceLink}_close`, `${CustomDOMTag.ReferenceLink}`, -1);

            // decrease the depth level of the state
            state.level--;

            // add a token to indicate the end of the object reference block
            state.push(`${TopDepthMarkdownCustomBlockNameEnum.StudyReference}_close`, `${CustomDOMTag.StudyReference}`, -1);
        }

        // return haveEndMarker
        return haveEndMarker;
    };

    
    // execute the study reference block function after the entity reference function
    md.block.ruler.after(
        `${TopDepthMarkdownCustomBlockNameEnum.EntityReference}`, 
        `${TopDepthMarkdownCustomBlockNameEnum.StudyReference}`, 
        (state: StateBlock, startLine: number) => commonCustomBlockExtension(state, startLine, `${CustomBlockMarkerEnum.StudyReference}`, parseStudyReferenceBlockContent)
    );
}