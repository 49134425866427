// node_modules
import { FC } from "react";
// Styles
import markdownStyles from "Styles/markdown.module.scss";
import styles from "./editorContent.module.scss";

type TEditorContentProps = {
    editorRef: (element: HTMLDivElement | null) => void,
    extraClassNames?: { wysiwygContent?: string },
};

export const EditorContent: FC<TEditorContentProps> = ({ editorRef, extraClassNames }: TEditorContentProps) => {
    // Render
    return (
        <div className={[styles.editorContent, markdownStyles.markdownContainer, extraClassNames?.wysiwygContent ? extraClassNames.wysiwygContent : ""].join(" ")}
            ref={editorRef}>
        </div>
    );
};