export enum CustomMarkdownSeparatorEnum {
    OPEN_BLOCK_REFERENCE_URL = "{",
    CLOSE_BLOCK_REFERENCE_URL = "}",
    OPEN_BLOCK_REFERENCE_TITLE = "<",
    CLOSE_BLOCK_REFERENCE_TITLE = ">",
    OPEN_INLINE_REFERENCE_ID = "[",
    CLOSE_INLINE_REFERENCE_ID = "]",
    OPEN_INLINE_REFERENCE_TYPE = "[{",
    CLOSE_INLINE_REFERENCE_TYPE = "}]",
    OPEN_INTAKE_SHEET_CONFIRMATION_EMAIL_ADDRESS = "{",
    CLOSE_INTAKE_SHEET_CONFIRMATION_EMAIL_ADDRESS = "}",
    OPEN_INTAKE_SHEET_CONFIRMATION_DATE = "<",
    CLOSE_INTAKE_SHEET_CONFIRMATION_DATE = ">",
    OPEN_FILE_REFERENCE_URL = "{",
    CLOSE_FILE_REFERENCE_URL = "}",
    OPEN_FILE_REFERENCE_EXTENSION = "<",
    CLOSE_FILE_REFERENCE_EXTENSION = ">",
    OPEN_FILE_REFERENCE_TITLE = "[{",
    CLOSE_FILE_REFERENCE_TITLE = "}]",
    OPEN_HIGHLIGHT_REFERENCE_URL = "{",
    CLOSE_HIGHLIGHT_REFERENCE_URL = "}",
    OPEN_IMAGE_REFERENCE_IMAGE_URL = "{",
    CLOSE_IMAGE_REFERENCE_IMAGE_URL = "}",
    OPEN_IMAGE_REFERENCE_URL = "<",
    CLOSE_IMAGE_REFERENCE_URL = ">",
    OPEN_IMAGE_REFERENCE_IMAGE_CAPTION = "[{",
    CLOSE_IMAGE_REFERENCE_IMAGE_CAPTION = "}]",
    OPEN_STARS_SOURCE_ID = "{",
    CLOSE_STARS_SOURCE_ID = "}",
    OPEN_STARS_TARGET_ID = "<",
    CLOSE_STARS_TARGET_ID = ">",
    OPEN_STARS_RATING = "[",
    CLOSE_STARS_RATING = "]",
    OPEN_STARS_NUMBER_OF_RATERS = "[<",
    CLOSE_STARS_NUMBER_OF_RATERS = ">]",
    OPEN_STARS_IS_RATING_NEEDED = "[{",
    CLOSE_STARS_IS_RATING_NEEDED = "}]"
}
