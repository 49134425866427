// Enums
import { StudyStatusEnum } from "Enums";
// Types
import { TOption, TOptions } from "Types";

export class StudyStatusHelper {
    private studyStatusStringToEnumMapping: { [name: string]: StudyStatusEnum } = {
        "new": StudyStatusEnum.New,
        "active": StudyStatusEnum.Active,
        "closed": StudyStatusEnum.Closed,
    };

    public getStudyStatusDisplayName(studyStatus: StudyStatusEnum): string {
        switch (studyStatus) {
            case StudyStatusEnum.New:
                return "New";
            case StudyStatusEnum.Active:
                return "Active";
            case StudyStatusEnum.Closed:
                return "Closed";
            default:
                return "Unknown";
        }
    }

    private getStudyStatusNamePairs(): TOption<StudyStatusEnum>[] {
        return Object
            .keys(StudyStatusEnum)
            .filter((key) => Number.isNaN(Number(key)))
            .map((key: string) => ({
                value: this.studyStatusStringToEnum(key),
                title: key
            }));
    }

    public studyStatusStringToEnum(studyStatus: string): StudyStatusEnum {
        return this.studyStatusStringToEnumMapping[studyStatus.toLowerCase()];
    }

    public studyStatusFilterDropdownOptions: TOptions<StudyStatusEnum>[] = [
        {
            group: "Study Status",
            options: this.getStudyStatusNamePairs()
        }
    ];

    public allStudyStatuses: StudyStatusEnum[] = Object.values(StudyStatusEnum) as StudyStatusEnum[];
}

export const StudyStatusHelperSingleton = new StudyStatusHelper();