// node_modules
import { FC } from "react";
// Images
import OpenAccessLogo from "Assets/Images/openAccessLogo.png";
// Styles
import styles from "./openAccess.module.scss";

export const OpenAccess: FC = () => {
    // Render
    return (
        <img className={styles.openAccessLogo} src={OpenAccessLogo} alt="Open Access Logo" />
    );
};