export class StringHelper {
    public capitalizeFirstLetter(stringValue: string): string {
        return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);   
    }

    public removeNewLines(stringValue: string): string {
        return stringValue.replace(/(\r\n|\n|\r)/gm, "");
    }

    public formatWithDot(stringValue: string): string {
        // trim string value
        stringValue = stringValue.trim();

        // if string value does not end with a dot
        if (stringValue && stringValue.slice(-1) !== ".") {
            // add a dot
            stringValue += ".";
        }

        // return string value
        return stringValue;
    }

    public removeDotAtTheEnd(stringValue: string): string {
        // trim string value
        stringValue = stringValue.trim();

        // if string value ends with a dot
        if (stringValue && stringValue.slice(-1) === ".") {
            // remove last dot
            stringValue = stringValue.slice(0, -1);
        }

        // return string value
        return stringValue;
    }
}

export const StringHelperSingleton = new StringHelper();