// node_modules
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
// Components
import { RatingStar } from "Components";
// Styles
import styles from "./fiveRatingStars.module.scss";

// Props type
type TFiveRatingStarsProps = {
    count?: number,
    isRatingNeeded?: boolean,
    score?: number | null | undefined,
    isRatingEditable: boolean,
    onNewScore?: (newScore: number) => void,
    children?: ReactNode,
    onMouseEnter?: () => void,
    onMouseLeave?: () => void
}

export const FiveRatingStars: FC<TFiveRatingStarsProps> = ({ count, isRatingNeeded, score, isRatingEditable, onNewScore, children, onMouseEnter, onMouseLeave }: TFiveRatingStarsProps) => {
    // Constants
    const fiveRatingStarsValue: number[] = [0.00, 1.00, 2.00, 3.00, 4.00];

    // State
    const [fiveRatingStars, setFiveRatingStars] = useState<number | null | undefined>(score);

    // useEffect
    useEffect(() => {
        // when score changes, set five rating stars to score
        setFiveRatingStars(score);
    }, [score]);

    // Logic
    const onMouseOverHandler = useCallback((newFiveRatingStars: number): void => {
        // safety-checks
        if (!isRatingEditable) {
            // do nothing
            return;
        }

        // set five rating stars
        setFiveRatingStars(newFiveRatingStars);
    }, [isRatingEditable]);

    const onMouseOutHandler = useCallback(() => {
        // safety-checks
        if (!isRatingEditable) {
            // do nothing
            return;
        }

        // set five rating stars to score
        setFiveRatingStars(score);
    }, [score, isRatingEditable]);

    const onClickHandler = useCallback((newScore: number) => {
        // safety-checks
        if (!isRatingEditable || !onNewScore) {
            // do nothing
            return;
        }

        // set five rating stars to new score
        setFiveRatingStars(newScore);

        // call on new score
        onNewScore(newScore);
    }, [isRatingEditable, onNewScore]);
    
    // Rating should be between 0.00 and 5.00 but could also be null, undefined or not in the range
    // Render
    return (
        <div className={styles.fiveRatingStars}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={`${styles.ratingStars} ${isRatingNeeded ? styles.isRatingNeeded : ""}`}>
                {fiveRatingStarsValue.map((fiveRatingStarValue: number) => {
                    return (
                        <RatingStar
                            size="medium"
                            extraClassNames={{ container: styles.ratingStarContainer }}
                            key={fiveRatingStarValue}
                            clickable={isRatingEditable}
                            rating={fiveRatingStars ? fiveRatingStars - fiveRatingStarValue : 0}
                            onMouseOverHandler={isRatingEditable ? () => { onMouseOverHandler(fiveRatingStarValue + 1.00); } : undefined}
                            onMouseOutHandler={isRatingEditable ? () => { onMouseOutHandler(); } : undefined}
                            onClickHandler={isRatingEditable ? () => { onClickHandler(fiveRatingStarValue + 1.00); } : undefined} />
                    );
                })}
                {(count !== undefined) && (
                    <div>
                        ({count})
                    </div>
                )}
            </div>
            {children}
        </div>
    );

};