// node_modules
import { FC, ReactNode, useEffect, useState } from "react";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// Enums
import { AddableSynonym, Modal, Tabs } from "Components";
// Enums
import { SynonymControllerSingleton } from "Controllers";
import { SynonymTypeEnum } from "Enums";
// Styles
import styles from "./synonymsHelperModal.module.scss";

type TSynonymsHelperPopupProps = {
    queryId: string,
    value: string,
    synonymsType: SynonymTypeEnum,
    fieldId: number,
    synonymId?: number,
    addSynonymAsync: (fieldId: number, synonymType: SynonymTypeEnum, newSynonymValue: string) => Promise<void>,
    closeSynonymsHelperPopup: () => void,
    children: ReactNode
}

export const SynonymsHelperPopup: FC<TSynonymsHelperPopupProps> = ({ queryId, value, synonymsType,
    fieldId, synonymId, addSynonymAsync, closeSynonymsHelperPopup, children }: TSynonymsHelperPopupProps) => {
    // State
    const [generatedSynonyms, setGeneratedSynonyms] = useState<string[]>([]);
    const [historicalSynonyms, setHistoricalSynonyms] = useState<string[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>("Generated");
    const [initialValue] = useState<string>(value);
    const [displayValue, setDisplayValue] = useState<string>(value);


    // Logic
    const onAddSynonymAsync = async (newSynonymValue: string,
        currentFieldId: number,
        currentSynonymsType: SynonymTypeEnum,
        currentAddSynonymAsync: (fieldId: number, synonymType: SynonymTypeEnum, newSynonymValue: string) => Promise<void>): Promise<void> => {
        // add synonym async
        await currentAddSynonymAsync(currentFieldId, currentSynonymsType, newSynonymValue);

        // remove it from generated and historical synonyms
        setGeneratedSynonyms((prevGeneratedSynonyms: string[]) => {
            return prevGeneratedSynonyms.filter((generatedSynonym: string) => generatedSynonym !== newSynonymValue);
        });
        setHistoricalSynonyms((prevHistoricalSynonyms: string[]) => {
            return prevHistoricalSynonyms.filter((historicalSynonym: string) => historicalSynonym !== newSynonymValue);
        });

    };

    const updateSynonyms = async (currentValue: string) => {
        setGeneratedSynonyms(
            await SynonymControllerSingleton
                .getGeneratedAsync(queryId, fieldId, currentValue, synonymsType)
        );
        setHistoricalSynonyms(
            await SynonymControllerSingleton
                .getHistoricalAsync(queryId, fieldId, currentValue, synonymsType, synonymId)
        );
        setDisplayValue(currentValue);
    };

    useEffect(() => {
        (async () => {
            await updateSynonyms(value);
        })();
    }, [fieldId, queryId, synonymId, synonymsType, value]);

    const onSelectedTabChange = (tab: string) => {
        if(tab === "Generated" || tab === "Historical") {
            setSelectedTab(tab);
        }
    };

    // Render
    return (
            <Modal
            title="Show synonyms"
            isOpen
            onClose={() => { closeSynonymsHelperPopup(); }}
            headerProps={{ 
                text: initialValue ,
                icon: faArrowsRotate,
                onIconClick: async () => {
                    await updateSynonyms(initialValue);
                    setDisplayValue(initialValue);
        }}}
            extraClassNames={{ container: styles.synonymsHelperModal, headerText: styles.synonymsHelperModalHeader }}>
            <div className={styles.synonymsHelperModalContainer}>
                <div className={styles.column}>
                    <div className={styles.title}>
                        Synonyms
                    </div>
                    <div className={styles.synonymsList}>
                        {children}
                    </div>
                </div>
                <div className={`${styles.column} ${styles.right}`}>
                    <div className={styles.title}>
                        {`Suggested synonyms for ${displayValue}`}
                    </div>
                    <div>
                        <Tabs tabs={[{ name: "Generated" }, { name: "Historical" }]} onSelectedTabChange={onSelectedTabChange} />
                        {selectedTab === "Generated" ? (
                            <div>
                                {generatedSynonyms.map((generatedSynonymValue: string) => {
                                    return (
                                        <AddableSynonym
                                            isAdded={false}
                                            key={`generated${generatedSynonymValue}`}
                                            synonymValue={generatedSynonymValue}
                                            addSynonymAsync={async (newSynonymValue: string) => { await onAddSynonymAsync(newSynonymValue, fieldId, synonymsType, addSynonymAsync); }} />
                                    );
                                })}
                                {generatedSynonyms.length === 0 && 
                                    <span className={styles.notFoundMessage}>No generated synonyms found</span>
                                }
                            </div>
                        ) : (
                            <div>
                                {historicalSynonyms.map((historicalSynonymValue: string) => {
                                    return (
                                        <AddableSynonym
                                            isAdded={false}
                                            key={`historical${historicalSynonymValue}`}
                                            synonymValue={historicalSynonymValue}
                                            addSynonymAsync={async (newSynonymValue: string) => { await onAddSynonymAsync(newSynonymValue, fieldId, synonymsType, addSynonymAsync); }} />
                                    );
                                })}
                                {historicalSynonyms.length === 0 && 
                                    <span className={styles.notFoundMessage}>No historical synonyms found</span>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};