// Enums
import { QueryViewOptionsEnum } from "Enums";
// Helpers
import { LocalStorageHelperSingleton } from "Helpers";
// Types
import { TQueryViewOptions } from "Types";

export class QueryViewOptionsHelper {
    private readonly storageKey = "QueryViewOptionsStored";

    public getActivatedViewOptions(): QueryViewOptionsEnum[] {
        // get filters from local storage
        const filters = LocalStorageHelperSingleton.getItem<QueryViewOptionsEnum[]>(this.storageKey);

        // if filters not defined, return empty array
        return filters ?? [];
    }

    public saveActivatedViewOptions(filters: QueryViewOptionsEnum[]): void {
        // set filters in local storage
        LocalStorageHelperSingleton.setItem<QueryViewOptionsEnum[]>(filters, this.storageKey);
    }

    public getAllViewOptions(selectedViewOptions: Set<QueryViewOptionsEnum>): TQueryViewOptions {
        return {
            isAffiliationViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.Affiliation),
            isAuthorViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.Author),
            isCitationScoreViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.CitationScore),
            isFilingDateViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.FilingDate),
            isMatchedTermsViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.MatchedTerms),
            isPatentCountryViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.PatentCountry),
            isPatentNumberViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.PatentNumber),
            isPublicationDatePatentViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.PublicationDatePatent),
            isAssigneeViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.Assignee),
            isPublicationDateScienceViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.PublicationDateScience),
            isRelevanceScoreViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.RelevanceScore),
            isInventorViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.Inventor),
            isAlreadyReadViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.AlreadyRead),
            isPublicationDateViewOptionChecked: selectedViewOptions.has(QueryViewOptionsEnum.PublicationDate)
        };
    }
}

export const QueryViewOptionsHelperSingleton = new QueryViewOptionsHelper();