// node_modules
import { faBold, faExternalLinkAlt, faHeading, faHighlighter, faImage, faIndent, faItalic, faListOl, faListUl, faOutdent, faPaperclip, faQuoteRight, faSubscript, faSuperscript, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
// Components
import { FileInputButton, OverviewTableModal, Popover } from "Components";
import { AddLinkModal } from "../AddLinkModal/AddLinkModal";
import { EditorScoutingServiceMenu } from "./EditorScoutingServiceMenu";
import { EditorTablesMenu } from "./EditorTablesMenu";
// Styles
import styles from "./editorMenu.module.scss";
// Types
import { TEditorMenuProps, THeaderLevel } from "Types";
// Custom hooks
import { useClickOutsideRef } from "Hooks";
// Enums
import { EditorFeatureEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ProseMirrorHelperSingleton, ToastHelperSingleton } from "Helpers";
// Constants
import { FeatureToggleConstants } from "Constants";
// Contexts
import { EditorContext } from "Providers";

// EditorMenu props type
type TEditorMenuComponentProps = { 
    objectIdEdited?: string,
    objectTypeEdited?: ObjectTypeEnum,
    initialIsEditorMenuDisabled?: boolean,
} & TEditorMenuProps;

export const EditorMenu: FC<TEditorMenuComponentProps> = (editorMenuProps: TEditorMenuComponentProps) => {
    // Context
    const { setIsRequirementsTableModalOpen, setIsMaturityRadarModalOpen } = useContext(EditorContext);

    // State
    const [isEditorMenuDisabled, setIsEditorMenuDisabled] = useState(editorMenuProps.initialIsEditorMenuDisabled !== undefined ? editorMenuProps.initialIsEditorMenuDisabled : !editorMenuProps.isEditModeOn);
    const [isReferencesOptionMenuOpen, setIsReferencesOptionMenuOpen] = useState<boolean>(false);
    const [isHeadersOptionMenuOpen, setIsHeadersOptionMenuOpen] = useState<boolean>(false);
    const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState<boolean>(false);
    const [referenceElementReference, setReferenceElementReference] = useState<HTMLButtonElement | null>(null);
    const [referenceElementHeaders, setReferenceElementHeaders] = useState<HTMLButtonElement | null>(null);
    const [isOverviewTableModalOpen, setIsOverviewTableModalOpen] = useState<boolean>(false);

    //Ref
    const referencesButtonRef = useRef<HTMLDivElement>(null);
    const headersButtonRef = useRef<HTMLDivElement>(null);

    // Custom hooks
    useClickOutsideRef(referencesButtonRef, () => setIsReferencesOptionMenuOpen(false));
    useClickOutsideRef(headersButtonRef, () => setIsHeadersOptionMenuOpen(false));
    
    // Memo
    const isBoldMenuDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.headerLevel, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isItalicMenuDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.headerLevel, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isHighlightedMenuDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.headerLevel, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isSubscriptMenuDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.headerLevel, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isSuperscriptMenuDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.headerLevel, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isLinkMenuDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.headerLevel, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isUnlinkMenuDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.headerLevel, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isHeadersOptionMenuDisabled = useMemo(() => { 
        return editorMenuProps.isSelectionInList || editorMenuProps.isSelectionInTable || 
            editorMenuProps.areSeveralParagraphsSelected || isEditorMenuDisabled || editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.isSelectionInList, editorMenuProps.isSelectionInTable, editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isTablesMenuDisabled = useMemo(() => { 
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.isSelectionInList || editorMenuProps.areSeveralParagraphsSelected;
    }, [editorMenuProps.headerLevel, editorMenuProps.isSelectionInList, editorMenuProps.areSeveralParagraphsSelected, isEditorMenuDisabled]);

    const isOrderedListMenuDisabled = useMemo(() => {
        return editorMenuProps.isBulletList || isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.isSelectionInTable || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [editorMenuProps.isBulletList, editorMenuProps.headerLevel, editorMenuProps.isSelectionInTable, editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.isSelectionInCustomNode, isEditorMenuDisabled]);

    const isBulletListMenuDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.isSelectionInTable || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [isEditorMenuDisabled, editorMenuProps.headerLevel, editorMenuProps.isSelectionInTable, editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.isSelectionInCustomNode]);

    const isListMenusDisabled = useMemo(() => {
        return isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.isSelectionInTable || editorMenuProps.areSeveralParagraphsSelected ||
            editorMenuProps.isSelectionInCustomNode === true;
    }, [isEditorMenuDisabled, editorMenuProps.headerLevel, editorMenuProps.isSelectionInTable, editorMenuProps.areSeveralParagraphsSelected, editorMenuProps.isSelectionInCustomNode]);

    // Logic
    useEffect(() => {
        // if is edit mode on no set, do nothing
        if (editorMenuProps.isEditModeOn === undefined) { return; }
        // otherwise set is editor menu disabled according to is edit mode on
        setIsEditorMenuDisabled(!editorMenuProps.isEditModeOn);
    }, [editorMenuProps.isEditModeOn]);

    const getMenuButtonClassName = (isActive: boolean, isDisabled?: boolean) => {
        const classNameList = [styles.menuButton];

        if (isDisabled !== undefined && isDisabled) {
            classNameList.push(styles.disabled);
        } else {
            if (isActive) classNameList.push(styles.active);
        }

        return classNameList.join(" ");
    };

    const onHeaderLevelClick = (level: THeaderLevel) => {
        // If the level to be applied is already there then remove the header style
        if(editorMenuProps.headerLevel === level) {
            editorMenuProps.applyHeaderLevel(0);
        } else {
            // Otherwise apply the header style
            editorMenuProps.applyHeaderLevel(level);
        }
    };

    const onResultsOverviewTableClick = useCallback((): void => {
        // safety-checks
        if (!editorMenuProps.editorView) {
            // set is overview table modal open to false
            setIsOverviewTableModalOpen(false);
            // stop execution, return
            return;
        }

        // check if there is already a results overview table in the document
        if (ProseMirrorHelperSingleton.isResultsOverviewTableAlreadyInDocument(editorMenuProps.editorView.state.doc)) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "A results overview table is already in the document, please remove it first.");
            // set is overview table modal open to false
            setIsOverviewTableModalOpen(false);
            // stop execution, return
            return;
        }

        // set is overview table modal open to true
        setIsOverviewTableModalOpen(true);
    }, [editorMenuProps.editorView]);

    const onRequirementsTableClickHandler = useCallback((): void => {
        setIsRequirementsTableModalOpen(true);
    }, [setIsRequirementsTableModalOpen]);

    const onMaturityRadarClickHandler = useCallback((): void => {
        // set is maturity radar modal open to true
        setIsMaturityRadarModalOpen(true);
    }, [setIsMaturityRadarModalOpen]);
    
    // Render
    return (
        <>
            <div className={`${styles.editorMenu} ${isEditorMenuDisabled ? styles.disabled : ""} ${editorMenuProps.hasPaddingLeftRight ? styles.paddingLeftRight : ""}`}>
                {(!editorMenuProps.featuresDisabled || !editorMenuProps.featuresDisabled.includes(EditorFeatureEnum.Headers)) &&
                    <>
                        <div className={styles.popoverButtonContainer} ref={headersButtonRef} onMouseLeave={() => {setIsHeadersOptionMenuOpen(false);}}>
                            <button 
                                type="button" title="Headers"
                                ref={setReferenceElementHeaders}
                                className={[getMenuButtonClassName(editorMenuProps.headerLevel === 1 || editorMenuProps.headerLevel === 2 || editorMenuProps.headerLevel === 3 ? true : false, isHeadersOptionMenuDisabled), styles.headerFormatButton].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onMouseEnter={() => {setIsHeadersOptionMenuOpen(!isHeadersOptionMenuOpen);}}>
                                    <FontAwesomeIcon icon={faHeading} />
                                    <span>eaders</span>
                            </button> 
                            {!isHeadersOptionMenuDisabled && isHeadersOptionMenuOpen ?
                                <Popover
                                    extraClassName={styles.popoverOptionMenu}
                                    referenceEl={referenceElementHeaders}
                                    placement="bottom-end"
                                >
                                    <div className={[getMenuButtonClassName(editorMenuProps.headerLevel === 0, isEditorMenuDisabled), styles.headerFormatOption, styles.headerZero].join(" ")} onMouseDown={(event) => { event.preventDefault(); }} onClick={() => onHeaderLevelClick(0)} title="Normal text">Normal text</div>
                                    <div className={[getMenuButtonClassName(editorMenuProps.headerLevel === 1, isEditorMenuDisabled), styles.headerFormatOption, styles.headerOne].join(" ")} onMouseDown={(event) => { event.preventDefault(); }} onClick={() => onHeaderLevelClick(1)} title={editorMenuProps.headerLevel === 1 ? "Remove header format" : "Header level 1"}>Heading 1</div>
                                    <div className={[getMenuButtonClassName(editorMenuProps.headerLevel === 2, isEditorMenuDisabled), styles.headerFormatOption, styles.headerTwo].join(" ")} onMouseDown={(event) => { event.preventDefault(); }} onClick={() => onHeaderLevelClick(2)} title={editorMenuProps.headerLevel === 2 ? "Remove header format" : "Header level 2"}>Heading 2</div>
                                    <div className={[getMenuButtonClassName(editorMenuProps.headerLevel === 3, isEditorMenuDisabled), styles.headerFormatOption, styles.headerThree].join(" ")} onMouseDown={(event) => { event.preventDefault(); }} onClick={() => onHeaderLevelClick(3)} title={editorMenuProps.headerLevel === 3 ? "Remove header format" : "Header level 3"}>Heading 3</div>
                                </Popover>
                            : 
                                null
                            }
                        </div> 
                        <div className={styles.editorSpacer}></div>
                    </>
                }
                <AddLinkModal 
                    isOpen={isAddLinkModalOpen}
                    setIsOpen={setIsAddLinkModalOpen} 
                    addLinkHandler={editorMenuProps.addLinkHandler} /> 
                <button type="button" className={getMenuButtonClassName(editorMenuProps.isBold, isBoldMenuDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applyBoldMark} title={"Bold"}><FontAwesomeIcon icon={faBold} /></button>
                <button type="button" className={getMenuButtonClassName(editorMenuProps.isItalic, isItalicMenuDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applyItalicMark} title={"Italic"}><FontAwesomeIcon icon={faItalic} /></button>
                <button type="button" className={getMenuButtonClassName(editorMenuProps.isHighlighted, isHighlightedMenuDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applyHighlightMark} title={"Highlight marker"}><FontAwesomeIcon icon={faHighlighter} /></button>
                {(!editorMenuProps.featuresDisabled || !editorMenuProps.featuresDisabled.includes(EditorFeatureEnum.Lists)) &&
                    <>
                        <div className={styles.editorSpacer}></div>
                        <button type="button" className={getMenuButtonClassName(editorMenuProps.isOrderedList, isOrderedListMenuDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applyOrderedList} title={"Numbered list"}><FontAwesomeIcon icon={faListOl} /></button>
                        <button type="button" className={getMenuButtonClassName(editorMenuProps.isBulletList, isBulletListMenuDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applyBulletList} title={"Bulleted list"}><FontAwesomeIcon icon={faListUl} /></button>
                        {(editorMenuProps.isOrderedList || editorMenuProps.isBulletList) && <button type="button" className={getMenuButtonClassName(false, isListMenusDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applyIncreaseListDepth} title={"Indent"}><FontAwesomeIcon icon={faIndent} /></button>} 
                        {(editorMenuProps.isOrderedList || editorMenuProps.isBulletList) && <button type="button" className={getMenuButtonClassName(false, isListMenusDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applyReduceListDepth} title={"Outdent"}><FontAwesomeIcon icon={faOutdent} /></button>}
                    </>
                }
                <div className={styles.editorSpacer}></div>
                <button type="button" className={getMenuButtonClassName(editorMenuProps.isSubscript, isSubscriptMenuDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applySubscriptMark} title={"Subscript"}><FontAwesomeIcon icon={faSubscript} /></button>
                <button type="button" className={getMenuButtonClassName(editorMenuProps.isSuperscript, isSuperscriptMenuDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applySuperscriptMark} title={"Superscript"}><FontAwesomeIcon icon={faSuperscript} /></button>
                <div className={styles.editorSpacer}></div>
                {editorMenuProps.isLink && <button type="button" className={getMenuButtonClassName(false, isUnlinkMenuDisabled)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.applyUnlinkMark} title={"Unlink"}><FontAwesomeIcon icon={faUnlink} /></button>}
                <div className={styles.popoverButtonContainer} ref={referencesButtonRef} onMouseLeave={() => {setIsReferencesOptionMenuOpen(false);}}>
                    <button 
                        type="button" title="References"
                        ref={setReferenceElementReference}
                        className={getMenuButtonClassName(false, isLinkMenuDisabled)} 
                        onMouseDown={(event) => { event.preventDefault(); }} 
                        onMouseEnter={editorMenuProps.headerLevel !== 0 ? undefined : () => {setIsReferencesOptionMenuOpen(!isReferencesOptionMenuOpen);}}>
                            <FontAwesomeIcon icon={faQuoteRight} />
                    </button> 
                    {isReferencesOptionMenuOpen && !isEditorMenuDisabled && editorMenuProps.headerLevel === 0 && !editorMenuProps.areSeveralParagraphsSelected ?
                        <Popover
                            extraClassName={styles.popoverOptionMenu}
                            referenceEl={referenceElementReference}
                            placement="bottom-end"
                        >
                            <div className={styles.popoverOptionMenuItem} onClick={() => {setIsReferencesOptionMenuOpen(false); setIsAddLinkModalOpen(true);}}>
                                    <div className={styles.popoverOptionMenuItemIcon}>
                                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </div>
                                    Insert external link
                            </div>
                        </Popover>
                    : 
                        null
                    }
                </div>
                {(!editorMenuProps.featuresDisabled || !editorMenuProps.featuresDisabled.includes(EditorFeatureEnum.Tables)) &&
                    <>
                        <div className={styles.editorSpacer}></div>
                        <EditorTablesMenu 
                            editorMenuProps={editorMenuProps}
                            isTablesMenuDisabled={isTablesMenuDisabled}
                            isSelectionInTable={editorMenuProps.isSelectionInTable}/>
                    </>
                }
                {editorMenuProps.onAddImageClick ? <button type="button" className={getMenuButtonClassName(false, isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected)} onMouseDown={(event) => { event.preventDefault(); }} onClick={editorMenuProps.onAddImageClick} title={"Add image"}><FontAwesomeIcon icon={faImage} /></button> : null}
                {editorMenuProps.onAttachFileClickHandler ? 
                        <FileInputButton
                            icon={faPaperclip}
                            fileSizeLimit={25_000_000}
                            onFileSelected={editorMenuProps.onAttachFileClickHandler}
                            extraClassNames={{
                                fileInputButton: getMenuButtonClassName(false, isEditorMenuDisabled || editorMenuProps.headerLevel !== 0 || editorMenuProps.areSeveralParagraphsSelected),
                                optionText: styles.fileInputButtonText
                            }}
                            buttonTitle="Attach file"
                        />
                    : 
                        null
                }
                {(!editorMenuProps.featuresDisabled || !editorMenuProps.featuresDisabled.includes(EditorFeatureEnum.ScoutingService)) &&
                    <EditorScoutingServiceMenu 
                        onIntakeSheetClickAsync={editorMenuProps.onIntakeSheetClickAsync} 
                        onMaturityRadarClickAsync={FeatureToggleConstants.MaturityRadar ? onMaturityRadarClickHandler : undefined} 
                        onOverviewTableClickAsync={onResultsOverviewTableClick}
                        onRequirementsTableClickHandler={FeatureToggleConstants.RequirementsTable ? onRequirementsTableClickHandler : undefined} />
                }
            </div>
        <OverviewTableModal isOpen={isOverviewTableModalOpen} setIsOpen={setIsOverviewTableModalOpen} />
    </>
    );
};
