// node_modules
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Components
import { DropdownButton } from "Components";
// Types
import { TDropdownButtonOption } from "Types";
// Styles
import styles from "./editorScoutingServiceMenu.module.scss";
// Enums
import { ToastTypeEnum } from "Enums";
// Controllers
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Contexts
import { LinksContext } from "Providers";

type TEditorScoutingServiceMenuProps = {
    onIntakeSheetClickAsync?: () => Promise<void>,
    onMaturityRadarClickAsync?: () => void,
    onOverviewTableClickAsync?: () => void,
    onRequirementsTableClickHandler?: () => void
}

export const EditorScoutingServiceMenu: FC<TEditorScoutingServiceMenuProps> = ({
    onIntakeSheetClickAsync,
    onMaturityRadarClickAsync,
    onOverviewTableClickAsync,
    onRequirementsTableClickHandler
}: TEditorScoutingServiceMenuProps) => {
    // State
    const [doesObjectHaveChildren, setDoesObjectHaveChildren] = useState<boolean>(false);

    // Contexts
    const { linkGraphForObjectEdited } = useContext(LinksContext);
    
    // when linkGraphForObjectEdited changes
    useEffect(() => {
        // if link graph for object edited is undefined
        if (!linkGraphForObjectEdited) {
            // set doesObjectHaveChildren to false
            setDoesObjectHaveChildren(false);
            // stop execution, return
            return;
        }

        // if linkGraphForObjectEdited has lower level nodes
        if (linkGraphForObjectEdited.lowerLevelNodes && linkGraphForObjectEdited.lowerLevelNodes.length > 0) {
            // set doesObjectHaveChildren to true
            setDoesObjectHaveChildren(true);
            // stop execution, return
            return;
        }

        // otherwise, set doesObjectHaveChildren to false
        setDoesObjectHaveChildren(false);
    }, [linkGraphForObjectEdited]);

    const optionLabels = useMemo((): TDropdownButtonOption[] => {
        // initialize the option labels
        const newOptionLabels: TDropdownButtonOption[] = [];

        // add intake sheet option if the onIntakeSheetClickAsync function is defined
        if (onIntakeSheetClickAsync) {
            newOptionLabels.push("intake sheet");
        }

        if (onOverviewTableClickAsync) {
            newOptionLabels.push("results overview table");
        }

        if (onRequirementsTableClickHandler) {
            newOptionLabels.push("requirements table");
        }

        // add maturity radar option if the onMaturityRadarClickAsync function is defined
        if (onMaturityRadarClickAsync) {
            newOptionLabels.push("maturity radar");
        }

        // return the new option labels
        return newOptionLabels;
    }, [onIntakeSheetClickAsync, onMaturityRadarClickAsync, onOverviewTableClickAsync, onRequirementsTableClickHandler]);

    // Logic
    const onClickOption = useCallback(async (optionName: TDropdownButtonOption,
        onMaturityRadarAsyncHandler?: () => void, onIntakeSheetClickAsyncHandler?: () => Promise<void>, onOverviewTableClickAsyncHandler?: () => void): Promise<void> => {

        // depending on the option name, call the appropriate function
        if (["maturity radar", "results overview table", "requirements table"].includes(optionName)) {
            if (!doesObjectHaveChildren) {
                ToastHelperSingleton
                    .showToast(ToastTypeEnum.Error, "Universe requires linked objects to use this feature, but there are none linked objects to the current one. Please link some objects to use this feature.",);
                // stop execution, return
                return;
            }

            if (optionName === "maturity radar" && onMaturityRadarAsyncHandler) {
                onMaturityRadarAsyncHandler();
            } else if (optionName === "results overview table" && onOverviewTableClickAsyncHandler) {
                onOverviewTableClickAsyncHandler();
            } else if (optionName === "requirements table" && onRequirementsTableClickHandler) {
                onRequirementsTableClickHandler();
            }
        } else if (optionName === "intake sheet" && onIntakeSheetClickAsyncHandler) {
            await onIntakeSheetClickAsyncHandler();
        }
    }, [doesObjectHaveChildren, onRequirementsTableClickHandler]);

    // Render
    return (
        (optionLabels && optionLabels.length > 0) ?
            <div>
                <DropdownButton
                    isButtonEnabled={true}
                    optionLabels={optionLabels}
                    onClickOption={(optionName: TDropdownButtonOption) => { onClickOption(optionName, onMaturityRadarClickAsync, onIntakeSheetClickAsync, onOverviewTableClickAsync); }}
                    iconNameRight={faChevronDown}
                    iconNameLeft={faPlus}
                    extraClassNames={{
                        dropdownButton: styles.scoutingServiceMenuDropdownButton,
                        button: styles.scoutingServiceMenuButton,
                        optionText: styles.scoutingServiceMenuOptionText,
                        rightIcon: styles.rightIcon,
                    }}
                />
            </div>
            :
            null
    );
};