import { CSSProperties } from "react";
// Components
import { GroupedListItem } from "./GroupedListItem";
// Types
import { TGroupedListExtraClassNames, TOption, TOptions } from "Types";
// Styles
import styles from "./groupedList.module.scss";

type TGroupedList<T> = {
    options: TOptions<T>[],
    extraContainerStyles?: CSSProperties,
    extraPopupClassName?: string,
    handleOptionSelect: (option: TOption<T>) => void,
    handleOptionUnselect?: (option: TOption<T>) => void,
    selectedOptions: TOption<T>[] | undefined,
    extraClassNames?: TGroupedListExtraClassNames,
    outsideClickExceptionDataIdentifier?: string
};

export function GroupedList<T>({
    options,
    extraContainerStyles,
    extraPopupClassName,
    handleOptionSelect,
    handleOptionUnselect,
    selectedOptions,
    extraClassNames = {},
    outsideClickExceptionDataIdentifier = ""
}: TGroupedList<T>) {
    return (
        <div style={extraContainerStyles} className={`${extraPopupClassName ? extraPopupClassName : ""} ${styles.groupedListContainer} ${extraClassNames.groupedListContainer || ""}`} >
            {options.map(({ group, options: groupOptions }) => {
                return (
                    <div key={`${group}-${groupOptions.length}`} className={`${extraClassNames.groupedList || ""} ${styles.groupedList}`}>
                        {group && group?.length > 0 ? <div className={`${styles.groupTitle} ${extraClassNames.groupTitle || ""}`} title={group}>{group}</div> : null}
                        {groupOptions.map((option: { title: string, value: T }) => {
                            const isSelected = selectedOptions?.find(selectedOption => selectedOption.value === option.value &&
                                selectedOption.title === option.title);
                            return (
                                <GroupedListItem
                                    key={`${option.title}-${option.value}`}
                                    isSelected={isSelected ? true : false}
                                    handleOptionSelect={handleOptionSelect}
                                    handleOptionUnselect={handleOptionUnselect}
                                    option={option}
                                    extraClassNames={extraClassNames}
                                    outsideClickExceptionDataIdentifier={outsideClickExceptionDataIdentifier}
                                />
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}