// node_modules
import {
    faFile,
    faFileAudio,
    faFileCode,
    faFileCsv,
    faFileExcel,
    faFileImage,
    faFileLines,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faFileZipper,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";

export class FileHelper {
    public isAcceptedImageFileFormat(file: File): boolean {
        // init array of accepted image file formats
        const acceptedImageFileFormats = [
            "image/jpeg",
            "image/jpg",
            "image/bmp",
            "image/gif",
            "image/png",
            "image/svg+xml",
            "image/webp"
        ];

        // return true if the file is an accepted image file format
        return acceptedImageFileFormats.includes(file.type);
    }

    public getIconFromExtension(extension: string): IconDefinition {
        extension = extension.toLowerCase();
        switch (extension) {
            case "pdf":
                return faFilePdf;
            case "pptx":
            case "pptm":
            case "ppt":
                return faFilePowerpoint;
            case "xlsx":
            case "xlsm":
            case "xls":
                return faFileExcel;
            case "csv":
            case "tsv":
                return faFileCsv;
            case "docx":
            case "docm":
            case "doc":
                return faFileWord;
            case "zip":
            case "rar":
            case "7z":
                return faFileZipper;
            case "mp4":
            case "avi":
            case "mov":
            case "wmv":
            case "flv":
            case "mkv":
                return faFileVideo;
            case "mp3":
            case "wav":
            case "wma":
            case "ogg":
            case "flac":
                return faFileAudio;
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
            case "bmp":
            case "tiff":
            case "svg":
                return faFileImage;
            case "txt":
            case "json":
                return faFileLines;
            case "js":
            case "ts":
            case "html":
            case "css":
            case "scss":
            case "less":
            case "xml":
            case "cs":
            case "java":
            case "py":
            case "php":
            case "c":
            case "cpp":
            case "h":
            case "hpp":
            case "go":
            case "swift":
            case "rb":
            case "sql":
            case "pl":
                return faFileCode;
            default:
                return faFile;
        }
    }
}

export const FileHelperSingleton = new FileHelper();
