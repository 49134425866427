// Controllers
import { WebsocketController } from "Controllers";
// Interfaces
import { IPubSubHandler } from "Interfaces";

export class WebsocketPubSubHandler implements IPubSubHandler {

    private readonly _websocketController: WebsocketController;

    constructor(websocketController: WebsocketController) {
        this._websocketController = websocketController;
    }

    public async subscribeToEvent(eventName: string, handler: (...args: any[]) => void) {
        await this._websocketController.addHandler(eventName, handler);
    }

    public unsubscribeFromEvent(eventName: string, handler: (...args: any[]) => void) {
        this._websocketController.removeHandler(eventName, handler);
    }

    public async publishEvent(eventName: string, ...args: any[]) {
        // Implement your publishEvent logic here
        await this._websocketController.invokeFunction(eventName, ...args);
    }
}