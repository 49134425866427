// node_modules
import { Mark, Node } from "prosemirror-model";
import { EditorState, Plugin, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { CSSProperties, Dispatch, MutableRefObject, ReactNode, SetStateAction, createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
// Types
import { TDropdownButtonOption, TEditorMenuProps, TIdNameTypeObjectType, TRatingsPopoverProps, TSavedFileDTO } from "Types";
// Enums
import { CustomDOMAttributes, ObjectTypeEnum, SpecialBlockClassNameEnum } from "Enums";
// Components
import { findestSchema } from "Components";
// Constants
import { ProseMirrorConstants } from "Constants";
// Custom hooks
import { useEditor } from "Hooks";
// Contexts
import { AuthContext } from "Providers";

// Constants
const ratingsPopoverPropsDefaultValues: TRatingsPopoverProps = {
    isOpen: false,
    styles: undefined,
    forTargetId: undefined,
    currentUserEmail: undefined
};

export type TEditorContext = {
    editorMenuProps: TEditorMenuProps,
    editorView?: EditorView,
    editorState?: EditorState,
    objectCreatedByUsername: string,
    setObjectCreatedByUsername: (createdByUsername: string) => void,
    objectCreatedOnDate: string,
    setObjectCreatedOnDate: (createdOnDate: string) => void,
    savedDocumentsCount: number,
    setSavedDocumentsCount: (savedDocumentsCount: number) => void,
    setObjectId: (id: string) => void,
    objectId: string,
    setObjectName: (name: string) => void,
    objectName: string,
    connectedQueriesCount: number,
    setConnectedQueriesCount: (connectedQueriesCount: number) => void,
    onActionsClickRef: MutableRefObject<(dropdownButtonOption: TDropdownButtonOption) => void>,
    onAttachFileClickHandler: (file: File) => Promise<void>,
    onAttachFileClickRef: MutableRefObject<(file: File) => Promise<TSavedFileDTO | undefined>>,
    updateEditorView: (editorViewToUpdate: EditorView, transaction: Transaction, doCallOnSourceChangeCallback: boolean, callbackBeforeSetState?: (newState: EditorState) => void) => void,
    updateEditorViewObjectSource: (newSource: string, objectIdEdited?: string, objectTypeEdited?: ObjectTypeEnum) => void,
    forceUpdateEditorViewSource: (newSource: string, doCallOnSourceCallback?: boolean) => void,
    updateOnSourceChangeCallback: (newOnSourceChangeCallback: (newSource: string) => void) => void,
    updateEditorViewStatePlugins: (plugins: Plugin[]) => void,
    applyFunctionOnEditor: (givenFunction: (state: EditorState, dispatch?: ((tr: Transaction) => void) | undefined) => boolean) => void,
    insertObject: (objectInformation: TIdNameTypeObjectType, state: EditorState, dispatch?: ((tr: Transaction) => void) | undefined) => boolean,
    editorRef: (element: HTMLDivElement | null) => void,
    editorContainerRef: MutableRefObject<HTMLDivElement | null>,
    focusEditor: () => void,
    ratingsPopoverProps: TRatingsPopoverProps,
    showRatingsPopover: (forTargetId: string, meterElement: HTMLElement) => void,
    hideRatingsPopover: () => void,
    onNewAverageRating: (forSourceId: string, forTargetId: string, newCount: number, newScore: number, newIsRatingNeeded: boolean, doCallOnSourceChangeCallback: boolean, callbackBeforeSetState?: (newState: EditorState) => void) => void,
    isRequirementsTableModalOpen: boolean,
    setIsRequirementsTableModalOpen: Dispatch<SetStateAction<boolean>>,
    isMaturityRadarModalOpen: boolean,
    setIsMaturityRadarModalOpen: Dispatch<SetStateAction<boolean>>
};

const defaultEditorContext: TEditorContext = {
    editorMenuProps : {} as TEditorMenuProps,
    editorView: undefined,
    editorState: undefined,
    objectCreatedByUsername: "",
    setObjectCreatedByUsername: () => { return; },
    objectCreatedOnDate: "",
    setObjectCreatedOnDate: () => { return; },
    savedDocumentsCount: 0,
    setSavedDocumentsCount: () => { return; },
    setObjectId: () => { return; },
    objectId: "",
    setObjectName: () => { return; },
    objectName: "",
    connectedQueriesCount: 0,
    setConnectedQueriesCount: () => { return; },
    onActionsClickRef: { current: () => { return; } },
    onAttachFileClickHandler: async () => { return; },
    onAttachFileClickRef: { current: async () => { return undefined; } },
    updateEditorView: () => { return; },
    updateEditorViewObjectSource: () => { return; },
    forceUpdateEditorViewSource: () => { return; },
    updateOnSourceChangeCallback: () => { return; },
    updateEditorViewStatePlugins: () => { return; },
    applyFunctionOnEditor: () => { return; },
    insertObject: () => { return false; },
    editorRef: () => { return; },
    editorContainerRef: { current: null },
    focusEditor: () => { return; },
    ratingsPopoverProps: ratingsPopoverPropsDefaultValues,
    showRatingsPopover: () => { return; },
    onNewAverageRating: () => { return; },
    hideRatingsPopover: () => { return; },
    isRequirementsTableModalOpen: false,
    setIsRequirementsTableModalOpen: () => { return; },
    isMaturityRadarModalOpen: false,
    setIsMaturityRadarModalOpen: () => { return; }
};

type TEditorProviderProps = {
    children?: ReactNode,
};

export const EditorContext = createContext<TEditorContext>({...defaultEditorContext});

export const EditorProvider = ({children}: TEditorProviderProps) => {
    // Contexts
    const { auth } = useContext(AuthContext);

    // Ref
    const onActionsClickRef = useRef<(dropdownButtonOption: TDropdownButtonOption) => void>(() => { return; });
    const ratingStarsHoverTimer = useRef<NodeJS.Timeout | null>(null);

    // State
    const [ratingsPopoverProps, setRatingsPopoverProps] = useState<TRatingsPopoverProps>(ratingsPopoverPropsDefaultValues);
    const [isRequirementsTableModalOpen, setIsRequirementsTableModalOpen] = useState<boolean>(false);
    const [isMaturityRadarModalOpen, setIsMaturityRadarModalOpen] = useState<boolean>(false);

    // Custom hooks
    const hookValues = useEditor();

    // Logic
    // on new average rating handler (update doc with new average rating)
    const onNewAverageRating = useCallback((forSourceId: string, forTargetId: string, newCount: number, newScore: number, newIsRatingNeeded: boolean,
            doCallOnSourceChangeCallback: boolean, callbackBeforeSetState?: (newState: EditorState) => void): void => {
        // safety-checks 
        if (!hookValues.editorView) {
            // stop execution, return
            return;
        }

        // init transaction
        let transaction: Transaction | undefined = undefined;

        // go through document nodes
        hookValues.editorView.state.doc.descendants((node: Node, pos: number) => {
            // safety-checks 
            if (!hookValues.editorView) {
                // stop execution, return
                return;
            }
            
            // if node is text node and has an inlineStars mark
            if (node.isText && node.marks.some(mark => mark.type.name === findestSchema.marks.inlineStars.name)) {
                // get inlineStars mark
                const inlineStarsMark: Mark | undefined = node.marks.find(mark => mark.type.name === findestSchema.marks.inlineStars.name);

                // safety-checks
                if (!inlineStarsMark) {
                    // stop execution, return
                    return;
                }
                
                // get needed inlineStars mark 
                const starsSourceId: string | undefined = inlineStarsMark.attrs[`${CustomDOMAttributes.StarsSourceId}`];
                const starsTargetId: string | undefined = inlineStarsMark.attrs[`${CustomDOMAttributes.StarsTargetId}`];
                
                // if starsSourceId and starsTargetId are defined and match the given ones
                if (starsSourceId && starsTargetId && starsSourceId === forSourceId && starsTargetId === forTargetId) {
                    // set transaction to update the inlineStars mark
                    transaction = transaction ?? hookValues.editorView.state.tr;
                    transaction = transaction
                        .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                        .removeMark(pos, pos + node.nodeSize, inlineStarsMark)
                        .addMark(pos, pos + node.nodeSize, findestSchema.marks.inlineStars.create(
                            {
                                ...inlineStarsMark.attrs,
                                ["class"]: `${SpecialBlockClassNameEnum.Stars}`,
                                [`${CustomDOMAttributes.StarsRating}`]: `${newScore}`,
                                [`${CustomDOMAttributes.StarsNumberOfRaters}`]: `${newCount}`,
                                [`${CustomDOMAttributes.StarsIsRatingNeeded}`]: `${newIsRatingNeeded}`
                            }
                        ));

                    // stop execution, return
                    return;
                }
            }
        });

        // run transaction if set
        if (transaction) {
            hookValues.updateEditorView(hookValues.editorView, transaction, doCallOnSourceChangeCallback, callbackBeforeSetState);
        }
    }, [hookValues]);

    // hide ratings popover
    const hideRatingsPopover = useCallback((): void => {
        if (ratingStarsHoverTimer.current) {
            clearTimeout(ratingStarsHoverTimer.current);
            ratingStarsHoverTimer.current = null;
        }
        setRatingsPopoverProps(() => {
            return {
                ...ratingsPopoverProps,
                styles: { display: "none" },
                isOpen: false
            };
        });
    }, [ratingsPopoverProps]);

    // show ratings popover
    const showRatingsPopover = useCallback((forTargetId: string, meterElement: HTMLElement): void => {
        // init styles (positions from meterElement)
        const meterElementClientRect = meterElement.getBoundingClientRect();

        const POPOVER_WIDTH = 202 > window.innerWidth ? window.innerWidth : 202;
        const POPOVER_HEIGHT = 316;
        const POPOVER_MARGIN = 6;
        let top = meterElementClientRect.top + document.documentElement.scrollTop;
        let left = meterElementClientRect.left + document.documentElement.scrollLeft + meterElementClientRect.width;

        const isPopoverOverflowingViewportWidth = left + POPOVER_WIDTH > window.innerWidth;
        const isPopoverOverflowingViewportHeight = top + POPOVER_HEIGHT > window.innerHeight;
                
        if (isPopoverOverflowingViewportHeight) {
            top =  top - POPOVER_HEIGHT / 2;
        }

        if (isPopoverOverflowingViewportWidth) {
            if (window.innerWidth - POPOVER_WIDTH - POPOVER_MARGIN > 0) {
                left = meterElementClientRect.left + document.documentElement.scrollLeft - POPOVER_WIDTH - POPOVER_MARGIN;
            } else {
                left = 0;
            }
        }

        const styles: CSSProperties = {
            left: `${left}px`,
            top: `${top}px`,
            position: "absolute",
            boxShadow: "-3px 3px 16px rgba(0,0,0,0.16)",
            maxWidth: window.innerWidth
        };
        
        // update rating popover props state with delay
        ratingStarsHoverTimer.current = setTimeout(() => {
            setRatingsPopoverProps(() => {
                return {
                    isOpen: true,
                    usePopoverComponent: false,
                    styles,
                    forTargetId,
                    currentUserEmail: auth.userEmail
                };
            });
        }, 500);
    }, [auth.userEmail]);

    const providerValue = useMemo(():  TEditorContext => {
        return {
            ...hookValues,
            onActionsClickRef,
            ratingsPopoverProps,
            showRatingsPopover,
            hideRatingsPopover,
            onNewAverageRating,
            isRequirementsTableModalOpen,
            setIsRequirementsTableModalOpen,
            isMaturityRadarModalOpen,
            setIsMaturityRadarModalOpen
        };
    }, [hookValues, ratingsPopoverProps, showRatingsPopover, hideRatingsPopover, onNewAverageRating, isRequirementsTableModalOpen, isMaturityRadarModalOpen]);
    
    // Render
    return (
        <EditorContext.Provider value={providerValue}>
            {children}
        </EditorContext.Provider>
    );
};
