// node_modules
import { FC } from "react";
// Components
import { SearchMetadataBubble } from "../SearchMetadataBubble";
// Types
import { TUniverseAffiliationResult } from "Types";
// Styles
import styles from "./affiliationsList.module.scss";

interface AffiliationListProps {
  affiliationSearchResults?: TUniverseAffiliationResult | null;
  title: string;
  showAllClicked: () => void;
}

export const AffiliationsList: FC<AffiliationListProps> = ({
  affiliationSearchResults,
  title,
  showAllClicked,
}) => {
  if (
    !affiliationSearchResults ||
    Object.entries(affiliationSearchResults.affiliations).length === 0
  ) {
    return null;
  }

  return (
    <div className={styles.affiliationsContainer}>
      <h3>{title}</h3>
      <div className={styles.affiliationsContent}>
        <div className={styles.affiliationsList}>
          <ul>
            {affiliationSearchResults &&
              Object.entries(affiliationSearchResults.affiliations).map(
                ([key, value]) => (
                  <li key={key} className={styles.affiliationItem}>
                    <span className={styles.affiliationName} title={key}>
                      {key}
                    </span>
                    <SearchMetadataBubble text={value} isSmall />
                  </li>
                )
              )}
          </ul>
        </div>
        <li className={styles.showAllAffiliations} onClick={showAllClicked}>
          Show all
        </li>
      </div>
    </div>
  );
};
