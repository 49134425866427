// node_modules
import { Node } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

export const selectedNodeReferencePlugin = () => {
    return new Plugin({
        props: {
            decorations: (state: EditorState) => {
                const decorations: Decoration[] = [];
                const decorate = (node: Node, pos: number) => {
                    if (node.attrs && node.attrs.selected !== undefined
                        && node.attrs.selected !== null
                        && node.attrs.selected === "true") {
                        decorations.push(
                            Decoration.node(pos, pos + node.nodeSize, {
                                class: "selected"
                            })
                        );
                    } else {
                        decorations.push(
                            Decoration.node(pos, pos + node.nodeSize, {
                                class: ""
                            })
                        );
                    }
                };
                state.doc.descendants(decorate);
                return DecorationSet.create(state.doc, decorations);
            }
        }
    });
};