// node_modules
import { useCallback, useEffect, useMemo, useState } from "react";
// Components
import { Dropdown } from "Components";
// Enums
import { ObjectTypeEnum } from "Enums";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Types
import { TIdNameTypeObjectType, TOption, TScoutingServiceTableObject } from "Types";

type TLayerSelectionDropdownProps = {
    extraClassName?: string,
    numberOfLayers: number,
    onChange: (layer: number) => void,
    allLayersData: (TScoutingServiceTableObject | null)[][],
    initialSelectedLayer?: number,
}

export function LayerSelectionDropdown({ extraClassName, numberOfLayers, onChange, allLayersData, initialSelectedLayer }: TLayerSelectionDropdownProps) {
    // Custom Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    const openReferenceModal = useCallback((objectId: string, objectType: ObjectTypeEnum) => {
        setReferenceModalProps((previousReferenceModalProps) => {
            return {
                ...previousReferenceModalProps,
                isOpen: true,
                id: objectId,
                type: objectType
            };
        });
    }, [setReferenceModalProps]);

    // Memo
    const layerOptions = useMemo(() => {
        const options: TOption<number>[] = [];

        for (let i = 0; i < numberOfLayers; i++) {
            // since we represent a graph in a matrix, we need to keep track of which objects are already added to the options
            // because a row can concern the same object
            const layerObjectIds: Set<string> = new Set();

            // create i. column data from allLayersData
            const columnData: TIdNameTypeObjectType[] = allLayersData.reduce((accumulator: TIdNameTypeObjectType[], row) => {
                // get object from row
                const object: TScoutingServiceTableObject | null = row[i];

                // if object is defined
                if (object && object.objectId && object.name && !layerObjectIds.has(object.objectId)) {
                    accumulator.push({
                        id: object.objectId,
                        name: object.name,
                        type:  "",
                        objectType: row[i]?.objectType ?? ObjectTypeEnum.Unknown, 
                    });

                    // add object id to layerObjectIds
                    layerObjectIds.add(object.objectId);
                }
                return accumulator;
            }, []);

            options.push({ title: `Layer ${i + 1}`, value: i + 1, optionDetails: { data: columnData, openReferenceModal: openReferenceModal } });
        }
        return options;
    }, [numberOfLayers, allLayersData, openReferenceModal]);

    // State
    const [selectedOption, setSelectedOption] = useState<TOption<number> | undefined>(initialSelectedLayer ? layerOptions[initialSelectedLayer - 1] : undefined);

    // when layerOptions changes, reset selectedOption
    useEffect(() => {
        setSelectedOption(layerOptions[0]);
    }, [layerOptions]);

    // when initialSelectedLayer changes, reset selectedOption
    useEffect(() => {
        setSelectedOption(initialSelectedLayer ? layerOptions[initialSelectedLayer - 1] : undefined);
    }, [initialSelectedLayer, layerOptions]);
    
    // Render
    return (
        <>
            <Dropdown
                className={extraClassName ?? ""}
                options={[{ options: layerOptions }]}
                placeholderText={"Select"}
                selectedOption={selectedOption}
                handleOptionSelect={(option) => { setSelectedOption(option); onChange(option.value); }}
                outsideClickExceptionDataIdentifier="optionDetailsComponentPopover"
            />
            {referenceModal}
        </>
    );
}
