// node_modules
import { FC, useEffect, useState } from "react";
// Components
import { SavedDocuments } from "Components/Shared";
// Types
import { TDocumentsDTO, TOption } from "Types";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";
// Controllers
import { SavedDocumentControllerSingleton } from "Controllers";
// Enums
import { LinkStatusEnum, OrderByEnum, SavedDocumentTypeEnum, SortTypeEnum } from "Enums";
// Styles
import styles from "./documents.module.scss";
// Helpers
import { DocumentTypeHelperSingleton, LogHelperSingleton, SavedFiltersHelperSingleton } from "Helpers";

export const Documents: FC = () => {
    // State
    const [savedDocuments, setSavedDocuments] = useState<ISavedDocumentDTO[]>([]);
    const [totalSavedDocumentsCount, setTotalSavedDocumentsCount] = useState<number>(0);

    // Logic
    useEffect(() => {
        // get saved filters in local storage
        const savedFilters: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[] =
            SavedFiltersHelperSingleton.getDocumentsFilters(LinkStatusEnum.NotLinked);
        
        (async () => {
            await refreshDocumentsAsync(
                undefined,
                savedFilters,
                SortTypeEnum.Newest
            );
        })();

        // log 
        LogHelperSingleton.log("DisplayDocuments");
    }, []);

    const refreshDocumentsAsync = async (fromDate: Date | undefined,
                filterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[], sortType: SortTypeEnum): Promise<void> => {
        const newDocuments: TDocumentsDTO = await SavedDocumentControllerSingleton.getAsync(
            sortType === SortTypeEnum.Oldest ? OrderByEnum.Ascending : OrderByEnum.Descending,
            fromDate,
            DocumentTypeHelperSingleton.getSelectedFilterOptions(filterOptions)
        );

        if (newDocuments && newDocuments.documents) {
            setSavedDocuments(newDocuments.documents);
            setTotalSavedDocumentsCount(newDocuments.totalCount);
        } else {
            setSavedDocuments([]);
            setTotalSavedDocumentsCount(0);
        }
    };

    // Render
    return (
        <div className={styles.documentsContainer}>
            <SavedDocuments 
                documents={savedDocuments}
                doUseSavedFilters={true}
                totalDocumentsCount={totalSavedDocumentsCount}
                refreshDocumentsAsync={refreshDocumentsAsync} 
            />
        </div>
    );
};