// node_modules
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
// Components
import { FindestTextBox, Popover, SynonymsHelperPopup } from "Components";
import { Synonym } from "./Synonym";
// Types
import { TSynonymDTO } from "Types";
// Style
import styles from "./synonym.module.scss";
// Enums
import { SynonymTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Controllers
import { SynonymControllerSingleton } from "Controllers";
// Interfaces
import { IQueryDTO } from "Interfaces";

type TSynonymsProps = {
    synonyms: TSynonymDTO[],
    synonymsType: SynonymTypeEnum,
    fieldId: number,
    synonymsHelperValue: string,
    setSynonymsHelperValue: Dispatch<SetStateAction<string>>,
    synonymsHelperId: number,
    setSynonymsHelperId: Dispatch<SetStateAction<number>>,
    clickedFieldElement: EventTarget | null,
    query: IQueryDTO,
    setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>,
    doOpenSynonymsHelperPopup: boolean,
    referenceElement: HTMLDivElement | null,
    showSynonymsPopover: boolean,
    onMouseEnter: () => void,
    onMouseLeave: () => void,
    handleCloseSynonymsModal: () => void
}

export const Synonyms: FC<TSynonymsProps> = ({ referenceElement, synonyms, synonymsType, fieldId, synonymsHelperValue, setSynonymsHelperValue,
    synonymsHelperId, setSynonymsHelperId, query, setQuery, clickedFieldElement, doOpenSynonymsHelperPopup, showSynonymsPopover, onMouseEnter, onMouseLeave,
    handleCloseSynonymsModal
}: TSynonymsProps) => {
    // State
    const [isSynonymsHelperPopupOpen, setIsSynonymsHelperPopupOpen] = useState<boolean>(false);

    // Logic
    const openSynonymsHelperPopup = useCallback((clickedElement: EventTarget | null): void => {
        // safety-checks
        if (!clickedElement) {
            return;
        }

        // open synonyms helper popup
        setIsSynonymsHelperPopupOpen(true);
        // close synonyms popover
        onMouseLeave();
    }, [onMouseLeave]);


    const closeSynonymsHelperPopup = useCallback((): void => {
        // close synonyms helper modal
        setIsSynonymsHelperPopupOpen(false);
        handleCloseSynonymsModal();
    }, [handleCloseSynonymsModal]);

    // when clickedFieldElement or doOpenSynonymsHelperPopup change
    useEffect(() => {
        // if doOpenSynonymsHelperPopup is true
        if (doOpenSynonymsHelperPopup) {
            // open synonyms helper popup
            openSynonymsHelperPopup(clickedFieldElement);
        }
    }, [clickedFieldElement, doOpenSynonymsHelperPopup, openSynonymsHelperPopup]);

    const updateFieldSynonyms = (synoymsParam: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number): void => {
        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }

            if (currentSynonymsType === SynonymTypeEnum.Action || currentSynonymsType === SynonymTypeEnum.Object) {
                // iterate through action objects
                for (const actionObject of prevQuery.actionObjects) {
                    // if field id is the same as the current action object id
                    if (actionObject.id === currentFieldId) {
                        // update synonyms
                        if (currentSynonymsType === SynonymTypeEnum.Action) {
                            actionObject.actionSynonyms = [...synoymsParam];
                        } else if (currentSynonymsType === SynonymTypeEnum.Object) {
                            actionObject.objectSynonyms = [...synoymsParam];
                        }
                        break;
                    }
                }
            } else if (currentSynonymsType === SynonymTypeEnum.Environment) {
                // iterate through environment variables
                for (const environmentVariable of prevQuery.environmentVariables) {
                    // if field id is the same as the current environment variable id
                    if (environmentVariable.id === currentFieldId) {
                        // update synonyms
                        environmentVariable.synonyms = [...synoymsParam];
                        break;
                    }
                }
            }

            return {
                ...prevQuery,
                actionObjects: [...prevQuery.actionObjects],
                environmentVariables: [...prevQuery.environmentVariables]
            };
        });
    };

    const isSynonymValueValid = (synonymValue: string, currentSynonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum): boolean => {
        // trim synonymValue
        synonymValue = synonymValue.trim();
        // split synonymValue
        const splitSynonymValue = synonymValue.split(" ");

        // safety-checks
        if (!synonymValue || splitSynonymValue.length <= 0) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "A synonym can not be empty.");
            return false;
        } else if (currentSynonymsType === SynonymTypeEnum.Action && splitSynonymValue.length > 1) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "An action synonym can not have more than one word.");
            return false;
        }

        // check if current synonyms list already has a synonym with this value
        const duplicatedSynonym = currentSynonyms.find((synonym: TSynonymDTO) => {
            return synonym.text === synonymValue;
        });

        // if synonym already exists, show error
        if (duplicatedSynonym) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Synonym already exists.");
            return false;
        }

        // return true
        return true;
    };

    const addSynyonymAsync = async (currentQueryId: string, synonymValue: string, currentSynonymsType: SynonymTypeEnum, currentFieldId: number,
        currentSynonyms: TSynonymDTO[],
        updateFieldSynonymsParam: (synonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number) => void): Promise<void> => {
        // create synonym dto
        const synonymToCreateDTO: TSynonymDTO = {
            id: 0,
            text: synonymValue,
            synonymType: currentSynonymsType,
            required: true
        };

        // create synonym
        const createdSynonym: TSynonymDTO | undefined = await SynonymControllerSingleton
            .createAsync(currentQueryId, currentFieldId, synonymToCreateDTO);

        // safety-checks
        if (!createdSynonym) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not create synonym.");
            return;
        }

        // build new synonyms list
        const newSynonymsList = [...currentSynonyms, createdSynonym];

        // update synonyms list
        updateFieldSynonymsParam(newSynonymsList, currentSynonymsType, currentFieldId);
    };

    const updateSynonymValueAsync = async (currentQueryId: string, synonymId: number, updatedSynonymValue: string,
        currentFieldId: number,
        currentSynonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum,
        currentIsSynonymValueValid: (synonymValue: string, currentSynonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum) => boolean,
        updateFieldSynonymsParam: (synonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number) => void): Promise<void> => {
        // get related synonym from current synonyms list
        const relatedSynonymToUpdate = currentSynonyms.find((synonym: TSynonymDTO) => synonym.id === synonymId);

        // safety-checks
        if (!relatedSynonymToUpdate) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find synonym to update.");
            return;
        }

        // safety-checks
        if (!currentIsSynonymValueValid(updatedSynonymValue, currentSynonyms, currentSynonymsType)) {
            return;
        }

        // trim updatedSynonymValue
        updatedSynonymValue = updatedSynonymValue.trim();

        // update synyonym text
        relatedSynonymToUpdate.text = updatedSynonymValue;

        // update synonym
        const updatedSynonym: TSynonymDTO | undefined = await SynonymControllerSingleton
            .updateAsync(currentQueryId, currentFieldId, relatedSynonymToUpdate);

        // safety-checks
        if (!updatedSynonym) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not update synonym.");
            return;
        }

        // update the synonym in the synonyms list
        const updatedSynonyms = currentSynonyms.map((synonym: TSynonymDTO) => {
            if (synonym.id === updatedSynonym.id) {
                return updatedSynonym;
            } else {
                return synonym;
            }
        });

        // update synonyms list
        updateFieldSynonymsParam(updatedSynonyms, currentSynonymsType, currentFieldId);
    };

    const updateSynonymRequiredAsync = async (currentQueryId: string, synonymId: number, updatedSynonymRequired: boolean,
        currentFieldId: number,
        currentSynonymsType: SynonymTypeEnum,
        currentSynonyms: TSynonymDTO[],
        updateFieldSynonymsParam: (synonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number) => void): Promise<void> => {
        // get related synonym from current synonyms list
        const relatedSynonymToUpdate = currentSynonyms.find((synonym: TSynonymDTO) => synonym.id === synonymId);

        // safety-checks
        if (!relatedSynonymToUpdate) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find synonym to update.");
            return;
        }

        // update synyonym required
        relatedSynonymToUpdate.required = updatedSynonymRequired;

        // update synonym
        const updatedSynonym: TSynonymDTO | undefined = await SynonymControllerSingleton
            .updateAsync(currentQueryId, currentFieldId, relatedSynonymToUpdate);

        // safety-checks
        if (!updatedSynonym) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not update synonym.");
            return;
        }

        // update the synonym in the synonyms list
        const updatedSynonyms = currentSynonyms.map((synonym: TSynonymDTO) => {
            if (synonym.id === updatedSynonym.id) {
                return updatedSynonym;
            } else {
                return synonym;
            }
        });

        // update synonyms list
        updateFieldSynonymsParam(updatedSynonyms, currentSynonymsType, currentFieldId);
    };

    const deleteSynonymAsync = async (currentQueryId: string, synonymToDelete: TSynonymDTO, currentSynonyms: TSynonymDTO[], currentFieldId: number,
        updateFieldSynonymsParam: (synonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number) => void): Promise<void> => {
        // get related synonym from current synonyms list
        const relatedSynonymToDelete = currentSynonyms.find((synonym: TSynonymDTO) => synonym.id === synonymToDelete.id);

        // safety-checks
        if (!relatedSynonymToDelete) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find synonym to delete.");
            return;
        }

        // delete synyonym
        const isSuccess: boolean = await SynonymControllerSingleton
            .deleteAsync(currentQueryId, currentFieldId, relatedSynonymToDelete.id);

        // safety-checks
        if (!isSuccess) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not delete synonym.");
            return;
        }

        // build new synonyms list
        const newSynonymsList = currentSynonyms.filter((synonym: TSynonymDTO) => synonym.id !== synonymToDelete.id);

        // update synonyms list
        updateFieldSynonymsParam(newSynonymsList, synonymToDelete.synonymType, currentFieldId);
    };

    const onEnterAsync = async (currentQueryId: string, text: string, inputRef: HTMLInputElement, currentFieldId: number, currentSynonymsType: SynonymTypeEnum,
        currentSynonyms: TSynonymDTO[], currentIsSynonymValueValid: (synonymValue: string, currentSynonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum) => boolean,
        updateFieldSynonymsParam: (synonyms: TSynonymDTO[], currentSynonymsType: SynonymTypeEnum, currentFieldId: number) => void): Promise<void> => {
        // safety-checks
        if (!currentIsSynonymValueValid(text, currentSynonyms, currentSynonymsType)) {
            return;
        }

        // add synonym
        await addSynyonymAsync(currentQueryId, text, currentSynonymsType, currentFieldId, currentSynonyms, updateFieldSynonymsParam);

        // empty add synonym input
        inputRef.value = "";
    };

    return (
        <>
            {isSynonymsHelperPopupOpen ?
                <SynonymsHelperPopup
                    queryId={query.guid}
                    value={synonymsHelperValue}
                    fieldId={fieldId}
                    synonymId={synonymsHelperId}
                    synonymsType={synonymsType}
                    closeSynonymsHelperPopup={closeSynonymsHelperPopup}
                    addSynonymAsync={async (fieldIdParam: number, synonymsTypeParam: SynonymTypeEnum, newSynonymValue: string) => { if (isSynonymValueValid(newSynonymValue, synonyms, synonymsTypeParam)) { addSynyonymAsync(query.guid, newSynonymValue, synonymsTypeParam, fieldIdParam, synonyms, updateFieldSynonyms); } }}
                >
                    <>
                     <FindestTextBox
                            extraClassName={styles.addSynonymTextBox}
                            isBorderless
                            placeholder={"+ synonym"}
                            onEnter={async (text: string, inputRef: HTMLInputElement) => { await onEnterAsync(query.guid, text, inputRef, fieldId, synonymsType, synonyms, isSynonymValueValid, updateFieldSynonyms); }}
                        />
                        {synonyms.map((synonym: TSynonymDTO) => {
                            return (
                                <Synonym
                                    onShowSynonymsClick={async (synonymValue: string) => { setSynonymsHelperValue(synonymValue); setSynonymsHelperId(synonym.id);}}
                                    key={synonym.id}
                                    synonym={synonym}
                                    updateSynonymRequiredAsync={async (synonymId: number, updatedSynonymRequired: boolean) => { updateSynonymRequiredAsync(query.guid, synonymId, updatedSynonymRequired, fieldId, synonymsType, synonyms, updateFieldSynonyms); }}
                                    deleteSynonymAsync={async (synonymToDelete: TSynonymDTO) => { deleteSynonymAsync(query.guid, synonymToDelete, synonyms, fieldId, updateFieldSynonyms); }}
                                    updateSynonymValueAsync={async (synonymId: number, updatedSynonymValue: string) => { await updateSynonymValueAsync(query.guid, synonymId, updatedSynonymValue, fieldId, synonyms, synonymsType, isSynonymValueValid, updateFieldSynonyms); setSynonymsHelperId(synonym.id); setSynonymsHelperValue(updatedSynonymValue); }}
                                />
                            );
                        })}
                    </>
                </SynonymsHelperPopup>
                :
                null
            }
            <Popover
                isOpen={showSynonymsPopover}
                referenceEl={referenceElement}
                extraClassName={styles.synonymsPopover}
                placement="bottom-end"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                popoverOffset={0}
            >
                <div className={styles.title}>
                    Synonyms
                </div>
                <div className={styles.synonymsList}>
                    {synonyms.map((synonym: TSynonymDTO) => {
                        return (
                            <Synonym
                                onShowSynonymsClick={async (synonymValue: string) => { setSynonymsHelperValue(synonymValue); setSynonymsHelperId(synonym.id);}}
                                key={synonym.id}
                                synonym={synonym}
                                updateSynonymRequiredAsync={async (synonymId: number, updatedSynonymRequired: boolean) => { updateSynonymRequiredAsync(query.guid, synonymId, updatedSynonymRequired, fieldId, synonymsType, synonyms, updateFieldSynonyms); }}
                                deleteSynonymAsync={async (synonymToDelete: TSynonymDTO) => { deleteSynonymAsync(query.guid, synonymToDelete, synonyms, fieldId, updateFieldSynonyms); }}
                                updateSynonymValueAsync={async (synonymId: number, updatedSynonymValue: string) => { await updateSynonymValueAsync(query.guid, synonymId, updatedSynonymValue, fieldId, synonyms, synonymsType, isSynonymValueValid, updateFieldSynonyms); setSynonymsHelperId(synonym.id); setSynonymsHelperValue(updatedSynonymValue); }}
                            />
                        );
                    })}
                </div>
            </Popover>
        </>
    );
};