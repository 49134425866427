// React
import { FC } from "react";
// Providers
import { QueryProvider } from "Providers";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Components
import { QueryDetailsLoader } from "./QueryDetailsLoader";

type TQueryDetailsProvidedLoaderProps = {
    queryId: string,
    onDelete: () => void,
    onDuplicateAsync: (duplicateQuery: IQueryDTO) => Promise<void>
};

export const QueryDetailsProvidedLoader: FC<TQueryDetailsProvidedLoaderProps> = ({ queryId, onDelete, onDuplicateAsync }) => {

    return (
        <QueryProvider>
            <QueryDetailsLoader 
                queryId={queryId}
                onDelete={onDelete} 
                onDuplicateAsync={onDuplicateAsync} />
        </QueryProvider>
    );
};