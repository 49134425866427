// React
import { Dispatch, SetStateAction, useCallback } from "react";
// Interfaces
import { IEntityDTO } from "Interfaces";
// Hooks
import { useObjectNameChangeListener } from "./useObjectNameChangeListener";

export const useEntityNameChangeListener = (
    setEntities?: Dispatch<SetStateAction<IEntityDTO[]>>,
    setEntity?: Dispatch<SetStateAction<IEntityDTO | undefined>>,
) => {
    // Handlers
    const onObjectNameChange = useCallback((objectId: string, name: string) => {
        if (setEntities) {
            setEntities((prevEntities) => {
                return prevEntities.map(entity => {
                    if (entity.id === objectId) {
                        return {
                            ...entity,
                            title: name
                        };
                    }
                    return entity;
                });
            });
        }

        if (setEntity) {
            setEntity((prevEntity) => {
                // safety-checks
                if (!prevEntity || prevEntity.id !== objectId) {
                    return prevEntity;
                }
                return {
                    ...prevEntity,
                    title: name
                };
            });
        }
    }, [setEntities, setEntity]);

    useObjectNameChangeListener(onObjectNameChange);
};
