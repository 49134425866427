// React
import { LogHelperSingleton } from "Helpers";
import { useMemo, useState } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";

export const useCheckboxedList = <T>(itemLength: number, singleLogName: string, pluralLogName: string, 
        mapFunction: (item: T) => TIdNameTypeObjectType) => {
    const [selectedItems, setSelectedItems] = useState<TIdNameTypeObjectType[]>([]);

    const onSelectAllItems = (isChecked: boolean, items: T[]): void => {
        let newSelectedItems: TIdNameTypeObjectType[] = [];
        if (isChecked) {
            newSelectedItems = items.map(mapFunction);
            // log
            LogHelperSingleton.log(`SelectAll${pluralLogName}`);
        } else {
            // log
            LogHelperSingleton.log(`UnselectAll${pluralLogName}`);
        }
        setSelectedItems(newSelectedItems);
    };

    const onSelectItem = (isChecked: boolean, currentItem: T, id: string): void => {
        let newSelectedItems = [...selectedItems];

        if (isChecked) {
            newSelectedItems.push(mapFunction(currentItem));
            // log
            LogHelperSingleton.log(`Select${singleLogName}`);
        } else {
            newSelectedItems = newSelectedItems.filter(selectedItem => selectedItem.id !== id);
            // log
            LogHelperSingleton.log(`Unselect${singleLogName}`);
        }
        setSelectedItems(newSelectedItems);
    };

    // Const
    const areAllItemsSelected = useMemo(() => {
        return selectedItems.length > 0 && selectedItems.length === itemLength;
    }, [selectedItems, itemLength]);
    const isAnyItemSelected = useMemo(() => {
        return selectedItems.length > 0 && selectedItems.length !== itemLength;
    }, [selectedItems, itemLength]);

    return { selectedItems, setSelectedItems, areAllItemsSelected, isAnyItemSelected,
        onSelectAllItems, onSelectItem };
};