// React
import { FC, useContext, useEffect } from "react";
// Components
import { QueryDetailsPage } from "./QueryDetailsPage";
// Context
import { QueryContext } from "Providers";
// Controllers
import { QueryControllerSingleton } from "Controllers";
// Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Interfaces
import { IQueryDTO } from "Interfaces";

type TQueryDetailsLoaderProps = {
    queryId: string,
    onDelete: () => void,
    onDuplicateAsync: (duplicateQuery: IQueryDTO) => Promise<void>
};

export const QueryDetailsLoader: FC<TQueryDetailsLoaderProps> = ({ queryId, onDelete, onDuplicateAsync }) => {
    // Context
    const { setQuery } = useContext(QueryContext);

    useEffect(() => {
        (async () => {
            const savedQuery = await QueryControllerSingleton.getByIdAsync(queryId);
            if(!savedQuery) {
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not retrieve query");
                return;
            }
            setQuery(savedQuery);
        })();
    }, [queryId, setQuery]);

    return (
        <QueryDetailsPage 
            showGoBackButton={false}
            onDelete={onDelete} 
            onDuplicateAsync={onDuplicateAsync} />
    );
};