// node_modules
import MarkdownIt from "markdown-it";
import StateBlock from "markdown-it/lib/rules_block/state_block";
// Extensions
import { commonCustomBlockExtension } from "./CommonCustomBlockExtension";
// Enums
import { CustomBlockIdAttributeEnum, CustomBlockMarkerEnum, CustomDOMTag, CustomMarkdownSeparatorEnum, OtherMarkdownCustomBlockNameEnum, SpecialBlockClassNameEnum, TopDepthMarkdownCustomBlockNameEnum } from "Enums";
import { MarkdownItHelperSingleton } from "Helpers";

export function imageReferenceBlockExtension(md: MarkdownIt): void {
    const parseImageReferenceBlockContent = (state: StateBlock, startLine: number, content: string, closeImageReferenceBlockMarker: string, imageId?: string): boolean => {
        // safety-checks
        if (!imageId) {
            return false;
        }
        
        // Create a variable to store the next line index
        const nextLine = startLine;

        // Remove the image id from the full content
        content = content.slice(38);

        // Check if the current line contains the end marker
        let haveEndMarker = false;
        const endMarkerIndex = content.indexOf(closeImageReferenceBlockMarker);
        if(endMarkerIndex >= 0) {
            // If the end marker was found then remove it from the full content
            // and set the haveEndMarker flag to true
            content = content.slice(0, endMarkerIndex);
            haveEndMarker = true;
        }

        // Update the line of the state with the last line we checked and depending
        // on if we found the end marker add one to the line index
        state.line = nextLine + (haveEndMarker ? 1 : 0);

        // If the end marker was found then add it to the tokens in the state
        if(haveEndMarker) {
            // try to get image url from content
            const curlyBracketIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_IMAGE_URL);
            let imageUrl: string | undefined = undefined;
            // if curly bracket was found
            if (curlyBracketIndex >= 0) {
                // try to get the image url
                imageUrl = content.slice(curlyBracketIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_IMAGE_URL, curlyBracketIndex + 1));
            }

            // try to get reference url from content
            const lessThanIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_URL);
            let referenceUrl: string | undefined = undefined;
            // if curly bracket was found
            if (curlyBracketIndex >= 0) {
                // try to get the reference url
                referenceUrl = content.slice(lessThanIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_URL, lessThanIndex + 1));
            }

            // try to get caption from content
            const captionSpecialCharsIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_IMAGE_CAPTION);
            let caption = "";
            // if curly bracket was found
            if (captionSpecialCharsIndex >= 0) {
                // try to get the caption
                caption = content.slice(captionSpecialCharsIndex + 2, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_IMAGE_CAPTION, captionSpecialCharsIndex + 2));
            }
            
            // if image url was not found
            if (!imageUrl) {
                // return false
                return false;
            }

            // clean image reference caption
            caption = MarkdownItHelperSingleton
                .cleanImageReferenceCaption(caption, imageUrl);

            /// Add a token to indicate the start of the image reference block
            const startToken = state.push(`${TopDepthMarkdownCustomBlockNameEnum.ImageReference}_open`, "figure", 1);
            startToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.ImageReference}`],
                [`${CustomBlockIdAttributeEnum.ImageReference}`, imageId],
                ["selected", "false"],
                ["id", `${crypto.randomUUID()}`]
            ];

            // Increase the depth level of the state
            state.level++;

            // Add a token to indicate the start of the img
            const imgToken = state.push(`${OtherMarkdownCustomBlockNameEnum.ImageReferenceImage}_open`, "img", 1);
            imgToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.ImageReferenceImg}`],
                ["src", imageUrl], 
                ["alt", caption]
            ];

            // Add a token to indicate the end of the img
            state.push(`${OtherMarkdownCustomBlockNameEnum.ImageReferenceImage}_close`, "img", -1);
            // Add a token to indicate the start of the figcaption
            const figCaptionToken = state.push(`${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaption}_open`, "figcaption", 1);
            figCaptionToken.attrs = [
                ["class", caption.length === 0 ? `${SpecialBlockClassNameEnum.ImageReferenceFigcaption} empty-caption` : `${SpecialBlockClassNameEnum.ImageReferenceFigcaption}`]
            ];

            // Increase the depth level of the state
            state.level++;

            // add ImageReferenceFigcaptionTextContainer token
            state.push(`${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionTextContainer}_open`, `${CustomDOMTag.ImageReferenceFigcaptionTextContainer}`, 1);

            // Increase the depth level of the state
            state.level++;

            // add ImageReferenceFigcaptionText token
            const imageReferenceFigcaptionTextToken = state.push(`${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionText}_open`, `${CustomDOMTag.ImageReferenceFigcaptionText}`, 1);
            imageReferenceFigcaptionTextToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.ImageReferenceFigcaptionText}`]
            ];

            // Add the content of the image reference block
            const textToken = state.push("inline", "", 0);
            textToken.content = caption;
            textToken.map = [startLine, state.line];
            textToken.children = [];

            state.push(`${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionText}_close`, `${CustomDOMTag.ImageReferenceFigcaptionText}`, -1);

            // Decrease the depth level of the state
            state.level--;

            state.push(`${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionTextContainer}_close`, `${CustomDOMTag.ImageReferenceFigcaptionTextContainer}`, -1);

            // reference url may be empty
            if (referenceUrl) {
                // Add a token to indicate the start of the reference link
                const linkToken = state.push(`${OtherMarkdownCustomBlockNameEnum.ReferenceLink}_open`, `${CustomDOMTag.ReferenceLink}`, 1);
                linkToken.attrs = [
                    ["href", referenceUrl], 
                    ["target", "_blank"],
                    ["rel", "noopener noreferrer"],
                    ["class", `${SpecialBlockClassNameEnum.ReferenceLink}`]
                ];
                
                // Add [Ref] as the text of the link
                const linkTextToken = state.push("inline", "", 0);
                linkTextToken.content = "[Ref]";
                linkTextToken.map = [startLine, state.line];
                linkTextToken.children = [];

                // Add a token to indicate the end of the image link
                state.push(`${OtherMarkdownCustomBlockNameEnum.ReferenceLink}_close`, `${CustomDOMTag.ReferenceLink}`, -1);
            }
            
            // Decrease the depth level of the state
            state.level--;

            // Add a token to indicate the end of the figcaption
            state.push(`${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaption}_close`, "figcaption", -1);


            // Decrease the depth level of the state
            state.level--;
            
            // Add a token to indicate the end of the image reference block
            state.push(`${TopDepthMarkdownCustomBlockNameEnum.ImageReference}_close`, "figure", -1);
        }

        return haveEndMarker;
    };

    
    // execute the image reference block function after the highlight reference function
    md.block.ruler.after(
        `${TopDepthMarkdownCustomBlockNameEnum.HighlightReference}`, 
        `${TopDepthMarkdownCustomBlockNameEnum.ImageReference}`, 
        (state: StateBlock, startLine: number) => commonCustomBlockExtension(state, startLine, `${CustomBlockMarkerEnum.ImageReference}`, parseImageReferenceBlockContent)
    );
}