// Node modules
import { FC, RefObject, useState } from "react";
import { Placement } from "@floating-ui/react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
// Styles
import styles from "./windowBubble.module.scss";
// Components
import { Tooltip } from "Components";

type TWindowBubbleProps = {
    icon: IconDefinition,
    windowCount?: number,
    setSidebarElementReference?: (element: HTMLDivElement | null) => void,
    onClick: () => void,
    isActive?: boolean,
    tooltipText: string,
    bubbleRef?: RefObject<HTMLDivElement>,
    tooltipPlacement?: Placement,
    extraClassNames?: { container?: string }
};

export const WindowBubble: FC<TWindowBubbleProps> = ({ icon, windowCount, isActive,
    setSidebarElementReference, onClick, tooltipText, bubbleRef, tooltipPlacement, extraClassNames = {}
}) => {

    // State
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

    const onMouseEnterHandler = (): void => {
        // Check if the popover is shown otherwise don't show the tooltip onHover
        if (!isActive) {
            setIsTooltipOpen(true);
        }
        setSidebarElementReference && bubbleRef && setSidebarElementReference(bubbleRef.current);
    };

    // OnClick will show the popover and disables the pinned tooltip when onHover
    const onClickHandler = (): void => {
        onClick();
        setIsTooltipOpen(false);
    };

    return (
        <div
            ref={bubbleRef}
            className={`${extraClassNames?.container ?? ""} ${windowCount !== undefined && windowCount === 0 ? styles.disabled : ""} ${styles.windowBubble} ${isActive ? styles.active : ""}`}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={() => setIsTooltipOpen(false)}
        >
            <div ref={setReferenceElement} className={styles.iconBubble} onClick={onClickHandler}>
                <FontAwesomeIcon icon={icon} />
            </div>
            {windowCount !== undefined && <div className={styles.windowCount}>{windowCount}</div>}
            <Tooltip
                referenceEl={referenceElement}
                isOpen={isTooltipOpen}
                tooltipText={tooltipText}
                placement={tooltipPlacement ?? undefined}
            />
        </div>
    );
};
