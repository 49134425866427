// React
import { Dispatch, SetStateAction, useCallback, useContext, useEffect } from "react";
// Providers 
import { PubSubContext } from "Providers";
// Enums
import { ObjectTypeEnum, PubSubEventTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";

export const useObjectTypeChangeListener = (onSingleTypeChange?: (objectId: string, newType: ObjectTypeEnum) => void,
        onObjectListTypeChange? : Dispatch<SetStateAction<TIdNameTypeObjectType[]>>) => {

    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);

    // Handlers
    const onObjectTypeChange = useCallback((objectId: string, newType: ObjectTypeEnum): void => {
        if(onSingleTypeChange) {
            onSingleTypeChange(objectId, newType);
        }

        if(onObjectListTypeChange) {
            onObjectListTypeChange((oldObjectList: TIdNameTypeObjectType[]) => {
                return oldObjectList.map((object: TIdNameTypeObjectType) => {
                    if (object.id === objectId) {
                        object.objectType = newType;
                    }
                    return object;
                });
            });
        }
    }, [onObjectListTypeChange, onSingleTypeChange]);

    useEffect(() => {
        pubSubHandler.subscribeToEvent(PubSubEventTypeEnum.TypeChange, onObjectTypeChange);

        return () => {
            pubSubHandler.unsubscribeFromEvent(PubSubEventTypeEnum.TypeChange, onObjectTypeChange);
        };
    }, [onObjectTypeChange, pubSubHandler]);
};
