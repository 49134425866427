// node_modules
import { FC } from "react";
// Types
import { TImageDTO } from "Types";
// Components
import { Image, Reference, Modal } from "Components";
// Styles
import styles from "./imageModal.module.scss";

type TImageModalProps = {
    image: TImageDTO,
    isOpen: boolean,
    onClose: () => void
}

export const ImageModal: FC<TImageModalProps> = ({ image, isOpen, onClose }: TImageModalProps) => {
    return (
        <Modal isOpen={isOpen} extraClassNames={{ container: styles.imageModal }} onClose={onClose}>
            <div className={styles.body}>
                <div className={styles.entityLikeImageAspectRatioBox}>
                    <div className={styles.entityLikeImageAspectRatioBoxContent}>
                        <Image
                            image={image}
                            extraClassName={styles.image}
                        />
                    </div>
                </div>
                <div className={styles.information}>
                    {image.caption && <h5>{image.caption}</h5>}
                    {image.reference && <Reference referenceUrl={image.reference} />}
                </div>
            </div>
        </Modal>
    );
};