export enum AskAIAssistantMenuItemEnum {
    QuestionAndAnswer = "QuestionAndAnswer",
    GeneralDescription = "GeneralDescription",
    GeneralDescriptionUsingLinks = "GeneralDescriptionUsingLinks",
    GeneralDescriptionUsingGeneralKnowledge = "GeneralDescriptionUsingGeneralKnowledge",
    ExecutiveSummary = "ExecutiveSummary",
    RequirementsSummary = "RequirementsSummary",
    WriteSection = "WriteSection",
    Table = "Table",
    GenerateReport = "GenerateReport"
}