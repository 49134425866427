//node_modules
import { FC, useContext, useState } from "react";
// Types
import { TCommentDTO, THighlightDTO } from "Types";
// Components
import { MarkdownItComponent, Reference, SaveableEditableMarkdown } from "Components";
import { Modal } from "../Modal";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Contexts
import { AuthContext } from "Providers";
// Controllers
import { CommentControllerSingleton } from "Controllers";
// Styles
import styles from "./createAnnotationModal.module.scss";

type TCreateAnnotationModalProps = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    highlight: THighlightDTO
}

export const CreateAnnotationModal: FC<TCreateAnnotationModalProps> = ({ isOpen, setIsOpen, highlight }: TCreateAnnotationModalProps) => {
    // State
    const [isEditing, setIsEditing] = useState(true);

    // Contexts
    const { auth } = useContext(AuthContext);

    // Logic
    const onSaveClickAsync = async (newAnnotation: string): Promise<void> => {
        // safety-checks
        if (!newAnnotation) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Can't add an empty annotation.");
            return;
        }

        // create new comment object (new annotation)
        const newComment = {
            commentOnId: highlight.id,
            commentOnType: ObjectTypeEnum.Highlight,
            text: newAnnotation,
            parentId: undefined,
            replies: undefined,
            username: auth.userEmail
        } as TCommentDTO;

        // create new comment in db
        const createdComment: TCommentDTO | undefined = await CommentControllerSingleton
            .createAsync(newComment);

        // safety-checks
        if (!createdComment) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to add annotation.");
            return;
        }

        // add created comment (annotation) to current highlight
        highlight.comments = [createdComment, ...highlight.comments];
        
        // reset state
        resetModalStateAndClose();

        // log
        LogHelperSingleton.log("CreateComment");
    };
    
    const resetModalStateAndClose = (): void => {
        // reset state
        setIsOpen(false);
        setIsEditing(true);
    };

    // Render
    return (
        <Modal
            isOpen={isOpen}
            onClose={resetModalStateAndClose}
            header="Add annotation"
        >
            <div className={styles.highlightDescriptionContainer}>
                <MarkdownItComponent
                    source={highlight.description} />
                <Reference referenceUrl={highlight.reference} />
            </div>
            <SaveableEditableMarkdown
                source={""}
                noSourcePlaceholder={"Add your annotation"}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onSaveClick={(savedText: string) => onSaveClickAsync(savedText)}
                onCancelClick={resetModalStateAndClose}
                isHighlighted
            />
        </Modal>
    );
};