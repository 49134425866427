// node_modules
import { ChangeEvent, FC } from "react";
// Enums
import { SearchPriorityEnum } from "Enums";
// Styles
import styles from "./selectSearchPriority.module.scss";

type TSelectSearchPriorityProps = {
    searchPriority: SearchPriorityEnum,
    onChange: (newSearchPriority: SearchPriorityEnum) => void,
    isSearchTermPriorityDropdown: boolean
}

export const SelectSearchPriority: FC<TSelectSearchPriorityProps> = ({ searchPriority, onChange, isSearchTermPriorityDropdown }: TSelectSearchPriorityProps) => {

    const handleOnChange = (newSearchPriority: string): void => {
        const typedSearchPriority = parseInt(newSearchPriority, 10) as SearchPriorityEnum;
        onChange(typedSearchPriority);
    };

    const getSelectClassName = (): string => {
        const classNameList = [styles.searchPrioritySelect];

        switch (searchPriority) {
            case SearchPriorityEnum.Must:
                classNameList.push(styles.must);
                break;
            case SearchPriorityEnum.Should:
                classNameList.push(styles.should);
                break;
            case SearchPriorityEnum.Excluded:
                classNameList.push(styles.excluded);
                break;
            default:
                classNameList.push(styles.should);
                break;
        }

        return classNameList.join(" ");
    };

    return (
        <>
            {isSearchTermPriorityDropdown ?
                <select title="Set search priority" className={getSelectClassName()} value={searchPriority} onChange={(event: ChangeEvent<HTMLSelectElement>) => { handleOnChange(event.target.value); }}>
                    <option value={SearchPriorityEnum.Must}>Must Match</option>
                    <option value={SearchPriorityEnum.Should}>Should Match</option>
                    <option value={SearchPriorityEnum.Excluded}>Not Match</option>
                </select>
                :
                <div className={styles.searchPrioritySelectContainer}>
                    <div className={`${styles.searchPrioritySelectButton} ${styles.must} ${searchPriority === SearchPriorityEnum.Must ? "" : styles.inActive}`} title="Must match" onClick={() => onChange(SearchPriorityEnum.Must)}>M</div>
                    <div className={`${styles.searchPrioritySelectButton} ${styles.should} ${searchPriority === SearchPriorityEnum.Should ? "" : styles.inActive}`} title="Should match" onClick={() => onChange(SearchPriorityEnum.Should)}>S</div>
                    <div className={`${styles.searchPrioritySelectButton} ${styles.excluded} ${searchPriority === SearchPriorityEnum.Excluded ? "" : styles.inActive}`} title="Must NOT match" onClick={() => onChange(SearchPriorityEnum.Excluded)}>N</div>
                </div>
            }
        </>
    );
};