import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { TTabIcon } from "Types";

import styles from "./iconnedTabs.module.scss";

export type IconnedTabsProps = {
    tabIcons: TTabIcon[],
    onSelectedTabChange?: (selectedTab: string) => void,
    disabledTabs?: string[]
};

export const IconnedTabs: FC<IconnedTabsProps> = ({ tabIcons, onSelectedTabChange, disabledTabs }: IconnedTabsProps) => {

    // State
    const [selectedTab, setSelectedTab] = useState<string | undefined>(tabIcons.length > 0 ? tabIcons[0].title : undefined);

    const onSelectedTab = (tabTitle: string) => {
        setSelectedTab(tabTitle);
        if(onSelectedTabChange) onSelectedTabChange(tabTitle);
    };

    return (
        <div className={styles.iconnedTabs}>
            {
                tabIcons.map(tabIcon => {
                    return (
                        <div key={tabIcon.title} title={tabIcon.title} onClick={() => onSelectedTab(tabIcon.title)}
                                className={`${styles.tab} ${selectedTab === tabIcon.title ? styles.selected : ""} ${disabledTabs?.indexOf(tabIcon.title) !== undefined && disabledTabs?.indexOf(tabIcon.title) > -1 ? styles.disabled : ""}`}>
                            <FontAwesomeIcon icon={tabIcon.icon} className={styles.tabIcon} />
                        </div>
                    );
                })
            }
        </div>
    );
};