import React, { useContext, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./profileMenu.module.scss";
import { SidebarItem } from "./SidebarItem";
//icons
import {
  faGear,
  faScrewdriverWrench,
  faRightFromBracket,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
//controllers
import { AuthControllerSingleton } from "Controllers";
//helpers
import {
  ExtensionCommunicationHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
//enums
import { ToastTypeEnum } from "Enums";
//providers
import { AuthContext } from "Providers";
//components
import { AccountBox, IsFindestAdministrator, UserIcon } from "Components";
import TenantSelector from "./TenantSelector/TenantSelector";

type ProfileMenuProps = {
  text: string;
  setText: (text: string) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  onItemClick?: () => void;
};

export const ProfileMenu: React.FC<ProfileMenuProps> = ({
  text,
  setText,
  isEditing,
  setIsEditing,
  onItemClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { entityId, studyId } = useParams();
  const { setDefaultAuth, auth } = useContext(AuthContext);

  // Logic
  const onLogoutElementClick = async (): Promise<void> => {
    const isSuccess = await AuthControllerSingleton.logoutAsync();

    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Error while signing out."
      );
    } else {
      await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();
      setDefaultAuth();
      navigate("/");
    }
  };

  const isObjectDetailsPageShown = useMemo((): boolean => {
    // if entityId or studyId is set, then we are on an object details page
    if (entityId || studyId) {
      return true;
    } else {
      return false;
    }
  }, [entityId, studyId]);

  return (
    <div
      className={`${styles.profileMenu} ${
        isObjectDetailsPageShown ? styles.detailsPage : ""
      }`}
    >
      <div className={styles.userDetails}>
        <UserIcon extraClassName={styles.userIcon} email={auth.userEmail} />
        <span title={auth.userEmail} className={styles.username}>
          {auth.userEmail}
        </span>
      </div>
      <IsFindestAdministrator>
        <div className={styles.divider} />
        <h4>Universe</h4>
        <TenantSelector
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          text={text}
          setText={setText}
        />
      </IsFindestAdministrator>

      <div className={styles.divider} />
      <ul className={styles.menuItems}>
        <SidebarItem
          isCollapsed={false}
          activeSidebarItem={location.pathname}
          key="resources"
          extraClassNames={{
            sidebarListItemIcon: styles.icon,
            sidebarRightIconStyle: styles.resourcesIcon,
            sidebarRightIconContainer: styles.resourcesIconContainer,
          }}
          onItemClick={onItemClick}
          sidebarItem={{
            title: "Resources",
            icon: faCircleQuestion,
            navigateTo: "https://docs.findest.com/",
            externalUrl: "https://docs.findest.com/",
            rightContent: faArrowUpRightFromSquare,
          }}
        />
        <IsFindestAdministrator>
          <SidebarItem
            isCollapsed={false}
            activeSidebarItem={location.pathname}
            key="admin"
            extraClassNames={{ sidebarListItemIcon: styles.icon }}
            sidebarItem={{
              title: "Admin",
              icon: faScrewdriverWrench,
              navigateTo: "/admin",
            }}
            onItemClick={onItemClick}
          />
        </IsFindestAdministrator>

        <SidebarItem
          isCollapsed={false}
          activeSidebarItem={location.pathname}
          key="settings"
          extraClassNames={{ sidebarListItemIcon: styles.icon }}
          sidebarItem={{
            title: "Settings",
            icon: faGear,
            navigateTo: "/settings",
          }}
          onItemClick={onItemClick}
        />
        <div className={styles.divider}></div>
        <AccountBox
          text="Logout"
          extraClassname={styles.logout}
          extraClassNames={{ iconContainer: styles.iconContainer }}
          onComponentClick={onLogoutElementClick}
          icon={faRightFromBracket}
          isCollapsed={false}
        />
      </ul>
    </div>
  );
};
