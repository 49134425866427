
export class PendoHelper {
    // init pendo with current user id and tenant user name
    initializePendo(userId, userTenantName) {
        // safety-checks
        if (!userId || userId.length === 0) return;
        // init pendo
        // eslint-disable-next-line no-undef
        pendo.initialize({
            visitor: {
                id: userId
            },
            account: {
                id: userTenantName
            }
        });
    }

    // track event based on name
    trackEvent(eventName) {
        // track
        // eslint-disable-next-line no-undef
        pendo.track(`universe-webapp-${eventName}`);
    }

    // tract event based on name and pass additional properties
    trackEventWithProperties(eventName, properties) {
        // track
        // eslint-disable-next-line no-undef
        pendo.track(`universe-webapp-${eventName}`, properties);
    }
}

export const PendoHelperSingleton = new PendoHelper();