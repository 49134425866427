// Extensions
import StateInline from "markdown-it/lib/rules_inline/state_inline";
import Token from "markdown-it/lib/token";
// Enums
import { CustomDOMAttributes, CustomDOMTag, CustomInlineIdAttributeEnum, CustomInlineMarkerEnum, CustomInlineNameEnum, CustomMarkdownSeparatorEnum, SpecialBlockClassNameEnum } from "Enums";
// Extensions
import { CommonInlineMarkExtension } from "./CommonInlineMarkExtension";

const parseContentBetweenMarkers = (stateInline: StateInline, content: string): boolean => {
    // get last added token 
    const lastToken = stateInline.tokens[stateInline.tokens.length - 1];

    // safety-checks 
    if(!content || !lastToken || lastToken.type !== "inlineReference_open") { 
        return false; 
    }

    // try to get reference id from content
    const bracketIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_INLINE_REFERENCE_ID);
    let referenceId: string | undefined = undefined;
    // if bracket was found
    if (bracketIndex >= 0) {
        // try to get the reference id
        referenceId = content.slice(bracketIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_INLINE_REFERENCE_ID, bracketIndex + 1));
    }
    // remove reference id from content
    content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_INLINE_REFERENCE_ID) + 1);

    // try to get reference type
    const referenceTypeSpecialCharsIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_INLINE_REFERENCE_TYPE);
    let referenceType: string | undefined = undefined;
    // if reference type special chars were found
    if (referenceTypeSpecialCharsIndex >= 0) {
        // try to get the caption
        referenceType = content.slice(referenceTypeSpecialCharsIndex + 2, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_INLINE_REFERENCE_TYPE, referenceTypeSpecialCharsIndex + 2));
    }
    // remove reference type from content
    content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_INLINE_REFERENCE_TYPE) + 2);

    // get reference title (everything that's left)
    const referenceTitle = content;

    // safety-checks
    if(!referenceId || !referenceTitle || !referenceType) {
        return false;
    }

    // add attributes to last token
    lastToken.attrs = [
        ["id", crypto.randomUUID()],
        [`${CustomInlineIdAttributeEnum.InlineReference}`, referenceId],
        ["class", `${SpecialBlockClassNameEnum.InlineReference}`],
        ["selected", "false"],
        [`${CustomDOMAttributes.DataInlineReferenceType}`, referenceType]
    ];

    // add reference title as text token
    const contentToken = stateInline.push("text", "", 0);
    contentToken.content = referenceTitle;
    
    // return true
    return true;
};

const renderStartCommonMark = (tokens: Token[], index: number): string => {
    // get token
    const token = tokens[index];

    // safety-checks
    if(!token || token.type !== `${CustomInlineNameEnum.InlineReference}_open`) {
        return CustomDOMTag.InlineReference;
    }

    // get attributes from "inlineReference_open" token
    const attributes = token.attrs;

    // safety-checks
    if(!attributes) {
        return CustomDOMTag.InlineReference;
    }

    // get value of each attribute
    const id: string | undefined = attributes.find((attribute) => attribute[0] === "id")?.[1];
    const referenceId: string | undefined = attributes.find((attribute) => attribute[0] === `${CustomInlineIdAttributeEnum.InlineReference}`)?.[1];
    const referenceType: string | undefined = attributes.find((attribute) => attribute[0] === `${CustomDOMAttributes.DataInlineReferenceType}`)?.[1];

    // safety-checks
    if(!id || !referenceId || !referenceType) {
        return CustomDOMTag.InlineReference;
    }

    // return custom tag with attributes
    return `<${CustomDOMTag.InlineReference} 
        id="${id}" 
        ${CustomInlineIdAttributeEnum.InlineReference}="${referenceId}" 
        ${CustomDOMAttributes.DataInlineReferenceType}="${referenceType}"
        selected="false"
        class = "${SpecialBlockClassNameEnum.InlineReference}">`;
};

export const inlineReferenceExtension = CommonInlineMarkExtension(CustomInlineNameEnum.InlineReference, CustomInlineMarkerEnum.InlineReference, CustomDOMTag.InlineReference, parseContentBetweenMarkers, renderStartCommonMark);