import { TextHighlightTypeEnum } from "Enums";
import { TTextHighlight } from "Types";

export class TextHighlighterHelper {
    
    private sortHighlights(highlightOne: TTextHighlight, highlightTwo: TTextHighlight) {
        // If higlightOne is opened and highlightTwo is closed, highlightOne should be first
        if (highlightOne.isOpened && !highlightTwo.isOpened) {
            return -1;
        // If higlightOne is closed and highlightTwo is not closed, higlightOne should be first
        } else if (highlightOne.isClosed && !highlightTwo.isClosed) {
            return -1;
        // Otherwise keep the same order
        } else {
            return 0;
        }
    }

    private highlightTypeToStyle(highlightType: TextHighlightTypeEnum, styles: { [key: string]: string }) {
        // Return the correct classname corresponding to the correct highlight type
        switch (highlightType) {
            case TextHighlightTypeEnum.TechnologyName:
                return styles.highlightedTechnology;
            case TextHighlightTypeEnum.Action:
                return styles.highlightedAction;
            case TextHighlightTypeEnum.Object:
                return styles.highlightedObject;
            default:
                return "";
        }
    }
}

export const TextHighlighterHelperSingleton = new TextHighlighterHelper();