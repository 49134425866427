// node_modules
import { faChevronDown, faChevronUp, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, MouseEvent, useCallback, useContext, useEffect, useState } from "react";
// Types
import { TCommentDTO, THighlightDTO, TIdNameTypeObjectType, TImageDTO, TReferenceDTO } from "Types";
// Interfaces
import { ISavedDocumentDTO, fromISavedDocumentDTO } from "Interfaces";
// Styles
import styles from "./referenceSidebar.module.scss";
// Components
import { AddReferencePopover } from "./AddReferencePopover";
import { CommonReference } from "./CommonReference";
import { DocumentModal } from "Components";
// Enums
import { ObjectTypeEnum, ReferenceClickTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
// Helpers
import { ConnectedObjectsHelperSingleton, ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Controllers
import { LinkingControllerSingleton, SavedDocumentControllerSingleton } from "Controllers";
import { PubSubContext } from "Providers";

type TCommonDocumentReferencesProps = {
    objectIdEdited: string,
    objectTypeEdited: ObjectTypeEnum,
    documentId: string,
    documentType: ObjectTypeEnum,
    documentTitle: string,
    documentUrl?: string,
    references: TReferenceDTO[],
    isConnected?: boolean,
    allReferencesUsed: boolean,
    usedReferenceIds: Set<string>,
    insertDocumentAsReference: (documentUrl: string) => void,
    insertImageAsReference: (reference: TReferenceDTO) => void,
    insertHighlightAsReference: (reference: TReferenceDTO) => void,
    insertHighlightAsText: (text: string) => void,
    onReferenceCommentsUpdated: (referenceId: string, comments: TCommentDTO[]) => void,
    onLinkingIconClick: (isCurrentlyConnected: boolean) => Promise<boolean>,
    refreshDocuments?: () => void
};

export const CommonDocumentReferences: FC<TCommonDocumentReferencesProps> = ({objectIdEdited, objectTypeEdited, documentId, documentType, documentTitle, documentUrl, references, 
            isConnected, allReferencesUsed, usedReferenceIds, insertDocumentAsReference, insertImageAsReference,
            insertHighlightAsReference, insertHighlightAsText, onReferenceCommentsUpdated, refreshDocuments,
            onLinkingIconClick} : TCommonDocumentReferencesProps) => {
    // State
    const [refPopoverReferenceElement, setRefPopoverReferenceElement] = useState<HTMLHeadElement | null>(null);
    const [isAddReferencePopoverShown, setIsAddReferencePopoverShown] = useState<boolean>(false);
    const [isObjectConnected, setIsObjectConnected] = useState<boolean | undefined>(isConnected);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isDocumentModalOpen, setDocumentIsModalOpen] = useState<boolean>(false);
    const [savedDocument, setSavedDocument] = useState<ISavedDocumentDTO | null>(null);

    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);

    // Logic
    useEffect(() => {
        // update state if isConnected prop changes
        setIsObjectConnected(isConnected);
    }, [isConnected]);

    const handleOnReferenceClickAsync = useCallback(async (event: MouseEvent, referenceClickType: ReferenceClickTypeEnum, reference?: TReferenceDTO) => {
        // if object was not connected, connect it
        if(!isObjectConnected) {
            setIsObjectConnected(true);
        }

        // then link document to object edited
        const webRequestStatus: WebRequestStatusEnum = await LinkingControllerSingleton
            .createToAsync(documentId, documentType, objectIdEdited, objectTypeEdited);

        // safety-checks on the result
        if (webRequestStatus !== WebRequestStatusEnum.Success && webRequestStatus !== WebRequestStatusEnum.AlreadyExists) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Failed to link document to object edited.");
        }
        
        // based on the reference click type, call the appropriate function
        switch(referenceClickType) {
            case ReferenceClickTypeEnum.INSERT_DOCUMENT_AS_REFERENCE:
                if(!documentUrl) return;
                insertDocumentAsReference(documentUrl);
                break;
            case ReferenceClickTypeEnum.INSERT_IMAGE_AS_REFERENCE:
                // safety-checks
                if (!reference) { return; }
                insertImageAsReference(reference);
                break;
            case ReferenceClickTypeEnum.INSERT_HIGHLIGHT_AS_REFERENCE:
                // safety-checks
                if (!reference) { return; }
                insertHighlightAsReference(reference);
                break;
            case ReferenceClickTypeEnum.INSERT_HIGHLIGHT_AS_TEXT:
                // safety-checks
                if (!reference) { return; }
                insertHighlightAsText(reference.text);
                if(reference.referenceUrl) insertDocumentAsReference(reference.referenceUrl);
                break;
            default:
                break;
        }
    }, [isObjectConnected, documentId, documentType, objectIdEdited, objectTypeEdited, documentUrl, insertDocumentAsReference, insertImageAsReference, insertHighlightAsReference, insertHighlightAsText]);

    const onCollapseClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleOnLinkingIconClickAsync = useCallback(async (isCurrentlyConnected: boolean): Promise<void> => {
        // call the onLinkingIconClick function
        const isSuccess = await onLinkingIconClick(isCurrentlyConnected);

        if (isSuccess) {
            // update state
            setIsObjectConnected(!isCurrentlyConnected);
        } else {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, `Failed to ${isCurrentlyConnected ? "unlink" : "link"}.`);
        }
    }, [onLinkingIconClick]);

    const openDocumentModal = () => {
        const getSavedDocument = async () => {
            // get saved document
            const fetchedDocument = await SavedDocumentControllerSingleton
                .getByIdAsync(documentId);

            // safety-checks
            if (!fetchedDocument) {
                return;
            }

            // open document modal
            setSavedDocument(fetchedDocument);
            setDocumentIsModalOpen(true);
        };
        getSavedDocument();
    };

    const onSaveElementClickAsync = async (element: TIdNameTypeObjectType, closeSavePopupCallback?: () => void): Promise<void> => {
        // if savedDocument is not set
        if (!savedDocument) {
            // show error message
            ToastHelperSingleton    
                .showToast(
                    ToastTypeEnum.Error,
                    `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(element.objectType).toLowerCase()} to document.`
                );
            // stop execution, return
            return;
        }

        // call close save popup callback if it set
        if (closeSavePopupCallback) closeSavePopupCallback();

        // get document object type from saved document type
        const documentObjectType: ObjectTypeEnum = ObjectTypeHelperSingleton.documentTypeToObjectType(savedDocument.savedDocumentType);
        
        // add object to current document
        await ConnectedObjectsHelperSingleton
            .addObjectToObjectAsync(element, pubSubHandler, savedDocument.id, documentObjectType);
    };

    const updateDocumentHighlights = (highlights: THighlightDTO[]) => {
        if (!savedDocument) return;
        setSavedDocument({
            ...savedDocument,
            highlights
        });
    };

    const onDeleteImage = (image: TImageDTO) => {
        if (!savedDocument) return;
        setSavedDocument({
            ...savedDocument,
            images: savedDocument.images.filter((savedImage) => savedImage.id !== image.id)
        });
    };

    const onAddImage = (newImage: TImageDTO) => {
        if (!savedDocument) return;
        setSavedDocument({
            ...savedDocument,
            images: [...savedDocument.images, newImage]
        });
    };

    return (
        <div
            className={`${styles.documentReferenceContainer} ${allReferencesUsed && usedReferenceIds.size > 0 ? styles.isUsed : ""}`}>
            <div onMouseEnter={() => { setIsAddReferencePopoverShown(true); }} onMouseLeave={() => { setIsAddReferencePopoverShown(false); }} ref={setRefPopoverReferenceElement} className={`${styles.documentReferenceTitle} ${isAddReferencePopoverShown ? styles.hover : ""}`}>
                {documentUrl && isAddReferencePopoverShown && <AddReferencePopover
                    refPopoverReferenceElement={refPopoverReferenceElement}
                    handleOnReferenceClick={handleOnReferenceClickAsync}
                />}
                {isObjectConnected === undefined || isObjectConnected === null ?
                    null
                    :
                    <div className={`${styles.addDocumentReference} ${isObjectConnected ? styles.isLinked : ""}`} 
                        onClick={() => handleOnLinkingIconClickAsync(isObjectConnected)} title={isObjectConnected ? "Unlink" : "Link"}>
                            <FontAwesomeIcon icon={faLink} />
                    </div>
                }
                <p onClick={openDocumentModal} title={documentTitle}>{documentTitle}</p>
                {references.length > 0 && <div onClick={onCollapseClick} className={styles.referenceAction} title={isCollapsed ? "Uncollapse" : "Collapse"}><FontAwesomeIcon icon={!isCollapsed ? faChevronUp : faChevronDown} /></div>}
            </div>
            {savedDocument && (
                <DocumentModal
                    refreshDocuments={refreshDocuments}
                    updateHighlights={updateDocumentHighlights}
                    onDeleteImage={onDeleteImage}
                    onAddImage={onAddImage}
                    isOpen={isDocumentModalOpen}
                    setIsOpen={setDocumentIsModalOpen}
                    document={fromISavedDocumentDTO(savedDocument)}
                    onSaveElementClick={async (element: TIdNameTypeObjectType, closeSavePopupCallback?: () => void) => { await onSaveElementClickAsync(element, closeSavePopupCallback); }}
                />
            )}
            {!isCollapsed && (
                <div className={styles.documentReferenceReferences}>
                    {references.map((reference: TReferenceDTO) => {
                        const isReferenceUsedAlready = usedReferenceIds.has(reference.id);
                        return (
                            <CommonReference 
                                key={reference.id}
                                isReferenceUsedAlready={isReferenceUsedAlready}
                                reference={reference}
                                handleOnReferenceClick={handleOnReferenceClickAsync} 
                                onReferenceCommentsUpdated={onReferenceCommentsUpdated} />
                        );
                    })}
                </div>
            )}
        </div>
    );
};