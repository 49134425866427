/* eslint-disable camelcase */
"use strict";

// constructor

// CHANGED FOLLOWING LINE FROM ORIGINAL CODE TO EXPORT DEFAULT 
// SEE ORIGINAL AT: https://github.com/redbug312/markdown-it-multimd-table/blob/master/lib/dfa.js
export default function DFA() {
    // alphabets are encoded by numbers in 16^N form, presenting its precedence
    this.__highest_alphabet__ = 0x0;
    this.__match_alphabets__ = {};
    // states are union (bitwise OR) of its accepted alphabets
    this.__initial_state__ = 0x0;
    this.__accept_states__ = {};
    // transitions are in the form: {prev_state: {alphabet: next_state}}
    this.__transitions__ = {};
    // actions take two parameters: step (line number), prev_state and alphabet
    this.__actions__ = {};
}

// setters

DFA.prototype.set_highest_alphabet = function(alphabet) {
    this.__highest_alphabet__ = alphabet;
};

DFA.prototype.set_match_alphabets = function(matches) {
    this.__match_alphabets__ = matches;
};

DFA.prototype.set_initial_state = function(initial) {
    this.__initial_state__ = initial;
};

DFA.prototype.set_accept_states = function(accepts) {
    for (let i = 0; i < accepts.length; i++) {
        this.__accept_states__[accepts[i]] = true;
    }
};

DFA.prototype.set_transitions = function(transitions) {
    this.__transitions__ = transitions;
};

DFA.prototype.set_actions = function(actions) {
    this.__actions__ = actions;
};

DFA.prototype.update_transition = function(state, alphabets) {
    this.__transitions__[state] = Object.assign(
        this.__transitions__[state] || Object(), alphabets
    );
};

// methods

DFA.prototype.execute = function(start, end) {
    let state, step, alphabet;
    for (state = this.__initial_state__, step = start; state && step < end; step++) {
        for (alphabet = this.__highest_alphabet__; alphabet > 0x0; alphabet >>= 4) {
            if ((state & alphabet)
                && this.__match_alphabets__[alphabet].call(this, step, state, alphabet)) { break; }
        }

        this.__actions__(step, state, alphabet);

        if (alphabet === 0x0) { break; }
        state = this.__transitions__[state][alphabet] || 0x0;
    }
    return !!this.__accept_states__[state];
};

// CHANGED FOLLOWING LINE FROM ORIGINAL CODE TO COMMENT OUT
// SEE ORIGINAL AT: https://github.com/redbug312/markdown-it-multimd-table/blob/master/lib/dfa.js
// module.exports = DFA;

/* vim: set ts=2 sw=2 et: */