import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

import styles from "./paginator.module.scss";

export type TPaginatorProps = {
    isPaginating: boolean,
    paginationIndicator: string,
    currentPageNumber: number,
    isLastPage: boolean,
    paginateToNextPage: () => void,
    paginateToPreviousPage: () => void
};

export const Paginator: FC<TPaginatorProps> = ({ isPaginating, paginationIndicator, currentPageNumber,
        isLastPage, paginateToNextPage, paginateToPreviousPage }) => {
    return (
        <div className={styles.paginatorContainer} style={{display: "flex"}}>
            <div className={styles.countIndicatorContainer}>
                {paginationIndicator}
            </div>
            <div className={`${(currentPageNumber === 1 || isPaginating) ? styles.disabled : ""} ${styles.arrow}`} onClick={paginateToPreviousPage}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div className={`${(isLastPage || isPaginating) ? styles.disabled : ""} ${styles.arrow}`} onClick={paginateToNextPage}>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
        </div>
    );
};