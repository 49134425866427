// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { TRequirementsTableDTO } from "Types";

export class RequirementsTableController {
    // define resource path
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/requirements-table`;

    public async getAsync(objectType: ObjectTypeEnum, objectId: string): Promise<TRequirementsTableDTO| undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/${objectType}/${objectId}`;

            // get requirements table
            const response = await axios.get<TRequirementsTableDTO>(url);

            // deal with response
            if(response && response.data) {
                // return response data
                return response.data;
            } else {
                // return undefined
                return undefined;
            }
        } catch {
            // return undefined
            return undefined;
        }
    }

    public async createAsync(objectType: ObjectTypeEnum, objectId: string, requirementsTable: TRequirementsTableDTO): Promise<TRequirementsTableDTO | undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/${objectType}/${objectId}`;

            // create requirements table
            const response = await axios.put<TRequirementsTableDTO>(url, requirementsTable);

            // deal with response
            if(response && response.data) {
                // return response data
                return response.data;
            } else {
                // return undefined
                return undefined;
            }
        } catch {
            // return undefined
            return undefined;
        }
    }

    public async extractAsync(objectId: string, objectType: ObjectTypeEnum): Promise<string[]> {
        try {
            // build url
            const url = `${this._resourcePath}/extract/${objectId}/${objectType}`;

            // extract requirements table
            const response = await axios.get<string[]>(url);

            // deal with response
            if(response && response.data) {
                // return response data
                return response.data;
            } else {
                // return []
                return [];
            }
        } catch {
            // return []
            return [];
        }
    }

    public async updateAsync(id: string, requirementsTable: TRequirementsTableDTO): Promise<TRequirementsTableDTO | undefined> {
        try {
            // build url
            const url = `${this._resourcePath}/${id}`;
            
            // update requirements table
            const response = await axios.patch<TRequirementsTableDTO>(url, requirementsTable);

            // deal with response
            if(response && response.data) {
                // return response data
                return response.data;
            } else {
                // return undefined
                return undefined;
            }
        } catch {
            // return undefined
            return undefined;
        }
    }

    public async deleteAsync(id: string): Promise<boolean> {
        try {
            // build url
            const url = `${this._resourcePath}/${id}`;

            // delete requirements table
            const response = await axios.delete<boolean>(url);

            // deal with response
            if(response) {
                // return true
                return true;
            } else {
                // return false
                return false;
            }
        } catch {
            // return false
            return false;
        }
    }
}

export const RequirementsTableControllerSingleton = new RequirementsTableController();