// React
import { Dispatch, SetStateAction, useCallback, useContext, useEffect } from "react";
// Providers 
import { PubSubContext } from "Providers";
// Enums
import { PubSubEventTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";

export const useObjectNameChangeListener = (onSingleNameChange?: (objectId: string, name: string) => void,
        onObjectListNameChange? : Dispatch<SetStateAction<TIdNameTypeObjectType[]>>) => {

    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);

    // Handlers
    const onObjectNameChange = useCallback((objectId: string, name: string): void => {
        if(onSingleNameChange) {
            onSingleNameChange(objectId, name);
        }

        if(onObjectListNameChange) {
            onObjectListNameChange((oldObjectList: TIdNameTypeObjectType[]) => {
                return oldObjectList.map((object: TIdNameTypeObjectType) => {
                    if (object.id === objectId) {
                        object.name = name;
                    }
                    return object;
                });
            });
        }
    }, [onObjectListNameChange, onSingleNameChange]);

    useEffect(() => {
        pubSubHandler.subscribeToEvent(`${PubSubEventTypeEnum.NameChange}`, onObjectNameChange);

        return () => {
            pubSubHandler.unsubscribeFromEvent(`${PubSubEventTypeEnum.NameChange}`, onObjectNameChange);
        };
    }, [onObjectNameChange, pubSubHandler]);
};