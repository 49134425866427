// node_modules
import { createContext, ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
// Controllers
import { CollaborationControllerSingleton } from "Controllers";
// Helpers
import { SharedToHelperSingleton } from "Helpers";
// Constants
import { CollaborationConstants } from "Constants";

// Context type
type TClaimEditOnObjectContext = {
    tryClaimingEditOnObjectAsync: (objectIdEdited: string) => Promise<boolean>,
    stopClaimingEditOnObjectAsync: (objectIdEdited: string) => Promise<void>
};

// default context
const defaultClaimEditOnObjectContext: TClaimEditOnObjectContext = {
    tryClaimingEditOnObjectAsync: async () => false,
    stopClaimingEditOnObjectAsync: async () => { return;}
};

// Provider props type
type TClaimEditOnObjectProviderProps = {
    children?: ReactNode,
    doNoStopClaimingOnCleanup?: boolean
};

// Context
export const ClaimEditOnObjectContext = createContext<TClaimEditOnObjectContext>({ ...defaultClaimEditOnObjectContext });

// Provider
export const ClaimEditOnObjectProvider = ({ children, doNoStopClaimingOnCleanup }: TClaimEditOnObjectProviderProps) => {
    // Ref
    const objectEditedTimerRef = useRef<number | undefined>(undefined);
    const lastObjectIdEditedRef = useRef<string | undefined>(undefined);

    // Hooks
    const location = useLocation();

    const keepClaimingEditOnObjectAsync = async (objectIdEdited: string): Promise<void> => {
        // if lastObjectIdEditedRef is equal to objectIdEdited
        if (lastObjectIdEditedRef.current === objectIdEdited) {
            // keep claiming the edit on the object
            await CollaborationControllerSingleton.putAsync(objectIdEdited);
        }
    };

    const tryClaimingEditOnObjectAsync = useCallback(async (objectIdEdited: string): Promise<boolean> => {
        // if lastObjectIdEditedRef is equal to objectIdEdited
        if (lastObjectIdEditedRef.current === objectIdEdited) {
            // no need to claim the edit on the object, return success
            return true;
        }
        
        // try to claim edit on the object
        const isSuccess: boolean = await CollaborationControllerSingleton
            .postAsync(objectIdEdited);

        // if isSuccess is true
        if (isSuccess) {
            // set lastObjectIdEditedRef to objectIdEdited
            lastObjectIdEditedRef.current = objectIdEdited;
            // add a timer to keep claiming the edit on the object
            objectEditedTimerRef.current = window.setInterval(
                () => keepClaimingEditOnObjectAsync(objectIdEdited), 
               CollaborationConstants.KEEP_RESOURCE_BEING_EDITED_TIMER_DEFAULT_MS_VALUE
            );
        }
        
        // return success result
        return isSuccess;
    }, []);

    const stopClaimingEditOnObjectAsync = useCallback(async (objectIdEdited: string): Promise<void> => {
        // if objectEditedTimerRef is set
        if (objectEditedTimerRef.current) {
            // clear the timer
            window.clearInterval(objectEditedTimerRef.current);
            objectEditedTimerRef.current = undefined;
        }

        // stop claiming the edit on the object
        await CollaborationControllerSingleton.deleteAsync(objectIdEdited);

        // set lastObjectIdEditedRef to undefined
        lastObjectIdEditedRef.current = undefined;
    }, []);

    // when location.pathname changes
    useEffect(() => {
        (async () => {
            // get objectId from URL
            const objectIdFromURL: string | undefined = SharedToHelperSingleton
                .getObjectIdFromURL(location.pathname, ["entities", "studies"]);

            // if lastObjectIdEditedRef is set and
            // objectIdFromURL is not set 
            // or objectIdFromURL is set and lastObjectIdEditedRef is set and they are not equal
            if (lastObjectIdEditedRef.current && (!objectIdFromURL || (objectIdFromURL && objectIdFromURL !== lastObjectIdEditedRef.current))) {
                // stop claiming the edit on the object
                await stopClaimingEditOnObjectAsync(lastObjectIdEditedRef.current);
            }
        })();

        // if doNoStopClaimingOnCleanup is not set
        if (!doNoStopClaimingOnCleanup) {
            // cleanup
            return () => {
                // if lastObjectIdEditedRef is set
                if (lastObjectIdEditedRef.current) {
                    // stop claiming the edit on the object
                    stopClaimingEditOnObjectAsync(lastObjectIdEditedRef.current);
                }
            };
        }
    }, [doNoStopClaimingOnCleanup, location.pathname, stopClaimingEditOnObjectAsync, tryClaimingEditOnObjectAsync]);

    // provider value
    const providerValue = useMemo(() => {
        return { tryClaimingEditOnObjectAsync, stopClaimingEditOnObjectAsync };
    }, [tryClaimingEditOnObjectAsync, stopClaimingEditOnObjectAsync]);

    // Render
    return (
        <ClaimEditOnObjectContext.Provider value={providerValue}>
            {children}
        </ClaimEditOnObjectContext.Provider>
    );
};
