// React
import { FC, useMemo } from "react";
// Types
import { THighlightDTO, THighlightsGroups } from "Types";
// Components
import { HighlightsGroup } from "./HighlightsGroup";
// Helpers 
import { HighlightTypeHelperSingleton } from "Helpers";

type THighlightsGroupsProps = {
    highlights: THighlightDTO[],
    updateHighlights: (highlights: THighlightDTO[]) => void,
    isEditable?: boolean,
};

export const HighlightsGroups: FC<THighlightsGroupsProps> = ({highlights, updateHighlights,
        isEditable = true}: THighlightsGroupsProps) => {
    
    const highlightsGroups: THighlightsGroups = useMemo(() => {
        return highlights.reduce((currentHighlightsGroups: THighlightsGroups, highlight: THighlightDTO) => {
            const currentHighlightType: string = HighlightTypeHelperSingleton.getHighlightTypeDisplayName(highlight);
            currentHighlightsGroups[currentHighlightType] = currentHighlightsGroups[currentHighlightType] || [];
            currentHighlightsGroups[currentHighlightType].push(highlight);
            return currentHighlightsGroups;
            }, {});
    }, [highlights]);

    const deleteHighlight = (highlightId: string) => {
        // If the highlight is not editable then do nothing
        if(!isEditable) return;

        // Remove the highlight from the highlights array
        const newHighlights = highlights.filter(h => h.id !== highlightId);
        updateHighlights(newHighlights);
    };

    const onEditHighlight = (editedHighlight: THighlightDTO) => {
        // If the highlight is not editable then do nothing
        if(!isEditable) return;

        // update highlights
        const newHighlights: THighlightDTO[] = [];
        for (const highlight of highlights) {
            if (highlight.id === editedHighlight.id) {
                newHighlights.push(editedHighlight);
            } else {
                newHighlights.push(highlight);
            }
        }
        
        // call updateHighlights parent prop callback
        updateHighlights(newHighlights);
    };

    // Render
    return (
        <div>
            {Object.keys(highlightsGroups).map((highlightsGroupType: string) => {
                const highlights: THighlightDTO[] = highlightsGroups[highlightsGroupType];
                return (
                    <HighlightsGroup 
                        key={highlightsGroupType}
                        type={highlightsGroupType}
                        highlights={highlights} 
                        deleteHighlight={deleteHighlight}
                        onEditHighlight={onEditHighlight}
                        isEditable={isEditable}
                        />
                );
            })}
        </div>
    );
};