// node_modules
import { FC, useEffect, useState } from "react";
// Types
import { TCommentDTO } from "Types";
// Components
import { Comment } from "./Comment";

type TCommentsProps = {
    comments: TCommentDTO[],
    emptyCommentPlaceholder: string,
    onCommentsUpdated?: (newComments: TCommentDTO[]) => void,
    allowAddingReply?: boolean
}

export const Comments: FC<TCommentsProps> = ({comments, emptyCommentPlaceholder, 
        onCommentsUpdated, allowAddingReply}: TCommentsProps) => {
    // State
    const [commentsToRender, setCommentsToRender] = useState<TCommentDTO[]>([]);

    // Logic
    useEffect(() => {
        setCommentsToRender([...comments]);
    }, [comments]);

    const onDeleteDone = (deletedComment: TCommentDTO): void => {
        // remove deleted comment from comments to render (either parent comments list or replies list)
        const newComments: TCommentDTO[] = [...commentsToRender.filter(comment => comment.id !== deletedComment.id)];

        // reset state
        setCommentsToRender(newComments);

        // if onCommentsUpdated is defined, call it
        if (onCommentsUpdated) {
            onCommentsUpdated(newComments);
        }
    };

    // Render
    return (
        <div>
            {commentsToRender.map((comment: TCommentDTO) => {
                return (
                    <Comment
                        key={comment.id}
                        comment={comment} 
                        emptyCommentPlaceholder={emptyCommentPlaceholder} 
                        onDeleteDone={onDeleteDone}
                        allowAddingReply={allowAddingReply}
                    />
                );
            })}
        </div>
    );
};