// node_modules
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Contexts
import { AuthContext } from "Providers";
// Controllers
import { SavedDocumentControllerSingleton } from "Controllers";

type TDocumentContext = {
    documentsNotLinkedCount: number,
    refreshDocumentsNotLinkedCount: () => Promise<void>
};

const defaultDocumentsNotLinkedCount = 0;

const defaultDocumentContext: TDocumentContext = {
    documentsNotLinkedCount: defaultDocumentsNotLinkedCount,
    refreshDocumentsNotLinkedCount: async () => { return; }
};

type TDocumentProviderProps = {
    children?: ReactNode
};

export const DocumentContext = createContext<TDocumentContext>(defaultDocumentContext);

export const DocumentProvider = ({children}: TDocumentProviderProps) => {
    // State
    const [documentsNotLinkedCount, setDocumentsNotLinkedCount] = useState<number>(defaultDocumentsNotLinkedCount);

    // Contexts
    const { auth } = useContext(AuthContext);

    // Logic
    const refreshDocumentsNotLinkedCount = useCallback(async (): Promise<void> => {
        // authentication done
        if (!auth.isRequestingAuthInformation) {
            // get new documents not linked count
            const newDocumentsNotLinkedCount = await
                SavedDocumentControllerSingleton.getMyCountAsync(true);
            // set state
            setDocumentsNotLinkedCount(newDocumentsNotLinkedCount);
        }
    }, [auth.isRequestingAuthInformation]);

    useEffect(() => {
        refreshDocumentsNotLinkedCount();
    }, [refreshDocumentsNotLinkedCount]);

    const providerValue = useMemo(() => {
        return { documentsNotLinkedCount, refreshDocumentsNotLinkedCount };
    }, [documentsNotLinkedCount, refreshDocumentsNotLinkedCount]);

    // Render
    return (
        <DocumentContext.Provider value={providerValue}>
            {children}
        </DocumentContext.Provider>
    );
};
