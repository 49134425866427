// React
import { FC, useState, useEffect } from "react";
// Components
import { UserIcon, SaveableEditableMarkdown } from "Components";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Controllers
import { CommentControllerSingleton } from "Controllers";
// Types
import { TCommentv2DTO } from "Types";
// Styles
import styles from "../pageComments.module.scss";

export type TCreatePageCommentProps = {
    emptyThread: boolean,
    objectId: string,
    objectType: ObjectTypeEnum,
    handleCreate: (createdComment: TCommentv2DTO) => void,
    parentId?: string,
    username: string
};

export const CreatePageComment: FC<TCreatePageCommentProps> = ({ emptyThread, objectId, objectType, handleCreate, parentId, username }) => {
    // State
    const [isEmptyThread, setIsEmptyThread] = useState<boolean>(true);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    // Logic
    useEffect(() => {
        setIsEmptyThread(emptyThread);
    }, [emptyThread]);

    const onSave = async (savedText: string) => {
        // safety-checks
        if (!savedText) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Comment can't be empty.");
            return;
        }

        // create new comment object
        const newComment = {
            commentedObjectId: objectId,
            type: objectType,
            text: savedText,
            ...(parentId ? {parentId: parentId} : {})
        } as TCommentv2DTO;

        // create new comment in db
        const createdComment: TCommentv2DTO | undefined = await CommentControllerSingleton
            .createv2Async(newComment);

        // safety-checks
        if (!createdComment) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to create the comment.");
            return;
        }

        setIsEditing(false);
        
        handleCreate(createdComment);
    };

    return (       
        <div className={[styles.createPageComment, isEditing ? styles.editing : ""].join(" ")}>
            {isEmptyThread && <UserIcon email={username} size="large" extraClassName={styles.createPageCommentIcon} /> }
            {!isEditing ? 
                    <p className={[styles.createPageCommentPlaceholder, isEmptyThread ? styles.comment : styles.reply].join(" ")} onClick={() => setIsEditing(true)}>
                        {isEmptyThread ? "Write your comment here" : "Write your reply here"}
                    </p>
                :
                    <div className={[styles.createPageCommentEditing, !isEmptyThread ? styles.extraMargin : ""].join(" ")}>
                        <SaveableEditableMarkdown 
                            source=""
                            noSourcePlaceholder={isEmptyThread ? "Write your comment here" : "Write your reply here"}
                            isEditing={isEditing}
                            onSaveClick={onSave}
                            onCancelClick={() => setIsEditing(false)}
                            saveButtonTitle="Post"
                            isHighlighted
                            hasContentBorder
                        />
                    </div>
            }
        </div>
    );
};