// node_modules
import { Fragment, Node } from "prosemirror-model";
import { Plugin, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
// Components
import { findestSchema } from "Components";
// Enums
import { EditorConstants } from "Constants";
import { StudyControllerSingleton } from "Controllers";
import { CustomBlockSpecialAttrsEnum, SpecialBlockClassNameEnum } from "Enums";
import { DateHelperSingleton } from "Helpers/DateHelper";

export const intakeSheetConfirmationButtonsPlugin = (
        studyId?: string,
        userEmail?: string,
        updateEditorView?: (editorViewToUpdate: EditorView, transaction: Transaction, doCallOnSourceChangeCallback: boolean) => void) => {
    return new Plugin({
        props: {
            handleClickOn: (editorView: EditorView, _: number, node: Node) => {
                // safety-checks
                if (!studyId || !updateEditorView || !userEmail) {
                    // return false (click not handled)
                    return false;
                }

                // if node is a button node
                if (node.type.name === findestSchema.nodes.button.name) {
                    // init node to replace and node to replace by
                    let nodeToReplace: Node | null = null;
                    let replaceNodeBy: Node | null = null;
                    
                    // init isConfirmed
                    let isConfirmed = false;

                    // init emailAddress and date
                    const emailAddress = userEmail;
                    const date: string = DateHelperSingleton
                        .getDateInFormatFromDate(new Date(), "DD-MM-YYYY");

                    // go through all nodes in the document
                    editorView.state.doc.descendants((docNode: Node, _: number, docNodeParent: Node | null) => {
                        // if node is the button node and its parent is an intake sheet confirmation not accepted block
                        if (docNode.type.name === findestSchema.nodes.button.name && docNode === node &&
                                docNodeParent !== null && docNodeParent.type.name === findestSchema.nodes.intake_sheet_confirmation_not_accepted.name) {
                            // set node to replace to the intake sheet confirmation not accepted block
                            nodeToReplace = docNodeParent;
                            // set replace node by to a new intake sheet confirmation accepted block
                            replaceNodeBy = findestSchema.nodes.intake_sheet_confirmation_accepted.create(
                                {
                                    class: `${SpecialBlockClassNameEnum.IntakeSheetConfirmationAccepted}`,
                                    [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedEmail}`]: `${emailAddress}`,
                                    [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedDate}`]: `${date}`
                                },
                                Fragment.fromArray([
                                    findestSchema.nodes.title.create(
                                        {
                                            class: `${SpecialBlockClassNameEnum.Title}`
                                        },
                                        findestSchema.text(EditorConstants.INTAKE_SHEET_CONFIRMATION_ACCEPTED_TITLE_BLOCK_TEXT)
                                    ),
                                    findestSchema.nodes.paragraph.create(
                                        undefined,
                                        findestSchema.text(`${EditorConstants.INTAKE_SHEET_CONFIRMATION_ACCEPTED_PARAGRAPH_BLOCK_PARTIAL_TEXT} ${emailAddress} on ${date}.`)
                                    ),
                                    findestSchema.nodes.button.create(
                                        {
                                            class: `${SpecialBlockClassNameEnum.Button}`
                                        },
                                        findestSchema.text(EditorConstants.INTAKE_SHEET_CONFIRMATION_ACCEPTED_BUTTON_BLOCK_TEXT)
                                    )
                                ])
                            );

                            // set is confirmed to true
                            isConfirmed = true;
                        } else if (docNode === node && docNode.type.name === findestSchema.nodes.button.name && docNodeParent !== null &&
                                docNodeParent.type.name === findestSchema.nodes.intake_sheet_confirmation_accepted.name) {
                            // otherwise if node is the button node and its parent is an intake sheet confirmation accepted block
                            // set node to replace to the intake sheet confirmation accepted block
                            nodeToReplace = docNodeParent;
                            // set replace node by to a new intake sheet confirmation not accepted block
                            replaceNodeBy = findestSchema.nodes.intake_sheet_confirmation_not_accepted.create(
                                {
                                    class: `${SpecialBlockClassNameEnum.IntakeSheetConfirmationNotAccepted}`
                                },
                                Fragment.fromArray([
                                    findestSchema.nodes.paragraph.create(
                                        undefined,
                                        findestSchema.text(EditorConstants.INTAKE_SHEET_CONFIRMATION_NOT_ACCEPTED_PARAGRAPH_BLOCK_TEXT)
                                    ),
                                    findestSchema.nodes.button.create(
                                        {
                                            class: `${SpecialBlockClassNameEnum.Button}`
                                        },
                                        findestSchema.text(EditorConstants.INTAKE_SHEET_CONFIRMATION_NOT_ACCEPTED_BUTTON_BLOCK_TEXT)
                                    )
                                ])
                            );
                            

                            // set is confirmed to false
                            isConfirmed = false;
                        }
                    });
                    
                    // init new transaction
                    let transaction: Transaction | undefined = undefined;

                    // if node to replace and replace node by are set
                    if (nodeToReplace !== null && replaceNodeBy !== null) {
                        // go through all nodes in the document
                        editorView.state.doc.descendants((docNode: Node, docPos: number) => {
                            // if docNode is the node to replace
                            if (docNode === nodeToReplace && replaceNodeBy !== null) {
                                // set transaction to replace the node by the replace node by
                                transaction = editorView.state.tr
                                    .replaceWith(docPos, docPos + docNode.nodeSize, replaceNodeBy);
                                // return false to stop the loop
                                return false;
                            }
                        });
                    }

                    // if transaction is set
                    if (transaction) {
                        // apply transaction
                        updateEditorView(editorView, transaction, true);

                        // update study intake sheet confirmation
                        StudyControllerSingleton
                            .updateStudyIntakeSheetConfirmationAsync(studyId, isConfirmed);
                        
                        // return false (click handled)
                        return true;
                    }
                }

                // return false (click not handled)
                return false;
            }
        }
    });
};