// node_modules
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Controllers
import { AuthControllerSingleton } from "Controllers";
// Contexts
import { AuthContext } from "Providers";
// Helpers
import {
  AxiosHelperSingleton,
  ExtensionCommunicationHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";

export const CallbackPage: React.FC = () => {
  // Other hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Contexts
  const { auth, setAuth, setDefaultAuth } = useContext(AuthContext);

  // handle the authentication flow
  useEffect(() => {
    let isMounted = true; // flag to track component mount status

    // handle the authentication flow
    async function handleAuthFlow() {
      // get code and state from the query parameters
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");
      const state = queryParams.get("state");
      const sharedObjectURLFromParams = queryParams.get("sharedObjectURL");

      // if code is not provided
      if (!code) {
        // console error
        console.error("Token not provided.");
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Token not provided."
        );
        // navigate to root
        navigate("/");
        // stop execution, return
        return;
      }

      // try to validate the callback token
      try {
        // validate the callback token
        await AuthControllerSingleton.validateCallbackToken(code, state || "");
        // get the oauth result
        const authResult = await AuthControllerSingleton.OAuthLogin(
          code,
          state || "",
          auth.isRememberMeEnabled
        );
        // if the component is still mounted and the auth result is provided
        if (isMounted && authResult) {
          // set Authorization header
          AxiosHelperSingleton.setCommonHeaderConfigDefaults(
            "Authorization",
            `Bearer ${authResult.auth.accessToken}`
          );

          // reset the authentication with the plugin
          await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();

          // update the auth context
          setAuth({
            ...auth,
            isTwoFactorRequired: false,
            hasPassword: authResult.auth.hasPassword,
            userEmail: authResult.auth.userEmail,
            roles: authResult.auth.roles,
            permissions: authResult.auth.permissions,
            tenantName: authResult.auth.tenantName,
            tenantId: authResult.auth.tenantId,
            userId: authResult.auth.userId,
          });

          if (sharedObjectURLFromParams) {
            navigate(sharedObjectURLFromParams);
          } else {
            navigate("/");
          }
        }
      } catch (error) {
        // otherwise, console error the error
        console.error("Error during authentication", error);
        // if the component is still mounted
        if (isMounted) {
          // set default auth state in context
          setDefaultAuth();

          // show error message
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Error during authentication"
          );

          // reset the authentication with the plugin
          await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();

          // navigate to root
          navigate("/");
        }
      }
    }

    // call the function to handle the authentication flow
    handleAuthFlow();

    // cleanup
    return () => {
      isMounted = false; // Cleanup sets the flag to false
    };
  }, [navigate, location, setAuth, setDefaultAuth, auth]);

  // Render
  return <div>Loading...</div>;
};
