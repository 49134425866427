import axios from "axios";
import { AxiosHelperSingleton } from "Helpers";
import { TEntitySavedDocumentDTO } from "Types";

export class EntitySavedDocumentController {

    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/entitysaveddocument`;

    public async createLinkAsync(entitySaveDocument: TEntitySavedDocumentDTO): Promise<boolean> {
        try {
            const response = await axios.post(`${this._resourcePath}`, entitySaveDocument);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const EntitySavedDocumentControllerSingleton = new EntitySavedDocumentController();