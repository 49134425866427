import { Dispatch, SetStateAction, useCallback } from "react";
import cloneDeep from "lodash.clonedeep";
import { TExplorerObjectItem } from "Types";
import { useObjectTypeChangeListener } from "Hooks";
import { ObjectTypeEnum } from "Enums";

export const useExplorerObjectConversionListener = (setItems: Dispatch<SetStateAction<TExplorerObjectItem[] | undefined>>) => {
    /** Object type conversion handler */
    const onSingleConversion = useCallback((objectId: string, newType: ObjectTypeEnum): void => {

        // find and update the object type
        const findItemAndUpdateType = (currentItems: TExplorerObjectItem[], objectIdToUpdate: string, updatedType: ObjectTypeEnum) => {
            // go through each current item
            return currentItems.map((currentItem: TExplorerObjectItem) => {
                // if the current item is the one we are looking for
                
                if (currentItem.id === objectIdToUpdate) {
                    // update its type
                    currentItem.objectType = updatedType;
                }

                // if the item has children
                if (currentItem.lowerLevelNodes) {
                    // find and update the children
                    currentItem.lowerLevelNodes = findItemAndUpdateType(currentItem.lowerLevelNodes, objectIdToUpdate, updatedType);
                }

                // return the current item
                return currentItem;
            });
        };

        setItems((prevItems: TExplorerObjectItem[] | undefined) => {
            // if prev items is set
            if (prevItems) {
                // find and update the object type in prev items
                return cloneDeep(findItemAndUpdateType(prevItems, objectId, newType));
            }

            // return prev items
            return prevItems;
        });
    }, [setItems]);
    
    /** Listen to object conversion changes using websockets */
    useObjectTypeChangeListener(onSingleConversion);
};
