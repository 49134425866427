// node_modules
import { useCallback } from "react";
import cloneDeep from "lodash.clonedeep";
import { useNavigate } from "react-router-dom";
// Hooks
import { useAnyLinkRemovedListener } from "Hooks";
// Types
import { TExplorerObjectItem } from "Types";
// Helpers
import { LinkGraphHelperSingleton, ObjectTypeHelperSingleton } from "Helpers";

export const useExplorerAnyLinkRemovedListener = (listItems: TExplorerObjectItem[] | undefined, setListItems: React.Dispatch<React.SetStateAction<TExplorerObjectItem[] | undefined>>,
        parents: TExplorerObjectItem[] | undefined, setParents: React.Dispatch<React.SetStateAction<TExplorerObjectItem[] | undefined>>,
        selectedParentId: string | undefined, setSelectedParentId: React.Dispatch<React.SetStateAction<string | undefined>>, objectIdEdited: string | undefined) => {
    // Hooks
    const navigate = useNavigate();
    
    /** Any link removed handler */
    const anyLinkRemoved = useCallback((fromId: string, toId: string): void => {
        // helper functions
        /** Function to recursively map isCollapsed property for each list item */
        const mapIsCollapsed = (currentListItems: TExplorerObjectItem[], isCollapsedPerListItemId: Map<string, boolean | undefined>): void => {
            // go through each current list item
            for (const currentListItem of currentListItems) {
                // add isCollapsed property to the map for current list item id
                isCollapsedByListItemId.set(currentListItem.id, currentListItem.isCollapsed);

                // if current list item has children
                if (currentListItem.lowerLevelNodes) {
                    // recursively map isCollapsed property for each child
                    mapIsCollapsed(currentListItem.lowerLevelNodes, isCollapsedPerListItemId);
                }
            }
        };
        /** Function to recursively set isCollapsed property for each list item using a map of isCollapsed per list item id */
        const setIsCollapsed = (currentListItems: TExplorerObjectItem[], isCollapsedPerListItemId: Map<string, boolean | undefined>): void => {
            // go through each current list item
            for (const currentListItem of currentListItems) {
                // if isCollapsed property is set for current list item id
                if (isCollapsedPerListItemId.has(currentListItem.id)) {
                    // set isCollapsed property for current list item
                    currentListItem.isCollapsed = isCollapsedPerListItemId.get(currentListItem.id);
                }

                // if current list item has children
                if (currentListItem.lowerLevelNodes) {
                    // recursively set isCollapsed property for each child
                    setIsCollapsed(currentListItem.lowerLevelNodes, isCollapsedPerListItemId);
                }
            }
        };

        // get new list items by removing the child
        let newListItems: TExplorerObjectItem[] = cloneDeep([...LinkGraphHelperSingleton.removeChildFromParent(listItems ?? [], fromId, toId)]);
        // recursively map isCollapsed property from list items
        const isCollapsedByListItemId: Map<string, boolean | undefined> = new Map<string, boolean>();
        mapIsCollapsed(listItems ?? [], isCollapsedByListItemId);


        // get new parents by removing the child, filtering the ones without children after
        // and filtering the ones which do not share any children with other parents
        let newParents: TExplorerObjectItem[] = 
            cloneDeep([...LinkGraphHelperSingleton.removeChildFromParent(parents ?? [], fromId, toId)])
            .filter((object: TExplorerObjectItem) => object.lowerLevelNodes && object.lowerLevelNodes.length > 0);

        // get unique children parent ids
        const uniqueChildrenParentIds: Set<string> = new Set<string>();
        // if there are more than one new parents
        if (newParents.length > 1) {
            // go through each new parent
            for (const newParent of newParents) {
                // set is unique to true
                let isUnique = true;
                
                // go through each new parent
                for (const otherNewParent of newParents) {
                    // if new parent id is the same as other new parent id, continue
                    if(newParent.id === otherNewParent.id) continue;

                    // go through each child of new parent
                    for (const child of newParent.lowerLevelNodes) {
                        // if other new parent direct children ids has current child id
                        if (LinkGraphHelperSingleton.getChildrenIds(otherNewParent.lowerLevelNodes, true).has(child.id)) {
                            // set is unique to false
                            isUnique = false;
                            // stop execution, break
                            break;
                        }
                    }

                    // if is not unique, break
                    if(!isUnique) break;
                }

                // if is unique, add new parent id to unique children parent ids
                if(isUnique) uniqueChildrenParentIds.add(newParent.id);
            }
        }
        newParents = newParents.filter(p => !uniqueChildrenParentIds.has(p.id));

        // get new parents common children
        const newParentsCommonChildren: TExplorerObjectItem[] = [];
        // go through each new parent
        for (const parent of newParents) {
            // go through each child of parent
            for (const child of parent.lowerLevelNodes) {
                // if every new parent has current child id as direct children and current child id is not in new parents common children
                if (newParents.every(newParent => LinkGraphHelperSingleton.getChildrenIds(newParent.lowerLevelNodes, true).has(child.id)) && 
                        !newParentsCommonChildren.some(c => c.id === child.id)) {
                    // add current child to new parents common children
                    newParentsCommonChildren.push(child);
                }
            }
        }

        // init doResetSelectedParentId
        let doResetSelectedParentId = false;

        // we might need to change new list items and reset selected parent id in some scenarios:
        // if there were two parents and now there is only one parent
        if (parents && parents.length === 2 && newParents.length === 1) {
            // set new list items to the only new parent
            newListItems = [{
                ...newParents[0],
                isCollapsed: false,
                lowerLevelNodes: [...newParents[0].lowerLevelNodes]
            }];

            // if selected parent id is set
            if (selectedParentId) {
                // reset selected parent id
                doResetSelectedParentId = true;
            }
        } else if (!LinkGraphHelperSingleton.getChildrenIds(newParents).has(fromId)) {
            // otherwise, if the object we removed the link from is not in new parents children ids
            // set new list items to the new parents common children
            newListItems = [...newParentsCommonChildren];

            // if selected parent id is set
            if (selectedParentId && !LinkGraphHelperSingleton.getChildrenIds(newParents).has(selectedParentId)) {
                // reset selected parent id
                doResetSelectedParentId = true;
            }
        }

        // get from id object (either from new parents, new list items or first new list item)
        let fromIdObject: TExplorerObjectItem | undefined = LinkGraphHelperSingleton.getExplorerObjectItemById(fromId, newParents);
        fromIdObject ??= LinkGraphHelperSingleton.getExplorerObjectItemById(fromId, newListItems);
        fromIdObject ??= newListItems[0];

        // if from id object is set, object id edited is set, object id edited is not in new parents children ids
        // and object id edited is not in new list items children ids
        if (fromIdObject && objectIdEdited && !LinkGraphHelperSingleton.getChildrenIds(newParents).has(objectIdEdited) &&
                !LinkGraphHelperSingleton.getChildrenIds(newListItems).has(objectIdEdited)) {
            // navigate to the from id object
            ObjectTypeHelperSingleton
                .navigateBasedOnObjectType(
                    fromIdObject.objectType, 
                    fromIdObject.id, 
                    navigate
                );
        }

        // if do reset selected parent id
        if (doResetSelectedParentId) {
            // set selected parent id to undefined
            setSelectedParentId(undefined);
        }

        // recursively set isCollapsed property on each list item using the map of isCollapsed by list item id
        setIsCollapsed(newListItems, isCollapsedByListItemId);
        // update list items by setting it to the new list items
        setListItems([...newListItems]);

        // update parents by setting it to the new parentss
        setParents([...newParents]);
    }, [listItems, navigate, objectIdEdited, parents, selectedParentId, setListItems, setParents, setSelectedParentId]);
    
    /** Listen to any linked removed using websockets */
    useAnyLinkRemovedListener(anyLinkRemoved);
};