// React
import { FeatureToggleConstants } from "Constants";
import { TTab } from "Types";
import { FC, useMemo, useState } from "react";
import styles from "../DeletedArchive/deletedArchive.module.scss";
import { Tabs } from "Components/Shared";
import { LockedEntities } from "./LockedEntities";
import { LockedStudies } from "./LockedStudies";

const enum LockedObjectsTabsEnum {
    Entities = "Entities",
    Studies = "Studies"
}

export const LockedObjects: FC = () => {
    // Memo needed for the tabs
    const possibleSettingsTabs = useMemo((): TTab[] => {
        // init settings tabs
        const settingsTabs: TTab[] = [
            { name: LockedObjectsTabsEnum.Entities }, 
            { name: LockedObjectsTabsEnum.Studies }
        ];

        // return settings tabs
        return settingsTabs;
    }, []);

    // State
    const [activeTab, setActiveTab] = useState<string>(LockedObjectsTabsEnum.Entities);

    if(!FeatureToggleConstants.AdminLockedObjectsManagement) return null;

    return (
        <div className={styles.deletedArchiveContainer}>
            <Tabs tabs={possibleSettingsTabs} onSelectedTabChange={setActiveTab} extraClassNames={{ container: styles.tabsContainer, tab: styles.tab}} />
            <div className={styles.deletedArchiveListContainer}>
                { activeTab === LockedObjectsTabsEnum.Entities && <LockedEntities /> }
                { activeTab === LockedObjectsTabsEnum.Studies && <LockedStudies /> }
            </div>
        </div>
    );
};