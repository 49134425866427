// node_modules
import { FC } from "react";
// Types
import { TImageDTO } from "Types";

type TImageProps = {
    image: TImageDTO,
    extraClassName?: string,
    onImageClickHandler?: (image: TImageDTO) => void
}

export const Image: FC<TImageProps> = ({image, extraClassName, onImageClickHandler}: TImageProps) => {
    // Render
    return(
        <img 
            onClick={onImageClickHandler ? () => onImageClickHandler(image) : undefined}
            className={extraClassName} 
            src={image.path}
            alt={image.caption} />
    );
};