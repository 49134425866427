// node_modules
import { FC, useContext, useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
// Contexts
import { EditorContext, EditorHeadersContext } from "Providers";
// Components
import { FindestButton, RatingProgressPopover } from "Components";
import { EditorHeadersMenuItem } from "./EditorHeadersMenuItem";
// Styles
import styles from "./editorHeadersMenu.module.scss";
import rightSidebarStyles from "../rightSidebar.module.scss";

type TEditorHeadersMenuProps = {
    onCollapseButtonClick: () => void
}

export const EditorHeadersMenu: FC<TEditorHeadersMenuProps> = ({ onCollapseButtonClick }: TEditorHeadersMenuProps) => {
    // Contexts
    const { editorHeaders, jumpToHeader, onCollapseOrExpandClick } = useContext(EditorHeadersContext);
    const { editorView } = useContext(EditorContext);
   
    // Logic
    const shouldShowExplanation = editorHeaders.filter(header => !header.id.startsWith("js-")).length === 0;

    // State
    const [isRatingProgressPopover, setIsRatingProgressPopover] = useState(false);
    const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
    
    // Render
    return (
        <div className={styles.editorHeadersMenu}>
            <div className={styles.editorHeadersMenuHeader}>
                <h3>On this page</h3>
                <FindestButton
                    leftIconName={faXmark}
                    onClick={onCollapseButtonClick}
                    titleAttribute="Close sidebar"
                    extraClassName={rightSidebarStyles.collapseButton}
                    buttonType="tertiary"
                />
            </div>
            <ul className={styles.editorHeadersMenuListContainer}>
                {shouldShowExplanation && <h4>Headers you add to this page will appear here as table of content.</h4>}
                {editorHeaders.length > 0 && editorHeaders.filter(header => !header.collapsedBy).map((editorHeader) => (
                    <div ref={editorHeader.isResultsOverviewTableHeader === true ? setPopoverRef : undefined} key={editorHeader.id}                    >
                        <EditorHeadersMenuItem
                            editorHeader={editorHeader}
                            onEditorHeaderClick={jumpToHeader}
                            onChevronClick={onCollapseOrExpandClick} 
                            onMouseEnterHandler={editorHeader.isResultsOverviewTableHeader === true ? () => { setIsRatingProgressPopover(true); }: undefined}
                            onMouseLeaveHandler={editorHeader.isResultsOverviewTableHeader === true ? () => { setIsRatingProgressPopover(false); }: undefined}
                        />
                    </div>
                   
                ))}
            </ul>
            <RatingProgressPopover 
                referenceElement={popoverRef}
                isOpen={isRatingProgressPopover}
                editorView={editorView}
                onMouseEnterHandler={() => { setIsRatingProgressPopover(true); }}
                onMouseLeaveHandler={() => { setIsRatingProgressPopover(false); }}
            />
        </div>
    );
};