// node_modules
import { Node } from "prosemirror-model";
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Contexts
import { AuthContext, EditorContext, LinksContext } from "Providers";
// Types
import { TEditorHeader, TRatingsData } from "Types";
// Components
import { findestSchema } from "Components";
// Constants
import { EditorConstants, FeatureToggleConstants, LinkingConstants } from "Constants";
// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";

type TEditorHeadersContext = {
    editorHeaders: TEditorHeader[],
    jumpToHeader: (editorHeader: TEditorHeader) => void,
    jumpToTop: () => void,
    refreshEditorHeaders: (doc: Node) => void,
    onCollapseOrExpandClick: (editorHeader: TEditorHeader) => void,
    changeSelectedEditorHeader: (scrollTop: number) => void,
    updateHeaderPositions: () => void,
    isScrollingAreaPositionTop: boolean,
    setIsScrollingAreaPositionTop: Dispatch<SetStateAction<boolean>>
};

const defaultEditorHeadersContext: TEditorHeadersContext = {
    editorHeaders: [],
    jumpToHeader: () => { return; },
    jumpToTop: () => { return; },
    refreshEditorHeaders: () => { return; },
    onCollapseOrExpandClick: () => { return; },
    changeSelectedEditorHeader: () => { return; },
    updateHeaderPositions: () => { return; },
    isScrollingAreaPositionTop: true,
    setIsScrollingAreaPositionTop: () => { return; }
};

type TEditorHeadersProviderProps = {
    children?: ReactNode,
};

export const EditorHeadersContext = createContext<TEditorHeadersContext>({...defaultEditorHeadersContext});

export const EditorHeadersProvider = ({children}: TEditorHeadersProviderProps) => {
    // Contexts
    const { hasAdvanced } = useContext(AuthContext);
    const { editorState, savedDocumentsCount, connectedQueriesCount, objectId } = useContext(EditorContext);
    const { maturityRadar, requirementsTable } = useContext(LinksContext);

    // State
    const [editorHeaders, setEditorHeaders] = useState<TEditorHeader[]>([]);
    const [isScrollingAreaPositionTop, setIsScrollingAreaPositionTop] = useState<boolean>(true);
    
    // Logic
    const updateHeaders = useCallback((headers: TEditorHeader[]) => {
        const newEditorHeaders = [...headers];
        for (let i = 0; i < newEditorHeaders.length; i++) {
            let hasChildren = false;
            for (let j = i + 1; j < newEditorHeaders.length; j++) {
                if (newEditorHeaders[j].level <= newEditorHeaders[i].level) {
                    break;
                }
                if (!newEditorHeaders[j].collapsedBy && newEditorHeaders[i].isCollapsed) {
                    newEditorHeaders[j].collapsedBy = newEditorHeaders[i].id;
                }
                if (newEditorHeaders[j].collapsedBy && !newEditorHeaders[i].isCollapsed && !newEditorHeaders[i].collapsedBy) {
                    newEditorHeaders[j].collapsedBy = undefined;
                }
                hasChildren = true;
            }
            if (!hasChildren && newEditorHeaders[i].isCollapsed) {
                newEditorHeaders[i].isCollapsed = undefined;
            }
            if (newEditorHeaders[i].collapsedBy) {
                const collapsedByHeader = newEditorHeaders.find(h => h.id === newEditorHeaders[i].collapsedBy);
                if (!collapsedByHeader || !collapsedByHeader.isCollapsed || newEditorHeaders[i].level <= collapsedByHeader.level) {
                    newEditorHeaders[i].collapsedBy = undefined;
                }
            } 
            newEditorHeaders[i].hasChildren = hasChildren;
        }
        return newEditorHeaders;
    }, []);

    const getCollapsedByHeaderValue = useCallback((headerNode: Node, previousEditorHeaders: TEditorHeader[],
            newEditorHeaders: TEditorHeader[]): string | undefined => {
        // init collapsedBy value
        let collapsedByValue = undefined;

        // collapse it if previous header is collapsed
        const currentHeaderProps = previousEditorHeaders.find(header => header.id === headerNode.attrs.id);
        const previousHeader = !currentHeaderProps ? previousEditorHeaders[newEditorHeaders.length - 1] : undefined;
        if (!currentHeaderProps && previousHeader?.collapsedBy) {
            collapsedByValue = previousHeader.collapsedBy;
        }

        // return the value
        return collapsedByValue;
    }, []);

    const refreshEditorHeaders = useCallback((doc: Node): void => {
        setEditorHeaders(previousEditorHeaders => {
            // int new editor headers
            let newEditorHeaders: TEditorHeader[] = [];

            // get the selected header id
            let selectedHeaderId = previousEditorHeaders.find(header => header.isSelected)?.id;
            // get scrolling parent
            const allEditorContainers = document.querySelectorAll(`[id="${objectId}_referenceModal"]`);
            const currentEditorContainer = allEditorContainers.length > 0 ? allEditorContainers[allEditorContainers.length - 1] : document;
            const scrollingParents = currentEditorContainer.querySelectorAll("[class*=entityLikeCard_entityLikeCard_]");
            const parent = scrollingParents[scrollingParents.length - 1];
            const parentY = parent?.getBoundingClientRect().y ?? 0;
            const parentScrollTop = parent?.scrollTop ?? 0;

            // go through content of the doc
            doc.content.forEach((node: Node, index: number) => {
                // if node is a heading, add it to the list
                if (node.type.name === findestSchema.nodes.heading.name && node.attrs.level !== 0) {
                    // get the title of the header
                    let title = "";
                    node.content.forEach((childNode: Node) => {
                        if (childNode.type.name === "text") {
                            title = childNode.text ?? "";
                        }
                    });
                    
                    // do not add empty headers
                    if (title === "") { return; }

                    if (index === 0 && !selectedHeaderId) {
                        selectedHeaderId = node.attrs.id;
                    }
                        
                    // init isResultsOverviewTableHeader
                    const isResultsOverviewTableHeader: boolean = title.toLowerCase().includes(EditorConstants.OVERVIEW_TABLE_HEADING_TEXT.toLowerCase());
                    // init is rating needed in results overview table
                    let isRatingNeededInResultsOverviewTable: boolean | undefined = undefined;
                    // if title contains results overview table
                    if (isResultsOverviewTableHeader) {
                        // get ratings data in results overview table
                        const ratingsData: TRatingsData = ProseMirrorHelperSingleton.getRatingsDataInResultsOverviewTable(doc);

                        // set isRatingNeededInResultsOverviewTable
                        isRatingNeededInResultsOverviewTable = ratingsData.totalNumberOfRatings !== ratingsData.numberOfRatingsDone;
                    }

                    // get collapsedBy value
                    const collapsedByValue = getCollapsedByHeaderValue(node, previousEditorHeaders, newEditorHeaders);
                    // add the header to the list
                    let domElement = document.getElementById(node.attrs.id);
                    // when switching between edit and view mode, DOM header element ids are not the same
                    // so domElement can be undefined
                    // if so, we need to find the header based on the content and its position
                    if (!domElement && previousEditorHeaders.length > 0) {
                        // get editorHeader index in previousEditorHeaders
                        const editorHeaderIndex = previousEditorHeaders.findIndex(header => header.id === node.attrs.id);

                        // get markdown container element
                        const editorElement = currentEditorContainer.querySelector("[class*=markdown_markdownContainer]");
            
                        // safety-checks
                        if (!editorElement) { return; }
            
                        // get all header elements in the markdown container
                        const headerElements = (editorElement as Element).querySelectorAll("h1, h2, h3");
                        // safety-checks
                        if (editorHeaderIndex === -1 && previousEditorHeaders.length === headerElements.length) { return; }
            
                        // safety-checks
                        if (headerElements.length === 0) { return; }
                        // get related header element thanks to editorHeaderIndex
                        domElement = headerElements[editorHeaderIndex] as (HTMLElement | null);
                    }

                    // get domElementY
                    const domElementY = domElement ? domElement.getBoundingClientRect().y : 0;
                    const currentHeaderProps = previousEditorHeaders.find(header => header.id === node.attrs.id);
                    newEditorHeaders.push({
                        ...(currentHeaderProps ?? {}),
                        id: node.attrs.id,
                        level: node.attrs.level,
                        title,
                        isConfirmed: undefined,
                        isResultsOverviewTableHeader,
                        isRatingNeededInResultsOverviewTable,
                        isSelected: selectedHeaderId === node.attrs.id,
                        scrollTop: domElement ? domElementY - parentY + parentScrollTop : 0,
                        ...(collapsedByValue ? { collapsedBy: collapsedByValue } : {}),
                    });
                } else if (node.type.name === findestSchema.nodes.intake_sheet_confirmation.name) {
                    // get collapsedBy value
                    const collapsedByValue = getCollapsedByHeaderValue(node, previousEditorHeaders, newEditorHeaders);
                    // add the header to the list
                    const domElement = document.getElementById(node.attrs.id);
                    // get domElementY
                    const domElementY = domElement ? domElement.getBoundingClientRect().y : 0;
                    const currentHeaderProps = previousEditorHeaders.find(header => header.id === node.attrs.id);
                    // get second child of the intake sheet confirmation node
                    const secondChild: Node | null = node.child(1);
                    // safety-checks
                    if (secondChild) {
                        // init isConfirmed
                        let isConfirmed: boolean | undefined = undefined;
                        // set isConfirmed based on the first child's type
                        if (secondChild.type.name === findestSchema.nodes.intake_sheet_confirmation_not_accepted.name) {
                            isConfirmed = false;
                        } else if (secondChild.type.name === findestSchema.nodes.intake_sheet_confirmation_accepted.name) {
                            isConfirmed = true;
                        }
                        
                        // add confirmation header
                        newEditorHeaders.push({
                            ...(currentHeaderProps ?? {}),
                            id: node.attrs.id,
                            level: 2,
                            title: "Confirmation",
                            isSelected: selectedHeaderId === node.attrs.id,
                            isConfirmed,
                            isResultsOverviewTableHeader: undefined,
                            isRatingNeededInResultsOverviewTable: undefined,
                            scrollTop: domElement ? domElementY - parentY + parentScrollTop: 0,
                            ...(collapsedByValue ? { collapsedBy: collapsedByValue } : {}),
                        });
                    }
                }
            });

            newEditorHeaders = updateHeaders(newEditorHeaders);

            const connectedQueriesHeaderElementId = `${LinkingConstants.CONNECTED_QUERIES_HEADER_ID}_${objectId}`;
            const linkedDocumentsHeaderElementId = `${LinkingConstants.LINKED_DOCUMENTS_HEADER_ID}_${objectId}`;
            const pageCommentsHeaderElementId = `${LinkingConstants.PAGE_COMMENTS_HEADER_ID}_${objectId}`;
            const maturityRadarHeaderElementId = `${LinkingConstants.MATURITY_RADAR_HEADER_ID}_${objectId}`;
            const requirementsTableHeaderElementId = `${LinkingConstants.REQUIREMENTS_TABLE_HEADER_ID}_${objectId}`;
            const connectedQueriesHeaderElement = document.getElementById(connectedQueriesHeaderElementId);
            const linkedDocumentsHeaderElement = document.getElementById(linkedDocumentsHeaderElementId);
            const pageCommentsHeaderElement = document.getElementById(pageCommentsHeaderElementId);
            const maturityRadarHeaderElement = document.getElementById(maturityRadarHeaderElementId);
            const requirementsTableHeaderElement = document.getElementById(requirementsTableHeaderElementId);

            // add requirements table to the editor headers sidebar.
            if (FeatureToggleConstants.RequirementsTable && requirementsTable?.tableRows && requirementsTable.tableRows.length > 0) {
                newEditorHeaders.push({
                    id: requirementsTableHeaderElementId,
                    level: 1,
                    title: requirementsTable.title ?? "Requirements table",
                    isSelected: selectedHeaderId === requirementsTableHeaderElementId,
                    scrollTop: requirementsTableHeaderElement ? requirementsTableHeaderElement.getBoundingClientRect().y - parentY + parentScrollTop : 0,
                });
            }

            // add maturity radar to the editor headers sidebar.
            if (FeatureToggleConstants.MaturityRadar && maturityRadar?.assessments && maturityRadar.assessments.length > 0) {
                newEditorHeaders.push({
                    id: maturityRadarHeaderElementId,
                    level: 1,
                    title: maturityRadar.title ?? "Maturity radar",
                    isSelected: selectedHeaderId === maturityRadarHeaderElementId,
                    scrollTop: maturityRadarHeaderElement ? maturityRadarHeaderElement.getBoundingClientRect().y - parentY + parentScrollTop : 0,
                });
            }

            // add linked items & linked documents to the editor headers sidebar.
            if (savedDocumentsCount > 0) {
                newEditorHeaders.push({
                    id: linkedDocumentsHeaderElementId,
                    level: 1,
                    title: "Linked documents",
                    isSelected: selectedHeaderId === linkedDocumentsHeaderElementId,
                    scrollTop: linkedDocumentsHeaderElement ? linkedDocumentsHeaderElement.getBoundingClientRect().y - parentY + parentScrollTop : 0,
                });
            }

            if(hasAdvanced && connectedQueriesCount >= 0) {
                newEditorHeaders.push({
                    id: connectedQueriesHeaderElementId,
                    level: 1,
                    title: "Connected queries",
                    isSelected: selectedHeaderId === connectedQueriesHeaderElementId,
                    scrollTop: connectedQueriesHeaderElement ? connectedQueriesHeaderElement.getBoundingClientRect().y - parentY + parentScrollTop : 0,
                });
            }

            newEditorHeaders.push({
                id: pageCommentsHeaderElementId,
                level: 1,
                title: "Page comments",
                isSelected: selectedHeaderId === pageCommentsHeaderElementId,
                scrollTop: pageCommentsHeaderElement ? pageCommentsHeaderElement.getBoundingClientRect().y - parentY + parentScrollTop : 0,
            });

            return newEditorHeaders;
        });
    }, [connectedQueriesCount, getCollapsedByHeaderValue, hasAdvanced, maturityRadar?.assessments, maturityRadar?.title, objectId, requirementsTable?.tableRows, requirementsTable?.title, savedDocumentsCount, updateHeaders]);
    
    const resetEditorHeadersContext = useCallback(() => {
        // empty editor headers
        setEditorHeaders([]);
        // reset is scrolling area position top
        setIsScrollingAreaPositionTop(true);
    }, []);

    useEffect(() => {
        // when umounting
        return () => {
            // reset editor headers context
            resetEditorHeadersContext();
        };
    }, [resetEditorHeadersContext]);

    const getEditorHeadersWithUpdatedIsSelectedProps = (scrollTop: number, currEditorHeaders: TEditorHeader[]) => { 
        const newEditorHeaders = [...currEditorHeaders];
        for (let i = 0; i < newEditorHeaders.length; i++) {
            // If scroll value is in between current and next header scroll values
            // Or if scroll value is bigger than current header and current header is the last header
            // Or if scroll value is smaller than current header and there is no previous header
            // Then current header should be selected
            if (
                (scrollTop >= newEditorHeaders[i].scrollTop && (scrollTop < newEditorHeaders[i + 1]?.scrollTop || !newEditorHeaders[i + 1]))
                || (scrollTop <= newEditorHeaders[i].scrollTop && !newEditorHeaders[i - 1])
            ) {
                newEditorHeaders[i].isSelected = true;
            } else {
                newEditorHeaders[i].isSelected = false;
            }
        }
        return newEditorHeaders;
    };

    const changeSelectedEditorHeader = useCallback((scrollTop: number) => { 
        setEditorHeaders(previousEditorHeaders => {
            return getEditorHeadersWithUpdatedIsSelectedProps(scrollTop, previousEditorHeaders);
        });
    }, []);

    const setSelectedEditorHeader = useCallback((headerId: string) => {
        setEditorHeaders(previousEditorHeaders => {
            const newEditorHeaders = previousEditorHeaders.map(header => {
                if (header.id === headerId) {
                    return { ...header, isSelected: true };
                }
                return { ...header, isSelected: false };
            });
            return newEditorHeaders;
        });
    }, []);

   const updateHeaderPositions = useCallback(() => {
        if (!objectId) {
            return;
        }
        // get markdown container element
        const allEditorContainers = document.querySelectorAll(`[id="${objectId}_referenceModal"]`);
        const currentEditorContainer = allEditorContainers.length > 0 ? allEditorContainers[allEditorContainers.length - 1] : document;
        const editorElement = currentEditorContainer.querySelector("[class*=markdown_markdownContainer]");
        // get all header elements in the markdown container
        const headerElements = editorElement?.querySelectorAll("h1, h2, h3");
        // get scrollable parent
        const scrollingParents = currentEditorContainer.querySelectorAll("[class*=entityLikeCard_entityLikeCard_]");
        const parent = scrollingParents[scrollingParents.length - 1];
        const parentY = parent.getBoundingClientRect().y ?? 0;
        const parentScrollTop = parent?.scrollTop ?? 0;
        setEditorHeaders(previousEditorHeaders => {
            let newEditorHeaders = [...previousEditorHeaders];
            if (headerElements) {
                newEditorHeaders = previousEditorHeaders.map((header, index) => {
                    const isPredefinedHeader = header.id.startsWith("js-");
                    const domElement = isPredefinedHeader ? document.querySelector(`[id="${header.id}"]`) as (HTMLElement | null): headerElements[index] as (HTMLElement | null);
                    if (domElement) {
                        return {
                            ...header,
                            scrollTop: domElement.getBoundingClientRect().y - parentY + parentScrollTop,
                        };
                    }
                    return header;
                });
            }
            if (parent && newEditorHeaders.length > 0) {
                newEditorHeaders = getEditorHeadersWithUpdatedIsSelectedProps(parentScrollTop, newEditorHeaders);
            }

            return newEditorHeaders;
        });
    }, [objectId]);
   
    const jumpToHeader = useCallback((editorHeader: TEditorHeader): void  => {
        // get DOM element related to editor header thanks to its id
        const allEditorContainers = document.querySelectorAll(`[id="${objectId}_referenceModal"]`);
        const currentEditorContainer = allEditorContainers.length > 0 ? allEditorContainers[allEditorContainers.length - 1] : document;
        let domElement: HTMLElement | null = currentEditorContainer.querySelector(`[id="${editorHeader.id}"]`);

        // when switching between edit and view mode, DOM header element ids are not the same
        // so domElement can be undefined
        // if so, we need to find the header based on the content and its position
        if (!domElement) {
            // get editorHeader index in editorHeaders
            const editorHeaderIndex = editorHeaders.findIndex(header => header.id === editorHeader.id);

            // safety-checks
            if (editorHeaderIndex === -1) { return; }

            // get markdown container element
            const editorElement = currentEditorContainer.querySelector("[class*=markdown_markdownContainer]");

            // safety-checks
            if (!editorElement) { return; }

            // get all header elements in the markdown container
            const headerElements = editorElement.querySelectorAll("h1, h2, h3");

            // safety-checks
            if (headerElements.length === 0) { return; }

            // get related header element thanks to editorHeaderIndex
            domElement = headerElements[editorHeaderIndex] as (HTMLElement | null);
        }

        // safety-checks
        if (!domElement) { return; }

        // scroll to the DOM element
        const scrollingParents = currentEditorContainer.querySelectorAll("[class*=entityLikeCard_entityLikeCard_]");
        const scrollingParent = scrollingParents[scrollingParents.length - 1];
        const scrollingParentY = scrollingParent?.getBoundingClientRect().y ?? 0;
        if (scrollingParent) {
            const distanceBetweenHeaderAndScrollingParent = domElement.getBoundingClientRect().y - scrollingParentY;
            const scrollTop = scrollingParent.scrollTop;
            const isScrollingParentCurrentlyAtTheBottom = scrollingParent.scrollHeight === scrollingParent.clientHeight + scrollTop;
            const willScrollingParentBeAtTheBottom = scrollingParent.scrollHeight - scrollingParent.scrollTop <= distanceBetweenHeaderAndScrollingParent + scrollingParent.clientHeight;
            
            // If there is no place to scroll, set the element selected
            if (isScrollingParentCurrentlyAtTheBottom && willScrollingParentBeAtTheBottom) {
                setSelectedEditorHeader(editorHeader.id);
            } else if (willScrollingParentBeAtTheBottom) {
                // No smooth scroll just for this case, since we don't have callback ability for scrollTo
                // https://github.com/w3c/csswg-drafts/issues/3744#issuecomment-1242758989
                scrollingParent.scrollTo({ top: distanceBetweenHeaderAndScrollingParent + scrollTop });
                // We still need to wrap it with timeout, because we already listen scroll event, and following line needs to run after that.
                setTimeout(() => {
                    setSelectedEditorHeader(editorHeader.id);
                }, 100);
            } else {
                scrollingParent.scrollTo({ behavior: "smooth", top: distanceBetweenHeaderAndScrollingParent + scrollTop });
            }
        }
    }, [editorHeaders, objectId, setSelectedEditorHeader]);

    const jumpToTop = useCallback(() => {
        const allEditorContainers = document.querySelectorAll(`[id="${objectId}_referenceModal"]`);
        const currentEditorContainer = allEditorContainers.length > 0 ? allEditorContainers[allEditorContainers.length - 1] : document;
        const scrollingParents = currentEditorContainer.querySelectorAll("[class*=entityLikeCard_entityLikeCard_]");
        const scrollingParent = scrollingParents[scrollingParents.length - 1];
        if (scrollingParent) {
            scrollingParent.scrollTo({
                behavior: "smooth", 
                top: 0
            });
        }
    }, [objectId]);

    const onCollapseOrExpandClick = useCallback((editorHeader: TEditorHeader) => {
        const headers = [...editorHeaders];
        const currentHeaderIndex = headers.findIndex(header => header.id === editorHeader.id);
        for (let i = currentHeaderIndex; i < headers.length; i++) {
            const shouldCollapse = !editorHeader.isCollapsed;
            if (i === currentHeaderIndex) {
                headers[i].isCollapsed = shouldCollapse;
                continue;
            }
            if (headers[i].level <= editorHeader.level) {
                break;
            }
            if (headers[i].collapsedBy && headers[i].collapsedBy !== editorHeader.id) {
                break;
            }

            if (shouldCollapse && !!headers[i].isCollapsed) {
                if (headers[i].collapsedBy) {
                    headers[i].collapsedBy = undefined;
                }
                break;
            }
            if (!!headers[i].isCollapsed === shouldCollapse && shouldCollapse === true) {
                break;
            }
            headers[i].collapsedBy = !editorHeader.isCollapsed ? undefined : editorHeader.id;
        }

        setEditorHeaders(headers);
    }, [editorHeaders]);

    useEffect(() => {
        if (!editorState) {
            return;
        }

        refreshEditorHeaders(editorState.doc);
    }, [editorState, refreshEditorHeaders]);

    const providerValue = useMemo(():  TEditorHeadersContext => {
        return {
            editorHeaders,
            jumpToHeader,
            jumpToTop,
            refreshEditorHeaders,
            onCollapseOrExpandClick,
            changeSelectedEditorHeader,
            updateHeaderPositions,
            isScrollingAreaPositionTop,
            setIsScrollingAreaPositionTop
        };
    }, [changeSelectedEditorHeader, editorHeaders, isScrollingAreaPositionTop, jumpToHeader, jumpToTop, onCollapseOrExpandClick, refreshEditorHeaders, updateHeaderPositions]);

    // Render
    return (
        <EditorHeadersContext.Provider value={providerValue}>
            {children}
        </EditorHeadersContext.Provider>
    );
};
