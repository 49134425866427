// node_modules
import {
    Placement
} from "@floating-ui/react-dom";
import { faXmark, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
// Components
import { GroupedList, Popover } from "Components";
// Constants
import { ReferenceConstants } from "Constants";
// Styles
import styles from "./filters.module.scss";
// Enums
import { ReferenceSidebarTabTitleEnum } from "Enums";
// Types
import { TOption, TOptions } from "Types";

type TFiltersProps<T> = {
    title?: string,
    leftIcon?: IconDefinition,
    rightIconOnClose?: IconDefinition,
    rightIconOnOpen?: IconDefinition,
    selectedFilterOptions: TOption<T>[],
    filterOptions: TOptions<T>[],
    handleOptionSelect: (option: TOption<T>) => void,
    handleOptionUnselect: (option: TOption<T>) => void,
    selectedTabTitle?: string,
    extraClassNames?: {
        addFilterButtonContainer?: string,
        addFilterButton?: string,
        filtersContainer?: string,
        filteredItems?: string,
        filteredItem?: string,
        groupedListOption?: string,
        groupTitle?: string,
        groupedList?: string,
        selected?: string
    },
    inReferenceSidebar?: boolean,
    popoverPlacement?: Placement
};

export function Filters<T>({
    title,
    leftIcon,
    rightIconOnClose,
    rightIconOnOpen,
    selectedFilterOptions,
    filterOptions,
    handleOptionSelect,
    handleOptionUnselect,
    extraClassNames,
    inReferenceSidebar = false,
    selectedTabTitle,
    popoverPlacement
}: TFiltersProps<T>) {
    // State
    const [isFilterPopupShown, setIsFilterPopupShown] = useState<boolean>(false);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    
    const shouldShowShowWholeUniverseChip = useMemo(() => {
        // TODO: fix unknown type
        if (inReferenceSidebar && selectedTabTitle !== ReferenceSidebarTabTitleEnum.Inbox) {
            return selectedFilterOptions.findIndex((option: TOption<unknown>) => option.title === ReferenceConstants.ONLY_SHOW_CONNECTED_FILTER_OPTION) === -1;
        }
    }, [inReferenceSidebar, selectedFilterOptions, selectedTabTitle]);

    const shouldShowAddedByMeChip = useMemo(() => {
        return inReferenceSidebar && selectedTabTitle === ReferenceSidebarTabTitleEnum.Inbox;
    }, [inReferenceSidebar, selectedTabTitle]);

    // Render
    return (
        <div className={`${styles.filtersContainer} ${extraClassNames?.filtersContainer ?? ""}`}>
            {!inReferenceSidebar && selectedFilterOptions.length > 0 && (
                <div className={`${styles.filteredItems} ${extraClassNames?.filteredItems ?? ""}`}>
                    {selectedFilterOptions.map((option) => {
                        return (
                            <div key={`${option.title}-${option.value}`} className={`${styles.filteredItem} ${extraClassNames?.filteredItem ?? ""}`}>
                                {option.customTitle ?? option.title}
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    onClick={() => { handleOptionUnselect(option); }} />
                            </div>
                        );
                    })}
                </div>
            )}
            <div className={[styles.addFilterButtonContainer, extraClassNames?.addFilterButtonContainer ?? ""].join(" ")}>
                <div
                    className={`${styles.addFilterButton} ${extraClassNames?.addFilterButton ?? ""}`}
                    ref={setReferenceElement}
                    onMouseEnter={() => { setIsFilterPopupShown(true); }}
                    onMouseLeave={() => { setIsFilterPopupShown(false); }}
                >
                    {leftIcon && <FontAwesomeIcon icon={leftIcon} />}
                    {title ?? ""}
                    {(rightIconOnClose && rightIconOnOpen) && (<FontAwesomeIcon icon={isFilterPopupShown ? rightIconOnOpen : rightIconOnClose} />)}
                </div>
                {isFilterPopupShown && (
                    <Popover
                        referenceEl={referenceElement}
                        extraClassName={styles.popover}
                        onMouseEnter={() => { setIsFilterPopupShown(true); }}
                        onMouseLeave={() => { setIsFilterPopupShown(false); }}
                        placement={inReferenceSidebar ? "bottom-start" : (popoverPlacement ?? undefined)}
                    >
                        <GroupedList
                            options={filterOptions}
                            selectedOptions={selectedFilterOptions}
                            handleOptionUnselect={inReferenceSidebar ? undefined : handleOptionUnselect}
                            handleOptionSelect={handleOptionSelect}
                            extraClassNames={inReferenceSidebar ? {
                                option: extraClassNames?.groupedListOption,
                                groupTitle: extraClassNames?.groupTitle,
                                groupedList: extraClassNames?.groupedList,
                                selected: extraClassNames?.selected
                            } : {}}
                        />
                    </Popover>
                )}
            </div>
            {shouldShowShowWholeUniverseChip && (
                <div key="show-whole-universe-default-chip" className={`${styles.filteredItem} ${styles.defaultChip} ${extraClassNames?.filteredItem ?? ""}`}>
                    Show whole Universe
                </div>
            )}
            {shouldShowAddedByMeChip && (
                <div key="added-by-me-default-chip" className={`${styles.filteredItem} ${styles.defaultChip} ${extraClassNames?.filteredItem ?? ""}`}>
                    Added by me
                </div>
            )}
            {inReferenceSidebar && selectedFilterOptions.length > 0 && (
                selectedFilterOptions.map((option) => {
                    return (
                        <div key={`${option.title}-${option.value}`} className={`${styles.filteredItem} ${extraClassNames?.filteredItem ?? ""}`}>
                            {option.customTitle ?? option.title}
                            <FontAwesomeIcon 
                                icon={faXmark} 
                                onClick={() => {handleOptionUnselect(option);}} />
                        </div>
                    );
                })
            )}
        </div>
    );
}