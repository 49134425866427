// Node modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TGalaxyDTO } from "Types";

export class GalaxyController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/galaxy`;

    public async getGalaxies(): Promise<TGalaxyDTO[]> {
        try {
            // add empty highlight to object
            const response = await axios.get<TGalaxyDTO[]>(this._resourcePath);

            if (response?.data) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

}

export const GalaxyControllerSingleton = new GalaxyController();