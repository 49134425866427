import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import { Image, Reference, ImageModal } from "Components/Shared";
import { FC, useEffect, useMemo, useState } from "react";
import { TImageDTO } from "Types";

import entityLikeCardStyles from "Styles/entityLikeCard.module.scss";

const defaultImage: TImageDTO = {
    id: "",
    caption: "",
    path: "",
    dateAdded: new Date(),
    reference: undefined
};

type TEntityLikeImageContainerProps = {
    images: TImageDTO[],
    onDeleteSelectedImageClickHandlerAsync?: (selectedImage: TImageDTO) => Promise<void>,
}

export const EntityLikeImageContainer: FC<TEntityLikeImageContainerProps> = ({images, 
        onDeleteSelectedImageClickHandlerAsync}: TEntityLikeImageContainerProps) => {
    // State 
    const [selectedImage, setSelectedImage] = useState<TImageDTO>(defaultImage);
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

    // Logic
    const isDefaultImageSelected = useMemo((): boolean => {
        return selectedImage.id === defaultImage.id &&
            selectedImage.path === defaultImage.path &&
            selectedImage.caption === defaultImage.caption &&
            selectedImage.dateAdded === defaultImage.dateAdded &&
            selectedImage.reference === defaultImage.reference;
    }, [selectedImage.id, selectedImage.path, selectedImage.caption, selectedImage.dateAdded, selectedImage.reference]);

    useEffect(() => {
        if (images.length > 0) {
            setSelectedImage({ ...images[0] });
        } else {
            setSelectedImage({ ...defaultImage });
        }
    }, [images]);

    const onImageDeleteClickHanlder = async () => {
        if(!selectedImage.id || isDefaultImageSelected) return;

        if(!onDeleteSelectedImageClickHandlerAsync) return;

        await onDeleteSelectedImageClickHandlerAsync(selectedImage);
    };

    const onImageClickHandler = (image: TImageDTO) => {
        setSelectedImage({ ...image });
    };

    return (
        <>
            <div className={entityLikeCardStyles.entityLikeImagesContainer}>
                <div className={entityLikeCardStyles.entityLikeImageContentContainer}>
                    <div className={entityLikeCardStyles.entityLikeImageContainer}>
                        <div className={entityLikeCardStyles.entityLikeImageAspectRatioBox}>
                            <div className={entityLikeCardStyles.entityLikeImageAspectRatioBoxContent}>
                                <Image
                                    image={selectedImage}
                                    extraClassName={entityLikeCardStyles.entityLikeImage} />
                            </div>
                        </div>
                        <div className={entityLikeCardStyles.entityLikeImageCaption}>
                            <h5>{selectedImage.caption}</h5>
                            <Reference referenceUrl={selectedImage.reference} />
                        </div>
                        <div className={entityLikeCardStyles.actions}>
                            <div className={entityLikeCardStyles.action} title="Expand image" onClick={() => { setIsImageModalOpen(true); }}>
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            </div>
                            {isDefaultImageSelected || !onDeleteSelectedImageClickHandlerAsync ?
                                null
                                :
                                <div className={entityLikeCardStyles.action} title="Delete image" onClick={onImageDeleteClickHanlder}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {images.length > 1 ?
                    <div className={entityLikeCardStyles.entityLikeImageThumbnailsContainer}>
                        {images.map((image: TImageDTO) => {
                            return (
                                <div key={image.id} className={[entityLikeCardStyles.entityLikeImageThumbnailContainer, selectedImage.id === image.id ? entityLikeCardStyles.selected : ""].join(" ")}>
                                    <Image
                                        image={image}
                                        extraClassName={entityLikeCardStyles.entityLikeImageThumbnail}
                                        onImageClickHandler={onImageClickHandler} />
                                </div>
                            );
                        })}
                    </div>
                    : null }
            </div>
            <ImageModal
                image={selectedImage}
                isOpen={isImageModalOpen}
                onClose={() => { setIsImageModalOpen(false); }}
            />
        </>
    );
};