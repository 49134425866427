// node_modules
import { ChangeEvent, FC } from "react";
// Styles
import styles from "./toggleSwitch.module.scss";

export type ToggleSwitchProps = {
    checked?: boolean,
    readOnly?: boolean,
    label?: string,
    onChange?: (isChecked: boolean) => void
};

export const ToggleSwitch: FC<ToggleSwitchProps> = ({ checked, readOnly, label, onChange }) => {
    return (
        <div className={`${styles.toggleLabelContainer} ${readOnly ? styles.readOnly : ""}`}>
            {label && <label>{label}</label>}
            <input title="Toggle" readOnly={!!readOnly} className={styles.toggle} checked={checked} type="checkbox" onChange={onChange && !readOnly ? (changeEvent: ChangeEvent<HTMLInputElement>) => onChange(changeEvent.target.checked) : undefined} />
        </div>
    );
};