// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TTenantDTO, TTenantUserDTO } from "Types";

export class TenantUserController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/tenantuser`;

    public async addUserToTenant(tenantId: string, userId: string): Promise<TTenantDTO | undefined> {
        try {
            const response = await axios.post<TTenantDTO>(this._resourcePath, {
                tenantId: tenantId,
                userId: userId
            } as TTenantUserDTO);

            if (response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch (error: unknown) {
            // show error message
            AxiosHelperSingleton
                .displayAxiosError(error, "Failed to add user to tenant");

            return undefined;
        }
    
    }

    public async removeUserFromTenant(tenantId: string, userId: string): Promise<boolean> {
        try {
            const response = await axios.post(`${this._resourcePath}/delete`, {
                tenantId: tenantId,
                userId: userId
            } as TTenantUserDTO);

            if (response && response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error: unknown) {
            // show error message
            AxiosHelperSingleton
                .displayAxiosError(error, "Failed to remove user from tenant");

            return false;
        }
    }
}


export const TenantUserControllerSingleton = new TenantUserController();