// React
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
// Contexts
import { CollaborationContext } from "./CollaborationProvider";
// Enums
import { ObjectTypeEnum } from "Enums";
// Constants
import { LinkingConstants } from "Constants";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Custom hooks
import { useAnyLinkRemovedListener, useObjectLinkedListener, useObjectNameChangeListener } from "Hooks";
// Types
import { fromTIdNameTypeObjectType, TIdNameTypeObjectType, TLinkGraphDTO, TLinkGraphNodeDTO, TUpdateGraphFromParent } from "Types";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";

type TLinkGraphContext = {
    focusedNodeId?: string,
    focusedNodeType?: ObjectTypeEnum,
    updateGraphFromParent: TUpdateGraphFromParent | undefined,
    doShowReanchorButton: boolean,
    isReferenceModalOpen: boolean,
    referenceModalObjectId?: string,
    referenceModalObjectType?: ObjectTypeEnum,
    onReanchorClick: (newObjectIdEdited?: string, newObjectTypeEdited?: ObjectTypeEnum, shouldNavigate?: boolean) => void,
    setUpdateGraphFromParent: React.Dispatch<React.SetStateAction<TUpdateGraphFromParent | undefined>>,
    openObjectReference: (id: string, type: ObjectTypeEnum) => void,
    closeReferenceModal: () => void,
    linkGraphForFocusedNode?: TLinkGraphDTO
};

const defaultLinkGraphContext: TLinkGraphContext = {
    focusedNodeId: undefined,
    focusedNodeType: undefined,
    updateGraphFromParent: undefined,
    doShowReanchorButton: false,
    isReferenceModalOpen: false,
    referenceModalObjectId: undefined,
    referenceModalObjectType: undefined,
    onReanchorClick: () => { return; },
    setUpdateGraphFromParent: () => { return; },
    openObjectReference: () => { return; },
    closeReferenceModal: () => { return; },
    linkGraphForFocusedNode: undefined
};

type TLinkGraphProviderProps = {
    children?: ReactNode
};

export const LinkGraphContext = createContext<TLinkGraphContext>(defaultLinkGraphContext);

export const LinkGraphProvider = ({children}: TLinkGraphProviderProps) => {
    // Contexts
    const { objectIdEdited, objectTypeEdited } = useContext(CollaborationContext);

    // State
    // TODO: make object out of this
    const [focusedNodeId, setFocusedNodeId] = useState<string | undefined>(undefined);
    const [focusedNodeType, setFocusedNodeType] = useState<ObjectTypeEnum | undefined>(undefined);
    const [updateGraphFromParent, setUpdateGraphFromParent] = useState<TUpdateGraphFromParent | undefined>(undefined);
    const [isReferenceModalOpen, setIsReferenceModalOpen] = useState<boolean>(false);
    const [referenceModalObjectId, setReferenceModalObjectId] = useState<string | undefined>(undefined);
    const [referenceModalObjectType, setReferenceModalObjectType] = useState<ObjectTypeEnum | undefined>(undefined);
    const [linkGraphForFocusedNode, setLinkGraphForFocusedNode] = useState<TLinkGraphDTO | undefined>(undefined);

    // Hooks
    const navigate = useNavigate();

    const onReanchorClick = useCallback((newObjectIdEdited?: string, newObjectTypeEdited?: ObjectTypeEnum, shouldNavigate = true) => {
        // if new object id and type edited are defined
        // (user wants to reanchor to another object from the graph/tree/list view for example)
        if (newObjectIdEdited && newObjectTypeEdited) {
            if (shouldNavigate) {
                ObjectTypeHelperSingleton
                .navigateBasedOnObjectType(newObjectTypeEdited, newObjectIdEdited, navigate);
            }
            // set new object id and type edited in CollaborationContext
            setFocusedNodeId(newObjectIdEdited);
            setFocusedNodeType(newObjectTypeEdited);
        } else {
            // otherwise it might a reanchor because the object id and type edited are... 
            // different from the focused ones (user navigated to another object then clicked on the reanchor button)
            if(objectIdEdited && objectTypeEdited) {
                setFocusedNodeId(objectIdEdited);
                setFocusedNodeType(objectTypeEdited);
            }
        }
    }, [navigate, objectIdEdited, objectTypeEdited]);

    // UseEffect
    useEffect(() => {
        // safety-checks
        if (!focusedNodeId || !focusedNodeType) {
            return;
        }

        (async () => {
            // get new link graph async for the focused node
            const newLinkGraphForFocusedNode: TLinkGraphDTO | undefined = await LinkingControllerSingleton
                .getLinkGraphAsync(focusedNodeId, focusedNodeType, LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT);

            // safety-checks
            if (!newLinkGraphForFocusedNode) { 
                return;
            }

            // set link graph for focused node
            setLinkGraphForFocusedNode(newLinkGraphForFocusedNode);
        })();
    }, [focusedNodeId, focusedNodeType]);

    useEffect(() => {
        if(focusedNodeId && focusedNodeType) return;
        onReanchorClick();
    }, [focusedNodeId, focusedNodeType, onReanchorClick]);

    const openObjectReference = useCallback((id: string, type: ObjectTypeEnum) => {
        setIsReferenceModalOpen(true);
        setReferenceModalObjectId(id);
        setReferenceModalObjectType(type);
    }, []);

    const updateLinkGraphNodeName = useCallback((linkGraphNode: TLinkGraphNodeDTO, objectId: string, name: string): TLinkGraphNodeDTO => {
        // init is update done flag
        let isUpdateDone = false;

        // if link graph node is the one we are looking for
        if (linkGraphNode.id === objectId) {
            // update name
            linkGraphNode.name = name;
            // set is update done flag
            isUpdateDone = true;
        }

        // if update is not done 
        if (!isUpdateDone) {
            // try to update name for each lower level, other upper level and other nodes
            if (linkGraphNode.lowerLevelNodes) {
                for (let lowerLevelNode of linkGraphNode.lowerLevelNodes) {
                    lowerLevelNode = updateLinkGraphNodeName(lowerLevelNode, objectId, name);
                }
            }
            if (linkGraphNode.otherUpperLevelNodes) {
                for (let otherUpperLevelNode of linkGraphNode.otherUpperLevelNodes) {
                    otherUpperLevelNode = updateLinkGraphNodeName(otherUpperLevelNode, objectId, name);
                }
            }
        }

        // return updated (or not) link graph node
        return linkGraphNode;
    }, []);

    const onLinkGraphNodeNameChange = useCallback((objectId: string, name: string) => {
        // set new link graph for focused node
        setLinkGraphForFocusedNode((oldLinkGraphForFocusedNode: TLinkGraphDTO | undefined) => {
            // safety-checks
            if (!oldLinkGraphForFocusedNode) {
                return oldLinkGraphForFocusedNode;
            }

            // init is update done flag
            let isUpdateDone = false;            

            // if focused node is the one we are looking for
            if (oldLinkGraphForFocusedNode.focusedNode.id === objectId) {
                // update name
                oldLinkGraphForFocusedNode.focusedNode.name = name;
                // set is update done flag
                isUpdateDone = true;
            }

            // if update is not done
            if (!isUpdateDone) {
                // try to update name for each lower and upper level nodes
                if (oldLinkGraphForFocusedNode.lowerLevelNodes) {
                    for (let lowerLevelNode of oldLinkGraphForFocusedNode.lowerLevelNodes) {
                        lowerLevelNode = updateLinkGraphNodeName(lowerLevelNode, objectId, name);
                    }
                }
                if (oldLinkGraphForFocusedNode.upperLevelNodes) {
                    for (let upperLevelNode of oldLinkGraphForFocusedNode.upperLevelNodes) {
                        upperLevelNode = updateLinkGraphNodeName(upperLevelNode, objectId, name);
                    }
                }
            }

            // return updated (or not) link graph for focused node
            return {
                ...oldLinkGraphForFocusedNode,
            };
        });
    }, [updateLinkGraphNodeName]);

    const addLinkToLinkGraphNode = useCallback((linkGraphNode: TLinkGraphNodeDTO, fromObject: TIdNameTypeObjectType, toObject: TIdNameTypeObjectType): TLinkGraphNodeDTO => {
        // init is update done flag
        let isUpdateDone = false;

        // if link graph node is the from object
        if (linkGraphNode.id === fromObject.id) {
            // add new lower level node
            linkGraphNode.lowerLevelNodes = linkGraphNode.lowerLevelNodes || [];
            linkGraphNode.lowerLevelNodes = [...linkGraphNode.lowerLevelNodes, fromTIdNameTypeObjectType(toObject)];
            // set is update done flag
            isUpdateDone = true;
        }

        // if link graph node is the to object and is update is not done
        if (linkGraphNode.id === toObject.id && !isUpdateDone) {
            // add new other upper level node
            linkGraphNode.otherUpperLevelNodes = linkGraphNode.otherUpperLevelNodes || [];
            linkGraphNode.otherUpperLevelNodes = [...linkGraphNode.otherUpperLevelNodes, fromTIdNameTypeObjectType(fromObject)];
            // set is update done flag
            isUpdateDone = true;
        }

        // if update is not done
        if (!isUpdateDone) {
            // try to add link for each lower, other upper level and other nodes
            if (linkGraphNode.lowerLevelNodes) {
                for (let lowerLevelNode of linkGraphNode.lowerLevelNodes) {
                    lowerLevelNode = addLinkToLinkGraphNode(lowerLevelNode, fromObject, toObject);
                }
            }
            if (linkGraphNode.otherUpperLevelNodes) {
                for (let otherUpperLevelNode of linkGraphNode.otherUpperLevelNodes) {
                    otherUpperLevelNode = addLinkToLinkGraphNode(otherUpperLevelNode, fromObject, toObject);
                }
            }
        }

        // return updated (or not) link graph node
        return linkGraphNode;
    }, []);

    const onObjectLinked = useCallback((fromObject: TIdNameTypeObjectType, toObject: TIdNameTypeObjectType) => {
        // set new link graph for focused node
        setLinkGraphForFocusedNode((oldLinkGraphForFocusedNode: TLinkGraphDTO | undefined) => {
            // if old link graph for focused node is undefined
            // or from or to object is undefined
            // or from or to object is not an entity or a study
            if (!oldLinkGraphForFocusedNode || !fromObject || !toObject || 
                (fromObject.objectType !== ObjectTypeEnum.Entity && fromObject.objectType !== ObjectTypeEnum.Study) || 
                (toObject.objectType !== ObjectTypeEnum.Entity && toObject.objectType !== ObjectTypeEnum.Study)) {
                return oldLinkGraphForFocusedNode;
            }

            // init is update done flag
            let isUpdateDone = false;
            
            // if focused node is the from object
            if (oldLinkGraphForFocusedNode.focusedNode.id === fromObject.id) {
                // add new lower level node
                oldLinkGraphForFocusedNode.lowerLevelNodes = oldLinkGraphForFocusedNode.lowerLevelNodes || [];
                oldLinkGraphForFocusedNode.lowerLevelNodes = [...oldLinkGraphForFocusedNode.lowerLevelNodes, fromTIdNameTypeObjectType(toObject)];
                // set is update done flag
                isUpdateDone = true;
            }

            // if focused node is the to object and is update is not done
            if (oldLinkGraphForFocusedNode.focusedNode.id === toObject.id && !isUpdateDone) {
                // add new upper level node
                oldLinkGraphForFocusedNode.upperLevelNodes = oldLinkGraphForFocusedNode.upperLevelNodes || [];
                oldLinkGraphForFocusedNode.upperLevelNodes = [...oldLinkGraphForFocusedNode.upperLevelNodes, fromTIdNameTypeObjectType(fromObject)];
                // set is update done flag
                isUpdateDone = true;
            }

            // if update is not done
            if (!isUpdateDone) {
                // try to add link for each lower and upper level nodes
                if (oldLinkGraphForFocusedNode.lowerLevelNodes) {
                    for (let lowerLevelNode of oldLinkGraphForFocusedNode.lowerLevelNodes) {
                        lowerLevelNode = addLinkToLinkGraphNode(lowerLevelNode, fromObject, toObject);
                    }
                }
                if (oldLinkGraphForFocusedNode.upperLevelNodes) {
                    for (let upperLevelNode of oldLinkGraphForFocusedNode.upperLevelNodes) {
                        upperLevelNode = addLinkToLinkGraphNode(upperLevelNode, fromObject, toObject);
                    }
                }
            }

            // return updated (or not) link graph for focused node
            return {
                ...oldLinkGraphForFocusedNode,
            };
        });
    }, [addLinkToLinkGraphNode]);

    const removeLinkFromLinkGraphNode = useCallback((linkGraphNode: TLinkGraphNodeDTO, fromId: string, toId: string): TLinkGraphNodeDTO => {
        // init is update done flag
        let isUpdateDone = false;

        // if link graph node is the from object
        if (linkGraphNode.id === fromId) {
            // remove link in lower level nodes
            linkGraphNode.lowerLevelNodes = linkGraphNode.lowerLevelNodes || [];
            linkGraphNode.lowerLevelNodes = linkGraphNode
                .lowerLevelNodes
                .filter((lowerLevelNode: TIdNameTypeObjectType) => lowerLevelNode.id !== toId);
            // set is update done flag
            isUpdateDone = true;
        }

        // if link graph node is the to object and is update is not done
        if (linkGraphNode.id === toId && !isUpdateDone) {
            // remove link in other upper level nodes
            linkGraphNode.otherUpperLevelNodes = linkGraphNode.otherUpperLevelNodes || [];
            linkGraphNode.otherUpperLevelNodes = linkGraphNode
                .otherUpperLevelNodes
                .filter((otherUpperLevelNode: TIdNameTypeObjectType) => otherUpperLevelNode.id !== fromId);
            // set is update done flag
            isUpdateDone = true;
        }
        
        // if update is not done
        if (!isUpdateDone) {
            // try to remove link for each lower lever, other upper level and other nodes
            if (linkGraphNode.lowerLevelNodes) {
                for (let lowerLevelNode of linkGraphNode.lowerLevelNodes) {
                    lowerLevelNode = removeLinkFromLinkGraphNode(lowerLevelNode, fromId, toId);
                }
            }
            if (linkGraphNode.otherUpperLevelNodes) {
                for (let otherUpperLevelNode of linkGraphNode.otherUpperLevelNodes) {
                    otherUpperLevelNode = removeLinkFromLinkGraphNode(otherUpperLevelNode, fromId, toId);
                }
            }
        }

        // return updated (or not) link graph node
        return linkGraphNode;
    }, []);

    const onLinkRemoved = useCallback((fromId: string, toId: string): void => {
        // set new link graph for focused node
        setLinkGraphForFocusedNode((oldLinkGraphForFocusedNode: TLinkGraphDTO | undefined) => {
            // safety-checks
            if (!oldLinkGraphForFocusedNode) {
                return oldLinkGraphForFocusedNode;
            }

            // init is update done flag
            let isUpdateDone = false;
            
            // if focused node is the from object
            if (oldLinkGraphForFocusedNode.focusedNode.id === fromId) {
                // remove link in lower level nodes
                oldLinkGraphForFocusedNode.lowerLevelNodes = oldLinkGraphForFocusedNode.lowerLevelNodes || [];
                oldLinkGraphForFocusedNode.lowerLevelNodes = oldLinkGraphForFocusedNode
                    .lowerLevelNodes
                    .filter((lowerLevelNode: TIdNameTypeObjectType) => lowerLevelNode.id !== toId);
                // set is update done flag
                isUpdateDone = true;
            }

            // if focused node is the to object and is update is not done
            if (oldLinkGraphForFocusedNode.focusedNode.id === toId && !isUpdateDone) {
                // remove link in upper level nodes
                oldLinkGraphForFocusedNode.upperLevelNodes = oldLinkGraphForFocusedNode.upperLevelNodes || [];
                oldLinkGraphForFocusedNode.upperLevelNodes = oldLinkGraphForFocusedNode
                    .upperLevelNodes
                    .filter((upperLevelNode: TIdNameTypeObjectType) => upperLevelNode.id !== fromId);
                // set is update done flag
                isUpdateDone = true;
            }

            // if update is not done
            if (!isUpdateDone) {
                // try to remove link for each lower and upper level nodes
                if (oldLinkGraphForFocusedNode.lowerLevelNodes) {
                    for (let lowerLevelNode of oldLinkGraphForFocusedNode.lowerLevelNodes) {
                        lowerLevelNode = removeLinkFromLinkGraphNode(lowerLevelNode, fromId, toId);
                    }
                }
                if (oldLinkGraphForFocusedNode.upperLevelNodes) {
                    for (let upperLevelNode of oldLinkGraphForFocusedNode.upperLevelNodes) {
                        upperLevelNode = removeLinkFromLinkGraphNode(upperLevelNode, fromId, toId);
                    }
                }
            }
            
            // return updated (or not) link graph for focused node
            return {
                ...oldLinkGraphForFocusedNode,
            };
        });
    }, [removeLinkFromLinkGraphNode]);

    // Custom hooks for Pub/Sub events handling
    useObjectNameChangeListener(onLinkGraphNodeNameChange, undefined);
    useObjectLinkedListener(onObjectLinked);
    useAnyLinkRemovedListener(onLinkRemoved);

    // useMemo
    const doShowReanchorButton = useMemo(() => {
        if(!objectIdEdited) return false;
        return focusedNodeId !== objectIdEdited;
    }, [focusedNodeId, objectIdEdited]);
    
    // Use memo on provider value
    const providerValue = useMemo(() => {
        return {
            focusedNodeId,
            focusedNodeType,
            updateGraphFromParent,
            doShowReanchorButton,
            isReferenceModalOpen,
            referenceModalObjectId,
            referenceModalObjectType,
            onReanchorClick,
            setUpdateGraphFromParent,
            openObjectReference,
            closeReferenceModal: () => setIsReferenceModalOpen(false),
            linkGraphForFocusedNode
        };
    }, [focusedNodeId, focusedNodeType, updateGraphFromParent, doShowReanchorButton, isReferenceModalOpen, referenceModalObjectId, referenceModalObjectType, onReanchorClick, openObjectReference, linkGraphForFocusedNode]);

    // Render
    return (
        <LinkGraphContext.Provider value={providerValue}>
            {children}
        </LinkGraphContext.Provider>
    );
};