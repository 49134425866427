// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TReadDocumentDTO } from "Types";

export class ReadDocumentsController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/read-documents`;

    public async getMyAsync(): Promise<TReadDocumentDTO[]> {
        try {
            // get current user read documents
            const response = await axios.get<TReadDocumentDTO[]>(`${this._resourcePath}/my`);

            // deal with response
            if(response && response.data) {
                // return response data
                return response.data;
            } else {
                // otherwise, return empty array
                return [];
            }
        } catch {
            // otherwise, return empty array
            return [];
        }
    }

    public async addAsync(documentId: string): Promise<TReadDocumentDTO | undefined> {
        try {
            // add document to read documents
            const response = await axios.put<TReadDocumentDTO | undefined>(`${this._resourcePath}`, { documentId });

            // deal with response
            if(response && response.data) {
                // return response data
                return response.data;
            } else {
                // otherwise, return undefined
                return undefined;
            }
        } catch {
            // otherwise, return undefined
            return undefined;
        }
    }
}

export const ReadDocumentsControllerSingleton = new ReadDocumentsController();
