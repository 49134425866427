import * as signalR from "@microsoft/signalr";
import { AxiosHelperSingleton } from "Helpers";


export class WebsocketController {
    private connection?: signalR.HubConnection;

    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}`;

    public buildSignalRConnection() {
        // if connection is already set, do not build a new one
        if (this.connection) {
            // stop execution, return
            return;
        }
        
        this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${this._resourcePath}totalhub`, 
        { accessTokenFactory: () => `${AxiosHelperSingleton.getCommonHeader("Authorization")}`.split(" ")[1] })
            .withAutomaticReconnect()
            .build();
        // TODO: Maybe use a Web Lock
        // TODO: A function to reconnect if the tab get's active again
        // TODO: Check if app maybe should just refresh completely after tab is active again
    }

    public async startIfNeeded() {
        // safety-checks
        if (!this.connection) {
            // stop execution, return
            return;
        }

        while (this.connection.state === signalR.HubConnectionState.Connecting) {
            await new Promise(r => setTimeout(r, 50));
        }

        if (this.connection.state !== signalR.HubConnectionState.Connected) {
            await this.connection.start();
        }
    }

    public async addHandler(methodName: string, handler: (...args: any[]) => void) {
        // safety-checks
        if (!this.connection) {
            // stop execution, return
            return;
        }

        await this.startIfNeeded();
        this.connection.on(methodName, handler);
    }

    public removeHandler(methodName: string, handler: (...args: any[]) => void) {
        // safety-checks
        if (!this.connection) {
            // stop execution, return
            return;
        }

        this.connection.off(methodName, handler);
    }

    public async invokeFunction(methodName: string, ...args: any[]) {
        // safety-checks
        if (!this.connection) {
            // stop execution, return
            return;
        }

        await this.startIfNeeded();
        await this.connection.invoke(methodName, ...args);
    }
}


export const WebsocketControllerSingleton = new WebsocketController(); 