export class ColorHelper {
    private stringToPastelColor(str: string): string {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        const color = (hash & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return "00000".substring(0, 6 - color.length) + color;
    }

    public getPastelColorFromString(text: string): string {
        return `#${  this.stringToPastelColor(text)}`;
    }
}

export const ColorHelperSingleton = new ColorHelper();
