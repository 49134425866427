// React
import { PinControllerSingleton } from "Controllers/PinController";
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";
import { AuthContext } from "./AuthProvider";

type TPinnedContext = {
    pinnedItems: TIdNameTypeObjectType[],
    refreshPins(): Promise<void>
};

export const defaultPinnedContext: TPinnedContext = {
    pinnedItems: [],
    refreshPins: async () => { return; }
};

type TPinnedProviderProps = {
    children?: ReactNode
};

export const PinnedContext = createContext<TPinnedContext>(defaultPinnedContext);

export const PinnedProvider = ({children}: TPinnedProviderProps) => {

    // Contexts
    const { auth } = useContext(AuthContext);

    // State
    const [pinnedItems, setPinnedItems] = useState<TIdNameTypeObjectType[]>(defaultPinnedContext.pinnedItems);

    // Logic
    const refreshPins = useCallback(async (): Promise<void> => {
        const retrievedPinnedItems = await PinControllerSingleton.getAsync();
        setPinnedItems(retrievedPinnedItems);
    }, []);
    
    // Use effects
    useEffect(() => {
        if(auth.isRequestingAuthInformation) return;
        
        refreshPins();
    }, [refreshPins, auth.isRequestingAuthInformation]);

    const providerValue = useMemo(() => {
        return { pinnedItems, refreshPins };
    }, [pinnedItems, refreshPins]);

    // Render
    return (
        <PinnedContext.Provider value={providerValue}>
            {children}
        </PinnedContext.Provider>
    );
};