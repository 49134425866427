// node_modules
import { ObjectTypeEnum } from "Enums";
import { FC, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TTypeGraphNodeDTO } from "Types";
// Helpers
import { PackGraphHelperSingleton } from "Helpers";
// Styles
import styles from "./universeOverview.module.scss";

type TTypeGraphViewProps = {
    data?: TTypeGraphNodeDTO[],
    searchKeyword?: string,
    extraClassName?: string
}

export const PackGraphView: FC<TTypeGraphViewProps> = ({ data, searchKeyword, extraClassName }: TTypeGraphViewProps) => {
    // Refs
    const packGraphContainerRef = useRef<HTMLDivElement>(null);
    // Hooks
    const navigate = useNavigate();

    const navigateToObject = useCallback((object: { objectType: ObjectTypeEnum, id: string }) => {
        if (object.objectType === ObjectTypeEnum.Entity) {
            navigate(`/library/entities/${object.id}`);
        } else if(object.objectType === ObjectTypeEnum.Study) {
            navigate(`/library/studies/${object.id}`);
        }
    }, [navigate]);

    const renderGraph = useCallback((searchKeyword: string) => {
        if (data && data.length > 0) {
            const newData = searchKeyword && searchKeyword.length > 0 ? data?.reduce((prev: TTypeGraphNodeDTO[], curr) => {
                if (searchKeyword && searchKeyword.length > 0) {
                    const newChildren = curr.children.filter(child => child.name.toLowerCase().includes(searchKeyword.toLowerCase()));
                    if (newChildren.length > 0) {
                        return [...prev, {
                            ...curr,
                            children: newChildren
                        }];
                    }
                }
                return prev;
            }, []) : data;

            PackGraphHelperSingleton.createSVG(newData, { tooltipClassName: styles.directedGraphNodeTooltip }, navigateToObject);
        }
    }, [data, navigateToObject]);

    useEffect(() => {
        if (data) {
            renderGraph(searchKeyword || "");
        }
    }, [searchKeyword, data, renderGraph]);

	return (
		<div ref={packGraphContainerRef} className={`${styles.packGraphContainer} ${extraClassName}`} id="packGraph" />
	);
};