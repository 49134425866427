// node_modules
import { faBullseye, faClockRotateLeft, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faBarsFilter, faSquareDashedCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useContext } from "react";
// Components
import { DraggableNewObject, FindestButton, ForceDirectedGraphViewMenu, ObjectItem } from "Components";
// Contexts
import { AuthContext, RecentActivityContext } from "Providers";
// Enums
import { EntityTypeEnum, LinksWindowTabsEnum, ObjectTypeEnum, StudyTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Types
import { TCheckedLinks, TOption, TUseDragAndDrop, fromTRecentSidebarActivityItemDTO } from "Types";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Styles
import styles from "../linksWindow.module.scss";

type TLinksWindowMenuProps = {
    linksWindowSelectedTab: LinksWindowTabsEnum,
    useDragAndDropProps: TUseDragAndDrop,
    doShowReanchorButton: boolean,
    onReanchorClick: () => void,
    checkedLinks: TCheckedLinks[],
    canUserEdit: boolean,
    selectedFilterOptions: TOption<EntityTypeEnum | StudyTypeEnum>[],
    setSelectedFilterOptions: Dispatch<SetStateAction<TOption<EntityTypeEnum | StudyTypeEnum>[]>>,
    setSearchKeyword: Dispatch<SetStateAction<string>>,
    minimizeAllWindows?: (exceptId?: string) => void,
};

export const LinksWindowMenu: FC<TLinksWindowMenuProps> = ({ 
        linksWindowSelectedTab, 
        useDragAndDropProps, 
        doShowReanchorButton, 
        onReanchorClick, 
        checkedLinks, 
        canUserEdit, 
        setSearchKeyword,
        selectedFilterOptions,
        setSelectedFilterOptions,
        minimizeAllWindows,
    }: TLinksWindowMenuProps) => {

    // Context
    const { mySimpleRecentActivity } = useContext(RecentActivityContext);
    const { isUserExternal } = useContext(AuthContext);

    // Memo
    const doShowRemoveLinksButton = useMemo((): boolean => {
        // return true if there are checked links
        return checkedLinks.length > 0;
    }, [checkedLinks]);

    const linksDisplayValue = useMemo((): string => {
        // get remove links 
        return checkedLinks.length > 1 ? "links" : "link";
    }, [checkedLinks]);

    // Logic
    // on remove links click handler async
    const onRemoveLinksClickAsync = useCallback(async (): Promise<void> => {
        // go through each checked link
        for (const checkedLink of checkedLinks) {
            // delete link
            const isSuccess: boolean = await LinkingControllerSingleton
                .deleteAsync(checkedLink.from.id, checkedLink.to.id);

            // if not success
            if (!isSuccess) {
                // show error message
                ToastHelperSingleton    
                    .showToast(ToastTypeEnum.Error, `Failed to delete link between ${checkedLink.from.name} and ${checkedLink.to.name}.`);
            }
        }
    }, [checkedLinks]);

    const renderRecentActivitySection = useCallback((): JSX.Element => {
        // if there is recent activity
        if (mySimpleRecentActivity.length > 0) {
            // return recent activity section
            return (
                <div className={styles.recentActivityContainer}>
                    <h6 className={styles.linksWindowTitle}>
                        <FontAwesomeIcon icon={faClockRotateLeft} />
                        Recent active
                    </h6>
                    <div className={styles.recentActiveObjects}>
                        {mySimpleRecentActivity.map((recentActivity) => (    
                            <ObjectItem
                                key={recentActivity.id}
                                objectItem={fromTRecentSidebarActivityItemDTO(recentActivity)}
                                isEditable={false}
                                isUserExternal={isUserExternal}
                                isNavigateToObjectItemDisabled
                                extraClassName={styles.recentActivityObjectItem}
                                useDragAndDropProps={linksWindowSelectedTab !== LinksWindowTabsEnum.GraphView ? useDragAndDropProps : undefined}
                                minimizeAllWindows={minimizeAllWindows}
                            />
                        ))}
                    </div>
                </div>
            );
        }

        // return empty fragment
        return <></>;
    }, [mySimpleRecentActivity, isUserExternal, linksWindowSelectedTab, useDragAndDropProps, minimizeAllWindows]);

    // Render
    return (
        <>  
            {linksWindowSelectedTab === LinksWindowTabsEnum.GraphView &&
                <div className={styles.linksWindowContentMenu}>
                    <div className={styles.linksWindowContentMenuTop}>
                        <h6 className={styles.linksWindowTitle}>
                            <FontAwesomeIcon icon={faBarsFilter} />
                            Filter the graph
                        </h6>
                        <ForceDirectedGraphViewMenu 
                            graphType={"Link"}
                            selectedFilterOptions={selectedFilterOptions}
                            setSelectedFilterOptions={setSelectedFilterOptions}
                            setSearchKeyword={setSearchKeyword} 
                            textFilterPlaceholder="Filter on title"
                            filtersTitle="Add filter"
                            extraClassNames={{
                                filtersContainer: styles.filtersContainer,
                                filteredItems: styles.filteredItems,
                                filteredItem: styles.filteredItem,
                                textFilterContainer: styles.linksWindowTextFilterContainer,
                                filtersParentContainer: styles.linksWindowOptionFilterContainer
                            }}
                        />
                    </div>
                    {canUserEdit && renderRecentActivitySection()}
                </div>
            }
            {(linksWindowSelectedTab === LinksWindowTabsEnum.TreeView || linksWindowSelectedTab === LinksWindowTabsEnum.ListView) && canUserEdit ?
                <div className={styles.linksWindowContentMenu}>
                    <div className={styles.linksWindowContentMenuTop}>
                        {doShowRemoveLinksButton && (
                            <>
                                <h6 className={`${styles.linksWindowTitle} ${styles.smallMarginBottom}`}>Selection</h6>
                                <FindestButton 
                                    title={`Remove ${linksDisplayValue}`}
                                    titleAttribute={`Remove the selected ${linksDisplayValue}`}
                                    onClick={onRemoveLinksClickAsync} 
                                    leftIconName={faTrashCan}
                                    extraClassName={styles.removeLinksButton}
                                />
                            </>
                        )}
                        {doShowReanchorButton &&
                            <div className={styles.changeFocusContainer}>
                                <h6 className={`${styles.linksWindowTitle} ${styles.smallMarginBottom}`}>Current active</h6>
                                <FindestButton 
                                    title="Change focus" 
                                    onClick={onReanchorClick} 
                                    extraClassName={styles.focusButton} 
                                    leftIconName={faBullseye} />
                            </div>
                        }
                        <h6 className={styles.linksWindowTitle}>
                            <FontAwesomeIcon icon={faSquareDashedCirclePlus} />
                            Add new objects
                        </h6>
                        <DraggableNewObject 
                            linksWindowSelectedTab={linksWindowSelectedTab}
                            objectType={ObjectTypeEnum.Entity} 
                            useDragAndDropProps={useDragAndDropProps} />
                        <DraggableNewObject 
                            linksWindowSelectedTab={linksWindowSelectedTab}
                            objectType={ObjectTypeEnum.Study}
                            useDragAndDropProps={useDragAndDropProps} />
                    </div>
                    {renderRecentActivitySection()}
                </div>
            :
                (doShowReanchorButton && linksWindowSelectedTab === LinksWindowTabsEnum.TreeView || linksWindowSelectedTab === LinksWindowTabsEnum.ListView) &&
                    <div className={[styles.changeFocusContainerNoEdit, linksWindowSelectedTab === LinksWindowTabsEnum.TreeView ? styles.absolute : styles.sticky].join(" ")}>
                        <h6>Current active</h6>
                        <FindestButton 
                            title="Change focus" 
                            onClick={onReanchorClick} 
                            extraClassName={styles.focusButton} 
                            leftIconName={faBullseye} />
                    </div>
            }
        </>
    );
};