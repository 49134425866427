import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useState } from "react";
// Styles
import styles from "./listItemLink.module.scss";
// Components
import { MoreActionsDropdownButton } from "Components";
// Types
import { TIdNameTypeObjectType, TUseDragAndDrop } from "Types";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Enums
import { ObjectTypeEnum } from "Enums";

export type TListObjectItemProps = {
    iconHasColor: boolean,
    object: TIdNameTypeObjectType,
    icon?: IconDefinition,
    subItemType?: string,
    onClick?: () => void,
    extraClassName?: string,
    extraClassNames?: { listItemContent?: string, listItemIconContainer?: string, itemText?: string, moreActionsButton?: string, rightContent?: string }
    useDragAndDropProps?: TUseDragAndDrop,
    isCreatedByCurrentUser?: boolean,
    isLinksWindowSearchBarResultItem?: boolean,
    setIsObjectResultsElementActive?: (isObjectSearchResultsElementActive: boolean) => void,
    minimizeAllWindows?: (exceptId?: string) => void,
    openReferenceModal?: (objectId: string, objectType: ObjectTypeEnum) => void,
    moreActionsDropdownPopoverDataIdentifier?: string,
    children?: JSX.Element | JSX.Element[],
    navigateCallback?: () => void,
    onMoreActionsOptionClick?: () => void,
    moreActionsDropdownOnCloseCallback?: () => void,
    theme?: string[],
    hideMoreActionsButton?: boolean,
};

export const ListObjectItem: FC<TListObjectItemProps> = ({ openReferenceModal, iconHasColor, object, subItemType, icon, extraClassName,
        onClick, useDragAndDropProps, isCreatedByCurrentUser, isLinksWindowSearchBarResultItem, setIsObjectResultsElementActive,
        moreActionsDropdownPopoverDataIdentifier, navigateCallback, onMoreActionsOptionClick, children, moreActionsDropdownOnCloseCallback,
        extraClassNames = {}, theme, hideMoreActionsButton }) => {

    const [moreActionsDropdownButtonIsOpen, setMoreActionsDropdownButtonIsOpen] = useState<boolean>(false);

    // Logic
    const deactivateObjectResultsElement = useCallback(() => {
        // if setIsObjectResultsElementActive is passed as a prop
        if (setIsObjectResultsElementActive) {
            // set it to false
            setIsObjectResultsElementActive(false);
        }
    }, [setIsObjectResultsElementActive]);

    const navigateToObject = useCallback(() => {
        // deactivate the object results element
        deactivateObjectResultsElement();
    }, [deactivateObjectResultsElement]);

    const onMoreActionsButtonClick = () => {
        setMoreActionsDropdownButtonIsOpen(!moreActionsDropdownButtonIsOpen);
    };

    const moreActionsDropdownOnClose = () => {
        setMoreActionsDropdownButtonIsOpen(false);
        moreActionsDropdownOnCloseCallback?.();
    };

    const getListItemContentClassName = () => {
        const classNames = [styles.listItemContent];
        if (moreActionsDropdownButtonIsOpen) {
            classNames.push(styles.moreActionsDropdownOpen);
        }
        if (extraClassNames?.listItemContent) {
            classNames.push(extraClassNames.listItemContent);
        }
        if (extraClassName) {
            classNames.push(extraClassName);
        }
        if (useDragAndDropProps) {
            classNames.push(styles.draggable);
        }

        if (theme && theme.length > 0) {
            theme.forEach((currentTheme) => {
                classNames.push(styles[currentTheme]);
            });
        }
        return classNames.join(" ");
    };

    return (
        <li 
            className={getListItemContentClassName()}
            onClick={onClick}
            draggable={useDragAndDropProps ? "true" : "false"}
            onDragStart={useDragAndDropProps ? () => { useDragAndDropProps.onDragStart(object, undefined); } : undefined}
        >
            <div className={styles.leftContent}>
                <div className={`${styles.listItemIconContainer} ${extraClassNames?.listItemIconContainer ?? ""} ${iconHasColor ? styles.colored : ""}`}>
                    <FontAwesomeIcon icon={icon ?? ObjectTypeHelperSingleton.getObjectTypeIcon(object.objectType)} />
                </div>
                <div className={styles.itemContent}>
                    <p className={`${styles.itemText} ${extraClassNames?.itemText ?? ""}`} title={object.name}>{object.name}</p>
                    {isCreatedByCurrentUser && <h4 className={styles.createdByYou}>Created by you</h4>}
                </div>
            </div>
            <div className={`${styles.rightContent} ${extraClassNames?.rightContent ?? ""}`}>
                {subItemType && <div className={styles.itemState}><h4>{subItemType}</h4></div>}
                {!hideMoreActionsButton && [ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(object.objectType) && (
                    <MoreActionsDropdownButton
                        objectType={object.objectType}
                        objectId={object.id}
                        openReferenceModalProp={openReferenceModal}
                        dropdownPopoverDataIdentifier={moreActionsDropdownPopoverDataIdentifier}
                        extraClassNames={{
                            dropdownButton: styles.moreActionsDropdownButton,
                            button: `${styles.moreActionsButton} ${extraClassNames?.moreActionsButton ?? ""} ${theme && theme.includes("smallMoreActionsButton") ? styles.smallMoreActionsButton : ""}`,
                            dropdownButtonHover: styles.moreActionsDropdownButtonHover,
                            buttonIconContainer: theme && theme.includes("smallMoreActionsButton") ? styles.smallMoreActionsButton : ""
                        }}
                        navigateCallback={isLinksWindowSearchBarResultItem ? navigateToObject : navigateCallback}
                        onClickOptionCallback={onMoreActionsOptionClick}
                        dropdownOnCloseCallback={moreActionsDropdownOnClose}
                        onButtonClick={onMoreActionsButtonClick}
                    />
                )}
                {children}
            </div>
        </li>
    );
};