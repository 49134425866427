// node_modules
import { FC } from "react";
// Types
import { TListItem } from "Types";
// Components
import { ListItem } from "Components";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { StudyStatusHelperSingleton, StudyTypeHelperSingleton } from "Helpers";
// Interfaces
import { IStudyDTO } from "Interfaces";

type TStudyItemProps = {
    study: IStudyDTO,
    isSelected?: boolean,
    onCheckboxChange?: (isChecked: boolean, title: string) => void,
    onDeleteStudyClick?: (studyToDelete: IStudyDTO) => void,
    onLinkStudyClick?: (study: IStudyDTO) => void,
    onUnlinkStudyClick?: (study: IStudyDTO) => void,
    onItemClickOverride?: (entity: IStudyDTO) => void,
    hideMoreActionsDropdownButton?: boolean,
};

export const StudyItem: FC<TStudyItemProps> = ({study, isSelected, onCheckboxChange, 
        onDeleteStudyClick, onLinkStudyClick, onUnlinkStudyClick, onItemClickOverride, hideMoreActionsDropdownButton }: TStudyItemProps) => {
    const studyListItem: TListItem = {
        id: study.id,
        title: study.title,
        description: study.description,
        conclusion: study.conclusion,
        dateAdded: study.dateAdded,
        objectType: ObjectTypeEnum.Study,
        topText: `${StudyStatusHelperSingleton.getStudyStatusDisplayName(study.status)}, ${StudyTypeHelperSingleton.getStudyTypeDisplayName(study.type, study.customTypeName)}`,
        images: study.images,
        linkedCounts: study.linkedCounts,
        createdByUsername: study.createdByUsername
    };

    return (
        <ListItem<IStudyDTO> isSelected={isSelected} itemObject={study} 
            onDeleteItemClick={onDeleteStudyClick}
            listItem={studyListItem} onCheckboxChange={onCheckboxChange} 
            onLinkItemClick={onLinkStudyClick} 
            onUnlinkItemClick={onUnlinkStudyClick}
            onItemClickOverride={onItemClickOverride}
            hideMoreActionsDropdownButton={hideMoreActionsDropdownButton}
        />
    );
};