// node_modules
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
// Components
import { MaturityLevelInputs } from "./MaturityLevelInputs";
import { MaturityLevelScale } from "./MaturityLevelScale";
import { MaturityLevelsPopover } from "./MaturityLevelsPopover";
// Styles
import styles from "./maturityLevel.module.scss";
// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { MaturityLevelHelperSingleton } from "Helpers";
import { TMaturityRadarDTO } from "Types";

type TMaturityLevelProps = {
    min: number,
    max: number,
    showStepsTexts?: boolean,
    isReadOnly: boolean,
    targetId?: string,
    targetType?: ObjectTypeEnum,
    onChange?: (newMin: number, newMax: number) => void,
    maturityLevels?: TMaturityRadarDTO[],
    setMaturityLevels?: Dispatch<SetStateAction<TMaturityRadarDTO[]>>,
    extraClassNames?: { container?: string }
};

export const MaturityLevel: FC<TMaturityLevelProps> = ({ min, max, showStepsTexts, isReadOnly, targetId, targetType, onChange, maturityLevels, setMaturityLevels, extraClassNames }: TMaturityLevelProps) => {
    // State
    const [safeMin, setSafeMin] = useState<number>(min);
    const [safeMax, setSafeMax] = useState<number>(max);
    const [isMaturityLevelInputsShown, setIsMaturityLevelInputsShown] = useState<boolean>(false);
    const [isMaturityLevelsPopoverShown, setIsMaturityLevelsPopoverShown] = useState<boolean>(false);
    const [reference, setReference] = useState<HTMLDivElement | null>(null);

    // Memo
    const doShowMaturityLevelInputs = useMemo((): boolean => {
        // do show maturity level inputs if is read only is false and is maturity level inputs shown is true
        return !isReadOnly && isMaturityLevelInputsShown;
    }, [isReadOnly, isMaturityLevelInputsShown]);

    const doShowMaturityLevelsPopover = useMemo((): boolean => {
        // do show maturity levels popover if is read only is true and is maturity levels popover shown is true and target id, target type, maturity levels and set maturity levels are defined
        if (isReadOnly && isMaturityLevelsPopoverShown && targetId && targetType && maturityLevels && maturityLevels.length > 0 && setMaturityLevels) {
            return true;
        } else {
            // otherwise, do not show maturity levels popover
            return false;
        }
    }, [isReadOnly, isMaturityLevelsPopoverShown, targetId, targetType, maturityLevels, setMaturityLevels]);

    // Logic
    /// when min or max changes
    useEffect(() => {
        // get sanitized values
        const { newSafeMin, newSafeMax } = MaturityLevelHelperSingleton
            .sanitizeValues(min, max);

        // set safe min and max
        setSafeMin(newSafeMin);
        setSafeMax(newSafeMax);
    }, [min, max]);

    const onMaturityLevelChange = useCallback((newMin: number, newMax: number): void => {
        // get sanitized values
        const { newSafeMin, newSafeMax } = MaturityLevelHelperSingleton
            .sanitizeValues(newMin, newMax);

        // set safe min and max
        setSafeMin(newSafeMin);
        setSafeMax(newSafeMax);

        // call on change props if defined
        if (onChange) {
            onChange(newSafeMin, newSafeMax);
        }
    }, [onChange]);

    const onMouseOver = () => {
        // set is maturity level inputs shown to true
        setIsMaturityLevelInputsShown(true);
        // set is maturity levels popover shown to true
        setIsMaturityLevelsPopoverShown(true);
    };

    const onMouseOut = () => {
        // set is maturity level inputs shown to false
        setIsMaturityLevelInputsShown(false);
        // set is maturity levels popover shown to false
        setIsMaturityLevelsPopoverShown(false);
    };

    // Render
    return (
        <div
            className={`${styles.maturityLevel} ${isMaturityLevelInputsShown && !isReadOnly ? styles.onHover : ""} ${extraClassNames?.container ?? ""}`}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            ref={setReference}
        >
            <MaturityLevelScale
                min={safeMin}
                max={safeMax}
                extraClassNames={{ actualStep: styles.maturityLevelScaleActualStep }}
                showStepsTexts={showStepsTexts} />
            {(doShowMaturityLevelInputs) && (
                <MaturityLevelInputs
                    min={safeMin}
                    max={safeMax}
                    onChange={onMaturityLevelChange} />
            )}
            {(doShowMaturityLevelsPopover && targetId && targetType && maturityLevels && maturityLevels.length > 0 && setMaturityLevels) && (
                <MaturityLevelsPopover
                    targetId={targetId}
                    targetType={targetType} 
                    isOpen={isMaturityLevelsPopoverShown}
                    setIsOpen={setIsMaturityLevelsPopoverShown} 
                    onChange={onMaturityLevelChange} 
                    popoverReferenceEl={reference}
                    maturityLevels={maturityLevels}
                    setMaturityLevels={setMaturityLevels} />
            )}
        </div>
    );
};