import { faChevronDown, faLock } from "@fortawesome/free-solid-svg-icons";
import { FC, useCallback, useContext, useMemo } from "react";
// Components
import { DropdownButton } from "Components";
// Contexts
import { AuthContext, CollaborationContext } from "Providers";
// Types
import { TDropdownButtonOption } from "Types";
// Enums
import { LogFeatureNameEnum } from "Enums";
// Helpers
import { LogHelperSingleton, UserHelperSingleton } from "Helpers";
// Styles
import styles from "./objectUnlockDropdown.module.scss";

type TObjectUnlockDropdownDropdownProps = {
    onUnlockPageClick?: () => void
};

export const ObjectUnlockDropdown: FC<TObjectUnlockDropdownDropdownProps> = ({ onUnlockPageClick }: TObjectUnlockDropdownDropdownProps) => {
    // Contexts
    const { auth } = useContext(AuthContext);
    const { isEditModeOn, isLocked, toggleIsLocked, objectIdEdited } = useContext(CollaborationContext);

    // Logic
    const onClickOption = useCallback((optionName: TDropdownButtonOption) => {
        if (optionName === "unlock page") {
            LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-UnlockPage-Manually`);

            if (onUnlockPageClick) {
                onUnlockPageClick();
                if (objectIdEdited) {
                    toggleIsLocked(objectIdEdited, false);
                }
            }
        }
    }, [objectIdEdited, onUnlockPageClick, toggleIsLocked]);

    // Memos
    const dropdownOptions: TDropdownButtonOption[] = useMemo(() => {
        if (isLocked && UserHelperSingleton.hasAccessToAllLocked(auth)) {
            return ["unlock page"];
        }
        return [];
    }, [isLocked, auth]);

    // Render
    return (
        <DropdownButton
            isButtonEnabled
            optionLabels={dropdownOptions}
            onClickOption={onClickOption}
            extraClassNames={{
                dropdownButton: [styles.dropdownButton, !isEditModeOn ? styles.viewMode : ""].join(" "),
                dropdownButtonHover: styles.dropdownButtonHover,
                button: styles.objectUnlockButton,
                rightIcon: styles.rightIcon,
                buttonIconContainer: styles.buttonIconContainer
            }}
            buttonText="Page is locked"
            iconNameRight={faChevronDown}
            iconNameLeft={faLock}
            titleText="Page is locked"
        />
    );
};
