// node_modules
import { faTable, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useMemo, useRef, useState } from "react";
// Custom hooks
import { useClickOutsideRef } from "Hooks";
// Components
import { EditableNumberInput, FindestButton, Popover } from "Components";
// Types
import { TEditorMenuProps } from "Types";
// Styles
import styles from "./editorMenu.module.scss";

type TEditorTablesMenuProps = {
    editorMenuProps: TEditorMenuProps,
    isTablesMenuDisabled: boolean,
    isSelectionInTable: boolean
}

const MINIMUM_NUMBER_OF_COLUMNS = 1;
const MINIMUM_NUMBER_OF_ROWS = 2;

export const EditorTablesMenu: FC<TEditorTablesMenuProps> = ({ editorMenuProps, isTablesMenuDisabled, isSelectionInTable }: TEditorTablesMenuProps) => {
    // State
    const [isTablesMenuOptionsOpen, setIsTablesMenuOptionsOpen] = useState<boolean>(false);
    const [isInsertTableMenuOptionOpen, setIsInsertTableMenuOptionOpen] = useState<boolean>(false);
    const [tablesMenuPopover, setTablesMenuPopover] = useState<HTMLButtonElement | null>(null);
    const [numberOfColumns, setNumberOfColumns] = useState<number>(MINIMUM_NUMBER_OF_COLUMNS);
    const [numberOfRows, setNumberOfRows] = useState<number>(MINIMUM_NUMBER_OF_ROWS);
    
    //Ref
    const tablesMenuDiv = useRef<HTMLDivElement>(null);

    // Custom hooks
    useClickOutsideRef(tablesMenuDiv, () => {setIsTablesMenuOptionsOpen(false); closeIsInsertTableMenuOptionOpen();});

    const getMenuButtonClassName = (isActive: boolean, isDisabled?: boolean) => {
        const classNameList = [];

        if (isDisabled !== undefined && isDisabled) {
            classNameList.push(styles.disabled);
        } else {
            if (isActive) classNameList.push(styles.active);
        }

        return classNameList.join(" ");
    };

    const isInsertTableButtonDisabled = useMemo((): boolean => {
        return (numberOfColumns < MINIMUM_NUMBER_OF_COLUMNS || numberOfRows < MINIMUM_NUMBER_OF_ROWS);
    }, [numberOfColumns, numberOfRows]);

    const closeIsInsertTableMenuOptionOpen = (): void => {
        setIsInsertTableMenuOptionOpen(false);
        setNumberOfColumns(MINIMUM_NUMBER_OF_COLUMNS);
        setNumberOfRows(MINIMUM_NUMBER_OF_ROWS);
    };

    // Render
    return (
        <div
            ref={tablesMenuDiv}
            onMouseLeave={() => { setIsTablesMenuOptionsOpen(false); closeIsInsertTableMenuOptionOpen(); }}>
            <button
                type="button"
                title="Add table"
                ref={setTablesMenuPopover}
                className={[getMenuButtonClassName(false, isTablesMenuDisabled), styles.menuButton].join(" ")}
                onMouseDown={(event) => { event.preventDefault(); }}
                onMouseEnter={isTablesMenuDisabled ? undefined : () => { setIsTablesMenuOptionsOpen(!isTablesMenuOptionsOpen); closeIsInsertTableMenuOptionOpen(); }}>
                <FontAwesomeIcon icon={faTable} />
            </button>
            {isTablesMenuOptionsOpen || isInsertTableMenuOptionOpen ?
                <Popover
                    referenceEl={tablesMenuPopover}
                    placement="bottom-end"
                    extraClassName={styles.popoverOptionMenu}
                >
                    {(isTablesMenuOptionsOpen && !isInsertTableMenuOptionOpen) &&
                        <div>
                            <div
                                className={[getMenuButtonClassName(false, isTablesMenuDisabled || isSelectionInTable), styles.popoverOptionMenuItem].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onClick={() => { setIsTablesMenuOptionsOpen(false); setIsInsertTableMenuOptionOpen(true); }} 
                                title={"Insert table"}>
                                Insert table
                            </div>
                            <div className={styles.editorSpacerHorizontal}></div>
                            <div
                                className={[getMenuButtonClassName(false, isTablesMenuDisabled || !isSelectionInTable), styles.popoverOptionMenuItem].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onClick={() => editorMenuProps.applyAddColumn("BEFORE")}
                                title={"Insert column before"}>
                                Insert column before
                            </div>
                            <div
                                className={[getMenuButtonClassName(false, isTablesMenuDisabled || !isSelectionInTable), styles.popoverOptionMenuItem].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onClick={() => editorMenuProps.applyAddColumn("AFTER")}
                                title={"Insert column after"}>
                                Insert column after
                            </div>
                            <div
                                className={[getMenuButtonClassName(false, isTablesMenuDisabled || !isSelectionInTable), styles.popoverOptionMenuItem].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onClick={() => editorMenuProps.applyAddRow("BEFORE")}
                                title={"Insert row before"}>
                                Insert row before
                            </div>
                            <div
                                className={[getMenuButtonClassName(false, isTablesMenuDisabled || !isSelectionInTable), styles.popoverOptionMenuItem].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onClick={() => editorMenuProps.applyAddRow("AFTER")}
                                title={"Insert row after"}>
                                Insert row after
                            </div>
                            <div className={styles.editorSpacerHorizontal}></div>
                            <div 
                                className={[getMenuButtonClassName(false, isTablesMenuDisabled || !isSelectionInTable), styles.popoverOptionMenuItem].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onClick={editorMenuProps.applyDeleteRow}
                                title={"Delete row"}>
                                Delete row
                            </div>
                            <div 
                                className={[getMenuButtonClassName(false, isTablesMenuDisabled || !isSelectionInTable), styles.popoverOptionMenuItem].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onClick={editorMenuProps.applyDeleteColumn}
                                title={"Delete column"}>
                                Delete column
                            </div>
                            <div  
                                className={[getMenuButtonClassName(false, isTablesMenuDisabled || !isSelectionInTable), styles.popoverOptionMenuItem, styles.deleteTableOption].join(" ")} 
                                onMouseDown={(event) => { event.preventDefault(); }} 
                                onClick={() => editorMenuProps.applyDeleteTable()}
                                title={"Delete table"}>
                                Delete table
                            </div>
                        </div>
                    }
                    {(!isTablesMenuOptionsOpen && isInsertTableMenuOptionOpen) &&
                        <div className={styles.tablePropertiesMenu}>
                            <EditableNumberInput 
                                value={numberOfColumns}
                                min={MINIMUM_NUMBER_OF_COLUMNS}
                                onValueChange={setNumberOfColumns} 
                                placeholder="columns"/>
                            <FontAwesomeIcon className={styles.xMark} icon={faXmark} />
                            <EditableNumberInput 
                                value={numberOfRows}
                                min={MINIMUM_NUMBER_OF_ROWS}
                                onValueChange={setNumberOfRows}
                                placeholder="rows"/>
                            <span className={styles.text}>table</span>
                            <div className={styles.footer}>
                                <FindestButton 
                                    title="Insert"
                                    onClick={() => editorMenuProps.applyInsertTable(numberOfColumns, numberOfRows)}
                                    isDisabled={isInsertTableButtonDisabled}/>
                            </div>
                        </div>
                    }
                </Popover>
                :
                null
            }
        </div>
    );
};