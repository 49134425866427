// node_modules
import { FC, } from "react";
// Components
import { FindestButton } from "Components";
// Styles
import styles from "./loginWithMagicLink.module.scss";

type TLoginWithMagicLinkProps = {
    errorMessage: string,
    onRequestNewMagicLinkClickAsync: () => Promise<void>,
    onLoginClickAsync: () => Promise<void>
};

export const LoginWithMagicLink: FC<TLoginWithMagicLinkProps> = ({errorMessage, onRequestNewMagicLinkClickAsync, onLoginClickAsync}) => {
    return (
        <div className={styles.loginBox}>
            <div className={styles.title}>Login</div>
            {errorMessage.length > 0 ?
                <>
                    <p className={styles.subtitle}>Something went wrong! Please request a new magic link by clicking the button below.</p>
                    <div className={styles.footer}>
                        <FindestButton extraClassName={[styles.button, styles.magicLinkButton].join(" ")} title="Request new magic link" onClick={onRequestNewMagicLinkClickAsync} />
                    </div>
                </>
            :
                <>
                    <p className={styles.subtitle}>Click Login button below and you will be logged in.</p>
                    <div className={styles.footer}>
                        <FindestButton extraClassName={[styles.button, styles.nextButton].join(" ")} title="Login" onClick={onLoginClickAsync} />
                    </div>
                </>
            }
        </div>
    );
};