// node_modules
import { Plugin } from "prosemirror-state";
import { FC, useEffect } from "react";
// Components
import { EditorContent } from "./EditorContent/EditorContent";
import { EditorMenu } from "./EditorMenu/EditorMenu";
// Styles
import styles from "./editor.module.scss";
// Contexts
import { useEditor } from "Hooks";
// Enums
import { EditorFeatureEnum, ObjectTypeEnum } from "Enums";

type TEditorProps = {
    objectIdEdited?: string,
    objectTypeEdited?: ObjectTypeEnum
    source: string,
    plugins: Plugin[],
    onSourceChange: (newSource: string) => void,
    extraClassNames?: { proseMirrorEditor?: string, wysiwygContent?: string }
};

export const Editor: FC<TEditorProps> = ({ objectIdEdited, objectTypeEdited, source, plugins, onSourceChange, extraClassNames }: TEditorProps) => {
    // Custom hooks
    const { 
        editorRef,
        updateEditorViewObjectSource,
        updateOnSourceChangeCallback,
        updateEditorViewStatePlugins,
        editorMenuProps,
    } = useEditor();

    // Logic
    useEffect(() => {
        // update editor view source
        updateEditorViewObjectSource(source, objectIdEdited, objectTypeEdited);
    }, [updateEditorViewObjectSource, source, objectIdEdited, objectTypeEdited]);

    useEffect(() => {
        // update on source change callback
        updateOnSourceChangeCallback(onSourceChange);
    }, [updateOnSourceChangeCallback, onSourceChange]);

    useEffect(() => {
        // update editor view state plugins
        updateEditorViewStatePlugins(plugins);
    }, [updateEditorViewStatePlugins, plugins]);

    // Render
    return (
        <div className={extraClassNames?.proseMirrorEditor ? extraClassNames.proseMirrorEditor : ""}>
            <EditorContent editorRef={editorRef} extraClassNames={extraClassNames} />
            <div className={[styles.wisiwygBottomContainer].join(" ")}>
                <EditorMenu 
                    initialIsEditorMenuDisabled={false}
                    objectIdEdited={objectIdEdited}
                    objectTypeEdited={objectTypeEdited}
                    hasPaddingLeftRight
                    {...editorMenuProps}
                    featuresDisabled={[EditorFeatureEnum.Headers, EditorFeatureEnum.Lists, EditorFeatureEnum.Tables, EditorFeatureEnum.ScoutingService]} />
            </div>
        </div>
    );
};