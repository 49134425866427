// node_modules
import { faCommentAlt, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
// Helpers
import { DateHelperSingleton, LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import { TCommentDTO } from "Types";
// Components
import { Comments, SaveableEditableMarkdown, UserIcon } from "Components";
import { CreateCommentReply } from "./CreateCommentReply";
// Controllers
import { CommentControllerSingleton } from "Controllers";
// Enums
import { ToastTypeEnum } from "Enums";
// Styles
import styles from "./comment.module.scss";

type TCommentProps = {
    comment: TCommentDTO,
    emptyCommentPlaceholder: string,
    onDeleteDone: (deletedComment: TCommentDTO) => void,
    allowAddingReply?: boolean
}

export const Comment: FC<TCommentProps> = ({comment, emptyCommentPlaceholder, onDeleteDone, allowAddingReply = false}: TCommentProps) => {
    // State
    const [isEditing, setIsEditing] = useState(false);
    const [isReplying, setIsReplying] = useState(false);

    // Logic
    const onSaveClick = async (newComment: string): Promise<void> => {
        // safety-checks
        if (!newComment) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Value can't be empty.");
            return;
        }

        // update text of comment
        comment.text = newComment;

        // update comment in db
        const updatedComment: TCommentDTO | undefined = await CommentControllerSingleton
            .updateAsync(comment.id, comment);

        // safety-checks
        if (!updatedComment) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to update.");
            return;
        }

        // reset is editing state
        setIsEditing(false);

         // log
         LogHelperSingleton.log("ModifyComment");
    };

    const onDeleteClickAsync = async (): Promise<void> => {
        // prevent accidental deletion
        if(!confirm("Are you sure you want to delete this comment? It will delete all replies as well.")) return;

        // remove comment in db (as well as related replies)
        const isSuccess = await CommentControllerSingleton
            .deleteAsync(comment.id);

        // if not successful, show error toast
        if (!isSuccess) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Was not able to delete comment.");
            return;
        }

        // remove replies on current comment object
        comment.replies = undefined;

        // call onDeleteDone function prop
        onDeleteDone(comment);

        // log
        LogHelperSingleton.log("DeleteComment");
    };

    // Render
    return (
        <div className={`${styles.comment} ${isEditing ? styles.edit : ""}`}>
            <div className={styles.commentDetails}>
                <UserIcon email={comment.username} extraClassName={styles.smallUserIcon}/>
                <span className={`${styles.username} ${styles.title}`}>{comment.username}</span>
                <div className={`${styles.dateAdded} ${styles.title}`}>{DateHelperSingleton.getShortenedDate(comment.dateAdded)}</div>
                {comment.isOwnedByCurrentUser && !isEditing ?
                    <div className={styles.commentActions}>
                        <div onClick={() => setIsEditing(true)} className={[styles.commentAction, styles.edit].join(" ")}><FontAwesomeIcon icon={faPencilAlt} title={"Edit comment"} /></div>
                        <div onClick={onDeleteClickAsync} className={[styles.commentAction, styles.delete].join(" ")}><FontAwesomeIcon icon={faTrash} title={"Delete comment"} /></div>
                    </div>
                    :
                    null
                }
            </div>
            <div className={styles.commentContent}>
                <SaveableEditableMarkdown
                    source={comment.text}
                    noSourcePlaceholder={emptyCommentPlaceholder}
                    isEditing={isEditing}
                    onSaveClick={(savedText: string) => onSaveClick(savedText)}
                    onCancelClick={() => setIsEditing(false)} />
            </div>
            {allowAddingReply && !comment.parentId ?
                <div className={styles.commentReplies}>
                    {comment.replies ?
                        <div className={styles.commentRepliesContent}>
                            <Comments
                                comments={comment.replies}
                                emptyCommentPlaceholder={"Add your reply"}
                            />
                        </div>
                        :
                            null
                    }
                    {isReplying ?
                            <CreateCommentReply
                                parentComment={comment}
                                onCancel={() => setIsReplying(false)}
                                onReplyDone={() => setIsReplying(false)} />
                        :
                            <div className={styles.commentReplyButton} onClick={() => setIsReplying(true)}>
                                <FontAwesomeIcon icon={faCommentAlt} />Reply
                            </div>
                    }
                </div>
                :
                null
            }
        </div>
    );
};