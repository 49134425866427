// node_modules
import axios, { AxiosResponse } from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class SavedScienceArticleController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/savedsciencearticle`;
    
    public async deleteMyAsync(id: string): Promise<boolean> {
        try {
            const response: AxiosResponse = await axios
                .delete(`${this._resourcePath}/my/${id}`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const SavedScienceArticleControllerSingleton = new SavedScienceArticleController();
