export enum SpecialBlockClassNameEnum {
    HighlightReference = "highlight-reference",
    ImageReference = "image-reference",
    ImageReferenceImg = "image-reference-img",
    ImageReferenceFigcaption = "image-reference-figcaption",
    ImageReferenceFigcaptionText = "image-reference-figcaption-text",
    FileReference = "file-reference",
    FileReferenceLink = "file-reference-link",
    FileReferenceLinkTitle = "file-reference-link-title",
    FileReferenceLinkExtension = "file-reference-link-extension",
    EntityReference = "entity-reference",
    StudyReference = "study-reference",
    ReferenceLink = "reference-link",
    Table = "editor-table",
    TBody = "editor-tbody",
    THead = "editor-thead",
    Tr = "editor-tr",
    Th = "editor-th",
    Td = "editor-td",
    IntakeSheetConfirmation = "intake-sheet-confirmation",
    IntakeSheetConfirmationAccepted = "intake-sheet-confirmation-accepted",
    IntakeSheetConfirmationNotAccepted = "intake-sheet-confirmation-not-accepted",
    Title = "editor-title",
    Button = "editor-button",
    InlineReference = "inline-reference",
    TdToMerge = "editor-td-to-merge",
    TableContainer = "editor-table-container",
    MeterContainer = "editor-meter-container",
    Stars = "editor-stars",
    RatingIsNeeded = "editor-rating-is-needed",
    HasInlineStars = "editor-has-inline-stars"
}