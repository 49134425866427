// node_modules
import { FC, useContext } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import { ObjectChip } from "Components";
// Custom hooks
import { useObjectReferenceModal } from "Hooks";
// Contexts
import { AuthContext } from "Providers";
import { UserHelperSingleton } from "Helpers";

type ConnectedObjectProps = {
    connectedObject: TIdNameTypeObjectType,
    onRemoveConnectionClickAsync: (connectedObjectToRemove: TIdNameTypeObjectType) => Promise<void>
}

export const ConnectedObject: FC<ConnectedObjectProps> = ({ connectedObject, onRemoveConnectionClickAsync }: ConnectedObjectProps) => {
    // Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    // Contexts
    const { auth, isUserExternal } = useContext(AuthContext);

    // Logic
    const onConnectedObjectClick = (clickedConnectedObject: TIdNameTypeObjectType): void => {
        // safety-checks
        if (!clickedConnectedObject) {
            return;
        }

        setReferenceModalProps((previousReferenceModalProps) => {
            return {
                ...previousReferenceModalProps,
                isOpen: true,
                id: clickedConnectedObject.id,
                type: clickedConnectedObject.objectType
            };
        });
    };

    const onRemoveConnectionClickAsyncHandler = async (connectedObjectToRemove: TIdNameTypeObjectType): Promise<void> => {
        // call parent handler
        await onRemoveConnectionClickAsync(connectedObjectToRemove);
    };

    // Render
    return (
        <>
            <ObjectChip
                isChipClickable
                object={connectedObject}
                removeIconTitle="Delete connection"
                onClick={UserHelperSingleton.isSharingRestrictedToObject(auth) ? undefined : () => { onConnectedObjectClick(connectedObject); }}
                onRemoveClick={isUserExternal ? undefined : async () => { await onRemoveConnectionClickAsyncHandler(connectedObject); }}
            />
            {referenceModal}
        </>
    );
};
