// node_modules
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";
// Enums
import { ToastTypeEnum } from "Enums";
// Components
import { DeletableTextbox, DivDropdown, FindestButton, FindestTextBox } from "Components";
// Styles
import styles from "./queryFilters.module.scss";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Controllers
import { QueryControllerSingleton } from "Controllers";
// Types
import { TQueryFiltersDTO } from "Types";

type TCountriesProps = {
    queryId: string,
    queryFilters: TQueryFiltersDTO,
    setQueryFilters: Dispatch<SetStateAction<TQueryFiltersDTO>>,
}

export const Countries: FC<TCountriesProps> = ({queryId, queryFilters, setQueryFilters}: TCountriesProps) => {
    // State
    const [countryInputValue, setCountryInputValue] = useState<string>("");
    const [countryFilterTypeIndex, setCountryFilterTypeIndex] = useState(queryFilters?.patentFilters?.countryFilterType ?? 0);
    const [countries, setCountries] = useState<string[]>(queryFilters?.patentFilters?.filteredCountries ?? []);

    // Helper
    const mapArrayToArrayWithId = (array: string[]) => array.map((value, index) => ({id: index, value}));

    // Memo
    const countryFilterTypes = useMemo(() => {
        return ["none", "show only", "exclude"];
    }, []);

    // Logic
    const onAddCountry = useCallback(async () => {
        // safety-checks
        if (!countryInputValue) {
            // do nothing
            return;
        }

        // copy countries and add new country
        const newCountries: string[] = [...countries, countryInputValue];

        // set query filters with new countries
        queryFilters.patentFilters.filteredCountries = [...newCountries];

        // set countries state with new countries
        setCountries(newCountries);

        // call back-end to update filters
        const isSuccess: boolean = await QueryControllerSingleton
            .updateFiltersAsync(queryId, queryFilters);

        // safety-checks
        if(!isSuccess) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update filters.");
            // stop execution
            return;
        }

        // empty new country input
        setCountryInputValue("");

        // set query filters state with new query filters
        setQueryFilters(queryFilters);
    }, [countries, countryInputValue, queryFilters, queryId, setQueryFilters]);

    const onUpdateCountry = useCallback(async (indexOfCountryUpdated: number, newValue: string) => {
        // safety-checks
        if (!newValue) {
            // do nothing
            return;
        }

        // prepare copy of new countries
        const newCountries: string[] = [...countries];

        // update country
        newCountries[indexOfCountryUpdated] = newValue;

        // set query filters with new countries
        queryFilters.patentFilters.filteredCountries = [...newCountries];

        // set countries state with new countries
        setCountries(newCountries);

        // call back-end to update filters
        const isSuccess: boolean = await QueryControllerSingleton
            .updateFiltersAsync(queryId, queryFilters);

        // safety-checks
        if(!isSuccess) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update filters.");
            // stop execution
            return;
        }

        // set query filters state with new query filters
        setQueryFilters(queryFilters);
    }, [countries, queryFilters, queryId, setQueryFilters]);

    const onDeleteCountry = useCallback(async (indexOfCountryDeleted: number) => {
        // prepare copy of new countries
        const newCountries: string[] = [...countries];

        // delete country
        newCountries.splice(indexOfCountryDeleted, 1);

        // set query filters with new countries
        queryFilters.patentFilters.filteredCountries = [...newCountries];

        // set countries state with new countries
        setCountries(newCountries);

        // call back-end to update filters
        const isSuccess: boolean = await QueryControllerSingleton
            .updateFiltersAsync(queryId, queryFilters);

        // safety-checks
        if(!isSuccess) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update filters.");
            // stop execution
            return;
        }

        // set query filters state with new query filters
        setQueryFilters(queryFilters);
    }, [countries, queryFilters, queryId, setQueryFilters]);

    const onCountryFilterTypeChange = useCallback(async (newCountryFilterType?: string) => {
        // safety-checks
        if(!newCountryFilterType) {
            // do nothing
            return;
        }

        // get new country filter type index
        const newCountryFilterTypeIndex = countryFilterTypes.indexOf(newCountryFilterType);

        // set country filter type index state with new country filter type index
        setCountryFilterTypeIndex(newCountryFilterTypeIndex);

        // set query filters with new country filter type index
        queryFilters.patentFilters.countryFilterType = newCountryFilterTypeIndex;

        // call back-end to update filters
        const isSuccess: boolean = await QueryControllerSingleton
            .updateFiltersAsync(queryId, queryFilters);

        // safety-checks
        if(!isSuccess) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update filters.");
            // stop execution
            return;
        }

        // set query filters state with new query filters
        setQueryFilters(queryFilters);
    }, [countryFilterTypes, queryFilters, queryId, setQueryFilters]);

    return (
        <div className={styles.filterListItem}>
            <div className={styles.filterListItemTitle}>
                <FontAwesomeIcon icon={faGlobe} />
                <span>Country</span>
            </div>
            <div className={styles.dataListItem}>
                <DivDropdown
                        objectList={countryFilterTypes} 
                        newObjectSelected={onCountryFilterTypeChange} 
                        selectedObjectIndex={countryFilterTypeIndex} 
                        className={styles.divDropdownFilter} 
                        overridenStyle={styles}>
                    {countryFilterTypes.map((option: string) => {
                        return (
                            <div style={{ textTransform: "capitalize" }} key={option}>
                                <span>{option}</span>
                            </div>
                        );
                    })}
                </DivDropdown>
            </div>
            {countryFilterTypeIndex !== 0 ?
                    <div>
                        <div className={[styles.dataListItem, styles.addDataListItem].join(" ")}>
                            <FindestTextBox 
                                placeholder="+ Country (e.g. US, EP, CN)" 
                                onChange={(text: string) => { setCountryInputValue(text); }}
                                onEnter={onAddCountry} 
                                value={countryInputValue} />
                            <FindestButton buttonType="secondary" title="Add" onClick={onAddCountry} />
                        </div>
                        <div className={styles.addedDataListItemContainer}>
                            {mapArrayToArrayWithId(countries).map(({id, value}: {id: number, value: string}, index: number) => {
                                return (
                                    <DeletableTextbox 
                                        key={id} 
                                        text={value} 
                                        index={index} 
                                        onChange={onUpdateCountry} 
                                        onDelete={onDeleteCountry} />
                                );
                            })}
                        </div>
                    </div>
                : 
                    null
            }
        </div>
    );
};
