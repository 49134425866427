// React
import { FC, useRef, useState } from "react";
// Components
import { Modal, SuggestingTextbox } from "Components/Shared";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
import { IQueryDTO } from "Interfaces";
import { QueryControllerSingleton, SearchControllerSingleton } from "Controllers";
import { TIdNameTypeObjectType } from "Types";
import { ToastHelperSingleton } from "Helpers";

export type TConnectToQueryModalProps = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    onLinkedQuery: (query: IQueryDTO) => void
};

export const ConnectToQueryModal: FC<TConnectToQueryModalProps> = ({ isOpen, setIsOpen, onLinkedQuery }) => {
    // State
    
    // Refs
    const [suggestions, setSuggestions] = useState<TIdNameTypeObjectType[]>([]);
    const lastSearchQueryRef = useRef<string | undefined>(undefined);
    const isSearchRunningRef = useRef<boolean>(false);
    const currentSearchQueryTextValueRef = useRef<string>("");

    const runSearchAsync = async (searchTextValue: string): Promise<void> => {
        const foundQueries = await SearchControllerSingleton
            .searchMultipleObjectsAsync(searchTextValue, [ObjectTypeEnum.Query]);

        setSuggestions(foundQueries);

        // set is search running to false
        isSearchRunningRef.current = false;

        // if a last search query is set and different from the last one executed
        if (lastSearchQueryRef.current !== undefined && lastSearchQueryRef.current !== searchTextValue) {
            // then run the search again with the last search query
            await onSearchTextValueChangeAsync(lastSearchQueryRef.current);
        }
    };

    // handle search text value changed
    const onSearchTextValueChangeAsync = async (searchTextValue: string): Promise<void> => {
        // set the current search query text value
        currentSearchQueryTextValueRef.current = searchTextValue;

        // if a search is already running, do not run another one
        // and keep the last search query
        if (isSearchRunningRef.current) {
            lastSearchQueryRef.current = searchTextValue;
            return;
        }

        // set is search running to true
        isSearchRunningRef.current = true;
        // set the last search query ref to undefined
        lastSearchQueryRef.current = undefined;

        // run the search
        await runSearchAsync(searchTextValue);
    };

    // On query click handler
    const onQueryClick = async (queryAlias: TIdNameTypeObjectType): Promise<void> => {
        const query = await QueryControllerSingleton.getByIdAsync(queryAlias.id);
        if(!query) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find query");
            return;
        }
        onLinkedQuery(query);
        setIsOpen(false);
    };

    return (
        <Modal header="Connect existing query" isOpen={isOpen} onClose={() => { setIsOpen(false);} }>
            <SuggestingTextbox
                placeholder="Enter query name"
                title={"Queries"}
                refreshSuggestionsAsync={(newValue: string) => onSearchTextValueChangeAsync(newValue)}
                suggestions={suggestions}
                handleSuggestionClick={onQueryClick}
            />
        </Modal>
    );

};
