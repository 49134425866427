import Cookies from "js-cookie";

type CookieOptions = Cookies.CookieAttributes;

class CookieHelper {
    /**
     * Sets a cookie with specified options. Default expiration time is set to 30 days if not specified.
     */
    public setCookie(name: string, value: string, options?: CookieOptions): void {
        // Establish default settings with a 30-day expiration if not provided
        const defaults: CookieOptions = { expires: 30 };
        // Merge user-provided options with defaults, user-defined settings will override defaults
        const settings = { ...defaults, ...options };
        Cookies.set(name, value, settings);
    }

    /**
     * Retrieves a cookie by name.
     */
    public getCookie(name: string): string | undefined {
        return Cookies.get(name);
    }

    /**
     * Removes a cookie by name, with options.
     */
    public removeCookie(name: string, options?: CookieOptions): void {
        Cookies.remove(name, options);
    }
}

export const CookieHelperSingleton = new CookieHelper();