// node_modules
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
// Components
import { Filters, FindestTextBox } from "Components";
// Enums
import { EntityTypeEnum, StudyTypeEnum } from "Enums";
// Helpers
import { EntityTypeHelperSingleton, StudyTypeHelperSingleton } from "Helpers";
// Types
import { TOption, TOptions } from "Types";

type TForceDirectedGraphViewMenuProps = {
    graphType: "Link" | "Pack",
    selectedFilterOptions: TOption<EntityTypeEnum | StudyTypeEnum>[],
    setSelectedFilterOptions: Dispatch<SetStateAction<TOption<EntityTypeEnum | StudyTypeEnum>[]>>,
    setSearchKeyword: Dispatch<SetStateAction<string>>,
    extraClassNames?: {
        filtersContainer?: string,
        filteredItems?: string,
        filteredItem?: string,
        textFilterContainer?: string,
        filtersParentContainer?: string
    },
    textFilterPlaceholder: string,
    textFilterLeftIcon?: IconDefinition,
    filtersTitle: string,
    filtersLeftIcon?: IconDefinition
}

export const ForceDirectedGraphViewMenu: FC<TForceDirectedGraphViewMenuProps> = ({ graphType, selectedFilterOptions, setSelectedFilterOptions, setSearchKeyword, extraClassNames, 
        textFilterPlaceholder, textFilterLeftIcon, filtersTitle, filtersLeftIcon }: TForceDirectedGraphViewMenuProps) => {
    const [entityTypesDropdownOptionsWithoutCreate, setEntityTypesDropdownOptionsWithoutCreate] = useState<TOptions<EntityTypeEnum>[]>([]);
    const [studyTypesDropdownOptionsWithoutCreate, setStudyTypesDropdownOptionsWithoutCreate] = useState<TOptions<StudyTypeEnum>[]>([]);

    useEffect(() => {
        (async () => {
            const entityTypesDropdownOptionsGroupsWithoutCreate = await EntityTypeHelperSingleton.getCustomTypeDropdownOptionsGroupAsync(false, false);
            setEntityTypesDropdownOptionsWithoutCreate(entityTypesDropdownOptionsGroupsWithoutCreate);
        })();

        (async () => {
            const customTypeDropdownOptionsWithoutCreate = await StudyTypeHelperSingleton.getCustomTypeDropdownOptionsGroupAsync(false, false);
            setStudyTypesDropdownOptionsWithoutCreate(customTypeDropdownOptionsWithoutCreate);
        })();
    }, []);

    const updateSelectedFilterOptions = (action: "add" | "remove", option: TOption<EntityTypeEnum | StudyTypeEnum>) => {
        let newFilterOptions = [];

        if (action === "add") {
            newFilterOptions = [...selectedFilterOptions].concat([option]);
        } else {
            for (const selectedFilterOption of selectedFilterOptions) {
                if (option.value === EntityTypeEnum.Custom) {
                    if (selectedFilterOption.value === option.value &&
                        selectedFilterOption.title === option.title) {
                        continue;
                    }
                } else if (option.value === StudyTypeEnum.Custom) {
                    if (selectedFilterOption.value === option.value &&
                        selectedFilterOption.title === option.title) {
                        continue;
                    }
                } else {
                    if (selectedFilterOption.value === option.value) {
                        continue;
                    }
                }
                newFilterOptions.push(selectedFilterOption);
            }
        }

        setSelectedFilterOptions(newFilterOptions);
    };

    // Render
    return (
        <>
            <div className={extraClassNames?.textFilterContainer}>
                <FindestTextBox
                    placeholder={textFilterPlaceholder}
                    leftIcon={textFilterLeftIcon}
                    onChange={setSearchKeyword}
                />
            </div>
            {graphType === "Link" && (
                <div className={extraClassNames?.filtersParentContainer}>
                    <Filters
                        title={filtersTitle}
                        leftIcon={filtersLeftIcon}
                        filterOptions={[...entityTypesDropdownOptionsWithoutCreate, ...studyTypesDropdownOptionsWithoutCreate]}
                        selectedFilterOptions={selectedFilterOptions}
                        handleOptionSelect={(option) => { updateSelectedFilterOptions("add", option); }}
                        handleOptionUnselect={(option) => { updateSelectedFilterOptions("remove", option); }}
                        popoverPlacement="bottom-end"
                        extraClassNames={extraClassNames} />
                </div>
            )}
        </>
    );
};