// node_modules
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { ObjectChip } from "Components";
// Contexts
import { WindowingContext } from "Providers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Types
import { TReferenceByDTO, fromTReferenceByDTO } from "Types";
// Styles
import styles from "./referencedBy.module.scss";

type TReferencedByProps = {
    referencedBy: TReferenceByDTO[]
}

export const ReferencedBy: FC<TReferencedByProps> = ({referencedBy}: TReferencedByProps) => {
    // State
    const [isReferencedByListOpen, setIsReferencedByListOpen] = useState(false);

    // Hooks
    const navigate = useNavigate();

    // Context
    const { minimizeAllWindows } = useContext(WindowingContext);

    // Logic
    const navigateToObject = useCallback((type: ObjectTypeEnum, id: string) => {
        // navigate to the object's page based on its object type
        ObjectTypeHelperSingleton
            .navigateBasedOnObjectType(type, id, navigate);
        
        // if minimize all windows is defined
        if (minimizeAllWindows) {
            // call it
            minimizeAllWindows();
        }
    }, [minimizeAllWindows, navigate]);


    // Render
    return (
        referencedBy && referencedBy.length > 0 ?
            <div className={styles.referencedBy}>
                <div className={`${styles.referencedByCounter} ${isReferencedByListOpen ? styles.isReferencedByListOpen : ""}`} onClick={() => setIsReferencedByListOpen(!isReferencedByListOpen)}>
                    <FontAwesomeIcon icon={isReferencedByListOpen ? faChevronUp : faChevronDown} />
                    {referencedBy.length} times referenced by
                </div>
                {isReferencedByListOpen &&
                    <div className={styles.referencedByItems}>
                        {referencedBy.map((referencedByItem) => {
                            return (
                                <div key={referencedByItem.id} className={styles.referencedByItem}>
                                    <ObjectChip
                                        shouldShowPreviewCard
                                        object={fromTReferenceByDTO(referencedByItem)}
                                        onClick={() => { navigateToObject(referencedByItem.type, referencedByItem.id); }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
            :
            null
    );
};
