// React
import { ReactNode, createContext, useContext, useMemo } from "react";
// Context
import { AuthContext } from "./AuthProvider";
// Enum
import { ObjectTypeEnum } from "Enums";
// Helpers
import { UserHelperSingleton } from "Helpers";
// Contexts
import { CollaborationContext } from "Providers";

export type TElementVisibilityContext = {
  isLeftSidebarVisible: boolean;
  isRightSidebarVisible: boolean;
  isTopbarVisible: boolean;
  isReferenceSidebarVisible: boolean;
  isEditModeDropdownVisible: boolean;
  isPinButtonVisible: boolean;
  isShareButtonVisible: boolean;
  isEditorEllipsisMenuVisible: boolean;
  isDocumentDropdownVisible: boolean;
  isWindowingSidebarVisible: boolean;
  isObjectEntityOrStudy: boolean;
  // Access related information
  canUserEdit: boolean;
  isInReferenceModal?: boolean;
};

export const defaultElementVisibilityContext: TElementVisibilityContext = {
  isLeftSidebarVisible: false,
  isRightSidebarVisible: false,
  isTopbarVisible: false,
  isReferenceSidebarVisible: false,
  isEditModeDropdownVisible: false,
  isPinButtonVisible: false,
  isShareButtonVisible: false,
  isEditorEllipsisMenuVisible: false,
  isDocumentDropdownVisible: false,
  isWindowingSidebarVisible: false,
  isObjectEntityOrStudy: false,
  isInReferenceModal: undefined,

  // Access related information
  canUserEdit: false,
};

type TElementVisibilityProviderProps = {
  children?: ReactNode;
  isInReferenceModal?: boolean;
};

export const ElementVisibilityContext =
  createContext<TElementVisibilityContext>({
    ...defaultElementVisibilityContext,
  });

export const ElementVisibilityProvider = ({
  children,
  isInReferenceModal,
}: TElementVisibilityProviderProps) => {
  // Contexts
  const {
    auth,
    isUserExternal,
    isUserAtLeastContributor,
    isUserAtLeastViewer,
  } = useContext(AuthContext);
  const {
    isLocked,
    isEditorShown,
    isEditModeOn,
    objectTypeEdited,
    objectIdEdited,
  } = useContext(CollaborationContext);

  // Determine if current object is an entity or study
  const isObjectEntityOrStudy = useMemo(() => {
    return (
      (!!objectIdEdited && objectTypeEdited === ObjectTypeEnum.Entity) ||
      objectTypeEdited === ObjectTypeEnum.Study
    );
  }, [objectTypeEdited, objectIdEdited]);

  // - Left sidebar
  const isLeftSidebarVisible = useMemo(() => {
    return !isUserExternal;
  }, [isUserExternal]);
  // - Topbar
  const isTopbarVisible = useMemo(() => {
    return !isUserExternal;
  }, [isUserExternal]);
  // - Reference sidebar
  const isReferenceSidebarVisible = useMemo(() => {
    return !isUserExternal && !!isEditModeOn;
  }, [isUserExternal, isEditModeOn]);

  const isRightSidebarVisible = useMemo(() => {
    return isObjectEntityOrStudy && !!objectIdEdited;
  }, [isObjectEntityOrStudy, objectIdEdited]);

  // - Edit Mode Dropdown button
  const isEditModeDropdownVisible = useMemo(() => {
    // If the user is not at least contributor, don't show the edit mode dropdown
    if (!isUserAtLeastContributor) return false;

    // If the object is locked then show the dropdown
    if (isLocked) return true;

    // If the editor is visible then show the dropdown, but do not show it in edit mode
    // because the edit mode dropdown is already shown in edit mode
    return isEditorShown && !isEditModeOn;
  }, [isEditModeOn, isEditorShown, isLocked, isUserAtLeastContributor]);

  // - Pin button
  const isPinButtonVisible = useMemo(() => {
    // If the user is not at least viewer, don't show the pin button
    return isObjectEntityOrStudy && isUserAtLeastViewer;
  }, [isUserAtLeastViewer, isObjectEntityOrStudy]);

  // - Share button
  const isShareButtonVisible = useMemo(() => {
    // If the user is not at least viewer, don't show the share button
    return isObjectEntityOrStudy && isUserAtLeastContributor;
  }, [isUserAtLeastContributor, isObjectEntityOrStudy]);

  // - Editor ellipsis menu
  const isEditorEllipsisMenuVisible = useMemo(() => {
    // If the user is not at least contributor, don't show the ellipsis menu
    return isUserAtLeastContributor && isEditorShown;
  }, [isEditorShown, isUserAtLeastContributor]);

  // - Document dropdown
  const isDocumentDropdownVisible = useMemo(() => {
    // If the user is not at least contributor, don't show the document dropdown
    return isUserAtLeastContributor;
  }, [isUserAtLeastContributor]);

  // - Windowing sidebar
  const isWindowingSidebarVisible = useMemo(() => {
    return (
      isUserAtLeastViewer ||
      !UserHelperSingleton.isSharingRestrictedToObject(auth)
    );
  }, [auth, isUserAtLeastViewer]);

  // Memo all the values
  const providerValue = useMemo(() => {
    return {
      isLeftSidebarVisible,
      isTopbarVisible,
      isReferenceSidebarVisible,
      isEditModeDropdownVisible,
      isPinButtonVisible,
      isShareButtonVisible,
      isEditorEllipsisMenuVisible,
      canUserEdit: isUserAtLeastContributor,
      isDocumentDropdownVisible,
      isWindowingSidebarVisible,
      isObjectEntityOrStudy,
      isRightSidebarVisible,
      isInReferenceModal,
    };
  }, [
    isLeftSidebarVisible,
    isRightSidebarVisible,
    isTopbarVisible,
    isReferenceSidebarVisible,
    isShareButtonVisible,
    isEditModeDropdownVisible,
    isPinButtonVisible,
    isEditorEllipsisMenuVisible,
    isUserAtLeastContributor,
    isDocumentDropdownVisible,
    isWindowingSidebarVisible,
    isObjectEntityOrStudy,
    isInReferenceModal,
  ]);

  // Render
  return (
    <ElementVisibilityContext.Provider value={providerValue}>
      {children}
    </ElementVisibilityContext.Provider>
  );
};
