// node_modules
import { Dispatch, FC, SetStateAction } from "react";
// Types
import { TActionObjectDTO } from "Types";
// Styles
import styles from "./actionObjects.module.scss";
// Components
import { FindestTextBox } from "Components";
import { ActionObject } from "./ActionObject";
// Enums
import { SearchPriorityEnum, SearchSubTermTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Controllers
import { ActionObjectControllerSingleton } from "Controllers";
// Interfaces
import { IQueryDTO } from "Interfaces";

type TActionObjectsProps = {
    query: IQueryDTO,
    setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>,
    isSearchTermPriorityDropdown: boolean
}

export const ActionObjects: FC<TActionObjectsProps> = ({ query, setQuery, isSearchTermPriorityDropdown }) => {
    // Logic
    const getSplitActionObjectValue = (actionObjectValue: string): { splitActionValue: string, splitObjectValue: string } => {
        // trim actionObjectValue
        const currentActionObjectValue = actionObjectValue.trim();

        // split actionObjectValue
        const splitActionValue = currentActionObjectValue.substring(0, currentActionObjectValue.indexOf(" "));
        const splitObjectValue = currentActionObjectValue.substring(currentActionObjectValue.indexOf(" ") + 1);

        // return split action object value
        return {
            splitActionValue: splitActionValue,
            splitObjectValue: splitObjectValue
        };
    };

    const isActionObjectValueValid = (actionObjectValue: string, currentQuery: IQueryDTO): boolean => {
        // trim actionObjectValue
        actionObjectValue = actionObjectValue.trim();

        const { splitActionValue, splitObjectValue } = getSplitActionObjectValue(actionObjectValue);

        // safety-checks
        if (!actionObjectValue || splitActionValue.length === 0 || splitObjectValue.length === 0) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "A function needs one action and at least one object.");
            return false;
        }

        // check if current query already has action object with these values
        const actionObject: TActionObjectDTO | undefined = currentQuery.actionObjects.find((actionObjectItem: TActionObjectDTO) => actionObjectItem.action === splitActionValue && actionObjectItem.dobject === splitObjectValue);

        // if action object already exists, show error
        if (actionObject) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Action object already exists.");
            return false;
        }

        // return true
        return true;
    };

    const isActionValueValid = (searchSubTermUpdatedValue: string): boolean => {
        if (searchSubTermUpdatedValue.includes(" ")) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "A function can only have one action.");
            return false;
        }
        return true;
    };

    const addActionObjectAsync = async (action: string, object: string, currentQuery: IQueryDTO): Promise<void> => {
        // create action object dto
        const actionObjectToCreateDTO: TActionObjectDTO = {
            id: 0,
            action: action,
            actionSynonymsAmount: 0,
            actionSynonyms: [],
            dobject: object,
            objectSynonymsAmount: 0,
            objectSynonyms: [],
            searchPriority: SearchPriorityEnum.Should,
            actionTermGroupName: "",
            objectTermGroupName: ""
        };

        // create action object
        const createdActionObject: TActionObjectDTO | undefined = await ActionObjectControllerSingleton
            .createAsync(currentQuery.guid, actionObjectToCreateDTO);

        // safety-checks
        if (!createdActionObject) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not create action object.");
            return;
        }

        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }

            // return query
            // add action object to query
            return {
                ...prevQuery,
                actionObjects: [...prevQuery.actionObjects, createdActionObject]
            };
        });
    };

    const updateActionObjectValueAsync = async (actionObjectId: number, searchSubTermUpdatedValue: string,
            searchSubTermType: SearchSubTermTypeEnum,
            currentQuery: IQueryDTO,
            currentIsActionObjectValueValid: (actionObjectValue: string, currentQuery: IQueryDTO) => boolean): Promise<void> => {
        // get related action object from query
        const actionObjectToUpdate = currentQuery.actionObjects.find((actionObject: TActionObjectDTO) => actionObject.id === actionObjectId);

        // safety-checks
        if (!actionObjectToUpdate) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find action object to update.");
            return;
        }

        // create new action object value
        let newActionObjectValue = "";
        if (searchSubTermType === SearchSubTermTypeEnum.Action) {
            if (!isActionValueValid(searchSubTermUpdatedValue)) {
                return;
            }
            newActionObjectValue = `${searchSubTermUpdatedValue} ${actionObjectToUpdate.dobject}`;
        } else if (searchSubTermType === SearchSubTermTypeEnum.Object) {
            newActionObjectValue = `${actionObjectToUpdate.action} ${searchSubTermUpdatedValue}`;
        }

        // safety-checks
        if (!currentIsActionObjectValueValid(newActionObjectValue, currentQuery)) {
            return;
        }

        // split new action object value to correct action and object values
        const { splitActionValue: newActionValue, splitObjectValue: newObjectValue } = getSplitActionObjectValue(newActionObjectValue);
        
        // update action object fields
        actionObjectToUpdate.action = newActionValue;
        actionObjectToUpdate.dobject = newObjectValue;

        // update action object
        const updatedActionObject: TActionObjectDTO | undefined = await ActionObjectControllerSingleton
            .updateAsync(currentQuery.guid, actionObjectToUpdate);

        // safety-checks
        if (!updatedActionObject) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not update action object.");
            return;
        }

        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }

            // update action object in query
            // return query
            return {
                ...prevQuery,
                actionObjects: [...prevQuery.actionObjects.map((actionObject) => {
                    if (actionObject.id === actionObjectToUpdate.id) {
                        return { ...actionObjectToUpdate };
                    } else {
                        return actionObject;
                    }
                })]
            };
        });
    };

    const updateActionObjectSearchPriorityAsync = async (actionObjectId: number, actionObjectUpdatedSearchPriority: SearchPriorityEnum,
                currentQuery: IQueryDTO): Promise<void> => {
        // get related action object from query
        const actionObjectToUpdate = currentQuery.actionObjects.find((actionObject: TActionObjectDTO) => actionObject.id === actionObjectId);

        // safety-checks
        if (!actionObjectToUpdate) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find action object to update.");
            return;
        }

        // update action object field
        actionObjectToUpdate.searchPriority = actionObjectUpdatedSearchPriority;

        // update action object
        const updatedActionObject: TActionObjectDTO | undefined = await ActionObjectControllerSingleton
            .updateAsync(currentQuery.guid, actionObjectToUpdate);

        // safety-checks
        if (!updatedActionObject) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not update action object.");
            return;
        }

        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }

            // return query
            // update action object in query
            return {
                ...prevQuery,
                actionObjects: [...prevQuery.actionObjects.map((actionObject) => {
                    if (actionObject.id === actionObjectToUpdate.id) {
                        return { ...actionObjectToUpdate };
                    } else {
                        return actionObject;
                    }
                })]
            };
        });
    };

    const deleteActionObjectAsync = async (actionObjectId: number, currentQuery: IQueryDTO): Promise<void> => {
        // get related action object from query
        const actionObjectToDelete = currentQuery.actionObjects.find((actionObject: TActionObjectDTO) => actionObject.id === actionObjectId);

        // safety-checks
        if (!actionObjectToDelete) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find action object to delete.");
            return;
        }

        // delete action object
        const isSuccess: boolean = await ActionObjectControllerSingleton
            .deleteAsync(currentQuery.guid, actionObjectToDelete.id);

        // safety-checks
        if (!isSuccess) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not delete action object.");
            return;
        }

        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }
            
            // return query
            // delete action object from query
            return {
                ...prevQuery,
                actionObjects: [...prevQuery.actionObjects.filter((actionObject) => actionObject.id !== actionObjectId)]
            };
        });
    };

    const onEnterAsync = async (text: string, inputRef: HTMLInputElement, currentQuery: IQueryDTO, 
            currentIsActionObjectValueValid: (actionObjectValue: string, currentQuery: IQueryDTO) => boolean,
            addActionObjectHandlerAsync: (action: string, object: string, currentQuery: IQueryDTO) => Promise<void>): Promise<void> => {
        // safety-checks
        if (!currentIsActionObjectValueValid(text, currentQuery)) {
            return;
        }

        // split text
        const {
            splitActionValue: splitTextActionValue,
            splitObjectValue: splitTextObjectValue
        } = getSplitActionObjectValue(text);

        // add action object
        await addActionObjectHandlerAsync(splitTextActionValue, splitTextObjectValue, currentQuery);

        // empty add action object input
        inputRef.value = "";
    };

    const getActionObjectHitsCount = (currentQuery: IQueryDTO, actionObject: TActionObjectDTO): number => {
        // safety-checks
        if (!currentQuery.searchTermHitsCounts || !currentQuery.searchTermHitsCounts.hitsCountsPerSearchTermId) {
            // return 0
            return 0;
        }

        // get action object hits count if exists otherwise return 0
        return currentQuery.searchTermHitsCounts.hitsCountsPerSearchTermId[`sao${actionObject.id}`] ? currentQuery.searchTermHitsCounts.hitsCountsPerSearchTermId[`sao${actionObject.id}`] : 0;
    };

    return (
        <div className={styles.actionObjectsContainer}>
            <p className={styles.title}>Functions</p>
            <FindestTextBox
                extraClassName={styles.inputField}
                placeholder={"+ action object <Enter>"}
                onEnter={async (text: string, inputRef: HTMLInputElement) => { await onEnterAsync(text, inputRef, query, isActionObjectValueValid, addActionObjectAsync); }} />
            {query.actionObjects.map((actionObject) => {
                return (
                    <ActionObject
                        key={actionObject.id}
                        actionObject={actionObject}
                        updateActionObjectSearchPriorityAsync={async (actionObjectId: number, actionObjectUpdatedSearchPriority: SearchPriorityEnum) => { updateActionObjectSearchPriorityAsync(actionObjectId, actionObjectUpdatedSearchPriority, query); }}
                        deleteActionObjectAsync={async (actionObjectId: number) => { deleteActionObjectAsync(actionObjectId, query); }}
                        updateActionObjectValueAsync={async (actionObjectId: number, searchSubTermUpdatedValue: string, searchSubTermType: SearchSubTermTypeEnum) => { updateActionObjectValueAsync(actionObjectId, searchSubTermUpdatedValue, searchSubTermType, query, isActionObjectValueValid) ;}} 
                        query={query}
                        setQuery={setQuery}
                        hitsCount={getActionObjectHitsCount(query, actionObject)}
                        isSearchTermPriorityDropdown={isSearchTermPriorityDropdown} />
                );
            })}
        </div>
    );
};
