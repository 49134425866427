import React, { useEffect } from "react";
import { useMaintenanceStatus } from "Hooks";
import { DateHelperSingleton } from "Helpers";
import styles from "./maintenanceStatusBox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

type TMaintenanceBoxProps = {
    isUnderMaintenance: boolean;
    setIsUnderMaintenance: (isUnderMaintenance: boolean) => void;
};

export const MaintenanceBox: React.FC<TMaintenanceBoxProps> = ({ isUnderMaintenance, setIsUnderMaintenance }) => {
    const { maintenanceData } = useMaintenanceStatus();

    useEffect(() => {
        if (maintenanceData && maintenanceData.length > 0) {
            const currentDate = DateHelperSingleton.getCurrentDateAsUtc();

            const isUnderMaintenance = maintenanceData.some(status => {
                const startDate = new Date(status.startsAt);
                const endDate = status.endsAt ? new Date(status.endsAt) : null;
                return (startDate <= currentDate && (endDate ? currentDate <= endDate : true));
            });
            setIsUnderMaintenance(isUnderMaintenance);
        }
    }, [maintenanceData, isUnderMaintenance]);

    return (
        <>
            {maintenanceData && maintenanceData.length > 0 ? (
                <div className={styles.maintenanceBox}>
                    <div className={styles.iconWrapper}>
                        <FontAwesomeIcon icon={faWarning} color="black" />
                    </div>
                    <div className={styles.maintenanceContent}>
                        {maintenanceData.map(status => (
                            <div key={status.id} className={styles.maintenanceItem}>
                                <p className={styles.maintenanceText}>
                                    Due to maintenance activity, your <strong>
                                    Universe {isUnderMaintenance ? "is currently unavailable between" : "will be unavailable from"} {DateHelperSingleton.formatUTCDate(status.startsAt)} to {status.endsAt ? DateHelperSingleton.formatUTCDate(status.endsAt) : "unknown"}
                                    </strong>
                                </p>
                            </div>
                        ))}
                        <a target="_blank" href="https://status.findest.com" className={styles.moreInformation} rel="noreferrer">{isUnderMaintenance ? "Check the current status" : "More information"}</a>
                    </div>
                </div>
            ) : null}
        </>
    );
};
