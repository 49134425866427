// node_modules
import { faArrowLeftLong, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Dispatch, FC, useState } from "react";
// Components
import { Dropdown, FindestButton, ForceDirectedGraphViewMenu } from "Components";
import { ForceDirectedGraphView } from "../ForceDirectedGraphView";
import { PackGraphView } from "../PackGraphView";
// Enums
import { EntityTypeEnum, GraphViewContainerTypeEnum, StudyTypeEnum } from "Enums";
// Types
import { TDataObject, TOption, TTypeGraphNodeDTO } from "Types";
// Styles
import commonDropdownStyles from "Styles/Common/dropdown.module.scss";
import styles from "./universeOverviewDetails.module.scss";

type TOverviewDetailsProps = {
    goBackHandler: () => void,
    graphType: "Link" | "Pack",
    setOverviewDetailsPageType: Dispatch<React.SetStateAction<"Link" | "Pack" | null>>,
    data: TDataObject,
    packData: TTypeGraphNodeDTO[] | undefined,
}

export const OverviewDetails: FC<TOverviewDetailsProps> = ({ setOverviewDetailsPageType, goBackHandler, graphType, data, packData }: TOverviewDetailsProps) => {
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<TOption<EntityTypeEnum | StudyTypeEnum>[]>([]);
    const [searchKeyword, setSearchKeyword] = useState<string>("");

    const setSelectedOption = (option: TOption<string>) => {
        setOverviewDetailsPageType(option.value as ("Link" | "Pack"));
    };

    // Render
    return (
        <div className={`${styles.universeOverviewDetails} ${styles[graphType.toLowerCase()]}`}>
            <div className={styles.header}>
                <div><FindestButton extraClassName={styles.goBackButton} onClick={goBackHandler} leftIconName={faArrowLeftLong} buttonType="secondary" title="Back to overview" /></div>
                <Dropdown
                        isEditable
                        selectedOption={graphType === "Link" ? { title: "Relations graph", value: "Link" } : { title: "Page type breakdown", value: "Pack" }}
                        handleOptionSelect={(option) => setSelectedOption(option)}
                        options={
                            [{ group: "1", options: [{ title: "Relations graph", value: "Link" }, { title: "Page type breakdown", value: "Pack" }] }]
                        }
                        className={commonDropdownStyles.commonDropdown}
                        classNameSelect={commonDropdownStyles.grayDropdownSelect}
                        extraClassNames={{
                            groupedList: {
                                groupTitle: styles.graphTypeDropdownGroupTitle
                            }
                        }}
                    />
                <div className={styles.graphActions}>
                    <ForceDirectedGraphViewMenu 
                        graphType={graphType}
                        selectedFilterOptions={selectedFilterOptions}
                        setSelectedFilterOptions={setSelectedFilterOptions}
                        setSearchKeyword={setSearchKeyword} 
                        textFilterPlaceholder="Search in the graph..."
                        textFilterLeftIcon={faSearch}
                        filtersTitle="Filters"
                        filtersLeftIcon={faFilter} />
                </div>
            </div>
            <div className={`${styles.body} ${styles[graphType.toLowerCase()]}`}>
                <div className={styles.childrenContainer}>
                    {graphType === "Link" ? (
                        <ForceDirectedGraphView 
                            data={data} 
                            searchKeyword={searchKeyword} 
                            selectedFilterOptions={selectedFilterOptions} 
                            containerType={GraphViewContainerTypeEnum.Overview} />
                    ) : (
                        <PackGraphView data={packData} searchKeyword={searchKeyword} />
                    )}
                </div>
            </div>
        </div>
    );
};