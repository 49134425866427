// Node modules
import axios from "axios";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TIdNameTypeObjectType } from "Types";

export class PinController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/pin`;

    public async getAsync(): Promise<TIdNameTypeObjectType[]> {
        try {
            const result = await axios.get<TIdNameTypeObjectType[]>(this._resourcePath);
            
            if(result && result.data) {
                return result.data;
            }

            return [];
        } catch {
            return [];
        }
    }

    public async isPinnedAsync(objectId: string): Promise<boolean> {
        try {
            const result = await axios.get<boolean>(`${this._resourcePath}/${objectId}`);
            
            if(result) {
                return result.data;
            }

            return false;
        } catch {
            return false;
        }
    }

    public async addAsync(objectId: string, objectType: ObjectTypeEnum): Promise<boolean> {
        try {
            const result = await axios.post(`${this._resourcePath}/${objectType}/${objectId}`);
            return !!result;
        } catch {
            return false;
        }
    }

    public async removeAsync(objectId: string): Promise<boolean> {
        try {
            const result = await axios.delete(`${this._resourcePath}/${objectId}`);
            return !!result;
        } catch {
            return false;
        }
    }
}

export const PinControllerSingleton = new PinController();