// node_modules
import MarkdownIt from "markdown-it";
import StateBlock from "markdown-it/lib/rules_block/state_block";
// Extensions
import { commonCustomBlockExtension } from "./CommonCustomBlockExtension";
// Enums
import { CustomBlockIdAttributeEnum, CustomBlockMarkerEnum, CustomBlockSpecialAttrsEnum, CustomDOMTag, CustomMarkdownSeparatorEnum, OtherMarkdownCustomBlockNameEnum, SpecialBlockClassNameEnum, TopDepthMarkdownCustomBlockNameEnum } from "Enums";
// Constants
import { EditorConstants } from "Constants";

export function intakeSheetConfirmationBlockExtension(md: MarkdownIt): void {
    const parseIntakeSheetConfirmationBlockContent = (state: StateBlock, startLine: number, content: string, closeIntakeSheetConfirmationBlockMarker: string): boolean => {
        // Create a variable to store the next line index
        const nextLine = startLine;

        // Check if the current line contains the end marker
        let haveEndMarker = false;
        const endMarkerIndex = content.indexOf(closeIntakeSheetConfirmationBlockMarker);
        if(endMarkerIndex >= 0) {
            // If the end marker was found then remove it from the full content
            // and set the haveEndMarker flag to true
            content = content.slice(0, endMarkerIndex);
            haveEndMarker = true;
        }
        
        // Update the line of the state with the last line we checked and depending
        // on if we found the end marker add one to the line index
        state.line = nextLine + (haveEndMarker ? 1 : 0);

        // If the end marker was found then add it to the tokens in the state
        if(haveEndMarker) {
            /// Add a token to indicate the start of the intake sheet confirmation block
            const startToken = state.push(`${TopDepthMarkdownCustomBlockNameEnum.IntakeSheetConfirmation}_open`, `${CustomDOMTag.IntakeSheetConfirmation}`, 1);
            startToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.IntakeSheetConfirmation}`],
                [`${CustomBlockIdAttributeEnum.IntakeSheetConfirmation}`, `${crypto.randomUUID()}`],
                ["selected", "false"]
            ];

            // Increase the depth level of the state
            state.level++;

            // add intake sheet confirmation title token
            const intakeSheetConfirmationTitleToken = state.push(`${OtherMarkdownCustomBlockNameEnum.Title}_open`, `${CustomDOMTag.Title}`, 1);
            intakeSheetConfirmationTitleToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.Title}`]
            ];

            // add intake sheet confirmation title text token
            const intakeSheetConfirmationTitleTextToken = state.push("inline", "", 0);
            intakeSheetConfirmationTitleTextToken.content = EditorConstants.INTAKE_SHEET_CONFIRMATION_TITLE_BLOCK_TEXT;
            intakeSheetConfirmationTitleTextToken.map = [startLine, state.line];
            intakeSheetConfirmationTitleTextToken.children = [];

            state.push(`${OtherMarkdownCustomBlockNameEnum.Title}_close`, `${CustomDOMTag.Title}`, -1);

            // try to get email address of the user that could have accepted the intake sheet
            const bracketIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_INTAKE_SHEET_CONFIRMATION_EMAIL_ADDRESS);
            let emailAddress: string | undefined = undefined;
            // if bracket was found
            if (bracketIndex >= 0) {
                // try to get the email address
                emailAddress = content.slice(bracketIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_INTAKE_SHEET_CONFIRMATION_EMAIL_ADDRESS, bracketIndex + 1));
            }  

            // try to get the date when the user could have accepted the intake sheet
            const lessThanIndex = content.indexOf(CustomMarkdownSeparatorEnum.OPEN_INTAKE_SHEET_CONFIRMATION_DATE);
            let date: string | undefined = undefined;
            // if less than was found
            if (lessThanIndex >= 0) {
                // try to get the date
                date = content.slice(lessThanIndex + 1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_INTAKE_SHEET_CONFIRMATION_DATE, lessThanIndex + 1));
            }
            
            // if both the email address and date were found
            if (emailAddress && date) {
                // create intake sheet confirmation accepted content
                // add intakeSheetConfirmationAccepted token
                const intakeSheetConfirmationAcceptedToken = state.push(`${OtherMarkdownCustomBlockNameEnum.IntakeSheetConfirmationAccepted}_open`, `${CustomDOMTag.IntakeSheetConfirmationAccepted}`, 1);
                intakeSheetConfirmationAcceptedToken.attrs = [
                    ["class", `${SpecialBlockClassNameEnum.IntakeSheetConfirmationAccepted}`],
                    [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedEmail}`, `${emailAddress}`],
                    [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedDate}`, `${date}`]
                ];

                // Increase the depth level of the state
                state.level++;

                // add intake sheet confirmation accepted title token
                const intakeSheetConfirmationAcceptedTitleToken = state.push(`${OtherMarkdownCustomBlockNameEnum.Title}_open`, `${CustomDOMTag.Title}`, 1);
                intakeSheetConfirmationAcceptedTitleToken.attrs = [
                    ["class", `${SpecialBlockClassNameEnum.Title}`]
                ];

                // add intake sheet confirmation accepted title text token
                const intakeSheetConfirmationAcceptedTitleTextToken = state.push("inline", "", 0);
                intakeSheetConfirmationAcceptedTitleTextToken.content = EditorConstants.INTAKE_SHEET_CONFIRMATION_ACCEPTED_TITLE_BLOCK_TEXT;
                intakeSheetConfirmationAcceptedTitleTextToken.map = [startLine, state.line];
                intakeSheetConfirmationAcceptedTitleTextToken.children = [];

                state.push(`${OtherMarkdownCustomBlockNameEnum.Title}_close`, `${CustomDOMTag.Title}`, -1);

                // add paragraph token
                state.push("paragraph_open", "p", 1);

                // Add the content of the paragraph block
                const paragraphTextToken = state.push("inline", "", 0);
                paragraphTextToken.content = `${EditorConstants.INTAKE_SHEET_CONFIRMATION_ACCEPTED_PARAGRAPH_BLOCK_PARTIAL_TEXT} ${emailAddress} on ${date}.`;
                paragraphTextToken.map = [startLine, state.line];
                paragraphTextToken.children = [];

                state.push("paragraph_close", "p", -1);

                // add button token
                state.push(`${OtherMarkdownCustomBlockNameEnum.Button}_open`, `${CustomDOMTag.Button}`, 1);

                // Add the content of the button block
                const buttonTextToken = state.push("inline", "", 0);
                buttonTextToken.content = EditorConstants.INTAKE_SHEET_CONFIRMATION_ACCEPTED_BUTTON_BLOCK_TEXT;
                buttonTextToken.map = [startLine, state.line];
                buttonTextToken.children = [];

                state.push(`${OtherMarkdownCustomBlockNameEnum.Button}_close`, `${CustomDOMTag.Button}`, -1);

                // Decrease the depth level of the state
                state.level--;

                state.push(`${OtherMarkdownCustomBlockNameEnum.IntakeSheetConfirmationAccepted}_close`, `${CustomDOMTag.IntakeSheetConfirmationAccepted}`, -1);
            } else {
                // create intake sheet confirmation not accepted content
                // add intakeSheetConfirmationNotAccepted token
                const intakeSheetConfirmationNotAcceptedToken = state.push(`${OtherMarkdownCustomBlockNameEnum.IntakeSheetConfirmationNotAccepted}_open`, `${CustomDOMTag.IntakeSheetConfirmationNotAccepted}`, 1);
                intakeSheetConfirmationNotAcceptedToken.attrs = [
                    ["class", `${SpecialBlockClassNameEnum.IntakeSheetConfirmationNotAccepted}`]
                ];

                // Increase the depth level of the state
                state.level++;

                // add paragraph token
                state.push("paragraph_open", "p", 1);

                // Add the content of the paragraph block
                const paragraphTextToken = state.push("inline", "", 0);
                paragraphTextToken.content = EditorConstants.INTAKE_SHEET_CONFIRMATION_NOT_ACCEPTED_PARAGRAPH_BLOCK_TEXT;
                paragraphTextToken.map = [startLine, state.line];
                paragraphTextToken.children = [];

                state.push("paragraph_close", "p", -1);

                // add button token
                state.push(`${OtherMarkdownCustomBlockNameEnum.Button}_open`, `${CustomDOMTag.Button}`, 1);

                // Add the content of the button block
                const buttonTextToken = state.push("inline", "", 0);
                buttonTextToken.content = EditorConstants.INTAKE_SHEET_CONFIRMATION_NOT_ACCEPTED_BUTTON_BLOCK_TEXT;
                buttonTextToken.map = [startLine, state.line];
                buttonTextToken.children = [];

                state.push(`${OtherMarkdownCustomBlockNameEnum.Button}_close`, `${CustomDOMTag.Button}`, -1);

                // Decrease the depth level of the state
                state.level--;

                state.push(`${OtherMarkdownCustomBlockNameEnum.IntakeSheetConfirmationNotAccepted}_close`, `${CustomDOMTag.IntakeSheetConfirmationNotAccepted}`, -1);
            }

            // Decrease the depth level of the state
            state.level--;
            
            // Add a token to indicate the end of the image reference block
            state.push(`${TopDepthMarkdownCustomBlockNameEnum.IntakeSheetConfirmation}_close`, `${CustomDOMTag.IntakeSheetConfirmation}`, -1);
        }
        
        return haveEndMarker;
    };

    // execute the intake sheet confirmation block parsing after the study reference block parsing
    md.block.ruler.after(
        `${TopDepthMarkdownCustomBlockNameEnum.StudyReference}`, 
        `${TopDepthMarkdownCustomBlockNameEnum.IntakeSheetConfirmation}`, 
        (state: StateBlock, startLine: number) => commonCustomBlockExtension(state, startLine, `${CustomBlockMarkerEnum.IntakeSheetConfirmation}`, parseIntakeSheetConfirmationBlockContent)
    );
}