// React
import { FC, useContext, useMemo, useState } from "react";
// Styles
import styles from "./settingsPage.module.scss";
// Shared Components
import { Tabs } from "Components/Shared";
// Constants
import { FeatureToggleConstants } from "Constants";
// Types
import { UsersSettings } from "./Users";
import { Galaxies } from "./Galaxies";
import { ObjectSettings } from "./ObjectSettings";
import { PersonalInformation } from "./PersonalInformation";
import { TTab } from "Types";
import { UserHelperSingleton } from "Helpers";
import { AuthContext } from "Providers";

const enum SettingsTabsEnum {
    UsersTab = "Users",
    GalaxiesTab = "Galaxies",
    StudiesAndEntitiesTab = "Studies and Entities",
    PersonalInformationTab = "Personal Information",
}

export const SettingsPage: FC = () => {
    // Context
    const { auth } = useContext(AuthContext);

    // Memo needed for the tabs
    const possibleSettingsTabs: TTab[] = useMemo(() => {
        const settingsTabs: TTab[] = [];
        if(FeatureToggleConstants.AdminUserManagement && UserHelperSingleton.hasAccessToUsers(auth)) {
            settingsTabs.push({ 
                name: SettingsTabsEnum.UsersTab,
            });
        }
        if(FeatureToggleConstants.GalaxiesManagement) {
            settingsTabs.push({
                name: SettingsTabsEnum.GalaxiesTab
            });
        }
        if(UserHelperSingleton.hasAccessToShared(auth) || UserHelperSingleton.hasAccessToAllLocked(auth) 
            || UserHelperSingleton.hasAccessToArchive(auth) || UserHelperSingleton.hasAccessToTypeManagement(auth)) {
            settingsTabs.push({
                name: SettingsTabsEnum.StudiesAndEntitiesTab
            });
        }
        settingsTabs.push({
            name: SettingsTabsEnum.PersonalInformationTab
        });
        return settingsTabs;
    }, [auth]);

    // State
    const [activeTab, setActiveTab] = useState<string>(possibleSettingsTabs[0].name);

    return (
        <div className={styles.settings}>
            <Tabs tabs={possibleSettingsTabs} onSelectedTabChange={setActiveTab} extraClassNames={{ container: styles.tabsContainer, tab: styles.tab}} />
            {activeTab === SettingsTabsEnum.UsersTab && <UsersSettings /> }
            {activeTab === SettingsTabsEnum.GalaxiesTab && <Galaxies /> }
            {activeTab === SettingsTabsEnum.StudiesAndEntitiesTab && <ObjectSettings /> }
            {activeTab === SettingsTabsEnum.PersonalInformationTab && <PersonalInformation />}
        </div>
    );
};