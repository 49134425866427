// node_modules
import MarkdownIt from "markdown-it";
import StateBlock from "markdown-it/lib/rules_block/state_block";
// Extensions
import { commonCustomBlockExtension } from "./CommonCustomBlockExtension";
// Enums
import { CustomBlockIdAttributeEnum, CustomBlockMarkerEnum, CustomDOMTag, CustomMarkdownSeparatorEnum, OtherMarkdownCustomBlockNameEnum, SpecialBlockClassNameEnum, TopDepthMarkdownCustomBlockNameEnum } from "Enums";

export function highlightReferenceBlockExtension(md: MarkdownIt): void {
    const parseHighlightReferenceBlockContent = (state: StateBlock, startLine: number, content: string, closeHighlightReferenceBlockMarker: string, highlightId?: string): boolean => {
        // safety-checks
        if (!highlightId) {
            return false;
        }
        
        // Create a variable to store the next line index
        const nextLine = startLine;

        // Remove the highlight id from the full content
        content = content.slice(38);
        const highlightUrl = content.slice(1, content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_HIGHLIGHT_REFERENCE_URL));
        content = content.slice(content.indexOf(CustomMarkdownSeparatorEnum.CLOSE_HIGHLIGHT_REFERENCE_URL) + 1);

        // Check if the current line contains the end marker
        let haveEndMarker = false;
        const endMarkerIndex = content.indexOf(closeHighlightReferenceBlockMarker);
        if(endMarkerIndex >= 0) {
            // If the end marker was found then remove it from the full content
            // and set the haveEndMarker flag to true
            content = content.slice(0, endMarkerIndex);
            haveEndMarker = true;
        }
        
        // Update the line of the state with the last line we checked and depending
        // on if we found the end marker add one to the line index
        state.line = nextLine + (haveEndMarker ? 1 : 0);

        // If the end marker was found then add it to the tokens in the state
        if(haveEndMarker) {
            // Add a token to indicate the start of the highlight reference block
            const startToken = state.push(`${TopDepthMarkdownCustomBlockNameEnum.HighlightReference}_open`, `${CustomDOMTag.HighlightReference}`, 1);
            startToken.attrs = [
                ["class", `${SpecialBlockClassNameEnum.HighlightReference}`],
                [`${CustomBlockIdAttributeEnum.HighlightReference}`, highlightId],
                ["selected", "false"],
                ["id", `${crypto.randomUUID()}`]
            ];

            // Increase the depth level of the state
            state.level++;

            // add paragraph token
            state.push("paragraph_open", "p", 1);

            // Add the content of the highlight reference block
            const textToken = state.push("inline", "", 0);
            textToken.content = content;
            textToken.map = [startLine, state.line];
            textToken.children = [];

            state.push("paragraph_close", "p", -1);

            // Add a token to indicate the start of the highlight link
            const linkToken = state.push(`${OtherMarkdownCustomBlockNameEnum.ReferenceLink}_open`, `${CustomDOMTag.ReferenceLink}`, 1);
            linkToken.attrs = [
                ["href", highlightUrl], 
                ["target", "_blank"],
                ["rel", "noopener noreferrer"],
                ["class", `${SpecialBlockClassNameEnum.ReferenceLink}`]
            ];
            
            // Add [Ref] as the text of the link
            const linkTextToken = state.push("inline", "", 0);
            linkTextToken.content = "[Ref]";
            linkTextToken.map = [startLine, state.line];
            linkTextToken.children = [];

            // Add a token to indicate the end of the highlight link
            state.push(`${OtherMarkdownCustomBlockNameEnum.ReferenceLink}_close`, `${CustomDOMTag.ReferenceLink}`, -1);

            // Decrease the depth level of the state
            state.level--;
            
            // Add a token to indicate the end of the highlight reference block
            state.push(`${TopDepthMarkdownCustomBlockNameEnum.HighlightReference}_close`, `${CustomDOMTag.HighlightReference}`, -1);
            
        }
        
        return haveEndMarker;
    };

    // Execute the highlight reference block functions after the heading functions
    md.block.ruler.after(
        "heading", 
        `${TopDepthMarkdownCustomBlockNameEnum.HighlightReference}`, 
        (state: StateBlock, startLine: number) => commonCustomBlockExtension(state, startLine, `${CustomBlockMarkerEnum.HighlightReference}`, parseHighlightReferenceBlockContent)
    );
}