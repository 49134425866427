/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
// node_modules
import * as commands from "prosemirror-commands";
import * as history from "prosemirror-history";
import * as schemaList from "prosemirror-schema-list";
import { EditorState, TextSelection, Transaction } from "prosemirror-state";
import { Node, ResolvedPos } from "prosemirror-model";
// Schemas
import { findestSchema, stopOnTypes } from "./FindestDefaultSchema";
// Helpers
import { ProseMirrorHelperSingleton, ProseMirrorTablesHelperSingleton } from "Helpers";
// Types
import { TProseMirrorNodeData } from "Types";
// Enums
import { CustomBlockIdAttributeEnum, EditorTableDOMTag, OtherMarkdownCustomBlockNameEnumStrings, TopDepthMarkdownCustomBlockNameEnumStrings } from "Enums";
// Constants
import { ProseMirrorConstants } from "Constants";

// chain commands
function chainCommands(..._args: any[]) {
    for (var _len = arguments.length, commands = new Array(_len), _key = 0; _key < _len; _key++) {
        commands[_key] = arguments[_key];
    }

    return function(state: any, dispatch: any, view: any) {
        for (let i = 0; i < commands.length; i++) {
            if (commands[i](state, dispatch, view)) return true;
        }

        return false;
    };
}

function getRowIndexWhereCursorIs(editorState: EditorState): number | undefined {
    // get table row from selection
    const tableRow: TProseMirrorNodeData = ProseMirrorHelperSingleton
        .getFirstNodeDataWithTypesFromSelection(
            editorState,
            [EditorTableDOMTag.Tr]
        );

    // get table from selection
    const table: TProseMirrorNodeData = ProseMirrorHelperSingleton
        .getFirstNodeDataWithTypesFromSelection(
            editorState,
            [EditorTableDOMTag.Table]
        );

    // safety-checks
    if (!table || !table.node || !table.depth ||
        !tableRow || !tableRow.node || !tableRow.depth) {
        return undefined;
    }

    // init row index (stores the index of the row in the table row)
    let rowIndex: number | undefined = undefined;
    let index = 0;
    // go through all descendants of the table
    table.node.descendants((tableNode: Node) => {
        // if descendant is a table row
        if (tableNode.type.name === EditorTableDOMTag.Tr) {
            // if table row id matches the id of the table row where the cursor is
            if (tableNode.attrs.id === tableRow.node?.attrs.id) {
                // set row index
                rowIndex = index;
            }

            // increase index
            index++;

            // return false to stop going through descendants of the table row
            return false;
        }
    });

    // return row index
    return rowIndex;
}

// command to go to the next row if selection is in table
function goToNextRowCmd(editorState: EditorState, dispatch?: ((tr: Transaction) => void) | undefined): boolean {
    // get table from selection
    const table: TProseMirrorNodeData = ProseMirrorHelperSingleton
        .getFirstNodeDataWithTypesFromSelection(
            editorState,
            [EditorTableDOMTag.Table]
        );
    
    // get row index where cursor is
    const rowIndex: number | undefined = getRowIndexWhereCursorIs(editorState);
    
    // safety-checks
    if (!dispatch || !table.node || !table.depth ||
            rowIndex === undefined) {
        // return false (command was not executed)
        return false;
    }

    // init boolean to store if command was executed
    let commandExecuted = false;
    // go through all descendants of the doc
    editorState.doc.descendants((docNode: Node, docNodePos: number) => {
        // if descendant is a table and the id matches the id of the table
        if (docNode.type.name === EditorTableDOMTag.Table &&
            docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] !== undefined &&
            docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] === table.node?.attrs[`${CustomBlockIdAttributeEnum.Table}`]) {
            let index = 0;
            // go through all descendants of the table
            docNode.descendants((tableNode: Node, tableNodePos: number) => {
                // if descendant is a table row
                if (tableNode.type.name === EditorTableDOMTag.Tr) {
                    // if index is equal to row index + 1
                    if (index === rowIndex + 1) {
                        // go through all descendants of the table row
                        tableNode.descendants((tableRowNode: Node) => {
                            // if descendant is a table cell (td or th)
                            if (tableRowNode.type.name === EditorTableDOMTag.Td ||
                                    tableRowNode.type.name === EditorTableDOMTag.Th) {
                                // add cursor (new selection) at the beginning of the cell
                                const tr = editorState.tr
                                    .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                                    .setSelection(
                                        new TextSelection(editorState.doc.resolve(docNodePos + tableNodePos + 4))
                                    );

                                // dispatch transaction
                                dispatch(tr);

                                // command was executed
                                commandExecuted = true;

                                // return false (stop going through descendants, we found the first cell)
                                return false;
                            }
                        });
                    }

                    // increase row index
                    index++;
                }
            });
        }
    });


    // return false (command was not executed)
    return commandExecuted;
}

function backspaceInsideTableCommand(state: EditorState, _: (tr: Transaction) => void): boolean {
    const isInTable: boolean = ProseMirrorTablesHelperSingleton.isSelectionInTable(state);
    const selectionParent: Node = state.selection.$from.parent;
    const isCellEmpty: boolean = selectionParent.content.size === 0;

    // Get the parents of the nodes at the start and end of the selection
    const parentOfStartOfSelection: Node = state.selection.$from.parent;
    const parentOfEndOfSelection: Node = state.selection.$to.parent;

    // If the current selection is not in a table then go on with the default behavior
    if(!isInTable) return false;

    // If the current selection is in a table and the cell is empty 
    // or if parents are not the same then stop the deletion
    if(isCellEmpty ||
        parentOfStartOfSelection !== parentOfEndOfSelection) return true;

    // get pos of parent node first child
    const pos = state.selection.$from.before(-1);
    // get resolved pos being the start of parent node first child
    const resolvedPos = state.doc.resolve(pos + 2);

    // Check if the selection is at the end of the cell
    const isAtStartOfNode = state.selection.$from.pos === resolvedPos.pos;

    // If selection is at the end of the parent then stop the deletion
    if(isAtStartOfNode) return true;

    // Go on with the default behavior
    return false;
}

function deleteInsideTableCommand(state: EditorState, _: (tr: Transaction) => void): boolean {
    const isInTable: boolean = ProseMirrorTablesHelperSingleton.isSelectionInTable(state);
    const selectionParent: Node = state.selection.$from.parent;
    const isCellEmpty: boolean = selectionParent.content.size === 0;

    // Get the parents of the nodes at the start and end of the selection
    const parentOfStartOfSelection: Node = state.selection.$from.parent;
    const parentOfEndOfSelection: Node = state.selection.$to.parent;

    // If the current selection is not in a table then go on with the default behavior
    if(!isInTable) return false;

    // If the current selection is in a table and the cell is empty 
    // or if parents are not the same then stop the deletion
    if(isCellEmpty ||
        parentOfStartOfSelection !== parentOfEndOfSelection) return true;

    // get pos of parent node first child
    const pos = state.selection.$from.before(-1);
    // get content length of parent node first child
    const contentLength = selectionParent.child(0).textContent.length;
    // get resolved pos being the end of parent node first child
    const resolvedPos = state.doc.resolve(pos + contentLength + 2);

    // Check if the selection is at the end of the cell
    const isAtEndOfNode = state.selection.$to.pos === resolvedPos.pos;
    
    // If selection is at the end of the parent then stop the deletion
    if(isAtEndOfNode) return true;

    // Go on with the default behavior
    return false;
}

// function to handle creation of a paragraph when selection is in a custom block
function createParagraphNearCustomBlocksCmd(state: EditorState, dispatch?: ((tr: Transaction) => void) | undefined): boolean {
    // safety-checks
    if (!dispatch) { return false; }

    // get $from ResolvedPos from selection
    const { $from } = state.selection;

    // get current depth from ResolvedPos $from
    let depth = $from.depth;

    // get current node from depth
    let currentNode = $from.node(depth);
    // get current node parent type name
    const currentNodeParentTypeName = $from.node(depth - 1).type.name;

    // if node is a custom block
    if (TopDepthMarkdownCustomBlockNameEnumStrings.includes(currentNodeParentTypeName) ||
        TopDepthMarkdownCustomBlockNameEnumStrings.includes(currentNode.type.name) ||
        OtherMarkdownCustomBlockNameEnumStrings.includes(currentNodeParentTypeName) ||
        OtherMarkdownCustomBlockNameEnumStrings.includes(currentNode.type.name)) {
            
        // init currentNodeEndPos
        let currentNodeEndPos: number | undefined = undefined;

        // do not find the top depth markdown custom block if current node parent type is the doc node
        if (currentNodeParentTypeName !== "doc") { 
            // get currentNode's parent node
            let nextParentNode: Node = $from.node(depth - 1);
            // while currentNode's parent node is not a doc node
            while (nextParentNode.type.name !== "doc") {
                // set currentNode to nextParentNode
                currentNode = nextParentNode;
                // get nextParentNode's parent node and update depth
                depth = depth - 1;
                nextParentNode = $from.node(depth);
            }
            currentNodeEndPos = $from.after(depth + 1);
        } else {
            currentNodeEndPos = $from.after(depth);
        }

        // create a new paragraph node
        let tr = state.tr
            .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
            .replaceWith(
                currentNodeEndPos,
                currentNodeEndPos,
                findestSchema.nodes.paragraph.create()
            );

        // get the new paragraph node's ResolvedPos
        const resolvedPos: ResolvedPos = tr.doc.resolve(currentNodeEndPos + 1);
        // create a new TextSelection from the new paragraph node's ResolvedPos (cursor at the beginning of the new paragraph)
        const nodeSelection = new TextSelection(resolvedPos);
        // set the new TextSelection as the selection
        tr = tr
            .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
            .setSelection(nodeSelection);

        // dispatch the transaction
        dispatch(tr);

        // command executed
        return true;
    } else {
        // command not executed
        return false;
    }
}

// build custom keymaps
const buildKeymaps = () => {
    // init keymaps object
    const keymaps: {[key: string]: any} = {};

    // use control/command Z to undo in the histroy
    keymaps["Mod-z"] = history.undo;
    // use control/command Y or shift + control/command Z
    keymaps["Shift-Mod-z"] = history.redo;
    keymaps["Mod-y"] = history.redo;
    // use backspace or delete to remove selections

    let cmd = chainCommands(
        backspaceInsideTableCommand,
        commands.deleteSelection, 
        commands.joinBackward,
        commands.selectNodeBackward
    );
    keymaps["Backspace"] = cmd;
    cmd = chainCommands(
        deleteInsideTableCommand,
        commands.deleteSelection, 
        commands.joinForward,
        commands.selectNodeForward
    );
    keymaps["Delete"] = cmd;

    // use shift/control/command + enter to create a a paragraph after the current selection
    cmd = chainCommands(goToNextRowCmd, 
        createParagraphNearCustomBlocksCmd, commands.createParagraphNear, 
        commands.liftEmptyBlock, schemaList.splitListItem(findestSchema.nodes.list_item), commands.splitBlock);
    keymaps["Mod-Enter"] = cmd;
    keymaps["Shift-Enter"] = cmd;

    // use control/command B to toggle the boldness of the current selection or the next text
    cmd = chainCommands(
        stopOnTypes(
            [findestSchema.marks.subscript, findestSchema.marks.superscript]
        ),
        commands.toggleMark(findestSchema.marks.strong)
    );
    keymaps["Mod-b"] = cmd;
    keymaps["Mod-B"] = cmd;   

    // use control/command I to toggle the italics of the current selection or the next text
    cmd = chainCommands(
        stopOnTypes(
            [findestSchema.marks.subscript, findestSchema.marks.superscript]
        ),
        commands.toggleMark(findestSchema.marks.em)
    );
    keymaps["Mod-i"] = cmd;
    keymaps["Mod-I"] = cmd;
    
    // use enter to create a new paragraph
    keymaps["Enter"] = chainCommands(goToNextRowCmd, 
        createParagraphNearCustomBlocksCmd, commands.createParagraphNear, 
        commands.liftEmptyBlock, schemaList.splitListItem(findestSchema.nodes.list_item), commands.splitBlock);
    // use control/command ] to sink the current list item
    keymaps["Mod-["] = schemaList.liftListItem(findestSchema.nodes.list_item);
    // use control/command [ to lift the current list item
    keymaps["Mod-]"] = schemaList.sinkListItem(findestSchema.nodes.list_item);

    // return keymaps
    return keymaps;
};

export const findestKeymaps = buildKeymaps();