import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
// Components
import { ListObjectItem, Popover } from "Components";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Types
import { TOption } from "Types";
// Styles
import styles from "./groupedList.module.scss";

type TGroupedListItemProps<T> = {
    isSelected: boolean,
    handleOptionSelect: (option: TOption<T>) => void,
    handleOptionUnselect?: (option: TOption<T>) => void,
    extraClassNames?: { option?: string, selected?: string },
    option: TOption<T>,
    outsideClickExceptionDataIdentifier?: string
}

export function GroupedListItem<T>({ isSelected, handleOptionSelect, handleOptionUnselect, extraClassNames = {}, option, outsideClickExceptionDataIdentifier }: TGroupedListItemProps<T>) {
    const [optionReference, setOptionReference] = useState<HTMLDivElement | null>(null);
    const [isPreviewPopoverShown, setIsPreviewPopoverShown] = useState<boolean>(false);

    return (
        <>
            <div
                onMouseEnter={option.optionDetails ? () => { setIsPreviewPopoverShown(true); } : undefined}
                onMouseLeave={option.optionDetails ? () => { setIsPreviewPopoverShown(false); } : undefined}
                ref={setOptionReference}
                onClick={isSelected ? undefined : () => { handleOptionSelect(option); }}
                className={`${isSelected ? [styles.selected, extraClassNames.selected ?? ""].join(" ") : ""} ${styles.option} ${extraClassNames.option || ""}`}
                title={option.title}
            >
                {option.title}
                {handleOptionUnselect && isSelected && <FontAwesomeIcon onClick={() => { handleOptionUnselect(option); }} icon={faXmark} />}
            </div>
            {option.optionDetails && (
                <Popover
                    dataIdentifier={outsideClickExceptionDataIdentifier}
                    extraClassName={styles.groupedListOptionDetailsPopover}
                    onMouseEnter={() => { setIsPreviewPopoverShown(true); }}
                    onMouseLeave={() => { setIsPreviewPopoverShown(false); }}
                    key={`${option.title}-${option.value}_optionDetailsComponentPopover`}
                    showInPortal
                    isOpen={isPreviewPopoverShown}
                    placement="right-start"
                    referenceEl={optionReference}
                    popoverOffset={0}
                >
                    <div className={styles.layerSelectionDropdownPreview}>
                        <h4 className={styles.layerPreviewTitle}>Layer Preview</h4>
                        {option.optionDetails.data.map((object) => (
                            <ListObjectItem 
                                key={`${object?.id}_layerSelectionDropdownPreview`}
                                extraClassName={styles.layerPreviewlistItem}
                                iconHasColor 
                                object={object} 
                                icon={ObjectTypeHelperSingleton.getObjectTypeIcon(object.objectType)}
                                onMoreActionsOptionClick={() => { setIsPreviewPopoverShown(false); }}
                                openReferenceModal={option.optionDetails ? option.optionDetails.openReferenceModal : undefined}
                            />
                        ))}
                    </div>
                </Popover>
            )}
        </>
    );
}