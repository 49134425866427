// Types
import { TInitialScoutingServiceTableProps, TLinkGraphDTO, TLinkGraphNodeDTO, TMaturityRadarAssessmentDTO, TMaturityRadarDTO, TRequirementsTableDTO, TRequirementsTableEntryDTO, TRequirementsTableRowDTO, TScoutingServiceTableObject, TTableCellDTO, TUpdateAssessmentScoreDTO } from "Types";
// Helpers
import { LinkGraphHelperSingleton } from "Helpers";
// Enums
import { ObjectTypeEnum } from "Enums";

export class ScoutingServiceTableHelper {
    public onTableCheckboxChange(tableObjectData: (TScoutingServiceTableObject | null)[][], rowIndex: number, columnIndex: number): (TScoutingServiceTableObject | null)[][] {
        const currentObject = tableObjectData[rowIndex][columnIndex];
        if (!currentObject) return tableObjectData;

        // Set isChecked value of current object and all its children
        const setCheckedValueOfObjectAndChildren = (innerCurrentObject: TScoutingServiceTableObject, isChecked: boolean) => {
            innerCurrentObject.isChecked = isChecked;

            if (innerCurrentObject.children) {
                for (const childObject of innerCurrentObject.children) {
                    setCheckedValueOfObjectAndChildren(childObject, isChecked);
                }
            }
        };

        // Set the isChecked value of the currentObject and all its children
        setCheckedValueOfObjectAndChildren(currentObject, !currentObject.isChecked);

        // Check if the currentObject has a parent
        if (currentObject.parent) {
            // Check if any children of the parent are checked
            const isAnyChildChecked = currentObject.parent.children?.some((childObject) => childObject.isChecked === true);

            // If any of the children are checked, check the parent
            if (isAnyChildChecked) {
                currentObject.parent.isChecked = true;
            }
        }

        let newTableObjectData = [...tableObjectData];

        // Renumber the table using the new order and ignoring unchecked objects
        newTableObjectData = this.renumberTable(newTableObjectData);

        // return the new table
        return newTableObjectData;
    }

    public moveUp(tableObjectData: (TScoutingServiceTableObject | null)[][], rowIndex: number, columnIndex: number): (TScoutingServiceTableObject | null)[][] {
        // Get the currentObject 
        const currentObject = tableObjectData[rowIndex][columnIndex];
        if (!currentObject) return tableObjectData;

        let newTableObjectData: (TScoutingServiceTableObject | null)[][] = [...tableObjectData];

        // Check if the currentObject has a parent
        if (!currentObject.parent) {
            // Check if the currentobject is the first object in the table
            const firstTableObject = tableObjectData[0][0];
            // If the currentObject is the first object in the table, do noting
            if (!firstTableObject || currentObject === firstTableObject) return tableObjectData;
        } else {
            // If the current object has a parent then change the order of the children of the parent

            // Get the position of the current object in it's parent's children array
            const currentObjectIndexInParentChildrenArray = currentObject.parent.children?.indexOf(currentObject);

            // If the currentObject is not in the parent's children array, return
            if (currentObjectIndexInParentChildrenArray === undefined || !currentObject.parent.children) return tableObjectData;

            // Check if the currentObject is the first child of the parent
            if (currentObjectIndexInParentChildrenArray === 0) return tableObjectData;

            // Get the object above the currentObject
            const objectAboveCurrentObject = currentObject.parent.children[currentObjectIndexInParentChildrenArray - 1];

            // Swap the currentObject with the object above the currentObject
            currentObject.parent.children[currentObjectIndexInParentChildrenArray] = objectAboveCurrentObject;
            currentObject.parent.children[currentObjectIndexInParentChildrenArray - 1] = currentObject;
        }

        // Find the last row index of the currentObject
        const lastRowIndexCurrentObject = this.getLastRowIndexOfObject(tableObjectData, currentObject, rowIndex, columnIndex);

        // Find the first object with a different id above the currentObject
        const { firstObjectWithDifferentIdAboveCurrentObject, firstDifferentObjectLastRowIndex } =
            this.getFirstObjectWithDifferentIdAboveObject(tableObjectData, currentObject, rowIndex, columnIndex);

        // If there is no object with a different id above the currentObject, return
        if (!firstObjectWithDifferentIdAboveCurrentObject) return tableObjectData;

        // Find the first row index of the object above the currentObject
        const firstRowIndexOfObjectAbove = this.getFirstRowIndexOfObjectAboveObject(
            tableObjectData,
            firstObjectWithDifferentIdAboveCurrentObject, firstDifferentObjectLastRowIndex,
            columnIndex);

        // Determine the first row index of the current object
        const firstCurrentObjectIndex = firstDifferentObjectLastRowIndex + 1;

        // Create all indices in between and including firstCurrentObjectIndex and firstDifferentObjectLastRowIndex
        const indicesToMoveUp: number[] = [];
        for (let i = firstCurrentObjectIndex; i <= lastRowIndexCurrentObject; i++) {
            indicesToMoveUp.push(i);
        }

        // Move the rows up
        this.moveRowsAboveRow(newTableObjectData, firstRowIndexOfObjectAbove, indicesToMoveUp);

        // Renumber the table using the new order
        newTableObjectData = this.renumberTable(newTableObjectData);

        // return the new table
        return newTableObjectData;
    }

    // Moving down is the same as moving the object below the current object up
    public moveDown(tableObjectData: (TScoutingServiceTableObject | null)[][], rowIndex: number, columnIndex: number): (TScoutingServiceTableObject | null)[][] {

        // If the rowindex is the last row, return
        if (rowIndex === tableObjectData.length - 1) return tableObjectData;

        // Get the currentObject
        const currentObject = tableObjectData[rowIndex][columnIndex];

        // If currentObject is null, return
        if (!currentObject) return tableObjectData;

        // Get the last index of the currentObject
        const lastCurrentObjectIndex = this.getLastRowIndexOfObject(tableObjectData, currentObject, rowIndex, columnIndex);

        // Check if an index below the index of the current index exsists
        if (lastCurrentObjectIndex === tableObjectData.length - 1) return tableObjectData;

        // Move the object below the current object up
        return this.moveUp(tableObjectData, lastCurrentObjectIndex + 1, columnIndex);
    }

    private moveRowsAboveRow(currentTableData: (TScoutingServiceTableObject | null)[][], rowIndexToMoveAbove: number, rowIndicesToMove: number[]): (TScoutingServiceTableObject | null)[][] {
        for (let i = 0; i < rowIndicesToMove.length; i++) {
            // Get the row to move
            const rowToMove = currentTableData[rowIndicesToMove[i]];
            if (!rowToMove) continue;

            // Remove the row from the tableObjectData
            currentTableData.splice(rowIndicesToMove[i], 1);

            // Add the row to the tableObjectData on right location
            currentTableData.splice(rowIndexToMoveAbove + i, 0, rowToMove);
        }

        return currentTableData;
    }

    private getLastRowIndexOfObject(tableObjectData: (TScoutingServiceTableObject | null)[][], tableObject: TScoutingServiceTableObject, startRowIndex: number, columnIndex: number): number {
        let lastRowIndex = 0;
        // Loop through all the rows below the startRowIndex
        for (let i = startRowIndex; i < tableObjectData.length; i++) {
            const tableObjectInRow = tableObjectData[i][columnIndex];
            // Check if the id of the tableObjectInRow is the same as the id of the current tableObject
            if (tableObjectInRow && tableObjectInRow.objectId === tableObject.objectId) {
                lastRowIndex = i;
            } else {
                // If this is not the case it means that this is a different object and the loop can be broken
                break;
            }
        }
        return lastRowIndex;
    }

    private getFirstRowIndexOfObjectAboveObject(tableObjectData: (TScoutingServiceTableObject | null)[][], tableObject: TScoutingServiceTableObject, firstDifferentObjectIndex: number, columnIndex: number): number {
        let firstRowIndexOfObject = 0;

        // Loop through all the rows above the firstDifferentObjectIndex
        for (let i = firstDifferentObjectIndex; i >= 0; i--) {
            const tableObjectAbove = tableObjectData[i][columnIndex];
            // Check if the id of the tableObjectAbove is the same as the id of the current tableObject
            if (tableObjectAbove && tableObjectAbove.objectId === tableObject.objectId) {
                firstRowIndexOfObject = i;
            } else {
                // If this is not the case it means that this is a different object and the loop can be broken
                break;
            }
        }
        return firstRowIndexOfObject;
    }

    private getFirstObjectWithDifferentIdAboveObject(tableObjectData: (TScoutingServiceTableObject | null)[][], tableObject: TScoutingServiceTableObject, startRowIndex: number, columnIndex: number): { firstObjectWithDifferentIdAboveCurrentObject: TScoutingServiceTableObject | null, firstDifferentObjectLastRowIndex: number } {
        // Declare variables to fill in the loop
        let firstObjectWithDifferentIdAboveCurrentObject: TScoutingServiceTableObject | null = null;
        let firstDifferentObjectLastRowIndex = 0;

        // Loop through all the rows above the startRowIndex
        for (let i = startRowIndex - 1; i >= 0; i--) {
            const tableObjectAbove = tableObjectData[i][columnIndex];
            // Check if the id of the tableObjectAbove is the same as the id of the current tableObject
            if (tableObjectAbove && tableObjectAbove.objectId !== tableObject.objectId) {
                // If this is not the case it means that this is the first object with a different 
                // id above the current object, set the variables and break the loop
                firstObjectWithDifferentIdAboveCurrentObject = tableObjectAbove;
                firstDifferentObjectLastRowIndex = i;
                break;
            }
        }

        return { firstObjectWithDifferentIdAboveCurrentObject, firstDifferentObjectLastRowIndex };
    }

    private renumberTable(currentTableObjectData: (TScoutingServiceTableObject | null)[][]): (TScoutingServiceTableObject | null)[][] {
        // Get all table unique objects without a parent
        const tableObjectsWithoutParent: TScoutingServiceTableObject[] = [];
        const foundObjectWithParentIds: Set<string> = new Set<string>();

        let currentOrder = 1;
        for (const tableRow of currentTableObjectData) {
            for (const tableCell of tableRow) {
                // If the tableCell is null, continue
                if (!tableCell) continue;
                if (!tableCell.parent && tableCell.objectId) {
                    // Check if the tableCell is already in the tableObjectsWithoutParent array
                    if (foundObjectWithParentIds.has(tableCell.objectId)) continue;
                    // If this is not the case, add the tableCell to the tableObjectsWithoutParent array
                    // and set the numbering
                    tableObjectsWithoutParent.push(tableCell);

                    // if table cell is not checked, set numbering to empty string
                    if (!tableCell.isChecked || tableCell.isRequirementValue) {
                        tableCell.numbering = "";
                    } else {
                        // otherwise, set numbering to current order
                        tableCell.numbering = `${currentOrder}.`;
                        currentOrder++;
                    }

                    // add the objectId to the foundObjectWithParentIds set
                    foundObjectWithParentIds.add(tableCell.objectId);
                }
            }
        }

        // Change the numbering of the children of the tableObjectsWithoutParent
        const changeNumberingOfChildren = (tableObject: TScoutingServiceTableObject) => {
            let currentChildOrder = 1;

            // Check if the tableObject has children
            if (!tableObject.children) return;

            for (const childObject of tableObject.children) {
                // Check if the childObject is checked and if not, set the numbering to empty string
                if (!childObject.isChecked || childObject.isRequirementValue) {
                    childObject.numbering = "";
                } else {
                    // Set the numbering of the childObject
                    childObject.numbering = `${tableObject.numbering}${currentChildOrder}.`;
                    currentChildOrder++;
                }
                changeNumberingOfChildren(childObject);
            }
        };

        // Change the numbering of the children of the tableObjectsWithoutParent
        for (const tableObject of tableObjectsWithoutParent) {
            changeNumberingOfChildren(tableObject);
        }

        return currentTableObjectData;
    }

    public getTableObjects(parentObject: TScoutingServiceTableObject, currentData: TScoutingServiceTableObject[][],
        lowerLevelNode: TLinkGraphNodeDTO, deeperCount: number, doUncheckStudy = false) {
        // If the deeperCount is 0, then the function should not go deeper and return
        if (deeperCount === 0) return currentData;

        // Create a new array to store the results
        let results: TScoutingServiceTableObject[][] = [];

        // init count of inner lower level nodes not checked
        let countOfInnerLowerLevelNodesNotChecked = 0;

        lowerLevelNode.lowerLevelNodes.forEach((innerLowerLevelNode: TLinkGraphNodeDTO, index: number) => {
            // init is checked
            let isChecked = true;
            // if do uncheck study is true and inner lower level node is study
            if (doUncheckStudy && innerLowerLevelNode.objectType === ObjectTypeEnum.Study) {
                // set is checked to false
                isChecked = false;
                // increment count of inner lower level nodes not checked
                countOfInnerLowerLevelNodesNotChecked++;
            }

            // Create overview table objects for all the current level nodes
            const currentObject: TScoutingServiceTableObject = {
                objectId: innerLowerLevelNode.id,
                objectType: innerLowerLevelNode.objectType,
                name: innerLowerLevelNode.name,
                numbering: isChecked ? `${parentObject.numbering}${(index + 1 - countOfInnerLowerLevelNodesNotChecked).toString()}.` : "",
                isChecked,
                parent: parentObject,
                children: []
            };

            // Add the currentObject to the children of the parentObject
            if (parentObject.children) {
                parentObject.children.push(currentObject);
            }

            // Create the rows for the table for the current top node, by recursively handling
            // all the children
            const newData = currentData.map((tableRow: TScoutingServiceTableObject[]) => {
                return [...tableRow, currentObject];
            });

            // Add the results of the currentObject to the results array
            results = results.concat(this.getTableObjects(currentObject, newData, innerLowerLevelNode, deeperCount - 1, doUncheckStudy));
        });

        // If the results array is empty, return the currentData passed by the parent
        if (results.length === 0) results = currentData;

        // Return the results
        return results;
    }

    public getTableObjectData(withLinkGraphForFocusedNode: TLinkGraphDTO, withNumberOfLayers: number, doUncheckStudy = false): (TScoutingServiceTableObject | null)[][] {
        let tableResultsObjectData: (TScoutingServiceTableObject | null)[][] = [];

        // init count of lower level nodes not checked
        let countOfLowerLevelNodesNotChecked = 0;

        // Get total amount of cells
        withLinkGraphForFocusedNode.lowerLevelNodes.forEach((lowerLevelNode: TLinkGraphNodeDTO, index: number) => {
            // init is checked
            let isChecked = true;
            // if do uncheck study is true and lower level node is study
            if (doUncheckStudy && lowerLevelNode.objectType === ObjectTypeEnum.Study) {
                // set is checked to false
                isChecked = false;
                // increment count of lower level nodes not checked
                countOfLowerLevelNodesNotChecked++;
            }

            // Create overview table objects for all the top level nodes
            const currentObject: TScoutingServiceTableObject = {
                objectId: lowerLevelNode.id,
                objectType: lowerLevelNode.objectType,
                name: lowerLevelNode.name,
                numbering: isChecked ? `${(index + 1 - countOfLowerLevelNodesNotChecked).toString()}.` : "",
                isChecked,
                children: []
            };

            // Create the rows for the table for the current top node, by recursively handling
            // all the children
            const tableObjectDataRows = this.getTableObjects(currentObject, [[currentObject]],
                lowerLevelNode, withNumberOfLayers - 1, doUncheckStudy);

            // Add the rows to the tableResultsObjectData
            tableResultsObjectData = tableResultsObjectData.concat(tableObjectDataRows);
        });

        // If one of the table rows does not have enough cells, add empty cells
        for (const tableObjectDataRow of tableResultsObjectData) {
            if (tableObjectDataRow.length < withNumberOfLayers) {
                for (let i = tableObjectDataRow.length; i < withNumberOfLayers; i++) {
                    tableObjectDataRow.push(null);
                }
            }
        }

        // return table results object data
        return tableResultsObjectData;
    }

    public getShownTableObjectData(withSelectedLayer: number, withTableObjectData: (TScoutingServiceTableObject | null)[][], doShowOnlyOneObjectsLayerFromGraph?: boolean): (TScoutingServiceTableObject | null)[][] {
        // if do show only one objects layer from graph is true
        if (doShowOnlyOneObjectsLayerFromGraph) {
            // init set of object ids already added to the new shown table object data
            // (since we only show one layer, we need to make sure that we do not add the same object twice, because the modal is using a matrice to display a graph in a table)
            const objectIdsAlreadyAdded: Set<string> = new Set<string>();

            // init new shown table object data
            const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] = [];

            // init rowIndexCount
            let rowIndexCount = 0;

            // get index of first requirement value
            const indexOfFirstRequirementValue = this
                .getIndexOfFirstRequirementValue(withTableObjectData);

            // init count of object data not checked
            let countOfObjectDataNotChecked = 0;
            
            // go through each row in table object data
            withTableObjectData.forEach((row: (TScoutingServiceTableObject | null)[]) => {
                // init new row
                const newRow: (TScoutingServiceTableObject | null)[] = [];

                // get obj
                const objectDataAtSelectedLayer: TScoutingServiceTableObject | null = row[withSelectedLayer - 1];

                // if object data at selected layer and object id are defined
                if (objectDataAtSelectedLayer && objectDataAtSelectedLayer.objectId) {
                    // if object id is in object ids already added
                    if (objectIdsAlreadyAdded.has(objectDataAtSelectedLayer.objectId)) {
                        // stop current iteration, return
                        return;
                    }

                    // if object data at selected layer is not checked
                    if (!objectDataAtSelectedLayer.isChecked) {
                        // increment count of object data not checked
                        countOfObjectDataNotChecked++;
                    }

                    // otherwise
                    // add object id to object ids already added
                    objectIdsAlreadyAdded.add(objectDataAtSelectedLayer.objectId);

                    // increment rowIndexCount
                    rowIndexCount++;

                    // remove children from object data at selected layer (except requirement values)
                    objectDataAtSelectedLayer.children = objectDataAtSelectedLayer.children?.filter((child: TScoutingServiceTableObject) => child.isRequirementValue) ?? [];
                    // set parent of object data at selected layer to undefined
                    objectDataAtSelectedLayer.parent = undefined;
                    // set numbering of object data at selected layer to rowIndexCount
                    objectDataAtSelectedLayer.numbering = objectDataAtSelectedLayer.isChecked ? `${rowIndexCount - countOfObjectDataNotChecked}.` : "";

                    // add object data at selected layer
                    newRow.push(objectDataAtSelectedLayer);

                    // if index of first requirement value is not -1
                    if (indexOfFirstRequirementValue !== -1) {
                        // add all object data after index of last object data in a row
                        newRow.push(...row.slice(indexOfFirstRequirementValue));
                    }

                    // add new row to new shown table object data
                    newShownTableObjectData.push(newRow);
                }
            });

            // return new shown table object data
            return newShownTableObjectData;
        } else {

            // return table object data
            return [...withTableObjectData];
        }
    }

    public getIndexOfFirstRequirementValue(ofTableObjectData: (TScoutingServiceTableObject | null)[][]): number {
        // if of table object data is empty
        if (ofTableObjectData.length === 0) {
            // return -1
            return -1;
        }

        // get first row of table object data
        const firstRow = ofTableObjectData[0];

        // get index of first object data being a requirement value
        return firstRow.findIndex((tableObject) => tableObject !== null && tableObject.isRequirementValue);
    }

    public getHeaderTitlesFromRequirementsTableRows(requirementsTableRows: TRequirementsTableRowDTO[]): string[] {
        // get first row in intialTableContent
        const firstRow: TRequirementsTableRowDTO = requirementsTableRows[0];

        // safety-checks
        if (!firstRow) {
            // stop execution, return empty array
            return [];
        }

        // get all first entry in each entry of first row (i.e. header titles)
        const headerTitles: string[] = firstRow.map((firstRowEntry: TRequirementsTableEntryDTO) => firstRowEntry.key);

        // return header titles
        return headerTitles;
    }

    public getNumberOfColumns(fromLinkGraphForObjectEdited: TLinkGraphDTO): number {
        // init number of columns
        let numberOfColumns = 1;

        // for each lower level node
        fromLinkGraphForObjectEdited.lowerLevelNodes.forEach((lowerLevelNode: TLinkGraphNodeDTO) => {
            // get deepest level number from lower level node
            const deepestLevelNumber = LinkGraphHelperSingleton
                .getDeepestLevelNumberFromLinkGraphNode(lowerLevelNode, 1);

            // if deepest level number is greater than number of columns
            if (deepestLevelNumber > numberOfColumns) {
                // set number of columns to deepest level number
                numberOfColumns = deepestLevelNumber;
            }
        });

        // return number of columns
        return numberOfColumns;
    }

    public generateHeaderTitlesFromNumberOfColumns(numberOfColumns: number): string[] {
        // init header titles
        const headerTitles: string[] = [];

        // go through each column
        for (let i = 0; i < numberOfColumns; i++) {
            // add layer number to header titles
            headerTitles.push(`Layer ${i + 1}`);
        }

        // return header titles
        return headerTitles;
    }

    public buildInitialScoutingServiceTablePropsFromRequirementsTable(currentLinkGraphForObjectEdited: TLinkGraphDTO, currentRequirementsTable: TRequirementsTableDTO): TInitialScoutingServiceTableProps {
        // init number of columns
        const numberOfColumns = this.getNumberOfColumns(currentLinkGraphForObjectEdited);

        // reset table object data
        const newTableObjectData: (TScoutingServiceTableObject | null)[][] = this
            .getTableObjectData(currentLinkGraphForObjectEdited, numberOfColumns, true);

        // reset shown table object data
        const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] = this
            .getShownTableObjectData(currentRequirementsTable.layerNumber, newTableObjectData, true);

        // get shown content from requirements table rows
        let shownContent: (TScoutingServiceTableObject | null)[][] = this
            .getTableObjectDataFromRequirementsTable(newShownTableObjectData, currentRequirementsTable.tableRows);

        // get shown header titles from requirements table rows
        let shownHeaderTitles: string[] = this
            .getHeaderTitlesFromRequirementsTableRows(currentRequirementsTable.tableRows);

        // get selected layer number
        let selectedLayerNumber: number = currentRequirementsTable.layerNumber;

        // if one of shown arrays is empty
        if (!shownContent || shownContent.length === 0 || !shownHeaderTitles || shownHeaderTitles.length === 0) {
            // set both to empty arrays
            shownHeaderTitles = [];
            shownContent = [];
            // also set selected layer number to 1
            selectedLayerNumber = 1;
        }

        // return table props
        return {
            id: currentRequirementsTable.id,
            createdAt: currentRequirementsTable.createdAt,
            updatedAt: currentRequirementsTable.updatedAt,
            title: currentRequirementsTable.title ?? "Requirements table",
            isRatingEnabled: currentRequirementsTable.isRatingEnabled,
            isNumbered: currentRequirementsTable.isNumbered,
            selectedLayerNumber,
            shownHeaderTitles,
            shownContent,
            numberOfLayers: numberOfColumns,
            content: newTableObjectData,
            headerTitles: this.generateHeaderTitlesFromNumberOfColumns(numberOfColumns)
        };
    }

    public getTableObjectDataFromRequirementsTable(currentTableObjectData: (TScoutingServiceTableObject | null)[][], fromRequirementsTableRows: TRequirementsTableRowDTO[]): (TScoutingServiceTableObject | null)[][] {
        // get all first entry in each entry of first row (i.e. initial table header titles)
        const initialTableHeaderTitles: string[] = this.getHeaderTitlesFromRequirementsTableRows(fromRequirementsTableRows);

        // get all table cell data in first entry of each row in initialTableContent (i.e. initial table objects, first column)
        const initialTableObjects: TTableCellDTO[] = fromRequirementsTableRows
            .map((tableRow: TRequirementsTableRowDTO) => tableRow[0].value);

        // init count of first object in row not checked
        let countOfFirstObjectInRowNotChecked = 0;

        // init new table object data
        const newTableObjectData: (TScoutingServiceTableObject | null)[][] = [];

        // go through each row in new table object data
        currentTableObjectData.forEach((row, rowIndex) => {
            // init new row
            const newRow: (TScoutingServiceTableObject | null)[] = [];

            // get first object in row (first column)
            const firstObjectInRow: TScoutingServiceTableObject | null = row[0];

            // safety-checks
            if (!firstObjectInRow) {
                // increment count of first object in row not checked
                countOfFirstObjectInRowNotChecked++;
                // stop here, return
                return;
            }

            // init is table object found
            let isShownTableObjectFound = false;

            // go through each initial table object
            initialTableObjects.forEach((initialTableObject) => {
                // if initial table object id is the same as first object in row id
                if (initialTableObject.objectId === firstObjectInRow.objectId &&
                    initialTableObject.objectType === firstObjectInRow.objectType) {
                    // set is table object found to true
                    isShownTableObjectFound = true;
                }
            });

            // if is table object found is false
            // (i.e. if it is not found in initial table objects)
            if (!isShownTableObjectFound) {
                // set numbering of first object in row to empty string
                firstObjectInRow.numbering = "";
                // set first object in row is checked to false
                firstObjectInRow.isChecked = false;
                // increment count of first object in row not checked
                countOfFirstObjectInRowNotChecked++;
            } else {
                // set numbering to row index + 1 - count of first object in row not checked
                firstObjectInRow.numbering = `${rowIndex + 1 - countOfFirstObjectInRowNotChecked}.`;
            }

            // push first object in row from current table object data to new row
            newRow.push(firstObjectInRow);

            // set children of first object in row to empty array
            firstObjectInRow.children = [];

            // get initial table row objects
            const initialRowObjects = fromRequirementsTableRows.find((tableRow) => tableRow[0].value.objectId === firstObjectInRow.objectId);

            // init default scouting service table object
            const defaultScoutingServiceTableObject: TScoutingServiceTableObject = {
                isRequirementValue: true,
                isChecked: firstObjectInRow.isChecked,
                name: "",
                numbering: ""
            };

            // go through each initialTableHeaderTitles (skipping first entry because it is already dealt with)
            initialTableHeaderTitles.slice(1).forEach((initialTableHeaderTitle) => {
                // if initial row objects is not defined
                if (!initialRowObjects) {
                    // push default scouting service table object to newRow
                    newRow.push(defaultScoutingServiceTableObject);
                    // add default scouting service table object to first object in row children
                    firstObjectInRow.children?.push(defaultScoutingServiceTableObject);
                } else {
                    // get initial table object
                    const initialTableObject: TRequirementsTableEntryDTO | undefined = initialRowObjects.find((initialRowObject) => initialRowObject.key === initialTableHeaderTitle);

                    // safety-checks
                    if (!initialTableObject) {
                        // push default scouting service table object to newRow
                        newRow.push(defaultScoutingServiceTableObject);
                        // add default scouting service table object to first object in row children
                        firstObjectInRow.children?.push(defaultScoutingServiceTableObject);
                    } else {
                        // init scouting service table object
                        const scoutingServiceTableObject: TScoutingServiceTableObject = {
                            name: initialTableObject.value.value ?? "",
                            isRequirementValue: true,
                            isChecked: firstObjectInRow.isChecked,
                            numbering: ""
                        };

                        // add initial table object to newRow
                        newRow.push(scoutingServiceTableObject);

                        // add scouting service table object to first object in row children
                        firstObjectInRow.children?.push(scoutingServiceTableObject);
                    }
                }
            });

            // add row to new table object data
            newTableObjectData.push(newRow);
        });

        // return new table object data
        return [...newTableObjectData];
    }

    public buildInitialScoutingServiceTablePropsFromMaturityRadar(currentLinkGraphForObjectEdited: TLinkGraphDTO, currentMaturityRadar: TMaturityRadarDTO): TInitialScoutingServiceTableProps {
        // init number of columns
        const numberOfColumns = this.getNumberOfColumns(currentLinkGraphForObjectEdited);

        // reset table object data
        const newTableObjectData: (TScoutingServiceTableObject | null)[][] = this
            .getTableObjectData(currentLinkGraphForObjectEdited, numberOfColumns, true);

        // reset shown table object data
        const newShownTableObjectData: (TScoutingServiceTableObject | null)[][] = this
            .getShownTableObjectData(currentMaturityRadar.layerNumber, newTableObjectData, true);

        // init maturity level per entity id
        const maturityLevelPerEntityId: Map<string, TUpdateAssessmentScoreDTO> = new Map<string, TUpdateAssessmentScoreDTO>();

        // go through each maturity radar assessment
        currentMaturityRadar.assessments.forEach((maturityRadarAssessment) => {
            // if maturity radar assessment target id is defined
            if (maturityRadarAssessment.targetId && maturityRadarAssessment.targetType) {
                // add maturity radar assessment target id and assessment score to new maturity level per entity id
                maturityLevelPerEntityId.set(maturityRadarAssessment.targetId, {
                    lowScore: maturityRadarAssessment.lowScore,
                    highScore: maturityRadarAssessment.highScore
                });
            }
        });

        // get shown content from maturity radar assessments
        let shownContent: (TScoutingServiceTableObject | null)[][] = this
            .getTableObjectDataFromMaturityRadar(newShownTableObjectData, currentMaturityRadar.assessments);

        // get selected layer number
        let selectedLayerNumber: number = currentMaturityRadar.layerNumber;

        // if shown content is empty
        if (!shownContent || shownContent.length === 0) {
            // set shown content to empty array
            shownContent = [];
            // also set selected layer number to 1
            selectedLayerNumber = 1;
        }

        // get shown header titles from selected layer number
        const shownHeaderTitles: string[] = [`Layer ${selectedLayerNumber}`];

        // return table props
        return {
            id: currentMaturityRadar.id,
            createdAt: currentMaturityRadar.createdAt,
            updatedAt: currentMaturityRadar.updatedAt,
            title: currentMaturityRadar.title ?? "Maturity radar",
            isRatingEnabled: false,
            isNumbered: currentMaturityRadar.isNumbered,
            selectedLayerNumber,
            shownHeaderTitles,
            shownContent,
            content: newTableObjectData,
            headerTitles: this.generateHeaderTitlesFromNumberOfColumns(numberOfColumns),
            numberOfLayers: numberOfColumns,
            description: currentMaturityRadar.description,
            maturityLevelPerEntityId
        };
    }

    public getTableObjectDataFromMaturityRadar(currentTableObjectData: (TScoutingServiceTableObject | null)[][], fromMaturityRadarAssessments: TMaturityRadarAssessmentDTO[]): (TScoutingServiceTableObject | null)[][] {
        // init new table object data
        const newTableObjectData: (TScoutingServiceTableObject | null)[][] = [];

        // init count of first object in row not checked
        let countOfFirstObjectInRowNotChecked = 0;

        // go through each row in new table object data
        currentTableObjectData.forEach((row, rowIndex) => {
            // init new row
            const newRow: (TScoutingServiceTableObject | null)[] = [];

            // get first object in row (first column)
            const firstObjectInRow: TScoutingServiceTableObject | null = row[0];

            // safety-checks
            if (!firstObjectInRow) {
                // increment count of first object in row not checked
                countOfFirstObjectInRowNotChecked++;
                // stop here, return
                return;
            }

            // init is table object found
            let isTableObjectFound = false;
            
            // go through each initial table object
            fromMaturityRadarAssessments.forEach((fromMaturityRadarAssessment) => {
                // if from maturity radar assessment target id is the same as first object in row id
                // and from maturity radar assessment target type is the same as first object in row type
                if (fromMaturityRadarAssessment.targetId === firstObjectInRow.objectId &&
                    fromMaturityRadarAssessment.targetType === firstObjectInRow.objectType) {
                    // set is table object found to true
                    isTableObjectFound = true;
                }
            });

            // if is table object found is false
            // (i.e. if it is not found in initial table objects)
            if (!isTableObjectFound) {
                // set numbering of first object in row to empty string
                firstObjectInRow.numbering = "";
                // set first object in row is checked to false
                firstObjectInRow.isChecked = false;
                // increment count of first object in row not checked
                countOfFirstObjectInRowNotChecked++;
            } else {
                // set numbering to row index + 1 - count of first object in row not checked
                firstObjectInRow.numbering = `${rowIndex + 1 - countOfFirstObjectInRowNotChecked}.`;
            }

            // set children of first object in row to empty array
            firstObjectInRow.children = [];

            // push first object in row from current table object data to new row
            newRow.push(firstObjectInRow);

            // add new row to new table object data
            newTableObjectData.push([...newRow]);
        });
        
        // return new table object data
        return [...newTableObjectData];
    }
}

export const ScoutingServiceTableHelperSingleton = new ScoutingServiceTableHelper();