// node_modules
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
// Types
import { TCollapsibleListItem } from "Types";
// Styles
import styles from "./collapsibleListItem.module.scss";

type TCollapsibleListItemProps = {
    children: React.ReactNode,
    shouldShowOnlyChildObjectIcon?: boolean,
    toggleIsCollapsed: (listItem: TCollapsibleListItem) => void,
    listItem: TCollapsibleListItem,
    extraClassNames?: { chevronIconContainer?: string, onlyChildIconContainer?: string, chevronIcon?: string, onlyChildIcon?: string }
}
export const CollapsibleListItem: FC<TCollapsibleListItemProps> = ({
    children,
    listItem,
    shouldShowOnlyChildObjectIcon,
    toggleIsCollapsed,
    extraClassNames = {}
}: TCollapsibleListItemProps) => {
    const doesListItemHaveLowerLevelNodes = listItem.lowerLevelNodes && listItem.lowerLevelNodes.length > 0;

    const changeIsCollapsed = () => {
        toggleIsCollapsed(listItem);
    };
    return (
        <div className={styles.collapsibleListItem}>
            <div className={styles.linksListItemActionsContainer}>
                {(doesListItemHaveLowerLevelNodes) && (
                    <div className={`${styles.actionContainer} ${extraClassNames?.chevronIconContainer ?? ""}`} title={listItem.isCollapsed !== false ? "Uncollapse" : "Collapse"} onClick={changeIsCollapsed}>
                        <FontAwesomeIcon className={extraClassNames?.chevronIcon ?? ""} icon={listItem.isCollapsed !== false ? faChevronRight : faChevronDown} />
                    </div>
                )}
                {!doesListItemHaveLowerLevelNodes && shouldShowOnlyChildObjectIcon && (
                    <div className={`${extraClassNames?.onlyChildIconContainer ?? ""}`}>
                        <FontAwesomeIcon className={extraClassNames?.onlyChildIcon ?? ""} icon={faCircle} />
                    </div>
                )}
            </div>
            <div className={styles.collapsibleListItemContent}>
                {children}
            </div>
        </div>
    );
};
