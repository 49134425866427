// node_modules
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
// Enums
import { AskAIAssistantMenuItemEnum } from "Enums/AskAIAssistantMenuItemEnum";
// Components
import { AskAIAssistantModalMenuItem } from "./AskAIAssistantModalMenuItem/AskAIAssistantModalMenuItem";
// Styles
import styles from "./askAIAssistantModalMenu.module.scss";
// Constants
import { FeatureToggleConstants } from "Constants";

type TAskAIAssistantModalMenuProps = {
    selectedItem: AskAIAssistantMenuItemEnum | undefined,
    onSelectedItemUpdate: (newSelectedItem: AskAIAssistantMenuItemEnum) => void
};

export const AskAIAssistantModalMenu: FC<TAskAIAssistantModalMenuProps> = ({ selectedItem, onSelectedItemUpdate }: TAskAIAssistantModalMenuProps) => {
    // Logic
    const onAskAIAssistantModalMenuItemClick = (item: AskAIAssistantMenuItemEnum): void => {
        // set selected menu item
        onSelectedItemUpdate(item);
    };

    // Render
    return (
        <div className={styles.askAIAssistantModalMenu}>
            <div className={styles.askAIAssistantModalMenuTitle}>
                <FontAwesomeIcon icon={faMessageBot}/>
                Ask IGOR
            </div>
            <div>
                <AskAIAssistantModalMenuItem 
                    item={AskAIAssistantMenuItemEnum.QuestionAndAnswer} 
                    isSelected={selectedItem === AskAIAssistantMenuItemEnum.QuestionAndAnswer} 
                    onClick={onAskAIAssistantModalMenuItemClick}/>
                <div className={styles.generateContentTitle}>Generate</div>
                <AskAIAssistantModalMenuItem
                    item={AskAIAssistantMenuItemEnum.GenerateReport}
                    isSelected={selectedItem === AskAIAssistantMenuItemEnum.GenerateReport} 
                    onClick={onAskAIAssistantModalMenuItemClick}/>
                {(FeatureToggleConstants.TIAutomation) && (
                    <>
                        <AskAIAssistantModalMenuItem
                            item={AskAIAssistantMenuItemEnum.GeneralDescription}
                            isSelected={selectedItem === AskAIAssistantMenuItemEnum.GeneralDescription || 
                                selectedItem === AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks ||
                                selectedItem === AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge}
                            onClick={onAskAIAssistantModalMenuItemClick}/>
                    </>
                )}
                <AskAIAssistantModalMenuItem
                    item={AskAIAssistantMenuItemEnum.WriteSection}
                    isSelected={selectedItem === AskAIAssistantMenuItemEnum.WriteSection} 
                    onClick={onAskAIAssistantModalMenuItemClick}/>
                {(FeatureToggleConstants.TIAutomation) && (
                    <>
                        <AskAIAssistantModalMenuItem
                            item={AskAIAssistantMenuItemEnum.Table}
                            isSelected={selectedItem === AskAIAssistantMenuItemEnum.Table} 
                            onClick={onAskAIAssistantModalMenuItemClick}/>
                         <AskAIAssistantModalMenuItem
                            item={AskAIAssistantMenuItemEnum.ExecutiveSummary}
                            isSelected={selectedItem === AskAIAssistantMenuItemEnum.ExecutiveSummary} 
                            onClick={onAskAIAssistantModalMenuItemClick}/>
                    </>
                )}
            </div>
        </div>
    );
};