import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styles from "./tenantMenu.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { TTenantDTO } from "Types";
import { TenantControllerSingleton, TenantUserControllerSingleton } from "Controllers";
import { AuthContext } from "Providers";

type TenantMenuProps = {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    text: string;
    setText: (text: string) => void;
};

export const TenantMenu: React.FC<TenantMenuProps> = ({ setIsEditing, text }) => {

    const { auth } = useContext(AuthContext);
    const [allTenants, setAllTenants] = useState<TTenantDTO[]>([]);
    const [filteredTenants, setFilteredTenants] = useState<TTenantDTO[]>(allTenants);

    useEffect(() => {
        (async () => {
            const retrievedAllTenants = await TenantControllerSingleton.getAsync(undefined, true, false, true);
            if (retrievedAllTenants) {
                setAllTenants(retrievedAllTenants);
                setFilteredTenants(retrievedAllTenants);
            }
        })();
    }, []);

    useEffect(() => {
        if (text) {
            setFilteredTenants(allTenants.filter(tenant => tenant.name.toLowerCase().includes(text.toLowerCase())));
        } else {
            setFilteredTenants(allTenants);
        }
    }, [text, allTenants]);

    const reloadPageAfterTenantChange = useCallback((userEmail: string) => {
        const cleanedUserEmail = userEmail.toLowerCase().trim();
        const cleanedAuthContextUserEmail = auth.userEmail.toLowerCase().trim();

        if (cleanedUserEmail === cleanedAuthContextUserEmail ||
            cleanedUserEmail.includes(cleanedAuthContextUserEmail) ||
            cleanedAuthContextUserEmail.includes(cleanedUserEmail)) {
            window.location.reload();
        }
    }, []);

    const addUserToTenantAsync = useCallback(async (tenantId: string, userId: string, userEmail: string) => {
        setIsEditing(false);
        const result: TTenantDTO | undefined = await TenantUserControllerSingleton.addUserToTenant(tenantId, userId);
        if (result) {
            reloadPageAfterTenantChange(userEmail);
        }
    }, [reloadPageAfterTenantChange, setIsEditing]);

    const removeUserFromTenantAsync = useCallback(async (tenantId: string, userId: string) => {
        await TenantUserControllerSingleton.removeUserFromTenant(tenantId, userId);
    }, []);

    const onTenantSelect = useCallback(async (newTenantId: string) => {
        await removeUserFromTenantAsync(auth.tenantId, auth.userId);
        await addUserToTenantAsync(newTenantId, auth.userId, auth.userEmail);
    }, [auth.tenantId, auth.userId, auth.userEmail, removeUserFromTenantAsync, addUserToTenantAsync]);

    return (
        <div className={styles.tenantMenu}>
            <div className={styles.infoText}>
                Search to access one of the {allTenants.length} Universes. <br />
                Clicking it will instantly transport you to that universe.
            </div>
            <div className={styles.currentTenant}>
                <span className={styles.tenantName}>{auth.tenantName}</span>
                <span className={styles.currentBadge}>Current</span>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.sectionTitle}>All Universes</div>
            {filteredTenants.map(tenant => (
                <div
                    key={tenant.id}
                    className={styles.tenantItem}
                    onClick={() => onTenantSelect(tenant.id)}
                    aria-hidden="true"
                >
                    <div className={styles.tenantName}>{tenant.name}</div>
                    {tenant.name == auth.tenantName && <span className={styles.currentBadge}>Current</span>}
                    <FontAwesomeIcon icon={faArrowRight} className={styles.arrowIcon} />
                </div>
            ))}
        </div>
    );
};