// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";

// Define custom element "file-reference-link-title"
export class FileReferenceLinkTitle extends HTMLElement {
    constructor() {
        super();
        this.addEventListener("click", ProseMirrorHelperSingleton.handleClickEvent);
    }
}
