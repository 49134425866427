// node_modules
import axios from "axios";
import { ObjectTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class LockController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api`;

    public async updateObjectLock(objectId: string, objectType: ObjectTypeEnum, isLocked: boolean): Promise<boolean> {
        try {
            let objectPath = "";
            if(objectType === ObjectTypeEnum.Study) {
                objectPath = "study";
            } else if(objectType === ObjectTypeEnum.Entity) {
                objectPath = "entity";
            } else {
                return false;
            }

            const url = `${this._resourcePath}/${objectPath}/${objectId}/lock/${isLocked}`;

            // Try to change lock state
            const response = await axios.post(url);

            if(response?.status >= 200 && response?.status < 300) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const LockControllerSingleton = new LockController();