// node_modules
import { FC, ChangeEvent, KeyboardEvent } from "react";
import TextareaAutosize from "react-textarea-autosize";
// Styles 
import styles from "./textArea.module.scss";

export type TTextAreaProps = {
    placeholder: string
    value: string,
    onChange: (changeEvent: ChangeEvent<HTMLTextAreaElement>) => void,
    onEnter?: (text: string) => void,
    extraClassName?: string,
    isReadonly?: boolean,
}

export const TextArea: FC<TTextAreaProps> = ({
    placeholder,
    value,
    onChange,
    extraClassName = "",
    onEnter,
    isReadonly
}: TTextAreaProps) => {

    const handleOnKeyDown = (keyboardEvent: KeyboardEvent<HTMLTextAreaElement>): void => {
        if (onEnter && keyboardEvent.key === "Enter") {
            // prevent default behaviour of adding new line
            keyboardEvent.preventDefault();
            // call onEnter callback
            onEnter(keyboardEvent.currentTarget.value);
        }
    };

    return (
        <TextareaAutosize
            placeholder={placeholder}
            value={value} 
            onChange={onChange}
            onKeyDown={handleOnKeyDown}
            disabled={isReadonly}
            className={[styles.textarea, extraClassName].join(" ")}
        />
    );
};