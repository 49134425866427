// node_modules
import { EditorState, Plugin, PluginKey, Transaction } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";

// init plugin key
const loadingIndicatorPluginKey = new PluginKey<LoadingIndicatorPluginState>(
    "loadingIndicatorPlugin",
);

// define plugin
export const loadingIndicatorPlugin = () => {
    return new Plugin<LoadingIndicatorPluginState>({
        key: loadingIndicatorPluginKey,
        state: {
          init() {
            // set default state
            return new LoadingIndicatorPluginState(false);
          },
          apply(tr: Transaction, prev: LoadingIndicatorPluginState) {
            return prev.apply(tr);
          },
        },
        props: {
            decorations: (state: EditorState) => {
                // init decorations array
                const decorations: Decoration[] = [];

                // get plugin state
                const pluginState = loadingIndicatorPluginKey.getState(state);

                // if plugin state is set and isLoading is true
                if (pluginState && pluginState.isLoading) {
                    // add loading indicator widget
                    const widget = document.createElement("div");
                    widget.className = "loading-indicator";

                    decorations.push(
                        Decoration.widget(0, widget)
                    );
                }
                
                // create the decoration set
                return DecorationSet.create(state.doc, decorations);
            }
        },
    });
};

// plugin state
class LoadingIndicatorPluginState {
    // store isLoading flag
    public isLoading = false;

    // constructor
    constructor(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    apply(tr: Transaction): LoadingIndicatorPluginState {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const state = this;

        // get action from transaction
        const action = tr.getMeta(loadingIndicatorPluginKey);

        // if action is set
        if (action && action.setData) {
            // get isLoading from action
            const { isLoading } = action.setData;
            // return new state
            return new LoadingIndicatorPluginState(isLoading);
        }

        // return old state
        return state;
    }
}

// helper function to update plugin state
export const updateLoadingIndicatorPluginState = (editorView: EditorView, isLoading: boolean): void => {
    // disptach transaction to update plugin state
    editorView.dispatch(
        editorView.state.tr.setMeta(loadingIndicatorPluginKey, { 
            setData: { isLoading }
        }),
    );
};
