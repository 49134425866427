// React
import { ReactNode, createContext, useMemo } from "react";
// Interfaces
import { IPubSubHandler } from "Interfaces";


type TPubSubContext = {
    pubSubHandler: IPubSubHandler
};

const defaultPubSubContext: TPubSubContext = {
    pubSubHandler: {
        publishEvent: async () => { return; },
        subscribeToEvent: () => { return; },
        unsubscribeFromEvent: () => { return; }
    }
};

type TPubSubProviderProps = {
    children?: ReactNode,
    pubSubHandler: IPubSubHandler
};

export const PubSubContext = createContext<TPubSubContext>(defaultPubSubContext);

export const PubSubProvider = ({children, pubSubHandler}: TPubSubProviderProps) => {

    const pubSubContextProps = useMemo(() => {
        return {
            pubSubHandler: pubSubHandler
        } as TPubSubContext;
    }, [pubSubHandler]);

    return (
        <PubSubContext.Provider value={pubSubContextProps}>
            {children}
        </PubSubContext.Provider>
    );
};