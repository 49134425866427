// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TIdAndName, TSearchUnitDTO } from "Types";

export class SearchSpecificationUnitController {
    public async getAsync(query: string): Promise<TSearchUnitDTO[]> {
        try {
            // get search specification unit
            const response = await axios
                .get<TSearchUnitDTO[]>(`${AxiosHelperSingleton.getServerBaseURL()}api/searchspecificationunit?query=${query}`);

            // deal with the result
            if (response && response.data) {
                // return the result
                return response.data;
            } else {
                // return empty array
                return [];
            }
        } catch {
            // return empty array
            return [];
        }
    }

    public searchSpecificationUnitToIdAndName(result: TSearchUnitDTO): TIdAndName {
        return { id: result.id, name: result.name };
    }
}

export const SearchSpecificationUnitControllerSingleton = new SearchSpecificationUnitController(); 