import { ActivityTypeEnum } from "Enums";

export class ActivityTypeHelper {

    public activityEnumToDisplayString(activityType: ActivityTypeEnum): string {
        switch (activityType) {
            case ActivityTypeEnum.Create:
                return "Created";
            case ActivityTypeEnum.Update:
                return "Edited";
            case ActivityTypeEnum.Open:
                return "Viewed";
            case ActivityTypeEnum.Delete:
                return "Deleted";
            default:
                return "Edited";
        }
    }
}


export const ActivityTypeHelperHelperSingleton = new ActivityTypeHelper();