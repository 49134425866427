// node_modules
import { Dispatch, FC, SetStateAction } from "react";
// Styles
import styles from "./overviewTableOption.module.scss";
// Components
import { FindestButton } from "Components";

// Props type
type TOverviewTableOptionProps = {
    optionTitle: string,
    isOptionOn: boolean,
    setIsOptionOn: Dispatch<SetStateAction<boolean>>
};

export const OverviewTableOption: FC<TOverviewTableOptionProps> = ({ optionTitle, isOptionOn, setIsOptionOn }: TOverviewTableOptionProps) => {
    // Render
    return (
        <div className={styles.optionContainer}>
            <p className={styles.optionTitle}>{optionTitle}</p>
            <div className={styles.switchButtonsContainer}>
                <FindestButton 
                    extraClassName={`${styles.yesButton} ${isOptionOn ? styles.active : styles.inactive}`} 
                    title="Yes" 
                    titleAttribute="Yes"
                    onClick={(() => { setIsOptionOn(true); })} />
                <FindestButton 
                    extraClassName={`${styles.noButton} ${isOptionOn ? styles.inactive : styles.active}`} 
                    title="No" 
                    titleAttribute="No"
                    onClick={(() => { setIsOptionOn(false); })} />
            </div>
        </div>
    );
};