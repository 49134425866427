// React
import { Dispatch, SetStateAction, useCallback } from "react";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Hooks
import { useObjectNameChangeListener } from "./useObjectNameChangeListener";

export const useQueryNameChangeListener = (
    setQueries: Dispatch<SetStateAction<IQueryDTO[]>>
) => {
    // Handlers
    const onObjectNameChange = useCallback((objectId: string, name: string) => {
        setQueries((prevQueries) => {
            return prevQueries.map(query => {
                if (query.guid === objectId) {
                    return {
                        ...query,
                        name: name
                    };
                }
                return query;
            });
        });
    }, [setQueries]);

    useObjectNameChangeListener(onObjectNameChange);
};