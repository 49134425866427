// node_modules
import { Chart } from "chart.js";
import { RefObject, useState } from "react";
// Controllers
import { EntityControllerSingleton, ImageControllerSingleton, StudyControllerSingleton } from "Controllers";
// Enums
import { CustomBlockMarkerEnum, CustomMarkdownSeparatorEnum, ObjectTypeEnum, StudyStatusEnum, StudyTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { LogHelperSingleton, ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Interfaces
import { IEntityDTO, IStudyDTO } from "Interfaces";
// Types
import { TIdNameTypeObjectType, TImageDTO } from "Types";

/** Save graph as image in selected object description, or create new object and save graph as image in its description */
export const useSaveGraphAsImage = (barChartRef: RefObject<Chart<"bar", (number | [number, number] | null)[], unknown>>, captionInputRef: RefObject<HTMLInputElement>, onClose: () => void) => {
    // State
    const [isSavingGraphAsImage, setIsSavingGraphAsImage] = useState<boolean>(false);
    
    const getImageReference = (imageId: string, caption: string): string => {
        // build image reference
        return `%F%OPEN%${CustomBlockMarkerEnum.ImageReference}%F%[${imageId}]${CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_IMAGE_CAPTION}${caption}${CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_IMAGE_CAPTION}%F%CLOSE%${CustomBlockMarkerEnum.ImageReference}%F%`;
    };

    const handleEntityUpdateAsync = async (entityId: string, newDescription: string): Promise<boolean> => {
        // update entity description
        const updatedEntity: IEntityDTO | undefined = await EntityControllerSingleton
            .updateDescriptionAsync(entityId, newDescription);

        // if updated entity is not set
        if (!updatedEntity) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update entity description.");
            
            // stop execution, return false
            return false;
        }

        // return success
        return true;
    };

    const handleStudyUpdateAsync = async (studyId: string, newDescription: string, studyData: Partial<IStudyDTO> = {}): Promise<boolean> => {
        // build study to update
        const studyToUpdate: IStudyDTO = {
            id: studyId,
            description: newDescription,
            ...studyData
        } as IStudyDTO;

        // update study description
        const isSuccess: boolean = await StudyControllerSingleton
            .updateAsync(studyToUpdate);

        // if it is not successful
        if (!isSuccess) {
            // show error message
            ToastHelperSingleton    
                .showToast(ToastTypeEnum.Error, "Could not update study description.");
            
            // stop execution, return false
            return false;
        }

        // return success
        return true;
    };

    const handleEntityAsync = async (entityId: string, imageReference: string): Promise<boolean> => {
        // get entity
        const entity: IEntityDTO | undefined = await EntityControllerSingleton
            .getByIdAsync(entityId);

        // if entity is not set
        if (!entity) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not get entity.");

            // stop execution, return false
            return false;
        }

        // build new description
        const newDescription = `${entity.description ? entity.description : ""}\n::: break\n:::\n${imageReference}`;

        // update entity description and return success
        return await handleEntityUpdateAsync(entity.id, newDescription);
    };

    const handleStudyAsync = async (studyId: string, imageReference: string): Promise<boolean> => {
        // get study
        const study: IStudyDTO | undefined = await StudyControllerSingleton
            .getByIdAsync(studyId);

        // if study is not set
        if (!study) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not get study.");

            // stop execution, return false
            return false;
        }

        // build new description
        const newDescription = `${study.description ? study.description : ""}\n::: break\n:::\n${imageReference}`;

        // update study description and return success
        return handleStudyUpdateAsync(study.id, newDescription, {
            id: study.id,
            title: study.title,
            status: study.status,
            type: study.type,
            customTypeName: study.customTypeName,
            conclusion: study.conclusion
        });
    };

    const handleCreateNewObjectAsync = async (option: string, objectName: string, imageReference: string): Promise<boolean> => {
        // create new object
        const createdObject: TIdNameTypeObjectType | undefined = await ObjectTypeHelperSingleton
            .createObject(option, objectName);

        // if created object is not set
        if (!createdObject) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not create object.");

            // stop execution, return false
            return false;
        }

        // if created object is entity
        if (createdObject.objectType === ObjectTypeEnum.Entity) {
            // update entity with image reference and return success
            return handleEntityUpdateAsync(createdObject.id, imageReference);
        } else if (createdObject.objectType === ObjectTypeEnum.Study) {
            // update study with image reference and return success
            return handleStudyUpdateAsync(createdObject.id, imageReference, {
                title: createdObject.name,
                status: StudyStatusEnum.New,
                type: StudyTypeEnum.Undefined,
                customTypeName: "",
                conclusion: ""
            });
        }

        // return success
        return false;
    };

    const saveGraphAsImageAsync = async (currentCaption: string, option: string, currentSelectedObject?: TIdNameTypeObjectType, currentObjectName?: string): Promise<boolean> => {
        // safety-checks
        if (!barChartRef.current || (!currentSelectedObject && !currentObjectName) || (currentObjectName && !option)) {
            // stop execution, return false
            return false;
        }

        // if caption input ref is set and caption is not set: ask users if they want to save the image without a caption
        if (captionInputRef.current && !currentCaption && !window.confirm("Save the image without a caption?")) {
            // if not, set focus to caption input
            captionInputRef.current.focus();
            // stop execution, return false
            return false;
        }

        // set is saving graph as image to true
        setIsSavingGraphAsImage(true);

        // get base64 image of bar chart
        const base64BarChartImage: string = barChartRef.current.toBase64Image();
        // convert base64 image to blob
        const barChartImage: Blob = await fetch(base64BarChartImage).then(res => res.blob());

        // add image
        const image: TImageDTO | undefined = await ImageControllerSingleton
            .addImageAsync(barChartImage, currentCaption, true);

        // if image is not set
        if (!image) {
            // show error message
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not add image.");

            // set is saving graph as image to false
            setIsSavingGraphAsImage(false);

            // stop execution, return false
            return false;
        }

        // get image reference
        const imageReference = getImageReference(image.id, currentCaption);

        // define success
        let isSuccess = false;

        // if current selected object is set
        if (currentSelectedObject) {
            // if current selected object is entity
            if (currentSelectedObject.objectType === ObjectTypeEnum.Entity) {
                // handle entity and return success
                isSuccess = await handleEntityAsync(currentSelectedObject.id, imageReference);
            } else if (currentSelectedObject.objectType === ObjectTypeEnum.Study) {
                // otherwise, handle study and return success
                isSuccess = await handleStudyAsync(currentSelectedObject.id, imageReference);
            }
        } else if (currentObjectName) {
            // otherwise, if current object name is set
            // handle create new object and return success
            isSuccess = await handleCreateNewObjectAsync(option, currentObjectName, imageReference);
        }

        // if success
        if (isSuccess) {
            // show success message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Success, "Graph saved as image successfully.");

            // log success
            LogHelperSingleton
                .log("SavedGraphAsImage");
        } else {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "An error occurred.");
        }

        // set is saving graph as image to false
        setIsSavingGraphAsImage(false);

        // call onClose function
        onClose();

        // return success
        return isSuccess;
    };

    // return saveGraphAsImageAsync function
    return { isSavingGraphAsImage, saveGraphAsImageAsync };
};
