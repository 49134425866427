import { FC } from "react";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import { faQuoteRight } from "@fortawesome/pro-regular-svg-icons";
// Styles
import styles from "./rightPanel.module.scss";
import { WindowBubble } from "../Windows/WindowBubble";

type TRightPanelProps = {
    toggleSidebar: (type: string) => void,
    selectedSidebar?: string,
    hideReferencesButton?: boolean,
};

export const RightPanel: FC<TRightPanelProps> = ({ toggleSidebar, selectedSidebar, hideReferencesButton }: TRightPanelProps) => {
    // Render
    return (
        <div className={styles.rightPanel}>
            <WindowBubble
                onClick={() => { toggleSidebar("headers"); }}
                icon={faListUl}
                tooltipText="On this page"
                tooltipPlacement="left"
                isActive={selectedSidebar === "headers"}
            />
            {!hideReferencesButton && (
                <WindowBubble
                    onClick={() => { toggleSidebar("reference"); }}
                    icon={faQuoteRight}
                    tooltipText="References"
                    tooltipPlacement="left"
                    isActive={selectedSidebar === "reference"}
                    extraClassNames={{ container: styles.referencesButton }}
                />
            )}
        </div>
    );
};
