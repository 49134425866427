// node_modules
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { useNavigate } from "react-router-dom";
// Types
import { TScoutingServiceTableObject } from "Types";
// Components
import { MarkdownItComponent, ObjectChip, SaveableEditableMarkdown } from "Components";
// Contexts
import { WindowingContext } from "Providers";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Styles
import styles from "../scoutingServiceOptionTable.module.scss";

// Component props types
type TScoutingServiceTableCellTextContentProps = {
    onSourceChange: (newValue: string) => void,
    tableCell: TScoutingServiceTableObject | null,
    isNumbered: boolean,
    isTableCellEditable: boolean,
    isEditing: boolean,
    setIsEditing: Dispatch<SetStateAction<boolean>>,
    isObjectChipClickable?: boolean
};

export const ScoutingServiceTableCellTextContent: FC<TScoutingServiceTableCellTextContentProps> = ({
    onSourceChange,
    tableCell,
    isNumbered,
    isTableCellEditable,
    isEditing,
    setIsEditing,
    isObjectChipClickable
}: TScoutingServiceTableCellTextContentProps) => {    
    // Hooks
    const navigate = useNavigate();

    // Context
    const { minimizeAllWindows } = useContext(WindowingContext);

    // Logic
    const navigateToObject = () => {
        if (!tableCell || !tableCell.objectType || !tableCell.objectId) return;

        // if minimize all windows is defined
        if (minimizeAllWindows) {
            // call it
            minimizeAllWindows();
        }

        ObjectTypeHelperSingleton.navigateBasedOnObjectType(tableCell.objectType, tableCell.objectId, navigate);
    };

    const shouldObjectChipBeClickable = isObjectChipClickable && !isTableCellEditable;
    // Render
    return (
        (tableCell) && (
            <>
                {isNumbered ? `${tableCell.numbering} ` : ""}
                <div className={tableCell.isRequirementValue ? "" : styles.objectChip}>
                    {tableCell.isRequirementValue && isTableCellEditable && (
                            <SaveableEditableMarkdown 
                                noSourcePlaceholder="N/A"
                                source={tableCell?.name ?? ""}
                                isEditing={isEditing}
                                setIsEditing={setIsEditing}
                                onCancelClick={() => { onSourceChange(tableCell?.name ?? ""); setIsEditing(false); }} 
                                onSaveClick={(newValue: string) => { onSourceChange(newValue); setIsEditing(false); }}/>    
                    )}
                    {tableCell.isRequirementValue && !isTableCellEditable && (
                         <MarkdownItComponent source={tableCell?.name ?? ""} />
                    )}
                    {!tableCell.isRequirementValue && tableCell.objectId && tableCell.objectType && (
                        <ObjectChip
                            object={{
                                id: tableCell.objectId,
                                name: tableCell.name,
                                objectType: tableCell.objectType,
                                type: ""
                            }}
                            onClick={shouldObjectChipBeClickable ? navigateToObject : undefined}
                            isChipClickable={shouldObjectChipBeClickable}
                            shouldShowPreviewCard={!isTableCellEditable}
                        />
                    )}
                </div>
            </>
        )
    );
};