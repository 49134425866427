import { faChevronDown, faChevronUp, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosHelperSingleton } from "Helpers";
import { useClickOutsideRef } from "Hooks";
import { T2DCoordinates, TIdAndName } from "Types";
import axios from "axios";
import { CSSProperties, PropsWithChildren, useRef, useState } from "react";
import { FindestTextBox } from "../Inputs/FindestTextBox";

import styles from "./searchableDropdown.module.scss";

// TODO: check if itemName and placeholder can be merged
type TSearchableDropdownProps<T> = {
    placeholder?: string,
    searchUrl?: string,
    value?: string,
    itemName: string,
    doClearValueOnClick?: boolean,
    doSearchWhenValueEmpty?: boolean,
    overriddenStyles?: {[key: string]: string },
    searchFunction?: (text: string) => T[]
    asyncSearchFunction?: (text: string) => Promise<T[]>,
    resultToIdAndName: (result: T) => TIdAndName,
    onResultClickAsync: (result: T) => Promise<boolean>,
}

// Needed a function to make it generic, but maybe using any is nicer for consistency
export function SearchableDropdown<T>(props: PropsWithChildren<TSearchableDropdownProps<T>>) {
    const { placeholder, searchUrl, value, searchFunction, asyncSearchFunction, resultToIdAndName, 
        doClearValueOnClick, doSearchWhenValueEmpty, overriddenStyles, itemName, onResultClickAsync } = props;

    const noArgumentGivenError = new Error("The searchable dropdown either needs an url to search or a function to call to search.");
    if (!searchUrl && !searchFunction && !asyncSearchFunction) throw noArgumentGivenError;

    // Ref
    const dropDownRef = useRef<HTMLDivElement>(null);

    const onClickOutsideDropdown = () => {
        setIsDropdownOpen(false);
    };

    useClickOutsideRef(dropDownRef, onClickOutsideDropdown);

    // State
    const [currentValue, setCurrentValue] = useState<string | undefined>(value);
    const [searchResults, setSearchResults] = useState<TIdAndName[]>([]);
    const [originalSearchResults, setOriginalSearchResults] = useState<T[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownListPosition, setDropdownListPosition] = useState<T2DCoordinates>({x: 0, y: 0});

    const onChangeText = async (text: string) => {
        let results = [];
        if (doSearchWhenValueEmpty || text.trim().length > 0) {
            if (asyncSearchFunction) {
                results = await asyncSearchFunction(text);
            } else if (searchFunction) {
                results = searchFunction(text);
            } else if (searchUrl) {
                const response = await axios.get<any[]>(`${AxiosHelperSingleton.getServerBaseURL()}${searchUrl}${encodeURIComponent(text)}`);
                results = response.data;
            } else {
                throw noArgumentGivenError;
            }
        }

        if (results.length > 0) setIsDropdownOpen(true);

        const idAndNameList = results.map((result) => resultToIdAndName(result));
        setOriginalSearchResults(results);
        setSearchResults(idAndNameList);
    };

    const onClickSearchResult = async (index: number, name?: string) => {

        const isSuccess = await onResultClickAsync(originalSearchResults[index]);
        if (!isSuccess) return;

        setIsDropdownOpen(false);

        if(doClearValueOnClick) {
            setCurrentValue(undefined);
        } else {
            setCurrentValue(name);
        }
    };

    const calculateDropdownListPosition = (event: React.MouseEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        const selectElement = event.currentTarget;
        setDropdownListPosition({x: selectElement.getBoundingClientRect().left, y: selectElement.getBoundingClientRect().bottom});
    };

    const getDropdownListPosition = (): CSSProperties => {
        const dropdownListPositionCss: CSSProperties = {};
        dropdownListPositionCss.left = `${dropdownListPosition.x}px`;
        dropdownListPositionCss.top = `${dropdownListPosition.y}px`;
        if (isDropdownOpen) {
            dropdownListPositionCss.display = "block";
        } else {
            dropdownListPositionCss.display = "none";
        }
        return dropdownListPositionCss;
    };

    return (
        <div ref={dropDownRef} className={overriddenStyles ? overriddenStyles.searchableDropdownContainer : styles.searchableDropdownContainer}>
            <div className={overriddenStyles ? overriddenStyles.dropdownSelect : styles.dropdownSelect} title={currentValue ? currentValue : `Select ${itemName}`} onClick={(event: React.MouseEvent) => { if(!isDropdownOpen) { onChangeText(currentValue ? currentValue : ""); } setIsDropdownOpen(!isDropdownOpen); calculateDropdownListPosition(event); }}>
                <span style={{textTransform: "capitalize"}}>{currentValue ? currentValue : `${itemName}`}</span>
                <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
            </div>
            {isDropdownOpen ?
                <div style={getDropdownListPosition()} className={[overriddenStyles ? overriddenStyles.searchableDropdown : styles.searchableDropdown, styles.scrollbarCustomThin].join(" ")}>

                    <div className={overriddenStyles ? overriddenStyles.dropdownSearchbar : styles.dropdownSearchbar}>
                        <FontAwesomeIcon icon={faSearch} />
                        <FindestTextBox value={currentValue} doAutoFocus={true} placeholder={placeholder} onChange={onChangeText} onClick={() => { setIsDropdownOpen(true); onChangeText(""); }} />
                    </div>

                    {searchResults.length > 0 ?
                        <div className={overriddenStyles ? overriddenStyles.searchableDropdownOptions : styles.searchableDropdownOptions}>
                            {searchResults.map((searchResult, index) => {
                                return (
                                    <div className={overriddenStyles ? overriddenStyles.dropdownItem : styles.dropdownItem} onClick={() => { onClickSearchResult(index, searchResult.name); }} key={searchResult.id}>
                                        {searchResult.name}
                                    </div>
                                );
                            })}
                        </div>
                        :
                        <div className={overriddenStyles ? overriddenStyles.searchableDropdownOptions : styles.searchableDropdownOptions}>
                            <div className={[overriddenStyles ? overriddenStyles.dropdownItem : styles.dropdownItem, styles.empty].join(" ")}>No matching results found</div>
                        </div>
                    }
                </div>
                : null}
        </div>
    );
}