// node_modules
import { Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { NavigateFunction } from "react-router-dom";
// Helpers
import { ProseMirrorHelperSingleton, UserHelperSingleton } from "Helpers";
// Types
import { TAuth } from "Types";

// init plugin key
export const openInlineReferencePluginKey = new PluginKey(
    "openInlineReference",
);

// define plugin
export const openInlineReferencePlugin = (navigate: NavigateFunction, auth: TAuth) => {
    return new Plugin({
        key: openInlineReferencePluginKey,
        props: {
            handleClick: (_: EditorView, __: number, event: MouseEvent): void => {
                // safety-checks
                if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
                    // stop execution, return
                    return;
                }

                ProseMirrorHelperSingleton.onClickInlineReferenceHandler(event, navigate);
            }
        },
    });
};
