export class SharedToHelper {
    allowedSharedObjectTypes: string[];

    constructor() {
        this.allowedSharedObjectTypes = ["entities", "studies", "documents"];
    }

    public getObjectIdFromURL(pathname: string, overrideAllowedSharedObjectTypes?: string[]): string | undefined {
        // lowercase the pathname
        pathname = pathname.toLowerCase();

        // if the pathname starts with a slash
        if(pathname.startsWith("/")) {
            // remove the slash
            pathname = pathname.slice(1);
        }

        // split the pathname into parts by slash
        const parts = pathname.split("/");
        // if there are less than 3 parts
        if(parts.length < 3) {
            // return undefined (object id not found)
            return undefined;
        }

        // if the first part is not "library"
        if(parts[0] !== "library") {
            // return undefined (object id not found)
            return undefined;
        }

        // if the second part is not an allowed shared object type (entities, studies, documents)
        if(!this.allowedSharedObjectTypes.includes(parts[1]) || (overrideAllowedSharedObjectTypes && !overrideAllowedSharedObjectTypes.includes(parts[1]))) {
            // return undefined (object id not found)
            return undefined;
        }

        // otherwise return the third part (object id)
        return parts[2];
    }
}

export const SharedToHelperSingleton = new SharedToHelper();