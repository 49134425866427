/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
// node_modules
import MarkdownIt from "markdown-it";
// Extensions
import { entityReferenceBlockExtension } from "Components/Shared/Markdown/MarkdownItExtensions/EntityReferenceBlockExtension";
import { fileReferenceBlockExtension } from "Components/Shared/Markdown/MarkdownItExtensions/FileReferenceBlockExtension";
import { headingBlockExtension } from "Components/Shared/Markdown/MarkdownItExtensions/HeadingBlockExtension";
import { highlightReferenceBlockExtension } from "Components/Shared/Markdown/MarkdownItExtensions/HighlightReferenceBlockExtension";
import { imageReferenceBlockExtension } from "Components/Shared/Markdown/MarkdownItExtensions/ImageReferenceBlockExtension";
import { inlineReferenceExtension } from "Components/Shared/Markdown/MarkdownItExtensions/InlineReferenceExtension";
import { inlineStarsExtension } from "Components/Shared/Markdown/MarkdownItExtensions/InlineStarsExtension";
import { intakeSheetConfirmationBlockExtension } from "Components/Shared/Markdown/MarkdownItExtensions/IntakeSheetConfirmationBlockExtension";
import { addModifyLinkExtension } from "Components/Shared/Markdown/MarkdownItExtensions/MdItModifyLinkExtension";
import { studyReferenceBlockExtension } from "Components/Shared/Markdown/MarkdownItExtensions/StudyReferenceBlockExtension";
import { subScriptExtension } from "Components/Shared/Markdown/MarkdownItExtensions/SubScriptExtension";
import { superScriptExtension } from "Components/Shared/Markdown/MarkdownItExtensions/SuperScriptExtension";
import multimd_table_plugin from "Components/Shared/Markdown/MarkdownItExtensions/markdown-it-multimd-table";
// Enums
import { CustomBlockMarkerEnum, CustomMarkdownSeparatorEnum } from "Enums";
// Helpers
import { StringHelperSingleton } from "Helpers";

// Constants
const customBlockMarkers = `${CustomBlockMarkerEnum.HighlightReference}|${CustomBlockMarkerEnum.ImageReference}|${CustomBlockMarkerEnum.FileReference}|${CustomBlockMarkerEnum.EntityReference}|${CustomBlockMarkerEnum.StudyReference}|${CustomBlockMarkerEnum.IntakeSheetConfirmation}`;
// eslint-disable-next-line no-useless-escape
const removeMeRegex = /\%\%\%\%OPEN_REMOVE_ME\%\%\%\%[\s\S]*?\%\%\%\%CLOSE_REMOVE_ME\%\%\%\%/mg;
const oldOpenFileReferenceTitleSeparatorRegex = /\[\|/mg;
const oldCloseFileReferenceTitleSeparatorRegex = /\|\]/mg;
const oldOpenImageReferenceImageCaptionSeparatorRegex = /\[\|/mg;
const oldCloseImageReferenceImageCaptionSeparatorRegex = /\|\]/mg;
// eslint-disable-next-line no-useless-escape
// build a reference id regex with custom block markers
const blockReferenceIdRegex = new RegExp(`\\%F\\%OPEN\\%(${customBlockMarkers})\\%F\\%\\[(?<referenceId>.+?)\\][\\s\\S]*?\\%F\\%CLOSE\\%(${customBlockMarkers})\\%F\\%`, "mg");
const inlineReferenceIdRegex = new RegExp("%F%OPEN%INLINEREFERENCE%F%\\[(?<referenceId>.+?)\\]\\[\\{\\d\\}\\][^\\n]+?%F%CLOSE%INLINEREFERENCE%F%", "mg");

// Markdown extensions
export class MarkdownItHelper {
    markdownIt: MarkdownIt;

    constructor() {
        this.markdownIt = MarkdownIt()
            .disable(["code", "fence", "blockquote", "hr", "reference", "html_block"])
            .use(multimd_table_plugin)
            .use(superScriptExtension)
            .use(subScriptExtension)
            .use(inlineReferenceExtension)
            .use(inlineStarsExtension)
            .use(require("markdown-it-mark"))
            .use(require("markdown-it-container"), "break", {

                validate: function(params: any) {
                    return params.trim().match(/^break/);
                },

                render: function(tokens: any, idx: any) {
                    if (tokens[idx].nesting === 1) {
                        // opening tag
                        return "<p>";
                    } else {
                        // closing tag
                        return "</p>";
                    }
                }
            })
            .use(headingBlockExtension)
            .use(highlightReferenceBlockExtension)
            .use(imageReferenceBlockExtension)
            .use(fileReferenceBlockExtension)
            .use(entityReferenceBlockExtension)
            .use(studyReferenceBlockExtension)
            .use(intakeSheetConfirmationBlockExtension);

        this.markdownIt.set({ html: false });
        // add custom MarkdownIt extensions
        addModifyLinkExtension(this.markdownIt);
    }

    public render(source: string): string {
        // safety-checks
        if (!source) {
            source = "";
        }

        // pre process markdown
        source = this.preProcessMarkdown(source);

        // render markdown
        return this.markdownIt.render(source);
    }

    public postProcessMarkdown(markdown: string): string {
        const processedMarkdown = markdown.replace(removeMeRegex, "");
        return processedMarkdown;
    }

    public preProcessMarkdown(markdown: string): string {
        // init new markdown
        let newMarkdown = markdown;

        // pre process markdown
        // while developing, separators were changed due to certain issues (i.e.: #6521)
        // so here, on the application level instead of the database level, we replace the old separators with the new ones

        // replace old file reference title separator
        newMarkdown = newMarkdown.replace(oldOpenFileReferenceTitleSeparatorRegex, CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_TITLE);
        newMarkdown = newMarkdown.replace(oldCloseFileReferenceTitleSeparatorRegex, CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_TITLE);
        // replace old image reference image caption separator
        newMarkdown = newMarkdown.replace(oldOpenImageReferenceImageCaptionSeparatorRegex, CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_IMAGE_CAPTION);
        newMarkdown = newMarkdown.replace(oldCloseImageReferenceImageCaptionSeparatorRegex, CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_IMAGE_CAPTION);

        // return new markdown
        return newMarkdown;
    }

    public getReferenceIds(markdown: string): string[] {
        const results: string[] = [];

        // deal with block references
        const blockMatches = markdown.matchAll(blockReferenceIdRegex);
        let match = blockMatches.next();
        while (!match.done) {
            const currentValue = match.value;
            if (currentValue.groups?.referenceId) {
                results.push(currentValue.groups.referenceId);
            }
            match = blockMatches.next();
        }

        // deal with inline references
        const inlineMatches = markdown.matchAll(inlineReferenceIdRegex);
        match = inlineMatches.next();
        while (!match.done) {
            const currentValue = match.value;
            if (currentValue.groups?.referenceId) {
                results.push(currentValue.groups.referenceId);
            }
            match = inlineMatches.next();
        }

        return results;
    }

    public cleanHighlightReferenceContent(content: string, referenceUrl?: string): string {
        // init new content
        let newContent = content;

        // for some yet unknown reason(s), the content can contain the reference url (with delimiter tags)
        // so we need to remove it from the content
        if (referenceUrl && newContent.includes(`${CustomMarkdownSeparatorEnum.OPEN_HIGHLIGHT_REFERENCE_URL}${referenceUrl}${CustomMarkdownSeparatorEnum.CLOSE_HIGHLIGHT_REFERENCE_URL}`)) {
            newContent = newContent.replaceAll(`${CustomMarkdownSeparatorEnum.OPEN_HIGHLIGHT_REFERENCE_URL}${referenceUrl}${CustomMarkdownSeparatorEnum.CLOSE_HIGHLIGHT_REFERENCE_URL}`, "");
        }

        // return new content
        return newContent;
    }

    public cleanFileReferenceTitle(title: string, fileSourceUrl: string, extension: string): string {
        // init new title
        let newTitle = title;

        // for some yet unknown reason(s), the title can contain the file source url
        // the extension, or both (with delimiter tags)
        // so we need to remove them from the title
        // see #6212
        if (fileSourceUrl && newTitle.includes(`${CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_URL}${fileSourceUrl}${CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_URL}`)) {
            newTitle = newTitle.replaceAll(`${CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_URL}${fileSourceUrl}${CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_URL}`, "");
        }
        if (extension && newTitle.includes(`${CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_EXTENSION}${extension}${CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_EXTENSION}`)) {
            newTitle = newTitle.replaceAll(`${CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_EXTENSION}${extension}${CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_EXTENSION}`, "");
        }

        // remove new lines from title
        // see #6229
        newTitle = StringHelperSingleton
            .removeNewLines(newTitle);

        // return new title
        return newTitle;
    }

    public cleanImageReferenceCaption(caption: string, imageSourceUrl: string): string {
        // init new caption
        let newCaption = caption;

        // for some yet unknown reason(s), the caption can contain the image source url (with delimiter tags)
        // so we need to remove it from the caption
        // see #6212
        if (imageSourceUrl && newCaption.includes(`${CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_IMAGE_URL}${imageSourceUrl}${CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_IMAGE_URL}`)) {
            newCaption = newCaption.replaceAll(`${CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_IMAGE_URL}${imageSourceUrl}${CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_IMAGE_URL}`, "");
        }

        // remove new lines from caption
        // see #6229
        newCaption = StringHelperSingleton
            .removeNewLines(newCaption);

        // return new caption
        return newCaption;
    }
}

export const MarkdownItHelperSingleton = new MarkdownItHelper();