import { WebsocketFunctionNames } from "Constants";
import { ToastTypeEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { WebsocketContext } from "Providers";
import { FC, useContext, useEffect } from "react";

type TWebsocketToastReceiverProps = {
    children?: (JSX.Element | boolean)[],
}

export const WebsocketToastReceiver: FC<TWebsocketToastReceiverProps> = ({children}) => {
    
    const {webSocketController} = useContext(WebsocketContext);

    const onToast = (toastType: ToastTypeEnum, message: string) => {
        ToastHelperSingleton.showToast(toastType, message);
    };

    useEffect(() => {

        webSocketController.addHandler(WebsocketFunctionNames.ReceiveToast,
            onToast);

        return () => {
            webSocketController.removeHandler(WebsocketFunctionNames.ReceiveToast,
                onToast);
        };

    }, [webSocketController]);
    
    return (
        <>
            {children}
        </>
    );
};