// node_modules
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, LegacyRef, MouseEventHandler } from "react";
// Types
import { TCommentDTO } from "Types";
// Styles
import styles from "./commentsCount.module.scss";

type TCommentsCountProps = {
    containerRef?: LegacyRef<HTMLDivElement>,
    onMouseEnter?: MouseEventHandler<HTMLDivElement>,
    comments: TCommentDTO[],
    extraClassNames?: { container?: string }
};

export const CommentsCount: FC<TCommentsCountProps> = ({ containerRef, onMouseEnter, comments, extraClassNames = {} }: TCommentsCountProps) => {
    // Render
    return (
        comments && comments.length > 0 ?
            <div ref={containerRef}
                onMouseEnter={onMouseEnter}
                className={`${styles.commentsCount} ${extraClassNames?.container || ""}`}
            >
                <FontAwesomeIcon icon={faComment} />
                {comments.length}
            </div>
            :
            null
    );
};