// React
import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from "react";
// Shared Components
import { ReferenceModal } from "Components";
// Types
import { TButtonDefinition, TReferenceModalProps } from "Types";

const defaultReferenceModalProps: TReferenceModalProps = {
    isOpen: false, 
    doIgnoreIsDeleted: true, 
    id: undefined,
    type: undefined
};

export const useObjectReferenceModal = (buttonDefintion?: TButtonDefinition, onReferenceModalClose?: (referenceId?: string) => void ): { 
        referenceModal: ReactNode, closeReferenceModal: () => void, referenceModalProps: TReferenceModalProps, 
        setReferenceModalProps: Dispatch<SetStateAction<TReferenceModalProps>>} => {
    // State
    const [referenceModalProps, setReferenceModalProps] = useState<TReferenceModalProps>(defaultReferenceModalProps);

    // Reset state of modal on close
    const closeReferenceModal = useCallback(() => {
        setReferenceModalProps(defaultReferenceModalProps);
        if(onReferenceModalClose) {
            onReferenceModalClose(referenceModalProps.id);
        }
    }, [onReferenceModalClose, referenceModalProps.id]);

    const referenceModal: ReactNode = ReferenceModal({
            id: referenceModalProps.id,
            type: referenceModalProps.type,
            hideReferenceModal: closeReferenceModal,
            isOpen: referenceModalProps.isOpen,
            buttonDefintion: buttonDefintion,
            doIgnoreIsDeleted: referenceModalProps.doIgnoreIsDeleted,
            styles: referenceModalProps.styles,
        });

    return { referenceModal, closeReferenceModal, referenceModalProps, setReferenceModalProps };
};