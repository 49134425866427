// node_modules
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
// Components
import { Popover } from "Components";
// Enums
import { ReferenceClickTypeEnum } from "Enums";
// Types
import { TReferenceDTO } from "Types";
// Styles
import styles from "./addReferencePopover.module.scss";

type TAddReferencePopover = {
    reference?: TReferenceDTO,
    refPopoverReferenceElement: Element | null,
    handleOnReferenceClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, referenceClickType: ReferenceClickTypeEnum, reference?: TReferenceDTO) => void,
    insertAsReferenceText?: string
};

// Component
export const AddReferencePopover: FC<TAddReferencePopover> = ({ refPopoverReferenceElement, reference, handleOnReferenceClick, insertAsReferenceText }: TAddReferencePopover) => {
    // Render

    if (!reference) {
        return (
            <Popover
                popoverOffset={0}
                isSameHeightWithReference
                showInPortal
                extraClassName={styles.addReferencePopover}
                referenceEl={refPopoverReferenceElement}
                placement="left"
                dynamicPlacement={false}
            >
                <div 
                    className={`${styles.addReference} ${styles.onlyReference}`}
                    onClick={(event) => handleOnReferenceClick(event, ReferenceClickTypeEnum.INSERT_DOCUMENT_AS_REFERENCE, reference)}
                    title={insertAsReferenceText ?? "Insert document as reference"}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </Popover> 
        );
    }

    return (
        <Popover
            popoverOffset={0}
            isSameHeightWithReference
            dynamicPlacement={false}
            showInPortal
            extraClassName={styles.addReferencePopover}
            referenceEl={refPopoverReferenceElement}
            placement="left"
        >
            {reference.isImage ?
                <div className={`${styles.addReference} ${styles.onlyReference}`} onClick={(event) => handleOnReferenceClick(event, ReferenceClickTypeEnum.INSERT_IMAGE_AS_REFERENCE, reference)} title="Insert image as reference"><FontAwesomeIcon icon={faPlus} /></div>
            :
                <>
                    <div className={styles.addReference} onClick={(event) => handleOnReferenceClick(event, ReferenceClickTypeEnum.INSERT_HIGHLIGHT_AS_TEXT, reference)} title="Insert highlight as text"><FontAwesomeIcon icon={faPlus} /></div>
                    {!reference.referenceUrl ? null : <div className={styles.addReference} onClick={(event) => handleOnReferenceClick(event, ReferenceClickTypeEnum.INSERT_HIGHLIGHT_AS_REFERENCE, reference)} title="Insert highlight as reference"><FontAwesomeIcon icon={faQuoteLeft} /></div>}
                </>
            }
        </Popover>
    );
};