export enum SynonymTypeEnum {
    Action = 0,
    Object = 1,
    Subject = 2,
    Noun = 3,
    NounPhrase = 4,
    Phrase = 5,
    Word = 6,
    ExtraObject = 7,
    Environment = 8,
    Unit = 9
}