// node_modules
import { FC } from "react";
// Types
import { THighlightDTO } from "Types";
// Components
import { Highlight } from "Components";
// Styles
import styles from "./highlightsGroup.module.scss";
// Helpers
import { HighlightTypeHelperSingleton } from "Helpers";

type THighlightsGroupProps = {
    type: string,
    highlights: THighlightDTO[],
    deleteHighlight?: (highlightId: string) => void,
    onEditHighlight: (editedHighlight: THighlightDTO) => void,
    isEditable?: boolean,
}

export const HighlightsGroup: FC<THighlightsGroupProps> = ({type, highlights, deleteHighlight, 
        onEditHighlight, isEditable = true}: THighlightsGroupProps) => {
    return (
        <div className={styles.highlightsGroup}>
            <h3 className={styles.highlightsGroupTitle}>{HighlightTypeHelperSingleton.getHighlightsGroupTitle(type)}</h3>
            {highlights.map((highlight: THighlightDTO) => {
                return (
                    <Highlight
                        key={highlight.id}
                        highlight={highlight} 
                        doShowType={false}
                        onDeleteHighlight={isEditable ? deleteHighlight : undefined}
                        onEditHighlight={isEditable ? onEditHighlight : undefined}
                    />
                );
            })}
        </div>
    );
};