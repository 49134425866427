// React
import { useContext, useEffect } from "react";
// Providers
import { PubSubContext } from "Providers";
// Enums
import { ObjectTypeEnum, PubSubEventTypeEnum } from "Enums";

export const useAnyLinkAddedListener = (onLinkAdded: (fromId: string, fromType: ObjectTypeEnum, toId: string, toType: ObjectTypeEnum) => void) => {
    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);

    useEffect(() => {
        pubSubHandler.subscribeToEvent(PubSubEventTypeEnum.AddLink, onLinkAdded);

        return () => {
            pubSubHandler.unsubscribeFromEvent(PubSubEventTypeEnum.AddLink, onLinkAdded);
        };
    }, [onLinkAdded, pubSubHandler]);
};