// node_modules
import { FC, useContext, useState } from "react";
// Components
import { SaveableEditableMarkdown } from "Components";
// Types
import { TCommentDTO } from "Types";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";
// Contexts
import { CommentControllerSingleton } from "Controllers";
import { AuthContext } from "Providers";
// Styles
import styles from "./createCommentReply.module.scss";

type TCreateCommentReplyProps = {
    parentComment: TCommentDTO,
    onReplyDone: () => void,
    onCancel: () => void
}

export const CreateCommentReply: FC<TCreateCommentReplyProps> = ({parentComment, onCancel, onReplyDone}: TCreateCommentReplyProps) => {
    // State
    const [isEditing, setIsEditing] = useState(true);

    // Contexts
    const {auth} = useContext(AuthContext);
    
    // safety-checks
    // only allow replies on comments which are not replies themselves
    if (parentComment.parentId) { return null; }

    // Logic
    const onSaveAsync = async (commentReply: string): Promise<void> => {
        // safety-checks
        if (!commentReply) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Can't add an empty reply.");
            return;
        }

        // create new comment object (reply)
        const newComment = {
            commentOnId: parentComment.commentOnId,
            commentOnType: parentComment.commentOnType,
            text: commentReply,
            parentId: parentComment.id,
            replies: undefined,
            username: auth.userEmail
        } as TCommentDTO;

        // create new comment (reply) in db
        const createdComment: TCommentDTO | undefined = await CommentControllerSingleton
            .createAsync(newComment);

        // safety-checks
        if (!createdComment) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to add reply.");
            return;
        }
        
        // safety-checks
        if (!parentComment.replies) {
            parentComment.replies = [];
        }
        
        // add created comment (reply) to parent comment
        parentComment.replies = [createdComment, ...parentComment.replies];

        onReplyDone();

        // log
        LogHelperSingleton.log("AddReplyToComment");
    };

    // Render
    return (
        <div className={styles.createCommentReply}>
            <SaveableEditableMarkdown
                source={""}
                noSourcePlaceholder={"Type your reply here..."}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onSaveClick={onSaveAsync}
                onCancelClick={onCancel}
                isHighlighted
            />
        </div>
    );
};