// node_modules
import { FC, MouseEventHandler, useCallback, useContext, useEffect, useState } from "react";
// Types
import { TCommentDTO } from "Types";
// Components
import { Comments, Popover, SaveableEditableMarkdown } from "Components";
// Controllers
import { CommentControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Contexts
import { AuthContext } from "Providers";
// Styles
import styles from "./commentsPopover.module.scss";

// Props
type TCommentsPopoverProps = {
    isPopoverShown: boolean,
    referenceEl: Element | null,
    referenceId: string,
    onMouseLeave?: MouseEventHandler<Element> | undefined,
    comments: TCommentDTO[],
    onCommentsUpdated: (comments: TCommentDTO[]) => void
};

// Component
export const CommentsPopover: FC<TCommentsPopoverProps> = ({isPopoverShown, referenceEl, referenceId, onMouseLeave, comments, onCommentsUpdated}: TCommentsPopoverProps) => {
    // State
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        // if popover is not shown, then we are not editing
        if (!isPopoverShown) {
            setIsEditing(false);
        }
    }, [isPopoverShown]);

    // Contexts
    const { auth } = useContext(AuthContext);

    // Logic
    const onSaveClickAsync = useCallback(async (newCommentText: string, currentReferenceId: string): Promise<void> => {
        // safety-checks
        if (!currentReferenceId) {
            return;
        }

        // safety-checks
        if (!newCommentText) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Can't add an empty comment.");
            return;
        }

        // create new comment object
        const newComment = {
            commentOnId: currentReferenceId,
            commentOnType: ObjectTypeEnum.Highlight,
            text: newCommentText,
            parentId: undefined,
            replies: undefined,
            username: auth.userEmail
        } as TCommentDTO;

        // create new comment in db
        const createdComment: TCommentDTO | undefined = await CommentControllerSingleton
            .createAsync(newComment);

        // safety-checks
        if (!createdComment) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to add comment.");
            return;
        }

        // log
        LogHelperSingleton.log("CreateComment");

        // update comments
        onCommentsUpdated([...comments, createdComment]);

        // set editing to false
        setIsEditing(false);
    }, [auth.userEmail, comments, onCommentsUpdated]);
    
    // Render
    return (
        <Popover
            referenceEl={referenceEl}
            onMouseLeave={onMouseLeave}
            isOpen={isPopoverShown}
            showInPortal={true}
            placement="bottom-end"
            extraClassName={styles.commentsPopover}
        >
                <Comments
                    comments={comments}
                    emptyCommentPlaceholder={"Add your comment"}
                    allowAddingReply={false}
                    onCommentsUpdated={onCommentsUpdated}
                />
                    <div className={styles.newCommentContainer}>
                        <SaveableEditableMarkdown
                            source={""}
                            noSourcePlaceholder={"Add your comment"}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            doHideMarkdown={true}
                            onSaveClick={(savedText) => onSaveClickAsync(savedText, referenceId)}
                            onCancelClick={() => setIsEditing(false)}
                            extraClassNames={{ editModeButton: styles.newCommentButton }}
                            editModeButtonText="Add new"
                        />
                    </div>
        </Popover>
    );
};