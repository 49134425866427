// Enums
import { PermissionsEnum, RolesEnum, UniverseRolesEnum } from "Enums";
// Types
import { TCreateUserDTO } from "Types";

export type TUserDetailsUser = {
    id: string,
    email: string,
    roles: (UniverseRolesEnum | RolesEnum)[],
    galaxyIds: string[],
    firstName: string,
    lastName: string,
    username: string,
    permissions: PermissionsEnum[]
}

export const fromTCreateUserDTOToTUserDetailsUser = (user: TCreateUserDTO): TUserDetailsUser => {
    return {
        ...user,
        id: "",
        galaxyIds: [],
        firstName: user.firstName ?? "",
        lastName: user.lastName ?? "",
        username: "",
        permissions: user?.permissions ? user?.permissions?.map(({ value }) => value) : [],
        roles: [user.role]
    };
};