// node_modules
import React, { Dispatch, FC, FocusEvent, SetStateAction, useEffect, useState } from "react";
// Types
import { TSynonymDTO } from "Types";
// Components
import { FindestTextBox, Synonyms } from "Components";
import { SynonymsButton } from "../Synonyms/SynonymsButton";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Enums
import { SearchPriorityEnum, SearchSubTermTypeEnum, SynonymTypeEnum, ToastTypeEnum } from "Enums";
// Styles
import styles from "./searchSubTerm.module.scss";
import { IQueryDTO } from "Interfaces";

type TSearchSubTermProps = {
    searchSubTermSearchPriority: SearchPriorityEnum,
    searchSubTerm: string,
    searchSubTermSynonyms: TSynonymDTO[],
    searchSubTermType: SearchSubTermTypeEnum,
    isDisabled?: boolean
    updateSearchSubTermValue: (searchSubTermUpdatedValue: string, searchSubTermType: SearchSubTermTypeEnum) => void,
    handleOpenSynonymsSection: (event: React.MouseEvent) => void,
    onInputFocus?: (event: FocusEvent) => void,

    synonyms: TSynonymDTO[],
    synonymsType: SynonymTypeEnum,
    fieldId: number,
    synonymsHelperId: number,
    setSynonymsHelperId: Dispatch<SetStateAction<number>>,
    synonymsHelperValue: string,
    setSynonymsHelperValue: Dispatch<SetStateAction<string>>,
    query: IQueryDTO,
    setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>,
    clickedFieldElement: EventTarget | null,
    doOpenSynonymsHelperPopup: boolean,
    handleCloseSynonymsModal: () => void
}

export const SearchSubTerm: FC<TSearchSubTermProps> = ({
    searchSubTermSearchPriority, searchSubTerm, searchSubTermSynonyms,
    searchSubTermType, isDisabled, updateSearchSubTermValue, handleOpenSynonymsSection,
    onInputFocus,
    synonyms, synonymsType, fieldId, synonymsHelperId, setSynonymsHelperId, synonymsHelperValue, setSynonymsHelperValue, query, setQuery, clickedFieldElement, doOpenSynonymsHelperPopup,
    handleCloseSynonymsModal
}: TSearchSubTermProps) => {
    // State
    const [subTerm, setSubTerm] = useState<string>(searchSubTerm);
    const [showSynonyms, setShowSynonyms] = useState<boolean>(false);

    let timeout: NodeJS.Timeout;

    // Ref
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);

    // Logic
    useEffect(() => {
        // when search sub term is changed, set new sub term
        setSubTerm(searchSubTerm);
    }, [searchSubTerm]);

    useEffect(() => {
        // when current sub term is edited,
        // after 500 ms do:
        const delayDebounceFn = setTimeout(() => {
            // remove white spaces at the start and end of the new sub term
            const newSubTerm = subTerm.trim();
            // if new sub term is null/empty/undefined, set new sub term to the old one
            if (!newSubTerm) {
                setSubTerm(searchSubTerm);
                const searchSubTermTypeModified = searchSubTermType.charAt(0) + searchSubTermType.slice(1).toLowerCase();
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, `${searchSubTermTypeModified} cannot be empty.`);
                return;
            }
            // if the new sub term is the same as the old one, do nothing
            if (newSubTerm.trim().toLowerCase() === searchSubTerm.trim().toLowerCase()) return;
            // set sub term state
            setSubTerm(newSubTerm);
            // call parent on sub term change
            updateSearchSubTermValue(newSubTerm, searchSubTermType);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [subTerm, searchSubTerm, updateSearchSubTermValue, searchSubTermType]);

    const onMouseEnter = () => {
        if (searchSubTermSynonyms.length > 0) {
            timeout = setTimeout(() => setShowSynonyms(true), 1000);
        }
    };

    const onMouseLeave = () => {
        if (searchSubTermSynonyms.length > 0) {
            if (timeout) {
                clearTimeout(timeout);
            }
            setShowSynonyms(false);
        }
    };
    return (
        <div className={styles.searchSubTerm}>
            <FindestTextBox 
                value={subTerm}  
                onChange={(text: string) => { setSubTerm(text); }}
                onInputFocus={onInputFocus} />
            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
             ref={setReferenceElement} className={[styles.synonymsContainer].join(isDisabled ? ` ${styles.isDisabled}` : "")}>
                <SynonymsButton
                    synonymsCount={searchSubTermSynonyms.length}
                    handleOpenSynonymsSection={(event: React.MouseEvent) => { if (searchSubTermSearchPriority !== SearchPriorityEnum.Disabled) { handleOpenSynonymsSection(event); }}}
                />
            </div>   
            <Synonyms 
                synonyms={synonyms} 
                synonymsType={synonymsType}
                fieldId={fieldId} 
                synonymsHelperId={synonymsHelperId}
                setSynonymsHelperId={setSynonymsHelperId}
                synonymsHelperValue={synonymsHelperValue}
                setSynonymsHelperValue={setSynonymsHelperValue}
                query={query}
                setQuery={setQuery} 
                clickedFieldElement={clickedFieldElement}
                doOpenSynonymsHelperPopup={doOpenSynonymsHelperPopup}
                referenceElement={referenceElement}
                showSynonymsPopover={showSynonyms}
                onMouseEnter={() => setShowSynonyms(true)}
                onMouseLeave={onMouseLeave}
                handleCloseSynonymsModal={handleCloseSynonymsModal}
            />
        </div>
    );
};
