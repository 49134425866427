/* eslint-disable @typescript-eslint/no-unused-vars */
// node_modules
import { Attrs, Fragment, Node, NodeType } from "prosemirror-model";
import { Command, EditorState, TextSelection, Transaction } from "prosemirror-state";
// Enums
import { CustomBlockIdAttributeEnum, EditorTableDOMTag, SpecialBlockClassNameEnum } from "Enums";
// Components
import { findestSchema } from "Components";
// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";
// Types
import { TEditorDirection, TProseMirrorNodeData } from "Types";
// Constants
import { EditorConstants, ProseMirrorConstants } from "Constants";

export class ProseMirrorTablesHelper {
    // function to insert a table
    public insertTable(
        position: {from: number, to: number},
        numberOfColumns: number,
        numberOfRows: number,
        state: EditorState,
        dispatch?: (tr: Transaction) => void
    ): boolean {
        // safety-checks
        if (!dispatch || numberOfColumns < 1 || numberOfRows < 1) {
            // return false (command was not executed)
            return false;
        }

        // prepare header cells
        const headerCells: Node[] = [];
        for (let i = 0; i < numberOfColumns; i++) {
            headerCells.push(
                findestSchema.nodes.th.create(
                    {id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Th}`},
                    findestSchema.nodes.paragraph.create(
                        undefined,
                        findestSchema.text("Column header")
                    )
                )
            );
        }

        // prepare header row
        const headerRow: Node = findestSchema.nodes.tr.create(
            {id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Tr}`},
            Fragment.fromArray(headerCells)
        );

        // decrease number of rows by 1
        numberOfRows--;

        // prepare body cells
        const bodyCells: Node[] = [];
        for (let i = 0; i < numberOfRows; i++) {
            // prepare row cells
            const rowCells: Node[] = [];
            for (let j = 0; j < numberOfColumns; j++) {
                rowCells.push(
                    findestSchema.nodes.td.create(
                        {id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Td}`, rowspan: `${EditorConstants.DEFAULT_TD_ROW_SPAN}`},
                        findestSchema.nodes.paragraph.create(undefined)
                    )
                );
            }

            // add row to the body cells
            bodyCells.push(
                findestSchema.nodes.tr.create(
                    {id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Tr}`},
                    Fragment.fromArray(rowCells)
                )
            );
        }

        // add table to the end of the document
        const tr = state.tr.replaceWith(
            position.from,
            position.to,
            findestSchema.nodes.table.create(
                {[`${CustomBlockIdAttributeEnum.Table}`]: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Table}`},
                Fragment.fromArray([
                    findestSchema.nodes.thead.create(
                        {class: `${SpecialBlockClassNameEnum.THead}`},
                        headerRow
                    ),
                    findestSchema.nodes.tbody.create(
                        {class: `${SpecialBlockClassNameEnum.TBody}`},
                        bodyCells
                    )
                ])
            )
        );

        // dispatch the transaction
        dispatch(tr);

        // return true (command was executed)
        return true;
    }

    // function to delete the table
    public deleteTable(editorState: EditorState, dispatch?: (tr: Transaction) => void): boolean {
        // get table from selection
        const table: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Table]
            );

        // safety-checks
        if (!dispatch || !table || !table.node || !table.depth) {
            // return false (command was not executed)
            return false;
        }

        // init new transaction
        let tr: Transaction = editorState.tr;

        // go through all descendants of the doc
        editorState.doc.descendants((docNode: Node, pos:  number) => {
            // if descendant is a table
            // and the id of the table matches the id of the table
            if (docNode.type.name === EditorTableDOMTag.Table &&
                    docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] !== undefined &&
                    docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] === table.node?.attrs[`${CustomBlockIdAttributeEnum.Table}`]) {
                // delete the table
                tr = tr.delete(
                    pos,
                    pos + docNode.nodeSize
                );
            }
        });

        // dispatch the transaction
        dispatch(tr);

        // return true (command was executed)
        return true;
    }

    // function to delete the table
    public deleteRow(editorState: EditorState, dispatch?: (tr: Transaction) => void): boolean {
        // get table group from selection
        const tableGroup: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.TBody, EditorTableDOMTag.THead]
            );
        
        // safety-checks
        if (!dispatch || !tableGroup || !tableGroup.node || !tableGroup.depth ||
                tableGroup.node.type.name === EditorTableDOMTag.THead) {
            // return false (command was not executed)
            return false;
        }


        // get table row from selection
        const tableRow: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Tr]
            );

        // safety-checks
        if (!dispatch || !tableRow || !tableRow.node || !tableRow.depth) {
            // return false (command was not executed)
            return false;
        }

        // init new transaction
        let tr: Transaction = editorState.tr;

        // go through all descendants of the doc
        editorState.doc.descendants((docNode: Node, pos:  number) => {
            // if descendant is a tableRow
            // and the id of the tableRow matches the id of the tableRow where the cursor is
            if (docNode.type.name === EditorTableDOMTag.Tr &&
                    docNode.attrs.id === tableRow.node?.attrs.id) {
                // delete the tableRow
                tr = tr.delete(
                    pos,
                    pos + docNode.nodeSize
                );
            }
        });

        // dispatch the transaction
        dispatch(tr);

        // return true (command was executed)
        return true;
    }

    // function to add a row to the table
    public addRow(direction: TEditorDirection, editorState: EditorState, dispatch?: (tr: Transaction) => void): boolean {
        // get table from selection
        const table: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Table]
            );

        // safety-checks
        if (!dispatch || !table || !table.node || !table.depth) {
            // return false (command was not executed)
            return false;
        }

        // get tbody or thead from selection
        const tableGroup: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.TBody, EditorTableDOMTag.THead]
            );
        
        // if tableGroup is undefined, or if it is a thead and the direction is "BEFORE"
        // we prevent user from adding a row before the thead
        if (!tableGroup || !tableGroup.node || !tableGroup.node || (tableGroup.node.type.name === EditorTableDOMTag.THead && direction === "BEFORE")) {
            // return false (command was not executed)
            return false;
        }
        
        // init new transaction
        let tr: Transaction = editorState.tr;

        // if tableGroup is a thead
        // we need to add a new tr to the tbody
        if (tableGroup.node.type.name === EditorTableDOMTag.THead) {
            // go through all descendants of the doc
            editorState.doc.descendants((docNode: Node, docNodePos: number) => {
                // if descendant is a table
                // and the id of the table matches the id of the table
                if (docNode.type.name === EditorTableDOMTag.Table &&
                        docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] !== undefined &&
                        docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] === table.node?.attrs[`${CustomBlockIdAttributeEnum.Table}`]) {
                    // go through all descendants of the table
                    docNode.descendants((tableNode: Node, tableNodePos: number) => {
                        // if descendant is a tbody
                        if (tableNode.type.name === EditorTableDOMTag.TBody && tableGroup &&
                                tableGroup.node) {
                            // init new tr position
                            // + 2 because of start & end of tr
                            const trPosition = docNodePos + tableNodePos + 2;
                            // add a new tr to the tbody (with the nodes array)
                            tr = tr.replaceWith(
                                trPosition, 
                                trPosition,
                                findestSchema.nodes.tr.create(
                                    {id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Tr}`},
                                    Fragment.fromArray(this.getNewRowNodesArray(tableGroup.node))
                                )
                            );
                        }
                    });
                }
            });
        } else {
            // otherwise, if tableGroup is a tbody
            // get table row from selection
            const tableRow: TProseMirrorNodeData = ProseMirrorHelperSingleton
                .getFirstNodeDataWithTypesFromSelection(
                    editorState,
                    [EditorTableDOMTag.Tr]
                );
            
            // safety-checks
            if (!tableRow || !tableRow.node || !tableRow.depth) {
                // return false (command was not executed)
                return false;
            }
            
            // go through all descendants of the doc
            editorState.doc.descendants((docNode: Node, docNodePos: number) => {
                // if descendant is a table
                // and the id of the table matches the id of the table
                if (docNode.type.name === EditorTableDOMTag.Table &&
                        docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] !== undefined &&
                        docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] === table.node?.attrs[`${CustomBlockIdAttributeEnum.Table}`]) {
                    // go through all descendants of the table
                    docNode.descendants((tableNode: Node, tableNodePos: number) => {
                        // if descendant is a tbody
                        if (tableNode.type.name === EditorTableDOMTag.TBody) {
                            // go through all descendants of the tbody
                            tableNode.descendants((tBodyNode: Node, tBodyNodePos: number) => {
                                // if descendant is a tr and the id of the tr matches the id of the tr where the cursor is
                                if (tBodyNode.type.name === EditorTableDOMTag.Tr &&
                                        tBodyNode.attrs.id === tableRow.node?.attrs.id &&
                                        tableGroup && tableGroup.node) {
                                    // init new tr position
                                    // + 2 because of start & end of tr
                                    let trPosition = docNodePos + tableNodePos + tBodyNodePos + 2;
                                    // if direction is after, add the size of the cell
                                    if (direction === "AFTER") {
                                        trPosition += tBodyNode.nodeSize;
                                    }

                                    // add a new tr to the tbody (with the nodes array)
                                    tr = tr.replaceWith(
                                        trPosition, 
                                        trPosition,
                                        findestSchema.nodes.tr.create(
                                            {id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Tr}`},
                                            Fragment.fromArray(this.getNewRowNodesArray(tableGroup.node))
                                        )
                                    );
                                }
                            });
                        }
                    });
                }
            });
        }  
        // dispatch transaction
        dispatch(tr);
            
        // return true (command was executed)
        return true;
    }

    // function to add a column to the table
    public addColumn(direction: TEditorDirection, editorState: EditorState, dispatch?: (tr: Transaction) => void): boolean {
        // get table from selection
        const table: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Table]
            );

        // safety-checks
        if (!dispatch || !table || !table.node || !table.depth) {
            return false;
        }

        // init cell index (stores the index of the cell in the table row)
        const cellIndex: number | undefined = this.getCellIndexWhereCursorIs(editorState);

        // safety-checks
        if (cellIndex === undefined) {
            return false;
        }

        // init map to store the current position of each cell in the doc (key: cell id, value: current position)
        // need it because everytime we add a cell, the positions of the cells after or before it change
        // everything is immutable in prosemirror
        const currentPosPerCellId: Map<string, number> = new Map<string, number>();
        // update it with the current positions of the cells
        this.updateCurrentPosPerCellId(editorState.doc, table, cellIndex, direction, currentPosPerCellId);

        // init new transaction
        let tr: Transaction = editorState.tr;

        // apply callback to all cells in the table at the cell index
        this.applyCallbackToTableAtCell(editorState, table, cellIndex, (cell: Node) => {
            // defined new node type and content
            let newNodeType: NodeType | undefined = undefined;
            let newNode: Node | undefined = undefined;
            let attrs: Attrs | undefined = undefined;
            if (cell.type.name === EditorTableDOMTag.Td) {
                newNodeType = findestSchema.nodes.td;
                newNode = findestSchema.nodes.paragraph.create(undefined);
                attrs = {id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Td}`, rowspan: `${EditorConstants.DEFAULT_TD_ROW_SPAN}`};
            } else if (cell.type.name === EditorTableDOMTag.Th) {
                newNodeType = findestSchema.nodes.th;
                newNode = findestSchema.nodes.paragraph.create(undefined, findestSchema.text("Column header"));
                attrs = {id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Th}`, style: ""};
            }
            
            // get current position of the cell
            const currentCellNodePos = currentPosPerCellId.get(cell.attrs.id);
            if (newNodeType && newNode && currentCellNodePos !== undefined) {
                // update transaction by adding a new cell before or after the current cell
                tr = tr.replaceWith(
                    currentCellNodePos,
                    currentCellNodePos,
                    newNodeType.create(attrs, newNode)
                );
                
                // update the current position of each cell in the doc thanks to the transaction's doc
                this.updateCurrentPosPerCellId(tr.doc, table, cellIndex, direction, currentPosPerCellId);
            }
        });

        // dispatch transaction
        dispatch(tr);

        // return true (command was executed)
        return true;
    }

    // function to delete column
    public deleteColumn(editorState: EditorState, dispatch?: (tr: Transaction) => void): boolean {
        // get table from selection
        const table: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Table]
            );

        // safety-checks
        if (!dispatch || !table || !table.node || !table.depth) {
            return false;
        }

        // init cell index (stores the index of the cell in the table row)
        const cellIndex: number | undefined = this.getCellIndexWhereCursorIs(editorState);

        // safety-checks
        if (cellIndex === undefined) {
            return false;
        }

        // init map to store the current position of each cell in the doc (key: cell id, value: current position)
        // need it because everytime we remove a cell, the positions of the cells after or before it change
        // everything is immutable in prosemirror
        const currentPosPerCellId: Map<string, number> = new Map<string, number>();
        // update it with the current positions of the cells
        this.updateCurrentPosPerCellId(editorState.doc, table, cellIndex, "", currentPosPerCellId);
        
        // init new transaction
        let tr: Transaction = editorState.tr;

        // apply callback to all cells in the table at the cell index
        this.applyCallbackToTableAtCell(editorState, table, cellIndex, (cell: Node) => {
            // get current position of the cell
            const currentCellNodePos = currentPosPerCellId.get(cell.attrs.id);
            // safety-checks
            if (currentCellNodePos !== undefined) {
                // update transaction by deleting before or current cell node
                tr = tr.delete(
                    currentCellNodePos,
                    currentCellNodePos + cell.nodeSize
                );
                // update the current position of each cell in the doc thanks to the transaction's doc
                this.updateCurrentPosPerCellId(tr.doc, table, cellIndex, "", currentPosPerCellId);
            }
        });

        // dispatch transaction
        dispatch(tr);

        // return true (command was executed)
        return true;
    }

    // function to check if the current selection is in a table
    public isSelectionInTable(editorState: EditorState): boolean {
        // get table from selection
        const table: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Table]
            );
     
        // check if a node was found or the end of the document was reached
        if(!table.node ||  !table.depth || table.node.type.name === "doc") {
            // return false then because the selection is not in a table
            return false;
        }

        // return true if the node is a table
        return true;
    }

    private applyCallbackToTableAtCell(editorState: EditorState, table: TProseMirrorNodeData, cellIndex: number, callback: (cell: Node) => void): void {
        // go through all descendants of the doc
        editorState.doc.descendants((docNode: Node) => {
            // if descendant is a table and the id matches the id of the table
            if (docNode.type.name === EditorTableDOMTag.Table &&
                docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] !== undefined &&
                docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] === table.node?.attrs[`${CustomBlockIdAttributeEnum.Table}`]) {
                // go through all descendants of the table
                docNode.descendants((tableNode: Node) => {
                    // if descendant is a table row
                    if (tableNode.type.name === EditorTableDOMTag.Tr) {
                        let index = 0;
                        // go through all descendants of the table row
                        tableNode.descendants((cellNode: Node) => {
                            // if descendant is a td or th
                            if (cellNode.type.name === EditorTableDOMTag.Td ||
                                    cellNode.type.name === EditorTableDOMTag.Th) {
                                // if index matches the cell index of the cell where the cursor is
                                if (index === cellIndex) { 
                                    // apply callback to the cell
                                    callback(cellNode);
                                }

                                // increase cell index
                                index++;
                            }
                        });
                    }
                });

                // stop going through descendants (went through all descendants of the table)
                return false;
            }
        });
    }

    private getCellIndexWhereCursorIs(editorState: EditorState): number | undefined {
        // get cell at selection
        const cell: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Td, EditorTableDOMTag.Th]
            );

        // get table row from selection
        const tableRow: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Tr]
            );

        // get table from selection
        const table: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorState,
                [EditorTableDOMTag.Table]
            );

        // safety-checks
        if (!cell || !cell.node || !cell.depth ||
            !table || !table.node || !table.depth ||
            !tableRow || !tableRow.node || !tableRow.depth) {
            return undefined;
        }

        // init cell index (stores the index of the cell in the table row)
        let cellIndex: number | undefined = undefined;
        // go through all descendants of the table
        table.node.descendants((tableNode: Node) => {
            // if descendant is a table row and the id matches the id of the table row
            if (tableNode.type.name === EditorTableDOMTag.Tr && tableNode.attrs.id === tableRow.node?.attrs.id) {
                let index = 0;
                // go through all descendants of the table row
                tableNode.descendants((tableRowNode: Node) => {
                    // if descendant is a table cell
                    if (tableRowNode.type.name === EditorTableDOMTag.Td ||
                        tableRowNode.type.name === EditorTableDOMTag.Th) {
                        // if the id of the table cell matches the id of the cell
                        if (tableRowNode.attrs.id === cell.node?.attrs.id) {
                            // set cell index
                            cellIndex = index;
                        }

                        // increase cell index
                        index++;
                    }
                });
                
                // stop going through descendants (went through all descendants of the table row)
                return false;
            }
        });

        // return cell index
        return cellIndex;
    }

    private getNewRowNodesArray(tableGroup: Node): Node[] {
        // safety-checks
        if (!tableGroup || (tableGroup.type.name !== EditorTableDOMTag.THead &&
                tableGroup.type.name !== EditorTableDOMTag.TBody)) {
            return [];
        }

        // init nodes array
        const nodes: Node[] = [];
        // add new cells (td) to nodes array depending on the number of headers
        // (number of headers is number of cells in one tr of tableGroup, the first one for example)
        // first get tr of thead
        const tableGroupTr: Node | null = tableGroup.firstChild;

        // safety-checks
        if (!tableGroupTr || tableGroupTr.type.name !== EditorTableDOMTag.Tr) {
            // return false (command was not executed)
            return [];
        }

        // add new cells
        for (let i = 0; i < tableGroupTr.childCount; i++) {
            nodes.push(findestSchema.nodes.td.create({id: crypto.randomUUID(), class: `${SpecialBlockClassNameEnum.Td}`, rowspan: `${EditorConstants.DEFAULT_TD_ROW_SPAN}`}, findestSchema.nodes.paragraph.create(undefined)));
        }

        // return nodes array
        return nodes;
    }

    private updateCurrentPosPerCellId(doc: Node, table: TProseMirrorNodeData, cellIndex: number, direction: TEditorDirection, currentPosPerCellId: Map<string, number>): void {
        // go through all descendants of the doc
        doc.descendants((docNode: Node, docNodePos: number) => {
            // if descendant is a table and the id matches the id of the table
            if (docNode.type.name === EditorTableDOMTag.Table &&
                docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] !== undefined &&
                docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] === table.node?.attrs[`${CustomBlockIdAttributeEnum.Table}`]) {
                // go through all descendants of the table
                docNode.descendants((tableNode: Node, tableNodePos: number) => {
                    // if descendant is a table row
                    if (tableNode.type.name === EditorTableDOMTag.Tr) {
                        let index = 0;
                        // go through all descendants of the table row
                        tableNode.descendants((cellNode: Node, cellNodePos: number) => {
                            // if descendant is a td or th
                            if (cellNode.type.name === EditorTableDOMTag.Td ||
                                    cellNode.type.name === EditorTableDOMTag.Th) {
                                // if index matches the cell index of the cell where the cursor is
                                if (index === cellIndex) {
                                    // init new current position 
                                    // + 2 because of start & end of tbody (or thead)
                                    let newCurrentPos = docNodePos + tableNodePos + cellNodePos + 2;
                                    // if direction is after, add the size of the cell
                                    if (direction === "AFTER") {
                                        newCurrentPos += cellNode.nodeSize;
                                    }
                                    // add the current position of the cell to the map
                                    currentPosPerCellId.set(cellNode.attrs.id, newCurrentPos);
                                }
                                
                                // increase cell index
                                index++;
                            }
                        });
                    }
                });

                // stop going through descendants (went through all descendants of the table)
                return false;
            }
        });
    }
}

export const ProseMirrorTablesHelperSingleton = new ProseMirrorTablesHelper();