// node_modules
import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Enums
// Controllers
import { AuthControllerSingleton, SharedToControllerSingleton } from "Controllers";
// Types
import { ObjectTypeHelperSingleton } from "Helpers";
import { TIdNameTypeObjectType } from "Types";

export const ReadOnlyRedirectPage: FC = () => {
    // Hooks
    const navigate = useNavigate();

    // Logic
    const redirectToAnySharedToAsync = useCallback(async () => {
        // get any object shared to the current user
        const result: TIdNameTypeObjectType | undefined = await SharedToControllerSingleton
            .anythingSharedWithMeAsync();

        // if result is undefined (nothing is shared with the current user)
        if(!result) {
            console.log("Nothing is shared with the current user");
            // sign/log out the current user
            await AuthControllerSingleton.logoutAsync();
            // reload the page
            location.reload();
            // stop execution
            return;
        }

        // if something is shared with the current user then redirect them to the shared page (depending on the object type)
        ObjectTypeHelperSingleton
            .navigateBasedOnObjectType(result.objectType, result.id, navigate);
    }, [navigate]);

    useEffect(() => {
        // redirect current user to any shared to
        redirectToAnySharedToAsync();
    }, [redirectToAnySharedToAsync]);

    // Render (empty div)
    return (
        <div>  
        </div>
    );

};