// node_modules
import { DragEvent, FC } from "react";
import { NodeProps } from "reactflow";
// Components
import { CustomNode } from "./CustomNode";
// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType, TReactFlowNode } from "Types";

type TEntityNodeProps = NodeProps<TReactFlowNode> & {
    onLoadMoreClickAsync: (nodeId: string, nodeType: ObjectTypeEnum) => Promise<void>,
    onAddNewLinkClick: (data: TReactFlowNode, defaultLinkTypeValue: string) => void,
    onCollapseOrExpandClick: (isCollapsed: boolean, id: string) => void,
    isViewOnlyMode: boolean,
    onDragOver: (dragEvent: DragEvent, newDraggedOverObject: TIdNameTypeObjectType) => void,
    onDragLeave: (dragEvent: DragEvent) => void,
}

export const EntityNode: FC<TEntityNodeProps> = (data) => {
    return (
        <CustomNode {...data} />
    );
};