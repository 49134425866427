// node_modules
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Controllers
import { LockControllerSingleton } from "Controllers";
// Enums
import { LogFeatureNameEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { LocalStorageHelperSingleton, LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Contexts
import { AuthContext, ClaimEditOnObjectContext } from "Providers";

export type TCollaborationContext = {
    isEditModeOn: boolean | undefined,
    tryClaimingEditModeOnObjectAsync: () => void,
    turnEditModeOff: () => void,
    isEditorShown: boolean,
    showEditorMenu: (objectIdToEdit?: string, objectTypeToEdit?: ObjectTypeEnum) => void,
    hideEditorMenu: () => void,
    isLocked: boolean | undefined,
    setIsLocked: Dispatch<SetStateAction<boolean | undefined>>,
    toggleIsLocked: (objectId: string, newIsLocked: boolean) => Promise<void>,
    objectIdEdited?: string,
    setObjectIdEdited: Dispatch<SetStateAction<string | undefined>>,
    objectTypeEdited?: ObjectTypeEnum,
    setObjectTypeEdited: Dispatch<SetStateAction<ObjectTypeEnum | undefined>>
};

export const defaultCollaborationContext: TCollaborationContext = {
    isEditModeOn: undefined,
    tryClaimingEditModeOnObjectAsync: () => { return; },
    turnEditModeOff: () => { return; },
    isEditorShown: false,
    showEditorMenu: () => { return; },
    hideEditorMenu: () => { return; },
    isLocked: undefined,
    setIsLocked: () => { return; },
    toggleIsLocked: async () => { return; },
    objectIdEdited: undefined,
    setObjectIdEdited: () => { return; },
    objectTypeEdited: undefined,
    setObjectTypeEdited: () => { return; },
};

type TCollaborationProviderProps = {
    children?: ReactNode,
};

export const CollaborationContext = createContext<TCollaborationContext>({ ...defaultCollaborationContext });

export const CollaborationProvider = ({ children }: TCollaborationProviderProps) => {
    // General state
    const [isEditModeOn, setIsEditModeOn] = useState<boolean | undefined>(defaultCollaborationContext.isEditModeOn);
    const [isEditorShown, setIsEditorShown] = useState<boolean>(defaultCollaborationContext.isEditorShown);
    const [objectIdEdited, setObjectIdEdited] = useState<string | undefined>(defaultCollaborationContext.objectIdEdited);
    const [objectTypeEdited, setObjectTypeEdited] = useState<ObjectTypeEnum | undefined>(defaultCollaborationContext.objectTypeEdited);
    const [isLocked, setIsLocked] = useState<boolean | undefined>(defaultCollaborationContext.isLocked);

    // Contexts
    const { isUserExternal, isUserViewer } = useContext(AuthContext);
    const { tryClaimingEditOnObjectAsync, stopClaimingEditOnObjectAsync } = useContext(ClaimEditOnObjectContext);

    const hideEditorMenu = useCallback(async () => {
        // set is editor shown to false
        setIsEditorShown(false);
        setIsLocked(false);
        // set the object id edited to undefined
        setObjectIdEdited(undefined);
        setObjectTypeEdited(undefined);
    }, []);

    const showEditorMenu = useCallback((objectIdToEdit?: string, objectTypeToEdit?: ObjectTypeEnum) => {
        // set is editor shown to true
        setIsEditorShown(true);
        // set the object id edited
        setObjectIdEdited(objectIdToEdit);
        setObjectTypeEdited(objectTypeToEdit);
    }, []);

    // Logic
    const tryClaimingEditModeOnObjectAsync = useCallback(async () => {
        // safety-checks
        if (!objectIdEdited || isUserExternal) { return; }

        if (isUserViewer || isLocked) {
            // set is edit mode on to false
            setIsEditModeOn(false);
            // stop execution, return
            return;
        }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-TurnEditModeOn`);

        // try to claim the edit mode on the object
        const result: boolean = await tryClaimingEditOnObjectAsync(objectIdEdited);

        // if the result is true then the edit mode was claimed
        if (result) {
            // set is edit mode on to true
            setIsEditModeOn(true);
        } else {
            // set is edit mode on to false
            setIsEditModeOn(false);

            // notify the user that the element is already being edited
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "A coworker is already editing this element.");
        }
    }, [objectIdEdited, isUserExternal, isUserViewer, isLocked, tryClaimingEditOnObjectAsync]);

    useEffect(() => {
        // if edit mode is on
        if (isEditModeOn) {
            // try claiming the edit mode on current object edited
            tryClaimingEditModeOnObjectAsync();
        }
    }, [isEditModeOn, tryClaimingEditModeOnObjectAsync]);

    const turnEditModeOff = useCallback(async () => {
        // safety-checks
        if (!objectIdEdited) { return; }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-TurnEditModeOff`);

        // set is edit mode on to false
        setIsEditModeOn(false);

        // stop claiming the edit mode on the object
        await stopClaimingEditOnObjectAsync(objectIdEdited);
    }, [objectIdEdited, stopClaimingEditOnObjectAsync]);

    const toggleIsLocked = useCallback(async (objectId: string, newIsLocked: boolean) => {
        // safety-checks
        if (!objectId || !objectTypeEdited) { return; }

        if (isUserExternal || isUserViewer) {
            // set is edit mode on to false
            turnEditModeOff();
            return;
        }
        // Try changing the lock state of the page
        const isSuccess = await LockControllerSingleton.updateObjectLock(objectId, objectTypeEdited, newIsLocked);

        // If not successful then show error toast
        if (!isSuccess) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not change page lock.");
            return;
        }

        // set the page lock
        setIsLocked(newIsLocked);

    }, [isUserExternal, isUserViewer, objectTypeEdited, turnEditModeOff]);

    // // Read from localStorage when the component mounts
    useEffect(() => {
        const storedIsEditMode = LocalStorageHelperSingleton.getItem<boolean>("isEditModeOn");
        // If no value is stored, default is Edit Mode On!
        if (storedIsEditMode === undefined || storedIsEditMode === null || storedIsEditMode === true) {
            setIsEditModeOn(true);
        }
    }, []);

    // // Write to localStorage whenever isEditMode changes
    useEffect(() => {
        if (isEditModeOn === undefined) { return; }
        LocalStorageHelperSingleton.setItem(isEditModeOn, "isEditModeOn");
    }, [isEditModeOn]);

    const providerValue = useMemo(() => {
        return { isEditModeOn, tryClaimingEditModeOnObjectAsync, turnEditModeOff, isEditorShown, showEditorMenu, hideEditorMenu, isLocked, toggleIsLocked, setIsLocked, objectIdEdited, setObjectIdEdited, objectTypeEdited, setObjectTypeEdited, setIsEditModeOn };
    }, [isEditModeOn, tryClaimingEditModeOnObjectAsync, turnEditModeOff, isEditorShown, showEditorMenu, hideEditorMenu, isLocked, toggleIsLocked, setIsLocked, objectIdEdited, objectTypeEdited, setIsEditModeOn]);

    // Render
    return (
        <CollaborationContext.Provider value={providerValue}>
            {children}
        </CollaborationContext.Provider>
    );
};
