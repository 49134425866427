// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class ImportController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/import`;

    public async importAsync(file: File): Promise<boolean> {
        try {
            // create form data
            const formData = new FormData();
            // append file to form data
            formData.append("file", file);

            // import file
            const response = await axios
                .post<boolean>(`${this._resourcePath}`, formData);
            
            // if response and response.data are set
            if (response && response.data) {
                // return true
                return true;
            } else {
                // otherwise, return false
                return false;
            }
        } catch {
            // otherwise, return false
            return false;
        }
    }
}

export const ImportControllerSingleton = new ImportController();