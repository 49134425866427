// node_modules
import { CSSProperties, FC, MouseEventHandler, useRef } from "react";
// Custom hooks
import { useClickOutsideRef } from "Hooks";
// Styles
import styles from "./positionedPopup.module.scss";

type TPositionedPopupProps = {
    children: null | (null | JSX.Element)[] | JSX.Element,
    extraClassName?: string,
    extraClassNameInner?: string,
    onClickOutside?: () => void,
    extraStyle?: CSSProperties,
    onMouseEnter?: MouseEventHandler,
    onMouseLeave?: MouseEventHandler
};

export const PositionedPopup: FC<TPositionedPopupProps> = ({
    onMouseEnter,
    onMouseLeave,
    children,
    extraClassName,
    onClickOutside,
    extraStyle,
}: TPositionedPopupProps) => {   
    // Ref
    const popupRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    // Hide popup on click outside
    const onClickOutsideModal = () => {
        if (onClickOutside) onClickOutside();
    };

    useClickOutsideRef(popupRef, onClickOutsideModal);

    return (
        <div
            ref={popupRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`${styles.positionedPopup} ${extraClassName ? extraClassName : ""}`}
            style={extraStyle}
        >
            <div ref={innerRef}>
                {children}
            </div>
        </div>
    );
};