// React
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileHelperSingleton } from "Helpers";
import { FC } from "react";
// Styles
import entityLikeItemStyle from "Styles/EntityLikeList/entityLikeItem.module.scss";
import styles from "./attachmentItem.module.scss";
// Types
import { TSavedFileDTO } from "Types";

type AttachmentItemProps = {
    savedFile: TSavedFileDTO
}

export const AttachmentItem: FC<AttachmentItemProps> = ({ savedFile }: AttachmentItemProps) => {

    const openFile = () => {
        // open document in new tab
        window.open(savedFile.url, "_blank", "noopener noreferrer");
    };

    return (
        <div onClick={openFile} className={entityLikeItemStyle.entityLikeItemContainer}>
            <div className={entityLikeItemStyle.entityLikeItem}>
                <h3 className={styles.title}>
                    <div className={styles.iconContainer}>
                        <FontAwesomeIcon icon={FileHelperSingleton.getIconFromExtension(savedFile.fileExtension)} />
                    </div>
                    {savedFile.title}
                </h3>
            </div>
        </div>
    );
};