// node_modules
import {
    useState,
    MouseEvent,
    useCallback
} from "react";

export const useTextSelection = (): 
        {
            setSelectedTextOnMouseEvent: (mouseEvent: MouseEvent<HTMLElement>) => void, 
            preventUnselection: (mouseEvent: MouseEvent<HTMLElement>) => void, 
            unselect: () => void,
            selectedText: string, 
            selectionBoundingClientRect: DOMRect | undefined
        } => {
    // State
    const [selectedText, setSelectedText] = useState<string>("");
    const [selectionBoundingClientRect, setSelectionBoundingClientRect] = useState<DOMRect | undefined>(undefined);

    // Logic
    const setSelectedTextOnMouseEvent = (mouseEvent: MouseEvent<HTMLElement>): void => {
        mouseEvent.preventDefault();
        mouseEvent.stopPropagation();
        
        const lastSelection: Selection | null = window.getSelection();

        if (!lastSelection || 
                (lastSelection && lastSelection.rangeCount < 1) ||
                (lastSelection && lastSelection.rangeCount > 1 && !lastSelection.getRangeAt(0).getBoundingClientRect())) { 
            resetStateToDefaultValue();
            return; 
        }
        
        setSelectedText(lastSelection.toString());
        setSelectionBoundingClientRect(lastSelection.getRangeAt(0).getBoundingClientRect());
    };

    const preventUnselection = (mouseEvent: MouseEvent<HTMLElement>): void => {
        mouseEvent.preventDefault();
    };

    const unselect = useCallback((): void => {
        const lastSelection: Selection | null = window.getSelection();
        if (lastSelection) { lastSelection.removeAllRanges();}

        resetStateToDefaultValue();
    }, []);

    const resetStateToDefaultValue = (): void => {
        setSelectedText("");
        setSelectionBoundingClientRect(undefined);
    };

    return { setSelectedTextOnMouseEvent, preventUnselection, unselect, selectedText, selectionBoundingClientRect };
};
