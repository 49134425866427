// node_modules
import { BaseType } from "d3";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { ReferencePopover } from "Components/Shared/Modals/ReferenceModal";
// Types
import { TOption } from "Types";
// Enums
import { EntityTypeEnum, GraphViewContainerTypeEnum, ObjectTypeEnum, StudyTypeEnum } from "Enums";
// Helpers
import { ForceGraphHelper } from "Helpers";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Types
import { ILink, INode, TDataObject } from "Types";
// Styles
import styles from "./universeOverview.module.scss";

type TForceDirectedGraphViewProps = {
    selectedFilterOptions: TOption<EntityTypeEnum | StudyTypeEnum>[],
    searchKeyword: string,
    data?: TDataObject,
    containerType: GraphViewContainerTypeEnum,
    minimizeAllWindows?: (exceptId?: string) => void
}

export const ForceDirectedGraphView: FC<TForceDirectedGraphViewProps> = ({ selectedFilterOptions, searchKeyword, data, containerType, minimizeAllWindows }: TForceDirectedGraphViewProps) => {
    const forceGraphContainerRef = useRef<HTMLDivElement>(null);
    const [chartStateVal, setChartStateVal] = useState<Node & { update: (data: TDataObject) => void } | null>(null);

    // State
    const [referencePopoverProps, setReferencePopoverProps] = useState<{ id: string, type: ObjectTypeEnum } | undefined>(undefined); 
    const [popoverReferenceElement, setPopoverReferenceElement] = useState<SVGCircleElement | BaseType | null>(null);
    const [isReferencePopoverOpen, setIsReferencePopoverOpen] = useState<boolean>(false);

    // Hooks
    const navigate = useNavigate();

    // Custom hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal(undefined);

    const navigateToObject = useCallback((object: { objectTypeEnum: ObjectTypeEnum, id: string }) => {
        if (object.objectTypeEnum === ObjectTypeEnum.Entity) {
            navigate(`/library/entities/${object.id}`);
        } else if(object.objectTypeEnum === ObjectTypeEnum.Study) {
            navigate(`/library/studies/${object.id}`);
        }

        // if graph shown in links window and minimizeAllWindows is provided, minimize all windows
        if (containerType === GraphViewContainerTypeEnum.LinksWindow && minimizeAllWindows) {
            minimizeAllWindows(containerType);
        }
    }, [containerType, minimizeAllWindows, navigate]);

    const showPopover = (object: { objectTypeEnum: ObjectTypeEnum, id: string }, currentNode: SVGCircleElement | BaseType) => {
        setIsReferencePopoverOpen(true);
        setReferencePopoverProps({ id: object.id, type: object.objectTypeEnum });
        setPopoverReferenceElement(currentNode);
    };

    const hidePopover = () => {
        setIsReferencePopoverOpen(false);
    };

    useEffect(() => {
        const linkGraphContainer = forceGraphContainerRef.current;
        if (data && linkGraphContainer && !linkGraphContainer.hasChildNodes()) {
            const newChartStateVal = ForceGraphHelper(data, {
                nodeId: d => d.id,
                nodeTitle: (d: INode) => `${d.id} (${d.group})`,
                tooltipClassName: styles.directedGraphNodeTooltip,
                width: linkGraphContainer?.clientWidth,
                height: linkGraphContainer?.clientHeight,
                navigateToObject: navigateToObject,
                showPopover,
                hidePopover,
                containerType
            });
            if (newChartStateVal) {
                setChartStateVal(newChartStateVal);
                linkGraphContainer?.appendChild(newChartStateVal);
            }
        }
    }, [data, navigateToObject, containerType]);

    const searchAndFilterGraph = useCallback(() => {
        if (chartStateVal && data && data.nodes) {
            const newGraphData = { ...data };
            let newNodes = newGraphData.nodes;
            const newLinks: ILink[] = [];
            if (searchKeyword.length > 0) {
                const highlightedNodes = newGraphData.nodes.filter(node => node.data.text.toLowerCase().includes(searchKeyword.toLowerCase())).map(node => ({...node, data: { ...node.data, isHighlighted: true }}));
                const remainingNodes = newGraphData.nodes.filter(node => node.data.text.toLowerCase().includes(searchKeyword.toLowerCase()) === false).map(node => ({...node, data: { ...node.data, isHighlighted: false }}));
                newNodes = highlightedNodes.concat(remainingNodes);
            } else {
                newNodes = newNodes.map(node => ({...node, data: { ...node.data, isHighlighted: true }}));
            }

            if (selectedFilterOptions.length > 0) {
                newNodes = newNodes.reduce((prev: INode[], curr) => {
                    const entityFilterOptions = selectedFilterOptions.filter(opt => (typeof opt.value === "number"));
                    const studyFilterOptions = selectedFilterOptions.filter(opt => (typeof opt.value !== "number"));

                    if (entityFilterOptions.length > 0 && curr.group === "entityNode" && entityFilterOptions.find(e => e.title === curr.data.objectType)) {
                        return [...prev, curr];
                    }

                    if (studyFilterOptions.length > 0 && curr.group === "studyNode" && studyFilterOptions.find(e => e.title === curr.data.objectType)) {
                        return [...prev, curr];
                    }
                    return prev;
                }, []);
            }

            newGraphData.links.forEach(link => {
                if (newNodes.find(node => node.id === link.source) && newNodes.find(node => node.id === link.target)) {
                    newLinks.push(link);
                }
            });

            const newData = { nodes: newNodes, links: newLinks };

            chartStateVal.update(newData);
        }
    }, [chartStateVal, data, searchKeyword, selectedFilterOptions]);

    useEffect(() => {
        if (data) {
            searchAndFilterGraph();
        }
    }, [data, searchAndFilterGraph]);

    return (
        <>        
            <div id={containerType} ref={forceGraphContainerRef} className={styles.graphContainer} />
            {isReferencePopoverOpen && referencePopoverProps && (
                <ReferencePopover
                    isOpen={isReferencePopoverOpen}
                    popoverOffset={6}
                    id={referencePopoverProps.id}
                    type={referencePopoverProps.type}
                    referenceElement={popoverReferenceElement as HTMLElement}
                    setModalProps={setReferenceModalProps}
                    hideReferencePopover={() => { setIsReferencePopoverOpen(false); }}
                    hideRefencePopoverOnOutsideClick
                />
            )}
            {referenceModal}
        </>
    );
};