import { EditableInput } from "Components";
import { FC, MouseEvent } from "react";
// Styles
import styles from "./mainTitle.module.scss";

export type TMainTitleProps = {
    title: string,
    isEditable?: boolean,
    placeholder?: string,
    onUpdateTitle?: (newTitle: string) => void,
    onMouseUp?: (mouseEvent: MouseEvent<HTMLElement>) => void,
    shouldEditableInputAutoGrow?: boolean,
    extraClassName?: string,
    showFullTitleOnHoverOnTooltip?: boolean
};

export const MainTitle: FC<TMainTitleProps> = ({
    title, isEditable = false, placeholder, onUpdateTitle, onMouseUp, shouldEditableInputAutoGrow, extraClassName = "",
    showFullTitleOnHoverOnTooltip
}: TMainTitleProps) => {
    if (isEditable && onUpdateTitle) {
        return (
            <EditableInput 
                shouldAutoGrow={shouldEditableInputAutoGrow}
                showFullTitleOnHoverOnTooltip={showFullTitleOnHoverOnTooltip}
                className={`${styles.mainTitleStyle} ${extraClassName}`}
                value={title} 
                setValue={onUpdateTitle}
                placeholder={placeholder}
            />
        );
    }

    return (
        <p className={`${styles.mainTitleStyle} ${styles.notEditable} ${extraClassName}`} onMouseUp={onMouseUp}>{title}</p>
    );
};