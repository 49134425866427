// React
import { useContext, useEffect } from "react";
// Providers
import { PubSubContext } from "Providers";
// Enums
import { PubSubEventTypeEnum } from "Enums";

export const useSpecificLinkRemovedListener = (mainObjectId: string, onLinkRemoved: (toId: string) => void) => {
    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);

    useEffect(() => {
        pubSubHandler.subscribeToEvent(`${PubSubEventTypeEnum.RemoveLinkFrom}-${mainObjectId}`, onLinkRemoved);

        return () => {
            pubSubHandler.unsubscribeFromEvent(`${PubSubEventTypeEnum.RemoveLinkFrom}-${mainObjectId}`, onLinkRemoved);
        };
    }, [mainObjectId, onLinkRemoved, pubSubHandler]);
};