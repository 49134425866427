// node_modules
import * as d3 from "d3";
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { ReferencePopover } from "Components/Shared/Modals/ReferenceModal";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { ObjectTypeHelperSingleton, UserHelperSingleton } from "Helpers";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Types
import { T2DCoordinates, TMaturityRadarAssessmentDTO, TMaturityRadarDTO, TPolarCoordinates } from "Types";
// Styles
import styles from "../maturityRadar.module.scss";
// Contexts
import { AuthContext } from "Providers";
// Constants
import { MaturityRadarConstants } from "Constants";

// Component props type
type TMaturityRadarSVGProps = {
    maturityRadar: TMaturityRadarDTO | undefined
}

// Types
type TextAlign = "begin" | "middle" | "end";

// Component
export const MaturityRadarSVG: FC<TMaturityRadarSVGProps> = ({ maturityRadar }: TMaturityRadarSVGProps) => {   
    // State
    const [referencePopoverProps, setReferencePopoverProps] = useState<{ id: string, type: ObjectTypeEnum } | undefined>(undefined); 
    const [popoverReferenceElement, setPopoverReferenceElement] = useState<HTMLElement | undefined>(undefined);
    const [isReferencePopoverOpen, setIsReferencePopoverOpen] = useState<boolean>(false);

    // Hooks
    const navigate = useNavigate();

    // Custom hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal(undefined);

    // Contexts
    const { auth } = useContext(AuthContext);

    // Memo
    // one circle revolution
    const oneCircleRevolution = useMemo(() => 2 * Math.PI, []);
    // circles data
    const circlesData = useMemo(() => {
        return [
            { radius: 40, color: "#FFFFFF" },
            { radius: 120, color: "#5EBBED" },
            { radius: 122, color: "#FFFFFF" },
            { radius: 200, color: "#ABDFF7" },
            { radius: 202, color: "#FFFFFF" },
            { radius: 280, color: "#C2E8F9" },
            { radius: 282, color: "#FFFFFF" },
            { radius: 360, color: "#DEF2FC" }
        ];
    }, []);
    // one maturity unit in circle
    const oneMaturityUnitInCircle = useMemo(() => {
        return (circlesData[circlesData.length - 1].radius - circlesData[0].radius) / MaturityRadarConstants.MAX_MATURITY_LEVEL;
    }, [circlesData]);

    // Refs
    const svgRef = useRef(null);
    const isHoveredRef = useRef(false);
    const hoverTimer = useRef<NodeJS.Timeout | null>(null);

    // Logic
    const fromPolarCoordinatesTo2DCoordinates = (polar: TPolarCoordinates): T2DCoordinates => {
        // cartesian coordinates are calculated from polar coordinates
        return {
            x: (polar.r * Math.cos(polar.t)),
            y: (polar.r * Math.sin(polar.t))
        };
    };

    const textAlignToCSSTextAlign = (textAlign: TextAlign): string => {
        // depending on the text align, return the correct css text align
        if (textAlign === "middle") {
            return "center";
        } else if (textAlign === "begin") {
            return "left";
        } else if (textAlign === "end") {
            return "right";
        }
        
        // return center as default
        return "center";
    };

    const textAlignToCSSJustifyContent = (textAlign: TextAlign): string => {
        // depending on the text align, return the correct css justify content
        if (textAlign === "middle") {
            return "center";
        } else if (textAlign === "begin") {
            return "flex-start";
        } else if (textAlign === "end") {
            return "flex-end";
        }

        // return center as default
        return "center";
    };

    const onEllipseTextMouseOver = useCallback((currentNode: HTMLElement, assessment: TMaturityRadarAssessmentDTO): void => {
        // safety-checks
        if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
            // stop execution, return
            return;
        }

        isHoveredRef.current = true;

        hoverTimer.current = setTimeout(() => {
            if (isHoveredRef.current) {
            // set popover reference element
            setPopoverReferenceElement(currentNode);
            // set is reference popover open
            setIsReferencePopoverOpen(true);
            // set reference popover props to show correct reference
            setReferencePopoverProps({
                id: assessment.targetId,
                type: assessment.targetType
            });
            }
        }, 500);
    }, [auth]);

    const onEllipseTextMouseOut = useCallback((): void => {
        // set is reference popover open to false to hide popover
        setIsReferencePopoverOpen(false);

        if (hoverTimer?.current) {
            clearTimeout(hoverTimer.current);
            hoverTimer.current = null;
            isHoveredRef.current = false;
        }
    }, []);

    const onEllipseMouseOver = (currentElementChildren?: NodeListOf<ChildNode>): void => {
        // safety-checks
        if (!currentElementChildren) {
            // stop execution, return
            return;
        }

        // go through each child of the current element
        currentElementChildren.forEach((currentElementChild: ChildNode) => {
            // typecast the current element child to an html element
            const element = currentElementChild as HTMLElement;

            // if the current element child is an ellipse
            if (element.tagName === "ellipse") {
                // set the fill to white
                d3.select(element).style("fill", "#ffffff");
            }
        });
    };

    const onEllipseMouseOut = (currentElementChildren?: NodeListOf<ChildNode>): void => {
        // safety-checks
        if (!currentElementChildren) {
            // stop execution, return
            return;
        }
        
        // go through each child of the current element
        currentElementChildren.forEach((currentElementChild: ChildNode) => {
            // typecast the current element child to an html element
            const element = currentElementChild as HTMLElement;

            // if the current element child is an ellipse
            if (element.tagName === "ellipse") {
                // set the fill to the default color
                d3.select(element)
                    .style("fill", "#5856D6")
                    .style("stroke", "#5856D6")
                    .style("stroke-width", "1");
            }
        });
    };

    // draw the grid layout (maturity radar rings)
    const createGridLayout = useCallback((grid: d3.Selection<SVGGElement, unknown, null, undefined>) => {
        // go through each circle data
        for (let index = circlesData.length; index > 0; index--) {
            // draw the circle
            grid.append("circle")
                .attr("cx", 0)
                .attr("cy", 0)
                .attr("r", circlesData[index - 1].radius)
                .style("fill", circlesData[index - 1].color);
        }
    }, [circlesData]);

    const drawAssessmentDetails = useCallback((grid: d3.Selection<SVGGElement, unknown, null, undefined>, assessment: TMaturityRadarAssessmentDTO, 
            assessmentIndex: number, part: number, cumulative: number, currentMaturityRadar: TMaturityRadarDTO) => {
        // init text related variables
        let textAlign: TextAlign = "begin";
        let textCumulative = cumulative % 1.0;
        let textMargin = 30;
        const defaultTextMargin = 25;
        const azimuth: number = cumulative * oneCircleRevolution;

        // depending on assessment index and text cumulative, set the text align
        if (assessmentIndex === 0) {
            textAlign = "begin";
        } else if (textCumulative >= 0.74 && textCumulative <= 0.76) {
            textAlign = "middle";
        } else if (textCumulative >= 0.24 && textCumulative <= 0.26) {
            textAlign = "end";
        } else if (textCumulative >= 0.26 && textCumulative <= 0.76) {
            textAlign = "end";
        }

        // calculate radial for ellipse polar coordinates
        const ellipseRiadal: number = circlesData[0].radius + (((MaturityRadarConstants.MAX_MATURITY_LEVEL - assessment.lowScore) * oneMaturityUnitInCircle) + 
            oneMaturityUnitInCircle) - ((((assessment.highScore - assessment.lowScore) * oneMaturityUnitInCircle) + oneMaturityUnitInCircle) / 2);

        // init ellipse coordinates
        const ellipsePolarCoordinates: TPolarCoordinates = { r: ellipseRiadal, t: azimuth };
        const ellipse2DCoordinate: T2DCoordinates = fromPolarCoordinatesTo2DCoordinates(ellipsePolarCoordinates);

        // calculate ellipse width
        const ellipseWidth = maturityRadar ? 16 - ((8 / 40) * maturityRadar.assessments.length) : 10;

        // append group to the grid
        const group = grid
            .append("g")
            .attr("class", "circle");

        // append ellipse to the group
        group
            .append("ellipse")
                .on("click", function() {
                    // safety-checks
                    if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
                        // stop execution, return
                        return;
                    }

                    ObjectTypeHelperSingleton
                        .navigateBasedOnObjectType(assessment.targetType, assessment.targetId, navigate);
                })
                .on("mouseover", function() {
                    onEllipseMouseOver(this.parentElement?.childNodes);
                })
                .on("mouseout", function() {
                    onEllipseMouseOut(this.parentElement?.childNodes); 
                })
                .attr("cx", ellipse2DCoordinate.x)
                .attr("cy", ellipse2DCoordinate.y)
                .attr("rx", ellipseWidth)
                .attr("ry", (((assessment.highScore - assessment.lowScore) * oneMaturityUnitInCircle) + oneMaturityUnitInCircle) / 2)
                .attr("transform", `rotate(${-90 + (360 * cumulative)}, ${ellipse2DCoordinate.x}, ${ellipse2DCoordinate.y})`)
                .attr("stroke-width", 1)
                .style("stroke", "#5856D6")
                .style("fill", "#5856D6");
            
        // set text cumulative
        textCumulative = cumulative % 1.0;

        // depending on text cumulative, set the text margin
        if (textCumulative > 0.05 && textCumulative < 0.45) {
            let extraMargin = Math.abs(textCumulative - 0.25);
            extraMargin = 0.20 - (extraMargin);
            textMargin = 30 + (Math.pow(extraMargin, 3) * 15000);
        } else if (textCumulative > 0.55 && textCumulative < 0.95) {
            let extraMargin = Math.abs(textCumulative - 0.75);
            extraMargin = 0.20 - (extraMargin);
            textMargin = 30 + (Math.pow(extraMargin, 3) * 15000);
        } else {
            textMargin = 30;
        }

        // calculate radial for line polar coordinates
        const lineRadial: number = circlesData[0].radius + (((MaturityRadarConstants.MAX_MATURITY_LEVEL - assessment.lowScore) * oneMaturityUnitInCircle) + 
            oneMaturityUnitInCircle);

        // init line coordinates
        const lineBeginPolarCoordinates: TPolarCoordinates = { r: lineRadial, t: azimuth };
        const lineBegin2DCoordinates: T2DCoordinates = fromPolarCoordinatesTo2DCoordinates(lineBeginPolarCoordinates);
        const lineEndPolarCoordinates: TPolarCoordinates = { r: circlesData[circlesData.length - 1].radius + textMargin, t: azimuth };
        const lineEnd2DCoordinates: T2DCoordinates = fromPolarCoordinatesTo2DCoordinates(lineEndPolarCoordinates);

        // append line to the group
        group
            .append("line")
                .on("click", function() {
                    // safety-checks
                    if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
                        // stop execution, return
                        return;
                    }

                    ObjectTypeHelperSingleton
                        .navigateBasedOnObjectType(assessment.targetType, assessment.targetId, navigate);
                })
                .on("mouseover", function() {
                    onEllipseMouseOver(this.parentElement?.childNodes);
                })
                .on("mouseout", function() {
                    onEllipseMouseOut(this.parentElement?.childNodes);
                })
                .attr("x1", lineBegin2DCoordinates.x)
                .attr("y1", lineBegin2DCoordinates.y)
                .attr("x2", lineEnd2DCoordinates.x)
                .attr("y2", lineEnd2DCoordinates.y)
                .attr("stroke-width", 2)
                .style("stroke", "#5856D6");

        // calculate radial for text polar coordinates
        const ellipseTextRadial: number = circlesData[circlesData.length - 1].radius + textMargin + defaultTextMargin;

        // init ellipse text coordinates
        const ellipseTextPolarCoordinates: TPolarCoordinates = { r: ellipseTextRadial, t: azimuth };
        const ellipseText2DCoordinates: T2DCoordinates = fromPolarCoordinatesTo2DCoordinates(ellipseTextPolarCoordinates);

        // calculate ellipse text x and y
        let textForeignObjectX = ellipseText2DCoordinates.x;
        if (textAlign === "end") {
            textForeignObjectX =  textForeignObjectX - 700;
        }
        const textForeignObjectY = ellipseText2DCoordinates.y - defaultTextMargin;

        // append ellipse text to the group
        const foreignObject = group
            .append("foreignObject")
                .on("click", function() {
                    // safety-checks
                    if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
                        // stop execution, return
                        return;
                    }

                    ObjectTypeHelperSingleton
                        .navigateBasedOnObjectType(assessment.targetType, assessment.targetId, navigate);
                })
                .on("mouseover", function() {
                    onEllipseMouseOver(this.parentElement?.childNodes);
                })
                .on("mouseout", function() {
                    onEllipseMouseOut(this.parentElement?.childNodes);
                })
                .attr("height", "64")
                .attr("width", "700")
                .attr("x", textForeignObjectX)
                .attr("y", textForeignObjectY)
                .style("color", "#252525");

                const foreignObjectInnerDiv = foreignObject.append("xhtml:div")
                    .style("text-align", textAlignToCSSTextAlign(textAlign))
                    .style("display", "flex")
                    .style("height", "100%")
                    .style("width", "100%")
                    .style("font-size", "28px")
                    .style("justify-content", textAlignToCSSJustifyContent(textAlign));

                    if (currentMaturityRadar.isNumbered) {
                        foreignObjectInnerDiv.append("xhtml:div")
                            .text(`${assessmentIndex + 1}.`)
                            .style("display", "table-cell")
                            .style("vertical-align", "middle")
                            .style("line-height", "32px")
                            .style("margin-right", "9px");
                    }

                    foreignObjectInnerDiv.append("xhtml:svg")
                        .attr("height", "32")
                        .attr("width", "32")
                        .style("margin-bottom", "2px")
                        .style("color", ObjectTypeHelperSingleton.getObjectTypeColor(assessment.targetType))
                        .classed(ObjectTypeHelperSingleton.getObjectTypeIconClassName(assessment.targetType), true);

                    foreignObjectInnerDiv.append("xhtml:div")
                        .text(assessment.targetTitle)
                        .attr("title", assessment.targetTitle)
                        .attr("data-original", assessment.targetTitle)
                        .attr("data-id", assessment.id)
                        .style("display", "table-cell")
                        .style("margin-left", "9px")
                        .style("vertical-align", "middle")
                        .style("line-height", "32px")
                        .style("cursor", "pointer")
                        .on("mouseover", function() {
                            onEllipseTextMouseOver(this as HTMLElement, assessment);
                        })
                        .on("mouseout", function() {
                            onEllipseTextMouseOut();
                        });

        // return the new cumulative
        return cumulative + part;
    }, [oneCircleRevolution, circlesData, oneMaturityUnitInCircle, maturityRadar, auth, navigate, onEllipseTextMouseOver, onEllipseTextMouseOut]);

    useEffect(() => {
        // get svg element by selecting the ref to it
        // set the viewbox values
        const svgElement = d3.select(svgRef.current)
            .attr("viewBox", "-1115 -575 2200 1125");

        // if svg element already has children, remove them
        svgElement.selectAll("*").remove();
        
        // append a grid element to the svg element
        const grid = svgElement.append("g");

        // create grid layout
        createGridLayout(grid);

        // safety-checks
        if (!maturityRadar || !maturityRadar.assessments || maturityRadar.assessments.length <= 0) {
            // stop execution, return
            return;
        }

        // init cumulative
        // it corresponds to a specific angular position on the radar chart
        // with 1 representing a full circle (360 degrees)
        let cumulative = 0.75;

        // init part
        // used to evenly distribute the assessments around the radar chart
        // each assessment takes up an equal part of the circumference, with some space in between
        const part = 1 / maturityRadar.assessments.length;

        // go through each assessment
        for (let index = 0; index < maturityRadar.assessments.length; index++) {
            // draw the current assessment details
            cumulative = drawAssessmentDetails(
                grid, 
                maturityRadar.assessments[index], 
                index, part, 
                cumulative,
                maturityRadar
            );
        }
    }, [createGridLayout, drawAssessmentDetails, maturityRadar]);

    return (
        <div className={styles.radarContainer}>
            <svg ref={svgRef} />
            {isReferencePopoverOpen && referencePopoverProps && (
                <ReferencePopover
                    isOpen
                    popoverOffset={0}
                    id={referencePopoverProps.id}
                    type={referencePopoverProps.type}
                    referenceElement={popoverReferenceElement}
                    setModalProps={setReferenceModalProps}
                    hideReferencePopover={() => { setIsReferencePopoverOpen(false); }}
                    onMouseEnter={() => { setIsReferencePopoverOpen(true); }}
                    onMouseLeave={() => { setIsReferencePopoverOpen(false); }}
                />
            )}
            {referenceModal}
        </div>
    );

};