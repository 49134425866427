// node_modules
import { Placement } from "@floating-ui/react-dom";
import { faEraser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Components
import { FiveRatingStars, Popover, RatingStar, UserIcon } from "Components";
// Types
import { TAddRatingResponseDTO, TGetRatingOfObjectByIdDTO, TRatingModelDTO, TRatingsPopoverProps, TRemoveRatingDTO } from "Types";
// Controllers
import { RatingControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Styles
import ratingPopoverStyles from "./ratingsPopover.module.scss";
// Contexts
import { AuthContext } from "Providers";

// Props type
type TRatingsPopoverExtraProps = TRatingsPopoverProps & {
    forSourceId?: string,
    forSourceType?: ObjectTypeEnum,
    hideRatingsPopover: () => void,
    onNewAverageRating: (forSourceId: string, forTargetId: string, newCount: number, newScore: number, newIsRatingNeeded: boolean) => void,
    firstPopoverRef?: HTMLDivElement | null,
    onMouseEnter?: () => void,
    onMouseLeave?: () => void,
    popoverPlacement?: Placement
    popoverOffset?: number,
};

// Component
export const RatingsPopover: FC<TRatingsPopoverExtraProps> = ({
    isOpen, forSourceId, forSourceType, forTargetId, styles, currentUserEmail,
    hideRatingsPopover, onNewAverageRating, firstPopoverRef, onMouseEnter, onMouseLeave, usePopoverComponent = true,
    popoverPlacement = "left-start", popoverOffset = 0
}: TRatingsPopoverExtraProps) => {
    // State
    const [ratingOfObject, setRatingOfObject] = useState<TGetRatingOfObjectByIdDTO | undefined>(undefined);
    
    // Contexts
    const { isUserExternal } = useContext(AuthContext);

    // when isOpen, forSourceId, forSourceType, and forTargetId change
    useEffect(() => {
        // if isOpen is false or forSourceId, forSourceType, and forTargetId are undefined
        if (!isOpen || !forSourceId || !forSourceType || !forTargetId) {
            // set rating of object to undefined
            setRatingOfObject(undefined);
            // stop, do not continue
            return;
        }

        (async () => {
            // get rating of object
            const newRatingOfObject: TGetRatingOfObjectByIdDTO | undefined = await RatingControllerSingleton
                .getRatingAsync(forSourceId, ObjectTypeHelperSingleton.getObjectTypeDisplayName(forSourceType).toLowerCase(), forTargetId);

            // safety-checks
            if (!newRatingOfObject) {
                // set rating of object to undefined
                setRatingOfObject(undefined);
                // stop, do not continue
                return;
            }

            // set rating of object
            setRatingOfObject(newRatingOfObject);
        })();

    }, [isOpen, forSourceId, forSourceType, forTargetId]);

    // Memos
    // average rating count
    const averageRatingCount = useMemo((): string => {
        // if ratingOfObject is undefined
        if (!ratingOfObject) {
            // return "0 ratings"
            return "0 ratings";
        }

        // otherwise return average rating count
        return `${ratingOfObject.overview.entities.averageRating.count} ratings`;
    }, [ratingOfObject]);

    // average rating score
    const averageRatingScore = useMemo((): number => {
        // if ratingOfObject is undefined
        if (!ratingOfObject) {
            // return 0
            return 0;
        }

        // otherwise return average rating score
        return ratingOfObject.overview.entities.averageRating.score;
    }, [ratingOfObject]);

    // get is current user email
    const getIsCurrentUserEmail = useCallback((userEmail: string): boolean => {
        // if currentUserEmail is undefined
        if (!currentUserEmail) {
            // return false
            return false;
        }

        // otherwise return if userEmail is equal to currentUserEmail
        return userEmail === currentUserEmail;
    }, [currentUserEmail]);

    // on new score handler
    const onNewScoreHandlerAsync = useCallback(async (ratingId: number, newScore: number): Promise<void> => {
        // safety-checks
        if (!forSourceType || !forSourceId || !forTargetId || !onNewAverageRating) {
            // stop, do not continue
            return;
        }

        // add rating async
        const addRatingResponse: TAddRatingResponseDTO | undefined = await RatingControllerSingleton
            .addRatingAsync(newScore, forSourceType, forSourceId, ObjectTypeEnum.Entity, forTargetId);

        // safety-checks
        if (!addRatingResponse) {
            // show error toast
            ToastHelperSingleton    
                .showToast(ToastTypeEnum.Error, "Failed to add rating.");
            // stop, do not continue
            return;
        }

        // set new ratingOfObject
        setRatingOfObject((prevRatingOfObject: TGetRatingOfObjectByIdDTO | undefined) => {
            // safety-checks
            if (!prevRatingOfObject) {
                // stop, do not continue
                return prevRatingOfObject;
            }

            // init new average rating score
            let newAverageRatingScore: number = newScore;
            // go through each rating in ratingOfObject
            prevRatingOfObject.overview.entities.ratings.forEach((rating: TRatingModelDTO) => {
                // if ratingId is equal to ratingId
                if (rating.ratingId === ratingId) {
                    // if rating was <= 0
                    if (rating.score <= 0) {
                        // increase average rating count
                        prevRatingOfObject.overview.entities.averageRating.count += 1;
                    }
                    
                    // set score to newScore
                    rating.score = newScore;
                } else {
                    // add score to new average rating score
                    newAverageRatingScore += rating.score;
                }
            });
            // set new average rating score
            prevRatingOfObject.overview.entities.averageRating.score = newAverageRatingScore / prevRatingOfObject.overview.entities.averageRating.count;
            
            // call callback to update average rating in parent component
            onNewAverageRating(
                forSourceId, 
                forTargetId, 
                prevRatingOfObject.overview.entities.averageRating.count,
                prevRatingOfObject.overview.entities.averageRating.score,
                false
            );

            hideRatingsPopover();

            // return prevRatingOfObject
            return {
                ...prevRatingOfObject,
            };
        });
    }, [forSourceId, forSourceType, forTargetId, hideRatingsPopover, onNewAverageRating]);

    const onClearHandlerAsync = useCallback(async (ratingId: number): Promise<void> => {
        // safety-checks
        if (!onNewAverageRating || !forSourceId || !forTargetId) {
            // stop, do not continue
            return;
        }

        // remove rating async
        const removeRatingResponse: TRemoveRatingDTO | undefined = await RatingControllerSingleton
            .removeRatingAsync(ratingId);

        // safety-checks
        if (!removeRatingResponse) {
            // show error toast
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Failed to remove rating.");
            // stop, do not continue
            return;
        }

        // set new ratingOfObject
        setRatingOfObject((prevRatingOfObject: TGetRatingOfObjectByIdDTO | undefined) => {
            // safety-checks
            if (!prevRatingOfObject) {
                // stop, do not continue
                return prevRatingOfObject;
            }

            // init new average rating score
            let newAverageRatingScore = 0;

            // go through each rating in ratingOfObject
            prevRatingOfObject.overview.entities.ratings.forEach((rating: TRatingModelDTO) => {
                // if ratingId is equal to ratingId
                if (rating.ratingId === ratingId) {
                    // set score to 0
                    rating.score = 0;
                } else {
                    // add score to new average rating score
                    newAverageRatingScore += rating.score;
                }
            });

            // decrease average rating count
            prevRatingOfObject.overview.entities.averageRating.count -= 1;
            // set new average rating score
            prevRatingOfObject.overview.entities.averageRating.score = newAverageRatingScore / (prevRatingOfObject.overview.entities.averageRating.count === 0 ? 1 : prevRatingOfObject.overview.entities.averageRating.count);

            // call callback to update average rating in parent component
            onNewAverageRating(
                forSourceId, 
                forTargetId, 
                prevRatingOfObject.overview.entities.averageRating.count,
                prevRatingOfObject.overview.entities.averageRating.score,
                true
            );

            // hide ratings popover
            hideRatingsPopover();

            // return prevRatingOfObject
            return {
                ...prevRatingOfObject,
            };
        });
    }, [forSourceId, forTargetId, onNewAverageRating, hideRatingsPopover]);

    const renderContent = (isInPopover?: boolean) => (
        <div className={`${ratingPopoverStyles.ratingsPopover} ${isInPopover ? ratingPopoverStyles.inPopover : ""}`} style={styles} data-is-popover>
            <div className={ratingPopoverStyles.ratingsPopoverTop} >
                <RatingStar
                    size="xlarge"
                    rating={averageRatingScore}
                    isRatingShown={true} />
                <h6 className={ratingPopoverStyles.averageRatingCount}>{averageRatingCount}</h6>
            </div>
            {(ratingOfObject) && (
                <div>
                    {ratingOfObject.overview.entities.ratings.map((rating: TRatingModelDTO) => {
                        const isCurrentUser = getIsCurrentUserEmail(rating.user.fullname);

                        // if is current user and is external
                        if (isCurrentUser && isUserExternal) {
                            // stop execution, return null
                            return null;
                        }

                        return (
                            <div className={ratingPopoverStyles.ratingContainer} key={rating.ratingId}>
                                <div className={`${ratingPopoverStyles.rating} ${isCurrentUser ? ratingPopoverStyles.isCurrentUser : ""}`}>
                                    <div className={ratingPopoverStyles.userDetailsContainer}>
                                        <UserIcon size="large" email={rating.user.fullname} showUserIconTooltip />
                                        {isCurrentUser ? "You" : ""}
                                    </div>
                                    <FiveRatingStars
                                        score={rating.score}
                                        isRatingEditable={isCurrentUser} 
                                        onNewScore={(isCurrentUser) ? (newScore: number) => { onNewScoreHandlerAsync(rating.ratingId, newScore); } : undefined}
                                    />
                                </div>
                                {(isCurrentUser && rating.score > 0) && (
                                    <div className={ratingPopoverStyles.clearRatingContainer} onClick={() => { onClearHandlerAsync(rating.ratingId); }}>
                                        <FontAwesomeIcon icon={faEraser} />
                                        <span>Clear</span>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );

    // Render
    return usePopoverComponent ? (
        <Popover
            extraClassName={ratingPopoverStyles.ratingsPopoverContainer}
            placement={popoverPlacement}
            popoverOffset={popoverOffset}
            showInPortal
            referenceEl={firstPopoverRef ?? null}
            isOpen={isOpen}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {renderContent(true)}
        </Popover>
    ): <div>
        {renderContent()}
    </div>;
};