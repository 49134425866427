// Controllers
import { DocumentControllerSingleton, EntityControllerSingleton, LinkingControllerSingleton } from "Controllers";
// Interfaces
import { IDocumentDetails, IEntityDTO } from "Interfaces";
// Helpers
import { EntityTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Enums
import { ObjectTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
// Constants
import { LinkingConstants } from "Constants";
// Types
import { TIdNameTypeObjectType } from "Types";

export class EntityHelper {
    public async createEntityFromLinkCreatedEntityModal(entity: IEntityDTO, document: IDocumentDetails,
            documentObjectType: ObjectTypeEnum, linkDirection: string,
            setIsLinkCreatedEntityModalOpen: (isOpen: boolean) => void,
            updateDocument?: (document: IDocumentDetails) => void,
            linkedObject?: TIdNameTypeObjectType, logProperties: { ActionOrigin?: string, QueryGuid?: string } = {}) {

        const actionOrigin = logProperties.ActionOrigin;        
        // Create an entity with the selected text as title
        const createdEntity = await EntityControllerSingleton.createAsync(entity, logProperties);

        // Check if the entity has been created otherwise display an error message and stop
        if(!createdEntity) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, `Failed to create entity named ${entity.title}.`);
            return;
        }

        let isDocumentAlreadySaved = false;
        if (document.createdByUsername && document.dateAdded) {
            isDocumentAlreadySaved = true;
        } else if (document.connectedObjects) {
            /**
             * We don’t have any way to tell if the document is saved before from the search results.
             * If we add that information, it might make the search slower.
             * On the list page, check connectedObjects of the document, if there is a connected object, don’t log "SaveDocument".
             * It can still happen that we send too many save document logs,
             * because the document can be saved from plugin or create new modal. This is an edge case.
             */
            isDocumentAlreadySaved = document.connectedObjects.length > 0;
        }

        // Create or get the document to link to the entity
        const savedDocument = await DocumentControllerSingleton
            .createWithoutWebAsync(document.id, document.documentType, !isDocumentAlreadySaved ? logProperties : undefined);

        // Check if the document has been created otherwise display an error message and stop
        if(!savedDocument) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to save document.");
            return;
        }
            
        // Link the document to the entity
        const documentEntityLinkingResponse = await LinkingControllerSingleton.createToAsync(
            savedDocument.id, documentObjectType, createdEntity.id, ObjectTypeEnum.Entity, actionOrigin);
        
        // Check if the document has been linked otherwise display an error message and stop
        const isDocumentEntityLinkSuccess = documentEntityLinkingResponse === WebRequestStatusEnum.Success;
        if(!isDocumentEntityLinkSuccess) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to link document to entity.");
            return;
        }

        // Add the entity to the connected objects of the document
        const newDocument = { ...document };
        newDocument.connectedObjects = [...document.connectedObjects, {
            id: createdEntity.id,
            name: createdEntity.title,
            objectType: ObjectTypeEnum.Entity,
            type: EntityTypeHelperSingleton.getEntityTypeDisplayName(createdEntity.type, createdEntity.customTypeName),
            customTypeName: createdEntity.customTypeName
        }];

        // If the entity should be linked to another object, link it
        if(linkedObject) {
            let objectEntityResponse: WebRequestStatusEnum;
            
            if(linkDirection === LinkingConstants.PARENT_LINK_TYPE.value) {
                objectEntityResponse = await LinkingControllerSingleton.createToAsync(linkedObject.id,
                    linkedObject.objectType, createdEntity.id, ObjectTypeEnum.Entity, actionOrigin);
            } else {
                objectEntityResponse = await LinkingControllerSingleton.createToAsync(createdEntity.id, 
                    ObjectTypeEnum.Entity, linkedObject.id, linkedObject.objectType, actionOrigin);
            }

            // Check if the document has been linked otherwise display an error message and stop
            const isObjectEntityLinkSuccess = objectEntityResponse === WebRequestStatusEnum.Success;
            if(!isObjectEntityLinkSuccess) {
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to link entity to object.");
            }
        }

        // Refresh the document
        if(updateDocument) updateDocument(newDocument);
        setIsLinkCreatedEntityModalOpen(false);
        return createdEntity;
    }
}

export const EntityHelperSingleton = new EntityHelper();
