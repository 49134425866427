export enum StudyTypeEnum {
    Undefined = "StudyTypeUndefined",
    Custom = "StudyTypeCustom",
    NewProductDevelopmentProject = "NewProductDevelopmentProject",
    ResearchProject = "ResearchProject",
    Ideation = "Ideation",
    LiteratureStudy = "LiteratureStudy",
    TechnologyScouting = "TechnologyScouting",
    ExperimentalStudy = "ExperimentalStudy",
    Characterization = "Characterization",
    FeasibilityStudy = "FeasibilityStudy",
    MarketResearch = "MarketResearch",
    Troubleshooting = "Troubleshooting",
    ConvertToEntity = "ConvertToEntity",
    IntakeSheet = "IntakeSheet"
}
