export const WebsocketFunctionNames = {
    ReceiveToast: "ReceiveToast",
    StartSearchQueryById: "StartSearchByQueryId",
    ReceiveDocumentSearchResults: "DocumentSearchResults",
    RequestDocumentContentHighlight: "RequestDocumentContentHighlight",
    ReceiveDocumentCount: "ReceiveDocumentCount",
    HighlightingFinished: "HighlightingFinished",
    GroupedDocumentSearchResults: "GroupedDocumentSearchResults",
    ReceivePublicationsPerYearSearchResults: "ReceivePublicationsPerYearSearchResults",
    SearchEnded: "ReceiveEndProgress",
    ReceiveSearchTermHitCounts: "ReceiveSearchTermHitCounts",
    ReceiveGroupedDocuments: "ReceiveGroupedDocuments",
    ReceiveAffiliationSearchResults: "ReceiveAffiliationSearchResults",
    ReceiveSearchId: "ReceiveSearchID",
    CancelSearch: "CancelSearch",
    ReceiveCompletionId: "ReceiveCompletionId",
    ReceiveCompletionPart: "ReceiveCompletionPart",
    ReceiveCompletionResult: "ReceiveCompletionResult",
    CancelCompletion: "CancelCompletion",
    StartQuestionAndAnswerCompletion: "StartQuestionAndAnswerCompletion",
    StartExecutiveSummaryCompletion: "StartExecutiveSummaryCompletion",
    StartDescriptionCompletion: "StartDescriptionCompletion",
    StartWriteSectionCompletion: "StartWriteSectionCompletion",
    StartTableCompletion: "StartTableCompletion"
} as const;
