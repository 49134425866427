// React
import { FC } from "react";
// Components
import { RolesChecker } from "./RolesChecker";
// Enums
import { RolesEnum } from "Enums";

type TIsFindestAdministratorProps = {
    children: React.ReactNode;
};

export const IsFindestAdministrator: FC<TIsFindestAdministratorProps> = ({ children }) => {
    return (
        <RolesChecker roles={[RolesEnum.FindestAdministrator]}>
            {children}
        </RolesChecker>
    );
};