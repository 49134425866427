// node_modules
import { FC } from "react";
// Styles
import styles from "./searchTermHitsCount.module.scss";

type TSearchTermHitsCountProps = {
    hitsCount: number,
    isDisabled: boolean
}

export const SearchTermHitsCount: FC<TSearchTermHitsCountProps> = ({ hitsCount, isDisabled }: TSearchTermHitsCountProps) => {
    return (
        <div className={`${styles.hitsCountContainer} ${isDisabled ? styles.disabled : ""}`} title="Search term count">{hitsCount > 10000 ? "10000+" : hitsCount}</div>
    );
};
