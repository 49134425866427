// node_modules
import { Dispatch, MouseEvent, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
// Enums
import { LinkStatusEnum, LinksWindowTabsEnum, ObjectTypeEnum, SavedDocumentTypeEnum, SortTypeEnum, ToastTypeEnum } from "Enums";
// Components
import { AddImageModal, ConnectedQueries, ContextedEditableMarkdown, FileUploadProgressIndicator, HasAdvanced, LinkingModal, MaturityRadar, PageComments, ReferencedBy, RequirementsTable, SavedDocuments, SharingModal } from "Components";
// Contexts
import { AuthContext, CollaborationContext, EditorContext, EditorHeadersContext, EditorReferencesContext, ElementVisibilityContext, LinkGraphContext, WindowingContext } from "Providers";
// Types
import { TDropdownButtonOption, TIdNameTypeObjectType, TOption, TSavedFileDTO } from "Types";
// Constants
import { FeatureToggleConstants, LinkingConstants } from "Constants";
// Controllers
import { SavedFileControllerSingleton } from "Controllers";
// Helpers
import { ToastHelperSingleton, UserHelperSingleton } from "Helpers";
// Interfaces
import { IEntityDTO, IObject, ISavedDocumentDTO } from "Interfaces";
// Styles
import entityLikeCardStyles from "Styles/entityLikeCard.module.scss";

type TObjectDetailsProps<T extends IObject> = {
    children?: React.ReactNode,
    object?: T,
    setObject: Dispatch<SetStateAction<T | undefined>>,
    objectType: ObjectTypeEnum,
    type: string,
    onImageSubmittedAsync: (image: File, caption?: string) => Promise<void>,
    onDeleteClickAsync: () => Promise<void>,
    onSourceChange: (newValue: string) => void,
    onForceSourceChange?: (newValue: string) => void,
    noSourcePlaceholder: string,
    refreshDocumentsAsync: (fromDate: Date | undefined, selectedFilterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[], sortType: SortTypeEnum, callback?: (newSavedDocuments: ISavedDocumentDTO[]) => void) => Promise<void>,
    deleteSavedDocumentAsync?: (savedDocumentsToDelete: ISavedDocumentDTO[]) => Promise<void>
}

export function ObjectDetails<T extends IObject>({ children, object, setObject,
    objectType, type, onImageSubmittedAsync, onDeleteClickAsync,
    onSourceChange, noSourcePlaceholder, onForceSourceChange,
    refreshDocumentsAsync, deleteSavedDocumentAsync
}: TObjectDetailsProps<T>) {
    // Hooks
    const [searchParams, setSearchParams] = useSearchParams();

    // Context
    const { isUserExternal, auth, isUserViewer } = useContext(AuthContext);
    const {
        setObjectCreatedByUsername,
        setObjectCreatedOnDate,
        setSavedDocumentsCount,
        setObjectId,
        setObjectName,
        onActionsClickRef,
        onAttachFileClickRef,
        focusEditor } = useContext(EditorContext);
    const { setIsScrollingAreaPositionTop, changeSelectedEditorHeader, isScrollingAreaPositionTop } = useContext(EditorHeadersContext);
    const {
        isLinkingModalOpen,
        setIsLinkingModalOpen,
        linkToName,
        setLinkToName,
        selectedTextPopupContainerProps
    } = useContext(EditorReferencesContext);
    const { isEditModeOn, toggleIsLocked, isLocked, setIsLocked } = useContext(CollaborationContext);
    const { canUserEdit } = useContext(ElementVisibilityContext);
    const { onReanchorClick } = useContext(LinkGraphContext);
    const { openGraph } = useContext(WindowingContext);

    // State
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [isSharingModalOpen, setIsSharingModalOpen] = useState<boolean>(false);
    const [fileUploadProgress, setFileUploadProgress] = useState<number | undefined>(undefined);

    // Refs
    const abortControllerRef = useRef<AbortController>(new AbortController());

    // Memos
    const isDescriptionEmpty = useMemo(() => {
        if (!object?.description || object.description.trim().length === 0) return true;
        // Remove things which could be added by the editor when empty
        const cleanedDescription = object.description.replaceAll(":::", "").replaceAll("break", "").replaceAll("#", "");
        // Check if the description would be empty when cleaned
        return cleanedDescription.trim().length === 0;
    }, [object?.description]);

    useEffect(() => {
        // if the user cannot edit then do nothing
        if (canUserEdit || isLocked) return;

        // If the linking parameter is enabled then open the linking modal 
        // and remove the linking parameter
        if (searchParams.has("isLinking")) {
            setIsLinkingModalOpen(true);
            setSearchParams({});
        }
    }, [isLocked, canUserEdit, searchParams, setIsLinkingModalOpen, setSearchParams]);

    useEffect(() => {
        // safety-checks
        if (!object) {
            return;
        }
        // update object created by username, created on date and saved documents count properties in the editor context
        setIsLocked(object.isLocked);
        setObjectCreatedByUsername(object.createdByUsername);
        setObjectCreatedOnDate(object.createdOnDate);
        setSavedDocumentsCount(object.savedDocuments.length);
        setObjectId(object.id);
        setObjectName(object.title);
        }, [object, object?.createdByUsername, object?.createdOnDate, object?.savedDocuments, setObjectCreatedByUsername, setObjectCreatedOnDate, setSavedDocumentsCount, setObjectId, setObjectName, isEditModeOn, isUserExternal, isUserViewer, toggleIsLocked, setIsLocked]);

    const onShareClick = useCallback(() => {
        // if the user is readonly then do nothing
        if (isUserExternal) return;
        // Open the sharing modal
        setIsSharingModalOpen(true);
    }, [isUserExternal]);

    const onAddLinkClick = useCallback(() => {
        // if the user is readonly then do nothing
        if (!canUserEdit) return;
        // Open the linking modal
        setIsLinkingModalOpen(true);
    }, [canUserEdit, setIsLinkingModalOpen]);

    const onAddImageClick = useCallback(() => {
        // if the user is readonly then do nothing
        if (!canUserEdit || isLocked) return;

        setIsImageModalOpen(true);
    }, [canUserEdit, isLocked]);

    const onSetPageLock = useCallback(async (newIsLocked: boolean) => {
        // if the user is readonly then do nothing and if object is undefined then do nothing
        if (isUserExternal || !object) return;
        
        if (newIsLocked) {
            await toggleIsLocked(object.id, newIsLocked);
         
        }else{
            await toggleIsLocked(object.id,false);
        }

        // Update the object's lock state
        setObject({
            ...object,
            isLocked: newIsLocked
        });
    }, [isUserExternal, object, setObject, toggleIsLocked]);

    const clickActionsOptionAsync = useCallback(async (option: TDropdownButtonOption) => {
        // if the user is readonly then do nothing
        if (!canUserEdit) return;

        if (option === "add image") {
            onAddImageClick();
        } else if (option === "create link") {
            onAddLinkClick();
        } else if (option === "delete") {
            onDeleteClickAsync();
        } else if (option === "share") {
            onShareClick();
        } else if (option === "unlock page") {
            onSetPageLock(false);
        } else if (option === "lock page") {
            onSetPageLock(true);
        } else if (option === "open in tree view" && object) {
            onReanchorClick(object.id, objectType, false);
            openGraph(LinksWindowTabsEnum.TreeView);
        } else if (option === "open in list view" && object) {
            onReanchorClick(object.id, objectType, false);
            openGraph(LinksWindowTabsEnum.ListView);
        }
    }, [canUserEdit, object, objectType, onAddImageClick, onAddLinkClick, onDeleteClickAsync, onReanchorClick, onSetPageLock, onShareClick, openGraph]);

    const onFileSelected = useCallback(async (file: File): Promise<TSavedFileDTO | undefined> => {
        // if the user is readonly or object is undefined then do nothing
        if (!canUserEdit || isLocked || !object) return undefined;

        // init abort controller
        abortControllerRef.current = new AbortController();

        // Start uploading and creating file
        const createdFile = await SavedFileControllerSingleton
            .createSavedFileUsingForm(file, file.name, object.id, objectType,
                setFileUploadProgress, abortControllerRef.current.signal);
        if (!createdFile) {
            // Check if upload was aborted
            if (createdFile === null) {
                return undefined;
            }

            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not attach file.");
            return undefined;
        }

        setObject({
            ...object,
            linkedFiles: [...object.linkedFiles, createdFile]
        });

        // return created file
        return createdFile;
    }, [isLocked, canUserEdit, object, objectType, setObject]);

    useEffect(() => {
        // set the on actions click function in the editor context
        onActionsClickRef.current = clickActionsOptionAsync;
        // set the on attach file click function in the editor context
        onAttachFileClickRef.current = onFileSelected;
    }, [clickActionsOptionAsync, onActionsClickRef, onAttachFileClickRef, onFileSelected]);

    const onLinkingModalClose = useCallback(() => {
        // focus on editor && set link to name to undefined
        // (we could have open it through save as entity menu option in SelectedTextActionsPopup)
        focusEditor();
        setLinkToName(undefined);
    }, [focusEditor, setLinkToName]);

    const excludedSearchTypes = useMemo((): ObjectTypeEnum[] => {
        // if link to name is defined, exclude the study type
        // (we could have open linking modal through save as entity menu option in SelectedTextActionsPopup)
        // and in this case we want to exclude the study type
        if (linkToName) {
            return [ObjectTypeEnum.Study];
        } else {
            return [];
        }
    }, [linkToName]);

    const onSaveClickCallback = useCallback((selectedLinkToObject: TIdNameTypeObjectType): void => {
        // if link to name is defined and selected object is an entity
        // (we could have open linking modal through save as entity menu option in SelectedTextActionsPopup)
        if (linkToName && selectedLinkToObject.objectType === ObjectTypeEnum.Entity) {
            // focus on editor
            focusEditor();

            // init new entity
            const entity: IEntityDTO = {
                id: selectedLinkToObject.id,
                title: selectedLinkToObject.name
            } as IEntityDTO;

            // call save as entity callback and open entity in new tab
            selectedTextPopupContainerProps.saveAsEntityCallback(
                entity,
                () => window.open(`/library/entities/${entity.id}`, "_blank", "noopener noreferrer")
            );
        } else {
            // otherwise do nothing
            return;
        }
    }, [focusEditor, linkToName, selectedTextPopupContainerProps]);

    // if object is undefined then return an empty div
    if (!object) return (<div></div>);

    const scrollEvent = (e: MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        if (target.scrollTop > 0) {
            if (isScrollingAreaPositionTop) {
                setIsScrollingAreaPositionTop(false);
            }
        } else {
            setIsScrollingAreaPositionTop(true);
        }
        changeSelectedEditorHeader(target.scrollTop);
    };

    const onCancelUpload = () => {
        // abort 
        abortControllerRef.current.abort();
        setFileUploadProgress(undefined);
    };

    return (
        <div className={entityLikeCardStyles.entityLikeCard} onScroll={scrollEvent}>
            <div className={`${entityLikeCardStyles.isScrollingElement} ${isScrollingAreaPositionTop ? "" : entityLikeCardStyles.isScrolling}`}></div>
            {children}
            {(!UserHelperSingleton.isSharingRestrictedToObject(auth)) && (
                <ReferencedBy referencedBy={object.referencedBy} />
            )}
            <div className={entityLikeCardStyles.entityLikeCardContentContainer}>
                <div className={`${entityLikeCardStyles.entityLikeCardInformationContainer}`}>
                    <div className={entityLikeCardStyles.entityDescriptionContainer}>
                        <ContextedEditableMarkdown
                            idEdited={object.id}
                            typeEdited={objectType}
                            source={!isDescriptionEmpty ? object.description : ""}
                            showPlaceholderInEditMode={true}
                            noSourcePlaceholder={noSourcePlaceholder}
                            onSourceChange={onSourceChange}
                            onForceSourceChange={onForceSourceChange}
                            doForceViewMode={!canUserEdit || isLocked} />
                    </div>
                </div>
                {(FeatureToggleConstants.RequirementsTable) && (
                    <div className={entityLikeCardStyles.entityLikeCardConnectedDocumentsContainer}>
                        <RequirementsTable shouldShowEditingOptions={isEditModeOn} objectType={objectType} objectId={object.id} />
                    </div>
                )}
                {(FeatureToggleConstants.MaturityRadar) && (
                    <div>
                        <MaturityRadar
                            objectId={object.id}
                            objectType={objectType}
                            shouldShowEditingOptions={isEditModeOn}
                        />
                    </div>
                )}
                <div className={entityLikeCardStyles.entityLikeCardConnectedDocumentsContainer}>
                    <SavedDocuments
                        linkedToObjectId={object.id}
                        header={"Linked documents"}
                        documents={object.savedDocuments}
                        doUseSavedFilters={false}
                        totalDocumentsCount={object.totalDocumentsCount}
                        refreshDocumentsAsync={refreshDocumentsAsync}
                        deleteSavedDocumentAsync={(!canUserEdit || isLocked) ? undefined : deleteSavedDocumentAsync}
                        deleteIsUnlink={true}
                        doHideIfNoDocumentsLinked={true}
                        isEditable={canUserEdit && !isLocked}
                    />
                </div>
                <HasAdvanced>
                    <div className={entityLikeCardStyles.entityLikeCardConnectedQueriesContainer}>
                        <ConnectedQueries objectId={object.id} objectName={object.title} objectType={objectType} />
                    </div>
                </HasAdvanced>
                <div className={entityLikeCardStyles.entityLikeCardPageCommentsContainer}>
                    <PageComments currentUsername={auth.userEmail} objectType={objectType} objectId={object.id} />
                </div>
            </div>
            <LinkingModal
                linkToName={linkToName}
                isOpen={isLinkingModalOpen}
                setIsOpen={setIsLinkingModalOpen}
                selectedObjects={[{ id: object.id, name: object.title, type, objectType }]}
                defaultLinkType={LinkingConstants.CHILD_LINK_TYPE}
                excludedSearchTypes={excludedSearchTypes}
                onClose={onLinkingModalClose}
                onSaveClickCallback={onSaveClickCallback} />
            <AddImageModal
                isOpen={isImageModalOpen}
                setIsOpen={setIsImageModalOpen}
                onAddImage={onImageSubmittedAsync}
                hasCaption={true} />
            <SharingModal
                isOpen={isSharingModalOpen}
                setIsOpen={setIsSharingModalOpen}
                objectId={object.id}
                objectType={objectType} />
            <FileUploadProgressIndicator onCancelClick={onCancelUpload} fileUploadProgress={fileUploadProgress} />
        </div>
    );
}