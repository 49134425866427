// node_modules
import { faArrowsRotate, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
// Components
import { EditableLabelledCheckbox } from "Components";
// Styles
import styles from "./synonym.module.scss";
// Types
import { TSynonymDTO } from "Types";

type TSynonymProps = {
    synonym: TSynonymDTO,
    updateSynonymRequiredAsync: (synonymId: number, updatedSynonymRequired: boolean) => Promise<void>,
    updateSynonymValueAsync: (synonymId: number, updatedSynonymValue: string) => Promise<void>,
    deleteSynonymAsync: (synonymToDelete: TSynonymDTO) => Promise<void>
    onShowSynonymsClick: (synonymValue: string) => Promise<void>
}

export const Synonym: FC<TSynonymProps> = ({ synonym, updateSynonymRequiredAsync, 
    updateSynonymValueAsync, deleteSynonymAsync, onShowSynonymsClick}: TSynonymProps) => {
    // Render
    return (
        <div className={styles.findestSynonym}>
            <EditableLabelledCheckbox 
                label={synonym.text} 
                placeholder={"Synonym"} 
                checked={synonym.required}
                onCheckboxChange={(checked: boolean) => { updateSynonymRequiredAsync(synonym.id, checked); }} 
                onLabelChange={(newLabelValue: string) => { updateSynonymValueAsync(synonym.id, newLabelValue); }} 
            /> 
            <div title="Show synonyms">
                <FontAwesomeIcon className={styles.showSynonym} icon={faArrowsRotate} onClick={() => { onShowSynonymsClick(synonym.text);}} />
            </div>
            <div title="Delete synonym">
                <FontAwesomeIcon className={`${styles.deleteSynonym} ${!synonym.required ? styles.hidden : ""}`} icon={faTrashAlt} onClick={() => { deleteSynonymAsync(synonym); }} />
            </div>
        </div>
    );
};