// Types
import { TMaturityRadarDTO } from "Types";
// Constants
import { MaturityRadarConstants } from "Constants";

export class MaturityLevelHelper {
    public getAverage(maturityLevels: TMaturityRadarDTO[], forTargetId: string): { averageMin: number, averageMax: number } {
        // init new average
        let averageMin = 0;
        let averageMax = 0;
        let averageCount = 0;

        // go through each maturity level
        for (const maturityLevel of maturityLevels) {
            // go through each assessment
            for (const assessment of maturityLevel.assessments) {
                // if assessment target id is the same as the target id we are looking for
                if (assessment.targetId === forTargetId) {
                    // add to average
                    averageMin += assessment.lowScore;
                    averageMax += assessment.highScore;
                    // increase count
                    averageCount++;
                }
            }
        }

        // return average
        return {
            averageMin: averageMin / averageCount,
            averageMax: averageMax / averageCount
        };
    }

    public sanitizeValues(min: number, max: number): { newSafeMin: number, newSafeMax: number } {
        // init new safe min and max
        let newSafeMin = min;
        let newSafeMax = max;

        // if min is under min, set it to min
        // if min is over max minus step, set it to max minus step
        if (newSafeMin < MaturityRadarConstants.MIN_MATURITY_LEVEL) {
            newSafeMin = MaturityRadarConstants.MIN_MATURITY_LEVEL;
        } else if (newSafeMin > (MaturityRadarConstants.MAX_MATURITY_LEVEL - MaturityRadarConstants.MATURITY_LEVEL_STEP)) {
            newSafeMin = MaturityRadarConstants.MAX_MATURITY_LEVEL - MaturityRadarConstants.MATURITY_LEVEL_STEP;
        }

        // if max is under min plus step, set it to min plus step
        // if max is over max, set it to max
        if (newSafeMax < (MaturityRadarConstants.MIN_MATURITY_LEVEL + MaturityRadarConstants.MATURITY_LEVEL_STEP)) {
            newSafeMax = MaturityRadarConstants.MIN_MATURITY_LEVEL + MaturityRadarConstants.MATURITY_LEVEL_STEP;
        } else if (newSafeMax > MaturityRadarConstants.MAX_MATURITY_LEVEL) {
            newSafeMax = MaturityRadarConstants.MAX_MATURITY_LEVEL;
        }

        // if min and max are equal, add step to max except if max is max, then subtract step from min
        if (newSafeMin === newSafeMax) {
            if (newSafeMax !== MaturityRadarConstants.MAX_MATURITY_LEVEL) {
                newSafeMax += MaturityRadarConstants.MATURITY_LEVEL_STEP;
            } else {
                newSafeMin -= MaturityRadarConstants.MATURITY_LEVEL_STEP;
            }
        }

        // if max is smaller than min, set max to min + step
        if (newSafeMax < newSafeMin) {
            newSafeMax = newSafeMin + MaturityRadarConstants.MATURITY_LEVEL_STEP;
        }

        // if min is bigger than max, set min to max - step
        if (newSafeMin > newSafeMax) {
            newSafeMin = newSafeMax - MaturityRadarConstants.MATURITY_LEVEL_STEP;
        }

        // return new safe min and max
        return { newSafeMin, newSafeMax };
    }
}

export const MaturityLevelHelperSingleton = new MaturityLevelHelper();