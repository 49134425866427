// node_modules
import { FC, useMemo } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import { ConnectedObject } from "./ConnectedObject/ConnectedObject";
// Styles
import styles from "./connectedObjects.module.scss";
// Interfaces
import { IConnectedObjectsProps } from "Interfaces";
// Helpers
import { ConnectedObjectsHelperSingleton } from "Helpers";

export const ConnectedObjects: FC<IConnectedObjectsProps> = ({ mainObjectId, connectedObjects, onConnectToObjectClick, 
        extraClassName, disableConnectToNewObjectButton, setContainerElementReference, doHideTitleOnEmptyOrUnsetConnectedObjects }) => {

    const onRemoveClick = async (connectedObjectToRemove: TIdNameTypeObjectType) => {
        // remove connected object from query
        await ConnectedObjectsHelperSingleton
            .removeConnectedObjectFromObjectAsync(connectedObjectToRemove, mainObjectId);
    };

    const isTitleHidden = useMemo((): boolean => {
        // if doHideTitleOnEmptyOrUnsetConnectedObjects is true and there are no connected objects
        if (doHideTitleOnEmptyOrUnsetConnectedObjects && (!connectedObjects || connectedObjects.length <= 0)) {
            // return true
            return true;
        }

        // otherwise, return false
        return false;
    }, [connectedObjects, doHideTitleOnEmptyOrUnsetConnectedObjects]);

    // Render
    return (
        <div className={[styles.connectionsSectionContainer, extraClassName ? extraClassName : null].join(" ")}>            
            {(!isTitleHidden) && (<h6 className={styles.connectionsTitle}>Connections</h6>)}
            <div className={styles.connectionsContainer}>
                {connectedObjects.map((connectedObject: TIdNameTypeObjectType) => {
                    return (
                        <ConnectedObject
                            key={connectedObject.id}
                            connectedObject={connectedObject}
                            onRemoveConnectionClickAsync={() => onRemoveClick(connectedObject)} />
                    );
                })}
                {!disableConnectToNewObjectButton ?
                        <div ref={setContainerElementReference} className={styles.connectToNewObject}
                            onClick={onConnectToObjectClick}>
                            <span>+ Connect to Entity or Study</span>
                        </div>
                    :
                        null
                }
            </div>
        </div>
    );
};
