// node_modules
import { FC, useState } from "react";
// Styles
import styles from "./createNewButton.module.scss";
// Components
import { CreateNewModal } from "./CreateNewModal";

export const CreateNewButton: FC = () => {
    // State
    const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState<boolean>(false);

    // Render
    return (
        <div>
            <CreateNewModal 
                isOpen={isCreateNewModalOpen}
                closeModal={() => setIsCreateNewModalOpen(false)} />
            <div
                className={styles.createButton}
                onClick={() => setIsCreateNewModalOpen(true)}>
                Create new
            </div>
        </div>
    );
};