// node_modules
import { FC } from "react";
// Enums
import { AskAIAssistantMenuItemEnum } from "Enums";
// Helpers
import { AskAIAssistantMenuItemHelperSingleton } from "Helpers/Enums/AskAIAssistantMenuItemHelper";
// Styles
import styles from "./askAIAssistantModalMenuItem.module.scss";

type TAskAIAssistantModalMenuItemProps = {
    item: AskAIAssistantMenuItemEnum,
    isSelected: boolean,
    onClick: (item: AskAIAssistantMenuItemEnum) => void
};

export const AskAIAssistantModalMenuItem: FC<TAskAIAssistantModalMenuItemProps> = ({ item, isSelected, onClick }: TAskAIAssistantModalMenuItemProps) => {
    return (
        <div className={styles.askAIAssistantModalMenuItemContainer}>
            <div onClick={() => { onClick(item); }} className={[styles.askAIAssistantModalMenuItem, isSelected ? styles.selected : ""].join(" ")}>
                {AskAIAssistantMenuItemHelperSingleton.askAIAssistantMenuItemEnumToDisplayString(item)}
            </div>
        </div>
    );
};