// node_modules
import { Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";

// init plugin key
export const openLinkPluginKey = new PluginKey(
    "openLinkPlugin",
);

// define plugin
export const openLinkPlugin = () => {
    return new Plugin({
        key: openLinkPluginKey,
        props: {
            handleDoubleClick: (_: EditorView, __: number, event: MouseEvent): boolean | void | undefined => {
                // handle click event
                return ProseMirrorHelperSingleton.handleClickEvent(event);
            }
        },
    });
};
