// Enums
import { SavedDocumentTypeEnum } from "Enums";
// Types
import { THeaderContentDTO } from "Types";
// Interfaces
import { IAuthorships, IConnectedObjects, IDocumentDetails, IHighlights, IImages, IIsOpenAccess, ISavedDocumentDTO, ISearchInformation } from "Interfaces";

export interface IDocumentSearchResult extends IConnectedObjects, ISearchInformation, IAuthorships, IIsOpenAccess, IImages, IHighlights {
    documentId: string,
    documentType: SavedDocumentTypeEnum,
    title: string,
    url: string,
    mainContents: THeaderContentDTO[] | undefined | null,
    isAlreadyRead: boolean,
    dateAdded?: Date,
    createdByUsername?: string
}

export const fromIDocumentDetails = (documentDetails: IDocumentDetails): IDocumentSearchResult => {
    return {
        documentId: documentDetails.id,
        documentType: documentDetails.documentType,
        title: documentDetails.title,
        url: documentDetails.url,
        mainContents: documentDetails.mainContents,
        searchInformation: documentDetails.searchInformation,
        authorships: documentDetails.authorships,
        connectedObjects: documentDetails.connectedObjects,
        isOpenAccess: documentDetails.isOpenAccess,
        highlights: documentDetails.highlights,
        images: documentDetails.images,
        isAlreadyRead: documentDetails.isAlreadyRead
    };
};

export const convertFromISavedDocumentDTO = (savedDocumentDTO: ISavedDocumentDTO): IDocumentSearchResult => {
   
    const mainContents: THeaderContentDTO[] = [];

    if (savedDocumentDTO.abstract) {
        mainContents.push({
            header: "Abstract",
            content: savedDocumentDTO.abstract
        });
    }

    return {
        documentId: savedDocumentDTO.id,
        documentType: savedDocumentDTO.savedDocumentType,
        title: savedDocumentDTO.title,
        url: savedDocumentDTO.url,
        mainContents: mainContents,
        searchInformation: savedDocumentDTO.searchInformation,
        authorships: savedDocumentDTO.authorships,
        connectedObjects: savedDocumentDTO.connectedObjects,
        isOpenAccess: savedDocumentDTO.isOpenAccess,
        highlights: savedDocumentDTO.highlights,
        images: savedDocumentDTO.images,
        isAlreadyRead: false
    };
};
