// node_modules
import { FC, useMemo } from "react";
// Styles
import styles from "./maturityLevelScale.module.scss";
// Constants
import { MaturityRadarConstants } from "Constants";

// Component props type
type TMaturityLevelScaleProps = {
    min: number,
    max: number,
    extraClassNames?: { 
        container?: string,
        stepsTextsContainer?: string,
        actualStep?: string 
    },
    showStepsTexts?: boolean
};

// Component constants
const MATURITY_LEVEL_STEP_PERCENTAGE = 100 / MaturityRadarConstants.MAX_MATURITY_LEVEL;

export const MaturityLevelScale: FC<TMaturityLevelScaleProps> = ({ min, max, extraClassNames = {}, showStepsTexts } : TMaturityLevelScaleProps) => {
    // Memo
    const actualStepWidth = useMemo((): number => {
        // get difference between min and max
        const difference = max - min;

        // init new actual step width
        let newActualStepWidth = difference * MATURITY_LEVEL_STEP_PERCENTAGE;

        // if min is less than max, add step percentage to the actual step width
        if (min < MaturityRadarConstants.MAX_MATURITY_LEVEL) newActualStepWidth += MATURITY_LEVEL_STEP_PERCENTAGE;

        // return the actual step width
        return newActualStepWidth;
    }, [max, min]);

    const actualStepLeft = useMemo((): number => {
        // init new actual step left
        let newActualStepLeft = 0;

        // if min is greater than min
        if (min > MaturityRadarConstants.MIN_MATURITY_LEVEL) {
            // set new actual step left to min * step percentage - step percentage
            newActualStepLeft = (min * MATURITY_LEVEL_STEP_PERCENTAGE) - MATURITY_LEVEL_STEP_PERCENTAGE;
        }

        // return the actual step left
        return newActualStepLeft;
    }, [min]);

    // Render
    return (
        <div>
            <div className={`${styles.stepsContainer} ${extraClassNames.container ?? ""}`}>
                <div className={`${styles.step} ${styles.fourthStep}`} />
                <div className={`${styles.step} ${styles.thirdStep}`} />
                <div className={`${styles.step} ${styles.secondStep}`} />
                <div className={`${styles.step} ${styles.firstStep}`} />
                <div className={`${styles.actualStep} ${extraClassNames.actualStep ?? ""}`} style={{width: `${actualStepWidth}%`, left: `${actualStepLeft}%`}} />
            </div>
            {showStepsTexts && (
                <div className={`${styles.stepsTextsContainer} ${extraClassNames.stepsTextsContainer ?? ""}`}>
                    {["Discover", "Develop", "Demonstrate", "Deploy"].map(step => {
                        return (
                            <div key={step}>{step}</div>
                        );    
                    })}
                </div>
            )}
        </div>
    );
};