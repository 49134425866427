// React
import { FC } from "react";
// Components
import { PermissionsChecker } from "Components";
// Enums
import { PermissionsEnum } from "Enums";

type THasAdvancedProps = {
    children: React.ReactNode;
};

export const HasAdvanced: FC<THasAdvancedProps> = ({ children }) => {
    return (
        <PermissionsChecker permissions={[PermissionsEnum.Advanced]}>
            {children}
        </PermissionsChecker>
    );
};