// node_modules
import React, { FC } from "react";
// Styles
import styles from "./synonymsButton.module.scss";

type TSynonymsButtonProps = {
    synonymsCount: number,
    handleOpenSynonymsSection: (event: React.MouseEvent) => void,
}

export const SynonymsButton: FC<TSynonymsButtonProps> = ({ synonymsCount, handleOpenSynonymsSection }: TSynonymsButtonProps) => {
    const getFindestSynonymsButton = (): JSX.Element | null => {
        // if synonyms section hidden & no synonyms
        if (synonymsCount === 0) {
            return (
                <div title="Add synonyms" className={`${styles.addSynonymContainer} ${styles.addSynonyms}`} onClick={handleOpenSynonymsSection}>
                    Add synonyms
                </div>
            );
        }
        // if synonyms section is hidden & at least one synonym
        if (synonymsCount >= 1) {
            return (
                <div title="Manage synonyms" className={styles.addSynonymContainer} onClick={handleOpenSynonymsSection}>
                    {synonymsCount === 1 ? "1 synonym" : `${synonymsCount} synonyms`}
                </div>
            );
        }
        // otherwise return null
        return null;
    };

    return getFindestSynonymsButton();
};