// node_modules
import axios from "axios";
// Types
import { TActionObjectDTO } from "Types";
// Helpers
import { AxiosHelperSingleton, LogHelperSingleton } from "Helpers";
// Enums
import { LogFeatureNameEnum } from "Enums";

export class ActionObjectController {

    public async createAsync(queryId: string, actionObjectToCreate: TActionObjectDTO): Promise<TActionObjectDTO | undefined> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/actionobjects`;

            // make request
            const response = await axios.post<TActionObjectDTO>(url, actionObjectToCreate);

            // deal with response
            if (response && response.data) {
                LogHelperSingleton.log(`${LogFeatureNameEnum.AdvancedSearch}-AddFunctionToQuery`);
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateAsync(queryId: string, actionObject: TActionObjectDTO): Promise<TActionObjectDTO | undefined> {
        try {
        // build url
        const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/actionobjects/${actionObject.id}`;

        // make request
        const response = await axios.put<TActionObjectDTO>(url, actionObject);

            // deal with response
            if (response && response.data) {
                LogHelperSingleton.log(`${LogFeatureNameEnum.AdvancedSearch}-UpdateFunctionOnQuery`);
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async deleteAsync(queryId: string, actionObjectId: number): Promise<boolean> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/actionobjects/${actionObjectId}`;

            // make request
            const response = await axios.delete<void>(url);

            // deal with response
            if (response && response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const ActionObjectControllerSingleton = new ActionObjectController(); 