// node_modules
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
// Components
import { ErrorHandling } from "Components";
import { App } from "./App";
// Contexts
import { AuthProvider } from "./Providers";
// utils
import reportWebVitals from "./reportWebVitals";
// Styles
import "./index.scss";
// Constants
import { ErrorConstants } from "Constants";
// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";

// define custom DOM elements
ProseMirrorHelperSingleton.defineCustomDOMElements();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <StrictMode>
        <ErrorHandling errorMessage={ErrorConstants.DEFAULT_ERROR_MESSAGE} >
            <AuthProvider>
                <BrowserRouter>
                    <App />
                    <Toaster 
                        position={"top-right"} 
                        reverseOrder={false} />
                </BrowserRouter>
            </AuthProvider>
        </ErrorHandling>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
