import { useState, useEffect } from "react";
import { StatusControllerSingleton } from "Controllers";
import { CookieHelperSingleton } from "Helpers";
import { TMaintenanceReportStatusDTO, TMonitoringStatusDTO } from "Types";

export const useMaintenanceStatus = () => {
    const [maintenanceData, setMaintenanceData] = useState<TMaintenanceReportStatusDTO[]>([]);

    useEffect(() => {
        const fetchMaintenanceStatus = async () => {
            const maintenanceStatus = await StatusControllerSingleton.fetchCurrentStatusAsync();
            if (maintenanceStatus) {
                getAcknowledgedStatusesFromCookie(maintenanceStatus);
                setMaintenanceData(maintenanceStatus.monitoringStatuses);
            }
        };

        fetchMaintenanceStatus();
    }, []);

    const handleAcknowledge = () => {
        if (maintenanceData) {
            updateAcknowledgedStatuses();
            setMaintenanceData([]);
        }
    };

    function getAcknowledgedStatusesFromCookie(maintenanceStatus: TMonitoringStatusDTO) {
        const acknowledgedStatuses = CookieHelperSingleton.getCookie("acknowledgedStatuses");
        const acknowledgedStatusesArray = acknowledgedStatuses ? acknowledgedStatuses.split(",") : [];
        maintenanceStatus.monitoringStatuses =
            maintenanceStatus.monitoringStatuses.filter(status => !acknowledgedStatusesArray.includes(status.id));
    }

    function updateAcknowledgedStatuses() {
        const acknowledgedStatuses = CookieHelperSingleton.getCookie("acknowledgedStatuses");
        const acknowledgedStatusesArray = acknowledgedStatuses ? acknowledgedStatuses.split(",") : [];
        maintenanceData.forEach(status => {
            acknowledgedStatusesArray.push(status.id);
        });
        CookieHelperSingleton.setCookie("acknowledgedStatuses", acknowledgedStatusesArray.join(","));
    }

    return {
        maintenanceData,
        handleAcknowledge
    };
};