import { faComment } from "@fortawesome/free-regular-svg-icons";
import { faLink, faTrash, faUnlink, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
// Styles
import styles from "./annotationActions.module.scss";

type TAnnotationActionsProps = {
    extraClassNames?: { annotationActions?: string },
    onLinkToClick?: () => void,
    onCommentClick?: () => void
    onEditClick?: () => void,
    onUnlinkClick?: () => void,
    onDeleteClick?: () => void,
};

export const AnnotationActions: FC<TAnnotationActionsProps> = ({
    extraClassNames, onLinkToClick, onCommentClick,
    onEditClick, onUnlinkClick, onDeleteClick
}: TAnnotationActionsProps) => {
    return (
        <div className={`${styles.annotationActions} ${extraClassNames?.annotationActions ? extraClassNames.annotationActions : ""}`}>
            {onLinkToClick ? <div onClick={onLinkToClick} className={styles.annotationAction} title="Link to" ><FontAwesomeIcon icon={faLink} /></div> : null}
            {onUnlinkClick ? <div onClick={onUnlinkClick} className={styles.annotationAction} title="Unlink"><FontAwesomeIcon icon={faUnlink} /></div> : undefined}
            {onCommentClick ? <div onClick={onCommentClick} className={styles.annotationAction} title="Comment"><FontAwesomeIcon icon={faComment} /></div> : undefined}
            {onEditClick ? <div onClick={onEditClick} className={styles.annotationAction} title="Edit"><FontAwesomeIcon icon={faPencilAlt} /></div> : undefined}
            {onDeleteClick ? <div onClick={onDeleteClick} className={styles.annotationAction} title="Delete"><FontAwesomeIcon icon={faTrash} /></div> : undefined}
        </div>
    );
};