// Enum
import { UniverseRolesEnum } from "Enums";
// Types
import { TClaimValueDTO, TUserDetailsUser } from "Types";

export type TCreateUserDTO = {
    email: string,
    role: UniverseRolesEnum,
    galaxyId?: string,
    firstName?: string,
    lastName?: string,
    password?: string,
    permissions: TClaimValueDTO[]
}

export const fromTUserDetailsUserToTCreateUserDTO = (user: TUserDetailsUser): TCreateUserDTO => {
    return {
        ...user,
        role: user.roles[0] as UniverseRolesEnum,
        permissions: user.permissions.map((permission) => ({ value: permission }))
    };
};