// React
import { DOMParser, DOMSerializer, Fragment, Mark, Node as ProseMirrorNode } from "prosemirror-model";
import { EditorState, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { useNavigate } from "react-router-dom";
import { Dispatch, MutableRefObject, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
// Controllers
import { ReferenceControllerSingleton } from "Controllers";
// Enums
import { AskAIAssistantMenuItemEnum, CustomDOMAttributes, CustomInlineIdAttributeEnum, CustomInlineNameEnum, EditorTableDOMTag, LogFeatureNameEnum, ObjectTypeEnum, OtherMarkdownCustomBlockNameEnum, SpecialBlockClassNameEnum, ToastTypeEnum, TopDepthMarkdownCustomBlockNameEnum, TopDepthMarkdownCustomBlockNameEnumStrings } from "Enums";
// Helpers
import { AskAIAssistantMenuItemHelperSingleton, LogHelperSingleton, MarkdownItHelperSingleton, ProseMirrorHelperSingleton, ToastHelperSingleton, UserHelperSingleton } from "Helpers";
// Contexts
import { AuthContext, CollaborationContext, EditorContext, ElementVisibilityContext } from "Providers";
// Components
import { findestMarkdownParser, findestMarkdownSerializer, findestSchema } from "Components";
// Types
import { NullableProseMirrorNode, TBlockContainerProps, TCommentDTO, TLogEventName, TProseMirrorNodeData, TReferenceModalProps, TSelectedTextData, TSelectedTextPopupContainerProps } from "Types";
// Interfaces
import { IEntityDTO } from "Interfaces";
// Constants
import { ProseMirrorConstants } from "Constants";

const referencePopoverPropsDefaultValues: TReferenceModalProps = {
    isOpen: false,
    styles: undefined,
    id: undefined,
    type: undefined
};

type TEditorReferencesContext = {
    usedReferenceIds: string[],
    customNodeBlockContainerProps: TBlockContainerProps,
    tableBlockContainerProps: TBlockContainerProps,
    selectedTextPopupContainerProps: TSelectedTextPopupContainerProps,
    askAIContainerRef: MutableRefObject<HTMLDivElement | null>,
    askAIAssistantContainerProps: {
        ref: MutableRefObject<HTMLDivElement | null>,
        inputText: string,
        insertAIGeneratedText: (inputText: string, selectedMenuItem: AskAIAssistantMenuItemEnum) => void,
        onAskAIAssistantModalCloseCallback: () => void,
        isAIAssistantModalOpen: boolean,
        setIsAIAssistantModalOpen: Dispatch<SetStateAction<boolean>>
    },
    referencePopoverProps: TReferenceModalProps,
    showReferencePopover: (id: string, type: ObjectTypeEnum, referenceElement: HTMLElement) => void,
    hideReferencePopover: () => void,
    commentsPerReferenceId: Map<string, TCommentDTO[]>,
    setCommentsPerReferenceId: Dispatch<SetStateAction<Map<string, TCommentDTO[]>>>,
    isSelectionInCustomNode: boolean,
    isLinkingModalOpen: boolean,
    setIsLinkingModalOpen: Dispatch<SetStateAction<boolean>>,
    linkToName?: string,
    setLinkToName: Dispatch<SetStateAction<string | undefined>>,
    turnBlockReferenceToInlineReference: (node: ProseMirrorNode) => void,
    turnInlineReferenceToBlockReference: (node: ProseMirrorNode) => void,
    addInlineListeners: (node: Node) => void
};

const defaultEditorReferencesContext: TEditorReferencesContext = {
    usedReferenceIds: [],
    customNodeBlockContainerProps: {} as TBlockContainerProps,
    tableBlockContainerProps: {} as TBlockContainerProps,
    selectedTextPopupContainerProps: {} as TSelectedTextPopupContainerProps,
    askAIContainerRef: {} as MutableRefObject<HTMLDivElement | null>,
    askAIAssistantContainerProps: {
        ref: {} as MutableRefObject<HTMLDivElement | null>,
        inputText: "",
        insertAIGeneratedText: () => { return; },
        onAskAIAssistantModalCloseCallback: () => { return; },
        isAIAssistantModalOpen: false,
        setIsAIAssistantModalOpen: () => { return; }
    },
    referencePopoverProps: referencePopoverPropsDefaultValues,
    showReferencePopover: () => { return; },
    hideReferencePopover: () => { return; },
    commentsPerReferenceId: new Map<string, TCommentDTO[]>(),
    setCommentsPerReferenceId: () => { return; },
    isSelectionInCustomNode: false,
    isLinkingModalOpen: false,
    setIsLinkingModalOpen: () => { return; },
    linkToName: undefined,
    setLinkToName: () => { return; },
    turnBlockReferenceToInlineReference: () => { return; },
    turnInlineReferenceToBlockReference: () => { return; },
    addInlineListeners: () => { return; }
};

type TEditorReferencesProviderProps = {
    children?: ReactNode
};

export const EditorReferencesContext = createContext<TEditorReferencesContext>(defaultEditorReferencesContext);

export const EditorReferencesProvider = ({children}: TEditorReferencesProviderProps) => {
    // Contexts
    const { editorView, editorState, updateEditorView, applyFunctionOnEditor, insertObject, showRatingsPopover, hideRatingsPopover } = useContext(EditorContext);
    const { auth, isUserExternal } = useContext(AuthContext);
    const { isEditModeOn, isEditorShown, objectIdEdited, objectTypeEdited } = useContext(CollaborationContext);
    const { canUserEdit } = useContext(ElementVisibilityContext);

    // Ref & State
    // We need to keep track of the used reference ids in a ref and state 
    // The state is used to rerender depending components when the used reference ids change
    // The ref is used to compare the used reference ids with the previous used reference ids and prevent unnecessary rerenders or looping
    // We might want to find a better way/pattern to handle it.
    const usedReferenceIdsRef = useRef<string[]>([]);
    const [usedReferenceIds, setUsedReferenceIds] = useState<string[]>([]);

    // Ref
    const customNodeBlockContainerRef = useRef<HTMLDivElement | null>(null);
    const tableBlockContainerRef = useRef<HTMLDivElement | null>(null);
    const selectedTextPopupContainerRef = useRef<HTMLDivElement | null>(null);
    const selectedCustomNodeRef = useRef<NullableProseMirrorNode>(undefined);
    const selectedTableRef = useRef<NullableProseMirrorNode>(undefined);
    const askAIContainerRef = useRef<HTMLDivElement | null>(null);
    const askAIAssistantContainerRef = useRef<HTMLDivElement | null>(null);
    const hoverTimer = useRef<NodeJS.Timeout | null>(null);

    // State
    const [currentlySelectedCustomNodeComments, setCurrentlySelectedCustomNodeComments] = useState<TCommentDTO[]>([]);
    const [commentsPerReferenceId, setCommentsPerReferenceId] = useState<Map<string, TCommentDTO[]>>(new Map<string, TCommentDTO[]>());
    const [selectedTextData, setSelectedTextData] = useState<TSelectedTextData>({isFullNodeTextContent: false, selectedText: undefined});
    const [askAIAssistantInputText, setAskAIAssistantInputText] = useState<string>("");
    const [isAIAssistantModalOpen, setIsAIAssistantModalOpen] = useState<boolean>(false);
    const [isSelectionInCustomNode, setIsSelectionInCustomNode] = useState<boolean>(false);
    const [isLinkingModalOpen, setIsLinkingModalOpen] = useState<boolean>(false);
    const [linkToName, setLinkToName] = useState<string | undefined>(undefined);
    const [referencePopoverProps, setReferencePopoverProps] = useState<TReferenceModalProps>(referencePopoverPropsDefaultValues);
    const [selectedMarkdown, setSelectedMarkdown] = useState<string>("");

    // Hooks
    const navigate = useNavigate();

    // Memoized is editable
    const isEditable = useMemo(() => {
        return !isUserExternal && isEditModeOn && isEditorShown && canUserEdit;
    }, [isUserExternal, isEditModeOn, isEditorShown, canUserEdit]);

    // Logic
    const updateCurrentlySelectedCustomNodeComments = useCallback((node: NullableProseMirrorNode, currentCommentsPerReferenceId: Map<string, TCommentDTO[]>) => {
        // safety-checks
        if (!node || !node.type || !node.type.name ||
                node.type.name !== TopDepthMarkdownCustomBlockNameEnum.HighlightReference) { 
            // set currently selected custom node comments to empty array
            setCurrentlySelectedCustomNodeComments([]);
            return; 
        }

        // init new currently selected custom node comments
        let newCurrentlySelectedCustomNodeComments: TCommentDTO[] = [];

        // if it is a highlight reference node
        // get the highlight reference id
        const highlightId: string | undefined = ProseMirrorHelperSingleton
            .getCustomBlockId(node, node.type);

        // safety-checks
        if (!highlightId) { 
            return; 
        }

        // get comments for this highlight reference id
        if (currentCommentsPerReferenceId && 
                currentCommentsPerReferenceId.has(highlightId)) {
            // get comments for this highlight reference id
            const commentsForThisHighlightReferenceId: TCommentDTO[] | undefined = currentCommentsPerReferenceId.get(highlightId);

            // safety-checks
            if (!commentsForThisHighlightReferenceId) {
                return;
            }
            
            // set currently selected custom node comments to comments for this highlight reference id
            newCurrentlySelectedCustomNodeComments = [...commentsForThisHighlightReferenceId];
        }

        // set currently selected custom node comments
        setCurrentlySelectedCustomNodeComments(newCurrentlySelectedCustomNodeComments);
    }, []);

    useEffect(() => {
        // every time the comments per reference id changes
        // update the currently selected custom node comments
        updateCurrentlySelectedCustomNodeComments(selectedCustomNodeRef.current, commentsPerReferenceId);
    }, [commentsPerReferenceId, updateCurrentlySelectedCustomNodeComments]);

    // do show selected text popup
    const doShowSelectedTextPopup = useCallback((currentEditorView: EditorView): void => {
        // safety-checks
        if (!selectedTextPopupContainerRef.current) {
            return;
        }

        // get selected text data in paragraph
        const newSelectedTextData: TSelectedTextData = ProseMirrorHelperSingleton.getSelectedTextDataInParagraph(currentEditorView);

        // set selected text data
        if(selectedTextData.selectedText !== newSelectedTextData.selectedText || 
                selectedTextData.isFullNodeTextContent !== newSelectedTextData.isFullNodeTextContent) {
            setSelectedTextData(newSelectedTextData);
        }

        // Set the selected markdown
        // Get the text selection of the current window
        const selection = window.getSelection();
        if(selection && selection.rangeCount > 0) {
            // Get the selected html contents
            const range = selection.getRangeAt(0);
            const clonedSelection = range.cloneContents();
            // Add the html contents 
            const div = document.createElement("div");
            div.appendChild(clonedSelection);
            
            // Serialize the html contents to markdown
            const markdownOfSelection = findestMarkdownSerializer.serialize(DOMParser.fromSchema(findestSchema).parse(div));

            // Remove the node from the DOM
            div.remove();
            setSelectedMarkdown(markdownOfSelection);
        }

        // if selected text in paragraph is empty
        if (!newSelectedTextData.selectedText) {
            selectedTextPopupContainerRef.current.style.display = "none";
        } else {
            setAskAIAssistantInputText(newSelectedTextData.selectedText);

            // logic to position selected text popup
            // WARNING: if you change the tree/components structure of ContextedEditorContent
            // it might break the positionning of the popup
            // inspired by: https://prosemirror.net/examples/tooltip/

            // get start and end of selection
            const { from, to } = currentEditorView.state.selection;

            // get start and end screen coordinates related to start and end of selection
            const start = currentEditorView.coordsAtPos(from);
            const end = currentEditorView.coordsAtPos(to);

            // the element in which the selected text popup container ref is set, to use as base
            const box = selectedTextPopupContainerRef.current.parentElement?.getBoundingClientRect();
            
            // get popup height and width
            const selectedTextActionsPopupRect = selectedTextPopupContainerRef.current.getBoundingClientRect();
            const selectedTextActionsPopupHeight = selectedTextActionsPopupRect.height;
            const selectedTextActionsPopupWidth = selectedTextActionsPopupRect.width;
            const entityLikeCardInformationContainerPadding = 48;
            const popoverGap = 6;

            // safety-checks
            if (!box) { return; }

            // Find a center-ish x position from the selection endpoints (when
            // crossing lines, end may be more to the left)
            const left = Math.max((start.left + end.left) / 2, start.left + 3);

            // then set style of selected text popup container ref
            // if popup position exceeds boundaries of editor, then show it inside the boundaries
            selectedTextPopupContainerRef.current.style.left = `${left + selectedTextActionsPopupWidth > box.right + entityLikeCardInformationContainerPadding ? box.width + entityLikeCardInformationContainerPadding - selectedTextActionsPopupWidth : left - box.left}px`;
            selectedTextPopupContainerRef.current.style.bottom = `${box.bottom - end.bottom - (selectedTextActionsPopupHeight + popoverGap)}px`;
            if (selectedTextData.selectedText !== newSelectedTextData.selectedText &&
                newSelectedTextData.selectedText &&
                newSelectedTextData.selectedText?.length > 0 &&
                selectedTextPopupContainerRef.current &&
                selectedTextPopupContainerRef.current.style.display !== "block"
            ) {
                selectedTextPopupContainerRef.current.style.display = "block";
            }
            selectedTextPopupContainerRef.current.style.position = "absolute";
            selectedTextPopupContainerRef.current.style.padding = "4px 0";
            selectedTextPopupContainerRef.current.style.boxShadow = "-1px 1px 10px #00000029";
            selectedTextPopupContainerRef.current.style.borderRadius = "4px";
            selectedTextPopupContainerRef.current.style.transition = "top .1s linear, left .1s linear";
            selectedTextPopupContainerRef.current.style.backgroundColor = "white";
            selectedTextPopupContainerRef.current.style.zIndex = "2";
            selectedTextPopupContainerRef.current.style.visibility = selectedTextActionsPopupHeight === 0 ? "hidden" : "visible";
        }
    }, [selectedTextData]);

    const handleOutsideClick = useCallback(() => {
        if (selectedTextData.selectedText &&
            selectedTextData.selectedText?.length > 0 &&
            selectedTextPopupContainerRef.current &&
            selectedTextPopupContainerRef.current.style.display === "block"
        ) {
            selectedTextPopupContainerRef.current.style.display = "none";
        }
    }, [selectedTextData]);

    // hide ask AI popup
    const hideAskAIPopup = useCallback((): void => {
        // safety-checks
        if (!askAIContainerRef.current ||
                askAIContainerRef.current.style.display === "none") {
            return;
        }

        // hide ask AI popup
        askAIContainerRef.current.style.display = "none";
    }, []);

    // do show ask AI popup
    const doShowAskAIPopup = useCallback((currentEditorView: EditorView, currentEditorState: EditorState): void => {
        // safety-checks
        if (!askAIContainerRef.current) {
            return;
        }

        // hide ask AI popup
        hideAskAIPopup();
        
        // logic to position ask ai popup
        // WARNING: if you change the tree/components structure of ContextedEditorContent
        // it might break the positionning of the popup
        // inspired by: https://prosemirror.net/examples/tooltip/

        // get selected node at selection
        const foundNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                currentEditorState,
                [findestSchema.nodes.heading.name, findestSchema.nodes.paragraph.name]
            );

        // get start and end of selection
        const { from, to } = currentEditorView.state.selection;

        // safety-checks
        // do not show ask AI popup if node at selection is not a heading or a paragraph
        // or selection is not empty
        // or heading is empty
        // or paragraph is not empty
        if (!foundNodeData || !foundNodeData.node ||
                (foundNodeData.node.type.name !== findestSchema.nodes.heading.name && 
                    foundNodeData.node.type.name !== findestSchema.nodes.paragraph.name) ||
                from !== to ||
                (foundNodeData.node.type.name === findestSchema.nodes.heading.name && 
                    foundNodeData.node.textContent === "") ||
                (foundNodeData.node.type.name === findestSchema.nodes.paragraph.name && 
                    foundNodeData.node.textContent !== "")) {
            return;
        }

        // get end screen coordinates related to end of selection
        const end = currentEditorView.coordsAtPos(to);

        // the element in which the ask ai container ref is set, to use as base
        const box = askAIContainerRef.current.parentElement?.getBoundingClientRect();
        
        // safety-checks
        if (!box) { return; }

        // set ask AI assistant text
        setAskAIAssistantInputText(foundNodeData.node.textContent);
        
        // then set style of ask ai container ref
        const left = -60;
        askAIContainerRef.current.style.left = `${left}px`;
        askAIContainerRef.current.style.bottom = `${box.bottom - end.bottom}px`;
        askAIContainerRef.current.style.display = "block";
        askAIContainerRef.current.style.position = "absolute";
        askAIContainerRef.current.style.boxShadow = "-1px 1px 10px #00000029";
        askAIContainerRef.current.style.borderRadius = "50%";
        askAIContainerRef.current.style.transition = "top .1s linear, left .1s linear";
    }, [hideAskAIPopup]);

    const insertAIGeneratedText = useCallback((newAiGeneratedText: string, selectedMenuItem: AskAIAssistantMenuItemEnum) => {
        // safety-checks
        if (!newAiGeneratedText || !editorView) { 
            return; 
        }

        // get related insert result log event name
        const insertResultLogEventName: TLogEventName = AskAIAssistantMenuItemHelperSingleton
            .getRelatedInsertResultLogEventName(selectedMenuItem);

        // log
        LogHelperSingleton.log(insertResultLogEventName);

        // get node at head of selection using editorView
        const nodeAtEndOfSelection = editorView.state.selection.$head.node();
        
        // go through all nodes in the document
        editorView.state.doc.descendants((node: ProseMirrorNode, pos: number) => {
            // if node is the node at the head of the selection
            if (node === nodeAtEndOfSelection) {
                // get ai generated text content as prosemirror node
                const aiGeneratedTextContent: ProseMirrorNode | null = findestMarkdownParser.parse(newAiGeneratedText);

                // safety-checks
                if (!aiGeneratedTextContent) {
                    return false;
                }
                    
                // init ai generated text content fragment
                let aiGeneratedTextFragment = Fragment.empty;
                
                // if selected menu item is a general description, executive summary or requirements summary
                // add a heading before the ai generated text content
                if ([AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks, AskAIAssistantMenuItemEnum.ExecutiveSummary, 
                        AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge, AskAIAssistantMenuItemEnum.GeneralDescription].includes(selectedMenuItem)) {
                    // create heading node
                    const headingNode: ProseMirrorNode = findestSchema.nodes.heading.create(
                        { level: 1, id: crypto.randomUUID() },
                        findestSchema.text(AskAIAssistantMenuItemHelperSingleton.askAIAssistantMenuItemEnumToDisplayString(selectedMenuItem))
                    );

                    // add heading node to fragment
                    aiGeneratedTextFragment = aiGeneratedTextFragment.addToEnd(headingNode);
                }

                // go through all nodes in the ai generated text content
                aiGeneratedTextContent.content.forEach((innerNode: ProseMirrorNode) => {
                    // add the node to the fragment
                    aiGeneratedTextFragment = aiGeneratedTextFragment.addToEnd(innerNode);
                });
                
                // create transaction to fragment after the node at the end of the selection
                const transaction: Transaction = editorView.state.tr.insert(pos + node.nodeSize, aiGeneratedTextFragment);
            
                // update editor view with transaction
                updateEditorView(editorView, transaction, true);

                // return false to stop going through the nodes
                return false;
            }
        });
    }, [editorView, updateEditorView]);

    const showTableBlockContainer = useCallback((): void => {
        // safety-checks
        if (!editorView || !tableBlockContainerRef.current) {
            return;
        }

        // get the current depth of the cursor
        const currentDepth = editorView.state.selection.$anchor.depth;
        // determine if the cursor is currently in a heading
        const currentNode = editorView.state.selection.$anchor.node(currentDepth);
        if(!currentNode) return;
        
        const currentDomNode = editorView.domAtPos(editorView.state.selection.$anchor.pos).node;

        let currentHtmlElement: HTMLElement;
        if (currentDomNode instanceof HTMLElement) {
            currentHtmlElement = currentDomNode;
        } else {
            if(!currentDomNode.parentElement) return;
            currentHtmlElement = currentDomNode.parentElement;
        }
        let clientHeight = currentHtmlElement.clientHeight;
        let currentHeightElement = currentHtmlElement;
        while (currentHeightElement.tagName !== "TABLE") {
            if (!currentHeightElement.parentElement) return;
            currentHeightElement = currentHeightElement.parentElement;
            clientHeight = currentHeightElement.clientHeight;
        }
        tableBlockContainerRef.current.style.display = "block";
        tableBlockContainerRef.current.style.position = "absolute";
        tableBlockContainerRef.current.style.top = `${currentHeightElement.offsetTop + clientHeight + 16}px`;
        tableBlockContainerRef.current.style.right = "calc(50% - 81px)";
        tableBlockContainerRef.current.style.zIndex = "2";
    }, [editorView]);

    const showCustomNodeBlockContainer = useCallback(() => {
        // safety-checks
        if (!editorView || !customNodeBlockContainerRef.current) {
            return;
        }

        // get the current depth of the cursor
        const currentDepth = editorView.state.selection.$anchor.depth;
        // determine if the cursor is currently in a heading
        const currentNode = editorView.state.selection.$anchor.node(currentDepth);
        if (!currentNode) return;
        
        const currentDomNode = editorView.domAtPos(editorView.state.selection.$anchor.pos).node;
        
        let currentHtmlElement: HTMLElement;
        if (currentDomNode instanceof HTMLElement) {
            currentHtmlElement = currentDomNode;
        } else {
            if (!currentDomNode.parentElement) return;
            currentHtmlElement = currentDomNode.parentElement;
        }

        let clientHeight = currentHtmlElement.clientHeight;
        let currentHeightElement = currentHtmlElement;
        while (clientHeight <= 0) {
            if (!currentHeightElement.parentElement) return;
            currentHeightElement = currentHeightElement.parentElement;
            clientHeight = currentHeightElement.clientHeight;
        }

        let topValue = currentHtmlElement.offsetTop + clientHeight;
        const inlineReferenceElement = currentHeightElement.closest("inline-reference") as HTMLElement;
        const TDElement = currentHeightElement.closest("TD") as HTMLElement;

        if (inlineReferenceElement && !TDElement) {
            const inlineReferenceOffsetLeft = inlineReferenceElement.offsetLeft;
            const inlineReferenceCenterOffsetLeft = inlineReferenceOffsetLeft + inlineReferenceElement.getBoundingClientRect().width / 2;
            const finalLeftValue = inlineReferenceCenterOffsetLeft - customNodeBlockContainerRef.current.getBoundingClientRect().width / 2;
            customNodeBlockContainerRef.current.style.left = `${finalLeftValue}px`;
            customNodeBlockContainerRef.current.style.right = "unset";
            topValue = topValue + 6;
        } else if (TDElement) {
            topValue = currentHeightElement.offsetTop + TDElement.offsetTop + (currentHeightElement.closest("table")?.offsetTop ?? 0) + clientHeight + 8;
            const leftValue = currentHeightElement.offsetLeft + TDElement.offsetLeft + currentHeightElement.offsetWidth / 2 - customNodeBlockContainerRef.current.offsetWidth / 2;
            customNodeBlockContainerRef.current.style.left = `${leftValue}px`;
            customNodeBlockContainerRef.current.style.right = "unset";
        } else {
            customNodeBlockContainerRef.current.style.left = "unset";
            customNodeBlockContainerRef.current.style.right = "calc(50% - 81px)";
        }

        const intakeSheetConfirmationElement = currentHeightElement.closest("intakesheetconfirmation") as HTMLElement;
        // if clicked element is child of intake sheet confirmation element, then show the custom block under the intake sheet confirmation properly 
        if (intakeSheetConfirmationElement) {
            topValue = intakeSheetConfirmationElement.offsetTop + intakeSheetConfirmationElement.offsetHeight + 10;
        }

        const studyReference = currentHeightElement.closest("study-reference") as HTMLElement;
        const entityReference = currentHeightElement.closest("entity-reference") as HTMLElement;
        if (studyReference || entityReference) {
            topValue = topValue + 12;
        }
        
        customNodeBlockContainerRef.current.style.display = "block";
        customNodeBlockContainerRef.current.style.position = "absolute";
        customNodeBlockContainerRef.current.style.top = `${topValue}px`;
        customNodeBlockContainerRef.current.style.zIndex = "2";
    }, [editorView]);

    const hideBlockContainer = useCallback((blockContainerRef: MutableRefObject<HTMLDivElement | null>,
            types: string[]) => {
        // safety-checks
        if(!blockContainerRef.current || !editorView) return;

        // hide block container
        blockContainerRef.current.style.display = "none";

        // init transaction
        let transaction: Transaction | undefined = undefined;
        
        // go through all descendants of doc
        editorView.state.doc.descendants((node, pos) => {
            // if node is a special custom block or had a special mark
            if (types.includes(node.type.name) || node.marks.some(mark => types.includes(mark.type.name))) {
                // get mark including wanted types
                const foundMark: Mark | undefined = node.marks.find(mark => types.includes(mark.type.name));
                // if node has selected attr and it is true
                if (node.attrs.selected !== undefined &&
                        node.attrs.selected === "true") {
                    // create transaction to change attrs of the node
                    // set it to not selected
                    transaction = transaction ?? editorView.state.tr;
                    transaction = transaction
                        .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                        .setNodeMarkup(pos, undefined, {
                            ...node.attrs,
                            selected: "false"
                        });
                } else if (foundMark && foundMark.attrs.selected !== undefined && 
                        foundMark.attrs.selected === "true") {
                    // otherwise if node has mark with selected attr and it is true
                    // create transaction to change attrs of the mark
                    // set it to not selected
                    // (remove mark and add it again with new attrs because marks list is readonly on a node)
                    transaction = transaction ?? editorView.state.tr;
                    transaction = transaction
                        .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                        .removeMark(pos, pos + node.nodeSize, foundMark)
                        .addMark(pos, pos + node.nodeSize, findestSchema.marks.inlineReference.create({...foundMark.attrs, selected: "false"}));
                }
            }
        });

        // if transaction was created
        if (transaction) {
            // update editor view 
            updateEditorView(editorView, transaction, false);
        }
    }, [editorView, updateEditorView]);
    
    // save as entity callback
    const saveAsEntityCallback = useCallback((createdEntity: IEntityDTO, callback: () => void): void => {
        // safety-checks
        if (!editorView) {
            return;
        }

        // if selected text is full node text content
        if (selectedTextData.isFullNodeTextContent) {
            // get current node at selection
            const currentNodeAtSelection = ProseMirrorHelperSingleton.getCurrentNodeDataAtSelection(editorView.state);

            // go through all doc nodes
            editorView.state.doc.descendants((node: ProseMirrorNode, pos: number) => {
                // if node is the current node at selection
                if (node === currentNodeAtSelection.node) {
                    // replace current node at selection by an empty paragraph
                    let tr = editorView.state.tr.replaceWith(pos, pos + node.nodeSize, findestSchema.nodes.paragraph.create(undefined, undefined));
                    // put cursor at the start of the empty paragraph
                    tr = tr
                        .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                        .setSelection(TextSelection.create(tr.doc, pos + 1));
                    // update editor view
                    updateEditorView(editorView, tr, false);
                }
            });
        }

        // insert object at the right place
        applyFunctionOnEditor(insertObject.bind(null, {
            objectType: ObjectTypeEnum.Entity,
            id: createdEntity.id,
            name: createdEntity.title,
            type: "undefined"
        }));

        // call callback
        callback();
    }, [applyFunctionOnEditor, editorView, insertObject, selectedTextData.isFullNodeTextContent, updateEditorView]);

    const removeSpecificNodeById = useCallback((currentEditorView: EditorView, nodeToRemove: ProseMirrorNode) => {
        // go through all nodes in the document
        currentEditorView.state.doc.descendants((node: ProseMirrorNode, pos: number) => {
            // if node is the node to remove
            if (node.eq(nodeToRemove) && nodeToRemove.attrs.id !== undefined &&
            node.attrs.id !== undefined && node.attrs.id === nodeToRemove.attrs.id) {
                // delete the node using its position and size (by calling the updateEditorView function with the delete transaction)
                updateEditorView(currentEditorView,
                    currentEditorView.state.tr.delete(
                    pos,
                    pos + nodeToRemove.nodeSize
                ), true);
            }
        });
    }, [updateEditorView]);

    const removeBlock = useCallback((nodeToRemove: NullableProseMirrorNode) => {
        // safety-checks
        if(!editorView || !nodeToRemove) {
            return;
        }
        
        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-RemoveBlock`);

        // remove specific node
        removeSpecificNodeById(editorView, nodeToRemove);
    }, [editorView, removeSpecificNodeById]);

    const setSelectedNode = useCallback((blockContainerRef: MutableRefObject<HTMLDivElement | null>, currentEditorView: EditorView, 
            types: string[],
            currentSelectedNodeRef: MutableRefObject<NullableProseMirrorNode>) => {
        // safety-checks
        if (!blockContainerRef.current) {
            return;
        }
        
        // init custom node types array (node and mark node types)
        const customNodeTypes = [...TopDepthMarkdownCustomBlockNameEnumStrings, CustomInlineNameEnum.InlineReference];
        
        // get selected node at selection
        const foundNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                currentEditorView.state,
                types
            );
            
        // get selected mark node at selection
        const foundMarkNodeData: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstMarkNodeDataWithTypesFromSelection(currentEditorView.state, types);

        // if found node data is undefined
        if (!foundNodeData.node || !foundNodeData.depth) {
            // set found node data to found mark node data
            foundNodeData.node = foundMarkNodeData.node;
            foundNodeData.depth = foundMarkNodeData.depth;
        }
        // if no node found or node is not of provided types (or if node does not have the provided mark types)
        if (!foundNodeData.node ||
                !foundNodeData.depth || (!types.includes(foundNodeData.node.type.name) &&
                !foundNodeData.node.marks.some(mark => types.includes(mark.type.name)))) {
            // if a custom node was selected before
            if (currentSelectedNodeRef.current) {
                // set selected custom node to undefined
                currentSelectedNodeRef.current = undefined;
                // hide block container
                hideBlockContainer(blockContainerRef, types);
            }
        } else {
            // if a custom node was not selected before or if the selected custom node is not the same as the found node
            if (!currentSelectedNodeRef.current || currentSelectedNodeRef.current !== foundNodeData.node) {
                // set selected custom node to found node
                currentSelectedNodeRef.current = foundNodeData.node;

                // update currently selected custom node comments
                updateCurrentlySelectedCustomNodeComments(foundNodeData.node, commentsPerReferenceId);

                // init transaction
                let transaction: Transaction | undefined = undefined;

                // go through all descendants of doc
                currentEditorView.state.doc.descendants((node: ProseMirrorNode, pos: number) => {
                    // if node is of provided types
                    if (types.includes(node.type.name)) {
                        // if found node is defined, is the same as the node, has an id, is the same as the node id and is not selected
                        if (foundNodeData.node && node.eq(foundNodeData.node) && foundNodeData.node.attrs.id !== undefined &&
                                node.attrs.id !== undefined && node.attrs.id === foundNodeData.node.attrs.id &&
                                node.attrs.selected !== undefined && node.attrs.selected === "false") {
                            // create transaction to change attrs of the node
                            // set it to selected
                            transaction = transaction ?? currentEditorView.state.tr;
                            transaction = transaction
                                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                                .setNodeMarkup(pos, undefined, {
                                    ...node.attrs,
                                    selected: "true"
                                });
                        } else if (node.attrs.selected !== undefined && node.attrs.selected === "true") {
                            // otherwise for other nodes, if node has selected attr and it is true
                            // create transaction to change attrs of the node
                            // set it to not selected
                            transaction = transaction ?? currentEditorView.state.tr;
                            transaction = transaction
                                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                                .setNodeMarkup(pos, undefined, {
                                    ...node.attrs,
                                    selected: "false"
                                });
                        }
                    }

                    // if node has mark with provided types and found node is defined
                    if (node.marks.some(mark => types.includes(mark.type.name)) && foundNodeData.node) {
                        // get mark including wanted types
                        const foundMark: Mark | undefined = node.marks.find(mark => types.includes(mark.type.name));
                        // get mark in foundNodeData.node including wanted types
                        const foundNodeDataMark: Mark | undefined = foundNodeData.node.marks.find(mark => types.includes(mark.type.name));

                        // if foundMark and foundNodeDataMark are defined
                        // foundMark and foundNodeDataMark have the same id
                        // foundMark has selected attr and it is false
                        if (foundMark && foundNodeDataMark && foundNodeDataMark.attrs.id !== undefined &&
                                foundMark.attrs.id !== undefined && foundMark.attrs.id === foundNodeDataMark.attrs.id &&
                                foundMark.attrs.selected !== undefined && foundMark.attrs.selected === "false") {
                            // create transaction to change attrs of the node
                            // set it to selected
                            transaction = transaction ?? currentEditorView.state.tr;
                            transaction = transaction
                                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                                .removeMark(pos, pos + node.nodeSize, foundMark)
                                .addMark(pos, pos + node.nodeSize, findestSchema.marks.inlineReference.create({...foundMark.attrs, selected: "true"}));
                        } else if (foundMark && foundMark.attrs.selected !== undefined && foundMark.attrs.selected === "true") {
                            // otherwise for other nodes, if found mark is defined, has selected attr and it is true
                            // create transaction to change attrs of the node
                            // set it to not selected
                            transaction = transaction ?? currentEditorView.state.tr;
                            transaction = transaction
                                .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                                .removeMark(pos, pos + node.nodeSize, foundMark)
                                .addMark(pos, pos + node.nodeSize, findestSchema.marks.inlineReference.create({...foundMark.attrs, selected: "false"}));
                        }
                    }
                });
                    
                // if a transaction was created
                if (transaction) {
                    // update editor view
                    updateEditorView(currentEditorView, transaction, false, (newState: EditorState) => {
                        // as a call back of the updateEditorView function, get the selected node at selection
                        // we need it because the state is updated in update editor view function
                        // so the current currentSelectedNodeRef.current is not the correct one anymore after the state update
                        // so we set it again
                        // get selected node at selection
                        const innerFoundNodeData: TProseMirrorNodeData = ProseMirrorHelperSingleton
                            .getFirstNodeDataWithTypesFromSelection(
                                newState,
                                types
                            );

                        // get selected mark node at selection
                        const innerFoundMarkNodeData: TProseMirrorNodeData = ProseMirrorHelperSingleton
                            .getFirstMarkNodeDataWithTypesFromSelection(newState, types);

                        // if inner found node data is undefined
                        if (!innerFoundNodeData.node || !innerFoundNodeData.depth) {
                            // set inner found node data to inner found mark node data
                            innerFoundNodeData.node = innerFoundMarkNodeData.node;
                            innerFoundNodeData.depth = innerFoundMarkNodeData.depth;
                        }

                        // if no node found or node is not of provided types (or if node does not have the provided mark types)
                        if (!innerFoundNodeData.node ||
                                !innerFoundNodeData.depth || (!types.includes(innerFoundNodeData.node.type.name) &&
                                !innerFoundNodeData.node.marks.some(mark => types.includes(mark.type.name)))) {
                            // set selected custom node to undefined
                            currentSelectedNodeRef.current = undefined;
                        } else {
                            // set selected custom node to the found node
                            currentSelectedNodeRef.current = innerFoundNodeData.node;
                        }

                        // set is selection in custom node
                        if (types === customNodeTypes) {
                            setIsSelectionInCustomNode(currentSelectedNodeRef.current !== undefined);
                        }
                    });
                }
            }

            // show table block or custom node block container (based on the type of the found node)
            if (currentSelectedNodeRef.current && foundNodeData.node) {
                if ([`${EditorTableDOMTag.Table}`].includes(foundNodeData.node.type.name)) {
                    showTableBlockContainer();
                } else if (types.includes(foundNodeData.node.type.name) ||
                        foundNodeData.node.marks.some(mark => types.includes(mark.type.name))) {
                    showCustomNodeBlockContainer();
                }
            }
        }

        // set is selection in custom node
        if (types === customNodeTypes) {
            setIsSelectionInCustomNode(currentSelectedNodeRef.current !== undefined);
        }
    }, [commentsPerReferenceId, hideBlockContainer, showCustomNodeBlockContainer, showTableBlockContainer, updateCurrentlySelectedCustomNodeComments, updateEditorView]);

    const moveBlockUp = useCallback((nodeToMoveUp: NullableProseMirrorNode) => {
        // safety-checks
        if (!editorView || !editorState || !nodeToMoveUp) {
            return;
        }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-MoveBlockUp`);

        // get mode node function
        const moveNodeFunction = ProseMirrorHelperSingleton.moveNode(nodeToMoveUp.type, "UP", true);

        // apply function on editor
        applyFunctionOnEditor(moveNodeFunction);

        // refresh block containers
        setSelectedNode(customNodeBlockContainerRef, editorView, [...TopDepthMarkdownCustomBlockNameEnumStrings, CustomInlineNameEnum.InlineReference], selectedCustomNodeRef);
        setSelectedNode(tableBlockContainerRef, editorView, [EditorTableDOMTag.Table], selectedTableRef);
    }, [applyFunctionOnEditor, editorState, editorView, setSelectedNode]);

    const moveBlockDown = useCallback((nodeToMoveDown: NullableProseMirrorNode) => {
        // safety-checks
        if (!editorView || !editorState || !nodeToMoveDown) {
            return;
        }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-MoveBlockDown`);

        // get mode node function
        const moveNodeFunction = ProseMirrorHelperSingleton.moveNode(nodeToMoveDown.type, "DOWN", true);

        // apply function on editor
        applyFunctionOnEditor(moveNodeFunction);

        // refresh block containers
        setSelectedNode(customNodeBlockContainerRef, editorView, [...TopDepthMarkdownCustomBlockNameEnumStrings, CustomInlineNameEnum.InlineReference], selectedCustomNodeRef);
        setSelectedNode(tableBlockContainerRef, editorView, [EditorTableDOMTag.Table], selectedTableRef);
    }, [applyFunctionOnEditor, editorState, editorView, setSelectedNode]);

    const cutBlock = useCallback(async (nodeToCut: NullableProseMirrorNode) => {
        // safety-checks
        if(!editorView || !nodeToCut) {
            return;
        }

        // log
        LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-CutBlock`);

        // serialize the node to cut to HTML
        const htmlNode = DOMSerializer.fromSchema(findestSchema).serializeNode(nodeToCut);
        
        // create a temporary empty div
        const temporaryDiv = document.createElement("div");

        // append the node to the temporary div and get the innerHTML
        temporaryDiv.appendChild(htmlNode);
        const htmlString = temporaryDiv.innerHTML;
        
        // write the innerHTML to the clipboard
        const blobInput = new Blob([htmlString], {type: "text/html"});
        const clipboardItemInput = new ClipboardItem({"text/html" : blobInput});
        await navigator.clipboard.write([clipboardItemInput]);
        
        // delete the node to cut
        removeSpecificNodeById(editorView, nodeToCut);
    }, [editorView, removeSpecificNodeById]);
    
    const updateReferencedByAsync = useCallback(async (currentObjectIdEdited: string, currentObjectTypeEdited: ObjectTypeEnum,
            newUsedReferenceIds: string[]): Promise<void> => {
        // If user is in share mode (shared to settings is defined) then do nothing
        if(UserHelperSingleton.isSharingRestrictedToObject(auth) || 
            !isEditable) return;

        // init doUpdateReferencedBy
        let doUpdateReferencedBy = false;
        
        // if used reference ids length is different from new used reference ids length
        if (newUsedReferenceIds.length !== usedReferenceIdsRef.current.length) {
            // set doUpdateReferencedBy to true
            doUpdateReferencedBy = true;
        }

        // if used reference ids is different from new used reference ids or new used reference ids is different from used reference ids
        if (!doUpdateReferencedBy && (!newUsedReferenceIds.every((value, index) => value === usedReferenceIdsRef.current[index]) ||
                !usedReferenceIdsRef.current.every((value, index) => value === newUsedReferenceIds[index]))) {
            // set doUpdateReferencedBy to true
            doUpdateReferencedBy = true;
        }

        // if doUpdateReferencedBy is true
        if (doUpdateReferencedBy) {
            // set used reference ids ref and state
            usedReferenceIdsRef.current = newUsedReferenceIds;
            setUsedReferenceIds(usedReferenceIdsRef.current);

            // update referenced by
            const isSuccessful = await ReferenceControllerSingleton
                .updateReferencedByAsync(currentObjectIdEdited, currentObjectTypeEdited, newUsedReferenceIds);

            // if not successful
            if (!isSuccessful) {
                // show error message
                ToastHelperSingleton
                    .showToast(ToastTypeEnum.Error, "There was an error while updating the referenced by list. Please contact us.");
            }
        }
    }, [auth, isEditable]);

    const onAskAIAssistantModalCloseCallback = useCallback((): void => {
        // safety-checks
        if (!editorView) {
            return;
        }

        // set ask ai assistant input text to empty
        setAskAIAssistantInputText("");

        // set focus on editor
        editorView.focus();
    }, [editorView]);

    useEffect(() => {
        // safety-checks
        if (!editorState || !objectIdEdited || !objectTypeEdited) {
            return;
        }
        
        // get updated markdown
        const updatedMarkdown = findestMarkdownSerializer.serialize(editorState.doc);
        // get new used reference ids
        const newUsedReferenceIds = MarkdownItHelperSingleton.getReferenceIds(updatedMarkdown);
        // update referenced by
        updateReferencedByAsync(objectIdEdited, objectTypeEdited, newUsedReferenceIds);
    }, [editorState, objectIdEdited, objectTypeEdited, updateReferencedByAsync]);

    useEffect(() => {
        // safety-checks
        if (!editorView || !editorState) {
            return;
        }

        // do show selected text popup
        doShowSelectedTextPopup(editorView);
    }, [doShowSelectedTextPopup, editorView, editorState]);

    useEffect(() => {
        // safety-checks
        if (!editorView || !editorState) {
            return;
        }

        // do show ask ai popup
        doShowAskAIPopup(editorView, editorState);
    }, [editorView, editorState, doShowAskAIPopup]);

    useEffect(() => {
        // safety-checks
        if (!editorView || !editorState) {
            return;
        }
        
        // refresh block containers
        setSelectedNode(customNodeBlockContainerRef, editorView, [...TopDepthMarkdownCustomBlockNameEnumStrings, CustomInlineNameEnum.InlineReference], selectedCustomNodeRef);
        setSelectedNode(tableBlockContainerRef, editorView, [EditorTableDOMTag.Table], selectedTableRef);
    }, [editorView, editorState, setSelectedNode]);

    const showReferencePopover = useCallback((id: string, type: ObjectTypeEnum, referenceElement: HTMLElement): void => {
        // update reference popover props state with delay
        hoverTimer.current = setTimeout(() => {
            setReferencePopoverProps(() => {
                return {
                    isOpen: true,
                    id,
                    type,
                    referenceElement: referenceElement,
                };
            });
        }, 500);
    }, []);

    const hideReferencePopover = useCallback((): void => {
        if (hoverTimer.current) {
            clearTimeout(hoverTimer.current);
            hoverTimer.current = null;
        }
        setReferencePopoverProps(() => {
            return {
                ...referencePopoverProps,
                isOpen: false,
                referenceElement: undefined
            };
        });
    }, [referencePopoverProps]);

    const onMouseOverInlineReference = useCallback((event: Event): void => {
        // safety-checks
        if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
            // stop execution, return
            return;
        }

        // call onMouseOverInlineReferenceHandler
        ProseMirrorHelperSingleton
            .onMouseOverInlineReferenceHandler(event, showReferencePopover, hideReferencePopover);
    }, [auth, showReferencePopover, hideReferencePopover]);

    const onClickInlineReference = useCallback((event: Event): void => {
        // safety-checks
        if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
            // stop execution, return
            return;
        }

        // call onClickInlineReferenceHandler
        ProseMirrorHelperSingleton
            .onClickInlineReferenceHandler(event, navigate);
    }, [auth, navigate]);

    const onMouseOverInlineStars = useCallback((event: Event): void => {
        // call onMouseOverInlineStarsHandler
        ProseMirrorHelperSingleton
            .onMouseOverInlineStarsHandler(event, showRatingsPopover, hideRatingsPopover);
    }, [showRatingsPopover, hideRatingsPopover]);

    const addInlineListeners = useCallback((node: Node): void => {
        // convert node to HTML element
        const nodeElement = node as HTMLElement;

        // go through node element child nodes
        nodeElement.childNodes.forEach((childNode: ChildNode) => {
            // convert child node to HTML element
            const childNodeElement = childNode as HTMLElement;
            
            // add on mouse over event listener
            childNodeElement.addEventListener("mouseover", onMouseOverInlineReference);

            // add on mouse over event listener
            childNodeElement.addEventListener("mouseover", onMouseOverInlineStars);

            // add on click event listener to inline reference
            childNodeElement.addEventListener("click", onClickInlineReference);
        });
    }, [onMouseOverInlineReference, onMouseOverInlineStars, onClickInlineReference]);

    const removeInlineListeners = useCallback((): void => {
        // go through document child nodes
        document.childNodes.forEach((childNode: ChildNode) => {
            // convert child node to HTML element
            const childNodeElement = childNode as HTMLElement;

            // remove on mouse over event listener
            childNodeElement.removeEventListener("mouseover", onMouseOverInlineReference);

            // remove on mouse over event listener
            childNodeElement.removeEventListener("mouseover", onMouseOverInlineStars);

            // remove on click event listener
            childNodeElement.removeEventListener("click", onClickInlineReference);
        });
    }, [onMouseOverInlineReference, onMouseOverInlineStars, onClickInlineReference]);

    useEffect(() => {
        // add clean up function
        return () => {
            // remove inline listeners
            removeInlineListeners();
        };
    }, [removeInlineListeners]);

    const turnBlockReferenceToInlineReference = useCallback((node: ProseMirrorNode): void => {
        // safety-checks 
        if (!editorView || (node.type.name !== TopDepthMarkdownCustomBlockNameEnum.EntityReference &&
                node.type.name !== TopDepthMarkdownCustomBlockNameEnum.StudyReference)) {
            return;
        }

        // go through all descendants of doc
        editorView.state.doc.descendants((descendantNode: ProseMirrorNode, descendantPos: number) => {
            // if node is the descendant node
            // and is a block reference node
            if (node.eq(descendantNode) && (descendantNode.type.name === TopDepthMarkdownCustomBlockNameEnum.EntityReference ||
                    descendantNode.type.name === TopDepthMarkdownCustomBlockNameEnum.StudyReference)) {
                // get reference type
                const referenceType: ObjectTypeEnum = descendantNode.type.name === TopDepthMarkdownCustomBlockNameEnum.EntityReference ?
                    ObjectTypeEnum.Entity : ObjectTypeEnum.Study;

                // get reference id
                const referenceId: string | undefined = ProseMirrorHelperSingleton
                        .getCustomBlockId(node, node.type);

                // get node first child (ReferenceLink)
                const referenceLinkNode = descendantNode.firstChild;

                // safety-checks
                if (!referenceId || !referenceLinkNode ||
                        referenceLinkNode.type.name !== OtherMarkdownCustomBlockNameEnum.ReferenceLink) {
                    return;
                }

                // create inlineReference attrs
                const inlineReferenceAttrs: { [key: string]: string } = {
                    id: crypto.randomUUID(),
                    [`${CustomInlineIdAttributeEnum.InlineReference}`]: `${referenceId}`,
                    [`${CustomDOMAttributes.DataInlineReferenceType}`]: `${referenceType}`,
                    class: `${SpecialBlockClassNameEnum.InlineReference}`
                };
                
                // create mark node
                const inlineReferenceNode = findestSchema.text(
                    referenceLinkNode.textContent, 
                    [findestSchema.marks.inlineReference.create(inlineReferenceAttrs)]
                );
                
                // create transaction to replace descendant node by a paragraph with the inline reference mark
                const transaction: Transaction = editorView.state.tr.replaceWith(
                    descendantPos,
                    descendantPos + descendantNode.nodeSize,
                    findestSchema.nodes.paragraph.create(
                        undefined,
                        inlineReferenceNode
                    )
                );

                // update editor view
                updateEditorView(editorView, transaction, true);
            }
        });
    }, [editorView, updateEditorView]);

    const turnInlineReferenceToBlockReference = useCallback((inlineReferenceTextNode: ProseMirrorNode): void => {
        // get inline reference mark from node
        const inlineReferenceMark: Mark | undefined = inlineReferenceTextNode
            .marks
            .find(mark => mark.type.name === findestSchema.marks.inlineReference.name);

        // safety-checks
        if (!editorView || !updateEditorView || !inlineReferenceMark) {
            // do nothing
            return;
        }
        
        // get reference data from mark
        const referenceName: string | undefined = inlineReferenceTextNode.textContent;
        const referenceType: ObjectTypeEnum | undefined = parseInt(inlineReferenceMark.attrs[`${CustomDOMAttributes.DataInlineReferenceType}`], 10);
        const referenceId: string | undefined = inlineReferenceMark.attrs[`${CustomInlineIdAttributeEnum.InlineReference}`];

        // safety-checks
        if (!referenceName || !referenceType || !referenceId) {
            // do nothing
            return;
        }

        // build block reference node
        const blockReferenceNode: ProseMirrorNode = ProseMirrorHelperSingleton
            .buildBlockReferenceNode(referenceId, referenceType, referenceName);

        // we need to know if the inline reference is in a paragraph which is in a special block (list or table)
        // because then the position where we need to insert the block reference is different
        let nodeTypesToFind: string[] = [
            findestSchema.nodes.ordered_list.name,
            findestSchema.nodes.bullet_list.name,
            findestSchema.nodes.table.name
        ];
        let foundNodeData: TProseMirrorNodeData = ProseMirrorHelperSingleton
            .getFirstNodeDataWithTypesFromSelection(
                editorView.state, 
                nodeTypesToFind
            );

        // if node was found and it is not the doc node
        if (foundNodeData.node && foundNodeData.depth &&
                foundNodeData.node.type.name !== findestSchema.nodes.doc.name) {
            // get the position where to insert the block reference
            // which is right before the special block
            const positionWhereToInsertBlockReference = editorView.state.selection.$anchor.before(foundNodeData.depth);

            // get transaction to insert block reference at the position
            let tr = editorView.state.tr.insert(
                positionWhereToInsertBlockReference,
                blockReferenceNode
            );

            // set cursor at the end of the block reference node
            tr = ProseMirrorHelperSingleton.setCursorAtNewCustomBlockEnd(
                tr, 
                referenceType === ObjectTypeEnum.Entity ? TopDepthMarkdownCustomBlockNameEnum.EntityReference : TopDepthMarkdownCustomBlockNameEnum.StudyReference,
                referenceId, 
                { from: positionWhereToInsertBlockReference, to: positionWhereToInsertBlockReference }
            );

            // update editor view (insert block reference at the position)
            updateEditorView(editorView, tr, true);
        } else {
            // otherwise, the inline reference is in a paragraph which is not in a special block
            // get paragraph where the inline reference is in
            nodeTypesToFind = [findestSchema.nodes.paragraph.name];
            foundNodeData = ProseMirrorHelperSingleton
                .getFirstNodeDataWithTypesFromSelection(
                    editorView.state, 
                    nodeTypesToFind
                );

            // if node was found and it is not the doc node
            if (foundNodeData.node && foundNodeData.depth &&
                    foundNodeData.node.type.name !== findestSchema.nodes.doc.name) {
                // we will now replace the paragraph by a paragraph (with the content before the node with inline reference mark)
                // followed by the block reference (inline reference mark turned into block reference) 
                // followed by a paragraph (with the content after the node with inline reference mark)
                const nodesBeforeInlineReference: ProseMirrorNode[] = [];
                const nodesAfterInlineReference: ProseMirrorNode[] = [];
                let inlineReferenceNodeFound = false;
                
                // go through all descendants of the paragraph and fill nodesBeforeInlineReference and nodesAfterInlineReference
                foundNodeData.node.descendants((descendantNode: ProseMirrorNode) => {
                    // if inlineReferenceTextNode is the descendant node
                    if (inlineReferenceTextNode.eq(descendantNode)) {
                        // set inlineReferenceNodeFound to true
                        inlineReferenceNodeFound = true;
                    } else if (!inlineReferenceNodeFound) {
                        // if inlineReferenceNodeFound is false, add the node to nodesBeforeInlineReference
                        nodesBeforeInlineReference.push(descendantNode);
                    } else {
                        // if inlineReferenceNodeFound is true, add the node to nodesAfterInlineReference
                        nodesAfterInlineReference.push(descendantNode);
                    }
                });

                // get start and end positions of the paragraph
                const paragraphStart = editorView.state.selection.$anchor.before(foundNodeData.depth);
                const paragraphEnd = editorView.state.selection.$anchor.after(foundNodeData.depth);

                // init nodes list to insert
                const nodesToInsert: ProseMirrorNode[] = [];
                // if there are nodes before inline reference
                if (nodesBeforeInlineReference.length > 0) {
                    // add paragraph with nodes before the inline reference node to fragment to insert
                    nodesToInsert.push(
                        findestSchema.nodes.paragraph.create(
                            undefined,
                            Fragment.fromArray(nodesBeforeInlineReference)
                        )
                    );
                }
                // add block reference to fragment to insert
                nodesToInsert.push(blockReferenceNode);
                // if there are nodes after inline reference
                if (nodesAfterInlineReference.length > 0) {
                    // add paragraph with nodes after the inline reference node to fragment to insert
                    nodesToInsert.push(
                        findestSchema.nodes.paragraph.create(
                            undefined,
                            Fragment.fromArray(nodesAfterInlineReference)
                        )
                    );
                }

                // get transaction to replace paragraph by the fragment to insert
                const tr = editorView.state.tr.replaceWith(
                    paragraphStart,
                    paragraphEnd,
                    Fragment.fromArray(nodesToInsert)
                );

                // update editor view (replace paragraph by the fragment to insert)
                updateEditorView(editorView, tr, true);
            }
        }
    }, [editorView, updateEditorView]);

    const providerValue = useMemo(() => {
        return {
            usedReferenceIds,
            customNodeBlockContainerProps: {
                blockContainerRef: customNodeBlockContainerRef,
                currentlySelectedNode: selectedCustomNodeRef.current,
                comments: currentlySelectedCustomNodeComments,
                removeBlock,
                moveBlockUp,
                moveBlockDown,
                cutBlock
            },
            tableBlockContainerProps: {
                blockContainerRef: tableBlockContainerRef,
                currentlySelectedNode: selectedTableRef.current,
                comments: [],
                removeBlock,
                moveBlockUp,
                moveBlockDown,
                cutBlock
            },
            selectedTextPopupContainerProps: {
                ref: selectedTextPopupContainerRef,
                selectedText: selectedTextData.selectedText,
                selectedMarkdown: selectedMarkdown,
                objectIdEdited: objectIdEdited,
                objectTypeEdited: objectTypeEdited,
                saveAsEntityCallback,
                handleOutsideClick
            },
            askAIContainerRef,
            askAIAssistantContainerProps: {
                ref: askAIAssistantContainerRef,
                inputText: askAIAssistantInputText,
                insertAIGeneratedText,
                onAskAIAssistantModalCloseCallback,
                isAIAssistantModalOpen,
                setIsAIAssistantModalOpen
            },
            referencePopoverProps,
            showReferencePopover,
            hideReferencePopover,
            commentsPerReferenceId,
            setCommentsPerReferenceId,
            isSelectionInCustomNode,
            isLinkingModalOpen,
            setIsLinkingModalOpen,
            linkToName,
            setLinkToName,
            turnBlockReferenceToInlineReference,
            turnInlineReferenceToBlockReference,
            addInlineListeners
        };
    }, [usedReferenceIds, currentlySelectedCustomNodeComments, removeBlock, moveBlockUp, moveBlockDown, cutBlock, selectedTextData.selectedText, selectedMarkdown, objectIdEdited, objectTypeEdited, saveAsEntityCallback, handleOutsideClick, askAIAssistantInputText, insertAIGeneratedText, onAskAIAssistantModalCloseCallback, isAIAssistantModalOpen, referencePopoverProps, showReferencePopover, hideReferencePopover, commentsPerReferenceId, isSelectionInCustomNode, isLinkingModalOpen, linkToName, turnBlockReferenceToInlineReference, turnInlineReferenceToBlockReference, addInlineListeners]);

    // Render
    return (
        <EditorReferencesContext.Provider value={providerValue}>
            {children}
        </EditorReferencesContext.Provider>
    );
};