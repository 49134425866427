import { FC, useContext, useEffect, useRef, useState } from "react";
// Components
import { ReferenceSidebar, RightPanel } from "Components";
import { EditorHeadersMenu } from "./EditorHeadersMenu/EditorHeadersMenu";
// Contexts
import { ElementVisibilityContext, CollaborationContext } from "Providers";
// Styles
import styles from "./rightSidebar.module.scss";

type TRightSidebarProps = {
    isInReferenceModal?: boolean
};

export const RightSidebar: FC<TRightSidebarProps> = ({ isInReferenceModal }: TRightSidebarProps) => {
    const { isReferenceSidebarVisible } = useContext(ElementVisibilityContext);
    const { objectIdEdited } = useContext(CollaborationContext);

    const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState<boolean>(false);
    const [sidebarType, setSidebarType] = useState<string | undefined>(!isReferenceSidebarVisible ? "headers": "reference");

    // Create refs for the sidebarType and isRightSidebarCollapsed
    const sidebarTypeRef = useRef(sidebarType);
    const isRightSidebarCollapsedRef = useRef(isRightSidebarCollapsed);

    // Update the ref whenever sidebarType changes
    useEffect(() => {
        sidebarTypeRef.current = sidebarType;
    }, [sidebarType]);

    // Update the ref whenever isRightSidebarCollapsed changes
    useEffect(() => {
        isRightSidebarCollapsedRef.current = isRightSidebarCollapsed;
    }, [isRightSidebarCollapsed]);

    useEffect(() => {
        // When the reference sidebar is hidden, switch to headers sidebar if needed
        if (
            !isRightSidebarCollapsedRef.current
            && !isReferenceSidebarVisible
            && sidebarTypeRef.current === "reference"
        ) {
            setSidebarType("headers");
        }
    }, [isReferenceSidebarVisible]);

    useEffect(() => {
        // Whenever it is switched from object to object, change the selected sidebar type to reference if needed
        if (
            objectIdEdited
            && isReferenceSidebarVisible
            && sidebarTypeRef.current !== "reference"
            && !isRightSidebarCollapsedRef.current
        ) {
            setSidebarType("reference");
        }
    }, [objectIdEdited, isReferenceSidebarVisible]);

    const onRightPanelButtonClick = (selectedSidebarType: string) => {
        // if sidebar will be opened
        if (isRightSidebarCollapsed) {
            setIsRightSidebarCollapsed(false);
            setSidebarType(selectedSidebarType);
        } else {
            // if newly selected sidebar type is different than the current one
            if (selectedSidebarType !== sidebarType) {
                setSidebarType(selectedSidebarType);
            } else {
                // if newly selected sidebar type is the same as the current one & sidebar will be closed
                setIsRightSidebarCollapsed(true);
                setSidebarType(undefined);
            }
        }
    };

    const onCollapseButtonClick = () => {
        setIsRightSidebarCollapsed(true);
        setSidebarType(undefined);
    };

    // Render
    return (
        <div className={`${styles.rightSidebar} ${isRightSidebarCollapsed ? styles.collapsed : styles.open} ${isInReferenceModal ? styles.isInReferenceModal : ""}`}>
            <div className={`${styles.contentContainer} ${sidebarType === "reference" ? "" : styles.hidden}`}>
                <ReferenceSidebar onCollapseButtonClick={onCollapseButtonClick} isInReferenceModal={isInReferenceModal} />
            </div>
            <div className={`${styles.contentContainer} ${sidebarType === "headers" ? "" : styles.hidden}`}>
                <EditorHeadersMenu onCollapseButtonClick={onCollapseButtonClick} />
            </div>
            <RightPanel
                selectedSidebar={sidebarType}
                toggleSidebar={onRightPanelButtonClick}
                hideReferencesButton={!isReferenceSidebarVisible}
            />
        </div>
    );
};
