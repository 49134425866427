// node_modules
import { FC, useEffect } from "react";
// Styles
import styles from "./notFoundPage.module.scss";

export const NotFoundPage: FC = () => {
    // Const
    // define redirect timeout in ms
    const redirectTimeoutInMs = 5000;
    const redirectTimeoutInS = redirectTimeoutInMs / 1000;

    // Logic
    const getRedirectUrl = (): string => {
        // init redirect url
        const redirectUrl = "/";

        // return redirect url
        return redirectUrl;
    };

    useEffect(() => {
        // in 5 seconds redirect to home page
        const timeout = setTimeout(() => {
            window.location.href = getRedirectUrl();
        }, redirectTimeoutInMs);
        
        // clear timeout on unmount
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // Render
    return (
        <div className={styles.notFoundContainer}>
            <p>Looks like you got lost in the Universe.</p>
            <p>{`You will be redirected in ${redirectTimeoutInS} seconds.`}</p>
        </div>
    );
};
