
// node_modules
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useResizeObserver from "@react-hook/resize-observer";
import debounce from "lodash.debounce";
import { FC, useCallback, useEffect, useMemo, useRef, useState, useContext } from "react";
// Types
import { TCommentDTO, THighlightDTO, TOption } from "Types";
// Styles
import styles from "./highlight.module.scss";
// Components
import { AnnotationActions, Comments, CreateAnnotationModal, Dropdown, EditableMarkdown, FindestButton, Reference } from "Components";
// Controllers
import { HighlightControllerSingleton } from "Controllers";
// Enums
import { HighlightTypeEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { HighlightTypeHelperSingleton, LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Constants
import { GeneralConstants } from "Constants";
import { AuthContext } from "Providers";

type THighlightProps = {
    highlight: THighlightDTO,
    extraClassNames?: { highlightContainer?: string },
    doShowType: boolean,
    onDeleteHighlight?: (highlightId: string) => void,
    onEditHighlight?: (editedHighlight: THighlightDTO) => void
};

export const Highlight: FC<THighlightProps> = ({ highlight, extraClassNames = {}, doShowType,
        onDeleteHighlight, onEditHighlight}: THighlightProps) => {
    // Context
    const { isUserExternal } = useContext(AuthContext);

    // Ref
    const divContainerRef = useRef<HTMLDivElement>(null);
    const highlightContainerRef = useRef<HTMLDivElement>(null);

    // State
    const [isCreateAnnotationModalOpen, setIsCreateAnnotationModalOpen] = useState<boolean>(false);
    const [doesNeedReadMore, setDoesNeedReadMore] = useState<boolean>(false);
    const [isReadMoreExpanded, setIsReadMoreExpanded] = useState<boolean>(false);
    const [editedHighlight, setEditedHighlight] = useState<THighlightDTO>({...highlight});
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    // Logic
    const determineIfReadMoreIsNeeded = useCallback(() => {
        // safety-checks
        if (!divContainerRef.current) { return; }

        const needsReadMore = divContainerRef.current.clientHeight !== divContainerRef.current.scrollHeight;
        setDoesNeedReadMore(needsReadMore);
    }, [divContainerRef]);

    const forceEditModeOn = useMemo(() => {
        // check if edit mode needs to be forced based on the highlight related property
        // (set for example when creating a new linked empty highlight)
        if (editedHighlight.doAutoTurnEditModeOn !== undefined && editedHighlight.doAutoTurnEditModeOn) {
            return true;
        }
    }, [editedHighlight]);

    useResizeObserver(divContainerRef, determineIfReadMoreIsNeeded);

    useEffect(() => {
        if(!isEditing) {
            setIsReadMoreExpanded(false);
        }
        determineIfReadMoreIsNeeded();
    }, [determineIfReadMoreIsNeeded, isEditing]);

    useEffect(() => {
        if (forceEditModeOn && highlightContainerRef?.current) {
            highlightContainerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [forceEditModeOn]);

    // Logic
    const onHighlightTypeChangeHandlerAsync = async (newEditedHighlight: THighlightDTO, option: TOption<HighlightTypeEnum>) => {
        // If highlight is not editable then do nothing
        if(!onEditHighlight) return;

        newEditedHighlight = { ...newEditedHighlight, type: option.value };

        const isUpdated = await HighlightControllerSingleton.updateHighlight(newEditedHighlight.id, newEditedHighlight);

        if (isUpdated) {
            setEditedHighlight({
                ...newEditedHighlight
            });
            
            // call onEditHighlight parent prop callback
            onEditHighlight(newEditedHighlight);
        }
        
        // log 
        LogHelperSingleton.log("ChangeHighlightType");
    };

    const onDeleteHandler = async () => {
        if(!onDeleteHighlight) return;

        // Confirm with the user that they want to delete the current highlight
        if(!confirm("Are you sure you want to delete this highlight?")) return;

        const isDeleted = await HighlightControllerSingleton.deleteAsync(editedHighlight.id);

        if (isDeleted) {
            onDeleteHighlight(editedHighlight.id);
            // log 
            LogHelperSingleton.log("DeleteHighlight");
        } else {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not delete highlight.");
        }
    };

    const handleNewHighlightDescriptionAsync = useCallback(async (highlight: THighlightDTO, newDescriptionValue: string) => {
        // If highlight is not editable then do nothing
        if(!onEditHighlight) return;

        const newEditedHighlight = { 
            ...highlight, 
            description: newDescriptionValue 
        };

        // update description in database
        await HighlightControllerSingleton
            .updateHighlight(newEditedHighlight.id, newEditedHighlight);

        setEditedHighlight({
            ...newEditedHighlight
        });

        onEditHighlight(newEditedHighlight);

        // log 
        LogHelperSingleton.log("UpdateHighlightDescription");
    }, [onEditHighlight]);


    // debounce the handleNewHighlightDescriptionAsync function
    const debouncedHandleNewHighlightDescriptionAsync = useMemo(() => debounce(handleNewHighlightDescriptionAsync, GeneralConstants.DEFAULT_MS_DELAY),
    [handleNewHighlightDescriptionAsync]);

    const updateHighlightCommentsAsync = (newEditedHighlight: THighlightDTO, newHighlightComments: TCommentDTO[]): void => {
        // If highlight is not editable then do nothing
        if(!onEditHighlight) return;

        setEditedHighlight({
            ...newEditedHighlight,
            comments: [...newHighlightComments]
        });
        
        // call onEditHighlight parent prop callback
        onEditHighlight(newEditedHighlight);
    };
    
    // safety-checks
    if (!editedHighlight || editedHighlight.description === undefined) { return null; }

    const toggleReadMore = (): void => {
        setIsReadMoreExpanded(!isReadMoreExpanded);
        // log
        LogHelperSingleton.log("ReadMoreOnHighlight");
    };

    const onEditModeChangeHandler = (isInEditMode: boolean): void => {
        // If highlight is not editable then don't change edit state
        if(!onEditHighlight) return;

        setIsEditing(isInEditMode);
        setIsEditMode(isInEditMode);
        if(isInEditMode) setIsReadMoreExpanded(true);
    };
    
    // Render
    return (
        <div ref={highlightContainerRef} className={[styles.highlightContainer, extraClassNames.highlightContainer || ""].join(" ")}>
            <div className={styles.highlight}>
                {isEditMode ? 
                    undefined
                :
                    <AnnotationActions
                        extraClassNames={{ annotationActions: styles.highlightAnnotationActions }}
                        onCommentClick={() => setIsCreateAnnotationModalOpen(true)}
                        onDeleteClick={onDeleteHighlight ? onDeleteHandler : undefined}
                        onEditClick={onEditHighlight ? () => onEditModeChangeHandler(true) : undefined}
                    />
                }
                {doShowType && (
                    <div className={styles.highlightTypeSelectionDropdown}>
                        <Dropdown
                            selectedOption={{value: editedHighlight.type, title: HighlightTypeHelperSingleton.getHighlightTypeDisplayName(editedHighlight)}}
                            handleOptionSelect={(option: TOption<HighlightTypeEnum>) => onHighlightTypeChangeHandlerAsync(editedHighlight, option)}
                            options={HighlightTypeHelperSingleton.highlightTypesDropdownOptions}
                            classNameSelect={styles.classNameSelect}
                        />
                    </div>
                )}
                <div className={styles.highlightTextContainer}>
                    <div ref={divContainerRef} className={`${styles.highlightText} ${isReadMoreExpanded || isEditMode ? styles.unclampText : ""}`}>
                        <EditableMarkdown
                            objectIdEdited={editedHighlight.id}
                            objectTypeEdited={ObjectTypeEnum.Highlight}
                            source={editedHighlight.description ? editedHighlight.description : ""}
                            noSourcePlaceholder="Highlight description"
                            onSourceChange={(newValue) => debouncedHandleNewHighlightDescriptionAsync(editedHighlight, newValue)}
                            extraClassNames={{ editButton: styles.editButton }}
                            onEditModeChange={onEditModeChangeHandler}
                            forceEditModeOn={forceEditModeOn}
                            isEditMode={isEditMode}
                            isEditable={!!onEditHighlight}
                        />
                    </div>
                    {(doesNeedReadMore || isReadMoreExpanded) && !isEditMode ?
                        <p onClick={toggleReadMore} className={styles.readMore}>
                            {isReadMoreExpanded ? 
                                <>
                                    Collapse text
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </> : 
                                <>
                                    Read more
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </>
                            }
                        </p>
                        : 
                        null
                    }
                    {isEditMode ? 
                        <FindestButton title={"Save"} extraClassName={styles.saveButton} onClick={() => onEditModeChangeHandler(false)} />
                    :
                        <Reference referenceUrl={editedHighlight.reference} />
                    }
                    
                </div>
            </div>
            {!isUserExternal &&
                <Comments
                    comments={editedHighlight.comments}
                    emptyCommentPlaceholder={"Add your annotation"}
                    onCommentsUpdated={(newComments: TCommentDTO[]) => updateHighlightCommentsAsync(editedHighlight, newComments)}
                    allowAddingReply={false}
                />
            }
            <CreateAnnotationModal
                isOpen={isCreateAnnotationModalOpen}
                setIsOpen={setIsCreateAnnotationModalOpen}
                highlight={editedHighlight}
            />
        </div>
    );
};