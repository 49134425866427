// node_modules
import { Node } from "prosemirror-model";
import { EditorState, Plugin, PluginKey, Transaction } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
// Enums
import { CustomBlockIdAttributeEnum, EditorTableDOMTag } from "Enums";
// Constants
import { ProseMirrorConstants } from "Constants";

// init plugin key
const tableColumnResizingPluginKey = new PluginKey<TableColumnResizingPluginState>(
    "tableColumnResizingPlugin",
);

const resizingWidgetClassName = "tableColumnResizingWidget";
const resizingWidgetCursorStyle = "col-resize";

// define plugin
export const tableColumnResizingPlugin = (updateEditorView?: (editorViewToUpdate: EditorView, transaction: Transaction, doCallOnSourceChangeCallback: boolean, callbackBeforeSetState?: (newState: EditorState) => void) => void) => {
    return new Plugin<TableColumnResizingPluginState>({
        key: tableColumnResizingPluginKey,
        state: {
          init() {
            // set default state
            return new TableColumnResizingPluginState(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
          },
          apply(tr: Transaction, prev: TableColumnResizingPluginState) {
            return prev.apply(tr);
          },
        },
        props: {
            decorations: (state: EditorState) => {
                // init decorations array
                const decorations: Decoration[] = [];
                // define decorate function
                const decorate = (docNode: Node, docNodePos: number) => {
                    // if node is table
                    if (docNode.type.name === EditorTableDOMTag.Table) {
                        // go through all table descendants
                        docNode.descendants((tableNode: Node, tableNodePos: number) => {
                            // if node is a th
                            if (tableNode.type.name === EditorTableDOMTag.Th) {
                                // WARNING: this is a workaround because we don't have access to the editorView
                                // (and so on the domAtPos)
                                const table = document.querySelector(`table[${CustomBlockIdAttributeEnum.Table}="${docNode.attrs[CustomBlockIdAttributeEnum.Table]}"]`) as HTMLElement | undefined;
                                const tableHeight: number | undefined = table?.offsetHeight;
                                // safety-checks
                                if (tableHeight) {
                                    // add resizing widget next to th paragraph
                                    const widget = document.createElement("div");
                                    widget.className = resizingWidgetClassName;
                                    widget.style.top = "-1px";
                                    widget.style.right = "-2px";
                                    widget.style.width = "4px";
                                    widget.style.position = "absolute";
                                    widget.style.zIndex = "1";
                                    widget.style.cursor = resizingWidgetCursorStyle;
                                    widget.style.userSelect = "none";
                                    widget.style.height = `${tableHeight}px`;
                                    decorations.push(Decoration.widget(
                                        docNodePos + tableNodePos + tableNode.nodeSize,
                                        widget
                                    ));
                                }
                            }
                        });
                    }
                };

                // apply decorate function to all doc descendants
                state.doc.descendants(decorate);

                // create the decoration set
                return DecorationSet.create(state.doc, decorations);
            },
            handleDOMEvents: {
                // on mouse move
                mousemove: (editorView: EditorView, event: MouseEvent) => {
                    // get plugin state
                    const pluginState = tableColumnResizingPluginKey.getState(editorView.state);
                    // if pageX, curCol and curColWidth are defined, means we are resizing
                    if (pluginState && pluginState.pageX && pluginState.curCol && pluginState.curColWidth) {
                        // get curColId
                        const curColId = pluginState.curCol.id;
                        // init diffX
                        const diffX = event.pageX - pluginState.pageX;
                        // set new width for curCol and nxtCol
                        const newCurColWidth = pluginState.curColWidth + diffX;                        

                        // init transaction
                        let transaction: Transaction | undefined = undefined;

                        if (pluginState.curColWidth + diffX > 142) {
                            // go through all doc nodes
                            editorView.state.doc.descendants((docNode: Node, docNodePos: number) => {
                                // if id is the same as curColId
                                if (docNode.attrs.id === curColId && (!pluginState.nxtCol || (pluginState.nxtColWidth && pluginState.nxtColWidth > 142))) {
                                    // change style of curCol
                                    transaction = transaction ?? editorView.state.tr;
                                    transaction = transaction
                                        .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                                        .setNodeMarkup(
                                            docNodePos,
                                            null,
                                            {
                                                ...docNode.attrs,
                                                style: `width: ${newCurColWidth}px;`,
                                            }
                                        );
                                }
                                // set table width to allow resizing
                                const currentTableId = pluginState?.currentTable?.getAttribute(`${CustomBlockIdAttributeEnum.Table}`);
                                if (currentTableId && pluginState.currentTableWidth && docNode.type.name === EditorTableDOMTag.Table && docNode.attrs[`${CustomBlockIdAttributeEnum.Table}`] === currentTableId) {
                                    const currentTableWidth = pluginState.currentTableWidth + diffX;
                                    transaction = transaction ?? editorView.state.tr;
                                    transaction = transaction
                                        .setMeta(ProseMirrorConstants.ADD_TO_HISTORY_META_KEY, false)
                                        .setNodeMarkup(
                                            docNodePos,
                                            null,
                                            {
                                                ...docNode.attrs,
                                                style: `width: ${currentTableWidth}px;`
                                            }
                                        );
                                }
                            });
                            
                            // if updateEditorView is set
                            if (updateEditorView && transaction) {
                                // call it
                                updateEditorView(editorView, transaction, false);
                            }
                        }
                    }
                },
                // on mouse out
                mouseout: (_: EditorView, event: MouseEvent) => {
                    // get event target as HTMLElement
                    const target = event.target as HTMLElement;

                    // if target is a resizing widget
                    if (isResizingWidgetElement(target)) {
                        target.style.borderRight = "";
                    }
                },
                // on mouse up
                mouseup: (editorView: EditorView) => {
                    // if user was resizing
                    if (isResizing(editorView)) {
                        // set default state (reset)
                        updateTableColumnResizingPluginState(editorView, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                    }
                },
                // on mouse over
                mouseover: (_: EditorView, event: MouseEvent) => {
                    // get event target as HTMLElement
                    const target = event.target as HTMLElement;

                    // if target is a resizing widget
                    if (isResizingWidgetElement(target)) {
                        // set resizer div height same as table if they are not same
                        const currentTableHeight = target.closest("table")?.getBoundingClientRect().height;
                        const newResizingDivHeight = `${currentTableHeight}px`;
                        if (currentTableHeight && target.style.height !== newResizingDivHeight) {
                            target.style.height = newResizingDivHeight;
                        }

                        target.style.borderRight = "2px solid #007AFF";
                    }
                },
                mousedown: (editorView: EditorView, event: MouseEvent) => {
                    // get event target as HTMLElement
                    const target = event.target as HTMLElement;

                    // if target is a resizing widget
                    if (isResizingWidgetElement(target)) {
                        // get current column (th)
                        const curCol = target.parentElement;

                        const currentTable = curCol?.closest("table");
                        const currentTableWidth = currentTable?.offsetWidth || 0;

                        // safety-checks
                        if (curCol && currentTable) {
                            // get next column (th)
                            const nxtCol = curCol.nextElementSibling as HTMLElement;

                            // get event pageX
                            const pageX = event.pageX;

                            // get padding difference
                            const padding = paddingDiff(curCol);

                            // calculate current column width and next column width
                            const curColWidth = curCol.offsetWidth - padding;
                            let nxtColWidth: number | undefined = undefined;
                            if (nxtCol) {
                                nxtColWidth = nxtCol.offsetWidth - padding;
                            }

                            // update plugin state
                            updateTableColumnResizingPluginState(editorView, pageX, curCol, nxtCol, curColWidth, nxtColWidth, currentTable, currentTableWidth);
                        }
                    }
                },
            },
        },
    });
};

// plugin state
class TableColumnResizingPluginState {
    // store event pageX, current column, next column, current column width and next column width
    // and isDragging flag
    public pageX: number | undefined = undefined;
    public curCol: HTMLElement | undefined = undefined;
    public nxtCol: HTMLElement | undefined = undefined;
    public currentTable: HTMLTableElement | undefined = undefined;
    public curColWidth: number | undefined = undefined;
    public nxtColWidth: number | undefined = undefined;
    public currentTableWidth: number | undefined = undefined;

    // constructor
    constructor(pageX: number | undefined, curCol: HTMLElement | undefined, nxtCol: HTMLElement | undefined,
            curColWidth: number | undefined, nxtColWidth: number | undefined, currentTable: HTMLTableElement | undefined,
            currentTableWidth: number | undefined) {
        this.pageX = pageX;
        this.curCol = curCol;
        this.nxtCol = nxtCol;
        this.curColWidth = curColWidth;
        this.nxtColWidth = nxtColWidth;
        this.currentTable = currentTable;
        this.currentTableWidth = currentTableWidth;
    }

    apply(tr: Transaction): TableColumnResizingPluginState {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const state = this;

        // get action from transaction
        const action = tr.getMeta(tableColumnResizingPluginKey);

        // if action is set
        if (action && action.setData) {
            // get pageX, curCol, nxtCol, curColWidth and nxtColWidth from action
            const { pageX, curCol, nxtCol, curColWidth, nxtColWidth, currentTable, currentTableWidth } = action.setData;
            // return new state
            return new TableColumnResizingPluginState(pageX, curCol, nxtCol, curColWidth, nxtColWidth, currentTable, currentTableWidth);
        }

        // return old state
        return state;
    }
}

// helper function to know if an element is resizing widget element
function isResizingWidgetElement(element: HTMLElement): boolean {
    return (element.className.includes(resizingWidgetClassName) && element.style.cursor === resizingWidgetCursorStyle);
}

// helper function to update plugin state
export const updateTableColumnResizingPluginState = (editorView: EditorView, pageX: number | undefined, 
        curCol: HTMLElement | undefined, nxtCol: HTMLElement | undefined,
        curColWidth: number | undefined, nxtColWidth: number | undefined,
        currentTable: HTMLTableElement | undefined, currentTableWidth: number | undefined): void => {
    // disptach transaction to update plugin state
    editorView.dispatch(
        editorView.state.tr.setMeta(tableColumnResizingPluginKey, { 
            setData: { pageX, curCol, nxtCol, curColWidth, nxtColWidth, currentTable, currentTableWidth }
        }),
    );
};

// helper function to know if user is resizing
export const isResizing = (editorView: EditorView): boolean => {
    // get plugin state
    const pluginState = tableColumnResizingPluginKey.getState(editorView.state);
    // if pageX, curCol and curColWidth are defined, means we are resizing
    return pluginState !== undefined && pluginState.pageX !== undefined && pluginState.curCol!== undefined && pluginState.curColWidth !== undefined;
};

// helper function to get the padding difference on an element
function paddingDiff(element: HTMLElement) {
    // safety-checks
    if (getStyleVal(element, "box-sizing") == "border-box") {
        return 0;
    }

    // get padding left and right
    const padLeft = getStyleVal(element, "padding-left");
    const padRight = getStyleVal(element, "padding-right");

    // return sum of padding left and right
    return (parseInt(padLeft) + parseInt(padRight));

}

// helper function to get value of a css property
function getStyleVal(element: HTMLElement, cssProperty: string) {
    // return computed style
    return (window.getComputedStyle(element, null).getPropertyValue(cssProperty));
}