// node_modules
import { Node } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

export const placeholderPlugin = (placeholder: string) => {
    return new Plugin({
        props: {
            decorations: (state: EditorState) => {
                const decorations: Decoration[] = [];
                // only show placeholder if there is only one node, 
                // and it is a paragraph and it is empty
                if (state.doc.childCount < 2 &&
                    state.doc.firstChild && state.doc.firstChild.type.name === "paragraph" &&
                    state.doc.firstChild.childCount === 0 && state.doc.firstChild.textContent === "") {
                    const decorate = (node: Node, pos: number) => {
                        decorations.push(
                            Decoration.node(pos, pos + node.nodeSize, {
                                class: "empty-node",
                                "data-placeholder": placeholder || ""
                            })
                        );
                    };
                    state.doc.descendants(decorate);
                }
                return DecorationSet.create(state.doc, decorations);
            }
        }
    });
};