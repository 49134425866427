// node_modules
import axios from "axios";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TCommentDTO, TCommentv2DTO } from "Types";

export class CommentController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/comment`;
    private _resourcePathv2 = `${AxiosHelperSingleton.getServerBaseURL()}api/v2/comment`;

    public async createAsync(commentDTO: TCommentDTO): Promise<TCommentDTO | undefined> { 
        try {
            const response = await axios.post<TCommentDTO>(`${this._resourcePath}`, commentDTO);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateAsync(id: string, commentDTO: TCommentDTO): Promise<TCommentDTO | undefined> { 
        try {
            const response = await axios.put<TCommentDTO>(`${this._resourcePath}/${id}`, commentDTO);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async deleteAsync(id: string): Promise<boolean> { 
        try {
            const response = await axios.delete(`${this._resourcePath}/${id}`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async createv2Async(commentDTO: TCommentv2DTO): Promise<TCommentv2DTO | undefined> { 
        try {
            const response = await axios.put<TCommentv2DTO>(`${this._resourcePathv2}`, commentDTO);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getv2Async(objectType: ObjectTypeEnum, objectId: string): Promise<TCommentv2DTO[] | undefined> { 
        try {
            const response = await axios.get<{comments: TCommentv2DTO[]}>(`${this._resourcePathv2}/${objectType}/${objectId}`);

            if (response) {
                return response.data.comments;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updatev2Async(commentId: string, comment: TCommentv2DTO): Promise<TCommentv2DTO | undefined> { 
        try {
            const response = await axios.patch<TCommentv2DTO>(`${this._resourcePathv2}/${commentId}`, {
                text: comment.text
            });

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async deletev2Async(commentId: string): Promise<boolean> { 
        try {
            const response = await axios.delete(`${this._resourcePathv2}/${commentId}`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const CommentControllerSingleton = new CommentController();