// node_modules
import MarkdownIt from "markdown-it";
import StateCore from "markdown-it/lib/rules_core/state_core";
import Token from "markdown-it/lib/token";

export function addModifyLinkExtension(markdownIt: MarkdownIt): void {
    markdownIt.core.ruler.after(
        "inline",
        "markdown-it-modify-link",
        (state: StateCore) => {
            state.tokens.forEach((value: Token) => {
                if (value.type === "inline" && value.children) {
                    value.children.forEach((token: Token) => {
                        const type: string = token.type;
                        if (type === "link_open" && token && token.attrs) {
                            token.attrs.push(["ref", "noopener noreferrer"], ["target", "_blank"]);
                        }
                    });
                }
            });
            return false;
        }
      );
}