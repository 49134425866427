export enum EditorTableDOMTag {
    Table = "table",
    THead = "thead",
    TBody = "tbody",
    Tr = "tr",
    Td = "td",
    Th = "th"
}

export const EditorTableDOMTagValues: string[] = [
    EditorTableDOMTag.Table.toString(),
    EditorTableDOMTag.THead.toString(),
    EditorTableDOMTag.TBody.toString(),
    EditorTableDOMTag.Tr.toString(),
    EditorTableDOMTag.Td.toString(),
    EditorTableDOMTag.Th.toString()
];
