// node_modules
import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
// Types
import { TAxiosParams, TUseFetch } from "Types";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Constants
import { ErrorConstants } from "Constants";

export const useFetch = <T>(url: string, axiosParams?: TAxiosParams): TUseFetch<T> => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [didError, setDidError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [fetchedData, setFetchedData] = useState<T | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();
        let isMounted = true;

        async function fetch() {
            try {
                setIsLoading(true);
                const response: AxiosResponse<T> = await axios.get(`${AxiosHelperSingleton.getServerBaseURL()}${url}`, {
                    params: axiosParams,
                    cancelToken: source.token
                });
                if (isMounted) {
                    setFetchedData(response.data);
                    setIsLoading(false);
                    setDidError(false);
                    setErrorMessage("");
                }
            } catch (error) {
                if (isMounted) {
                    let errorMessageToDisplay: string = ErrorConstants.DEFAULT_ERROR_MESSAGE;
                    // in the case where there is an error message in the response
                    // we use it instead of using the default error message 
                    if (axios.isAxiosError(error)) {
                        const typedError = (error as AxiosError);
                        if (typedError.response && 
                                typedError.response.data &&
                                typeof typedError.response.data === "string") {
                            errorMessageToDisplay = typedError.response.data;
                        }
                    }
                    setErrorMessage(errorMessageToDisplay);
                    setDidError(true);
                    setIsLoading(false);
                }
            }
          }

          fetch();
          
          return () => {
              isMounted = false;
              source.cancel();
          };
    }, [axiosParams, url]);
  
    return { isLoading, didError, errorMessage, fetchedData };
};