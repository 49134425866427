export enum RolesEnum { 
    FindestAdministrator = "FindestAdministrator",
    FindestScout = "FindestScout",
    BackOffice = "BackOffice",
    Scout = "Scout",
    // Universe roles
    Owner = "Owner",
    Administrator = "Administrator",
    Contributor = "Contributor",
    Viewer = "Viewer",
    External = "External",
}