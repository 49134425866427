// React
import { Dispatch, SetStateAction, useCallback } from "react";
// Interfaces
import { IStudyDTO } from "Interfaces";
// Hooks
import { useObjectNameChangeListener } from "./useObjectNameChangeListener";

export const useStudyNameChangeListener = (
    setStudies?: Dispatch<SetStateAction<IStudyDTO[]>>,
    setStudy?: Dispatch<SetStateAction<IStudyDTO | undefined>>) => {
    // Handlers
    const onObjectNameChange = useCallback((objectId: string, name: string) => {
        if (setStudies) {
            setStudies((prevStudies) => {
                return prevStudies.map(study => {
                    if (study.id === objectId) {
                        return {
                            ...study,
                            title: name
                        };
                    }
                    return study;
                });
            });
        }

        if (setStudy) {
            setStudy((prevStudy) => {
                // safety-checks
                if (!prevStudy  || prevStudy.id !== objectId) {
                    return prevStudy;
                }
                return {
                    ...prevStudy,
                    title: name
                };
            });
        }
    }, [setStudies, setStudy]);

    useObjectNameChangeListener(onObjectNameChange);
};
