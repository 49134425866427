// node_modules
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useState } from "react";
// Components
import { Checkbox, FindestTextBox, SearchableDropdown } from "Components";
import { SelectSearchPriority } from "../SearchPriority/SelectSearchPriority";
import { UnitComparisonDropdown } from "./UnitComparisonDropdown";
// Enums
import { MathematicalOperationEnum, SearchPriorityEnum, SearchSubTermTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { MathematicalOperationHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import { TSearchUnitDTO, TSpecificationUnitDTO } from "Types";
// Styles
import styles from "./specificationUnit.module.scss";
import unitComparisonStyles from "./unitComparisonEditDropdown.module.scss";
import { SearchSpecificationUnitControllerSingleton } from "Controllers";

type TSpecificationUnitProps = {
    specificationUnit: TSpecificationUnitDTO,
    deleteSpecificationUnitAsync: (specificationUnitId: number) => Promise<void>,
    updateSearchSubTermValueAsync: (specificationUnitId: number, specificationUnitUpdatedSearchSubTermValue: number | string, searchSubTermType: SearchSubTermTypeEnum) => Promise<void>,
    updateSpecificationUnitSearchPriorityAsync: (specificationUnitId: number, specificationUnitUpdatedSearchPriority: SearchPriorityEnum) => Promise<void>,
    updateSpecificationUnitMathLimitTypeAsync: (specificationUnitId: number, specificationUnitUpdatedMathLimitType: MathematicalOperationEnum) => Promise<void>,
    isSearchTermPriorityDropdown: boolean
};

export const SpecificationUnit: FC<TSpecificationUnitProps> = ({ specificationUnit,
    deleteSpecificationUnitAsync, updateSearchSubTermValueAsync, updateSpecificationUnitSearchPriorityAsync, updateSpecificationUnitMathLimitTypeAsync, isSearchTermPriorityDropdown }: TSpecificationUnitProps) => {
    // State
    const [unitValue, setUnitValue] = useState<number | undefined>(specificationUnit.unitValue);
    const [maximumUnitValue, setMaximumUnitValue] = useState<number | undefined>(specificationUnit.maxUnitValue);

    // Logic
    useEffect(() => {
        setUnitValue(specificationUnit.unitValue);
    }, [specificationUnit.unitValue]);

    useEffect(() => {
        setMaximumUnitValue(specificationUnit.maxUnitValue);
    }, [specificationUnit.maxUnitValue]);

    useEffect(() => {
        // when current specification is edited,
        // after 500 ms do:
        const delayDebounceFn = setTimeout(() => {
            // if new specification is null/empty/undefined, set new specification to the old one
            if (!unitValue) {
                setUnitValue(specificationUnit.unitValue);
                return;
            }
            // if the new specification is the same as the old one, do nothing
            if (unitValue === specificationUnit.unitValue) return;
            // set specification state
            setUnitValue(unitValue);
            // call parent on specification change
            updateSearchSubTermValueAsync(specificationUnit.id, unitValue, SearchSubTermTypeEnum.UnitValue);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [updateSearchSubTermValueAsync, unitValue, specificationUnit.id, specificationUnit.unitValue]);

    useEffect(() => {
        // when current specification is edited,
        // after 500 ms do:
        const delayDebounceFn = setTimeout(() => {
            // if new specification is null/empty/undefined, set new specification to the old one
            if (!maximumUnitValue) {
                setMaximumUnitValue(specificationUnit.maxUnitValue);
                return;
            }
            // if the new specification is the same as the old one, do nothing
            if (maximumUnitValue === specificationUnit.maxUnitValue) return;
            // set specification state
            setMaximumUnitValue(maximumUnitValue);
            // call parent on specification change
            updateSearchSubTermValueAsync(specificationUnit.id, maximumUnitValue, SearchSubTermTypeEnum.MaximumUnitValue);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [updateSearchSubTermValueAsync, maximumUnitValue, specificationUnit.id, specificationUnit.maxUnitValue]);

    const onUpdateSearchSubTermValueAsync = useCallback(async (specificationUnitUpdatedSearchSubTermValue: number | string, searchSubTermType: SearchSubTermTypeEnum) => {
        // if search sub term type is unit
        if (searchSubTermType === SearchSubTermTypeEnum.Unit) {
            await updateSearchSubTermValueAsync(specificationUnit.id, specificationUnitUpdatedSearchSubTermValue, SearchSubTermTypeEnum.Unit);
            // stop execution
            return;
        }

        // otherwise, parse to number
        const parsedSpecificationUnitUpdatedSearchSubTermValue = parseFloat(specificationUnitUpdatedSearchSubTermValue as string);
        // if parsed value is not a number
        if (isNaN(parsedSpecificationUnitUpdatedSearchSubTermValue)) {
            // show error toast
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Please input a valid number as unit value.");
            // stop execution
            return;
        }

        // if search sub term type is unit value
        if (searchSubTermType === SearchSubTermTypeEnum.UnitValue) {
            // set unit value
            setUnitValue(parsedSpecificationUnitUpdatedSearchSubTermValue);
        } else if (searchSubTermType === SearchSubTermTypeEnum.MaximumUnitValue) {
            // set maximum unit value
            setMaximumUnitValue(parsedSpecificationUnitUpdatedSearchSubTermValue);
        }
    }, [specificationUnit.id, updateSearchSubTermValueAsync]);

    const onUpdateSpecificationUnitMathLimitTypeAsync = useCallback(async (specificationUnitUpdatedMathLimitType: MathematicalOperationEnum) => {
        await updateSpecificationUnitMathLimitTypeAsync(specificationUnit.id, specificationUnitUpdatedMathLimitType);
    }, [specificationUnit.id, updateSpecificationUnitMathLimitTypeAsync]);

    const onUpdateSpecificationUnitSearchPriorityAsync = useCallback(async (specificationUnitUpdatedSearchPriority: SearchPriorityEnum) => {
        await updateSpecificationUnitSearchPriorityAsync(specificationUnit.id, specificationUnitUpdatedSearchPriority);
    }, [specificationUnit.id, updateSpecificationUnitSearchPriorityAsync]);

    const onDeleteSpecificationUnitAsync = useCallback(async () => {
        await deleteSpecificationUnitAsync(specificationUnit.id);
    }, [deleteSpecificationUnitAsync, specificationUnit.id]);
        
    return (
        <div className={styles.specificationUnitContainer}>
            <div className={specificationUnit.searchType !== SearchPriorityEnum.Disabled ? styles.specificationUnit : [styles.specificationUnit, styles.disabled].join(" ")}>
                <Checkbox
                    isChecked={specificationUnit.searchType !== SearchPriorityEnum.Disabled}
                    onCheckboxChange={() => { onUpdateSpecificationUnitSearchPriorityAsync(specificationUnit.searchType === SearchPriorityEnum.Disabled ? SearchPriorityEnum.Should : SearchPriorityEnum.Disabled); }} />
                <UnitComparisonDropdown 
                    overriddenStyles={unitComparisonStyles} 
                    mathematicalOperation={specificationUnit.mathLimitType} 
                    selectMathOperation={onUpdateSpecificationUnitMathLimitTypeAsync} />
                {specificationUnit.mathLimitType !== MathematicalOperationEnum.None ?
                    specificationUnit.mathLimitType === MathematicalOperationEnum.Between ?
                            <div style={{display: "flex"}}>
                                <FindestTextBox 
                                    extraClassName={styles.unitValueInputContainer} 
                                    extraClassNameInputElement={styles.unitValueInputField} 
                                    placeholder="Min" value={specificationUnit.unitValue?.toString()} 
                                    onChange={(text: string) => { onUpdateSearchSubTermValueAsync(text, SearchSubTermTypeEnum.UnitValue); }} />
                                <span className={styles.rangeDivider}>-</span>
                                <FindestTextBox 
                                    extraClassName={styles.unitValueInputContainer} 
                                    extraClassNameInputElement={styles.unitValueInputField} 
                                    placeholder="Max" value={specificationUnit.maxUnitValue?.toString()} 
                                    onChange={(text: string) => { onUpdateSearchSubTermValueAsync(text, SearchSubTermTypeEnum.MaximumUnitValue); }} />
                            </div>
                        :
                            <div>
                                <FindestTextBox 
                                    extraClassName={styles.unitValueInputContainer} 
                                    extraClassNameInputElement={styles.unitValueInputField} 
                                    placeholder={MathematicalOperationHelperSingleton.getMathematicalOperationDisplayName(specificationUnit.mathLimitType)} 
                                    value={specificationUnit.unitValue?.toString()} 
                                    onChange={(text: string) => { onUpdateSearchSubTermValueAsync(text, SearchSubTermTypeEnum.UnitValue); }} />
                            </div>
                    : 
                        null
                }
                <SearchableDropdown<TSearchUnitDTO> 
                    placeholder="Unit" 
                    itemName="unit" 
                    asyncSearchFunction={SearchSpecificationUnitControllerSingleton.getAsync}
                    doClearValueOnClick={false} 
                    resultToIdAndName={SearchSpecificationUnitControllerSingleton.searchSpecificationUnitToIdAndName} 
                    onResultClickAsync={async (result: TSearchUnitDTO) => { await onUpdateSearchSubTermValueAsync(result.name, SearchSubTermTypeEnum.Unit); return true; }} 
                    value={specificationUnit.unit} 
                    overriddenStyles={styles} />
            </div>
            <div className={styles.searchPriorityContainer}>
                {specificationUnit.searchType !== SearchPriorityEnum.Disabled ?
                        <SelectSearchPriority 
                            searchPriority={specificationUnit.searchType} 
                            onChange={onUpdateSpecificationUnitSearchPriorityAsync} 
                            isSearchTermPriorityDropdown={isSearchTermPriorityDropdown} />
                    : 
                        null
                }
                {specificationUnit.searchType !== SearchPriorityEnum.Disabled ?
                        <FontAwesomeIcon 
                            icon={faTrashAlt} 
                            onClick={onDeleteSpecificationUnitAsync} />
                    : 
                        null
                }
            </div>
        </div>
    );
};