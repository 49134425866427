// node_modules
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useCallback, useContext, useMemo } from "react";
// Styles
import styles from "./ratingStar.module.scss";
// Contexts
import { AuthContext } from "Providers";

// Props type
type TRatingStarProps = {
    children?: ReactNode,
    rating: number,
    size?: "small" | "medium" | "large" | "xlarge",
    isRatingNeeded?: boolean,
    isRatingShown?: boolean,
    doShowRatingAsPercentage?: boolean,
    onMouseOverHandler?: () => void,
    onMouseOutHandler?: () => void,
    onClickHandler?: () => void,
    extraClassNames?: {container?: string },
    clickable?: boolean
};

// Component
export const RatingStar: FC<TRatingStarProps> = ({ children, rating, isRatingNeeded, isRatingShown, doShowRatingAsPercentage, onMouseOverHandler, onMouseOutHandler, onClickHandler, extraClassNames, size = "small", clickable }: TRatingStarProps) => {
    // Contexts
    const { isUserExternal } = useContext(AuthContext);
    
    // fix decimal
    const fixDecimal = useCallback((decimal: number): string => {
        const numString = decimal.toString();
        const numDecimals = (numString.split(".")[1] || "").length;

        if (numDecimals >= 2) {
            return decimal.toFixed(2);
        } else if (numDecimals === 1) {
            return decimal.toFixed(1);
        } else {
            return decimal.toFixed(0);
        }
    }, []);

    // Rating is between 0.00 and 1.00 but could also be null, undefined or not in the range
    const rightInsetPercentage = useMemo((): number => {
        // get the right inset in percentage
        return (1.00 - rating) * 100.00;
    }, [rating]);

    // get the rating star container class name
    const ratingStarClassName = useMemo((): string => {
        // init class name
        let className = styles.ratingStarContainer;

        // if is rating needed and user is not external
        if (isRatingNeeded && !isUserExternal) {
            className += ` ${styles.ratingNeeded}`;
        }

        // return class name
        return className;
    }, [isRatingNeeded, isUserExternal]);

    const ratingDisplay = useMemo((): string => {
        // if rating is null or undefined
        if (rating === null || rating === undefined) {
            // if do show rating as percentage is true
            if (doShowRatingAsPercentage) {
                // return 0%
                return "0 %";
            } else {
                // return 0
                return "0";
            }
        }

        // get rating into fixed decimals (maximum of two, it depends on the rating)
        const fixedRating = fixDecimal(rating);

        // if rating is 0.00
        if (fixedRating === "0.00") {
            // if do show rating as percentage is true
            if (doShowRatingAsPercentage) {
                // return 0%
                return "0 %";
            } else {
                // return 0
                return "0";
            }
        }

        // if do show rating as percentage is true
        if (doShowRatingAsPercentage) {
            // return rating as percentage without showing any decimals
            return `${(rating * 100).toFixed(0)} %`;
        } else {
            // return rating as number (show 2 decimals)
            return `${fixedRating}`;
        }
    }, [rating, fixDecimal, doShowRatingAsPercentage]);

    // Render
    return (
        <div 
            className={`${styles.ratingStar} ${extraClassNames?.container ?? ""} ${clickable ? styles.clickable: ""}`}
            onMouseOver={onMouseOverHandler}
            onMouseOut={onMouseOutHandler}
            onClick={onClickHandler}
        >
            <div className={`${ratingStarClassName} ${size ? styles[size] : ""}`}>
                <FontAwesomeIcon
                    icon={faStar} />
                {(rating > 0.00) &&
                    <FontAwesomeIcon
                        className={styles.ratingStarFilled}
                        style={{ clipPath: rating > 0.98 ? "" : `inset(0 ${rightInsetPercentage}% 0 0)` }}
                        icon={faStar} />
                }
            </div>
            {(isRatingShown) && (
                <span className={styles.ratingText}>
                    {ratingDisplay}
                </span>
            )}
            {children}
        </div>
    );
};