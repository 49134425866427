// node_modules
import {
  ChangeEvent,
  FC,
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import { FindestButton } from "Components";
// Controllers
import { AuthControllerSingleton } from "Controllers";
// Contexts
import { AuthContext } from "Providers";
// Helpers
import {
  AuthenticationHelperSingleton,
  AxiosHelperSingleton,
  ExtensionCommunicationHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Enums
import { ToastTypeEnum } from "Enums";
// Images
import FindestUniverseLogo from "Assets/Images/universe_logo_color.png";
// Styles
import { LoginImageSlider } from "./LoginPage/LoginImageSlider/LoginImageSlider";
import loginStyles from "./LoginPage/loginPage.module.scss";
// Types
import { TJsonWebTokenDTO, TLoginResponseDTO } from "Types";

export const TwoFactorPage: FC = () => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const sharedObjectURLFromParams = params.get("sharedObjectURL");

  // Ref
  const formRef = useRef<HTMLFormElement>(null);

  // Contexts
  const { auth, setAuth } = useContext(AuthContext);

  // State
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [sharedObjectURL, setSharedObjectURL] = useState(
    sharedObjectURLFromParams ? sharedObjectURLFromParams.trim() : ""
  );

  useEffect(() => {
    if (sharedObjectURLFromParams) {
      setSharedObjectURL(sharedObjectURLFromParams.trim());
    }
  }, [sharedObjectURLFromParams]);

  // prevent the real submission of the form and trigger the appropriate action
  const onFormSubmit = (submitEvent: FormEvent<HTMLFormElement>) => {
    // prevent the real submission of the form
    submitEvent.preventDefault();
    submitEvent.stopPropagation();

    // if the code is 6 digits long
    if (twoFactorCode.length === 6) {
      // verify the two factor code
      loginWith2FA();
    }
  };

  const loginWith2FA = async () => {
    // login with 2FA
    const loginResponse: TLoginResponseDTO | undefined =
      await AuthControllerSingleton.loginWith2FA(
        auth.userEmail,
        twoFactorCode,
        auth.isRememberMeEnabled
      );

    // safety-checks
    if (!loginResponse) {
      // show an error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Error while verifying code."
      );
      // stop execution, return
      return;
    }

    // decode access token
    const jwt: TJsonWebTokenDTO | undefined =
      AuthenticationHelperSingleton.decodeAccessToken(
        loginResponse.accessToken
      );

    // init isSuccess to true
    let isSuccess = true;

    // safety-checks
    if (!jwt || !AuthenticationHelperSingleton.isJWTValid(jwt)) {
      // set isSuccess to false
      isSuccess = false;
    }

    // if isSuccess true and jwt is set
    if (isSuccess && jwt) {
      // set Authorization header
      AxiosHelperSingleton.setCommonHeaderConfigDefaults(
        "Authorization",
        `Bearer ${loginResponse.accessToken}`
      );

      // reset the authentication with the plugin
      await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();

      // update the auth context
      setAuth((prevAuth) => ({
        ...prevAuth,
        isTwoFactorRequired: false,
        userId:
          jwt[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ],
        userEmail:
          jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
        roles:
          jwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
        permissions: jwt.permissions,
        tenantName: jwt.tenantName,
        tenantId: jwt.tenantId,
      }));

      if (sharedObjectURL) {
        navigate(sharedObjectURL);
      } else {
        navigate("/");
      }
    } else {
      // show an error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Error while verifying code."
      );

      // reset the authentication with the plugin
      await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();
    }
  };

  // Render
  return (
    <div className={loginStyles.loginPage}>
      <div className={loginStyles.loginLeftSide}>
        <LoginImageSlider />
      </div>
      <div className={loginStyles.loginRightSide}>
        <div className={loginStyles.findestUniverseLogoContainer}>
          <img
            className={loginStyles.findestUniverseLogo}
            src={FindestUniverseLogo}
            alt="Findest Universe Logo"
          />
        </div>
        <div className={loginStyles.loginRightSideContent}>
          <form ref={formRef} onSubmit={onFormSubmit}>
            <div className={loginStyles.loginBox}>
              <h3 className={loginStyles.title}>
                Enter your verification code
              </h3>
              <div>
                <p className={loginStyles.mb20}>
                  Two factor authentication (2FA) is enabled on your account.
                  Please enter the verification code to login.
                </p>
                <input
                  className={loginStyles.emailInput}
                  type="text"
                  placeholder="Verification code"
                  value={twoFactorCode}
                  onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                    setTwoFactorCode(changeEvent.target.value);
                  }}
                />
              </div>
              <div className={loginStyles.footer}>
                <FindestButton
                  extraClassName={[
                    loginStyles.button,
                    loginStyles.nextButton,
                  ].join(" ")}
                  title="Verify"
                  onClick={loginWith2FA}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
