// node_modules
import axios, { AxiosResponse } from "axios";
import { ObjectTypeEnum, OrderByEnum, SavedDocumentTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton, ObjectTypeHelperSingleton } from "Helpers";
// Types
import { TAxiosParams, TDocumentsDTO, TOption } from "Types";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";

export class SavedDocumentController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/saveddocument`;
    private defaultTDocumentsDTOValue: TDocumentsDTO = {
        documents: [],
        totalCount: 0
    };

    public async getMyAsync(orderBy: OrderByEnum,
            fromDate: Date | undefined, filterOptions: TOption<SavedDocumentTypeEnum>[], 
            doExcludeLinked: boolean): Promise<TDocumentsDTO> {
        let doIncludePatents = filterOptions.length === 0;
        let doIncludeScienceArticles = filterOptions.length === 0;
        let doIncludeWeblinks = filterOptions.length === 0;
        for (const filterOption of filterOptions) {
            if (filterOption.value === SavedDocumentTypeEnum.UsPatent || 
                    filterOption.value === SavedDocumentTypeEnum.MagPatent) {
                doIncludePatents = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.ScienceArticle) {
                doIncludeScienceArticles = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.Weblink) {
                doIncludeWeblinks = true;
            }
        }

        let url = `${this._resourcePath}/my?orderBy=${orderBy}&doIncludePatents=${doIncludePatents}&doIncludeScienceArticles=${doIncludeScienceArticles}&doIncludeWeblinks=${doIncludeWeblinks}&doExcludeLinked=${doExcludeLinked}`;
        if (fromDate) {
            url += `&fromDate=${fromDate}`;
        }

        try {
            const response: AxiosResponse<TDocumentsDTO> = await axios
                .get<TDocumentsDTO>(url);

            if (response && response.data) {
                return response.data;
            } else {
                return this.defaultTDocumentsDTOValue;
            }
        } catch {
            return this.defaultTDocumentsDTOValue;
        }
    }

    public async getMyCountAsync(doExcludeLinked: boolean): Promise<number> {
        const url = `${this._resourcePath}/my/count?doExcludeLinked=${doExcludeLinked}`;
        try {
            const response: AxiosResponse<number> = await axios
                .get<number>(url);

            if (response && response.data) {
                return response.data;
            } else {
                return 0;
            }
        } catch {
            return 0;
        }
    }

    public async getObjectSavedDocumentsAsync(objectId: string, objectType: ObjectTypeEnum,
            orderBy: OrderByEnum, fromDate: Date | undefined, 
            filterOptions: TOption<SavedDocumentTypeEnum>[]): Promise<TDocumentsDTO> {
        const startUrl = `${AxiosHelperSingleton.getServerBaseURL()}api/${ObjectTypeHelperSingleton.getObjectTypeEndpointName(objectType).toLowerCase()}`;
        
        let doIncludePatents = filterOptions.length === 0;
        let doIncludeScienceArticles = filterOptions.length === 0;
        let doIncludeWeblinks = filterOptions.length === 0;
        for (const filterOption of filterOptions) {
            if (filterOption.value === SavedDocumentTypeEnum.UsPatent || 
                    filterOption.value === SavedDocumentTypeEnum.MagPatent) {
                doIncludePatents = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.ScienceArticle) {
                doIncludeScienceArticles = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.Weblink) {
                doIncludeWeblinks = true;
            }
        }
        
        try {
            const url = `${startUrl}/${objectId}/saveddocuments`;
            const axiosParams: TAxiosParams = {
                orderBy: orderBy,
                doIncludePatents: doIncludePatents,
                doIncludeScienceArticles: doIncludeScienceArticles,
                doIncludeWeblinks: doIncludeWeblinks
            };
            if (fromDate) {
                axiosParams.fromDate = fromDate;
            }

            const response = await axios.get<TDocumentsDTO>(url, {
                params: axiosParams
            });

            if (response && response.data) {
                return response.data;
            } else {
                return this.defaultTDocumentsDTOValue;
            }
        } catch {
            return this.defaultTDocumentsDTOValue;
        }
    }

    public async getAsync(orderBy: OrderByEnum,
        fromDate: Date | undefined, filterOptions: TOption<SavedDocumentTypeEnum>[]): Promise<TDocumentsDTO> {
        let doIncludePatents = filterOptions.length === 0;
        let doIncludeScienceArticles = filterOptions.length === 0;
        let doIncludeWeblinks = filterOptions.length === 0;
        for (const filterOption of filterOptions) {
            if (filterOption.value === SavedDocumentTypeEnum.UsPatent || 
                    filterOption.value === SavedDocumentTypeEnum.MagPatent) {
                doIncludePatents = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.ScienceArticle) {
                doIncludeScienceArticles = true;
            } else if (filterOption.value === SavedDocumentTypeEnum.Weblink) {
                doIncludeWeblinks = true;
            }
        }

        let url = `${this._resourcePath}?orderBy=${orderBy}&doIncludePatents=${doIncludePatents}&doIncludeScienceArticles=${doIncludeScienceArticles}&doIncludeWeblinks=${doIncludeWeblinks}`;
        if (fromDate) {
            url += `&fromDate=${fromDate}`;
        }

        try {
            const response: AxiosResponse<TDocumentsDTO> = await axios
                .get<TDocumentsDTO>(url);

            if (response && response.data) {
                return response.data;
            } else {
                return this.defaultTDocumentsDTOValue;
            }
        } catch {
            return this.defaultTDocumentsDTOValue;
        }
    }

    public async getLinkedToObject(objectId: string): Promise<ISavedDocumentDTO[] | undefined> {
        try {
            const response = await axios.get<ISavedDocumentDTO[]>(`${this._resourcePath}/linkedto/${objectId}`);

            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getByIdAsync(documentId: string): Promise<ISavedDocumentDTO | undefined> {
        try {
            // get document by id
            const response = await axios.get<ISavedDocumentDTO>(`${this._resourcePath}/${documentId}`);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }
}

export const SavedDocumentControllerSingleton = new SavedDocumentController();
