// node_modules
import { TJsonWebTokenDTO } from "Types";

export class AuthenticationHelper {
    public decodeAccessToken(token: string): TJsonWebTokenDTO | undefined {
        // safety-checks
        if (!token) {
            // stop execution, return
            return undefined;
        }

        // decode JWT
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");

        // JSON parse and return
        return JSON.parse(window.atob(base64));
    }

    public isJWTValid(token: TJsonWebTokenDTO | undefined): boolean {
        // safety-checks
        if (!token) {
            // stop execution, return
            return false;
        }

        // check if token is valid (exp greater than current timestamp)
        const currentTimestamp = Math.floor(Date.now() / 1000);
        
        // check JWT validity
        if (token.exp > currentTimestamp && token["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] &&
                token["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] &&
                token.tenantId && 
                token.tenantName) {
            // token is valid
            return true;
        }

        // otherwise, token is invalid
        return false;
    }
}

export const AuthenticationHelperSingleton = new AuthenticationHelper();