// React
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FC, useCallback, useContext, useEffect, useState } from "react";
// Constants
import { LinkingConstants } from "Constants";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Components
import { ConnectToQueryModal, DropdownButton } from "Components";
import { QueryDetailsProvidedLoader, QueryItem } from "Components/Queries";
import { LinkingControllerSingleton, ObjectQueryControllerSingleton, QueryControllerSingleton } from "Controllers";
import { LogFeatureNameEnum, ObjectTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { WindowingContext } from "Providers";
import { TDropdownButtonOption } from "Types";
// Styles
import styles from "./connectedQueries.module.scss";
// Hooks
import { useQueryNameChangeListener, useSpecificLinkRemovedListener } from "Hooks";

export type TConnectedQueriesProps = {
    objectId: string,
    objectName: string,
    objectType: ObjectTypeEnum
};

export const ConnectedQueries: FC<TConnectedQueriesProps> = ({ objectName, objectId, objectType }) => {
    // Context
    const { addSearchWindow, deleteWindow } = useContext(WindowingContext);
    // State
    const [isConnectToQueryModalOpen, setIsConnectToQueryModalOpen] = useState<boolean>(false);
    const [connectedQueries, setConnectedQueries] = useState<IQueryDTO[]>([]);
    
    const optionLabels: TDropdownButtonOption[] = ["create new query", "connect existing query"];

    const refreshQueriesAsync = useCallback(async (forObjectId: string, forObjectType: ObjectTypeEnum) => {
        const retrievedQueries = await ObjectQueryControllerSingleton
            .getConnectedQueriesAsync(forObjectId, forObjectType);
        setConnectedQueries(retrievedQueries);
    }, []);

    useEffect(() => {
        (async () => {
            await refreshQueriesAsync(objectId, objectType);
        })();

    }, [objectId, objectType, refreshQueriesAsync]);

    useEffect(() => {
        setConnectedQueries((prevConnectedQueries) => {
            for (const query of prevConnectedQueries) {
                // Change the name of the current object in the query
                query.connectedObjects = query.connectedObjects.map((connectedObject) => {
                    if (connectedObject.id === objectId) {
                        return {
                            ...connectedObject,
                            name: objectName
                        };
                    }
                    return connectedObject;
                });
            }

            return [...prevConnectedQueries];
        });
    }, [objectId, objectName]);

    const onLinkQuery = async (query: IQueryDTO) => {
        // Link the query to the current object
        const requestStatus = await LinkingControllerSingleton.createToAsync(query.guid, ObjectTypeEnum.Query,
            objectId, objectType);
        // Notify the user if the query could not be linked
        if (requestStatus !== WebRequestStatusEnum.Success) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not link query to object");
            return;
        }

        // Add current object to the query
        query = {
            ...query,
            connectedObjects: [
                ...query.connectedObjects,
                {
                    id: objectId,
                    name: objectName,
                    objectType: objectType,
                    type: ""
                }
            ]
        };

        // Update the state
        setConnectedQueries([query, ...connectedQueries]);
    };

    const onCreateNewQuery = async () => {
        // Create the query
        const newQuery = await QueryControllerSingleton
            .createAsync(`${objectName} - Query ${connectedQueries.length + 1}`, LogFeatureNameEnum.ConnectedQueries);
        // Notify the user if the query could not be created
        if (!newQuery) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not create query");
            return;
        }

        await onLinkQuery(newQuery);
    };

    const onQueryEditClick = useCallback(async (query: IQueryDTO, actionOrigin: string) => {
        addSearchWindow(query, 
            <QueryDetailsProvidedLoader 
                queryId={query.guid}
                onDelete={async () => { 
                    // refresh queries
                    await refreshQueriesAsync(objectId, objectType);

                    // delete the window
                    deleteWindow(query.guid); 
                }}
                onDuplicateAsync={async (duplicateQuery: IQueryDTO) => {
                    // refresh queries
                    await refreshQueriesAsync(objectId, objectType);

                    // delete the window
                    deleteWindow(query.guid);

                    // open the duplicate query
                    onQueryEditClick(duplicateQuery, "DuplicateButton");
                }} />, actionOrigin
        );
    }, [addSearchWindow, deleteWindow, objectId, objectType, refreshQueriesAsync]);

    const onQueryItemClick = (query: IQueryDTO) => {
        onQueryEditClick(query, LogFeatureNameEnum.ConnectedQueries);
    };

    const clickActionsOption = async (option: TDropdownButtonOption) => {
        if (option === "create new query") {
            onCreateNewQuery();
        } else if (option === "connect existing query") {
            setIsConnectToQueryModalOpen(true);
        } else {
            return;
        }
    };

    // Update connected queries when a connection is removed
    const onLinkRemoved = useCallback((toId: string) => {
        setConnectedQueries((prevConnectedQueries) => {
            return prevConnectedQueries.filter((query) => query.guid !== toId);
        });
      }, []);

    // Hooks live update for removing a connection
    useSpecificLinkRemovedListener(objectId, onLinkRemoved);

    // Hooks live update the query name
    useQueryNameChangeListener(setConnectedQueries);

    return (
        <div className={styles.connectedQueriesContainer}>
            <h1 id={`${LinkingConstants.CONNECTED_QUERIES_HEADER_ID}_${objectId}`}>Connected queries</h1>
            <div className={styles.connectedQueriesListContainer}>
                {connectedQueries.map((query: IQueryDTO) => {
                    return (
                        <QueryItem key={query.guid} query={query} 
                            onQueryClick={onQueryItemClick} 
                            extraClassNames={{ queryItemContainer: styles.queryItemContainer, queryItemConnectedObjects: styles.queryItemConnectedObjects}} />
                    );
                })}
            </div>
            
            <DropdownButton
                isButtonEnabled={true}
                optionLabels={optionLabels}
                onClickOption={clickActionsOption}
                extraClassNames={{ dropdownButton: styles.addQueryButtonContainer, button: styles.addQueryButtonStyle, optionsContainer: styles.addQueryOptionContainer, optionText: styles.addQueryOptionButtonText }}
                buttonText="add query" 
                iconNameRight={faChevronDown} />

            {isConnectToQueryModalOpen &&
                <ConnectToQueryModal isOpen={isConnectToQueryModalOpen} setIsOpen={setIsConnectToQueryModalOpen}
                    onLinkedQuery={onLinkQuery} />
            }
        </div>
    );
};