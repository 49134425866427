/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
// node_modules
import MarkdownIt from "markdown-it";
import Token from "markdown-it/lib/token";
import { MarkdownParser, MarkdownSerializer, MarkdownSerializerState } from "prosemirror-markdown";
import { DOMOutputSpec, Mark, Node, Schema } from "prosemirror-model";
// Constants
import { EditorConstants, RegexConstants } from "Constants";
// Enums
import { CustomBlockIdAttributeEnum, CustomBlockMarkerEnum, CustomBlockSpecialAttrsEnum, CustomDOMAttributes, CustomDOMTag, CustomInlineIdAttributeEnum, CustomInlineMarkerEnum, CustomInlineNameEnum, CustomMarkdownSeparatorEnum, EditorTableDOMTag, OtherMarkdownCustomBlockNameEnum, SpecialBlockClassNameEnum, TopDepthMarkdownCustomBlockNameEnum } from "Enums";
// Extensions
import { entityReferenceBlockExtension } from "../../MarkdownItExtensions/EntityReferenceBlockExtension";
import { fileReferenceBlockExtension } from "../../MarkdownItExtensions/FileReferenceBlockExtension";
import { headingBlockExtension } from "../../MarkdownItExtensions/HeadingBlockExtension";
import { highlightReferenceBlockExtension } from "../../MarkdownItExtensions/HighlightReferenceBlockExtension";
import { imageReferenceBlockExtension } from "../../MarkdownItExtensions/ImageReferenceBlockExtension";
import { inlineReferenceExtension } from "../../MarkdownItExtensions/InlineReferenceExtension";
import { inlineStarsExtension } from "../../MarkdownItExtensions/InlineStarsExtension";
import { intakeSheetConfirmationBlockExtension } from "../../MarkdownItExtensions/IntakeSheetConfirmationBlockExtension";
import { studyReferenceBlockExtension } from "../../MarkdownItExtensions/StudyReferenceBlockExtension";
import { subScriptExtension } from "../../MarkdownItExtensions/SubScriptExtension";
import { superScriptExtension } from "../../MarkdownItExtensions/SuperScriptExtension";
import multimd_table_plugin from "../../MarkdownItExtensions/markdown-it-multimd-table";
// Helpers
import { MarkdownItHelperSingleton, ProseMirrorHelperSingleton, StringHelperSingleton } from "Helpers";

export const findestSchema = new Schema({
    nodes: {
        doc: {
            content: "block+",
            draggable: false
        },

        paragraph: {
            group: "block",
            content: "inline*",
            draggable: false,
            parseDOM: [{ tag: "p" }],
            toDOM() {
                return ["p", {
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },

        text: {
            group: "inline",
            draggable: false,
            toDOM(node) { return node.text as DOMOutputSpec; }
        },

        ordered_list: {
            group: "block",
            content: "list_item*",
            draggable: false,
            attrs: {
                order: {
                    default: 1
                },
                tight: {
                    default: false
                }
            },
            parseDOM: [{
                tag: "ol", getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        order: newDom.hasAttribute("start") ? +newDom.getAttribute("start")! : 1,
                        tight: newDom.hasAttribute("data-tight")
                    };
                }
            }],
            toDOM(node) {
                return ["ol", {
                    start: node.attrs.order === 1 ? null : node.attrs.order,
                    "data-tight": node.attrs.tight ? "true" : null,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        bullet_list: {
            group: "block",
            content: "list_item*",
            draggable: false,
            attrs: {
                tight: {
                    default: false
                }
            },
            parseDOM: [{
                tag: "ul", getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        tight: newDom.hasAttribute("data-tight")
                    };
                }
            }],
            toDOM(node) {
                return ["ul", {
                    "data-tight": node.attrs.tight ? "true" : null,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },

        list_item: {
            content: `(paragraph | ${TopDepthMarkdownCustomBlockNameEnum.HighlightReference} | ${TopDepthMarkdownCustomBlockNameEnum.ImageReference} | ${TopDepthMarkdownCustomBlockNameEnum.FileReference} | ${TopDepthMarkdownCustomBlockNameEnum.EntityReference} | ${TopDepthMarkdownCustomBlockNameEnum.StudyReference}) (paragraph | ordered_list | bullet_list)?`,
            draggable: false,
            parseDOM: [{ tag: "li" }],
            toDOM() {
                return ["li", {
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },

        heading: {
            group: "block",
            content: "inline*",
            draggable: false,
            attrs: {
                level: {
                    default: 1
                },
                id: {
                    default: ""
                }
            },
            parseDOM: [
                { tag: "h1", attrs: { level: 1, id: crypto.randomUUID() } },
                { tag: "h2", attrs: { level: 2, id: crypto.randomUUID() } },
                { tag: "h3", attrs: { level: 3, id: crypto.randomUUID() } }
            ],
            toDOM(node) {
                return [`h${node.attrs.level}`, {
                    id: node.attrs.id,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        table: {
            group: "block",
            content: "thead tbody",
            draggable: false,
            isolating: true,
            attrs: {
                [`${CustomBlockIdAttributeEnum.Table}`]: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.Table}`
                },
                style: {
                    default: ""
                }
            },
            parseDOM: [{
                tag: "table",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        [`${CustomBlockIdAttributeEnum.Table}`]: newDom.getAttribute(`${CustomBlockIdAttributeEnum.Table}`),
                        class: newDom.hasAttribute("class"),
                        style: newDom.getAttribute("style")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.TableContainer}`, {
                        class: `${SpecialBlockClassNameEnum.TableContainer}`
                    }, ["table", {
                        ...node.attrs,
                        draggable: false,
                        ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0]];
            },
        },
        thead: {
            group: "block",
            content: "tr",
            draggable: false,
            attrs: {
                id: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.THead}`
                }
            },
            parseDOM: [{
                tag: "thead",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        id: newDom.getAttribute("id"),
                        class: newDom.hasAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return ["thead", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            },
        },
        tbody: {
            group: "block",
            content: "tr*",
            draggable: false,
            attrs: {
                class: {
                    default: `${SpecialBlockClassNameEnum.TBody}`
                }
            },
            parseDOM: [{
                tag: "tbody",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        class: newDom.hasAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return ["tbody", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        tr: {
            content: "(td | th | td_to_merge)*",
            draggable: false,
            attrs: {
                id: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.Tr}`
                }
            },
            parseDOM: [{
                tag: "tr",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        id: newDom.getAttribute("id"),
                        class: newDom.hasAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return ["tr", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            },
        },
        td: {
            content: `(paragraph | ${TopDepthMarkdownCustomBlockNameEnum.HighlightReference} | ${TopDepthMarkdownCustomBlockNameEnum.ImageReference} | ${TopDepthMarkdownCustomBlockNameEnum.FileReference} | ${TopDepthMarkdownCustomBlockNameEnum.EntityReference} | ${TopDepthMarkdownCustomBlockNameEnum.StudyReference})`,
            draggable: false,
            attrs: {
                id: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.Td}`
                },
                rowspan: {
                    default: `${EditorConstants.DEFAULT_TD_ROW_SPAN}`
                }
            },
            parseDOM: [{
                tag: "td",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        id: newDom.getAttribute("id"),
                        class: newDom.hasAttribute("class"),
                        rowspan: newDom.hasAttribute("rowspan")
                    };
                }
            }],
            toDOM(node) {
                return ["td", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            },
        },
        td_to_merge: {
            group: "block",
            content: "inline*",
            draggable: false,
            attrs: {
                class: {
                    default: `${SpecialBlockClassNameEnum.TdToMerge}`
                }
            },
            toDOM(node) {
                return [`${CustomDOMTag.TdToMerge}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }];
            }
        },
        th: {
            content: "paragraph",
            group: "block",
            draggable: false,
            attrs: {
                id: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.Th}`
                },
                style: {
                    default: ""
                }
            },
            parseDOM: [{
                tag: "th",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        id: newDom.getAttribute("id"),
                        class: newDom.hasAttribute("class"),
                        style: newDom.getAttribute("style")
                    };
                }
            }],
            toDOM(node) {
                return ["th", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            },
        },
        container_break: {
            group: "block",
            content: "inline*",
            draggable: false,
            parseDOM: [{ tag: "p" }],
            toDOM() {
                return ["p", {
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        reference_link: {
            group: "block",
            content: "inline*",
            draggable: false,
            attrs: {
                href: { default: "" },
                target: { default: "_blank" },
                rel: { default: "noreferrer noopener" },
                class: { default: `${SpecialBlockClassNameEnum.ReferenceLink}` }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.ReferenceLink}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        href: newDom.getAttribute("href"),
                        target: newDom.getAttribute("target"),
                        rel: newDom.getAttribute("rel"),
                        class: newDom.getAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.ReferenceLink}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        highlight_reference: {
            group: "block",
            content: `paragraph ${OtherMarkdownCustomBlockNameEnum.ReferenceLink}`,
            contentEditable: false,
            draggable: false,
            isolating: true,
            attrs: {
                [`${CustomBlockIdAttributeEnum.HighlightReference}`]: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.HighlightReference}`
                },
                selected: {
                    default: "false"
                },
                id: {
                    default: ""
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.HighlightReference}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        [`${CustomBlockIdAttributeEnum.HighlightReference}`]: newDom.getAttribute(`${CustomBlockIdAttributeEnum.HighlightReference}`),
                        class: newDom.getAttribute("class"),
                        selected: newDom.getAttribute("selected"),
                        id: newDom.getAttribute("id")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.HighlightReference}`, {
                    ...node.attrs,
                    draggable: false,
                    contentEditable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        image_reference: {
            group: "block",
            content: `${OtherMarkdownCustomBlockNameEnum.ImageReferenceImage} ${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaption}`,
            draggable: false,
            isolating: true,
            attrs: {
                [`${CustomBlockIdAttributeEnum.ImageReference}`]: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.ImageReference}`
                },
                selected: {
                    default: "false"
                },
                id: {
                    default: ""
                }
            },
            parseDOM: [{
                tag: "figure",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        [`${CustomBlockIdAttributeEnum.ImageReference}`]: newDom.getAttribute(`${CustomBlockIdAttributeEnum.ImageReference}`),
                        class: newDom.getAttribute("class"),
                        selected: newDom.getAttribute("selected"),
                        id: newDom.getAttribute("id")
                    };
                }
            }],
            toDOM(node) {
                return ["figure", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        image_reference_img: {
            group: "block",
            draggable: false,
            attrs: {
                src: { default: "" },
                alt: { default: "" },
                class: { default: `${SpecialBlockClassNameEnum.ImageReferenceImg}` }
            },
            parseDOM: [{
                tag: "img",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        src: newDom.getAttribute("src"),
                        alt: newDom.getAttribute("alt"),
                        class: newDom.getAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return ["img", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }];
            }
        },
        image_reference_figcaption: {
            group: "block",
            content: `${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionTextContainer} | ${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionTextContainer} ${OtherMarkdownCustomBlockNameEnum.ReferenceLink}`,
            draggable: false,
            attrs: {
                class: { default: `${SpecialBlockClassNameEnum.ImageReferenceFigcaption}` },
            },
            parseDOM: [{
                tag: "figcaption",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return { class: newDom.getAttribute("class") };
                }
            }],
            toDOM(node) {
                return ["figcaption", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        image_reference_figcaption_text_container: {
            group: "block",
            content: `${OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionText}`,
            draggable: false,
            parseDOM: [{
                tag: `${CustomDOMTag.ImageReferenceFigcaptionTextContainer}`
            }],
            toDOM(node) {
                return [`${CustomDOMTag.ImageReferenceFigcaptionTextContainer}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        image_reference_figcaption_text: {
            group: "block",
            content: "inline*",
            draggable: false,
            attrs: {
                class: { default: `${SpecialBlockClassNameEnum.ImageReferenceFigcaptionText}` },
            },
            parseDOM: [{
                tag: `${CustomDOMTag.ImageReferenceFigcaptionText}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return { class: newDom.getAttribute("class") };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.ImageReferenceFigcaptionText}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        file_reference: {
            group: "block",
            content: OtherMarkdownCustomBlockNameEnum.FileReferenceLink,
            draggable: false,
            isolating: true,
            attrs: {
                [`${CustomBlockIdAttributeEnum.FileReference}`]: { default: "" },
                class: {
                    default: `${SpecialBlockClassNameEnum.FileReference}`
                },
                selected: {
                    default: "false"
                },
                id: {
                    default: ""
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.FileReference}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        [`${CustomBlockIdAttributeEnum.FileReference}`]: newDom.getAttribute(`${CustomBlockIdAttributeEnum.FileReference}`),
                        class: newDom.getAttribute("class"),
                        selected: newDom.getAttribute("selected"),
                        id: newDom.getAttribute("id")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.FileReference}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        file_reference_link: {
            group: "block",
            content: `${OtherMarkdownCustomBlockNameEnum.FileReferenceLinkTitle} ${OtherMarkdownCustomBlockNameEnum.FileReferenceLinkExtension}`,
            draggable: false,
            attrs: {
                href: { default: "" },
                target: { default: "_blank" },
                rel: { default: "noreferrer noopener" },
                class: { default: `${SpecialBlockClassNameEnum.FileReferenceLink}` }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.FileReferenceLink}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        href: newDom.getAttribute("href"),
                        target: newDom.getAttribute("target"),
                        rel: newDom.getAttribute("rel"),
                        class: newDom.getAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.FileReferenceLink}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        file_reference_link_title: {
            group: "block",
            content: "inline*",
            draggable: false,
            attrs: {
                class: { default: `${SpecialBlockClassNameEnum.FileReferenceLinkTitle}` }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.FileReferenceLinkTitle}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return { class: newDom.getAttribute("class") };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.FileReferenceLinkTitle}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        file_reference_link_extension: {
            group: "block",
            content: "inline*",
            draggable: false,
            attrs: {
                class: { default: `${SpecialBlockClassNameEnum.FileReferenceLinkExtension}` }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.FileReferenceLinkExtension}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return { class: newDom.getAttribute("class") };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.FileReferenceLinkExtension}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        entity_reference: {
            group: "block",
            content: `${OtherMarkdownCustomBlockNameEnum.ReferenceLink}`,
            contentEditable: false,
            draggable: false,
            isolating: true,
            attrs: {
                [`${CustomBlockIdAttributeEnum.EntityReference}`]: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.EntityReference}`
                },
                selected: {
                    default: "false"
                },
                id: {
                    default: ""
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.EntityReference}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        [`${CustomBlockIdAttributeEnum.EntityReference}`]: newDom.getAttribute(`${CustomBlockIdAttributeEnum.EntityReference}`),
                        class: newDom.getAttribute("class"),
                        selected: newDom.getAttribute("selected"),
                        id: newDom.getAttribute("id")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.EntityReference}`, {
                    ...node.attrs,
                    draggable: false,
                    contentEditable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        study_reference: {
            group: "block",
            content: `${OtherMarkdownCustomBlockNameEnum.ReferenceLink}`,
            contentEditable: false,
            draggable: false,
            isolating: true,
            attrs: {
                [`${CustomBlockIdAttributeEnum.StudyReference}`]: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.StudyReference}`
                },
                selected: {
                    default: "false"
                },
                id: {
                    default: ""
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.StudyReference}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        [`${CustomBlockIdAttributeEnum.StudyReference}`]: newDom.getAttribute(`${CustomBlockIdAttributeEnum.StudyReference}`),
                        class: newDom.getAttribute("class"),
                        selected: newDom.getAttribute("selected"),
                        id: newDom.getAttribute("id")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.StudyReference}`, {
                    ...node.attrs,
                    draggable: false,
                    contentEditable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        intake_sheet_confirmation: {
            group: "block",
            content: `${OtherMarkdownCustomBlockNameEnum.Title} (${OtherMarkdownCustomBlockNameEnum.IntakeSheetConfirmationNotAccepted} | ${OtherMarkdownCustomBlockNameEnum.IntakeSheetConfirmationAccepted})`,
            contentEditable: false,
            draggable: false,
            isolating: true,
            attrs: {
                [`${CustomBlockIdAttributeEnum.IntakeSheetConfirmation}`]: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.IntakeSheetConfirmation}`
                },
                selected: {
                    default: "false"
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.IntakeSheetConfirmation}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        [`${CustomBlockIdAttributeEnum.IntakeSheetConfirmation}`]: newDom.getAttribute(`${CustomBlockIdAttributeEnum.IntakeSheetConfirmation}`),
                        class: newDom.getAttribute("class"),
                        selected: newDom.getAttribute("selected")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.IntakeSheetConfirmation}`, {
                    ...node.attrs,
                    draggable: false,
                    contentEditable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        intake_sheet_confirmation_not_accepted: {
            group: "block",
            content: `paragraph ${OtherMarkdownCustomBlockNameEnum.Button}`,
            contentEditable: false,
            draggable: false,
            attrs: {
                class: {
                    default: `${SpecialBlockClassNameEnum.IntakeSheetConfirmationNotAccepted}`
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.IntakeSheetConfirmationNotAccepted}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        class: newDom.getAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.IntakeSheetConfirmationNotAccepted}`, {
                    ...node.attrs,
                    draggable: false,
                    contentEditable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        intake_sheet_confirmation_accepted: {
            group: "block",
            content: `${OtherMarkdownCustomBlockNameEnum.Title} paragraph ${OtherMarkdownCustomBlockNameEnum.Button}`,
            contentEditable: false,
            draggable: false,
            attrs: {
                class: {
                    default: `${SpecialBlockClassNameEnum.IntakeSheetConfirmationAccepted}`
                },
                [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedEmail}`]: {
                    default: ""
                },
                [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedDate}`]: {
                    default: ""
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.IntakeSheetConfirmationAccepted}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        class: newDom.getAttribute("class"),
                        [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedEmail}`]: newDom.getAttribute(`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedEmail}`),
                        [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedDate}`]: newDom.getAttribute(`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedDate}`)
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.IntakeSheetConfirmationAccepted}`, {
                    ...node.attrs,
                    draggable: false,
                    contentEditable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        button: {
            group: "block",
            content: "inline*",
            contentEditable: false,
            draggable: false,
            attrs: {
                class: {
                    default: `${SpecialBlockClassNameEnum.Button}`
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.Button}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        class: newDom.getAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.Button}`, {
                    ...node.attrs,
                    draggable: false,
                    contentEditable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        title: {
            group: "block",
            content: "inline*",
            contentEditable: false,
            draggable: false,
            attrs: {
                class: {
                    default: `${SpecialBlockClassNameEnum.Title}`
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.Title}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        class: newDom.getAttribute("class")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.Title}`, {
                    ...node.attrs,
                    draggable: false,
                    contentEditable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        }
    },

    marks: {
        em: {
            parseDOM: [{ tag: "i" }, { tag: "em" },
            { style: "font-style", getAttrs: value => value === "italic" && null }],
            toDOM() { return ["em"]; }
        },
        strong: {
            parseDOM: [{ tag: "b" }, { tag: "strong" },
            {
                style: "font-weight", getAttrs: value => {
                    const newValue = value as string;
                    return RegexConstants.PROSEMIRROR_SCHEMA_BOLD_REGEX.test(newValue) && null;
                }
            }],
            toDOM() { return ["strong"]; }
        },
        subscript: {
            parseDOM: [{ tag: CustomDOMTag.SubScript }],
            toDOM() { return [CustomDOMTag.SubScript]; }
        },

        superscript: {
            parseDOM: [{ tag: CustomDOMTag.SuperScript }],
            toDOM() { return [CustomDOMTag.SuperScript]; }
        },
        link: {
            inclusive: true,
            attrs: {
                href: { default: "" },
                target: { default: "_blank" },
                rel: { default: "noreferrer noopener" }
            },
            parseDOM: [{
                tag: "a[href]",
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        href: newDom.getAttribute("href"),
                        target: newDom.getAttribute("target"),
                        rel: newDom.getAttribute("rel")
                    };
                }
            }],
            toDOM(node) {
                return ["a", {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        marked: {
            inclusive: true,
            parseDOM: [{ tag: "mark" }],
            toDOM() { return ["mark"]; }
        },
        inlineReference: {
            inclusive: false,
            attrs: {
                id: {
                    default: ""
                },
                [`${CustomInlineIdAttributeEnum.InlineReference}`]: {
                    default: ""
                },
                class: {
                    default: `${SpecialBlockClassNameEnum.InlineReference}`
                },
                [`${CustomDOMAttributes.DataInlineReferenceType}`]: {
                    default: ""
                },
                selected: {
                    default: "false"
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.InlineReference}[${CustomInlineIdAttributeEnum.InlineReference}]`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        id: newDom.getAttribute("id"),
                        [`${CustomInlineIdAttributeEnum.InlineReference}`]: newDom.getAttribute(`${CustomInlineIdAttributeEnum.InlineReference}`),
                        class: newDom.getAttribute(`${SpecialBlockClassNameEnum.InlineReference}`),
                        [`${CustomDOMAttributes.DataInlineReferenceType}`]: newDom.getAttribute(`${CustomDOMAttributes.DataInlineReferenceType}`),
                        selected: newDom.getAttribute("selected")
                    };
                }
            }],
            toDOM(node) {
                return [`${CustomDOMTag.InlineReference}`, {
                    ...node.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0];
            }
        },
        inlineStars: {
            attrs: {
                class: {
                    default: `${SpecialBlockClassNameEnum.Stars}`
                },
                min: {
                    default: "0"
                },
                max: {
                    default: "5"
                },
                [`${CustomDOMAttributes.StarsRating}`]: {
                    default: "0"
                },
                [`${CustomDOMAttributes.StarsSourceId}`]: {
                    default: ""
                },
                [`${CustomDOMAttributes.StarsTargetId}`]: {
                    default: ""
                },
                [`${CustomDOMAttributes.StarsNumberOfRaters}`]: {
                    default: "0"
                },
                [`${CustomDOMAttributes.StarsIsRatingNeeded}`]: {
                    default: "false"
                }
            },
            parseDOM: [{
                tag: `${CustomDOMTag.Meter}`,
                getAttrs(dom) {
                    const newDom = dom as Element;
                    return {
                        class: `${SpecialBlockClassNameEnum.Stars}`,
                        min: "0",
                        max: "5",
                        [`${CustomDOMAttributes.StarsRating}`]: newDom.getAttribute(`${CustomDOMAttributes.StarsRating}`),
                        [`${CustomDOMAttributes.StarsSourceId}`]: newDom.getAttribute(`${CustomDOMAttributes.StarsSourceId}`),
                        [`${CustomDOMAttributes.StarsTargetId}`]: newDom.getAttribute(`${CustomDOMAttributes.StarsTargetId}`),
                        [`${CustomDOMAttributes.StarsNumberOfRaters}`]: newDom.getAttribute(`${CustomDOMAttributes.StarsNumberOfRaters}`),
                        [`${CustomDOMAttributes.StarsIsRatingNeeded}`]: newDom.getAttribute(`${CustomDOMAttributes.StarsIsRatingNeeded}`)
                    };
                }
            }],
            toDOM(mark: Mark) {
                // get CustomDOMAttributes.StarsIsRatingNeeded attribute
                const isRatingNeeded: string  | undefined = mark.attrs[`${CustomDOMAttributes.StarsIsRatingNeeded}`];
                let meterContainerClasses = `${SpecialBlockClassNameEnum.MeterContainer}`;
                // if is rating needed
                if (isRatingNeeded === "true") {
                    // add class to meter container
                    meterContainerClasses = meterContainerClasses.concat(` ${SpecialBlockClassNameEnum.RatingIsNeeded}`);
                }

                return [`${CustomDOMTag.MeterContainer}`, {class: `${meterContainerClasses}`}, [`${CustomDOMTag.Meter}`, {
                    ...mark.attrs,
                    draggable: false,
                    ondragstart: EditorConstants.PREVENT_EVENT_STRING
                }, 0], `${mark.attrs[`${CustomDOMAttributes.StarsNumberOfRaters}`] ?? "0"}`];
            }
        }
    }
});

// :: MarkdownSerializer
// A serializer for the [basic schema](#schema).
export const findestMarkdownSerializer = new MarkdownSerializer({
    paragraph(state, node) {
        // if node is empty and has no children, then it's a break
        if (node.content.size === 0 && node.childCount === 0) {
            state.write("::: break\n:::");
            state.closeBlock(node);
        } else {
            // otherwise serialize as a normal paragraph
            state.renderInline(node);
            state.closeBlock(node);
        }
    },
    container_break(state, node) {
        // if node is empty and has no children, then it's a break
        if (node.content.size === 0 && node.childCount === 0) {
            state.write("::: break\n:::");
            state.closeBlock(node);
        } else {
            // otherwise serialize as a normal paragraph
            state.renderInline(node);
            state.closeBlock(node);
        }
    },
    text(state, node: any) {
        state.text(node.text);
    },
    bullet_list(state, node) {
        state.renderList(node, " ", () => `${node.attrs.bullet || "*"} `);
    },
    ordered_list(state, node) {
        const start = node.attrs.order || 1;
        const maxW = String(start + node.childCount - 1).length;
        const space = state.repeat(" ", maxW + 2);
        state.renderList(node, space, i => {
            const nStr = String(start + i);
            return `${state.repeat(" ", maxW - nStr.length) + nStr}. `;
        });
    },
    file_reference(state, node, parentNode) {
        // get first child
        const firstChild = node.firstChild;

        // check if first child is a file reference link
        if (!firstChild || firstChild.type.name !== OtherMarkdownCustomBlockNameEnum.FileReferenceLink) {
            state.write("");
            state.ensureNewLine();
        } else {
            // check if first child has two children of type file_reference_link_title and file_reference_link_extension
            if (firstChild.childCount !== 2 ||
                !firstChild.firstChild ||
                firstChild.firstChild.type.name !== OtherMarkdownCustomBlockNameEnum.FileReferenceLinkTitle ||
                !firstChild.lastChild ||
                firstChild.lastChild.type.name !== OtherMarkdownCustomBlockNameEnum.FileReferenceLinkExtension) {
                state.write("");
                state.ensureNewLine();
            } else {
                // get text content of file reference link first child (title)
                let title = firstChild.firstChild.textContent;
                // get extension from firstChild lastChild text content, remove leading dot if there is one
                const extension = firstChild.lastChild.textContent.replace(/^\./, "");
                // get file source url
                const fileSourceUrl = firstChild.attrs.href;

                // clean file reference title
                title = MarkdownItHelperSingleton
                    .cleanFileReferenceTitle(title, fileSourceUrl, extension);

                state.write(`%F%OPEN%${CustomBlockMarkerEnum.FileReference}%F%[${node.attrs[`${CustomBlockIdAttributeEnum.FileReference}`]}]${EditorConstants.OPEN_REMOVE_ME_TAG}${CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_URL}${fileSourceUrl}${CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_URL}${CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_EXTENSION}${extension}${CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_EXTENSION}${EditorConstants.CLOSE_REMOVE_ME_TAG}${CustomMarkdownSeparatorEnum.OPEN_FILE_REFERENCE_TITLE}${title}${CustomMarkdownSeparatorEnum.CLOSE_FILE_REFERENCE_TITLE}%F%CLOSE%${CustomBlockMarkerEnum.FileReference}%F%`);
                // do not ensure new line if parent node is a td
                if (parentNode.type.name !== EditorTableDOMTag.Td) {
                    state.ensureNewLine();
                }
            }
        }
    },
    file_reference_link(state) {
        state.write("");
    },
    file_reference_link_title(state) {
        state.write("");
    },
    file_reference_link_extension(state) {
        state.write("");
    },
    reference_link(state) {
        state.write("");
    },
    highlight_reference(state, node, parentNode) {
        // get first child
        const firstChild = node.firstChild;

        // check if first child is a paragraph
        if (!firstChild || firstChild.type.name !== "paragraph") {
            state.write("");
            state.ensureNewLine();
        } else {
            let textContent = firstChild.textContent;
            // get reference url
            const referenceUrl = node.child(1)?.attrs.href;

            // clean highlight reference text content
            textContent = MarkdownItHelperSingleton
                .cleanHighlightReferenceContent(textContent, referenceUrl);

            state.write(`%F%OPEN%${CustomBlockMarkerEnum.HighlightReference}%F%[${node.attrs[`${CustomBlockIdAttributeEnum.HighlightReference}`]}]${EditorConstants.OPEN_REMOVE_ME_TAG}${CustomMarkdownSeparatorEnum.OPEN_HIGHLIGHT_REFERENCE_URL}${node.child(1)?.attrs.href}${CustomMarkdownSeparatorEnum.CLOSE_HIGHLIGHT_REFERENCE_URL}${EditorConstants.CLOSE_REMOVE_ME_TAG}${textContent}%F%CLOSE%${CustomBlockMarkerEnum.HighlightReference}%F%`);
            // do not ensure new line if parent node is a td
            if (parentNode.type.name !== EditorTableDOMTag.Td) {
                state.ensureNewLine();
            }
        }
    },
    image_reference(state, node, parentNode) {
        // get second child
        const secondChild = node.child(1);

        // check if second child is a figcaption
        if (!secondChild || secondChild.type.name !== OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaption) {
            state.write("");
            state.ensureNewLine();
        } else {
            // get first child of figcaption
            const firstChild = secondChild.firstChild;
            // check if first child is a image_reference_figcaption_text_container
            if (!firstChild || firstChild.type.name !== OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionTextContainer) {
                state.write("");
                state.ensureNewLine();
            } else {
                // get first child of figcaption's first child
                const imageReferenceFigcaptionText = firstChild.firstChild;
                // check if first child is a image_reference_figcaption_text_container
                if (!imageReferenceFigcaptionText || imageReferenceFigcaptionText.type.name !== OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionText) {
                    state.write("");
                    state.ensureNewLine();
                } else {
                    // get text content of first child
                    let caption = imageReferenceFigcaptionText.textContent;
                    // get reference url
                    let referenceUrl = "";
                    if (secondChild.childCount > 1 && secondChild.child(1).attrs.href) {
                        referenceUrl = secondChild.child(1).attrs.href;
                    }

                    // get image source url
                    const imageSourceUrl: string = node.firstChild?.attrs["src"];

                    // clean image reference caption
                    caption = MarkdownItHelperSingleton
                        .cleanImageReferenceCaption(caption, imageSourceUrl);

                    state.write(`%F%OPEN%${CustomBlockMarkerEnum.ImageReference}%F%[${node.attrs[`${CustomBlockIdAttributeEnum.ImageReference}`]}]${EditorConstants.OPEN_REMOVE_ME_TAG}${CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_IMAGE_URL}${imageSourceUrl}${CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_IMAGE_URL}${CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_URL}${referenceUrl}${CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_URL}${EditorConstants.CLOSE_REMOVE_ME_TAG}${CustomMarkdownSeparatorEnum.OPEN_IMAGE_REFERENCE_IMAGE_CAPTION}${caption}${CustomMarkdownSeparatorEnum.CLOSE_IMAGE_REFERENCE_IMAGE_CAPTION}%F%CLOSE%${CustomBlockMarkerEnum.ImageReference}%F%`);
                    // do not ensure new line if parent node is a td
                    if (parentNode.type.name !== EditorTableDOMTag.Td) {
                        state.ensureNewLine();
                    }
                }
            }
        }
    },
    image_reference_img(state) {
        state.write("");
    },
    image_reference_figcaption(state) {
        state.write("");
    },
    image_reference_figcaption_text_container(state) {
        state.write("");
    },
    image_reference_figcaption_text(state) {
        state.write("");
    },
    list_item(state, node) {
        // if list item node only contains a paragraph which is empty, don't render anything
        if (node.firstChild && node.firstChild.type.name === "paragraph" && node.firstChild.textContent === "") {
            state.write("");
        } else {
            // otherwise, render the list item
            state.renderContent(node);
        }
    },
    entity_reference(state, node, parentNode) {
        // get first child
        const firstChild = node.firstChild;

        // check if first child is a reference link
        if (!firstChild || firstChild.type.name !== `${OtherMarkdownCustomBlockNameEnum.ReferenceLink}`) {
            state.write("");
            state.ensureNewLine();
        } else {
            // get entity title (text content of first child)
            let entityTitle = firstChild.textContent;

            // remove new lines from entity title
            // see #6229
            entityTitle = StringHelperSingleton
                .removeNewLines(entityTitle);

            state.write(`%F%OPEN%${CustomBlockMarkerEnum.EntityReference}%F%[${node.attrs[`${CustomBlockIdAttributeEnum.EntityReference}`]}]${EditorConstants.OPEN_REMOVE_ME_TAG}${CustomMarkdownSeparatorEnum.OPEN_BLOCK_REFERENCE_URL}${firstChild.attrs.href}${CustomMarkdownSeparatorEnum.CLOSE_BLOCK_REFERENCE_URL}${CustomMarkdownSeparatorEnum.OPEN_BLOCK_REFERENCE_TITLE}${entityTitle}${CustomMarkdownSeparatorEnum.CLOSE_BLOCK_REFERENCE_TITLE}${EditorConstants.CLOSE_REMOVE_ME_TAG}%F%CLOSE%${CustomBlockMarkerEnum.EntityReference}%F%`);
            // do not ensure new line if parent node is a td
            if (parentNode.type.name !== EditorTableDOMTag.Td) {
                state.ensureNewLine();
            }
        }
    },
    study_reference(state, node, parentNode) {
        // get first child
        const firstChild = node.firstChild;

        // check if first child is a reference link
        if (!firstChild || firstChild.type.name !== `${OtherMarkdownCustomBlockNameEnum.ReferenceLink}`) {
            state.write("");
            state.ensureNewLine();
        } else {
            // get study title (text content of first child)
            let studyTitle = firstChild.textContent;

            // remove new lines from study title
            // see #6229
            studyTitle = StringHelperSingleton
                .removeNewLines(studyTitle);

            state.write(`%F%OPEN%${CustomBlockMarkerEnum.StudyReference}%F%[${node.attrs[`${CustomBlockIdAttributeEnum.StudyReference}`]}]${EditorConstants.OPEN_REMOVE_ME_TAG}${CustomMarkdownSeparatorEnum.OPEN_BLOCK_REFERENCE_URL}${firstChild.attrs.href}${CustomMarkdownSeparatorEnum.CLOSE_BLOCK_REFERENCE_URL}${CustomMarkdownSeparatorEnum.OPEN_BLOCK_REFERENCE_TITLE}${studyTitle}${CustomMarkdownSeparatorEnum.CLOSE_BLOCK_REFERENCE_TITLE}${EditorConstants.CLOSE_REMOVE_ME_TAG}%F%CLOSE%${CustomBlockMarkerEnum.StudyReference}%F%`);
            // do not ensure new line if parent node is a td
            if (parentNode.type.name !== EditorTableDOMTag.Td) {
                state.ensureNewLine();
            }
        }
    },
    heading(state, node) {
        state.write(`${state.repeat("#", node.attrs.level)} `);
        state.renderInline(node);
        state.closeBlock(node);
    },
    table(state, node) {
        state.renderContent(node);
    },
    tbody(state, node) {
        state.renderContent(node);
    },
    thead(state, node) {
        state.write("|");
        state.renderContent(node);
        state.ensureNewLine();
        state.write("|");
        // get node first child
        const firstChild = node.firstChild;
        // safety-checks
        if (firstChild && firstChild.type.name === "tr") {
            for (let index = 0; index < firstChild.childCount; index++) {
                state.write("---|");
            }
            state.ensureNewLine();
        }
    },
    tr(state, node) {
        // get node first child
        const firstChild = node.firstChild;
        // safety-checks
        if (!firstChild) {
            state.write("");
        } else {
            if (firstChild.type.name === findestSchema.nodes.td.name || firstChild.type.name === findestSchema.nodes.td_to_merge.name) {
                state.write("|");
            }
            state.renderContent(node);
            state.ensureNewLine();
        }
    },
    td_to_merge(state) {
        state.write("^^|");
    },
    td(state, node) {
        // if node only contains a paragraph, render the paragraph inline
        if (node.childCount === 1 && node.firstChild && node.firstChild.type.name === "paragraph") {
            // if paragraph is empty
            if (node.firstChild.textContent === "") {
                // render a space
                state.text(" ");
            } else {
                // otherwise, render the paragraph inline
                state.renderInline(node.firstChild);
            }
        } else {
            state.renderContent(node);
        }
        state.write("|");
    },
    th(state, node) {
        // if node text content is empty, render a space, otherwise render the text content
        state.text(node.textContent === "" ? " " : node.textContent);
        state.write("|");
    },
    intake_sheet_confirmation(state: MarkdownSerializerState, node: Node) {
        // serialize intake sheet confirmation
        state.write(`%F%OPEN%${CustomBlockMarkerEnum.IntakeSheetConfirmation}%F%`);
        state.renderContent(node);
        state.write(`%F%CLOSE%${CustomBlockMarkerEnum.IntakeSheetConfirmation}%F%`);
        state.ensureNewLine();
    },
    intake_sheet_confirmation_not_accepted(state: MarkdownSerializerState) {
        // serialize intake sheet confirmation not accepted
        state.write("");
    },
    intake_sheet_confirmation_accepted(state: MarkdownSerializerState, node: Node) {
        // get intake sheet confirmation email from attributes
        const intakeSheetConfirmationEmail: string | undefined | null = node.attrs[`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedEmail}`];
        // get intake sheet confirmation date from attributes
        const intakeSheetConfirmationDate: string | undefined | null = node.attrs[`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedDate}`];
        
        // safety-checks
        if (!intakeSheetConfirmationEmail || !intakeSheetConfirmationDate) {
            state.write("");
        }

        // serialize intake sheet confirmation accepted
        state.write(`${CustomMarkdownSeparatorEnum.OPEN_INTAKE_SHEET_CONFIRMATION_EMAIL_ADDRESS}${intakeSheetConfirmationEmail}${CustomMarkdownSeparatorEnum.CLOSE_INTAKE_SHEET_CONFIRMATION_EMAIL_ADDRESS}${CustomMarkdownSeparatorEnum.OPEN_INTAKE_SHEET_CONFIRMATION_DATE}${intakeSheetConfirmationDate}${CustomMarkdownSeparatorEnum.CLOSE_INTAKE_SHEET_CONFIRMATION_DATE}`);
    },
    title(state: MarkdownSerializerState) {
        state.write("");
    },
    button(state: MarkdownSerializerState) {
        state.write("");
    }
}, {
    em: { open: "*", close: "*", mixable: true, expelEnclosingWhitespace: true },
    strong: { open: "**", close: "**", mixable: true, expelEnclosingWhitespace: true },
    subscript: { open: `%F%OPEN%${CustomInlineMarkerEnum.SubScript}%F%`, close: `%F%CLOSE%${CustomInlineMarkerEnum.SubScript}%F%`, mixable: true, expelEnclosingWhitespace: true },
    superscript: { open: `%F%OPEN%${CustomInlineMarkerEnum.SuperScript}%F%`, close: `%F%CLOSE%${CustomInlineMarkerEnum.SuperScript}%F%`, mixable: true, expelEnclosingWhitespace: true },
    link: {
        open() {
            return "[";
        },
        close(state, mark: any) {
            return `](${state.esc(mark.attrs.href)}${mark.attrs.title ? ` " ${mark.attrs.title}"` : ""})`;
        }
    },
    marked: { open: "==", close: "==", mixable: true, expelEnclosingWhitespace: true },
    inlineReference: {
        open(_: MarkdownSerializerState, mark: Mark): string { 
            // get attrs to serialize
            const referenceId: string | undefined | null = mark.attrs[`${CustomInlineIdAttributeEnum.InlineReference}`];
            const referenceType: string | undefined | null = mark.attrs[`${CustomDOMAttributes.DataInlineReferenceType}`];

            // init open inline reference markdown
            let openInlineReferenceMarkdown = `%F%OPEN%${CustomInlineMarkerEnum.InlineReference}%F%`;

            // safety-checks
            if (!referenceId || !referenceType) {
                return openInlineReferenceMarkdown;
            }

            // update open inline reference markdown with reference id, url and type
            openInlineReferenceMarkdown = `${openInlineReferenceMarkdown}${CustomMarkdownSeparatorEnum.OPEN_INLINE_REFERENCE_ID}${referenceId}${CustomMarkdownSeparatorEnum.CLOSE_INLINE_REFERENCE_ID}${CustomMarkdownSeparatorEnum.OPEN_INLINE_REFERENCE_TYPE}${referenceType}${CustomMarkdownSeparatorEnum.CLOSE_INLINE_REFERENCE_TYPE}`;

            // return open inline reference markdown
            return openInlineReferenceMarkdown;
        },
        close: `%F%CLOSE%${CustomInlineMarkerEnum.InlineReference}%F%`,
        mixable: false,
        expelEnclosingWhitespace: true
    },
    inlineStars: {
        open(_: MarkdownSerializerState, mark: Mark): string {
            // get attrs to serialize
            const rating: string | undefined | null = mark.attrs[`${CustomDOMAttributes.StarsRating}`];
            const sourceId: string | undefined | null = mark.attrs[`${CustomDOMAttributes.StarsSourceId}`];
            const targetId: string | undefined | null = mark.attrs[`${CustomDOMAttributes.StarsTargetId}`];
            const numberOfRaters: string | undefined | null = mark.attrs[`${CustomDOMAttributes.StarsNumberOfRaters}`];
            const isRatingNeeded: string | undefined | null = mark.attrs[`${CustomDOMAttributes.StarsIsRatingNeeded}`];

            // init open inline stars markdown
            let openInlineStarsMarkdown = `%F%OPEN%${CustomInlineMarkerEnum.Stars}%F%`;

            // safety-checks
            if (rating === null || sourceId === null || targetId === null || numberOfRaters === null || isRatingNeeded === null ||
                    rating === undefined || sourceId === undefined || targetId === undefined || numberOfRaters === undefined || isRatingNeeded === undefined) {
                return openInlineStarsMarkdown;
            }

            // update open inline stars markdown with rating, source id, target id, number of raters and is rating needed
            openInlineStarsMarkdown = `${openInlineStarsMarkdown}${CustomMarkdownSeparatorEnum.OPEN_STARS_SOURCE_ID}${sourceId}${CustomMarkdownSeparatorEnum.CLOSE_STARS_SOURCE_ID}${CustomMarkdownSeparatorEnum.OPEN_STARS_TARGET_ID}${targetId}${CustomMarkdownSeparatorEnum.CLOSE_STARS_TARGET_ID}${EditorConstants.OPEN_REMOVE_ME_TAG}${CustomMarkdownSeparatorEnum.OPEN_STARS_RATING}${rating}${CustomMarkdownSeparatorEnum.CLOSE_STARS_RATING}${CustomMarkdownSeparatorEnum.OPEN_STARS_NUMBER_OF_RATERS}${numberOfRaters}${CustomMarkdownSeparatorEnum.CLOSE_STARS_NUMBER_OF_RATERS}${CustomMarkdownSeparatorEnum.OPEN_STARS_IS_RATING_NEEDED}${isRatingNeeded}${CustomMarkdownSeparatorEnum.CLOSE_STARS_IS_RATING_NEEDED}${EditorConstants.CLOSE_REMOVE_ME_TAG}`;

            // return open inline stars markdown
            return openInlineStarsMarkdown;
        },
        close: `%F%CLOSE%${CustomInlineMarkerEnum.Stars}%F%`,
        mixable: false,
        expelEnclosingWhitespace: true
    }
});

export const findestMarkdownParser = new MarkdownParser(findestSchema,
    MarkdownIt({ html: false })
        .disable(["code", "fence", "blockquote", "hr", "reference", "html_block"])
        .use(multimd_table_plugin)
        .use(superScriptExtension)
        .use(subScriptExtension)
        .use(inlineReferenceExtension)
        .use(inlineStarsExtension)
        .use(require("markdown-it-mark"))
        .use(require("markdown-it-container"), "break", {

            validate: function(params: any) {
                return params.trim().match(/^break/);
            },

            render: function(tokens: any, idx: any) {
                if (tokens[idx].nesting === 1) {
                    // opening tag
                    return "<p>";
                } else {
                    // closing tag
                    return "</p>";
                }
            }
        })
        .use(headingBlockExtension)
        .use(highlightReferenceBlockExtension)
        .use(imageReferenceBlockExtension)
        .use(fileReferenceBlockExtension)
        .use(entityReferenceBlockExtension)
        .use(studyReferenceBlockExtension)
        .use(intakeSheetConfirmationBlockExtension), {
    container_break: {
        block: "paragraph"
    },
    paragraph: {
        block: "paragraph"
    },
    list_item: {
        block: "list_item"
    },
    bullet_list: {
        block: "bullet_list"
    },
    ordered_list: {
        block: "ordered_list",
        getAttrs: (token: Token) => {
            const attrOrder: string | null = token.attrGet("order");
            let newOrder = 1;
            if (attrOrder) {
                newOrder = parseInt(attrOrder, 10);
            }
            return ({ order: newOrder });
        }
    },
    heading: {
        block: "heading",
        getAttrs: (token: Token) => {
            return { level: + token.tag.slice(1), id: crypto.randomUUID() };
        }
    },
    table: {
        block: "table",
        getAttrs: () => {
            return {
                [`${CustomBlockIdAttributeEnum.Table}`]: crypto.randomUUID(),
                class: `${SpecialBlockClassNameEnum.Table}`,
                style: ""
            };
        }
    },
    thead: {
        block: "thead",
        getAttrs: () => {
            return {
                id: crypto.randomUUID(),
                class: `${SpecialBlockClassNameEnum.THead}`
            };
        }
    },
    tbody: {
        block: "tbody",
        getAttrs: () => {
            return {
                class: `${SpecialBlockClassNameEnum.TBody}`
            };
        }
    },
    tr: {
        block: "tr",
        getAttrs: () => {
            return {
                id: crypto.randomUUID(),
                class: `${SpecialBlockClassNameEnum.Tr}`
            };
        }
    },
    td_to_merge: {
        block: "td_to_merge",
        getAttrs: () => {
            return {
                class: `${SpecialBlockClassNameEnum.TdToMerge}`
            };
        }
    },
    td: {
        block: "td",
        getAttrs: (token: Token) => {
            return {
                id: crypto.randomUUID(),
                class: `${SpecialBlockClassNameEnum.Td}`,
                rowspan: token.attrGet("rowspan")
            };
        }
    },
    th: {
        block: "th",
        getAttrs: () => {
            return {
                id: crypto.randomUUID(),
                class: `${SpecialBlockClassNameEnum.Th}`,
                style: ""
            };
        }
    },
    link: {
        mark: "link",
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class"),
                href: token.attrGet("href"),
                target: "_blank",
                rel: "noreferrer noopener"
            };
        }
    },
    reference_link: {
        block: OtherMarkdownCustomBlockNameEnum.ReferenceLink,
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class"),
                href: token.attrGet("href"),
                target: "_blank",
                rel: "noreferrer noopener"
            };
        }
    },
    highlight_reference: {
        block: TopDepthMarkdownCustomBlockNameEnum.HighlightReference,
        getAttrs: (token: Token) => {
            return {
                [`${CustomBlockIdAttributeEnum.HighlightReference}`]: token.attrGet(`${CustomBlockIdAttributeEnum.HighlightReference}`),
                class: token.attrGet("class"),
                selected: token.attrGet("selected"),
                id: crypto.randomUUID()
            };
        }
    },
    image_reference: {
        block: TopDepthMarkdownCustomBlockNameEnum.ImageReference,
        getAttrs: (token: Token) => {
            return {
                [`${CustomBlockIdAttributeEnum.ImageReference}`]: token.attrGet(`${CustomBlockIdAttributeEnum.ImageReference}`),
                class: token.attrGet("class"),
                selected: token.attrGet("selected"),
                id: crypto.randomUUID()
            };
        }
    },
    image_reference_img: {
        block: OtherMarkdownCustomBlockNameEnum.ImageReferenceImage,
        getAttrs: (token: Token) => {
            return {
                src: token.attrGet("src"),
                alt: token.attrGet("alt"),
                class: token.attrGet("class")
            };
        }
    },
    image_reference_figcaption: {
        block: OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaption,
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class")
            };
        }
    },
    image_reference_figcaption_text_container: {
        block: OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionTextContainer,
    },
    image_reference_figcaption_text: {
        block: OtherMarkdownCustomBlockNameEnum.ImageReferenceFigcaptionText,
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class")
            };
        }
    },
    file_reference: {
        block: `${TopDepthMarkdownCustomBlockNameEnum.FileReference}`,
        getAttrs: (token: Token) => {
            return {
                [`${CustomBlockIdAttributeEnum.FileReference}`]: token.attrGet(`${CustomBlockIdAttributeEnum.FileReference}`),
                class: token.attrGet("class"),
                selected: token.attrGet("selected"),
                id: crypto.randomUUID()
            };
        }
    },
    file_reference_link: {
        block: OtherMarkdownCustomBlockNameEnum.FileReferenceLink,
        getAttrs: (token: Token) => {
            return {
                href: token.attrGet("href"),
                target: "_blank",
                rel: "noreferrer noopener",
                class: token.attrGet("class")
            };
        }
    },
    file_reference_link_title: {
        block: OtherMarkdownCustomBlockNameEnum.FileReferenceLinkTitle,
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class")
            };
        }
    },
    file_reference_link_extension: {
        block: OtherMarkdownCustomBlockNameEnum.FileReferenceLinkExtension,
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class")
            };
        }
    },
    entity_reference: {
        block: TopDepthMarkdownCustomBlockNameEnum.EntityReference,
        getAttrs: (token: Token) => {
            return {
                [`${CustomBlockIdAttributeEnum.EntityReference}`]: token.attrGet(`${CustomBlockIdAttributeEnum.EntityReference}`),
                class: token.attrGet("class"),
                selected: token.attrGet("selected"),
                id: crypto.randomUUID()
            };
        }
    },
    study_reference: {
        block: TopDepthMarkdownCustomBlockNameEnum.StudyReference,
        getAttrs: (token: Token) => {
            return {
                [`${CustomBlockIdAttributeEnum.StudyReference}`]: token.attrGet(`${CustomBlockIdAttributeEnum.StudyReference}`),
                class: token.attrGet("class"),
                selected: token.attrGet("selected"),
                id: crypto.randomUUID()
            };
        }
    },
    intake_sheet_confirmation: {
        block: TopDepthMarkdownCustomBlockNameEnum.IntakeSheetConfirmation,
        getAttrs: (token: Token) => {
            return {
                [`${CustomBlockIdAttributeEnum.IntakeSheetConfirmation}`]: token.attrGet(`${CustomBlockIdAttributeEnum.IntakeSheetConfirmation}`),
                class: token.attrGet("class"),
                selected: token.attrGet("selected")
            };
        }
    },
    intake_sheet_confirmation_not_accepted: {
        block: OtherMarkdownCustomBlockNameEnum.IntakeSheetConfirmationNotAccepted,
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class")
            };
        }
    },
    intake_sheet_confirmation_accepted: {
        block: OtherMarkdownCustomBlockNameEnum.IntakeSheetConfirmationAccepted,
        getAttrs: (token: Token) => {
            return {
                [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedEmail}`]: token.attrGet(`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedEmail}`),
                [`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedDate}`]: token.attrGet(`${CustomBlockSpecialAttrsEnum.IntakeSheetConfirmationAcceptedDate}`),
                class: token.attrGet("class")
            };
        }
    },
    button: {
        block: OtherMarkdownCustomBlockNameEnum.Button,
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class")
            };
        }
    },
    title: {
        block: OtherMarkdownCustomBlockNameEnum.Title,
        getAttrs: (token: Token) => {
            return {
                class: token.attrGet("class")
            };
        }
    },
    em: { mark: "em" },
    strong: { mark: "strong" },
    subScript: { mark: "subscript" },
    superScript: { mark: "superscript" },
    mark: { mark: "marked" },
    inlineReference: { 
        mark: CustomInlineNameEnum.InlineReference, 
        getAttrs: (token: Token) => {
            return {
                id: crypto.randomUUID(),
                [`${CustomInlineIdAttributeEnum.InlineReference}`]: token.attrGet(`${CustomInlineIdAttributeEnum.InlineReference}`),
                [`${CustomDOMAttributes.DataInlineReferenceType}`]: token.attrGet(`${CustomDOMAttributes.DataInlineReferenceType}`),
                selected: token.attrGet("selected"),
                class: token.attrGet("class")
            };
        }
    },
    inlineStars: {
        mark: CustomInlineNameEnum.InlineStars,
        getAttrs: (token: Token) => {
            return {
                class : token.attrGet("class"),
                min: "0",
                max: "5",
                [`${CustomDOMAttributes.StarsRating}`]: token.attrGet(`${CustomDOMAttributes.StarsRating}`),
                [`${CustomDOMAttributes.StarsSourceId}`]: token.attrGet(`${CustomDOMAttributes.StarsSourceId}`),
                [`${CustomDOMAttributes.StarsTargetId}`]: token.attrGet(`${CustomDOMAttributes.StarsTargetId}`),
                [`${CustomDOMAttributes.StarsNumberOfRaters}`]: token.attrGet(`${CustomDOMAttributes.StarsNumberOfRaters}`),	
                [`${CustomDOMAttributes.StarsIsRatingNeeded}`]: token.attrGet(`${CustomDOMAttributes.StarsIsRatingNeeded}`)
            };
        }
    }
});

export function markApplies(doc: any, ranges: any, type: any) {
    for (let i = 0; i < ranges.length; i++) {
        const { $from, $to } = ranges[i];
        let can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
        doc.nodesBetween($from.pos, $to.pos, (node: any) => {
            if (can) return false;
            can = node.inlineContent && node.type.allowsMarkType(type);
        });
        if (can) return true;
    }
    return false;
}

export function stopOnTypes(markTypes: any) {
    return function(state: any, dispatch: any) {
        const selectionParent = state.selection.$from.parent;
        const fromPos = state.selection.$from.parentOffset;
        const toPos = state.selection.$to.parentOffset;
        const checkRange = fromPos !== toPos;

        let hasOneType = false;

        for (let i = 0; i < markTypes.length; i++) {
            if (checkRange) {
                hasOneType = hasOneType || (
                    selectionParent.rangeHasMark(fromPos, fromPos + 1, markTypes[i]) &&
                    selectionParent.rangeHasMark(toPos - 1, toPos, markTypes[i])
                );
            }

            if (state.storedMarks !== null && state.storedMarks !== undefined) {
                for (let j = 0; j < state.storedMarks.length; j++) {
                    const storedMark = state.storedMarks[j];
                    hasOneType = hasOneType || storedMark.type.name === markTypes[i].name;
                }
            }
        }


        return hasOneType;
    };
}

export function goOnOnTypes(markTypes: any) {
    return function(state: any, dispatch: any) {
        const selectionParent = state.selection.$from.parent;
        const fromPos = state.selection.$from.parentOffset - 1;
        const toPos = state.selection.$to.parentOffset;

        let hasOneType = false;

        for (let i = 0; i < markTypes.length; i++) {
            hasOneType = hasOneType || (
                selectionParent.rangeHasMark(fromPos, fromPos + 1, markTypes[i]) &&
                selectionParent.rangeHasMark(toPos - 1, toPos, markTypes[i])
            );
        }

        return !hasOneType;
    };
}

export function removeMarkSelection(markType: any) {
    return function(state: any, dispatch: any) {
        const { empty, $cursor, ranges } = state.selection;
        if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) return false;
        if (dispatch) {
            if ($cursor) {
                if (markType.isInSet(state.storedMarks || $cursor.marks())) {
                    dispatch(state.tr.removeStoredMark(markType));
                }
            }
        }
        return false;
    };
}

export function findestEscape(str: string, startOfLine: boolean) {
    str = str.replace(RegexConstants.PROSEMIRROR_FIND_ESCAPED_SQUARE_BRACKET, RegexConstants.PROSEMIRROR_ESCAPED_REPLACE);
    str = str.replace(RegexConstants.PROSEMIRROR_FIND_EQUALS, RegexConstants.PROSEMIRROR_ESCAPED_REPLACE);
    if (startOfLine) { str = str.replace(RegexConstants.PROSEMIRROR_FIND_ANCHOR_REFERENCES, RegexConstants.PROSEMIRROR_ESCAPED_REPLACE).replace(RegexConstants.PROSEMIRROR_FIND_DIGITS, "$1\\."); }
    return str;
}
