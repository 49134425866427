// node_modules
import { FC, useContext } from "react";
import { faEye, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
// Components
import { ToggleButton } from "Components";
// Contexts
import { CollaborationContext } from "Providers";
// Helpers
import { LogHelperSingleton } from "Helpers";
// Styles
import styles from "./objectTopbar.module.scss";
// Enums
import { LogFeatureNameEnum } from "Enums";

export const ObjectModeToggle: FC = () => {
    // Contexts
    const { isEditModeOn, tryClaimingEditModeOnObjectAsync, turnEditModeOff } = useContext(CollaborationContext);

    const onClickToggleButton = (leftToggleActive: boolean) => {
        if (leftToggleActive && isEditModeOn) {
            turnEditModeOff();
            LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-TurnEditModeOff-Manually`);
        } else if (!leftToggleActive && !isEditModeOn) {
            tryClaimingEditModeOnObjectAsync();
            LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-TurnEditModeOn-Manually`);
        }
    };

    // Render
    return (
        <ToggleButton
            buttonWidth="164px"
            toggleTextLeft="View"
            toggleTextRight="Edit"
            leftToggleActive={!isEditModeOn}
            toggleIconLeft={faEye}
            toggleIconRight={faPenToSquare}
            extraClassName={styles.editViewToggleButton}
            onClickToggleButton={onClickToggleButton}
        />
    );
};
