// React
import { FC, useMemo, useState } from "react";
// Components
import { Tabs } from "Components/Shared";
import { DeletedEntitiesArchive } from "./DeletedEntitiesArchive";
import { DeletedStudiesArchive } from "./DeletedStudiesArchive";
// Constants
import { FeatureToggleConstants } from "Constants";
// Styles
import styles from "./deletedArchive.module.scss";
// Types
import { TTab } from "Types";

const enum ArchiveTabsEnum {
    Entities = "Entities",
    Studies = "Studies"
}

export const DeletedArchive: FC = () => {
    // Memo needed for the tabs
    const possibleSettingsTabs = useMemo((): TTab[] => {
        // init settings tabs
        const settingsTabs: TTab[] = [
            { name: ArchiveTabsEnum.Entities }, 
            { name: ArchiveTabsEnum.Studies }
        ];

        // return settings tabs
        return settingsTabs;
    }, []);

    // State
    const [activeTab, setActiveTab] = useState<string>(ArchiveTabsEnum.Entities);

    if(!FeatureToggleConstants.DeletedArchive) return null;

    return (
        <div className={styles.deletedArchiveContainer}>
            <Tabs tabs={possibleSettingsTabs} onSelectedTabChange={setActiveTab} extraClassNames={{ container: styles.tabsContainer, tab: styles.tab}} />
            <div className={styles.deletedArchiveListContainer}>
                { activeTab === ArchiveTabsEnum.Entities && <DeletedEntitiesArchive /> }
                { activeTab === ArchiveTabsEnum.Studies && <DeletedStudiesArchive /> }
            </div>
        </div>
    );
};