export enum CustomDOMTag {
    ReferenceLink = "reference-link",
    HighlightReference = "highlight-reference",
    ImageReferenceFigcaptionTextContainer = "image-reference-figcaption-text-container",
    ImageReferenceFigcaptionText = "image-reference-figcaption-text",
    FileReference = "file-reference",
    FileReferenceLink = "file-reference-link",
    FileReferenceLinkTitle = "file-reference-link-title",
    FileReferenceLinkExtension = "file-reference-link-extension",
    EntityReference = "entity-reference",
    StudyReference = "study-reference",
    IntakeSheetConfirmation = "intake-sheet-confirmation",
    Title = "title",
    IntakeSheetConfirmationAccepted = "intake-sheet-confirmation-accepted",
    IntakeSheetConfirmationNotAccepted = "intake-sheet-confirmation-not-accepted",
    Button = "button",
    InlineReference = "inline-reference",
    SubScript = "sub",
    SuperScript = "sup",
    TdToMerge = "div",
    TableContainer = "div",
    Meter = "meter",
    MeterContainer = "div"
}
