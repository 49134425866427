import { FC, useCallback, useContext, useState } from "react";
// Components
import { FindestTextBox, Modal } from "Components";
// Controllers
import { SharedToControllerSingleton } from "Controllers";
// Providers
import { AuthContext } from "Providers";
// Helpers
import { AuthenticationHelperSingleton, AxiosHelperSingleton } from "Helpers";
// Types
import { TJsonWebTokenDTO, TLoginResponseDTO } from "Types";
// Styles
import styles from "./sharedToCodePage.module.scss";

export const SharedToCodePage: FC = () => {
    // Contexts
    const { auth, verifyAuth, getNewSharingCode } = useContext(AuthContext);

    // State
    const [showInvalidCodeMessage, setShowInvalidCodeMessage] = useState<boolean>(false);

    // Logic
    const onChangeCode = useCallback(async (text: string): Promise<void> => {
        // if showInvalidCodeMessage is true (invalid code message was shown)
        if (showInvalidCodeMessage) {
            // then hide invalid code message
            setShowInvalidCodeMessage(false);
        }

        // if text length is 6
        if (text.trim().length === 6) {
            // login using six digit code validation
            const loginResponse: TLoginResponseDTO | undefined = await SharedToControllerSingleton
                .valideSixDigitCode(auth.userEmail, text.trim());

            // if login response is not set
            if (!loginResponse) {
                // then show invalid code message
                setShowInvalidCodeMessage(true);
                // and stop execution
                return;
            }
            
            // decode access token
            const jwt: TJsonWebTokenDTO | undefined = AuthenticationHelperSingleton
                .decodeAccessToken(loginResponse.accessToken);

            // init isSuccess to true
            let isSuccess = true;

            // safety-checks
            if (!jwt || !AuthenticationHelperSingleton.isJWTValid(jwt)) {
                // set isSuccess to false
                isSuccess = false;
            }
            
            // if isSuccess true and jwt is set
            if(isSuccess && jwt) {
                // set Authorization header
                AxiosHelperSingleton.setCommonHeaderConfigDefaults("Authorization", `Bearer ${loginResponse.accessToken}`);

                // then check auth
                verifyAuth(false, auth.sharedToSettings);
            } else {
                // then show invalid code message
                setShowInvalidCodeMessage(true);
                // and stop execution
                return;
            }
        }
    }, [auth.sharedToSettings, auth.userEmail, verifyAuth, showInvalidCodeMessage]);

    // Render
    return (
        <div className={styles.shareCodePage}>
            <Modal header="Enter confirmation code" isOpen={true} extraClassNames={{ container: styles.shareCodeModal, header: styles.header }}>
                <div className={styles.body}>
                    <p className={styles.bodyText}>Enter the 6-digit code we sent to your email address</p>
                </div>
                <div className={styles.textBoxContainer}>
                    <FindestTextBox type="number" onChange={onChangeCode} />
                    <p className={`${styles.invalidCode} ${showInvalidCodeMessage ? styles.visible : ""}`}>Invalid code</p>
                </div>
                <div className={styles.footerText}>
                    <p>Didn&apos;t receive your code?</p><p onClick={() => { getNewSharingCode(); } } className={styles.link}>Get a new one.</p>
                </div>
            </Modal>
        </div>
    );
};