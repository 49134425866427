// node_modules
import { FC, MouseEvent, ReactNode, useCallback } from "react";

// Component props type
type TStopOnClickEventPropagationProps = {
    children?: ReactNode,
    onClick?: () => void,
    extraClassNames?: { container?: string }
};

// Component
export const StopOnClickEventPropagation: FC<TStopOnClickEventPropagationProps> = ({ children, onClick: onClickProp, extraClassNames = {} }: TStopOnClickEventPropagationProps) => {
    // Logic
    const onClick = useCallback((mouseEvent: MouseEvent<HTMLDivElement>) => {
        // stop propagation on all levels
        mouseEvent.nativeEvent.stopImmediatePropagation();
        mouseEvent.nativeEvent.stopPropagation();
        mouseEvent.stopPropagation();

        // if onClickProp is defined
        if (onClickProp) {
            // call it
            onClickProp();
        }
    }, [onClickProp]);

    // Render
    return (
        <div className={extraClassNames.container ?? ""} onClick={onClick}>
            {children}
        </div>
    );
};
