import { FC, useCallback, useContext } from "react";
// Components
import { AddScoutingServiceItemModal } from "Components";
// Enums
import { ObjectTypeEnum, ScoutingServiceTableTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Controllers
import { MaturityRadarControllerSingleton } from "Controllers";
// Types
import { TAddMaturityRadarDTO, TInitialScoutingServiceTableProps, TLinkGraphDTO, TMaturityRadarAssessmentDTO, TMaturityRadarDTO, TScoutingServiceTableObject, TUpdateAssessmentScoreDTO } from "Types";
// Contexts
import { LinksContext } from "Providers";
// Constants
import { MaturityRadarConstants } from "Constants";
// Styles
import styles from "./maturityRadarModal.module.scss";

// Component props type
type TMaturityRadarModalProps = {
    objectIdEdited?: string,
    objectTypeEdited?: ObjectTypeEnum,
    isEditing?: boolean,
    isOpen: boolean,
    initialMaturityRadarProps?: TInitialScoutingServiceTableProps,
    setIsOpen: (isOpen: boolean) => void,
    onDeleteMaturityRadarClickAsync?: (id: string, forObjectId: string, forObjectType: ObjectTypeEnum) => Promise<boolean>,
    refreshMaturityRadarAsync: (forObjectId: string, forObjectType: ObjectTypeEnum, currentLinkGraphForObjectEdited: TLinkGraphDTO) => Promise<void>,
    maturityRadar?: TMaturityRadarDTO
};

export const MaturityRadarModal: FC<TMaturityRadarModalProps> = ({ objectIdEdited, objectTypeEdited, isEditing, isOpen, setIsOpen,
        initialMaturityRadarProps, onDeleteMaturityRadarClickAsync, refreshMaturityRadarAsync, maturityRadar }: TMaturityRadarModalProps) => {
    // Contexts
    const { linkGraphForObjectEdited } = useContext(LinksContext);

    // Logic
    const buildNewMaturityRadarAssessments = useCallback((tableObjectData: (TScoutingServiceTableObject | null)[][],
        maturityLevelPerEntityId?: Map<string, TUpdateAssessmentScoreDTO>, currentMaturityRadar?: TMaturityRadarDTO): TMaturityRadarAssessmentDTO[] => {
        // init maturity radar assessments
        const maturityRadarAssessments: TMaturityRadarAssessmentDTO[] = [];

        // go through each row of table object data
        for (const tableObjectDataRow of tableObjectData) {
            // safety-checks
            if (!tableObjectDataRow || tableObjectDataRow.length <= 0) {
                // stop execution, continue
                continue;
            }

            // get row first column object
            const rowFirstColumnObject: TScoutingServiceTableObject | null = tableObjectDataRow[0];

            // safety-checks
            if (!rowFirstColumnObject || !rowFirstColumnObject.objectId || !rowFirstColumnObject.objectType || !rowFirstColumnObject.isChecked || rowFirstColumnObject.objectType === ObjectTypeEnum.Study) {
                // stop execution, continue
                continue;
            }

            // init maturity levels
            let lowScore: number = MaturityRadarConstants.DEFAULT_MIN_MATURITY_LEVEL;
            let highScore: number = MaturityRadarConstants.DEFAULT_MAX_MATURITY_LEVEL;

            // if maturity level per entity id is defined and has the row first column object id
            if (maturityLevelPerEntityId && maturityLevelPerEntityId.has(rowFirstColumnObject.objectId)) {
                // get maturity level
                const maturityLevel: TUpdateAssessmentScoreDTO | undefined = maturityLevelPerEntityId.get(rowFirstColumnObject.objectId);

                // safety-checks
                if (!maturityLevel) {
                    // stop execution, continue
                    continue;
                }

                // set low and high score
                lowScore = maturityLevel.lowScore;
                highScore = maturityLevel.highScore;
            }

            // init new maturity radar assessment id
            let newMaturityRadarAssessmentId = "";
            // if current maturity radar is defined and has assessments
            if (currentMaturityRadar && currentMaturityRadar.assessments && currentMaturityRadar.assessments.length > 0) {
                // get maturity radar assessment related to row first column object
                const maturityRadarAssessment: TMaturityRadarAssessmentDTO | undefined = currentMaturityRadar
                    .assessments
                    .find((assessment: TMaturityRadarAssessmentDTO) => assessment.targetId === rowFirstColumnObject.objectId &&
                        assessment.targetType === rowFirstColumnObject.objectType);

                // if maturity radar assessment is defined
                if (maturityRadarAssessment) {
                    // set new maturity radar assessment id
                    newMaturityRadarAssessmentId = maturityRadarAssessment.id;
                }
            }

            // create new maturity radar assessment
            const newMaturityRadarAssessment: TMaturityRadarAssessmentDTO = {
                id: newMaturityRadarAssessmentId,
                targetId: rowFirstColumnObject.objectId,
                targetType: rowFirstColumnObject.objectType,
                targetTitle: "",
                lowScore,
                highScore
            };

            // add new maturity radar assessment to maturity radar assessments
            maturityRadarAssessments.push(newMaturityRadarAssessment);
        }

        // return maturity radar assessments
        return maturityRadarAssessments;
    }, []);

    const handleInsertScoutingServiceItemAsync = useCallback(async (modalTitle: string, selectedLayer: number, isNumbered: boolean, _: boolean,
        tableObjectData: (TScoutingServiceTableObject | null)[][], __: string[], callback: () => void, maturityLevelPerEntityId?: Map<string, TUpdateAssessmentScoreDTO>,
        description?: string): Promise<void> => {
        // safety-checks
        if (!objectIdEdited || !objectTypeEdited || !linkGraphForObjectEdited) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not add maturity radar.");
            // stop execution, return
            return;
        }

        // get maturity radar assessments
        const maturityRadarAssessments: TMaturityRadarAssessmentDTO[] = buildNewMaturityRadarAssessments(tableObjectData, maturityLevelPerEntityId);

        // safety-checks
        if (!maturityRadarAssessments || maturityRadarAssessments.length === 0) {
            // show error toast
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Maturity radar must have at least one entity.");
            // stop execution, return
            return;
        }

        // init add maturity radar
        const addMaturityRadar: TAddMaturityRadarDTO = {
            sourceId: objectIdEdited,
            sourceType: objectTypeEdited,
            sourceTitle: "",
            title: modalTitle,
            description: description || "",
            isNumbered,
            layerNumber: selectedLayer,
            maturityRadarAssessments
        };

        // create maturity radar
        const createdMaturityRadar: TMaturityRadarDTO | undefined = await MaturityRadarControllerSingleton
            .createAsync(addMaturityRadar);

        // safety-checks
        if (!createdMaturityRadar) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not add maturity radar.");
            // stop execution, return
            return;
        }

        // call callback
        callback();

        // refresh maturity radar
        await refreshMaturityRadarAsync(objectIdEdited, objectTypeEdited, linkGraphForObjectEdited);
    }, [buildNewMaturityRadarAssessments, linkGraphForObjectEdited, objectIdEdited, objectTypeEdited, refreshMaturityRadarAsync]);

    const handleUpdateScoutingServiceItemAsync = useCallback(async (itemId: string, modalTitle: string, selectedLayer: number, isNumbered: boolean, _: boolean,
            tableObjectData: (TScoutingServiceTableObject | null)[][], __: string[], callback: () => void, maturityLevelPerEntityId?: Map<string, TUpdateAssessmentScoreDTO>,
            description?: string): Promise<void> => {
        // safety-checks
        if (!objectIdEdited || !objectTypeEdited || !linkGraphForObjectEdited) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update maturity radar.");
            // stop execution, return
            return;
        }

        // get assessments
        const assessments: TMaturityRadarAssessmentDTO[] = buildNewMaturityRadarAssessments(tableObjectData, maturityLevelPerEntityId, maturityRadar);

        // safety-checks
        if (!assessments || assessments.length === 0) {
            // show error toast
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Maturity radar must have at least one entity.");
            // stop execution, return
            return;
        }

        // init new maturity radar
        const newMaturityRadar: TMaturityRadarDTO = {
            id: itemId,
            sourceId: objectIdEdited,
            sourceType: objectTypeEdited,
            sourceTitle: "",
            title: modalTitle,
            description: description || "",
            isNumbered,
            createdAt: initialMaturityRadarProps ? initialMaturityRadarProps.createdAt : new Date(),
            layerNumber: selectedLayer,
            assessments
        };

        // update maturity radar
        const updatedMaturityRadar: TMaturityRadarDTO | undefined = await MaturityRadarControllerSingleton
            .updateAsync(newMaturityRadar);

        // safety-checks
        if (!updatedMaturityRadar) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update maturity radar.");
            // stop execution, return
            return;
        }

        // call callback
        callback();

        // refresh maturity radar
        await refreshMaturityRadarAsync(objectIdEdited, objectTypeEdited, linkGraphForObjectEdited);
    }, [buildNewMaturityRadarAssessments, initialMaturityRadarProps, linkGraphForObjectEdited, maturityRadar, objectIdEdited, objectTypeEdited, refreshMaturityRadarAsync]);

    const onDeleteScoutingServiceItemClickAsyncHandler = useCallback(async (id: string, callback: () => void): Promise<void> => {
        // safety-checks
        if (!onDeleteMaturityRadarClickAsync || !objectIdEdited || !objectTypeEdited) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not delete maturity radar.");
            // stop execution, return
            return;
        }

        // call on delete maturity radar click async
        const isSuccess: boolean = await onDeleteMaturityRadarClickAsync(id, objectIdEdited, objectTypeEdited);

        // if not success
        if (!isSuccess) {
            // stop execution, return
            return;
        }

        // call callback
        callback();
    }, [objectIdEdited, objectTypeEdited, onDeleteMaturityRadarClickAsync]);

    // Render
    return (
        <AddScoutingServiceItemModal
            type={ScoutingServiceTableTypeEnum.MaturityRadar}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            showLayerSelectionDropdown
            scoutingServiceItemTitle="Maturity radar"
            insertButtonTitle="Insert radar"
            updateButtonTitle="Update radar"
            optionsTitle="Radar options"
            handleInsertScoutingServiceItem={handleInsertScoutingServiceItemAsync}
            handleUpdateScoutingServiceItem={handleUpdateScoutingServiceItemAsync}
            onDeleteScoutingServiceItemClickAsync={onDeleteMaturityRadarClickAsync ? onDeleteScoutingServiceItemClickAsyncHandler : undefined}
            isEditing={isEditing} 
            initialTableProps={initialMaturityRadarProps}
            extraClassNames={{ cellActionsContainer: styles.cellActionsContainer }}
        />
    );
};