/* eslint-disable @typescript-eslint/no-unused-vars */
// node_modules
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useMemo, useState } from "react";
// Types
import { TLineChat } from "Types";
// Styles
import styles from "./textLineChat.module.scss";
// Components
import { FindestButton, TextArea } from "Components";

// Component props type
type TextLineChatProps = {
    lineChat: TLineChat,
    isGeneratingText?: boolean,
    isSubmitDisabled?: boolean,
    onSubmit?: (text: string) => void
}

// Component
export const TextLineChat: FC<TextLineChatProps> = ({ lineChat, onSubmit, isGeneratingText, isSubmitDisabled }) => {
    // State
    const [inputValue, setInputValue] = useState<string>(lineChat.inputValue ?? "");

    // Memo
    const memoizedIsSubmitDisabled = useMemo(() => {
        // if disableSubmitWhenInputEmpty is true and input value is empty
        if ((lineChat.disableSubmitWhenInputEmpty && !inputValue) || isGeneratingText || isSubmitDisabled) {
            return true;
        }

        // otherwise, return false
        return false;
    }, [lineChat.disableSubmitWhenInputEmpty, isSubmitDisabled, inputValue, isGeneratingText]);

    // on enter handler
    const onEnterHandler = useCallback((text: string) => {
        // if onSubmit is defined
        if (onSubmit) {
            // submit text
            onSubmit(text);
        }
    }, [onSubmit]);

    // Render
    return (
        <div className={`${styles.lineChat} ${lineChat.hideBorderBottom ? styles.hideBorderBottom : ""}`}>
            <div className={styles.messageOwnerContainer}>
                {lineChat.isAskAI ?
                    <div className={styles.messageBotContainer}>
                        <FontAwesomeIcon icon={faMessageBot} />
                        <span>IGOR</span>
                    </div>
                    :
                    <span>You</span>
                }
            </div>
            <div className={styles.messageContainer}>
                {(lineChat.text) && (<div className={styles.textLineChatText}>{lineChat.text}</div>)}
                {(lineChat.options) && (
                    lineChat.options.map((option) => {
                        return (
                            <FindestButton 
                                key={option.text}
                                title={option.text} 
                                onClick={option.onClick}
                                extraClassName={styles.optionButton}
                            />
                        );
                    }
                ))}
                <div className={isGeneratingText ? styles.disabledChildren : ""}>{(lineChat.children) && (lineChat.children)}</div>
                {(lineChat.isInputShown && onSubmit) && (<TextArea onEnter={onEnterHandler} isReadonly={isGeneratingText} extraClassName={styles.inputElement} placeholder={lineChat.inputPlaceholder ?? "Enter your text here..."} value={inputValue} onChange={(e) => { setInputValue(e.target.value); }} />)}
                {(lineChat.isSubmitShown && onSubmit) && (<FindestButton buttonType="secondary" extraClassName={styles.submitButton} title="Submit" onClick={() => { onSubmit(inputValue); }} isDisabled={memoizedIsSubmitDisabled} />)}
            </div>
        </div>
    );
};