// React
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
// Node modules
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
// Shared components
import { FindestTextBox, HasAdvanced, Popover, Tabs } from "Components";
// Contexts
import { AuthContext, RecentActivityContext, WindowingContext } from "Providers";
// Styles
import styles from "./globalSearchBar.module.scss";
// Types
import { TIdNameTypeObjectType, TRecentSidebarActivityItemDTO, TSearchbarResultDTO, TTab } from "Types";
// Enums
import { LogFeatureNameEnum, ObjectTypeEnum } from "Enums";
// Constants
import { FeatureToggleConstants, SearchbarConstants } from "Constants";
// Helpers
import { LogHelperSingleton } from "Helpers";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Sub components
import { QueryDetailsProvidedLoader } from "Components/Queries";
import { SearchRecentActivities } from "./SearchRecentActivities";
// Custom hooks
import { useClickOutsideRef } from "Hooks";
// Controllers
import { SearchControllerSingleton } from "Controllers";
// Sub components
import { GroupedSearchResultsList } from "./GroupedSearchResultsList";
// Interfaces
import { IQueryDTO } from "Interfaces";

const enum SearchTabsEnum {
    Top4 = "Top 4",
    Inbox = "Inbox",
    Studies = "Studies",
    Entities = "Entities",
    Documents = "Documents",
    Queries = "Queries"
}

export const GlobalSearchBar: FC = () => {
    // Contexts
    const { auth, isUserExternal, hasAdvanced } = useContext(AuthContext);
    const { mySimpleRecentActivity, refreshMySimpleRecentActivityAsync } = useContext(RecentActivityContext);
    const { addSearchWindow, deleteWindow } = useContext(WindowingContext);

    // Ref
    const lastSearchQueryTextValueRef = useRef<string | undefined>(undefined);
    const displayedSearchQueryTextValueRef = useRef<string>("");
    const isSearchRunningForAutocompleteRef = useRef<boolean>(false);
    const searchElementsContainerRef = useRef<HTMLDivElement>(null);
    const abortControllerRef = useRef<AbortController>(new AbortController());

    // Memo used for State
    const possibleSearchTabs = useMemo((): TTab[] => {
        // init tabs
        const tabs = [
            { name: SearchTabsEnum.Top4 }, 
            { name: SearchTabsEnum.Inbox }, 
            { name: SearchTabsEnum.Studies }, 
            { name: SearchTabsEnum.Entities }, 
            { name: SearchTabsEnum.Documents }
        ];

        // if user has access to queries
        if(hasAdvanced) {
            // add queries tab
            tabs.push({
                name: SearchTabsEnum.Queries
            });
        }

        // return tabs
        return tabs;
    }, [hasAdvanced]);

    // Custom Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
        setReferenceModalProps({
            id: objectId,
            type: objectType,
            isOpen: true,
            doIgnoreIsDeleted: false,
        });
    };

    // State
    const [doShowSearchResults, setDoShowSearchResults] = useState<boolean>(false);
    const [top4SearchResults, setTop4SearchResults] = useState<TSearchbarResultDTO | null>(null);
    const [tabSpecificSearchResults, setTabSpecificSearchResults] = useState<TIdNameTypeObjectType[]>([]);
    const [isOnFocus, setIsOnFocus] = useState<boolean>(false);
    const [searchResultsPopoverElement, setSearchResultsPopoverElement] = useState<HTMLSpanElement | null>(null);
    const [searchQueryTextValue, setSearchQueryTextValue] = useState<string>("");
    const [selectedTabName, setSelectedTabName] = useState<string>(possibleSearchTabs[0].name);
    const [searchRunningCount, setSearchRunningCount] = useState<number>(0);

    // Memo
    const isSearchRunningBasedOnCount = useMemo(() => {
        return searchRunningCount > 0;
    }, [searchRunningCount]);

    const hasNoResults = useMemo(() => {
        if(top4SearchResults?.entities && top4SearchResults.entities?.length > 0) return false;
        if(top4SearchResults?.inboxDocuments && top4SearchResults.inboxDocuments?.length > 0) return false;
        if(top4SearchResults?.documentDocuments && top4SearchResults.documentDocuments?.length > 0) return false;
        if(top4SearchResults?.studies && top4SearchResults.studies?.length > 0) return false;
        return true;
    }, [top4SearchResults]);

    const doesntCurrentTabHaveSearchResults = useMemo(() => {
        if(isSearchRunningBasedOnCount) return false;
        if(selectedTabName === SearchTabsEnum.Top4) return false;
        return tabSpecificSearchResults.length === 0;
    }, [isSearchRunningBasedOnCount, selectedTabName, tabSpecificSearchResults.length]);

    const doShowRecentActivity = useMemo(() => {
        // show recent activity if there are no search results and search query text value is empty
        return hasNoResults && !searchQueryTextValue;
    }, [hasNoResults, searchQueryTextValue]);

    const mySimpleRecentEntitiesActivity = useMemo(() => {
        // get my simple recent activity related to entities
        return mySimpleRecentActivity.filter((mySimpleRecentActivityItem: TRecentSidebarActivityItemDTO) => { return mySimpleRecentActivityItem.objectType === ObjectTypeEnum.Entity; });
    }, [mySimpleRecentActivity]);

    const mySimpleRecentStudiesActivity = useMemo(() => {
        // get my simple recent activity related to studies
        return mySimpleRecentActivity.filter((mySimpleRecentActivityItem: TRecentSidebarActivityItemDTO) => { return mySimpleRecentActivityItem.objectType === ObjectTypeEnum.Study; });
    }, [mySimpleRecentActivity]);

    const mySimpleRecentQueriesActivity = useMemo(() => {
        // get my simple recent activity related to queries
        return mySimpleRecentActivity.filter((mySimpleRecentActivityItem: TRecentSidebarActivityItemDTO) => { return mySimpleRecentActivityItem.objectType === ObjectTypeEnum.Query; });
    }, [mySimpleRecentActivity]);

    const tabResultsName = useMemo(() => {
        if(selectedTabName === SearchTabsEnum.Studies) return "study";
        if(selectedTabName === SearchTabsEnum.Entities) return "entity";
        if(selectedTabName === SearchTabsEnum.Inbox) return "inbox";
        if(selectedTabName === SearchTabsEnum.Documents) return "document";
        if(selectedTabName === SearchTabsEnum.Queries) return "query";
        return "";
    }, [selectedTabName]);

    const tabCounts = useMemo(() => {
        // init the current tab counts
        const currentTabCounts: { [key: string]: string } = {};

        // if search bar counts are not enabled, return the empty object
        if(!FeatureToggleConstants.SearchBarCount) {
            return currentTabCounts;
        }

        // set the counts for each tab based on the top 4 search results
        currentTabCounts[SearchTabsEnum.Entities] = `(${top4SearchResults?.entityCount ? top4SearchResults.entityCount.toString() : "0"})`;
        currentTabCounts[SearchTabsEnum.Studies] = `(${top4SearchResults?.studyCount ? top4SearchResults.studyCount.toString() : "0"})`;
        currentTabCounts[SearchTabsEnum.Documents] = `(${top4SearchResults?.documentCount ? top4SearchResults.documentCount.toString() : "0"})`;
        currentTabCounts[SearchTabsEnum.Queries] = `(${top4SearchResults?.queryCount ? top4SearchResults.queryCount.toString() : "0"})`;

        // return the current tab counts
        return currentTabCounts;
    }, [top4SearchResults]);

    const disabledTabs = useMemo((): TTab[] => {
        // init the current disabled tabs
        const currentDisabledTabs: TTab[] = [];

        // set the disabled tabs based on the top 4 search results
        if (top4SearchResults && !top4SearchResults.studyCount) currentDisabledTabs.push({name: SearchTabsEnum.Studies});
        if (top4SearchResults && !top4SearchResults.entityCount) currentDisabledTabs.push({name: SearchTabsEnum.Entities});
        if (top4SearchResults && !top4SearchResults.documentCount) currentDisabledTabs.push({name: SearchTabsEnum.Documents});
        if (top4SearchResults && !top4SearchResults.queryCount) currentDisabledTabs.push({name: SearchTabsEnum.Queries});
        if (top4SearchResults && (!top4SearchResults.inboxDocuments || top4SearchResults.inboxDocuments.length === 0)) {
            currentDisabledTabs.push({name: SearchTabsEnum.Inbox});
        } 

        // if there are no results also disable the top 4 tab
        if (hasNoResults) {
            currentDisabledTabs.push({name: SearchTabsEnum.Top4});
        }

        // return the current disabled tabs
        return currentDisabledTabs;
    }, [hasNoResults, top4SearchResults]);

    const currentTabSearchResults = useMemo(() => {
        // if top 4 search results are not set, return null
        if(!top4SearchResults) return null;

        // return the search results based on the selected tab
        if(selectedTabName === SearchTabsEnum.Inbox) return tabSpecificSearchResults;
        if(selectedTabName === SearchTabsEnum.Studies) return tabSpecificSearchResults;
        if(selectedTabName === SearchTabsEnum.Entities) return tabSpecificSearchResults;
        if(selectedTabName === SearchTabsEnum.Documents) return tabSpecificSearchResults;
        if(selectedTabName === SearchTabsEnum.Queries) return tabSpecificSearchResults;

        // otherwise return null
        return null;
    }, [top4SearchResults, selectedTabName, tabSpecificSearchResults]);

    // Logic
    // logic to reset the search bar
    const resetSearchBar = (doHideSearchResults: boolean, clearTextbox: boolean) => {
        // set do show search results based on do hide search results parameter
        setDoShowSearchResults(!doHideSearchResults);

        // empty search query text value
        setSearchQueryTextValue("");

        // set selected tab name to the first possible search tab
        setSelectedTabName(possibleSearchTabs[0].name);

        // set is search running for autocomplete to false
        isSearchRunningForAutocompleteRef.current = false;

        // set search running count to 0
        setSearchRunningCount(0);

        // set last search query text value ref to undefined
        lastSearchQueryTextValueRef.current = undefined;

        // if clear textbox is true, empty the displayed search query text value
        if(clearTextbox) {
            displayedSearchQueryTextValueRef.current = "";
        }

        // if top 4 search results are set, empty them
        if(top4SearchResults) {
            setTop4SearchResults(null);
        }

        // if is on focus is true, set it to false
        if(isOnFocus) {
            setIsOnFocus(false);
        }
    };

    const moreActionsDropdownOnCloseCallback = (): void => {
        // reset search bar
        resetSearchBar(true, false);
    };

    // Other hooks
    useClickOutsideRef(searchElementsContainerRef, moreActionsDropdownOnCloseCallback, [], SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER);
    const navigate = useNavigate();
    // get the search query from the url 
    // it can be set if user clicks on "View all Universe results" 
    // while using the plugin on a google search results page
    const [searchParams] = useSearchParams();
    const searchQueryInUrl: string | null = searchParams.get("searchQuery");

    // Logic
    const runSearchAsync = async (searchQueryValue: string, doSearchThroughInbox: boolean, 
            doSearchThroughDocuments: boolean, doSearchThroughEntities: boolean, doSearchThroughStudies: boolean,
            doSearchThroughQueries: boolean): Promise<void> => {
        // safety-checks
        // if string is empty don't reset the searchbar
        if (searchQueryValue === undefined || searchQueryValue === null) { 
            resetSearchBar(false, false);
            return;
        }

        // log
        LogHelperSingleton.log("RunGlobalSearch");

        // init new tab specific search results
        let newTabSpecificSearchResults = undefined;

        // init new abort controller
        abortControllerRef.current = new AbortController();

        // always need this for the amount of search results
        const newTop4SearchResultsTask = SearchControllerSingleton.searchBarAsync(searchQueryValue, 
            doSearchThroughInbox, doSearchThroughDocuments, doSearchThroughEntities,
            doSearchThroughStudies, doSearchThroughQueries, false);

        if(selectedTabName === SearchTabsEnum.Inbox) {
            newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(searchQueryValue,
                [ObjectTypeEnum.MagPatent, ObjectTypeEnum.ScienceArticle, ObjectTypeEnum.UsPatent, ObjectTypeEnum.Weblink],
                undefined, true, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, abortControllerRef.current);
        } else if(selectedTabName === SearchTabsEnum.Studies) {
            newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(searchQueryValue,
                [ObjectTypeEnum.Study], undefined, undefined, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, 
                abortControllerRef.current);
        } else if(selectedTabName === SearchTabsEnum.Entities) {
            newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(searchQueryValue,
                [ObjectTypeEnum.Entity], undefined, undefined, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, 
                abortControllerRef.current);
        } else if(selectedTabName === SearchTabsEnum.Documents) {
            newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(searchQueryValue,
                [ObjectTypeEnum.MagPatent, ObjectTypeEnum.ScienceArticle, ObjectTypeEnum.UsPatent, ObjectTypeEnum.Weblink],
                undefined, undefined, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, abortControllerRef.current);
        } else if(selectedTabName === SearchTabsEnum.Queries && hasAdvanced) {
            newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(searchQueryValue,
                [ObjectTypeEnum.Query], undefined, undefined, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, 
                abortControllerRef.current);
        }

        // if new tab specific search results are set, set them
        if(newTabSpecificSearchResults) {
            setTabSpecificSearchResults(newTabSpecificSearchResults);
        }

        // await the async task
        const newTop4SearchResults = await newTop4SearchResultsTask;

        // set search results state variable
        setTop4SearchResults(newTop4SearchResults);

        // set is search running for autocomplete to false
        isSearchRunningForAutocompleteRef.current = false;

        // decrease search running count
        setSearchRunningCount(prevValue => prevValue - 1);

        // if a last search query text value is set and different from the last one executed
        if (lastSearchQueryTextValueRef.current !== undefined && lastSearchQueryTextValueRef.current !== searchQueryValue) {
            // then run the search again with the last search query text value
            await onTextTypedAsync(lastSearchQueryTextValueRef.current);
        }
    };

    const onTextTypedAsync = async (text: string): Promise<void> => {
        // safety-checks 
        // if string is empty don't reset the searchbar
        if (text === undefined || text === null) {
            resetSearchBar(false, false);
            return;
        }

        // set the search query text value to text
        setSearchQueryTextValue(text);

        // set the displayed search query text value to text
        displayedSearchQueryTextValueRef.current = text;
        
        // if a search is already running, do not run another one
        // and keep the last search query text value
        if (isSearchRunningForAutocompleteRef.current) {
            lastSearchQueryTextValueRef.current = text;
            return;
        }

        // set is search running for autocomplete to true
        isSearchRunningForAutocompleteRef.current = true;

        // increase search running count
        setSearchRunningCount(prevValue => prevValue + 1);

        // set the last search query text value ref to undefined
        lastSearchQueryTextValueRef.current = undefined;
        
        // run search
        await runSearchAsync(text, true, true, true, true, hasAdvanced);
    };

    // use effect on the possible search query from the url
    useEffect(() => {
        // if there is a search query in the url set it in the search bar 
        // and auth information have been requested
        // then trigger a search
        if (searchQueryInUrl && !auth.isRequestingAuthInformation) {
            // set displayed search query text value
            displayedSearchQueryTextValueRef.current = searchQueryInUrl;
            // do show the search results dropdown
            setDoShowSearchResults(true);
            // set the search query in the search bar
            lastSearchQueryTextValueRef.current = searchQueryInUrl;
            // remove the search query from the url
            navigate(".", { replace: true });
            // trigger a search
            (async () => {
                // init  new abort controller
                abortControllerRef.current = new AbortController();

                // run search
                const newTop4SearchResults = await SearchControllerSingleton.searchBarAsync(searchQueryInUrl, 
                    false, false, false, false, false, true);
                
                // set top 4 search results state variable
                setTop4SearchResults(newTop4SearchResults);
            })();
        }
    }, [auth.isRequestingAuthInformation, navigate, searchQueryInUrl]);
    
    // use effect on tab change to receive the search results
    useEffect(() => {
        // get the displayed search query text value
        const displayedSearchQueryTextValue = displayedSearchQueryTextValueRef.current;

        // safety-checks
        if(displayedSearchQueryTextValue.length === 0) {
            isSearchRunningForAutocompleteRef.current = false;
            setSearchRunningCount(0);

            // set top 4 search results to null
            setTop4SearchResults(null);

            // abort ongoing search
            abortControllerRef.current.abort();

            // return, do nothing
            return;
        }

        // run search
        (async () => {
            // init  new abort controller
            abortControllerRef.current = new AbortController();

            // cannot use the runSearchAsync function because of callback hell
            let newTop4SearchResults = undefined;
            let newTabSpecificSearchResults = undefined;
            if(selectedTabName === SearchTabsEnum.Top4) {
                newTop4SearchResults = await SearchControllerSingleton.searchBarAsync(displayedSearchQueryTextValue, 
                    true, true, true, true, hasAdvanced, false);
            } else if(selectedTabName === SearchTabsEnum.Inbox) {
                newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(displayedSearchQueryTextValue,
                    [ObjectTypeEnum.MagPatent, ObjectTypeEnum.ScienceArticle, ObjectTypeEnum.UsPatent, ObjectTypeEnum.Weblink],
                    undefined, true, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, abortControllerRef.current);
            } else if(selectedTabName === SearchTabsEnum.Studies) {
                newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(displayedSearchQueryTextValue,
                    [ObjectTypeEnum.Study], undefined, undefined, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, 
                    abortControllerRef.current);
            } else if(selectedTabName === SearchTabsEnum.Entities) {
                newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(displayedSearchQueryTextValue,
                    [ObjectTypeEnum.Entity], undefined, undefined, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, 
                    abortControllerRef.current);
            } else if(selectedTabName === SearchTabsEnum.Documents) {
                newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(displayedSearchQueryTextValue,
                    [ObjectTypeEnum.MagPatent, ObjectTypeEnum.ScienceArticle, ObjectTypeEnum.UsPatent, ObjectTypeEnum.Weblink],
                    undefined, undefined, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, 
                    abortControllerRef.current);
            } else if(selectedTabName === SearchTabsEnum.Queries && hasAdvanced) {
                newTabSpecificSearchResults = await SearchControllerSingleton.searchMultipleObjectsAsync(displayedSearchQueryTextValue,
                    [ObjectTypeEnum.Query], undefined, undefined, undefined, SearchbarConstants.MAXIMUM_TO_RETRIEVE, 
                    abortControllerRef.current);
            }

            // set is search running for autocomplete to false
            isSearchRunningForAutocompleteRef.current = false;

            // decrease search running count
            setSearchRunningCount(prevValue => prevValue - 1);

            // if new top 4 search results are set, set them
            if(newTop4SearchResults) {
                setTop4SearchResults(newTop4SearchResults);
            }

            // if new tab specific search results are set, set them
            if(newTabSpecificSearchResults) {
                setTabSpecificSearchResults(newTabSpecificSearchResults);
            }
        })();

    }, [hasAdvanced, selectedTabName]);

    const onObjectClick = (objectId: string,  objectType: ObjectTypeEnum, objectName: string, actionOrigin: string = LogFeatureNameEnum.GlobalSearchBar) => {

        // init path to object details page
        let pathToObjectDetailsPage = "/library/";

        // init log event name
        let logEventName: "OpenEntity" | "OpenStudy" | "OpenDocument" | undefined = undefined;

        // depending on the object type, set the path to object details page and the log event name
        switch(objectType) {
            case ObjectTypeEnum.Entity:
                pathToObjectDetailsPage += "entities/";
                logEventName = "OpenEntity";
                break;
            case ObjectTypeEnum.Study:
                pathToObjectDetailsPage += "studies/";
                logEventName = "OpenStudy";
                break;
            case ObjectTypeEnum.MagPatent:
            case ObjectTypeEnum.ScienceArticle:
            case ObjectTypeEnum.UsPatent:
            case ObjectTypeEnum.Weblink:
            case ObjectTypeEnum.Document:
                pathToObjectDetailsPage += "documents/";
                logEventName = "OpenDocument";
                break;
            case ObjectTypeEnum.Query:
                break;
            default:
                return;
        }

        // Creating query
        const query = {
            guid : objectId,
            name: objectName,
            
       } as IQueryDTO;

       // if query show Window else adding the link of the study/entity/document to the URL
        if (objectType === ObjectTypeEnum.Query) {
            addSearchWindow(query, 
                <QueryDetailsProvidedLoader 
                    queryId={query.guid}
                    onDelete={() => { deleteWindow(query.guid); }}
                    onDuplicateAsync={async (duplicateQuery: IQueryDTO) => {
                        // delete the window
                        deleteWindow(query.guid);
                        
                        // open the duplicate query
                        onObjectClick(duplicateQuery.guid, objectType, duplicateQuery.name, "DuplicateButton");
                    }} />, actionOrigin
            );
        } else {
            navigate(`${pathToObjectDetailsPage}${objectId}`);
            // log
            if (logEventName) {
                LogHelperSingleton.log(logEventName);
            }
        }
        
        // reset search bar
        resetSearchBar(true, true);
    };

    const onFocusHandler = useCallback(async (currentisUserExternal: boolean) => {
        // if the user is not in read only mode, because of a shared link
        if (!currentisUserExternal) {
            // set do show search results to true
            setDoShowSearchResults(true);

            // set the is on focus to true
            setIsOnFocus(true);

            // refresh the my simple recent activity
            await refreshMySimpleRecentActivityAsync();
        }
    }, [refreshMySimpleRecentActivityAsync]);

    const onTabChange = (tabName: string) => {
        // abort ongoing search 
        // to prevent displaying results from the previous tab on the new tab
        abortControllerRef.current.abort();

        // if tab name is top 4
        if(tabName === SearchTabsEnum.Top4) {
            // set top 4 search results to empty object
            setTop4SearchResults({} as TSearchbarResultDTO);
        }

        // set is search running for autocomplete to true
        isSearchRunningForAutocompleteRef.current = true;

        // increase search running count
        setSearchRunningCount(prevValue => prevValue + 1);

        // set selected tab name
        setSelectedTabName(tabName);

        // set tab specific search results to empty array
        setTabSpecificSearchResults([]);
    };

    return (
        <div className={`${styles.searchBarContainer}`} onFocus={() => { onFocusHandler(isUserExternal); }}>
            <div ref={searchElementsContainerRef}>
                <span ref={setSearchResultsPopoverElement}>
                    <FindestTextBox 
                        extraClassNameInputElement={styles.globalSearchBarInput}
                        placeholder="Search the Universe" 
                        leftIcon={faSearch} 
                        showEmptyInputCrossIcon={doShowSearchResults} 
                        width={560} 
                        onChange={onTextTypedAsync}
                        onEmptyInputButtonClickHandler={() => { resetSearchBar(true, true); }} 
                        value={displayedSearchQueryTextValueRef.current} />
                </span>
                {doShowSearchResults && 
                    <Popover
                        extraClassName={styles.searchBarPopup}
                        referenceEl={searchResultsPopoverElement}
                        placement="bottom-start">
                        <Tabs 
                            tabs={possibleSearchTabs} disabledTabs={top4SearchResults ? disabledTabs : possibleSearchTabs} onSelectedTabChange={onTabChange} extraTabNaming={tabCounts}/>
                        {doShowRecentActivity &&
                            <div className={styles.recentActiveContainer}>
                                {mySimpleRecentStudiesActivity.length > 0 &&
                                    <div className={styles.recentActiveList}>
                                        <SearchRecentActivities 
                                            headerName="studies" keyName="study"
                                            navigationPathPrefix="/library/"
                                            onObjectClick={onObjectClick}
                                            mySimpleRecentActivity={mySimpleRecentStudiesActivity}
                                            resetSearchBar={resetSearchBar}
                                            extraClassname={styles.listItemContent}
                                            openReferenceModal={openReferenceModal}
                                            navigateCallback={() => { resetSearchBar(true, true); }}
                                            moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                            moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                        />
                                    </div>
                                    }
                                {mySimpleRecentEntitiesActivity.length > 0 &&
                                    <div className={styles.recentActiveList}>
                                        <SearchRecentActivities 
                                            headerName="entities" keyName="entity"
                                            navigationPathPrefix="/library/"
                                            onObjectClick={onObjectClick}
                                            mySimpleRecentActivity={mySimpleRecentEntitiesActivity}
                                            resetSearchBar={resetSearchBar}
                                            extraClassname={styles.listItemContent}
                                            openReferenceModal={openReferenceModal}
                                            navigateCallback={() => { resetSearchBar(true, true); }}
                                            moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                            moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                        />
                                    </div>
                                }
                                {mySimpleRecentQueriesActivity.length > 0 &&
                                    <HasAdvanced>
                                        <div className={styles.recentActiveList}>
                                            <SearchRecentActivities 
                                                headerName="queries" keyName="query"
                                                mySimpleRecentActivity={mySimpleRecentQueriesActivity}
                                                resetSearchBar={resetSearchBar}
                                                onObjectClick={onObjectClick}
                                                extraClassname={styles.listItemContent}
                                                openReferenceModal={openReferenceModal}
                                                navigateCallback={() => { resetSearchBar(true, true); }}
                                                moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                                moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                            />
                                        </div>
                                    </HasAdvanced>
                                }
                            </div>
                        }
                        {top4SearchResults && !isSearchRunningBasedOnCount && !hasNoResults &&
                            <div className={styles.searchResultsContainer}>
                                {(selectedTabName === possibleSearchTabs[0].name) ?
                                        <div>
                                            {top4SearchResults.studies && top4SearchResults.studies.length > 0 &&
                                                <GroupedSearchResultsList 
                                                    headerName="Studies"
                                                    keyName="study"
                                                    onObjectClick={onObjectClick}
                                                    searchResults={top4SearchResults?.studies || []}
                                                    extraClassname={styles.listItemContent}
                                                    openReferenceModal={openReferenceModal}
                                                    navigateCallback={() => { resetSearchBar(true, true); }}
                                                    moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                                    moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                                />
                                            }
                                            {top4SearchResults.entities && top4SearchResults.entities.length > 0 &&
                                                <GroupedSearchResultsList 
                                                    headerName="Entities"
                                                    keyName="entity"
                                                    onObjectClick={onObjectClick}
                                                    searchResults={top4SearchResults?.entities || []}
                                                    extraClassname={styles.listItemContent}
                                                    openReferenceModal={openReferenceModal}
                                                    navigateCallback={() => { resetSearchBar(true, true); }}
                                                    moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                                    moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                                />
                                            }
                                            {top4SearchResults.inboxDocuments && top4SearchResults.inboxDocuments.length > 0 &&
                                                <GroupedSearchResultsList 
                                                    headerName="Inbox"
                                                    keyName="inbox-document"
                                                    onObjectClick={onObjectClick}
                                                    searchResults={top4SearchResults?.inboxDocuments || []}
                                                    extraClassname={styles.listItemContent}
                                                    openReferenceModal={openReferenceModal}
                                                    navigateCallback={() => { resetSearchBar(true, true); }}
                                                    moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                                    moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                                />
                                            }
                                            {top4SearchResults.documentDocuments && top4SearchResults.documentDocuments.length > 0 &&
                                                <GroupedSearchResultsList 
                                                    headerName="Documents"
                                                    keyName="document"
                                                    onObjectClick={onObjectClick}
                                                    searchResults={top4SearchResults?.documentDocuments || []}
                                                    extraClassname={styles.listItemContent}
                                                    openReferenceModal={openReferenceModal}
                                                    navigateCallback={() => { resetSearchBar(true, true); }}
                                                    moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                                    moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                                />
                                            }
                                            {top4SearchResults.queries && top4SearchResults.queries.length > 0 &&
                                                <GroupedSearchResultsList
                                                    headerName="Queries"
                                                    keyName="query"
                                                    onObjectClick={onObjectClick}
                                                    searchResults={top4SearchResults?.queries || []}
                                                    extraClassname={styles.listItemContent}
                                                    openReferenceModal={openReferenceModal}
                                                    navigateCallback={() => { resetSearchBar(true, true); }}
                                                    moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                                    moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                                />
                                            }
                                        </div>
                                    :
                                        <div>
                                            {currentTabSearchResults && currentTabSearchResults.length > 0 &&
                                                <GroupedSearchResultsList
                                                    headerName=""
                                                    keyName="tabbed-search-result"
                                                    onObjectClick={onObjectClick}
                                                    searchResults={currentTabSearchResults}
                                                    extraClassname={styles.listItemContent}
                                                    openReferenceModal={openReferenceModal}
                                                    navigateCallback={() => { resetSearchBar(true, true); }}
                                                    moreActionsDropdownPopoverDataIdentifier={SearchbarConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                                    moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                                                />
                                            }
                                        </div>
                                    }
                            </div>
                        }
                        {((hasNoResults || doesntCurrentTabHaveSearchResults) && !isSearchRunningBasedOnCount && !doShowRecentActivity) ?
                                selectedTabName === possibleSearchTabs[0].name ?
                                    <p className={styles.noResults}>No results found</p> 
                                    :
                                    <p className={styles.noResults}>No {tabResultsName} results found</p>
                            : 
                                null
                        }
                        {isSearchRunningBasedOnCount ? 
                                <p className={styles.noResults}>Search is running...</p> 
                            : 
                                null
                        }
                    </Popover>
                }
            </div>
           {referenceModal}
        </div>
    );
};