// node_modules
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FC, useContext, useMemo } from "react";
// Components
import {
  ContextedEditorMenu,
  CreatedByAccount,
  DropdownButton,
  ObjectPinButton,
  RolesChecker,
  ShareButton,
} from "Components";
import { ObjectModeToggle } from "./ObjectModeToggle";
import { ObjectUnlockDropdown } from "./ObjectUnlockDropdown/ObjectUnlockDropdown";
// Contexts
import {
  AuthContext,
  CollaborationContext,
  EditorContext,
  ElementVisibilityContext,
} from "Providers";
// Enums
import { RolesEnum } from "Enums";
// Helpers
import { DateHelperSingleton, UserHelperSingleton } from "Helpers";
// Types
import { TDropdownButtonOption } from "Types";
// Styles
import styles from "./objectTopbar.module.scss";

type TObjectTopbarProps = {
  extraClassNames?: {
    container?: string;
  };
};

export const ObjectTopbar: FC<TObjectTopbarProps> = ({
  extraClassNames = {},
}: TObjectTopbarProps) => {
  // Contexts
  const { auth } = useContext(AuthContext);
  const { isLocked, isEditModeOn, isEditorShown } =
    useContext(CollaborationContext);
  const {
    objectCreatedByUsername,
    objectCreatedOnDate,
    onActionsClickRef,
    onAttachFileClickHandler,
  } = useContext(EditorContext);
  const {
    isPinButtonVisible,
    isShareButtonVisible,
    isEditorEllipsisMenuVisible,
  } = useContext(ElementVisibilityContext);

  // Determine the current options for the dropdown button
  const optionLabels: TDropdownButtonOption[] = useMemo(() => {
    const options: TDropdownButtonOption[] = ["create link"];

    if (UserHelperSingleton.hasAccessToAllLocked(auth)) {
      options.push(isLocked ? "unlock page" : "lock page");
    }

    if (!UserHelperSingleton.isSharingRestrictedToObject(auth)) {
      options.push(...["open in tree view", "open in list view"]);
    }

    if (!isLocked && isEditModeOn) {
      options.push("delete");
    }
    return options;
  }, [auth, isLocked, isEditModeOn]);

  // Render
  return (
    <div
      className={`${styles.objectTopbarContainer} ${
        extraClassNames.container ?? ""
      }`}
    >
      <ContextedEditorMenu
        onAttachFileClickHandler={onAttachFileClickHandler}
        onAddImageClick={() => onActionsClickRef.current("add image")}
      />
      <div className={styles.rightSide}>
        <RolesChecker
          roles={[RolesEnum.Viewer, RolesEnum.External]}
          isExcluding={true}
        >
          {!isLocked && <ObjectModeToggle />}
          {isLocked && (
            <ObjectUnlockDropdown
              onUnlockPageClick={() => onActionsClickRef.current("unlock page")}
            />
          )}
        </RolesChecker>
        {isPinButtonVisible && (
          <div className={styles.standaloneButtons}>
            <div className={styles.standaloneButton}>
              <ObjectPinButton />
            </div>
          </div>
        )}
        {isShareButtonVisible && (
          <div className={styles.standaloneButtons}>
            <div className={styles.standaloneButton}>
              <ShareButton onClick={() => onActionsClickRef.current("share")} />
            </div>
          </div>
        )}
        {isEditorEllipsisMenuVisible && (
          <DropdownButton
            isButtonEnabled={true}
            showDropdownInPortal
            optionLabels={optionLabels}
            onClickOption={(optionName) =>
              onActionsClickRef.current(optionName)
            }
            extraClassNames={{
              dropdownButton: styles.actionsButton,
              buttonIconContainer: styles.actionsButtonIconContainer,
              optionText: styles.optionText,
              dropdownButtonHover: styles.actionsDropdownButtonHover,
            }}
            iconNameRight={faEllipsisV}
          />
        )}
        {isEditorShown && (
          <CreatedByAccount
            email={objectCreatedByUsername}
            createdDate={DateHelperSingleton.getDateWithYear(
              new Date(objectCreatedOnDate)
            )}
          />
        )}
      </div>
    </div>
  );
};
