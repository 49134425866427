// node_modules
import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import styles from "./editableNumberInput.module.scss";

type TEditableNumberInputProps = {
    value: number,
    onValueChange: (newValue: number) => void,
    placeholder?: string,
    min?: number
}

export const EditableNumberInput: FC<TEditableNumberInputProps> = ({ value, onValueChange, placeholder, min}: TEditableNumberInputProps) => {
    // State
    const [editingValue, setEditingValue] = useState<string>(`${value}`);

    const minValue = useMemo((): number => {
        if (min !== undefined && min > 0) {
            return min;
        } else {
            return 1;
        }
    }, [min]);
    
    // handle editing value change
    const onEditingValueChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        // get new value
        const newValue: string  = event.target.value;

        // set editing value to new value
        setEditingValue(newValue);

        // if new value is a positive number superior to 0
        if (newValue && !isNaN(Number(newValue)) &&
            (parseInt(newValue, 10) >= minValue)) {
            // parse new value
            const parsedNewValue: number = parseInt(newValue, 10);
            // call onValueChange
            onValueChange(parsedNewValue);
        } else {
            // call onValueChange
            onValueChange(-1);
        }
    }, [onValueChange, minValue]);

    // Render
    return (
        <input
            type="number"
            min={minValue}
            placeholder={placeholder}
            value={editingValue}
            onChange={onEditingValueChange}
            className={styles.input}
        />
    );
};
