// node_modules
import { FC, useMemo } from "react";
// Enums
import { ObjectTypeEnum, WebRequestStatusEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import { LoadingStatusIndicator } from "Components";
import { CommonObjectReference } from "./CommonObjectReference";
// Styles
import { LinkingControllerSingleton } from "Controllers";
import styles from "./referenceSidebarTabs.module.scss";

export type ReferenceSidebarObjectsTabProps = {
    objectIdEdited: string,
    objectTypeEdited: ObjectTypeEnum,
    objectType: ObjectTypeEnum,
    searchTextValue: string,
    isSearchRunning: boolean,
    objectList: TIdNameTypeObjectType[],
    applyInsertObjectReference: (object: TIdNameTypeObjectType) => void,
    usedReferenceIds: Set<string>
};

export const ReferenceSidebarObjectsTab: FC<ReferenceSidebarObjectsTabProps> = ({ objectIdEdited, objectTypeEdited, searchTextValue, 
        isSearchRunning, objectType, objectList, applyInsertObjectReference, usedReferenceIds }: ReferenceSidebarObjectsTabProps) => {
    // Logic
    const typeNameForText = useMemo(() => {
        return objectType === ObjectTypeEnum.Study ? "Studies" : "Entities";
    }, [objectType]);

    const onLinkingIconClickAsync = async (isCurrentlyConnected: boolean, currentObjectIdEdited: string, currentObjectTypeEdited: ObjectTypeEnum,
        object: TIdNameTypeObjectType, currentObjectType: ObjectTypeEnum): Promise<boolean> => {
        // safety-checks
        if (!currentObjectIdEdited ||
                !object) { 
            return false; 
        }

        // depending on isCurrentlyConnected, call the appropriate function
        if (isCurrentlyConnected) {
            // delete the link
            return await LinkingControllerSingleton
                .deleteAsync(currentObjectIdEdited, object.id);
        } else {
            // create the link
            const webRequestStatus: WebRequestStatusEnum = await LinkingControllerSingleton
                .createToAsync(object.id, currentObjectType, currentObjectIdEdited, currentObjectTypeEdited);

            // return true if the web request was successful
            return webRequestStatus === WebRequestStatusEnum.Success || webRequestStatus === WebRequestStatusEnum.AlreadyExists ? true : false;
        }
    };


    // Render
    return (
        <div className={styles.referenceSidebarSelectedTab}>
            {objectList.map((currentObject) => {
                const isReferenceUsedAlready = usedReferenceIds.has(currentObject.id);
                return (
                    <CommonObjectReference 
                        objectIdEdited={objectIdEdited}
                        objectTypeEdited={objectTypeEdited}
                        isReferenceUsedAlready={isReferenceUsedAlready} 
                        key={currentObject.id} objectInformation={currentObject} 
                        applyInsertObjectReference={applyInsertObjectReference} 
                        onLinkingIconClick={(isCurrentlyConnected) => onLinkingIconClickAsync(isCurrentlyConnected, objectIdEdited, objectTypeEdited, currentObject, objectType)} 
                        />
                );
            })}

            {objectList.length === 0 ?
                searchTextValue.length === 0 ? 
                    <div className={styles.noReferences}>
                        <h4>{typeNameForText} from your Universe appear here.</h4>
                        <h4>You can create {typeNameForText.toLowerCase()} from the {typeNameForText} page in the library or using the the browser extension!</h4> 
                    </div>
                    :
                    isSearchRunning ? 
                            <div className={styles.loadingIndicatorContainer}>
                                <LoadingStatusIndicator size={40} status={1} />
                            </div>
                            :
                            <div className={styles.noReferences}>
                                <div>No {typeNameForText.toLocaleLowerCase()} found for: {searchTextValue}</div>
                            </div>
                : null
            }
        </div>
    );
};