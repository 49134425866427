// node_modules
import { Edge, MarkerType, Node } from "reactflow";
// Types
import { TLinkGraphDTO, TLinkGraphNodeDTO, TReactFlowNode, ILink, INode } from "Types";
// Enums
import { ObjectTypeEnum } from "Enums";
// Constants
import { LinkingConstants } from "Constants";
export class ReactFlowHelper {
    public static generalEdgeProps = {
        type: "buttonedge",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#757575"
        },
        style: {
            strokeWidth: 1.2,
            stroke: "#757575"
        }
    };

    public FromLinkGraphToNodes(linkGraph: TLinkGraphDTO, position: {x: number, y: number}, lowerLevelLimits: number = LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT, doIncludeFocusedNode = true): Node<TReactFlowNode>[] {
        // safety-checks on lower level limits
        if (lowerLevelLimits < 0) {
            lowerLevelLimits = LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT;
        }
        
        // init nodes list with link graph focused node data
        const nodes: Node<TReactFlowNode>[] = [];

        // add focused link graph node to nodes list if needed
        if (doIncludeFocusedNode) {
            // load more count of focused node is 0
            // because we display all its lower and upper level nodes
            nodes.push(ReactFlowHelper.buildNodeFromLinkGraphNode(linkGraph.focusedNode, position, 0));
        }

        // add lower level link graph nodes to nodes recursively
        ReactFlowHelper.addLowerLevelNodesToNodes(linkGraph.lowerLevelNodes, nodes, 0, lowerLevelLimits, position);

        // add upper level link graph nodes to nodes list
        for (const upperLevelNode of linkGraph.upperLevelNodes) {
            // upper level nodes are extremity nodes, so their load more count
            // is the sum of their lower level nodes count and their other upper level nodes count
            const loadMoreCount = upperLevelNode.lowerLevelNodes.length + upperLevelNode.otherUpperLevelNodes.length;
            nodes.push(ReactFlowHelper.buildNodeFromLinkGraphNode(upperLevelNode, position, loadMoreCount));
        }

        // return nodes list
        return nodes;
    }

    public FromLinkGraphToEdges(linkGraph: TLinkGraphDTO,
            lowerLevelLimits: number = LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT): Edge[] {
        // init edges list
        const edges: Edge[] = [];

        // add edges between upper level link graph nodes and focused link graph node
        for (const upperLevelNode of linkGraph.upperLevelNodes) {
            edges.push(ReactFlowHelper.buildEdgeFromSourceToTargetLinkGraphNodes(upperLevelNode, linkGraph.focusedNode));
        }

        // recursively add edges between lower level link graph nodes (starting from focused link graph node)
        for (const lowerLevelNode of linkGraph.lowerLevelNodes) {
            edges.push(ReactFlowHelper.buildEdgeFromSourceToTargetLinkGraphNodes(linkGraph.focusedNode, lowerLevelNode));
            ReactFlowHelper.addLowerLevelNodesToEdges(lowerLevelNode, edges, 1, lowerLevelLimits);
        }

        // return edges list
        return edges;
    }


    public BuildNodesForUniverse(nodes: TLinkGraphNodeDTO[]) {
        const newNodes: INode[] = nodes.map(node => {
            return ReactFlowHelper.buildNodeFromLinkGraphNode(node, {x: 0, y: 0}, 0);
        }).map(n => ({ ...n, group: n.type || "entityNode" }));
       
        return newNodes;
    }
    public BuildEdgesForUniverse = (nodes: TLinkGraphNodeDTO[]) => {
        const edges: ILink[] = [];
        nodes.forEach((node) => {
            for (const lowerLevelNode of node.lowerLevelNodes) {
                const reactFlowEdge = ReactFlowHelper.buildEdgeFromSourceToTargetLinkGraphNodes(node, lowerLevelNode);
                edges.push({ source: reactFlowEdge.source, target: reactFlowEdge.target, value: reactFlowEdge.id });
            }
        });
        return edges;
    };

    private static buildNodeFromLinkGraphNode(linkGraphNode: TLinkGraphNodeDTO, 
            position: {x: number, y: number}, loadMoreCount: number): Node<TReactFlowNode> {
        const objectType: string = linkGraphNode.type.toLocaleUpperCase() == "CUSTOM" && 
            linkGraphNode.customTypeName ? linkGraphNode.customTypeName : linkGraphNode.type;
        return {
            id: linkGraphNode.id,
            type: ReactFlowHelper.getNodeType(linkGraphNode.objectType),
            data: { 
                id: linkGraphNode.id,
                objectType: objectType, 
                objectTypeEnum: linkGraphNode.objectType,
                text: linkGraphNode.name,
                loadMoreCount: loadMoreCount,
                otherUpperLevelNodes: linkGraphNode.otherUpperLevelNodes,
                lowerLevelNodes: linkGraphNode.lowerLevelNodes
            },
            position
        };
    }

    private static buildEdgeFromSourceToTargetLinkGraphNodes(source: TLinkGraphNodeDTO, target: TLinkGraphNodeDTO): Edge {
        return { 
            id: `${source.id}-${target.id}`, 
            source: source.id, 
            target: target.id,
            ...ReactFlowHelper.generalEdgeProps
        };
    }

    private static addLowerLevelNodesToNodes(lowerLevelNodes: TLinkGraphNodeDTO[], nodes: Node<TReactFlowNode>[], 
                currentLevel: number, levelsLimit: number, position: {x: number, y: number}): void {
        // safety-checks on current level and levels limit
        if (currentLevel >= levelsLimit) {
            return;
        }
        
        // add current link graph nodes to nodes recursively
        for (const currentLowerLevelNode of lowerLevelNodes) {
            // init load more count
            let loadMoreCount = currentLowerLevelNode.otherUpperLevelNodes.length;
            // when current level is the last level (extremity nodes), add lower level nodes count to load more count
            if (currentLevel === levelsLimit - 1) {
                loadMoreCount += currentLowerLevelNode.lowerLevelNodes.length;
            }

            // build nodes
            nodes.push(ReactFlowHelper.buildNodeFromLinkGraphNode(currentLowerLevelNode, position, loadMoreCount));
            
            // add next lower level nodes to nodes recursively
            ReactFlowHelper.addLowerLevelNodesToNodes(currentLowerLevelNode.lowerLevelNodes, nodes,
                currentLevel + 1, levelsLimit, position);
        }
    }

    private static addLowerLevelNodesToEdges(node: TLinkGraphNodeDTO, edges: Edge[],
            currentLevel: number, levelsLimit: number): void {
        // safety-checks on current level and levels limit
        if (currentLevel >= levelsLimit) {
            return;
        }

        // add current link graph node edges to edges recursively
        for (const lowerLevelNode of node.lowerLevelNodes) {
            edges.push(ReactFlowHelper.buildEdgeFromSourceToTargetLinkGraphNodes(node, lowerLevelNode));
            ReactFlowHelper.addLowerLevelNodesToEdges(lowerLevelNode, edges, currentLevel + 1, levelsLimit);
        }
    }

    public static getNodeType(objectType: ObjectTypeEnum): string {
        return objectType === ObjectTypeEnum.Entity ? "entityNode" : "studyNode";
    }
}

export const ReactFlowHelperSingleton = new ReactFlowHelper();