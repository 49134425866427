import axios from "axios";
import { AxiosHelperSingleton } from "Helpers";
import { TIdNameTypeObjectType, TRecentSidebarActivityItemDTO } from "Types";

export class ActivityController {
    private resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/activity`;

    public async getMySimpleActivityAsync(): Promise<TIdNameTypeObjectType[]> {
        try {
            const response = await axios.get<TIdNameTypeObjectType[]>(`${this.resourcePath}/mysimplerecent`);

            if (response && response.data) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }
    
    public async getMyActivityAsync(maxActivity: number | undefined = undefined): Promise<TRecentSidebarActivityItemDTO[]> {
        try {
            // init url
            let url = `${this.resourcePath}/my`;

            // add max activity to url if defined
            if (maxActivity) {
                url += `?maxActivity=${maxActivity}`;
            }

            const response = await axios.get<TRecentSidebarActivityItemDTO[]>(url);

            if (response && response.data) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getActivityAsync(maxActivity: number | undefined = undefined): Promise<TRecentSidebarActivityItemDTO[]> {
        try {
            // init url
            let url = `${this.resourcePath}`;

            // add max activity to url if defined
            if (maxActivity) {
                url += `?maxActivity=${maxActivity}`;
            }

            const response = await axios.get<TRecentSidebarActivityItemDTO[]>(url);

            if (response && response.data) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }
}

export const ActivityControllerSingleton = new ActivityController();