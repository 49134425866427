// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class CachingController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/caching`;

    public async clearUserTenantCache(): Promise<boolean> {
        try {
            const response = await axios.delete(`${this._resourcePath}/usertenant`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async addNewDatabaseFilesToCache(): Promise<boolean> {
        try {
            const response = await axios.post(`${this._resourcePath}/addnewdatabasefiles`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const CachingControllerSingleton = new CachingController();