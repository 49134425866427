// Enums
import { LogFeatureNameEnum, ObjectTypeEnum, PubSubEventTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";
// Interfaces
import { IPubSubHandler } from "Interfaces";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Helpers
import { LogHelperSingleton, ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";

export class ConnectedObjectsHelper {
    public async addObjectToObjectAsync(objectToConnect: TIdNameTypeObjectType, pubSubHandler: IPubSubHandler,
                objectIdToConnectTo: string, objectTypeToConnectTo: ObjectTypeEnum, ActionOrigin?: LogFeatureNameEnum): Promise<void> {
        // link objects
        const resultStatus: WebRequestStatusEnum = await LinkingControllerSingleton
            .createToAsync(objectToConnect.id, objectToConnect.objectType, 
                objectIdToConnectTo, objectTypeToConnectTo, ActionOrigin);

        // get object type to connect to display name
        const objectTypeToConnectToDisplayName = ObjectTypeHelperSingleton
            .getObjectTypeDisplayName(objectTypeToConnectTo)
            .toLowerCase();
        // get object to connect display name
        const objectToConnectDisplayName = ObjectTypeHelperSingleton
            .getObjectTypeDisplayName(objectToConnect.objectType);

        // safety-checks
        if (resultStatus === WebRequestStatusEnum.Failed) {
            // show error message
            ToastHelperSingleton
                .showToast(
                    ToastTypeEnum.Error, 
                    `Could not link ${objectToConnectDisplayName.toLowerCase()} to ${objectTypeToConnectToDisplayName}.`
                );
            // stop execution, return
            return;
        }
        if (resultStatus === WebRequestStatusEnum.AlreadyExists) {
            // show error message
            ToastHelperSingleton
                .showToast(
                    ToastTypeEnum.Error, 
                    `${objectToConnectDisplayName} is already linked to ${objectTypeToConnectToDisplayName}.`
                );
            // stop execution, return
            return;
        }
        
        // show success message
        ToastHelperSingleton
            .showToast(
                ToastTypeEnum.Success, 
                `${objectToConnectDisplayName} linked to ${objectTypeToConnectToDisplayName}.`
            );

        // send echo message to sync addition of object everywhere
        await pubSubHandler.publishEvent("Echo", `${PubSubEventTypeEnum.AddLinkTo}-${objectIdToConnectTo}`, objectToConnect);
    }

    public addNewConnectedObjectToObject(newConnectedObject: TIdNameTypeObjectType, connectedObjects: TIdNameTypeObjectType[]): void {
        // if new connected object is already in connected objects, do nothing
        const isAlreadyConnected = connectedObjects.some((connectedObject: TIdNameTypeObjectType) => {
            return connectedObject.objectType === newConnectedObject.objectType && connectedObject.id === newConnectedObject.id;
        });
        if (isAlreadyConnected) {
            // stop execution
            return;
        }

        // if selected link to object is a Study, add it to the end of connected objects
        if (newConnectedObject.objectType === ObjectTypeEnum.Study) {
            // add the object to the end of the query
            connectedObjects.push(newConnectedObject);
        } else {
            // otherwise, add it before the first Study in connected objects
            // find the first Study in the connected objects
            const firstStudyIndex = connectedObjects.findIndex((connectedObject: TIdNameTypeObjectType) => {
                return connectedObject.objectType === ObjectTypeEnum.Study;
            });
            // if there is no Study in the connected objects, add the object to the end of the query
            if (firstStudyIndex === -1) {
                connectedObjects.push(newConnectedObject);
            } else {
                // otherwise, add the object before the first Study in the connected objects
                connectedObjects.splice(firstStudyIndex, 0, newConnectedObject);
            }
        }

    }

    public async removeConnectedObjectFromObjectAsync(connectedObjectToRemove: TIdNameTypeObjectType, objectId: string): Promise<void> {
        // remove link
        const isSuccess: boolean = await LinkingControllerSingleton
            .deleteAsync(objectId, connectedObjectToRemove.id);

        // if the link was not removed
        if (!isSuccess) {
            // show error message
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not remove link.");
        } else {
            // log
            LogHelperSingleton.log("Unlink");
        }
    }
}

export const ConnectedObjectsHelperSingleton = new ConnectedObjectsHelper();