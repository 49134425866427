// node_modules
import axios from "axios";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class GptController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/ai`;
    
    public async generateDescriptionAsync(name: string, abortSignal: AbortSignal): Promise<string | undefined> {
        try {
            const response = await axios.get<string | undefined>(`${this._resourcePath}/generateDescription`, {
                params: {
                    name: name
                },
                signal: abortSignal
            });

            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch (error: unknown) {
            // handle error
            return this.handleError(error);
        }
    }

    public async generateReportFromDocumentsAndHighlightsAsync(objectId: string, 
            objectType: ObjectTypeEnum, abortSignal: AbortSignal): Promise<string | undefined> { 
        
        try {
            const response = await axios.get<string | undefined>(`${this._resourcePath}/generateReportFromDocumentsAndHighlights`, {
                params: {
                    objectId: objectId,
                    objectType: objectType
                },
                signal: abortSignal
            });

            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch (error: unknown) {
            // handle error
            return this.handleError(error);
        }
    }

    private handleError(error: unknown): string | undefined {
        // if error is AxiosError and code is ERR_CANCELED
        if (axios.isAxiosError(error) && error.code === "ERR_CANCELED") {
            // return empty string
            return "";
        } else if (axios.isAxiosError(error) && error.code === "ERR_BAD_REQUEST" && 
                error.response && error.response.data && 
                (error.response.data as { message: string }).message) {
            // otherwise, if error is AxiosError, code is ERR_BAD_REQUEST and error response data message is set
            return `##ERROR## ${(error.response.data as { message: string }).message}`;
        } else {
            // otherwise, return undefined
            return undefined;
        }
    }
}

export const GptControllerSingleton = new GptController();