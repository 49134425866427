class SetHelper {
    // function to compare two sets
    public areSetsEqual(set1: Set<string>, set2: Set<string>): boolean {
        // if the sizes of the sets are different
        if (set1.size !== set2.size) {
            // the sets are not equal
            return false;
        }

        // init areEqual flag
        let areEqual = true;
        // go through each value in set1
        set1.forEach(value => {
            // if value is not in set2
            if (!set2.has(value)) {
                // the sets are not equal
                areEqual = false;
                // stop loop, return false
                return false;
              }
        });

        // return areEqual flag
        return areEqual;
    }
}

export const SetHelperSingleton = new SetHelper();
