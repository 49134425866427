// node_modules
import { FC } from "react";

type TReferenceProps = {
    referenceUrl?: string
}

export const Reference: FC<TReferenceProps> = ({referenceUrl}: TReferenceProps) => {
    // safety-checks
    if (!referenceUrl) { return null; }

    // Render
    return(
        <a title={"[Link]"} href={referenceUrl} target="_blank" rel="noopener noreferrer">[Link]</a>
    );
};