// node_modules
import { Dispatch, FC, SetStateAction } from "react";
// Styles
import styles from "./pubSubExplorerCollapsibleList.module.scss";
// Components
import { CollapsibleList, ListItemLink } from "Components";
// Types
import { TExplorerObjectItem, fromTLinkGraphNodeDTO } from "Types";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Custom hooks
import { useExplorerObjectNameChangeListener, useExplorerObjectConversionListener } from "Hooks";

// Component props type
type TPubSubCollapsibleListProps = {
    parents?: TExplorerObjectItem[],
    items?: TExplorerObjectItem[],
    setItems: Dispatch<SetStateAction<TExplorerObjectItem[] | undefined>>,
    objectIdEdited?: string,
    openReferenceModal: (objectId: string, objectType: ObjectTypeEnum) => void,
    toggleIsCollapsedAsync: (listItemToUpdate: TExplorerObjectItem) => Promise<void>
}

// Component
export const PubSubExplorerCollapsibleList: FC<TPubSubCollapsibleListProps> = ({ parents, items, setItems, objectIdEdited, openReferenceModal, toggleIsCollapsedAsync }: TPubSubCollapsibleListProps) => {
    /** Listen to object name changes using websockets */
    useExplorerObjectNameChangeListener(setItems);
    useExplorerObjectConversionListener(setItems);

    // Render
    return (
        (items && items.length > 0) ? (
                <CollapsibleList
                    listItems={items}
                    toggleIsCollapsed={toggleIsCollapsedAsync}
                    extraClassNames={{
                        collapsibleList: parents && parents.length > 1 ? styles.collapsibleListWithLeftBorder : "",
                        collapsibleListItemClassNames: {
                            chevronIconContainer: styles.chevronIconContainer,
                            onlyChildIconContainer: styles.onlyChildIconContainer,
                            chevronIcon: styles.chevronIcon,
                            onlyChildIcon: styles.onlyChildIcon
                        }
                    }}
                    render={(listItem) => (
                        <ListItemLink
                            key={listItem.id}
                            navigateTo={`/library/${ObjectTypeHelperSingleton.getObjectTypeReactRouteName(listItem.objectType)}/${listItem.id}`}
                            iconHasColor={objectIdEdited === listItem.id}
                            icon={undefined}
                            object={fromTLinkGraphNodeDTO(listItem)}
                            extraClassNames={{
                                listItem: styles.listItem,
                                listItemIconContainer: styles.listItemIconContainer,
                                itemText: styles.itemText,
                            }}
                            openReferenceModal={openReferenceModal}
                            theme={["transparent", "smallMoreActionsButton", objectIdEdited === listItem.id ? "selected" : ""]}
                        />
                    )}
                />
            )
        :
            null
    );
};