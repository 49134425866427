// node_modules
import { Plugin, PluginKey, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { ProseMirrorHelperSingleton, UserHelperSingleton } from "Helpers";
// Types
import { TAuth } from "Types";

// init plugin key
export const referencePopoverPluginKey = new PluginKey<ReferencePopoverPluginState>(
    "referencePopoverPlugin",
);

// define plugin
export const referencePopoverPlugin = (auth: TAuth, showReferencePopover?: (id: string, type: ObjectTypeEnum, referenceElement: HTMLElement) => void,
        hideReferencePopover?: () => void) => {
    return new Plugin<ReferencePopoverPluginState>({
        key: referencePopoverPluginKey,
        state: {
          init() {
            // set default state
            return new ReferencePopoverPluginState(false, undefined);
          },
          apply(tr: Transaction, prev: ReferencePopoverPluginState) {
            return prev.apply(tr);
          },
        },
        props: {
            handleDOMEvents: {
                // on mouse over
                mouseover: (editorView: EditorView, event: MouseEvent) => {
                    if (UserHelperSingleton.isSharingRestrictedToObject(auth)) {
                        // stop execution, return
                        return;
                    }
                    
                    // get event target as HTMLElement
                    const target = event.target as HTMLElement;

                    // if node name is an inline reference
                    if (ProseMirrorHelperSingleton.isInlineReferenceNode(target)) {
                        // update plugin state
                        updateReferencePopoverPluginState(editorView, true, target);
                    }

                    // if showReferencePopover and hideReferencePopover are defined
                    if (showReferencePopover && hideReferencePopover) {
                        // call onMouseOverInlineReferenceHandler
                        ProseMirrorHelperSingleton
                            .onMouseOverInlineReferenceHandler(event, showReferencePopover, hideReferencePopover);
                    }
                }
            },
        },
    });
};

// plugin state
class ReferencePopoverPluginState {
    // store is reference popover open and reference node
    public isReferencePopoverOpen: boolean;
    public referenceNode: Node | undefined;

    // constructor
    constructor(isReferencePopoverOpen: boolean, referenceNode: Node | undefined) {
        this.isReferencePopoverOpen = isReferencePopoverOpen;
        this.referenceNode = referenceNode;
    }

    apply(tr: Transaction): ReferencePopoverPluginState {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const state = this;

        // get action from transaction
        const action = tr.getMeta(referencePopoverPluginKey);

        // if action is set
        if (action && action.setData) {
            // get isReferencePopoverOpen and referenceNode from action
            const { isReferencePopoverOpen, referenceNode } = action.setData;
            // return new state
            return new ReferencePopoverPluginState(isReferencePopoverOpen, referenceNode);
        }

        // return old state
        return state;
    }
}

// helper function to update plugin state
function updateReferencePopoverPluginState(editorView: EditorView, isReferencePopoverOpen: boolean, referenceNode: Node | undefined): void {  
    // disptach transaction to update plugin state
    editorView.dispatch(
        editorView.state.tr.setMeta(referencePopoverPluginKey, { 
            setData: { isReferencePopoverOpen, referenceNode }
        })
    );
}

