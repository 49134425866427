// node_modules
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import {
    CreatedByAccount, DropdownButton, FindestButton, LoadingStatusIndicator, MainTitle,
    ObjectSearchPopupContent, Popover, QueryFields, ToggleButton, PubSubConnectedObjects
} from "Components";
import { QuerySearchResults } from "./QuerySearchResults";
// Constants
import { EventConstants, GeneralConstants, WebsocketFunctionNames } from "Constants";
// Controllers
import { QueryControllerSingleton } from "Controllers";
// Enums
import { LogFeatureNameEnum, ObjectTypeEnum, SearchQueryTypeEnum, ToastTypeEnum } from "Enums";
// Contexts
import { AuthContext, PubSubContext, QueryContext, QueryViewOptionsProvider, WebsocketContext } from "Providers";
// Types
import { TDropdownButtonOption, TIdNameTypeObjectType } from "Types";
// Helpers
import { ConnectedObjectsHelperSingleton, DateHelperSingleton, LogHelperSingleton, ObjectTypeHelperSingleton, ToastHelperSingleton, UserHelperSingleton } from "Helpers";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Styles
import styles from "./queryDetailsPage.module.scss";

type TQueryDetailsPage = {
    extraClassNames?: { container?: string },
    showGoBackButton?: boolean,
    onDelete: () => void,
    onDuplicateAsync: (duplicateQuery: IQueryDTO) => Promise<void>
}

export const QueryDetailsPage: FC<TQueryDetailsPage> = ({ extraClassNames, showGoBackButton = true, onDelete, onDuplicateAsync }: TQueryDetailsPage) => {
    // Context
    const { webSocketController } = useContext(WebsocketContext);
    const { query, setQuery } = useContext(QueryContext);
    const { auth, isUserExternal } = useContext(AuthContext);
    const { pubSubHandler } = useContext(PubSubContext);

    // Hooks
    const navigate = useNavigate();

    // Custom Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    // State
    const [isSavePopupOpen, setIsSavePopupOpen] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [queryElementReference, setQueryElementReference] = useState<HTMLDivElement | null>(null);
    const [searchQueryType, setSearchQueryType] = useState<SearchQueryTypeEnum>(SearchQueryTypeEnum.UniverseScienceArticles);
    const [isGroupedSearch, setIsGroupedSearch] = useState<boolean>(false);
    const [isQueryShown, setIsQueryShown] = useState<boolean>(true);
    const [runningSearchId, setRunningSearchId] = useState<string | undefined>(undefined);

    const searchAmount = 100;

    const endSearch = useCallback(() => {
        setRunningSearchId(undefined);
        setIsSearching(false);
    }, [setIsSearching]);

    // Effects
    useEffect(() => {
        // safety-checks
        if (!query || !runningSearchId) {
            return;
        }

        const searchEndedListenName = `${WebsocketFunctionNames.SearchEnded}-${runningSearchId}`;
        webSocketController.addHandler(searchEndedListenName, endSearch);

        return () => {
            webSocketController.removeHandler(searchEndedListenName, endSearch);
        };
    }, [webSocketController, endSearch, query, runningSearchId]);

    const onSearchClick = useCallback(async (currentSearchQueryType: SearchQueryTypeEnum, showSearchResults: boolean, isGroupedSearchParam?: boolean, filteredResultNames?: string[], groupName?: string, sortQuery?: string) => {
        if (!query) return;

        if (currentSearchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) {
            LogHelperSingleton.logWithProperties(`${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Science`, { QueryGuid: query.guid });
        } else if (currentSearchQueryType === SearchQueryTypeEnum.UniversePatents) {
            LogHelperSingleton.logWithProperties(`${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Patent`, { QueryGuid: query.guid });
        }

        setIsSearching(true);
        setPage(1);
        showSearchResults ? setIsQueryShown(false) : null;

        const finalIsGroupedSearch = isGroupedSearchParam !== undefined ? isGroupedSearchParam : isGroupedSearch;
        filteredResultNames = filteredResultNames ? filteredResultNames : [];
        await webSocketController.invokeFunction(WebsocketFunctionNames.StartSearchQueryById,
            query.guid, currentSearchQueryType, 1, finalIsGroupedSearch, searchAmount, filteredResultNames, groupName ? groupName : null, sortQuery);
    }, [isGroupedSearch, query, webSocketController]);

    const onPaginate = useCallback(async (isToNextPage: boolean, alreadyPagedNames?: string[], sortQuery?: string) => {
        if (!query) return;

        if (searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) {
            LogHelperSingleton.logWithProperties(`${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Science-Paginate`, { QueryGuid: query.guid });
        } else if (searchQueryType === SearchQueryTypeEnum.UniversePatents) {
            LogHelperSingleton.logWithProperties(`${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Patent-Paginate`, { QueryGuid: query.guid });
        }

        let newPageValue: number;
        if (isToNextPage) {
            newPageValue = page + 1;
        } else {
            newPageValue = page - 1;
        }

        await webSocketController.invokeFunction(WebsocketFunctionNames.StartSearchQueryById,
            query.guid, searchQueryType, newPageValue, isGroupedSearch, searchAmount, alreadyPagedNames,
            null, sortQuery);
        setIsSearching(true);
        setPage(newPageValue);
    }, [isGroupedSearch, page, query, searchQueryType, webSocketController]);

    const handleNewQueryNameAsync = useCallback(async (newName: string): Promise<void> => {
        // safety-checks
        if (!newName || !query) {
            // do nothing
            return;
        }

        // set new query ame
        query.name = newName;

        // update query name
        const updatedQuery: IQueryDTO | undefined = await QueryControllerSingleton
            .updateAsync(query.guid, query);

        // safety-checks
        if (!updatedQuery) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Failed to update query name");
            // do nothing
            return;
        }

        // log
        LogHelperSingleton.log("UpdateQueryName");

        // set the query to the new query
        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                // return the previous query
                return prevQuery;
            }

            // return the updated query
            return {
                ...prevQuery,
                name: updatedQuery.name
            };
        });
    }, [query, setQuery]);

    // debounce the handleNewQueryNameAsync function
    const debouncedHandleNewQueryNameAsync = useMemo(() => debounce(handleNewQueryNameAsync, EventConstants.UPDATE_OBJECT_NAME_DEFAULT_MS_DELAY),
        [handleNewQueryNameAsync]);

    const deleteQueryAsync = useCallback(async (queryToDelete: IQueryDTO): Promise<void> => {
        // confirm with the user that they want to delete the query
        if (!confirm("Are you sure you want to delete the query?")) {
            // stop execution
            return;
        }

        // delete query
        const isSuccess: boolean = await QueryControllerSingleton
            .bulkDeleteAsync([queryToDelete.guid]);

        // safety-checks
        if (!isSuccess) {
            // show error message
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to delete the query.");
            // stop execution
            return;
        }

        // log
        LogHelperSingleton.log("RemoveQuery(ies)");

        // call on delete handler
        onDelete();
    }, [onDelete]);

    const onDuplicateQueryAsync = useCallback(async (queryToDuplicate: IQueryDTO): Promise<void> => {
        // duplicate query
        const duplicatedQuery: IQueryDTO | undefined = await QueryControllerSingleton
            .duplicateAsync(queryToDuplicate.guid);

        // safety-checks
        if (!duplicatedQuery) {
            // show error message
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Failed to duplicate the query.");
            // stop execution
            return;
        }

        // call on duplicate handler
        await onDuplicateAsync(duplicatedQuery);
    }, [onDuplicateAsync]);

    const onClickOptionAsync = useCallback(async (optionName: TDropdownButtonOption): Promise<void> => {
        // safety-checks
        if (!query) {
            // stop execution, return
            return;
        }

        // if the option is "delete"
        if (optionName === "delete") {
            // delete the query
            await deleteQueryAsync(query);
        } else if (optionName === "duplicate") {
            await onDuplicateQueryAsync(query);
        }
    }, [deleteQueryAsync, onDuplicateQueryAsync, query]);

    const cancelSearch = useCallback(async () => {
        await webSocketController.invokeFunction(WebsocketFunctionNames.CancelSearch, runningSearchId);
        setRunningSearchId(undefined);
        setIsSearching(false);
    }, [runningSearchId, webSocketController]);

    // Logic
    if (!query) {
        return (
            <div className={styles.loadingIndicatorContainer}><LoadingStatusIndicator size={60} status={1} /></div>
        );
    }

    const onElementClickAsync = async (currentQuery: IQueryDTO | undefined,
            selectedLinkToObject: TIdNameTypeObjectType): Promise<void> => {
        // if current query is not set
        if (!currentQuery) {
            // show error message
            ToastHelperSingleton
                .showToast(
                    ToastTypeEnum.Error, 
                    `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(selectedLinkToObject.objectType).toLowerCase()} to query.`
                );
            // stop execution, return
            return;
        }

        // add object to query
        await ConnectedObjectsHelperSingleton
            .addObjectToObjectAsync(selectedLinkToObject, pubSubHandler, currentQuery.guid, ObjectTypeEnum.Query, LogFeatureNameEnum.AdvancedSearch);

        // set the query to the new query
        setQuery(currentQuery);

        // close modal
        setIsSavePopupOpen(false);
    };

    const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
        setReferenceModalProps((previousReferenceModalProps) => {
            return {
                ...previousReferenceModalProps,
                isOpen: true,
                id: objectId,
                type: objectType
            };
        });
    };

    // Render
    return (
        <div className={`${extraClassNames?.container ?? ""} ${styles.queryDetailsPageContainer}`}>
            <div className={styles.queryDetailsPageHeader}>
                <div className={styles.queryDetailsPageHeaderPart}>
                    {showGoBackButton && <FontAwesomeIcon className={styles.goBackToQueriesIcon} icon={faChevronLeft} onClick={() => navigate("/queries")} />}
                    <ToggleButton
                        toggleTextLeft="Query"
                        toggleTextRight="Results"
                        leftToggleActive={isQueryShown}
                        onClickToggleButton={setIsQueryShown}
                        buttonWidth="180px" />
                    <MainTitle
                        title={query.name}
                        isEditable={!isUserExternal}
                        onUpdateTitle={(newTitle: string) => debouncedHandleNewQueryNameAsync(newTitle)}
                        extraClassName={styles.titleInput} />
                </div>
                <div className={styles.queryDetailsPageHeaderPart}>
                    <DropdownButton
                        isButtonEnabled={true}
                        optionLabels={["duplicate", "delete"]}
                        onClickOption={onClickOptionAsync}
                        extraClassNames={{ dropdownButton: styles.queryOptionsButton, optionText: styles.optionText }}
                        buttonText="options"
                    />
                    <CreatedByAccount
                        email={query.username}
                        createdDate={DateHelperSingleton.getDateWithYear(query.dateCreated)}
                        userIconSize="large"
                    />
                </div>
            </div>
            <div className={[styles.queryDetailsContainer, isQueryShown ? styles.active : ""].join(" ")}>
                <PubSubConnectedObjects
                    mainObjectId={query.guid}
                    mainObjectType={ObjectTypeEnum.Query}
                    connectedObjects={query.connectedObjects}
                    onConnectToObjectClick={() => { setIsSavePopupOpen(true); }}
                    setContainerElementReference={setQueryElementReference}
                    extraClassName={styles.connectedObjectsContainer}
                    disableConnectToNewObjectButton={UserHelperSingleton.isUserViewer(auth) || UserHelperSingleton.isUserExternalByAuth(auth)} />
                <Popover referenceEl={queryElementReference}
                    isOpen={isSavePopupOpen}
                    onClickOutside={() => { setIsSavePopupOpen(false); }}
                    extraClassName={styles.objectSearchPopupContainer}
                    placement="bottom-start"
                    exceptionDataIdentifiter={GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                >
                    <ObjectSearchPopupContent
                        currentObjectId={query.guid}
                        onElementClick={(result: TIdNameTypeObjectType) => { onElementClickAsync(query, result); }}
                        doShowRecentActivity={true}
                        openReferenceModal={openReferenceModal}
                    />
                </Popover>
                <QueryFields
                    query={query}
                    setQuery={setQuery}
                    searchQueryType={searchQueryType}
                    setSearchQueryType={setSearchQueryType}
                    isGroupedSearch={isGroupedSearch}
                    setIsGroupedSearch={setIsGroupedSearch}
                />
                <div className={styles.actionButtons}>
                    <FindestButton title="Execute query" onClick={() => { onSearchClick(searchQueryType, true); }} isDisabled={isSearching ? true : false} />
                    <FindestButton title="Calculate search terms count" onClick={() => { onSearchClick(searchQueryType, false); }} buttonType={"secondary"} isDisabled={isSearching ? true : false} />
                    {isSearching &&
                        <div className={styles.loadingIndicatorContainer}>
                            <LoadingStatusIndicator size={32} status={1} />
                            <FindestButton onClick={cancelSearch} buttonType="secondary" title="Cancel search" />
                        </div>
                    }
                </div>
            </div>
            <QueryViewOptionsProvider>
                <QuerySearchResults
                    query={query}
                    setQuery={setQuery}
                    isSearching={isSearching}
                    setIsSearching={setIsSearching}
                    pageNumber={page}
                    searchResultAmount={searchAmount}
                    onPaginate={onPaginate}
                    isQueryShown={isQueryShown}
                    searchQueryType={searchQueryType}
                    setSearchQueryType={setSearchQueryType}
                    onSearchClick={onSearchClick}
                    setRunningSearchId={setRunningSearchId}
                    cancelSearch={cancelSearch} />
            </QueryViewOptionsProvider>
            {referenceModal}
        </div>
    );
};