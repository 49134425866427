export enum TopDepthMarkdownCustomBlockNameEnum {
    HighlightReference = "highlight_reference",
    ImageReference = "image_reference",
    FileReference = "file_reference",
    EntityReference = "entity_reference",
    StudyReference = "study_reference",
    IntakeSheetConfirmation = "intake_sheet_confirmation"
}

export const TopDepthMarkdownCustomBlockNameEnumValues = Object.values(TopDepthMarkdownCustomBlockNameEnum);
export const TopDepthMarkdownCustomBlockNameEnumStrings = Object.values(TopDepthMarkdownCustomBlockNameEnum).map(v => v.toString());