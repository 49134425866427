// node_modules
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
// Styles
import styles from "./askAIPopup.module.scss";

type TAskAIPopupProps = {
    onClickHandler: () => void
};

export const AskAIPopup: FC<TAskAIPopupProps> = ({ onClickHandler }: TAskAIPopupProps) => {
    // Render
    return (
        <div title="Ask IGOR" className={styles.askAIPopup} onClick={onClickHandler}>
            <FontAwesomeIcon icon={faMessageBot} />
        </div>
    );
};