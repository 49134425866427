// node_modules
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// Types
import { TConceptDTO, TIdNameTypeObjectType, TIgorCaseMigrationDTO, TMigrateCaseResultDTO, TPhaseDTO, TTechnologyDTO } from "Types";
// Controllers
import { TenantControllerSingleton } from "Controllers";
// Components
import { Checkbox, FindestButton, LoadingStatusIndicator } from "Components";
// Styles
import styles from "./caseDataSelection.module.scss";
// Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";

// Component props type
type TCaseDataSelectionProps = {
    selectedFromTenant: TIdNameTypeObjectType,
    selectedFromOrganization: TIdNameTypeObjectType,
    selectedCase: TIdNameTypeObjectType,
    selectedToTenant: TIdNameTypeObjectType
}

export const CaseDataSelection: FC<TCaseDataSelectionProps> = ({
    selectedFromTenant,
    selectedFromOrganization,
    selectedCase,
    selectedToTenant
}: TCaseDataSelectionProps) => {
    // State
    const [phases, setPhases] = useState<TPhaseDTO[]>([]);
    const [selectedPhasePerId, setSelectedPhasePerId] = useState<Map<string, TPhaseDTO>>(new Map<string, TPhaseDTO>());
    const [selectedConceptPerId, setSelectedConceptPerId] = useState<Map<number, TConceptDTO>>(new Map<number, TConceptDTO>());
    const [selectedTechnologyPerId, setSelectedTechnologyPerId] = useState<Map<number, TTechnologyDTO>>(new Map<number, TTechnologyDTO>());
    const [isMigrating, setIsMigrating] = useState<boolean>(false);

    // Hooks
    const navigate = useNavigate();

    // Logic
    const refreshPhasesAsync = useCallback(async (fromTenantId: string, organizationId: string, caseId: string): Promise<void> =>{
        // get phases
        const newPhases: TPhaseDTO[] = await TenantControllerSingleton
            .getPhasesAsync(fromTenantId, organizationId, caseId);
        
        // set new phases
        setPhases(newPhases);
    }, []);
    
    useEffect(() => {
        // get selected case phases
        (async () => {
            await refreshPhasesAsync(selectedFromTenant.id, selectedFromOrganization.id, selectedCase.id);
        })();
    }, [selectedFromTenant.id, selectedFromOrganization.id, selectedCase.id, refreshPhasesAsync]);

    const onSelectClick = (isChecked: boolean, currentPhases: TPhaseDTO[]): void => {
        // go through each phases
        for (const currentPhase of currentPhases) {
            // call on phase checkbox change
            onPhaseCheckboxChange(currentPhase, isChecked, false);
        }
    };

    const onPhaseCheckboxChange = (phase: TPhaseDTO, isChecked: boolean, withWithoutConcepts = true) => {
        // if checked
        if (isChecked) {
            // add phase to selected phases
            setSelectedPhasePerId(prevSelectedPhasePerId => {
                const newSelectedPhasePerId = new Map(prevSelectedPhasePerId);
                if (!newSelectedPhasePerId.has(phase.id)) {
                    newSelectedPhasePerId.set(phase.id, phase);
                }
                return newSelectedPhasePerId;
            });

            // add concepts to selected concepts
            phase.concepts.forEach(concept => {
                onConceptCheckboxChange(concept, true);
            });

            // add technologies without concept to selected technologies
            if (withWithoutConcepts) {
                phase.technologiesWithoutConcept.forEach(technology => {
                    onTechnologyCheckboxChange(technology, true);
                });
            }
        } else {
            // otherwise remove phase from selected phases
            setSelectedPhasePerId(prevSelectedPhasePerId => {
                const newSelectedPhasePerId = new Map(prevSelectedPhasePerId);
                if (newSelectedPhasePerId.has(phase.id)) {
                    newSelectedPhasePerId.delete(phase.id);
                }
                return newSelectedPhasePerId;
            });

            // remove concepts from selected concepts
            phase.concepts.forEach(concept => {
                onConceptCheckboxChange(concept, false);
            });

            // remove technologies without concept from selected technologies
            if (withWithoutConcepts) {
                phase.technologiesWithoutConcept.forEach(technology => {
                    onTechnologyCheckboxChange(technology, false);
                });
            }
        }
    };

    const onConceptCheckboxChange = (concept: TConceptDTO, isChecked: boolean) => {
        // if checked
        if (isChecked) {
            // add concept to selected concepts
            setSelectedConceptPerId(prevSelectedConceptPerId => {
                const newSelectedConceptPerId = new Map(prevSelectedConceptPerId);
                if (!newSelectedConceptPerId.has(concept.id)) {
                    newSelectedConceptPerId.set(concept.id, concept);
                }
                return newSelectedConceptPerId;
            });

            // add technologies to selected technologies
            concept.technologies.forEach(technology => {
                onTechnologyCheckboxChange(technology, true);
            });
        } else {
            // otherwise remove concept from selected concepts
            setSelectedConceptPerId(prevSelectedConceptPerId => {
                const newSelectedConceptPerId = new Map(prevSelectedConceptPerId);
                if (newSelectedConceptPerId.has(concept.id)) {
                    newSelectedConceptPerId.delete(concept.id);
                }
                return newSelectedConceptPerId;
            });

            // remove technologies from selected technologies
            concept.technologies.forEach(technology => {
                onTechnologyCheckboxChange(technology, false);
            });
        }
    };

    const onTechnologyCheckboxChange = (technology: TTechnologyDTO, isChecked: boolean) => {
        // if checked
        if (isChecked) {
            // add technology to selected technologies
            setSelectedTechnologyPerId(prevSelectedTechnologyPerId => {
                const newSelectedTechnologyPerId = new Map(prevSelectedTechnologyPerId);
                if (!newSelectedTechnologyPerId.has(technology.id)) {
                    newSelectedTechnologyPerId.set(technology.id, technology);
                }
                return newSelectedTechnologyPerId;
            });
        } else {
            // otherwise remove technology from selected technologies
            setSelectedTechnologyPerId(prevSelectedTechnologyPerId => {
                const newSelectedTechnologyPerId = new Map(prevSelectedTechnologyPerId);
                if (newSelectedTechnologyPerId.has(technology.id)) {
                    newSelectedTechnologyPerId.delete(technology.id);
                }
                return newSelectedTechnologyPerId;
            });
        }
    };

    const isPhaseFullySelected = (phase: TPhaseDTO, currentSelectedConceptPerId: Map<number, TConceptDTO>, currentSelectedTechnologyPerId: Map<number, TTechnologyDTO>): boolean => {
        // for each concept under the phase
        for (const concept of phase.concepts) {
            // check if the concept is selected
            if (!currentSelectedConceptPerId.has(concept.id)) {
                return false;
            }
            
            // check if all technologies under the concept are selected
            if (!isConceptFullySelected(concept, currentSelectedTechnologyPerId)) {
                return false;
            }
        }

        // check if all technologies without concept under the phase are selected
        if (!phase.technologiesWithoutConcept.every(technology => currentSelectedTechnologyPerId.has(technology.id))) {
            return false;
        }

        // if all concepts and technologies are selected, then the phase is fully selected
        return true;
    };

    const isConceptFullySelected = (concept: TConceptDTO, currentSelectedTechnologyPerId: Map<number, TTechnologyDTO>): boolean => {
        // check if all technologies under the concept are selected
        if (!concept.technologies.every(technology => currentSelectedTechnologyPerId.has(technology.id))) {
            return false;
        }

        // if all technologies are selected, then the concept is fully selected
        return true;
    };

    const isPhasePartiallySelected = (phase: TPhaseDTO, currentSelectedConceptPerId: Map<number, TConceptDTO>, currentSelectedTechnologyPerId: Map<number, TTechnologyDTO>): boolean => {
        // get if phase is fully selected
        const phaseFullySelected = isPhaseFullySelected(phase, currentSelectedConceptPerId, currentSelectedTechnologyPerId);
        // if phase is fully selected, then it is not partially selected
        if (phaseFullySelected) {
            return false;
        }
        
        // for each concept under the phase
        for (const concept of phase.concepts) { 
            // check if the concept is selected
            if (currentSelectedConceptPerId.has(concept.id)) {
                return true;
            }

            // check if at least one technology under the concept is selected
            if (isConceptPartiallySelected(concept, currentSelectedTechnologyPerId)) {
                return true;
            }
        }

        // check if at least one technology without concept under the phase is selected
        if (phase.technologiesWithoutConcept.some(technology => currentSelectedTechnologyPerId.has(technology.id))) {
            return true;
        }

        // if none of the concepts and technologies are selected, then the phase is not partially selected
        return false;
    };


    const isConceptPartiallySelected = (concept: TConceptDTO, currentSelectedTechnologyPerId: Map<number, TTechnologyDTO>): boolean => {
        // check if at least one technology under the concept is selected
        return concept.technologies.some(technology => currentSelectedTechnologyPerId.has(technology.id));
    };

    const migrateCaseAsync = async (fromTenantId: string, organizationId: string, caseId: string,
            currentSelectedPhasePerId: Map<string, TPhaseDTO>, currentSelectedConceptPerId: Map<number, TConceptDTO>, currentSelectedTechnologyPerId: Map<number, TTechnologyDTO>, toTenantId: string,
            currentPhases: TPhaseDTO[],
            currentIsPhaseFullySelected: (phase: TPhaseDTO, currentSelectedConceptPerId: Map<number, TConceptDTO>, currentSelectedTechnologyPerId: Map<number, TTechnologyDTO>) => boolean,
            currentIsPhasePartiallySelected: (phase: TPhaseDTO, currentSelectedConceptPerId: Map<number, TConceptDTO>, currentSelectedTechnologyPerId: Map<number, TTechnologyDTO>) => boolean): Promise<void> => {
        // set is migrating to true
        setIsMigrating(true);
                
        // try parse organizationId
        const parsedOrganizationId = parseInt(organizationId);
        // try parse caseId
        const parsedCaseId = parseInt(caseId);

        // safety-checks
        if ((currentSelectedPhasePerId.size === 0 && currentSelectedConceptPerId.size === 0 && currentSelectedTechnologyPerId.size === 0) ||
                !fromTenantId || !organizationId || !caseId || isNaN(parsedOrganizationId) || isNaN(parsedCaseId) || !toTenantId) {
            // set is migrating to false
            setIsMigrating(false);
            return;
        }

        // build arrays of ids
        const phaseIds: string[] = Array.from(currentSelectedPhasePerId.keys());
        const conceptIds: number[] = Array.from(currentSelectedConceptPerId.keys());
        const technologyIds: number[] = Array.from(currentSelectedTechnologyPerId.keys());

        // go over current phases
        for (const phase of currentPhases) {
            // if phase is fully or partially selected and not in currentSelectedPhasePerId
            if ((currentIsPhaseFullySelected(phase, currentSelectedConceptPerId, currentSelectedTechnologyPerId) ||
                    currentIsPhasePartiallySelected(phase, currentSelectedConceptPerId, currentSelectedTechnologyPerId)) &&
                    !currentSelectedPhasePerId.has(phase.id)) {
                // add phase to phaseIds
                phaseIds.push(phase.id);
            }
        }

        // build TIgorCaseMigrationDTO
        const igorCaseMigrationDTO: TIgorCaseMigrationDTO = {
            phaseIds,
            conceptIds,
            technologyIds,
            fromTenantId: fromTenantId,
            caseId: parsedCaseId,
            fromOrganizationId: parsedOrganizationId
        };

        // call server to migrate case async
        const migrateCaseResult: TMigrateCaseResultDTO = await TenantControllerSingleton
            .migrateCaseAsync(toTenantId, igorCaseMigrationDTO);

        // set is migrating to false
        setIsMigrating(false);

        // if migration was unsuccessful
        if (!migrateCaseResult.isSuccess) {
            // show error message
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, migrateCaseResult.message);
        } else {
            // show success message
            ToastHelperSingleton.showToast(ToastTypeEnum.Success, migrateCaseResult.message);
            // go back to admin page
            navigate("/admin", { replace: true });
        }
    };

    const isMigrateButtonDisabled = useMemo((): boolean => {
        return (selectedConceptPerId.size === 0 && selectedTechnologyPerId.size === 0) || isMigrating;
    }, [isMigrating, selectedConceptPerId.size, selectedTechnologyPerId.size]);

    // Render
    return (
        <div className={styles.caseDataSelectionContainer}>
            <h3>{"Select data you want to migrate to Universe (Select/Unselect buttons do not apply to technologies without concept):"}</h3>
            <div className={styles.actionButtons}>
                <FindestButton
                    title="Select all" 
                    onClick={() => { onSelectClick(true, phases); }} />
                <FindestButton
                    title="Deselect all"
                    onClick={() => { onSelectClick(false, phases); }}/>
                <FindestButton 
                    isDisabled={isMigrateButtonDisabled}
                    title="Migrate case" 
                    onClick={() => migrateCaseAsync(
                        selectedFromTenant.id,
                        selectedFromOrganization.id,
                        selectedCase.id,
                        selectedPhasePerId,
                        selectedConceptPerId,
                        selectedTechnologyPerId,
                        selectedToTenant.id,
                        phases,
                        isPhaseFullySelected,
                        isPhasePartiallySelected
                    )} />
                {(isMigrating) && (
                    <div>
                        <LoadingStatusIndicator size={30} status={1} />
                    </div>
                )}
            </div>
            <div className={styles.phasesContainer}>
                {phases.map((phase: TPhaseDTO) => {
                    return (
                        <div key={phase.id} className={styles.phase}>
                            <label className={styles.phaseNameContainer}>
                                <Checkbox
                                    theme="black"
                                    isChecked={isPhaseFullySelected(phase, selectedConceptPerId, selectedTechnologyPerId) || isPhasePartiallySelected(phase, selectedConceptPerId, selectedTechnologyPerId)}
                                    isPartiallySelected={isPhasePartiallySelected(phase, selectedConceptPerId, selectedTechnologyPerId)}
                                    onCheckboxChange={(isChecked: boolean) => onPhaseCheckboxChange(phase, isChecked)}/>
                                <span className={styles.phaseName}>{phase.name}</span>
                            </label>
                            <div className={styles.phaseConcepts}>
                                {phase.concepts.map((concept: TConceptDTO) => {
                                    return (
                                        <div key={concept.id} className={styles.concept}>
                                            <label className={styles.conceptNameContainer}>
                                                <Checkbox
                                                    theme="black" 
                                                    isChecked={selectedConceptPerId.has(concept.id)}
                                                    onCheckboxChange={(isChecked: boolean) => onConceptCheckboxChange(concept, isChecked)}/>
                                                <span className={styles.conceptName}>{concept.name}</span>
                                            </label>
                                            <div className={styles.technologies}>
                                                {concept.technologies.map((technology: TTechnologyDTO) => {
                                                    return (
                                                        <label className={styles.technologyNameContainer} key={technology.id}>
                                                            <Checkbox
                                                                theme="black" 
                                                                isChecked={selectedTechnologyPerId.has(technology.id)}
                                                                onCheckboxChange={(isChecked: boolean) => onTechnologyCheckboxChange(technology, isChecked)}/>
                                                            <span className={styles.technologyName}>{technology.name}</span>
                                                        </label>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {phase.technologiesWithoutConcept.length > 0 && (
                                <div className={styles.technologies}>
                                    <div className={styles.technologiesWithoutConceptTitle}>Technologies without concept</div>
                                    {phase.technologiesWithoutConcept.map((technology: TTechnologyDTO) => {
                                        return (
                                            <label className={styles.technologyNameContainer} key={technology.id}>
                                                <Checkbox
                                                    theme="black"
                                                    isChecked={selectedTechnologyPerId.has(technology.id)}
                                                    onCheckboxChange={(isChecked: boolean) => onTechnologyCheckboxChange(technology, isChecked)}/>
                                                <span className={styles.technologyName}>{technology.name}</span>
                                            </label>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};