// node_modules
import { FC } from "react";
// Styles
import styles from "./magicLinkMessage.module.scss";

export type TMagicLinkMessageProps = {
    email: string
};

export const MagicLinkMessage: FC<TMagicLinkMessageProps> = ({email}: TMagicLinkMessageProps) => {
    // Render
    return (
        <div className={styles.magicLinkMessage}>
            <h1 className={styles.title}>Check your inbox</h1>
            <p>We just emailed a &ldquo;magic link&rdquo; to {email}.</p>
            <p>Click the link and you will be signed in.</p>
            <br/>
            <p>This link will expire in 30 minutes and is valid for one-time use.</p>
        </div>
    );
};