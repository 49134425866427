// node_modules
import { FC } from "react";
// Components
import { EntityLikeImageContainer } from "Components";
// Types
import { TImageDTO } from "Types";
// Styles
import "Styles/documentHighlights.scss";
import styles from "./documentImages.module.scss";
// Interfaces
import { IDocumentDetails } from "Interfaces";

// Component props type
type TDocumentImagesProps = {
    document: IDocumentDetails,
    onImageDeletedAsync: (image: TImageDTO) => Promise<void>
}

// Component
export const DocumentImages: FC<TDocumentImagesProps> = ({ document, onImageDeletedAsync }) => {
    // Render
    return (
            document.images.length > 0 ? (
                <div className={styles.rightContentItem}>
                    <h3 className={styles.rightContentItemTitle}>Images</h3>
                    <EntityLikeImageContainer images={document.images}
                        onDeleteSelectedImageClickHandlerAsync={onImageDeletedAsync} />
                </div>
            )
        :
            null
    );
};