// node_modules
import useResizeObserver from "@react-hook/resize-observer";
import { EditorState } from "prosemirror-state";
import { useNavigate } from "react-router-dom";
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
// Components
import { ContextedEditorContent, MarkdownItComponent, RatingsPopover, findestMarkdownSerializer } from "Components";
// Components
import { ReferencePopover } from "../../Modals/ReferenceModal";
// Helpers
import { ObjectTypeHelperSingleton, ProseMirrorPluginHelperSingleton, ToastHelperSingleton } from "Helpers";
// Styles
import styles from "./editableMarkdown.module.scss";
// Enums
import { CustomDOMAttributes, CustomDOMTag, ObjectTypeEnum, SpecialBlockClassNameEnum, ToastTypeEnum } from "Enums";
// Contexts
import { AuthContext, CollaborationContext, EditorContext, EditorHeadersContext, EditorReferencesContext } from "Providers";
// Controllers
import { RatingControllerSingleton, StudyControllerSingleton } from "Controllers";
// Types
import { TGetRatingOfObjectByIdDTO } from "Types";
// Hooks
import { EditorConstants } from "Constants";
import { useObjectReferenceModal } from "Hooks";

type TContextedEditableMarkdownProps = {
    idEdited: string,
    typeEdited: ObjectTypeEnum,
    source: string,
    noSourcePlaceholder: string,
    onSourceChange: (newValue: string) => void,
    onForceSourceChange?: (newValue: string) => void,
    extraClassNames?: { proseMirrorEditor?: string, wysiwygContent?: string },
    showPlaceholderInEditMode?: boolean,
    doForceViewMode?: boolean
}

export const ContextedEditableMarkdown: FC<TContextedEditableMarkdownProps> = ({
        idEdited, typeEdited, source, noSourcePlaceholder, onForceSourceChange,
        onSourceChange, extraClassNames, showPlaceholderInEditMode, doForceViewMode
    }: TContextedEditableMarkdownProps) => {
    // Ref
    const editorContainerRef = useRef<HTMLDivElement>(null);
    // TODO: Guillaume will fix this
    const objectTypedEditedRef = useRef<ObjectTypeEnum | undefined>(undefined);
    const objectIdEditedRef = useRef<string | undefined>(undefined);

    // Contexts
    const {
        updateEditorViewObjectSource, 
        updateOnSourceChangeCallback,
        updateEditorViewStatePlugins,
        updateEditorView,
        forceUpdateEditorViewSource,
        ratingsPopoverProps,
        showRatingsPopover,
        hideRatingsPopover,
        onNewAverageRating
    } = useContext(EditorContext);
    const { updateHeaderPositions } = useContext(EditorHeadersContext);
    const { isEditModeOn, showEditorMenu, hideEditorMenu, objectIdEdited, objectTypeEdited } = useContext(CollaborationContext);
    const { auth, isUserExternal } = useContext(AuthContext);
    const { referencePopoverProps, addInlineListeners, showReferencePopover, hideReferencePopover } = useContext(EditorReferencesContext);
    
    // State
    const [markdown, setMarkdown] = useState<string>(source);

    // Memos
    const isViewMode = useMemo(() => {
        return ((doForceViewMode !== undefined && doForceViewMode) || !isEditModeOn);
    }, [doForceViewMode, isEditModeOn]);

    
    const onNewAverageRatingHandler = useCallback((forSourceId: string, forTargetId: string, newCount: number, newScore: number, newIsRatingNeeded: boolean): void => {
        // if is view mode
        if(isViewMode) {
            // call on new average rating (do not call on source change callback)
            onNewAverageRating(forSourceId, forTargetId, newCount, newScore, newIsRatingNeeded, false, (newState: EditorState) => {
                // and then update the markdown source for MarkdownItComponent
                let updatedMarkdown = findestMarkdownSerializer.serialize(newState.doc);
                // remove remove me tags
                updatedMarkdown = updatedMarkdown.replaceAll(`${EditorConstants.OPEN_REMOVE_ME_TAG}`, "");
                updatedMarkdown = updatedMarkdown.replaceAll(`${EditorConstants.CLOSE_REMOVE_ME_TAG}`, "");

                // set markdown
                setMarkdown(updatedMarkdown);
            });
        } else {
            // call on new average rating (do call on source change callback)
            onNewAverageRating(forSourceId, forTargetId, newCount, newScore, newIsRatingNeeded, true);
        }
    }, [isViewMode, onNewAverageRating]);

    const onCloseModal = useCallback(async (referenceId?: string | undefined): Promise<void> => {
        // it could be that the user changed the rating of the object, in the context of the object edited, in the reference modal
        // so if reference modal object id and type are defined
        if (objectIdEditedRef.current && objectTypedEditedRef.current && referenceId) {
            // get rating of the object, in the context of the object edited, opened in the reference modal 
            const ratingOfObjectById: TGetRatingOfObjectByIdDTO | undefined = await RatingControllerSingleton
                .getRatingAsync(objectIdEditedRef.current, ObjectTypeHelperSingleton.getObjectTypeDisplayName(objectTypedEditedRef.current).toLowerCase(), referenceId);

            // if rating of object by id is defined
            if (ratingOfObjectById) {
                // update average rating in reporting
                onNewAverageRatingHandler(
                    objectIdEditedRef.current, 
                    referenceId, 
                    ratingOfObjectById.overview.entities.averageRating.count, 
                    ratingOfObjectById.overview.entities.averageRating.score,
                    !ratingOfObjectById.overview.entities.isRatedByCurrentUser
                );
            }
        }
    }, [onNewAverageRatingHandler]);

    // Hooks
    const navigate = useNavigate();

    // Custom hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal(undefined, onCloseModal);

    // Logic
    useEffect(() => {
        // when source changes
        // set markdown
        setMarkdown(source);
    }, [source]);

    useEffect(() => {
        // show editor
        showEditorMenu(idEdited, typeEdited);

        // when unmouting
        return () => {
            // hide editor
            hideEditorMenu();
        };
    }, [idEdited, showEditorMenu, hideEditorMenu, typeEdited]);

    useEffect(() => {
        // update editor view markdown
        updateEditorViewObjectSource(markdown, objectIdEdited, objectTypeEdited);
    }, [updateEditorViewObjectSource, markdown, objectIdEdited, objectTypeEdited]);

    useEffect(() => {
        // update on source change callback
        updateOnSourceChangeCallback(onSourceChange);
    }, [updateOnSourceChangeCallback, onSourceChange]);
    
    useEffect(() => {
        // get editor plugins
        const plugins = ProseMirrorPluginHelperSingleton.getDefault(
            auth,
            showPlaceholderInEditMode ? noSourcePlaceholder : "", 
            updateEditorView,
            auth.userEmail,
            typeEdited === ObjectTypeEnum.Study ? idEdited : undefined,
            showReferencePopover,
            hideReferencePopover,
            showRatingsPopover,
            hideRatingsPopover,
            navigate
        );
        
        // update editor view state plugins
        updateEditorViewStatePlugins(plugins);
    }, [auth, auth.userEmail, hideRatingsPopover, hideReferencePopover, idEdited, navigate, noSourcePlaceholder, showPlaceholderInEditMode, showRatingsPopover, showReferencePopover, typeEdited, updateEditorView, updateEditorViewStatePlugins]);

    useEffect(() => {
        updateHeaderPositions();
    }, [updateHeaderPositions]);

    useEffect(() => {
        objectTypedEditedRef.current = objectTypeEdited;
    }, [objectTypeEdited]);
    useEffect(() => {
        objectIdEditedRef.current = objectIdEdited;
    }, [objectIdEdited]);

    useResizeObserver(editorContainerRef, updateHeaderPositions);

    const confirmIntakeSheet = useCallback(async () => {
        if(!objectIdEditedRef.current) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not determine the study to confirm.");
            return;
        }
        const newSource = await StudyControllerSingleton.updateStudyIntakeSheetConfirmationAsync(
            objectIdEditedRef.current, true, true);       

        if(!newSource) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not confirm the intake sheet.");
            return;
        }

        if(onForceSourceChange) onForceSourceChange(newSource);
        onSourceChange(newSource);
        forceUpdateEditorViewSource(newSource);
    }, [onForceSourceChange, onSourceChange, forceUpdateEditorViewSource]);

    const unconfirmIntakeSheet = useCallback(async () => {
        if(!objectIdEditedRef.current) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not determine the study to remove confirmation from.");
            return;
        }
        const newSource = await StudyControllerSingleton
            .updateStudyIntakeSheetConfirmationAsync(objectIdEditedRef.current, false, true);       

        if(!newSource) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not remove confirmation the intake sheet.");
            return;
        }

        if(onForceSourceChange) onForceSourceChange(newSource);
        onSourceChange(newSource);
        forceUpdateEditorViewSource(newSource);
    }, [onSourceChange, forceUpdateEditorViewSource, onForceSourceChange]);

    const addIntakeSheetListeners = useCallback(() => {
        // If the editor is in edit mode or the object is not a study or if editor container ref 
        // is undefined or if the object id is undefined
        if (!isViewMode || !editorContainerRef.current || objectTypedEditedRef.current !== ObjectTypeEnum.Study || !objectIdEditedRef.current) {
            // then return, do nothing
            return;
        }

        const intakeSheetButtons = editorContainerRef.current.querySelectorAll("intake-sheet-confirmation-not-accepted");

        intakeSheetButtons.forEach((intakeSheetButton) => {
            const intakeSheetButtonElement = intakeSheetButton as HTMLButtonElement;

            intakeSheetButtonElement.onclick = confirmIntakeSheet;
        });
    
        const intakeSheetRemovalButtons = editorContainerRef.current.querySelectorAll("intake-sheet-confirmation-accepted");

        intakeSheetRemovalButtons.forEach((intakeSheetRemovalButton) => {
            const intakeSheetRemovalButtonElement = intakeSheetRemovalButton as HTMLButtonElement;

            intakeSheetRemovalButtonElement.onclick = unconfirmIntakeSheet;
        });

    }, [confirmIntakeSheet, isViewMode, unconfirmIntakeSheet]);

    const addTableContainers = useCallback((node: Node) => {
        // if object id or object type is undefined
        if (!objectIdEditedRef.current || !objectTypedEditedRef.current) {
            // then return, do nothing
            return;
        }

        // convert node to HTML element
        const nodeElement = node as HTMLElement;

        // get all tables of the node
        const tables: NodeListOf<HTMLTableElement> = nodeElement.querySelectorAll("table");

        // go over each table
        tables.forEach((table: HTMLTableElement) => {
            // if table has parent and parent is CustomDOMTag.TableContainer
            if (table.parentElement && table.parentElement.classList.contains(`${SpecialBlockClassNameEnum.TableContainer}`)) {
                // then return, do nothing
                return;
            } else if (table.parentElement) {
                // otherwise, wrap table in a div
                const tableContainer = document.createElement(`${CustomDOMTag.TableContainer}`);
                tableContainer.classList.add(`${SpecialBlockClassNameEnum.TableContainer}`);
                table.parentElement.replaceChild(tableContainer, table);
                tableContainer.appendChild(table);
            }
        });
    }, []);

    const addMeterContainers = useCallback((node: Node) => {
        // if object id or object type is undefined
        if (!objectIdEditedRef.current || !objectTypedEditedRef.current) {
            // then return, do nothing
            return;
        }
        
        // convert node to HTML element
        const nodeElement = node as HTMLElement;

        // get all meter of the node
        const meters: NodeListOf<HTMLMeterElement> = nodeElement.querySelectorAll("meter");

        // go over each meter
        meters.forEach((meter: HTMLMeterElement) => {
            // if meter has parent and parent is CustomDOMTag.MeterContainer
            if (meter.parentElement && meter.parentElement.classList.contains(`${SpecialBlockClassNameEnum.MeterContainer}`)) {
                // then return, do nothing
                return;
            } else if (meter.parentElement) {
                // get CustomDOMAttributes.StarsIsRatingNeeded attribute
                const isRatingNeeded: string | null = meter.getAttribute(`${CustomDOMAttributes.StarsIsRatingNeeded}`);
                const meterContainerClasses: string[] = [`${SpecialBlockClassNameEnum.MeterContainer}`];
                // if is rating needed
                if (isRatingNeeded === "true" && !isUserExternal) {
                    // add class to meter container
                    meterContainerClasses.push(`${SpecialBlockClassNameEnum.RatingIsNeeded}`);
                }

                // otherwise, wrap meter in a div
                const meterContainer = document.createElement(`${CustomDOMTag.MeterContainer}`);
                // go through each class
                meterContainerClasses.forEach((meterContainerClass: string) => {
                    // add class to meter container
                    meterContainer.classList.add(meterContainerClass);
                });
                meter.parentElement.replaceChild(meterContainer, meter);
                meterContainer.appendChild(meter);
                const meterTextNode = document.createTextNode(`${meter.getAttribute(`${CustomDOMAttributes.StarsNumberOfRaters}`)}`);
                meterContainer.appendChild(meterTextNode);
            }
        });
    }, [isUserExternal]);

    const addHasInlineStarsClass = useCallback((node: Node) => {
        // convert node to HTML element
        const nodeElement = node as HTMLElement;

        // get all td elements of the node
        const tdElements: NodeListOf<HTMLTableCellElement> = nodeElement.querySelectorAll("td");

        // go over each td element
        tdElements.forEach(tdElement => {
            const doesTdElementHaveHasInlineStarsClass = tdElement.classList.contains(SpecialBlockClassNameEnum.HasInlineStars);
            // check if td element has meter element inside
            if (tdElement.querySelector("meter") && !doesTdElementHaveHasInlineStarsClass) {
                // add editor-has-inline-stars class
                tdElement.classList.add(SpecialBlockClassNameEnum.HasInlineStars);
            }
        });
    }, []);

    const contentMutationCallback = useCallback((mutations: MutationRecord[]) => {
        // go through each mutation
        mutations.forEach((mutation: MutationRecord) => {
            // add inline listeners
            addInlineListeners(mutation.target);

            // add table containers
            addTableContainers(mutation.target);

            // add meter containers
            addMeterContainers(mutation.target);

            // add has inline stars class
            addHasInlineStarsClass(mutation.target);
        });

        addIntakeSheetListeners();
    }, [addIntakeSheetListeners, addInlineListeners, addTableContainers, addMeterContainers, addHasInlineStarsClass]);

    const onMouseLeave = useCallback(() => {
        // hide reference popover
        hideReferencePopover();
    }, [hideReferencePopover]);

    // Render
    return (
        <div onMouseLeave={onMouseLeave}>
            {isViewMode ?
                <div ref={editorContainerRef} className={[styles.editableMarkdownContainer].join(" ")}>
                    <MarkdownItComponent 
                        source={markdown}
                        noSourcePlaceholder={noSourcePlaceholder}
                        contentMutationCallback={contentMutationCallback} 
                        />
                </div>
            : 
                <div ref={editorContainerRef} className={[styles.editableMarkdownContainer, styles.isInEditMode].join(" ")}>
                    <ContextedEditorContent
                        extraClassNames={extraClassNames} />
                </div>
            }
            {referencePopoverProps.referenceElement && referencePopoverProps.isOpen && (
                <ReferencePopover 
                    {...referencePopoverProps}
                    setModalProps={setReferenceModalProps}
                    hideReferencePopover={hideReferencePopover}
                />
            )}
            {referenceModal}
            <RatingsPopover          
                {...ratingsPopoverProps}
                forSourceId={objectIdEdited}
                forSourceType={objectTypeEdited}
                onNewAverageRating={onNewAverageRatingHandler}
                hideRatingsPopover={hideRatingsPopover} />
        </div>
    );
};

