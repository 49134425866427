// Enums
import { QuerySortOptionsEnum } from "Enums";

export class QuerySortOptionsHelper {
    public getQuerySortOptionSearchValue(querySortOption: QuerySortOptionsEnum): string {
        switch (querySortOption) {
            case QuerySortOptionsEnum.PublicationDate:
                return "pubdatedesc";
            case QuerySortOptionsEnum.CitationScore:
                return "impactfactor";
            case QuerySortOptionsEnum.Relevance:
                return "reldesc";
            default:
                return "reldesc";
        }
    }

    public getQuerySortOptionDisplayValue(querySortOption: QuerySortOptionsEnum): string {
        switch (querySortOption) {
            case QuerySortOptionsEnum.PublicationDate:
                return "Publication Date";
            case QuerySortOptionsEnum.CitationScore:
                return "Citation Score";
            case QuerySortOptionsEnum.Relevance:
                return "Relevance";
            default:
                return "Relevance";
        }
    }
}

export const QuerySortOptionsHelperSingleton = new QuerySortOptionsHelper();