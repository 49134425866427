// node_modules
import { FC } from "react";
// Types
import { TListItem } from "Types";
// Enums
import { ObjectTypeEnum } from "Enums";
// Components
import { ListItem } from "Components";
// Helpers
import { EntityTypeHelperSingleton } from "Helpers";
// Interfaces
import { IEntityDTO } from "Interfaces";

type TEntityItemProps = {
    entity: IEntityDTO,
    isSelected?: boolean,
    onCheckboxChange?: (isChecked: boolean, title: string) => void,
    onDeleteEntityClick?: (entityToDelete: IEntityDTO) => void,
    onLinkEntityClick?: (entity: IEntityDTO) => void,
    onUnlinkEntityClick?: (entity: IEntityDTO) => void,
    onItemClickOverride?: (entity: IEntityDTO) => void,
    hideMoreActionsDropdownButton?: boolean,
};

export const EntityItem: FC<TEntityItemProps> = ({entity, isSelected, onCheckboxChange, 
    onDeleteEntityClick, onLinkEntityClick, onUnlinkEntityClick, onItemClickOverride,
    hideMoreActionsDropdownButton
}: TEntityItemProps) => {
    const entityListItem: TListItem = {
        id: entity.id,
        title: entity.title,
        description: entity.description,
        dateAdded: entity.dateAdded,
        objectType: ObjectTypeEnum.Entity,
        topText: EntityTypeHelperSingleton.getEntityTypeDisplayName(entity.type, entity.customTypeName),
        images: entity.images,
        linkedCounts: entity.linkedCounts,
        createdByUsername: entity.createdByUsername
    };

    return (
        <ListItem<IEntityDTO> isSelected={isSelected} itemObject={entity} 
            onCheckboxChange={onCheckboxChange} onDeleteItemClick={onDeleteEntityClick}
            onLinkItemClick={onLinkEntityClick} listItem={entityListItem}
            onUnlinkItemClick={onUnlinkEntityClick} onItemClickOverride={onItemClickOverride}
            hideMoreActionsDropdownButton={hideMoreActionsDropdownButton}
        />
    );
};