// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";

// Define custom element "file-reference-link-extension"
export class FileReferenceLinkExtension extends HTMLElement {
    constructor() {
        super();
        this.addEventListener("click", ProseMirrorHelperSingleton.handleClickEvent);
    }
}
