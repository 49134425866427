// node_modules
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, FC, FocusEvent, MouseEvent, SetStateAction, useCallback, useState } from "react";
// Types
import { TEnvironmentVariableDTO } from "Types";
// Components
import { EditableLabelledCheckbox, SearchTermHitsCount, Checkbox, EditableTextInput } from "Components";
import { SelectSearchPriority } from "../SearchPriority/SelectSearchPriority";
import { Synonyms } from "../Synonyms/Synonyms";
import { SynonymsButton } from "../Synonyms/SynonymsButton";
// Enums
import { SearchPriorityEnum, SynonymTypeEnum } from "Enums";
// Styles
import styles from "./environmentVariable.module.scss";
// Interfaces
import { IQueryDTO } from "Interfaces";

type TEnvironmentVariableProps = {
    environmentVariable: TEnvironmentVariableDTO,
    deleteEnvironmentVariableAsync: (environmentVariableId: number) => Promise<void>,
    updateEnvironmentVariableValueAsync: (environmentVariableId: number, updatedEnvironmentVariableValue: string) => Promise<void>,
    updateEnvironmentVariableSearchPriorityAsync: (environmentVariableId: number, environmentVariableUpdatedSearchPriority: SearchPriorityEnum) => Promise<void>,
    query: IQueryDTO,
    setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>,
    hitsCount: number,
    isSearchTermPriorityDropdown: boolean
}

export const EnvironmentVariable: FC<TEnvironmentVariableProps> = ({ environmentVariable,
    deleteEnvironmentVariableAsync, updateEnvironmentVariableValueAsync,
    updateEnvironmentVariableSearchPriorityAsync, query, setQuery, hitsCount, isSearchTermPriorityDropdown }: TEnvironmentVariableProps) => {
    // State
    const [isSynonymsSectionShown, setIsSynonymsSectionShown] = useState<boolean>(false);
    const [clickedEnvironmentVariable, setClickedEnvironmentVariable] = useState<EventTarget | null>(null);
    const [synonymsHelperValue, setSynonymsHelperValue] = useState<string>(environmentVariable.environment);
    const [synonymsHelperId, setSynonymsHelperId] = useState<number>(environmentVariable.id);
    const [showSynonyms, setShowSynonyms] = useState<boolean>(false);

    let timeout: NodeJS.Timeout;

    // Ref
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);

    //Logic
    const openSynonymsSection = useCallback((event: MouseEvent): void => {
        // if the keyword is enabled, show synonyms section
        if (environmentVariable.searchPriority !== SearchPriorityEnum.Disabled) setIsSynonymsSectionShown(true);
        // set clicked environment variable
        setClickedEnvironmentVariable(event.target);
        // set synonyms helper value
        setSynonymsHelperValue(environmentVariable.environment);
        // set synonyms helper id
        setSynonymsHelperId(environmentVariable.id);
    }, [environmentVariable.environment, environmentVariable.id, environmentVariable.searchPriority]);

    const closeSynonymsSection = useCallback((): void => {
        setIsSynonymsSectionShown(false);
    }, []);

    const handleOnCheckboxChange = useCallback((checked: boolean) => {
        if (checked) {
            updateEnvironmentVariableSearchPriorityAsync(environmentVariable.id, SearchPriorityEnum.Should);
        } else {
            updateEnvironmentVariableSearchPriorityAsync(environmentVariable.id, SearchPriorityEnum.Disabled);
            setIsSynonymsSectionShown(false);
        }
    }, [environmentVariable.id, updateEnvironmentVariableSearchPriorityAsync]);

    const handleEnvironmentVariableChange = useCallback((environmentVariableUpdatedValue: string) => {
        updateEnvironmentVariableValueAsync(environmentVariable.id, environmentVariableUpdatedValue);
        // set synonyms helper value
        setSynonymsHelperValue(environmentVariableUpdatedValue);
    }, [environmentVariable.id, updateEnvironmentVariableValueAsync]);

    const onMouseEnter = () => {
        if (environmentVariable.synonyms.length > 0) {
            timeout = setTimeout(() => setShowSynonyms(true), 1000);
        }
    };

    const onMouseLeave = () => {
        if (environmentVariable.synonyms.length > 0) {
            if (timeout) {
                clearTimeout(timeout);
            }
            setShowSynonyms(false);
        }
    };

    return (
        <div className={`${styles.findestKeywordContainer} ${isSearchTermPriorityDropdown ? styles.searchTermPriorityDropdownStyle : styles.searchTermPriorityButtonStyle}`}>
            <div className={environmentVariable.searchPriority === SearchPriorityEnum.Disabled ? [styles.findestKeyword, styles.findestKeywordDisabled].join(" ") : styles.findestKeyword}>
                <div className={styles.priorityContainer}>
                    <Checkbox
                        isChecked={environmentVariable.searchPriority !== SearchPriorityEnum.Disabled ? true : false}
                        onCheckboxChange={handleOnCheckboxChange}
                        title={environmentVariable.searchPriority !== SearchPriorityEnum.Disabled ? "Disable keyword" : "Enable keyword"}
                    />
                    <div className={styles.prioritySelection}>
                        <SelectSearchPriority 
                            searchPriority={environmentVariable.searchPriority} 
                            onChange={(newSearchPriority: SearchPriorityEnum) => updateEnvironmentVariableSearchPriorityAsync(environmentVariable.id, newSearchPriority)} 
                            isSearchTermPriorityDropdown={isSearchTermPriorityDropdown}/>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.valueContainer}>
                    <EditableTextInput 
                        label={environmentVariable.environment} 
                        placeholder={"Keyword"}
                        isReadonly={false}
                        onLabelChange={handleEnvironmentVariableChange} 
                        onInputFocus={(event: FocusEvent) => { event.preventDefault(); event.stopPropagation(); setSynonymsHelperId(environmentVariable.id); setSynonymsHelperValue(environmentVariable.environment); setClickedEnvironmentVariable(event.currentTarget); } }/>
                    <div className={styles.synonymsButtonContainer} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={setReferenceElement}>
                        <SynonymsButton
                            synonymsCount={environmentVariable.synonyms.length}
                            handleOpenSynonymsSection={openSynonymsSection}
                        />
                    </div>
                </div>
                <div className={styles.separator}></div>
                <SearchTermHitsCount isDisabled={environmentVariable.searchPriority === SearchPriorityEnum.Disabled} hitsCount={hitsCount} />
                <div className={styles.deleteContainer} title="Delete keyword" onClick={() => deleteEnvironmentVariableAsync(environmentVariable.id)}> 
                    <FontAwesomeIcon icon={faTrashAlt} />
                </div>
            </div>
                <Synonyms 
                    referenceElement={referenceElement}
                    synonyms={environmentVariable.synonyms} 
                    synonymsType={SynonymTypeEnum.Environment} 
                    fieldId={environmentVariable.id} 
                    synonymsHelperValue={synonymsHelperValue}
                    setSynonymsHelperValue={setSynonymsHelperValue}    
                    synonymsHelperId={synonymsHelperId}
                    setSynonymsHelperId={setSynonymsHelperId}               
                    query={query}
                    setQuery={setQuery} 
                    clickedFieldElement={clickedEnvironmentVariable} 
                    doOpenSynonymsHelperPopup={isSynonymsSectionShown}
                    showSynonymsPopover={showSynonyms}
                    onMouseEnter={() => setShowSynonyms(true)}
                    onMouseLeave={onMouseLeave}
                    handleCloseSynonymsModal={closeSynonymsSection}
                />
        </div>
    );
};