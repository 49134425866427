// Enums
import { MathematicalOperationEnum } from "Enums";

export class MathematicalOperationHelper {
    public getMathematicalOperationDisplayName(mathematicalOperation: MathematicalOperationEnum): string {
        switch (mathematicalOperation) {
            case MathematicalOperationEnum.GreaterThan:
                return "Greater than";      
            case MathematicalOperationEnum.LessThan:
                return "Less than";
            default:
                return "Greater than";
        }
    }
}

export const MathematicalOperationHelperSingleton = new MathematicalOperationHelper();