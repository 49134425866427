// node_modules
import { FC, useContext, useEffect, useState } from "react";
// Components
import { SavedDocuments } from "Components";
// Styles
import styles from "./inbox.module.scss";
// Types
import { TDocumentsDTO, TOption } from "Types";
// Controllers
import {
    SavedDocumentControllerSingleton,
    SavedPatentControllerSingleton,
    SavedScienceArticleControllerSingleton,
    SavedWeblinkControllerSingleton
} from "Controllers";
// Enums
import { LinkStatusEnum, OrderByEnum, SavedDocumentTypeEnum, SortTypeEnum } from "Enums";
// Helpers
import { DocumentTypeHelperSingleton, LinkStatusHelperSingleton, LogHelperSingleton, SavedFiltersHelperSingleton } from "Helpers";
// Contexts
import { DocumentContext } from "Providers";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";

export const Inbox: FC = () => {
    // Context
    const { refreshDocumentsNotLinkedCount } = useContext(DocumentContext);
    // State
    const [savedDocuments, setSavedDocuments] = useState<ISavedDocumentDTO[]>([]);
    const [totalSavedDocumentsCount, setTotalSavedDocumentsCount] = useState<number>(0);

    // Logic
    useEffect(() => {
        // get saved filters in local storage
        const savedFilters: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[] =
            SavedFiltersHelperSingleton.getDocumentsFilters();
        
        refreshDocumentsAsync(
            undefined,
            savedFilters,
            SortTypeEnum.Newest
        );

        // log 
        LogHelperSingleton.log("DisplayInbox");
    }, []);

    useEffect(() => {
        (async () => {
            await refreshDocumentsNotLinkedCount();
        })();
    }, [refreshDocumentsNotLinkedCount]);

    const refreshDocumentsAsync = async (fromDate: Date | undefined,
                filterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[], sortType: SortTypeEnum): Promise<void> => {
        const newDocuments: TDocumentsDTO = await SavedDocumentControllerSingleton.getMyAsync(
            sortType === SortTypeEnum.Oldest ? OrderByEnum.Ascending : OrderByEnum.Descending,
            fromDate,
            DocumentTypeHelperSingleton.getSelectedFilterOptions(filterOptions),
            LinkStatusHelperSingleton.getIsNotLinkedSelected(filterOptions)
        );

        if (newDocuments && newDocuments.documents) {
            setSavedDocuments(newDocuments.documents);
            setTotalSavedDocumentsCount(newDocuments.totalCount);
        } else {
            setSavedDocuments([]);
            setTotalSavedDocumentsCount(0);
        }
    };

    const deleteSavedDocumentAsync = async (savedDocumentsToDelete: ISavedDocumentDTO[]): Promise<void> => {
        for (const savedDocumentToDelete of savedDocumentsToDelete) {
            switch (savedDocumentToDelete.savedDocumentType) {
                case SavedDocumentTypeEnum.ScienceArticle:
                    await SavedScienceArticleControllerSingleton.deleteMyAsync(savedDocumentToDelete.id);
                    break;
                case SavedDocumentTypeEnum.MagPatent:
                case SavedDocumentTypeEnum.UsPatent:
                    await SavedPatentControllerSingleton.deleteMyAsync(savedDocumentToDelete.id);
                    break;
                case SavedDocumentTypeEnum.Weblink:
                    await SavedWeblinkControllerSingleton.deleteMyAsync(savedDocumentToDelete.id);
                    break;
                default:
                    break;
            }   
        }

        // log
        LogHelperSingleton.log("RemoveDocument(s)FromInbox");
    };

    // Render
    return (
        <div className={styles.inboxContainer}>
            <SavedDocuments 
                documents={savedDocuments}
                doUseSavedFilters={true}
                totalDocumentsCount={totalSavedDocumentsCount}
                refreshDocumentsAsync={refreshDocumentsAsync}
                deleteSavedDocumentAsync={deleteSavedDocumentAsync} 
                isInboxSection={true} 
            />
        </div>
    );
};