// node_modules
import { FC, useRef, useState } from "react";
// Components
import { Tooltip } from "Components";
// Types
import { TUserIconSize } from "Types";
// Helpers
import { ColorHelperSingleton } from "Helpers";
// Styles
import styles from "./userIcon.module.scss";

type TUserIconProps = {
    email: string,
    extraClassName?: string,
    size?: TUserIconSize,
    showUserIconTooltip?: boolean
}

export const UserIcon: FC<TUserIconProps> = ({ email, extraClassName = "", size, showUserIconTooltip }: TUserIconProps) => {
    const userIconBackgroundColor = ColorHelperSingleton.getPastelColorFromString(email);
    // State
    const [isUserIconTooltipOpen, setIsUserIconTooltipOpen] = useState<boolean>(false);

    // Refs
    const userIconContainerRef = useRef<HTMLDivElement>(null);
    
    // Render
    return (
        <>
            <div
                style={{backgroundColor: userIconBackgroundColor}}
                className={`${styles.userIconContainer} ${extraClassName} ${size ? styles[size] : ""}`}
                ref={userIconContainerRef}
                {...(showUserIconTooltip ? {
                    onMouseEnter: () => setIsUserIconTooltipOpen(true),
                    onMouseLeave: () => setIsUserIconTooltipOpen(false)
                }: {})}
            >
                {email.substring(0, 1).toUpperCase()}
            </div>
            {isUserIconTooltipOpen && <Tooltip tooltipText={email} referenceEl={userIconContainerRef.current} isOpen={isUserIconTooltipOpen} />}
        </>
    );
};
