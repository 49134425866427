// React
import { FC, useContext, useEffect, useState } from "react";
// Font awesome
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";
// Controllers
import { PinControllerSingleton } from "Controllers";
import { CollaborationContext, PinnedContext } from "Providers";
import { FindestButton } from "Components";
// Styles
import styles from "./objectPinButton.module.scss";
import { LogHelperSingleton } from "Helpers";

export const ObjectPinButton: FC = () => {
    // Contexts
    const { objectIdEdited, objectTypeEdited } = useContext(CollaborationContext);
    const { refreshPins } = useContext(PinnedContext);

    // State
    const [isPinned, setIsPinned] = useState(false);
    
    // useEffect
    useEffect(() => {
        (async () => {
            if(!objectIdEdited) return;
            setIsPinned(await PinControllerSingleton.isPinnedAsync(objectIdEdited));
        })();
    }, [objectIdEdited]);

    const onPinClick = async () => {
        if(!objectIdEdited || !objectTypeEdited) return;

        let isSuccess = false;
        // Add or remove pin based on current state
        if(isPinned) {
            // log
            LogHelperSingleton.log("RemovePinFromObject");
            isSuccess = await PinControllerSingleton.removeAsync(objectIdEdited);
        } else {
            // log
            LogHelperSingleton.log("AddPinToObject");
            isSuccess = await PinControllerSingleton.addAsync(objectIdEdited, objectTypeEdited);
        }

        // On success toggle the pinned state
        if(isSuccess) setIsPinned(!isPinned);

        // Refresh the pins in the pinned provider
        await refreshPins();
    };
    
    return (
        <FindestButton
            extraClassName={`${styles.pinButton} ${isPinned ? styles.pinned : ""}`}
            title={isPinned ? "Pinned" : "Pin"}
            leftIconName={faThumbTack}
            onClick={onPinClick}
        />
    );
};