// node_modules
import { faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";
import { FC, useState } from "react";
// Components
import { FindestButton, Popover } from "Components";
// Interfaces
import { IEntityDTO } from "Interfaces";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Controllers
import { EntityControllerSingleton, LinkingControllerSingleton, SearchControllerSingleton } from "Controllers";
// Enums
import { AskAIAssistantMenuItemEnum, EntityTypeEnum, LogFeatureNameEnum, ObjectTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
// Styles
import styles from "./selectedTextActionsPopup.module.scss";
// Types
import { TIdNameTypeObjectType } from "Types";

type TSelectedTextActionsPopupProps = {
    objectIdEdited?: string,
    objectTypeEdited?: ObjectTypeEnum,
    selectedText: string,
    saveAsEntityCallback: (createdEntity: IEntityDTO, callback: () => void) => void,
    requestAssistanceAsync: (text: string, selectedMenuItem: AskAIAssistantMenuItemEnum) => Promise<void>,
    openLinkingModal: () => void
};

export const SelectedTextActionsPopup: FC<TSelectedTextActionsPopupProps> = ({
    objectIdEdited,
    objectTypeEdited,
    selectedText,
    saveAsEntityCallback,
    requestAssistanceAsync,
    openLinkingModal
}: TSelectedTextActionsPopupProps) => {
    // State
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [showAskIgorPopover, setShowAskIgorPopover] = useState<boolean>(false);

    // Logic
    const onSaveAsEntityClickAsync = async (currentObjectIdEdited?: string,
        currentObjectTypeEdited?: ObjectTypeEnum, currentSelectedText?: string): Promise<void> => {
        // safety-checks
        if (!currentSelectedText || !currentObjectIdEdited || !currentObjectTypeEdited) {
            return;
        }

        // search for entities with same or similar title as current selected text
        const foundEntities: TIdNameTypeObjectType[] = await SearchControllerSingleton
            .searchMultipleObjectsAsync(currentSelectedText, [ObjectTypeEnum.Entity]);

        // if there is at least one entity with same or similar title
        if (foundEntities && foundEntities.length > 0) {
            // open linking modal
            openLinkingModal();
        } else {
            // otherwise create new entity
            const newEntity = {
                title: currentSelectedText,
                type: EntityTypeEnum.Undefined
            } as IEntityDTO;

            // create entity in db
            const createdEntity = await EntityControllerSingleton
                .createAsync(newEntity);

            // safety-checks
            if (!createdEntity) {
                // show error
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Error while creating new entity.");
                // and stop execution
                return;
            }

            // log
            LogHelperSingleton.log("CreateEntity");
            LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-SaveSelectionAsEntity`);

            // link entity to current page
            const webRequestStatusEnum: WebRequestStatusEnum = await LinkingControllerSingleton
                .createToAsync(createdEntity.id, ObjectTypeEnum.Entity, currentObjectIdEdited, currentObjectTypeEdited);

            // safety-checks
            if (webRequestStatusEnum !== WebRequestStatusEnum.Success &&
                webRequestStatusEnum !== WebRequestStatusEnum.AlreadyExists) {
                // show error
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Error while linking entity to current page.");
                // and stop execution
                return;
            }

            // log 
            LogHelperSingleton.log("Link");

            // call save as entity callback and open entity in new tab
            saveAsEntityCallback(
                createdEntity, 
                () => window.open(`/library/entities/${createdEntity.id}`, "_blank", "noopener noreferrer")
            );
        }
    };

    const showPopover = (): void => {
        setShowAskIgorPopover(true);
    };

    const hidePopover = (): void => {
        setShowAskIgorPopover(false);
    };

    // Render
    return (
        <div ref={setReferenceElement} className={styles.selectedTextActionsPopup}>
            <div onMouseEnter={showPopover} onMouseLeave={hidePopover} className={styles.askIgor}>
                <FontAwesomeIcon icon={faMessageBot} />
                <span>Ask IGOR</span>
                <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
            </div>
            <Popover
                extraClassName={styles.askIgorOptionsPopover}
                onMouseEnter={showPopover}
                onMouseLeave={hidePopover}
                popoverOffset={0}
                isOpen={showAskIgorPopover}
                placement="right-start"
                referenceEl={referenceElement}
            >
                <FindestButton
                    extraClassName={styles.askIgorOptionsPopoverButton}
                    title="Write section"
                    onClick={() => { requestAssistanceAsync(selectedText, AskAIAssistantMenuItemEnum.WriteSection); }} />
            </Popover>
            <FindestButton
                leftIconName={faPlus}
                extraClassName={styles.saveAsEntityPopupButton}
                title="Save as entity"
                onClick={() => onSaveAsEntityClickAsync(objectIdEdited, objectTypeEdited, selectedText)} />
        </div>
    );
};