// node_modules
import { Fragment, Slice } from "prosemirror-model";
import { EditorState, Plugin, PluginKey, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
// Components
import { findestSchema } from "Components";
// Constants
import { EventConstants } from "Constants";
// Enums
import { EditorTableDOMTag } from "Enums";
// Helpers
import { ProseMirrorHelperSingleton, updateLoadingIndicatorPluginState } from "Helpers";

export function pastePlugin(updateEditorView?: (editorViewToUpdate: EditorView, transaction: Transaction, doCallOnSourceChangeCallback: boolean, callbackBeforeSetState?: (newState: EditorState) => void) => void): Plugin {
    return new Plugin({
        key: new PluginKey("PastePlugin"),
        props: {
            handlePaste(editorView: EditorView, clipboardEvent: ClipboardEvent, slice: Slice) {
                // init handledPaste to false (use to indicate if the paste was handled by our code or automatically by ProseMirror)
                let handledPaste = false;

                // if the clipboard event has files
                // means we are only pasting images copied from local system (not from the web)
                if (clipboardEvent && clipboardEvent.clipboardData &&
                        clipboardEvent.clipboardData.files && clipboardEvent.clipboardData.files.length > 0) {
                    // update loading indicator plugin state and set isLoading to true
                    updateLoadingIndicatorPluginState(editorView, true);
                        
                    // create custom event with the files
                    const event = new CustomEvent(EventConstants.PASTE_FILES_EVENT, {
                        detail: {
                            files: clipboardEvent.clipboardData.files
                        }
                    });

                    // dispatch the event
                    dispatchEvent(event);

                    // set handledPaste to true
                    handledPaste = true;
                    
                    // return handledPaste (to indicate that the paste was handled by our code)
                    return handledPaste;
                }
                // otherwise...

                // init transaction
                let tr: Transaction | undefined = undefined;

                // init file src list
                const fileSrcList: string[] = [];

                // get parent node at selection
                const parentNode = editorView.state.selection.$from.node(editorView.state.selection.$from.depth - 1);
                // build fragment to paste
                let newFragment = Fragment.empty;
                // go through the slice
                slice.content.forEach((node) => {
                    // if the node is of type image_reference_img
                    // and it has a src attribute
                    // and no id attribute
                    // means we are pasting content from the web (containing images)
                    if (node.type.name === findestSchema.nodes.image_reference_img.name && 
                            node.attrs["src"] &&
                            !node.attrs["id"]) {
                        // add the src to the file src list
                        fileSrcList.push(node.attrs["src"]);
                    }
                    // add the node to the fragment
                    newFragment = newFragment.addToEnd(node);
                });

                // if file src list is not empty (we are pasting content from the web containing images)
                if (fileSrcList.length > 0) {
                    // update loading indicator plugin state and set isLoading to true
                    updateLoadingIndicatorPluginState(editorView, true);
                    
                    // build a list of promises to fetch the files
                    // which return an array of objects containing the original src and the related file
                    // (we need the src to compare back which file is related to which img tag in the frament to paste
                    // and correctly replace it by a image_reference_img node with the related file)
                    const promises: Promise<{src: string, file: File}>[] = fileSrcList
                        .map((fileSrc: string, index: number) => fetch(fileSrc)
                            .then((response: Response) => response.blob())
                            .then(blob => {return {src: fileSrc, file: new File([blob], `image${index}`, blob)};}));
                    // resolve all promises
                    Promise.all(promises).then((filesWithUrls: {src: string, file: File}[]) => {
                        // create custom event with the files
                        const event = new CustomEvent(EventConstants.PASTE_FILES_EVENT, {
                            detail: {
                                filesWithUrls: filesWithUrls,
                                fragmentToPaste: newFragment
                            }
                        });

                        // dispatch the event
                        dispatchEvent(event);
                    });

                    // set handledPaste to true
                    handledPaste = true;

                    // return handledPaste (to indicate that the paste was handled by our code)
                    return handledPaste;
                }
                // otherwise...
                
                // init custom node reference block names
                const customNodeReferenceBlockNames: string[] = [findestSchema.nodes.highlight_reference.name, findestSchema.nodes.image_reference.name,
                    findestSchema.nodes.file_reference.name, findestSchema.nodes.entity_reference.name,
                    findestSchema.nodes.study_reference.name];

                // if parent node is a table cell or a list item
                if ([EditorTableDOMTag.Td, EditorTableDOMTag.Th, findestSchema.nodes.list_item.name].includes(parentNode.type.name)) {
                    // do not handle the paste if we are pasting a paragraph in a table cell or a list item
                    if (!(newFragment.childCount === 1 &&
                            newFragment.firstChild &&
                            findestSchema.nodes.paragraph.name === newFragment.firstChild.type.name)) {
                        // get position where to insert the new fragment
                        let position = ProseMirrorHelperSingleton
                            .getPositionWhereToInsertNewElement(editorView.state.selection);
                        
                        // if the content of the new fragment is not correct for the parent node
                        if (newFragment.childCount > 1 || (newFragment.childCount === 1 &&
                                newFragment.firstChild &&
                                ![findestSchema.nodes.paragraph.name, ...customNodeReferenceBlockNames].includes(newFragment.firstChild.type.name))) {
                            // change position where to insert the new fragment
                            position = ProseMirrorHelperSingleton
                                .getPositionWhereToInsertNewElement(editorView.state.selection, true);
                        }
                        
                        // replace the content of the parent node with the new fragment
                        tr = tr ?? editorView.state.tr;
                        tr = tr.replaceWith(
                            position.from,
                            position.to,
                            newFragment
                        );

                        // set handledPaste to true
                        handledPaste = true;
                    }
                }

                // get the transaction to update the node markups for the pasted content
                tr = ProseMirrorHelperSingleton
                    .getPostPasteNodeMarkupsUpdateTransaction(tr, editorView);

                // if updateEditorView and tr are defined
                if (updateEditorView && tr) {
                    // apply the transaction
                    updateEditorView(editorView, tr, handledPaste);
                }

                // return handledPaste to indicate if the paste was handled by our code or automatically by ProseMirror
                return handledPaste;
            },
        }
    });
}