
// node_modules
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FC, useRef } from "react";
// Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Styles
import buttonStyles from "Styles/buttons.module.scss";
import styles from "./fileInputButton.module.scss";

type TFileInputButtonProps = {
    onFileSelected?: (file: File) => void,
    buttonText?: string,
    icon?: IconDefinition,
    extraClassNames?: {
        fileInputButtonContainer?: string,
        fileInputButton?: string,
        iconContainer?: string,
        optionText?: string
    },
    fileSizeLimit?: number,
    buttonTitle?: string,
    acceptedMIMETypes?: string[]
}

export const FileInputButton: FC<TFileInputButtonProps> = ({
    onFileSelected,
    buttonText = "Choose file",
    icon,
    extraClassNames = {},
    fileSizeLimit = 2048000,
    buttonTitle,
    acceptedMIMETypes
}: TFileInputButtonProps) => {
    const fileInput = useRef<HTMLInputElement>(null);

    const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target.files || event.target.files.length === 0 || !event.target.files[0] ||
            event.target.files.length > 1) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Please select one file to upload.");
            return;
        }

        // if component only accepts certain MIME types
        if(acceptedMIMETypes && acceptedMIMETypes.length > 0) {
            // get file
            const file = event.target.files[0];
            // git file MIME type
            const fileMIMEType = file.type;
            // if file MIME type is not set or not in the accepted MIME types
            if(!fileMIMEType || !acceptedMIMETypes.includes(fileMIMEType)) {
                // show error message
                ToastHelperSingleton
                    .showToast(
                        ToastTypeEnum.Error, 
                        `The file type is not supported. Please select a file of the following type: ${acceptedMIMETypes.join(", ")}.`
                    );
                // stop execution, return
                return;
            }
        }

        const file = event.target.files[0];
        if (file.size > fileSizeLimit) {
            const maxFileString = Math.round(fileSizeLimit / 1000000).toString();
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, `The maximum file size is ${maxFileString}MB, please upload a smaller file.`);
            LogHelperSingleton.logWithProperties("fileSizeTooBig", {"Size": fileSizeLimit});
            return;
        }

        if(onFileSelected) {
            onFileSelected(file);
        }
    };

    const onButtonClick = () => {
        if(fileInput.current) {
            fileInput.current.value = "";
            fileInput.current.click();
        } 
    };

    return (
        <div title={buttonTitle} className={`${styles.fileInputButtonContainer} ${extraClassNames.fileInputButtonContainer || ""}`}>
            <input title="File upload button" ref={fileInput} type="file" onChange={handleFileInput} />
            <button type="button" className={`${buttonStyles.secondaryButton} ${extraClassNames.fileInputButton || ""}`} onClick={onButtonClick}>
                {icon && (
                    <div className={extraClassNames.iconContainer}>
                        <FontAwesomeIcon className={styles.attachFileIcon} icon={icon} />
                    </div>
                )}
                <div className={extraClassNames.optionText}>{buttonText}</div>
            </button>
        </div>
    );
};