// node_modules
import { useCallback } from "react";
import cloneDeep from "lodash.clonedeep";
import { useNavigate } from "react-router-dom";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { LinkGraphHelperSingleton, ObjectTypeHelperSingleton } from "Helpers";
// Hooks
import { useAnyLinkAddedListener } from "Hooks";
// Types
import { TExplorerObjectItem, TLinkGraphDTO } from "Types";


export const useExplorerAnyLinkAddedListener = (listItems: TExplorerObjectItem[] | undefined, setListItems: React.Dispatch<React.SetStateAction<TExplorerObjectItem[] | undefined>>,
        parents: TExplorerObjectItem[] | undefined, setParents: React.Dispatch<React.SetStateAction<TExplorerObjectItem[] | undefined>>,
        selectedParentId: string | undefined, objectIdEdited: string | undefined) => {
    // Hooks
    const navigate = useNavigate();
    
    /** Any link added handler */
    const anyLinkAddedAsync = useCallback(async (fromId: string, fromType: ObjectTypeEnum, toId: string, toType: ObjectTypeEnum): Promise<void> => {
        // if a link was added but it is not between entity(ies) and/or study(ies)
        if (![ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(fromType) || ![ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(toType)) {
            // stop execution, return
            return;
        }

        // get children ids
        const parentsChildrenIds: Set<string> = LinkGraphHelperSingleton.getChildrenIds(parents ?? []);
        const listItemsChildrenIds: Set<string> = LinkGraphHelperSingleton.getChildrenIds(listItems ?? []);

        // if from id is in list items children ids
        // or from id is selected parent id
        // or there is only one parent and it is the from id
        if (listItemsChildrenIds.has(fromId) || fromId === selectedParentId || (parents && parents.length === 1 && parents[0].id === fromId)) {
            // build node ids already visited from parents and list items children ids
            const nodeIdsAlreadyVisited: Set<string> = new Set<string>(parentsChildrenIds);
            listItemsChildrenIds.forEach(id => nodeIdsAlreadyVisited.add(id));
            nodeIdsAlreadyVisited.delete(toId);

            // get link graph for new linked object
            const linkGraphForNewLinkedObject: TLinkGraphDTO | undefined = await LinkingControllerSingleton
                .postLoadMoreLinkGraph(
                    toId,
                    toType,
                    Array.from(nodeIdsAlreadyVisited),
                    false,
                    2
            );

            // if link graph for new linked object is not set
            if (!linkGraphForNewLinkedObject) {
                // stop execution, return
                return;
            }

            // init new child
            const newChild: TExplorerObjectItem = {
                ...linkGraphForNewLinkedObject.focusedNode,
                isCollapsed: linkGraphForNewLinkedObject.lowerLevelNodes && linkGraphForNewLinkedObject.lowerLevelNodes.length > 0,
                lowerLevelNodes: [...linkGraphForNewLinkedObject.lowerLevelNodes]
            };

            // if from id is the selected parent
            if (fromId === selectedParentId) {
                // update list items by adding new child at the end of list items
                setListItems(cloneDeep([...listItems ?? [], newChild]));
            } else {
                // otherwise, update list items by adding new child to related from id parent
                setListItems(cloneDeep([...LinkGraphHelperSingleton.addChildToParent(listItems ?? [], fromId, newChild)]));
            }
        }

        // if from id is in parents children ids
        // (i.e. linked an object to a parent already displayed)
        if (parentsChildrenIds.has(fromId)) {
            // build node ids already visited from list items and parents children ids
            const nodeIdsAlreadyVisited: Set<string> = new Set<string>(listItemsChildrenIds);
            parentsChildrenIds.forEach(id => nodeIdsAlreadyVisited.add(id));
            nodeIdsAlreadyVisited.delete(toId);

            // get link graph for the new linked object
            const linkGraphForNewLinkedObject: TLinkGraphDTO | undefined = await LinkingControllerSingleton
                .postLoadMoreLinkGraph(
                    toId,
                    toType,
                    Array.from(nodeIdsAlreadyVisited),
                    false,
                    2
            );

            // if link graph for the new linked object is not set
            if (!linkGraphForNewLinkedObject) {
                // stop execution, return
                return;
            }

            // init new child
            const newChild: TExplorerObjectItem = {
                ...linkGraphForNewLinkedObject.focusedNode,
                isCollapsed: linkGraphForNewLinkedObject.lowerLevelNodes && linkGraphForNewLinkedObject.lowerLevelNodes.length > 0,
                lowerLevelNodes: [...linkGraphForNewLinkedObject.lowerLevelNodes]
            };

            // update parents by adding new child to related from id parent
            setParents(cloneDeep([...LinkGraphHelperSingleton.addChildToParent(parents ?? [], fromId, newChild)]));
        } else if (!parents || parents.length === 0 && LinkGraphHelperSingleton.getChildrenIds(listItems ?? [], true).has(toId)) {
            // otherwise, if there are no parents and to id is in direct items children
            // (i.e. added a parent to an item already displayed and there were not parents yet) 

            // build node ids already visited from list items children ids
            const nodeIdsAlreadyVisited: Set<string> = new Set<string>(listItemsChildrenIds);
            nodeIdsAlreadyVisited.delete(fromId);

            // get link graph for new parent
            const linkGraphForNewParent: TLinkGraphDTO | undefined = await LinkingControllerSingleton
                .postLoadMoreLinkGraph(
                    fromId,
                    fromType,
                    Array.from(nodeIdsAlreadyVisited),
                    false,
                    2
            );

            // get item by to id
            const itemByToId: TExplorerObjectItem | undefined = LinkGraphHelperSingleton.getExplorerObjectItemById(toId, listItems);

            // if link graph for new parent or item by to id is not set
            if (!linkGraphForNewParent || !itemByToId) {
                // stop execution, return
                return;
            }

            // init new object
            const newObject: TExplorerObjectItem = {
                ...linkGraphForNewParent.focusedNode,
                isCollapsed: false,
                lowerLevelNodes: [itemByToId, ...linkGraphForNewParent.lowerLevelNodes]
            };

            // update parents and list items by setting new object as only parent and list item
            setParents(cloneDeep([newObject]));
            setListItems(cloneDeep([newObject]));
        } else if (parents && parents.length > 0 && LinkGraphHelperSingleton.getChildrenIds(listItems ?? []).has(toId)) {
            // otherwise, if there are parents and to id is in direct list items children
            // (i.e. added a parent to an item already displayed and there were already parents)

            // build node ids already visited from list items and parents children ids
            const nodeIdsAlreadyVisited: Set<string> = new Set<string>(listItemsChildrenIds);
            parentsChildrenIds.forEach(id => nodeIdsAlreadyVisited.add(id));
            nodeIdsAlreadyVisited.delete(fromId);
            nodeIdsAlreadyVisited.delete(toId);

            // get link graph for new parent
            const linkGraphForNewParent: TLinkGraphDTO | undefined = await LinkingControllerSingleton
                .postLoadMoreLinkGraph(
                    fromId,
                    fromType,
                    Array.from(nodeIdsAlreadyVisited),
                    false,
                    2
            );

            // if link graph for new parent is not set
            if (!linkGraphForNewParent) {
                // stop execution, return
                return;
            }

            // init new object
            const newObject: TExplorerObjectItem = {
                ...linkGraphForNewParent.focusedNode,
                lowerLevelNodes: [...linkGraphForNewParent.lowerLevelNodes]
            };

            // get common children between all parents in order to know if to id is in parents common children
            // (we add new linked object as a parent only if to id is in parents common children)
            const parentsCommonChildren: TExplorerObjectItem[] = [];
            for (const parent of [...parents, newObject]) {
                for (const child of parent.lowerLevelNodes) {
                    if ([...parents, newObject].every(p => LinkGraphHelperSingleton.getChildrenIds(p.lowerLevelNodes, true).has(child.id)) && 
                            !parentsCommonChildren.some(c => c.id === child.id)) {
                        parentsCommonChildren.push(child);
                    }
                }
            }
            
            // if to id is in parents common children
            if (parentsCommonChildren.find((commonChild: TExplorerObjectItem) => commonChild.id === toId)) {
                // if parents has only one parent, list items is set and has only one item
                // then we also need to update list items using parents common children
                if (parents.length === 1 && listItems && listItems.length === 1) {
                    // get is collapsed property from only parent's lower level nodes
                    const isCollapsedByListItemId: Map<string, boolean | undefined> = new Map<string, boolean | undefined>();
                    for (const child of (listItems[0].lowerLevelNodes as TExplorerObjectItem[])) {
                        isCollapsedByListItemId.set(child.id, child.isCollapsed);
                    }

                    // set is collapsed property on each parent common child from their corresponding list item
                    for (const commonChild of parentsCommonChildren) {
                        if (isCollapsedByListItemId.has(commonChild.id)) {
                            commonChild.isCollapsed = isCollapsedByListItemId.get(commonChild.id);
                        }
                    }

                    // update list items by setting it to parents common children
                    setListItems(cloneDeep([...parentsCommonChildren]));

                    // if object displayed was the only parent
                    if (parents[0].id === objectIdEdited) {
                        // we navigate to parents common children first item
                        ObjectTypeHelperSingleton.navigateBasedOnObjectType(
                            parentsCommonChildren[0].objectType,
                            parentsCommonChildren[0].id,
                            navigate
                        );
                    }
                }

                // since to id is in new object lower level nodes but also in parents common children
                // remove it from new object lower level nodes 
                // (we keep the one in parents common children because it has the isCollapsed property correctly set and also the children we did not retrieve because of the node ids already visited set)
                newObject.lowerLevelNodes = newObject.lowerLevelNodes.filter(child => child.id !== toId);
                // add parents common children to new object's lower level nodes
                newObject.lowerLevelNodes = [...parentsCommonChildren, ...newObject.lowerLevelNodes];
                
                // update parents by adding new object to parents
                setParents(cloneDeep([...parents, newObject]));
            }
        }
    }, [listItems, navigate, objectIdEdited, parents, selectedParentId, setListItems, setParents]);

    /** Listen to any link added using websockets */
    useAnyLinkAddedListener(anyLinkAddedAsync);
};