// node_modules
import { FC, useContext, useEffect, useState } from "react";
// Components
import { InformationBox, LoadingStatusIndicator, UserIcon } from "Components";
// Constants
import { EmptyStateMessageConstants, TextConstants } from "Constants";
// Controllers
import { ActivityControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { ActivityTypeHelperHelperSingleton, DateHelperSingleton } from "Helpers";
// Types
import { TRecentSidebarActivityItemDTO } from "Types";
// Custom hooks
import { useObjectReferenceModal } from "Hooks";
// Contexts
import { ElementVisibilityContext } from "Providers";
// Styles
import styles from "./recentCompanyActivity.module.scss";

export const RecentCompanyActivity: FC = () => {
    // Context
    const { canUserEdit } = useContext(ElementVisibilityContext);

    // States
    const [recentCompanyActivity, setRecentCompanyActivity] = useState<TRecentSidebarActivityItemDTO[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    useEffect(() => {
        (async () => {
            const recentActiveItems = await ActivityControllerSingleton.getActivityAsync(10);
            if (recentActiveItems) {
                setRecentCompanyActivity(recentActiveItems);
            }
            setIsLoading(false);
        })();
    }, []);

    const openReferenceModal = (item: TRecentSidebarActivityItemDTO) => {
        setReferenceModalProps({
            id: item.id,
            type: item.objectType,
            isOpen: true,
            doIgnoreIsDeleted: false,
        });
    };
    // Render
    return (
        <div className={styles.recentCompanyActivity}>
            {recentCompanyActivity.length > 0 && recentCompanyActivity.map((activity) => (
                <div key={`recentCompanyActivity${activity.id}`} className={`${styles.recentActivityItem} ${activity.objectType === ObjectTypeEnum.Entity ? styles.entity : ""}`}>
                    <div><UserIcon size="medium" email={activity.userEmail ?? ""} /></div>
                    <h4 className={styles.activityNameContainer}>{`${activity.userEmail} ${ActivityTypeHelperHelperSingleton.activityEnumToDisplayString(activity.activityType).toLowerCase()} `}<span onClick={() => openReferenceModal(activity)} className={styles.activityName}>{`${activity.name}.`}</span></h4>
                    {activity.date && <h5 className={styles.activityDate}>{DateHelperSingleton.getShortenedDate(activity.date)}</h5>}
                </div>
            ))}
            {recentCompanyActivity.length === 0 && !isLoading && (
                <div className={styles.emptyContentContainer}>
                    <InformationBox 
                        title={EmptyStateMessageConstants.EMPTY_COMPANY_RECENT_ITEMS}
                        subTitle={canUserEdit ? TextConstants.START_CREATING_BY_CLICKING_CREATE_BUTTON : TextConstants.RECENT_ACTIVITY_OF_YOUR_COMPANY_WILL_BE_DISPLAYED_HERE}
                        type="information"
                    />
                </div>
            )}
            {isLoading && <LoadingStatusIndicator shouldCenter size={32} status={1} />}
            {referenceModal}
        </div>
    );
};