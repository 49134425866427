// node_modules
import { faPencilAlt, faSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
// Components
import { Editor, MarkdownItComponent } from "Components";
// Helpers
import { ProseMirrorPluginHelperSingleton } from "Helpers";
// Styles
import styles from "./editableMarkdown.module.scss";
// Enums
import { ObjectTypeEnum } from "Enums";
// Contexts
import { AuthContext } from "Providers";

type TEditableMarkdownProps = {
    objectIdEdited?: string,
    objectTypeEdited?: ObjectTypeEnum,
    source: string,
    noSourcePlaceholder: string,
    onSourceChange?: (newValue: string) => void,
    extraButtons?: ReactNode[],
    extraClassNames?: { editButton?: string },
    onEditModeChange?: (isInEditMode: boolean) => void,
    forceEditModeOn?: boolean,
    isEditMode?: boolean,
    isEditable?: boolean,
    showPlaceholderInEditMode?: boolean
}

export const EditableMarkdown: FC<TEditableMarkdownProps> = ({
    objectIdEdited, objectTypeEdited, source, noSourcePlaceholder, onSourceChange,  extraButtons,
    extraClassNames, onEditModeChange, forceEditModeOn, isEditMode,
    isEditable = true, showPlaceholderInEditMode = true}: TEditableMarkdownProps) => {
    // State
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isEditModeForcedOn, setIsEditModeForcedOn] = useState<boolean>(forceEditModeOn !== undefined ? forceEditModeOn : false);

    // Contexts
    const { auth } = useContext(AuthContext);

    // Logic
    useEffect(() => {
        // check if need to turn it on based on forceEditModeOn prop
        if (isEditModeForcedOn) {
            setIsEditing(true);
            if (onEditModeChange) {
                onEditModeChange(true);
            }
            setIsEditModeForcedOn(false);
        }
    }, [isEditModeForcedOn, onEditModeChange]);

    useEffect(() => {
        if (isEditMode !== undefined) {
            setIsEditing(isEditMode);
        }
    }, [isEditMode]);

    const getExtraButtons = useMemo(() => {
        // safety-checks
        if (!extraButtons) { return null; }
        // return extra buttons
        return extraButtons.map((extraButton) => extraButton ? <div key={extraButton.toString()}>{extraButton}</div> : null);
    }, [extraButtons]);
    
    const setIsEditingMode = (isInEditMode: boolean) => {
        // If the markdown is not editable then never change the editmode state
        if(!isEditable) return;

        setIsEditing(isInEditMode);
        if(onEditModeChange) onEditModeChange(isInEditMode);
    };
    
    return (
        <>
            {(isEditing && onSourceChange) ?
                <div className={[styles.editableMarkdownContainer, styles.isInEditMode].join(" ")}>
                    {getExtraButtons}
                    <button type="button" className={[styles.changeContentButton, styles.save, extraClassNames?.editButton ? extraClassNames.editButton : ""].join(" ")}  title={"Exit edit mode"} onClick={() => setIsEditingMode(false)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                        <FontAwesomeIcon className={styles.slash} icon={faSlash} />
                        <FontAwesomeIcon className={styles.slashBottom} icon={faSlash} />
                    </button>
                    <Editor 
                        objectIdEdited={objectIdEdited}
                        objectTypeEdited={objectTypeEdited}
                        source={source} 
                        plugins={ProseMirrorPluginHelperSingleton.getDefault(auth, showPlaceholderInEditMode ? noSourcePlaceholder : "")}
                        onSourceChange={onSourceChange} />
                </div>
            :
                <div className={[styles.editableMarkdownContainer].join(" ")}>
                    {getExtraButtons}
                    {isEditable && 
                        <button type="button" className={[styles.changeContentButton, styles.edit, extraClassNames?.editButton ? extraClassNames.editButton : ""].join(" ")} title={"Edit mode"} onClick={() => setIsEditingMode(true)} >
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </button>}
                    <MarkdownItComponent 
                        source={source}
                        noSourcePlaceholder={noSourcePlaceholder}
                        onDoubleClickHandler={() => setIsEditingMode(true)} />
                </div>
            }
        </>
    );
};