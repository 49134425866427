export const EditorConstants = {
    PREVENT_EVENT_STRING: "event.preventDefault(); event.stopPropagation();",
    INTAKE_SHEET_CONFIRMATION_TITLE_BLOCK_TEXT: "Intake sheet confirmation",
    INTAKE_SHEET_CONFIRMATION_NOT_ACCEPTED_PARAGRAPH_BLOCK_TEXT: 'Please press the "accept" button if the intake sheet matches the scouting challenge we have discussed or place a message with your comment if this is not the case.',
    INTAKE_SHEET_CONFIRMATION_NOT_ACCEPTED_BUTTON_BLOCK_TEXT: "Accept",
    INTAKE_SHEET_CONFIRMATION_ACCEPTED_TITLE_BLOCK_TEXT: "Accepted",
    INTAKE_SHEET_CONFIRMATION_ACCEPTED_PARAGRAPH_BLOCK_PARTIAL_TEXT: "Intake sheet confirmed by",
    INTAKE_SHEET_CONFIRMATION_ACCEPTED_BUTTON_BLOCK_TEXT: "Remove confirmation",
    DEFAULT_TD_ROW_SPAN: 1,
    OVERVIEW_TABLE_HEADING_TEXT: "Results overview table",
    OPEN_REMOVE_ME_TAG: "%%%%OPEN_REMOVE_ME%%%%",
    CLOSE_REMOVE_ME_TAG: "%%%%CLOSE_REMOVE_ME%%%%"
} as const;
