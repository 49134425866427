// node_modules
import { FC, MouseEvent, useState } from "react";
// Types
import { TCommentDTO, TReferenceDTO } from "Types";
// Styles
import styles from "./referenceSidebar.module.scss";
// Enums
import { ReferenceClickTypeEnum } from "Enums";
// Components
import { CommentsCount, CommentsPopover } from "Components";
import { AddReferencePopover } from "./AddReferencePopover";

// Constants
type TCommonReferenceProps = {
    isReferenceUsedAlready: boolean,
    reference: TReferenceDTO,
    handleOnReferenceClick: (event: MouseEvent, referenceClickType: ReferenceClickTypeEnum, reference?: TReferenceDTO) => void,
    onReferenceCommentsUpdated: (referenceId: string, comments: TCommentDTO[]) => void
};

export const CommonReference: FC<TCommonReferenceProps> = ({isReferenceUsedAlready, reference, handleOnReferenceClick, onReferenceCommentsUpdated} : TCommonReferenceProps) => {
    // State
    const [isPopoverShown, setIsPopoverShown] = useState<boolean>(false);
    const [referenceElement, setReferenceElement] = useState<HTMLHeadElement | null>(null);
    const [refPopoverReferenceElement, setRefPopoverReferenceElement] = useState<HTMLHeadElement | null>(null);
    const [isAddReferencePopoverShown, setIsAddReferencePopoverShown] = useState<boolean>(false);

    
    // Render
    return (
        <div onMouseEnter={() => { setIsAddReferencePopoverShown(true); }} ref={setRefPopoverReferenceElement} className={`${styles.reference} ${isAddReferencePopoverShown ? styles.hover : ""} ${isReferenceUsedAlready ? styles.isUsed : ""}`} onMouseLeave={() => { setIsPopoverShown(false); setIsAddReferencePopoverShown(false); }}>
            {isAddReferencePopoverShown && <AddReferencePopover
                reference={reference}
                refPopoverReferenceElement={refPopoverReferenceElement}
                handleOnReferenceClick={handleOnReferenceClick}
            />}
            <div className={`${styles.addLinkContainer}`} ></div>
            {reference.isImage ?
                <div className={styles.referenceImage}>
                    <img src={reference.url} alt={reference.text} />
                    <h5 title={reference.text}>{reference.text}</h5>
                </div>
            : 
                <div className={styles.referenceHighlight}>
                    <h4 title={reference.text}>{reference.text}</h4>
                    <CommentsCount 
                        containerRef={setReferenceElement} 
                        onMouseEnter={() => { setIsPopoverShown(true); }} 
                        comments={reference.comments}
                        extraClassNames={{ container: styles.commentsCountContainer }}
                    />
                </div>}
            <CommentsPopover 
                isPopoverShown={isPopoverShown}
                onMouseLeave={() => { setIsPopoverShown(false); }}
                comments={reference.comments}
                referenceEl={referenceElement} 
                referenceId={reference.id}
                onCommentsUpdated={(comments) => { onReferenceCommentsUpdated(reference.id, comments); }} />
        </div>
    );
};