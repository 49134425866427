// node_modules
import { FC } from "react";
// Types
import { TLinkGraphNodeDTO, TUseDragAndDrop } from "Types";
// Components
import { CollapsibleLinksListItem } from "Components";
import { ObjectTypeEnum } from "Enums";

type TCollapsibleLinksListProps = {
    linkGraphNodes: TLinkGraphNodeDTO[],
    upperLevelNode: TLinkGraphNodeDTO,
    addToCheckedLinks: (from: TLinkGraphNodeDTO, to: TLinkGraphNodeDTO) => void,
    removeFromCheckedLinks: (from: TLinkGraphNodeDTO, to: TLinkGraphNodeDTO) => void,
    useDragAndDropProps?: TUseDragAndDrop,
    isUserExternal: boolean,
    openItemInModal: (objectId: string, objectType: ObjectTypeEnum) => void,
};    

export const CollapsibleLinksList: FC<TCollapsibleLinksListProps> = ({ linkGraphNodes, upperLevelNode, 
        addToCheckedLinks, removeFromCheckedLinks, useDragAndDropProps, isUserExternal,
        openItemInModal }: TCollapsibleLinksListProps) => {
    // Render
    return (
        <ul>
            {linkGraphNodes.map((currentLinkGraphNode: TLinkGraphNodeDTO) => {
                return (
                    <CollapsibleLinksListItem 
                        key={currentLinkGraphNode.id}
                        upperLevelNode={upperLevelNode}
                        linkGraphNode={currentLinkGraphNode}
                        addToCheckedLinks={addToCheckedLinks}
                        removeFromCheckedLinks={removeFromCheckedLinks}
                        useDragAndDropProps={useDragAndDropProps}
                        isUserExternal={isUserExternal}
                        openItemInModal={openItemInModal}
                    />
                );
            })}
        </ul>
    );
};