import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Types
import { TExpandableActionButtonsListData } from "Types";
// Styles
import styles from "./expandableActionButtons.module.scss";

type TExpandableActionButtons = {
    listData: TExpandableActionButtonsListData,
    extraClassNames?: { container?: string },
    children?: JSX.Element
}

export const ExpandableActionButtons: FC<TExpandableActionButtons> = ({ children, listData, extraClassNames = {} }: TExpandableActionButtons) => {
    return (
        <div className={`${styles.nodeControls} ${extraClassNames.container ?? ""}`}>
            <ul>
                {listData.map((data) => (
                    <li key={data.title} onClick={data.onClick} title={data.title}>
                        <FontAwesomeIcon icon={data.icon} />
                        <span>{data.title}</span>
                    </li>
                ))}
                {children}
            </ul>
        </div>
    );
};