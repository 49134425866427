import React from "react";
// Components
import { SearchMetadataBubble } from "../SearchMetadataBubble";
// Types
import { TGroupedDocumentSearchResult } from "Types";
// Styles
import styles from "./affiliationsGroupedDirectory.module.scss";

interface AffiliationsGroupedDirectoryProps {
    groupedDocumentSearchResult: TGroupedDocumentSearchResult[] | null,
    handleGroupedDocumentClick: (groupName: string) => void
}

export const AffiliationsGroupedDirectory: React.FC<AffiliationsGroupedDirectoryProps> = ({ groupedDocumentSearchResult, handleGroupedDocumentClick }) => {

    const handleClick = (groupName: string) => {
        handleGroupedDocumentClick(groupName);
    };

    return (
        <div className={styles.groupedResultContainer} >
            {groupedDocumentSearchResult && (
                <ul>
                    {groupedDocumentSearchResult.map((groupedResult, index) => (
                        <li key={index} className={styles.groupedResultItem} onClick={() => handleClick(groupedResult.groupName)} >
                            <div className={styles.groupedResultCountContainer}>
                                <SearchMetadataBubble text={groupedResult.documentCount} isSmall />
                            </div>
                            <span className={styles.groupedResultName} >
                                {groupedResult.groupName}</span>
                        </li>
                    ))}
                </ul>

            )}
        </div>

    );
};
