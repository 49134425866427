// node_modules
import {
  faArrowUpRightFromSquare,
  faLink,
  faLinkSlash,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, MouseEvent, useContext, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import {
  Checkbox,
  CreatedByAccount,
  DocumentModal,
  LinkedCounts,
  OpenAccess,
  Tooltip,
} from "Components";
// Styles
import styles from "./savedDocumentItem.module.scss";
// Types
import { ISavedDocumentDTO, fromISavedDocumentDTO } from "Interfaces";
import { TIdNameTypeObjectType } from "Types";
// Helpers
import {
  ConnectedObjectsHelperSingleton,
  DateHelperSingleton,
  DocumentTypeHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
} from "Helpers";
// Contexts
import { ElementVisibilityContext, PubSubContext } from "Providers";

type TSavedDocumentTypeProps = {
  savedDocument: ISavedDocumentDTO;
  isSelected: boolean;
  onCheckboxChange?: (
    isChecked: boolean,
    savedDocument: ISavedDocumentDTO
  ) => void;
  onLinkSavedDocumentClick?: (savedDocument: ISavedDocumentDTO) => void;
  onDeleteSavedDocumentClick?: (
    savedDocument: ISavedDocumentDTO
  ) => Promise<void>;
  deleteIsUnlink?: boolean;
  isInboxSection?: boolean;
  refreshDocuments?: () => void;
};

export const SavedDocumentItem: FC<TSavedDocumentTypeProps> = ({
  savedDocument,
  isSelected,
  onCheckboxChange,
  onLinkSavedDocumentClick,
  onDeleteSavedDocumentClick,
  deleteIsUnlink,
  isInboxSection,
  refreshDocuments,
}: TSavedDocumentTypeProps) => {
  const { isInReferenceModal } = useContext(ElementVisibilityContext);

  const documentUrl = savedDocument.fullUrl
    ? savedDocument.fullUrl
    : savedDocument.url
    ? savedDocument.url
    : undefined;

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isOpenArticleButtonTooltipOpen, setIsOpenArticleButtonTooltipOpen] =
    useState<boolean>(false);

  const openArticleButtonContainerRef = useRef<HTMLDivElement>(null);

  // contexts
  const { pubSubHandler } = useContext(PubSubContext);

  const onOpenDocumentClick = (): void => {
    // safety-checks
    if (!savedDocument.id) {
      return;
    }

    // log
    LogHelperSingleton.log("OpenDocument");

    // if the location is an entity or a study page, then open document on document modal
    if (
      location.pathname.includes("entities") ||
      location.pathname.includes("studies") ||
      isInReferenceModal
    ) {
      setIsModalOpen(true);
      return;
    }

    // if the location is not an entity or a study page, then navigate to the document page
    // init url to navigate to
    let urlToNavigateTo = "";

    // if current location pathname does not contain "/", "/inbox" or "/library"
    if (["/", "/inbox", "/library"].indexOf(location.pathname) === -1) {
      // set url to navigate to library
      urlToNavigateTo = `/library/documents/${savedDocument.id}`;
    } else {
      // set url to navigate to inbox or library depending on current location pathname
      const currentPath =
        ["/", "/inbox"].indexOf(location.pathname) > -1 ? "inbox" : "library";
      urlToNavigateTo = `/${currentPath}/documents/${savedDocument.id}`;
    }

    // navigate to url if url to navigate to is not empty
    if (urlToNavigateTo) {
      navigate(urlToNavigateTo);
    }
  };

  const onOpenDocumentInNewTabClick = (event: MouseEvent): void => {
    // prevent the default behavior of the underlying element
    event.preventDefault();
    event.stopPropagation();

    // safety-checks
    if (!documentUrl) {
      return;
    }

    // log
    LogHelperSingleton.log("GotoDocumentUrl");

    // open document in new tab
    window.open(documentUrl, "_blank", "noopener noreferrer");
  };

  const getLinkedCountsClassName = (): string => {
    if (
      (savedDocument.linkedCounts &&
        savedDocument.linkedCounts.documentCount !== 0) ||
      savedDocument.linkedCounts?.entityCount !== 0 ||
      savedDocument.linkedCounts?.fileCount !== 0 ||
      savedDocument.linkedCounts?.highlightCount !== 0 ||
      savedDocument.linkedCounts?.imageCount !== 0 ||
      savedDocument.linkedCounts?.projectCount !== 0 ||
      savedDocument.linkedCounts?.studyCount !== 0
    ) {
      return styles.linkedDoc;
    } else {
      return styles.unlinkedDoc;
    }
  };

  const onSaveElementClickAsync = async (
    element: TIdNameTypeObjectType,
    closeSavePopupCallback?: () => void
  ): Promise<void> => {
    // call close save popup callback if it set
    if (closeSavePopupCallback) closeSavePopupCallback();

    // get document object type from saved document type
    const documentObjectType =
      ObjectTypeHelperSingleton.documentTypeToObjectType(
        savedDocument.savedDocumentType
      );

    // add object to current document
    await ConnectedObjectsHelperSingleton.addObjectToObjectAsync(
      element,
      pubSubHandler,
      savedDocument.id,
      documentObjectType
    );

    // call refresh documents if it set
    if (refreshDocuments) {
      refreshDocuments();
    }
  };

  // Render
  return (
    <>
      <div
        className={[
          styles.savedDocumentItemContainer,
          isSelected ? styles.selected : "",
          isInboxSection ? getLinkedCountsClassName() : "",
        ].join(" ")}
      >
        <div onClick={onOpenDocumentClick} className={styles.savedDocumentItem}>
          {onCheckboxChange ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={styles.checkboxContainer}
            >
              <Checkbox
                isChecked={isSelected}
                onCheckboxChange={(isChecked) => {
                  onCheckboxChange(isChecked, savedDocument);
                }}
                theme="black"
              />
            </div>
          ) : null}
          <p className={styles.savedDocumentType}>
            {DocumentTypeHelperSingleton.getSavedDocumentTypeDisplayName(
              savedDocument.savedDocumentType
            )}
          </p>
          <div className={styles.savedDocumentDetails}>
            <div
              title={savedDocument.title}
              className={styles.savedDocumentTitle}
            >
              <span className={styles.savedDocumentTitleText}>
                {savedDocument.isOpenAccess && <OpenAccess />}
                {savedDocument.title}
              </span>
              <div ref={openArticleButtonContainerRef}>
                <FontAwesomeIcon
                  title={documentUrl}
                  className={[
                    styles.openUrlIcon,
                    documentUrl ? "" : styles.openUrlIconDisabled,
                  ].join(" ")}
                  icon={faArrowUpRightFromSquare}
                  onClick={onOpenDocumentInNewTabClick}
                  onMouseOver={() => setIsOpenArticleButtonTooltipOpen(true)}
                  onMouseOut={() => setIsOpenArticleButtonTooltipOpen(false)}
                />
                <Tooltip
                  referenceEl={openArticleButtonContainerRef.current}
                  isOpen={isOpenArticleButtonTooltipOpen}
                  tooltipText={
                    documentUrl ? documentUrl : "The document has no URL"
                  }
                  placement="bottom-start"
                />
              </div>
            </div>
            <div className={styles.savedDocumentCountsContainer}>
              {savedDocument.linkedCounts ? (
                <LinkedCounts
                  objectId={savedDocument.id}
                  linkedCounts={savedDocument.linkedCounts}
                />
              ) : null}
            </div>
          </div>
          <div className={styles.creationInformation}>
            <div className={styles.dateAdded}>
              {DateHelperSingleton.getShortenedDate(savedDocument.dateAdded)}
            </div>
            {savedDocument.createdByUsername && (
              <CreatedByAccount
                email={savedDocument.createdByUsername}
                userIconSize="small"
                extraClassNames={{
                  createdByAccountContainer: styles.createdByAccountContainer,
                }}
              />
            )}
          </div>
        </div>
        <div className={styles.savedDocumentItemActions}>
          {onLinkSavedDocumentClick && (
            <FontAwesomeIcon
              title="Link to"
              icon={faLink}
              className={styles.linkIcon}
              onClick={() => {
                onLinkSavedDocumentClick(savedDocument);
              }}
            />
          )}
          {onDeleteSavedDocumentClick && (
            <FontAwesomeIcon
              title={deleteIsUnlink ? "Unlink" : "Delete from inbox"}
              icon={deleteIsUnlink ? faLinkSlash : faTrashCan}
              className={deleteIsUnlink ? styles.unlinkIcon : styles.trashIcon}
              onClick={() => {
                onDeleteSavedDocumentClick(savedDocument);
              }}
            />
          )}
        </div>
      </div>
      <DocumentModal
        refreshDocuments={refreshDocuments}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        document={fromISavedDocumentDTO(savedDocument)}
        onSaveElementClick={async (
          element: TIdNameTypeObjectType,
          closeSavePopupCallback?: () => void
        ) => {
          await onSaveElementClickAsync(element, closeSavePopupCallback);
        }}
      />
    </>
  );
};
