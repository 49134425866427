
// node_modules 
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class CollaborationController {
    private resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/collaboration`;

    public async postAsync(objectId: string): Promise<boolean> {
        try {
            const response = await axios.post(`${this.resourcePath}/${objectId}`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async putAsync(objectId: string): Promise<boolean> {
        try {
            const response = await axios.put(`${this.resourcePath}/${objectId}`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async deleteAsync(objectId: string): Promise<boolean> {
        try {
            const response = await axios.delete(`${this.resourcePath}/${objectId}`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const CollaborationControllerSingleton = new CollaborationController();