// node_modules
import { FC, useContext } from "react";
// Components
import { CreateNewButton } from "./CreateNewButton/CreateNewButton";
import { GlobalSearchBar } from "./GlobalSeachBar/GlobalSearchBar";
import { Pinned } from "./Pinned/Pinned";
import { Activities } from "./Activities/Activities";
import { WindowingSidebar } from "Components";
// Contexts
import { ElementVisibilityContext } from "Providers";
// Styles
import styles from "./topbar.module.scss";

type TTopbarProps = {
    extraClassNames?: { 
        container?: string,
        hasNavigateToButton?: string
    }
};

export const Topbar: FC<TTopbarProps> = ({ extraClassNames = {} }: TTopbarProps) => {
    
    const { isWindowingSidebarVisible } = useContext(ElementVisibilityContext);

    // Render
    return (
        <div className={`${styles.topbarContainer} ${extraClassNames.container ?? ""} ${extraClassNames.hasNavigateToButton ?? ""}`}>
            <div className={styles.navigationButtonsContainer}>
                <Activities />
                <Pinned />
                {isWindowingSidebarVisible && <WindowingSidebar />}
            </div>
            <GlobalSearchBar />
            <CreateNewButton />
        </div>
    );
};