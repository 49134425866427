// node_modules
import { Plugin, PluginKey, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
// Enums
// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";

// init plugin key
export const ratingsPopoverPluginKey = new PluginKey<RatingsPopoverPluginState>(
    "ratingsPopoverPlugin",
);

// define plugin
export const ratingsPopoverPlugin = (showRatingsPopover?: (forTargetId: string, meterElement: HTMLElement) => void,
        hideRatingsPopover?: () => void) => {
    return new Plugin<RatingsPopoverPluginState>({
        key: ratingsPopoverPluginKey,
        state: {
          init() {
            // set default state
            return new RatingsPopoverPluginState(false, undefined);
          },
          apply(tr: Transaction, prev: RatingsPopoverPluginState) {
            return prev.apply(tr);
          },
        },
        props: {
            handleDOMEvents: {
                // on mouse over
                mouseover: (editorView: EditorView, event: MouseEvent) => {
                    // get event target as HTMLElement
                    const target = event.target as HTMLElement;

                    if (ProseMirrorHelperSingleton.isInlineStarsNode(target)) {
                        // update plugin state
                        updateRatingsPopoverPluginState(editorView, true, target);
                    }

                    // if showRatingsPopover and hideRatingsPopover are defined
                    if (showRatingsPopover && hideRatingsPopover) {
                        // call onMouseOverInlineStarsHandler
                        ProseMirrorHelperSingleton
                            .onMouseOverInlineStarsHandler(event, showRatingsPopover, hideRatingsPopover);
                    }
                }
            },
        },
    });
};

// plugin state
class RatingsPopoverPluginState {
    // store is ratings popover open and meter node
    public isRatingsPopoverOpen: boolean;
    public meterMode: Node | undefined;

    // constructor
    constructor(isRatingsPopoverOpen: boolean, meterMode: Node | undefined) {
        this.isRatingsPopoverOpen = isRatingsPopoverOpen;
        this.meterMode = meterMode;
    }

    apply(tr: Transaction): RatingsPopoverPluginState {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const state = this;

        // get action from transaction
        const action = tr.getMeta(ratingsPopoverPluginKey);

        // if action is set
        if (action && action.setData) {
            // get isRatingsPopoverOpen and meterMode from action
            const { isRatingsPopoverOpen, meterMode } = action.setData;
            // return new state
            return new RatingsPopoverPluginState(isRatingsPopoverOpen, meterMode);
        }

        // return old state
        return state;
    }
}

// helper function to update plugin state
function updateRatingsPopoverPluginState(editorView: EditorView, isRatingsPopoverOpen: boolean, meterMode: Node | undefined): void {  
    // disptach transaction to update plugin state
    editorView.dispatch(
        editorView.state.tr.setMeta(ratingsPopoverPluginKey, { 
            setData: { isRatingsPopoverOpen, meterMode }
        })
    );
}

