// node_modules
import { Dispatch, FC, SetStateAction, useCallback, useRef, useState } from "react";
// Types
import { TSearchUnitDTO, TSpecificationUnitDTO } from "Types";
// Enums
import { MathematicalOperationEnum, SearchPriorityEnum, SearchSubTermTypeEnum, ToastTypeEnum } from "Enums";
// Components
import { FindestRefTextBox, SearchableDropdown } from "Components";
import { SpecificationUnit } from "./SpecificationUnit";
import { UnitComparisonDropdown } from "./UnitComparisonDropdown";
// Styles
import styles from "./specificationUnit.module.scss";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Helpers
import { MathematicalOperationHelperSingleton, ToastHelperSingleton } from "Helpers";
// Controllers
import { SearchSpecificationUnitControllerSingleton, SpecificationUnitControllerSingleton } from "Controllers";

type TSpecificationUnitsProps = {
    query: IQueryDTO,
    setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>,
    isSearchTermPriorityDropdown: boolean
}

export const SpecificationUnits: FC<TSpecificationUnitsProps> = ({ query, setQuery, isSearchTermPriorityDropdown }: TSpecificationUnitsProps) => {
    // Ref
    const minimumInputRef = useRef<HTMLInputElement>(null);
    const maxUnitValueInputRef = useRef<HTMLInputElement>(null);
    const unitValueInputRef = useRef<HTMLInputElement>(null);

    // State
    const [newMathematicalOperation, setNewMathematicalOperation] = useState(MathematicalOperationEnum.None);

    // Logic
    const getSpecificationUnitById = (specificationUnitId: number, specificationUnits: TSpecificationUnitDTO[]): TSpecificationUnitDTO | undefined => {
        return specificationUnits.find((specificationUnit: TSpecificationUnitDTO) => specificationUnit.id === specificationUnitId);
    };

    const deleteSpecificationUnitAsync = useCallback(async (specificationUnitId: number): Promise<void> => {
        // get specification unit to delete by id 
        const specificationUnitToDelete: TSpecificationUnitDTO | undefined = getSpecificationUnitById(specificationUnitId, query.specificationUnits);

         // safety-checks
         if (!specificationUnitToDelete) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find specification unit to delete.");
            return;
        }

        // delete specification unit
        const isSuccess: boolean = await SpecificationUnitControllerSingleton
            .deleteAsync(query.guid, specificationUnitToDelete.id);

        // safety-checks
        if (!isSuccess) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not delete specification unit.");
            // stop execution
            return;
        }

        // set new specification units
        const newSpecificationUnits: TSpecificationUnitDTO[] = query
            .specificationUnits
            .filter((specificationUnit: TSpecificationUnitDTO) => specificationUnit.id !== specificationUnitId);
        setQuery((prevQuery: IQueryDTO | undefined) => {
            if (!prevQuery) {
                return prevQuery;
            }

            return {
                ...prevQuery,
                specificationUnits: newSpecificationUnits
            };
        });
    }, [query.guid, query.specificationUnits, setQuery]);

    const updateSearchSubTermValueAsync =useCallback(async (specificationUnitId: number, specificationUnitUpdatedSearchSubTermValue: string | number, searchSubTermType: SearchSubTermTypeEnum): Promise<void> => {
        // get specification unit to update by id
        const specificationUnitToUpdate: TSpecificationUnitDTO | undefined = getSpecificationUnitById(specificationUnitId, query.specificationUnits);

        // safety-checks
        if (!specificationUnitToUpdate) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not find specification unit to update.");
            // stop execution
            return;
        }

        // update specification unit
        if (searchSubTermType === SearchSubTermTypeEnum.UnitValue) {
            specificationUnitToUpdate.unitValue = specificationUnitUpdatedSearchSubTermValue as number;
        } else if(searchSubTermType === SearchSubTermTypeEnum.MaximumUnitValue) {
            specificationUnitToUpdate.maxUnitValue = specificationUnitUpdatedSearchSubTermValue as number;
        } else {
            specificationUnitToUpdate.unit = specificationUnitUpdatedSearchSubTermValue as string;
        }

        // update specification unit
        const isSuccess: boolean = await SpecificationUnitControllerSingleton
            .updateAsync(query.guid, specificationUnitToUpdate);

        // safety-checks
        if (!isSuccess) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update specification unit.");
            // stop execution
            return;
        }

        // set new specification units
        const newSpecificationUnits: TSpecificationUnitDTO[] = query
            .specificationUnits
            .map((specificationUnit: TSpecificationUnitDTO) => {
                if (specificationUnit.id === specificationUnitId) {
                    return specificationUnitToUpdate;
                }

                return specificationUnit;
            });
        setQuery((prevQuery: IQueryDTO | undefined) => {
            if (!prevQuery) {
                return prevQuery;
            }

            return {
                ...prevQuery,
                specificationUnits: newSpecificationUnits
            };
        });
    }, [query.guid, query.specificationUnits, setQuery]);

    const updateSpecificationUnitSearchPriorityAsync = useCallback(async (specificationUnitId: number, specificationUnitUpdatedSearchPriority: SearchPriorityEnum): Promise<void> => {
        // get specification unit to update by id
        const specificationUnitToUpdate: TSpecificationUnitDTO | undefined = getSpecificationUnitById(specificationUnitId, query.specificationUnits);

        // safety-checks
        if (!specificationUnitToUpdate) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not find specification unit to update.");
            // stop execution
            return;
        }

        // update specification unit
        specificationUnitToUpdate.searchType = specificationUnitUpdatedSearchPriority;

        // update specification unit
        const isSuccess: boolean = await SpecificationUnitControllerSingleton
            .updateAsync(query.guid, specificationUnitToUpdate);

        // safety-checks
        if (!isSuccess) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update specification unit.");
            // stop execution
            return;
        }

        // set new specification units
        const newSpecificationUnits: TSpecificationUnitDTO[] = query
            .specificationUnits
            .map((specificationUnit: TSpecificationUnitDTO) => {
                if (specificationUnit.id === specificationUnitId) {
                    return specificationUnitToUpdate;
                }

                return specificationUnit;
            });
        setQuery((prevQuery: IQueryDTO | undefined) => {
            if (!prevQuery) {
                return prevQuery;
            }

            return {
                ...prevQuery,
                specificationUnits: newSpecificationUnits
            };
        });
    }, [query.guid, query.specificationUnits, setQuery]);

    const updateSpecificationUnitMathLimitTypeAsync = useCallback(async (specificationUnitId: number, specificationUnitUpdatedMathLimitType: MathematicalOperationEnum): Promise<void> => {
        // get specification unit to update by id
        const specificationUnitToUpdate: TSpecificationUnitDTO | undefined = getSpecificationUnitById(specificationUnitId, query.specificationUnits);

        // safety-checks
        if (!specificationUnitToUpdate) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not find specification unit to update.");
            // stop execution
            return;
        }

        // update specification unit
        specificationUnitToUpdate.mathLimitType = specificationUnitUpdatedMathLimitType;

        // update specification unit
        const isSuccess: boolean = await SpecificationUnitControllerSingleton
            .updateAsync(query.guid, specificationUnitToUpdate);

        // safety-checks
        if (!isSuccess) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not update specification unit.");
            // stop execution
            return;
        }

        // set new specification units
        const newSpecificationUnits: TSpecificationUnitDTO[] = query
            .specificationUnits
            .map((specificationUnit: TSpecificationUnitDTO) => {
                if (specificationUnit.id === specificationUnitId) {
                    return specificationUnitToUpdate;
                }

                return specificationUnit;
            });
        setQuery((prevQuery: IQueryDTO | undefined) => {
            if (!prevQuery) {
                return prevQuery;
            }

            return {
                ...prevQuery,
                specificationUnits: newSpecificationUnits
            };
        });
    }, [query.guid, query.specificationUnits, setQuery]);


    const onResultClickAsync = useCallback(async (result: TSearchUnitDTO): Promise<boolean> => {
        // get unit text from result
        const unitText: string = result.name;
        // trim unit text
        const trimmedUnitText: string = unitText.trim();

        // init new specification unit data
        const newSpecificationUnit: TSpecificationUnitDTO = {
            id: 0,
            specification: "",
            mathLimitType: newMathematicalOperation,
            unit: trimmedUnitText,
            searchType: SearchPriorityEnum.Must,
            unitSynonyms: [],
            savedQueryId: query.guid
        };

        // set unit value and max unit value depending on mathematical operation type
        if (newMathematicalOperation === MathematicalOperationEnum.Between) {
            // safety-checks
            if (!minimumInputRef.current || !maxUnitValueInputRef.current) {
                return false;
            }

            // set unit value and max unit value
            newSpecificationUnit.unitValue = parseFloat(minimumInputRef.current.value);
            newSpecificationUnit.maxUnitValue = parseFloat(maxUnitValueInputRef.current.value);

            // safety-checks
            if (isNaN(newSpecificationUnit.unitValue) || isNaN(newSpecificationUnit.maxUnitValue)) {
                // show error toast
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Please input a valid number for both minnimum and maximum unit value.");
                // stop execution
                return false;
            }
        } else if (newMathematicalOperation === MathematicalOperationEnum.GreaterThan || newMathematicalOperation === MathematicalOperationEnum.LessThan) {
            // safety-checks
            if (!unitValueInputRef.current) {
                return false;
            }

            // set unit value
            newSpecificationUnit.unitValue = parseFloat(unitValueInputRef.current.value);
            // safety-checks
            if (isNaN(newSpecificationUnit.unitValue)) {
                // show error toast
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Please input a valid number for unit value.");
                // stop execution
                return false; 
            }
        }

        // create new specification unit
        const createdSpecificationUnit: TSpecificationUnitDTO | undefined  = await SpecificationUnitControllerSingleton
            .createAsync(query.guid, newSpecificationUnit);

        // safety-checks
        if (!createdSpecificationUnit) {
            // show error message
            ToastHelperSingleton
                .showToast(ToastTypeEnum.Error, "Could not create specification unit.");
            // stop execution
            return false;
        }

        // add new specification unit to query
        setQuery((prevQuery: IQueryDTO | undefined) => {
            if (!prevQuery) {
                return prevQuery;
            }

            return {
                ...prevQuery,
                specificationUnits: [
                    ...prevQuery.specificationUnits,
                    createdSpecificationUnit
                ]
            };
        });

        // empty add specification unit input refs
        if(minimumInputRef.current) {
            minimumInputRef.current.value = "";
        }
        if(maxUnitValueInputRef.current) {
            maxUnitValueInputRef.current.value = "";
        }
        if(unitValueInputRef.current) {
            unitValueInputRef.current.value = "";
        }

        // return success
        return true;
    }, [newMathematicalOperation, query.guid, setQuery]);

    return (
        <div className={styles.userFlowDataList}>
            <h5 className={styles.dataListTitle}>Specifications</h5>
            <div className={styles.dataListInputContainer}>
                <UnitComparisonDropdown 
                    mathematicalOperation={newMathematicalOperation} 
                    selectMathOperation={setNewMathematicalOperation} />
                {newMathematicalOperation !== MathematicalOperationEnum.None ?
                    newMathematicalOperation === MathematicalOperationEnum.Between ?
                            <div style={{display: "flex"}}>
                                <FindestRefTextBox 
                                    onChange={(text: string) => { if (minimumInputRef.current) { minimumInputRef.current.value = text; }}} 
                                    extraClassName={styles.unitValueInputContainer} 
                                    extraClassNameInputElement={styles.unitValueInputField} 
                                    placeholder="Minimum" 
                                    ref={minimumInputRef} />
                                <span className={styles.rangeDivider}>-</span>
                                <FindestRefTextBox
                                    onChange={(text: string) => { if (maxUnitValueInputRef.current) { maxUnitValueInputRef.current.value = text; }}} 
                                    extraClassName={styles.unitValueInputContainer} 
                                    extraClassNameInputElement={styles.unitValueInputField} 
                                    placeholder="Maximum" 
                                    ref={maxUnitValueInputRef} />
                            </div>
                        : 
                            <FindestRefTextBox 
                            onChange={(text: string) => { if (unitValueInputRef.current) { unitValueInputRef.current.value = text; }}} 
                                extraClassName={styles.unitValueInputContainer} 
                                extraClassNameInputElement={styles.unitValueInputField} 
                                placeholder={MathematicalOperationHelperSingleton.getMathematicalOperationDisplayName(newMathematicalOperation)} 
                                ref={unitValueInputRef} />
                    : 
                        null
                }
                <SearchableDropdown<TSearchUnitDTO> 
                    placeholder="Unit" itemName="unit" 
                    asyncSearchFunction={SearchSpecificationUnitControllerSingleton.getAsync}
                    doClearValueOnClick={true} 
                    resultToIdAndName={SearchSpecificationUnitControllerSingleton.searchSpecificationUnitToIdAndName} 
                    onResultClickAsync={onResultClickAsync}
                    overriddenStyles={styles} 
                    doSearchWhenValueEmpty={true} />
            </div>
            {query.specificationUnits.map((specificationUnit: TSpecificationUnitDTO) => {
                return <SpecificationUnit 
                    key={specificationUnit.id} 
                    specificationUnit={specificationUnit} 
                    deleteSpecificationUnitAsync={deleteSpecificationUnitAsync}
                    updateSearchSubTermValueAsync={updateSearchSubTermValueAsync} 
                    updateSpecificationUnitSearchPriorityAsync={updateSpecificationUnitSearchPriorityAsync}
                    updateSpecificationUnitMathLimitTypeAsync={updateSpecificationUnitMathLimitTypeAsync} 
                    isSearchTermPriorityDropdown={isSearchTermPriorityDropdown} />;
            })}
        </div>
    );
};