// node_modules
import { IEntityDTO, IStudyDTO } from "Interfaces";
import { DragEvent, useCallback, useState } from "react";
// Types
import { TIdNameTypeObjectType, TUseDragAndDrop } from "Types";

export const useDragAndDrop = (
    onDropHandlerAsync: (
        draggedObject: TIdNameTypeObjectType,
        draggedOverObject: TIdNameTypeObjectType,
        draggedObjectParent: TIdNameTypeObjectType | undefined,
        callback?: (createdObject: TIdNameTypeObjectType) => void
    ) => Promise<void>): TUseDragAndDrop => {
    // State
    const [draggedObject, setDraggedObject] = useState<TIdNameTypeObjectType | undefined>(undefined);
    const [draggedObjectUpperLevelNode, setDraggedObjectUpperLevelNode] = useState<TIdNameTypeObjectType | undefined>(undefined);
    const [draggedOverObject, setDraggedOverObject] = useState<TIdNameTypeObjectType | undefined>(undefined);

    // Logic
    // reset hook state variables
    const resetHookStateVariables = useCallback((): void => {
        // reset hook state variables (values to undefined)
        setDraggedObject(undefined);
        setDraggedObjectUpperLevelNode(undefined);
        setDraggedOverObject(undefined);
    }, []);

    // on drag start handler
    const onDragStart = (newDraggedObject: TIdNameTypeObjectType, newDraggedObjectUpperLevelNode: TIdNameTypeObjectType | undefined): void => {
        // set new dragged object
        setDraggedObject(newDraggedObject);

        // set new dragged object upper level node
        setDraggedObjectUpperLevelNode(newDraggedObjectUpperLevelNode);
    };

    // on drag over handler
    const onDragOver = useCallback((dragEvent: DragEvent, newDraggedOverObject: TIdNameTypeObjectType): void => {
        // prevent default
        dragEvent.preventDefault();

        // stop propagation
        dragEvent.stopPropagation();

        // set new dragged over object
        setDraggedOverObject(newDraggedOverObject);
    }, []);

    // on drop handler
    const onDrop = useCallback(async (dragEvent: DragEvent, callback?: (createdObject: TIdNameTypeObjectType) => void): Promise<void | IEntityDTO | IStudyDTO> => {
        // prevent default
        dragEvent.preventDefault();

        // stop propagation
        dragEvent.stopPropagation();

        // safety-checks
        if (!draggedObject || !draggedOverObject || (draggedObject.id === draggedOverObject.id) ||
                (draggedObjectUpperLevelNode && (draggedObjectUpperLevelNode.id === draggedOverObject.id))) {
            // reset hook state variables
            resetHookStateVariables();

            // stop execution, return
            return;
        }

        // await on drop handler
        await onDropHandlerAsync(draggedObject, draggedOverObject, draggedObjectUpperLevelNode, callback);

        // reset hook state variables
        resetHookStateVariables();
    }, [draggedObject, draggedObjectUpperLevelNode, draggedOverObject, resetHookStateVariables, onDropHandlerAsync]);

    // on drag leave handler
    const onDragLeave = useCallback((dragEvent: DragEvent): void => {
        // prevent default
        dragEvent.preventDefault();

        // stop propagation
        dragEvent.stopPropagation();

        // reset hook state variable
        setDraggedOverObject(undefined);
    }, []);

    // is object dragged over
    const isObjectDraggedOver = useCallback((objectId: string): boolean => {
        // safety-checks
        if (!draggedOverObject || !draggedObject) {
            // return false
            return false;
        }

        return draggedOverObject.id === objectId;
    }, [draggedObject, draggedOverObject]);

    // Return hook values
    return {
        onDragStart,
        onDragOver,
        onDrop,
        onDragLeave,
        draggedObject,
        draggedOverObject,
        isObjectDraggedOver
    };
};
