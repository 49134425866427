// node_modules
import { Dispatch, FC, SetStateAction, useCallback } from "react";
// Enums
import { ObjectTypeEnum } from "Enums";
// Components
import { Popover } from "Components";
import { MaturityLevelsPopoverItem } from "./MaturityLevelsPopoverItem";
// Helpers
import { MaturityLevelHelperSingleton } from "Helpers";
// Types
import { TMaturityRadarAssessmentDTO, TMaturityRadarDTO } from "Types";
// Styles
import styles from "./maturityLevelsPopover.module.scss";

type TMaturityLevelsPopoverProps = {
    targetId: string,
    targetType: ObjectTypeEnum,
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>,
    onChange: (newMin: number, newMax: number) => void,
    maturityLevels: TMaturityRadarDTO[],
    setMaturityLevels: Dispatch<SetStateAction<TMaturityRadarDTO[]>>,
    popoverReferenceEl: HTMLDivElement | null
};

export const MaturityLevelsPopover: FC<TMaturityLevelsPopoverProps> = ({ targetId, targetType, isOpen, setIsOpen, onChange, maturityLevels, setMaturityLevels, popoverReferenceEl }: TMaturityLevelsPopoverProps) => {
    // Logic
    const onMouseEnter = () => {
        setIsOpen(true);
    };

    const onMouseLeave = () => {
        setIsOpen(false);
    };

    const onMaturityLevelsPopoverItemChangeAsync = useCallback(async (forSourceId: string, forSourceType: ObjectTypeEnum, newMin: number, newMax: number): Promise<void> => {
        // init new maturity levels
        const newMaturityLevels: TMaturityRadarDTO[] = [];

        // go through each maturity level
        maturityLevels.forEach((maturityLevel: TMaturityRadarDTO) => {
            // init new assessments
            const newAssessments: TMaturityRadarAssessmentDTO[] = [];

            // go through each assessment
            maturityLevel.assessments.forEach((assessment: TMaturityRadarAssessmentDTO) => {
                // if source id, source type, target id and target type match
                if (maturityLevel.sourceId === forSourceId && maturityLevel.sourceType === forSourceType &&
                        assessment.targetId === targetId && assessment.targetType === targetType) {
                    // set new min and max
                    assessment.lowScore = newMin;
                    assessment.highScore = newMax;
                }

                // add assessment to new assessments
                newAssessments.push(assessment);
            });


            // set new assessments
            maturityLevel.assessments = [...newAssessments];

            // add maturity level to new maturity levels
            newMaturityLevels.push(maturityLevel);
        });

        // set new maturity levels
        setMaturityLevels([...newMaturityLevels]);

        // get new average
        const { averageMin, averageMax } = MaturityLevelHelperSingleton
            .getAverage(newMaturityLevels, targetId);

        // get sanitized values
        const { newSafeMin: newSafeAverageMin, newSafeMax: newSafeAverageMax } = MaturityLevelHelperSingleton
            .sanitizeValues(averageMin, averageMax);

        // call on change prop
        onChange(newSafeAverageMin, newSafeAverageMax);
    }, [maturityLevels, onChange, setMaturityLevels, targetId, targetType]);

    // Render
    return (
        <Popover
            referenceEl={popoverReferenceEl}
            isOpen={isOpen}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            extraClassName={styles.maturityLevelsPopover}
            placement="bottom-end"
        >
            <h3 className={styles.popoverTitle}>
                Maturity
            </h3>
            <div>
                {maturityLevels.map((maturityLevel: TMaturityRadarDTO) =>
                    maturityLevel.assessments.map((assessment: TMaturityRadarAssessmentDTO) => {
                        if (assessment.targetId === targetId && assessment.targetType === targetType) {
                            return (
                                <MaturityLevelsPopoverItem
                                    key={assessment.id}
                                    min={assessment.lowScore}
                                    max={assessment.highScore}
                                    assessmentId={assessment.id}
                                    sourceId={maturityLevel.sourceId}
                                    sourceTitle={maturityLevel.sourceTitle}
                                    sourceType={maturityLevel.sourceType}
                                    onChange={onMaturityLevelsPopoverItemChangeAsync} />
                            );
                        }
                    })
                )}
            </div>
        </Popover>
    );
};