// node_modules
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ChangeEvent, FC, useEffect, useState } from "react";
// Components
import { FindestButton, TextArea } from "Components";
import { Modal } from "./Modal";
// Styles
import sharedModalStyles from "./modal.module.scss";

type TTextAreaModalProps = {
    title: string,
    textName: string,
    isOpen: boolean,
    placeHolder: string,
    setIsOpen: (isOpen: boolean) => void,
    onSaveButtonClick: (textValue: string) => void,
}

export const TextAreaModal: FC<TTextAreaModalProps> = ({ title, isOpen, textName, 
        placeHolder, setIsOpen, onSaveButtonClick}: TTextAreaModalProps) => {
    // State
    const [textValue, setTextValue] = useState<string>("");
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(true);
    
    // Logic
    useEffect(() => {
        // disable create button if no text value is entered
        if (!textValue) {
            setIsCreateButtonDisabled(true);
        } else {
            setIsCreateButtonDisabled(false);
        }
    }, [textValue]);

    const onSaveButtonClickHandler = () => {
        if(!isCreateButtonDisabled) {
            onSaveButtonClick(textValue);
        }
    };

    const resetModalStateAndClose = () => {
        setTextValue("");
        setIsCreateButtonDisabled(true);
        setIsOpen(false);
    };

    // Render
    return (
        <Modal
        isOpen={isOpen}
        onClose={resetModalStateAndClose} 
        header={title}>
            <div className={sharedModalStyles.section}>
                <div className={sharedModalStyles.title}>{textName}</div>
                <TextArea 
                    placeholder={placeHolder}
                    value={textValue} 
                    onChange={(changeEvent: ChangeEvent<HTMLTextAreaElement>) => setTextValue(changeEvent.target.value)} />
            </div>

            <FindestButton
                extraClassName={sharedModalStyles.button}
                leftIconName={faPlus}
                title="Save"
                onClick={onSaveButtonClickHandler}
                isDisabled={isCreateButtonDisabled}
            />
        </Modal>
    );
};