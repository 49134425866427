// node_modules
import { FC, useContext, useMemo } from "react";
// Contexts
import { AuthContext, CollaborationContext, EditorContext, EditorReferencesContext } from "Providers";
// Enums
import { EditorFeatureEnum } from "Enums";
// Components
import { EditorMenu } from "./EditorMenu/EditorMenu";

type TContextedEditorMenuProps = {
    onAddImageClick: () => void,
    onAttachFileClickHandler: (file: File) => Promise<void>,
}

export const ContextedEditorMenu: FC<TContextedEditorMenuProps> = ({ onAddImageClick, onAttachFileClickHandler }: TContextedEditorMenuProps) => {
    // Contexts
    const { editorMenuProps, editorView } = useContext(EditorContext);
    const { isEditorShown, isEditModeOn, objectIdEdited, objectTypeEdited } = useContext(CollaborationContext);
    const { isSelectionInCustomNode } = useContext(EditorReferencesContext);
    const { hasAdvanced } = useContext(AuthContext);

    const featuresDisabled: EditorFeatureEnum[] | undefined = useMemo(() => {
        return hasAdvanced ? undefined : [EditorFeatureEnum.ScoutingService];
    }, [hasAdvanced]);
    
    // Render
    return (
        isEditorShown ?
            <EditorMenu 
                objectIdEdited={objectIdEdited}
                objectTypeEdited={objectTypeEdited}
                isEditModeOn={isEditModeOn}
                onAddImageClick={onAddImageClick}
                onAttachFileClickHandler={onAttachFileClickHandler}
                {...editorMenuProps} 
                editorView={editorView}
                isSelectionInCustomNode={isSelectionInCustomNode} 
                featuresDisabled={featuresDisabled}/>
        :
            null
    );
};