// Enums
import { LinkStatusEnum, SavedDocumentTypeEnum } from "Enums";
// Types
import { TOption, TOptions } from "Types";

export class DocumentTypeHelper {
    private savedDocumentTypeStringToEnumMapping: { [name: string]: SavedDocumentTypeEnum } = {
        "sciencearticle": SavedDocumentTypeEnum.ScienceArticle,
        "science": SavedDocumentTypeEnum.ScienceArticle,
        "uspatent": SavedDocumentTypeEnum.UsPatent,
        "magpatent": SavedDocumentTypeEnum.MagPatent,
        "patent": SavedDocumentTypeEnum.UsPatent,
        "Webpage": SavedDocumentTypeEnum.Weblink
    };

    private getSavedDocumentPairs(): TOption<SavedDocumentTypeEnum>[] {
        return Object
        .keys(SavedDocumentTypeEnum)
        .filter((key) => !Number.isNaN(Number(key)))
        .map((key) => {
            return ({
                value: parseInt(key),
                title: this.getSavedDocumentTypeDisplayName(parseInt(key))
            });
        });
    }

    public getSavedDocumentTypeDisplayName(savedDocumentType: SavedDocumentTypeEnum): string {
        switch (savedDocumentType) {
            case SavedDocumentTypeEnum.ScienceArticle:
                return "Science";
            case SavedDocumentTypeEnum.UsPatent:
            case SavedDocumentTypeEnum.MagPatent: 
                return "Patent";
            case SavedDocumentTypeEnum.Weblink:
                return "Webpage";
            default:
                return "Webpage";
        }
    }

    public savedDocumentTypeStringToEnum(savedDocumentType: string): SavedDocumentTypeEnum {
        return this.savedDocumentTypeStringToEnumMapping[savedDocumentType.toLowerCase()];
    }

    public savedDocumentDropdownOptions: TOptions<SavedDocumentTypeEnum>[] = [
        {
            group: "Doc Type",
            options: this.getSavedDocumentPairs().filter(pair => pair.value !== SavedDocumentTypeEnum.MagPatent)
        }
    ];

    public getSelectedFilterOptions(filterOptions: TOption<(SavedDocumentTypeEnum | LinkStatusEnum)>[]): TOption<SavedDocumentTypeEnum>[] {
        const newSelectedFilterOptions: TOption<SavedDocumentTypeEnum>[] = [];

        for (const filterOption of filterOptions) {
            if (this.allDocumentTypes.includes(filterOption.value as SavedDocumentTypeEnum)) {
                newSelectedFilterOptions.push(filterOption as TOption<SavedDocumentTypeEnum>);
            }
        }

        return newSelectedFilterOptions;
    }

    public allDocumentTypes: SavedDocumentTypeEnum[] = Object.values(SavedDocumentTypeEnum) as SavedDocumentTypeEnum[];
}

export const DocumentTypeHelperSingleton = new DocumentTypeHelper();