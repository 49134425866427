// node_modules
import { faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useRef,
} from "react";
// Styles
import styles from "./findestTextBox.module.scss";

type TFindestTextBoxProps = {
  children?: React.ReactNode | React.ReactNode[];
  value?: string;
  inTextboxPlaceholder?: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  leftIcon?: IconDefinition;
  showEmptyInputCrossIcon?: boolean;
  width?: number;
  isBorderless?: boolean;
  hasNoPadding?: boolean;
  doAutoFocus?: boolean;
  isReadonly?: boolean;
  extraClassName?: string;
  extraClassNameInputElement?: string;
  onChange?: (text: string) => void;
  onEnter?: (text: string, inputRef: HTMLInputElement) => void;
  onClick?: () => void;
  onBlur?: () => void;
  onInputFocus?: (event: FocusEvent) => void;
  onEmptyInputButtonClickHandler?: () => void;
  rightIconProps?: {
    icon?: IconDefinition;
    tooltip?: string;
    onClick?: () => void;
    customIcon?: React.ReactNode;
  };
  autoComplete?: string;
  extraClassNames?: { iconContainerLeft?: string };
};

export const FindestTextBox: FC<TFindestTextBoxProps> = ({
  value,
  placeholder,
  leftIcon,
  showEmptyInputCrossIcon,
  width,
  isBorderless,
  hasNoPadding,
  type = "text",
  doAutoFocus,
  isReadonly,
  extraClassName,
  extraClassNameInputElement,
  children,
  onChange,
  onEnter,
  onClick,
  onBlur,
  onInputFocus,
  onEmptyInputButtonClickHandler,
  rightIconProps,
  autoComplete,
  extraClassNames = {},
}: TFindestTextBoxProps) => {
  // Ref
  const inputRef = useRef<HTMLInputElement>(null);

  // Logic
  useEffect(() => {
    if (inputRef.current) {
      if (value) {
        inputRef.current.value = value;
      } else {
        inputRef.current.value = "";
      }
    }
  }, [value]);

  const handleOnChange = (changeEvent: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      changeEvent.preventDefault();
      changeEvent.stopPropagation();
      onChange(changeEvent.target.value);
    }
  };

  const handleOnKeyDown = (
    keyboardEvent: KeyboardEvent<HTMLInputElement>
  ): void => {
    if (
      onEnter &&
      keyboardEvent.key === "Enter" &&
      inputRef.current &&
      inputRef.current.value
    ) {
      onEnter(keyboardEvent.currentTarget.value, inputRef.current);
    }
  };

  const onEmptyInputButtonClick = (): void => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
      if (onChange) {
        onChange("");
      }
      if (onEmptyInputButtonClickHandler) {
        onEmptyInputButtonClickHandler();
      }
    }
  };

  const getClassName = (): string => {
    const classNameList = [styles.findestTextBox];
    if (isBorderless) classNameList.push(styles.borderlessTextBox);
    if (hasNoPadding) classNameList.push(styles.noPaddingTextBox);
    return classNameList.join(" ");
  };

  return (
    <div
      className={[getClassName(), extraClassName ? extraClassName : ""].join(
        " "
      )}
      onClick={onClick}
    >
      {!children && leftIcon ? (
        <div
          className={`${styles.iconContainerLeft} ${
            extraClassNames.iconContainerLeft ?? ""
          }`}
        >
          <FontAwesomeIcon className={styles.icon} icon={leftIcon} />
        </div>
      ) : null}
      {showEmptyInputCrossIcon && (
        <div
          className={`${styles.iconContainerRight} ${styles.clickable}`}
          onClick={onEmptyInputButtonClick}
        >
          <FontAwesomeIcon
            className={styles.emptyInputCrossIcon}
            icon={faTimes}
          />
        </div>
      )}
      {rightIconProps && rightIconProps.icon && (
        <div
          className={`${styles.iconContainerRight} ${styles.clickable}`}
          onClick={rightIconProps.onClick}
        >
          <FontAwesomeIcon
            className={styles.rightIcon}
            icon={rightIconProps.icon}
            title={rightIconProps.tooltip}
          />
        </div>
      )}
      {rightIconProps?.customIcon && (
        <div className={styles.iconContainerRight}>
          {rightIconProps.customIcon}
        </div>
      )}
      <input
        autoFocus={doAutoFocus}
        type={type}
        ref={inputRef}
        style={{ width: width }}
        placeholder={placeholder}
        className={[
          extraClassNameInputElement ? extraClassNameInputElement : "",
          styles.textBox,
        ].join(" ")}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onBlur={onBlur}
        onFocus={onInputFocus}
        disabled={isReadonly}
        {...(autoComplete ? { autoComplete } : {})}
      />
      {children && !leftIcon && !showEmptyInputCrossIcon ? children : null}
    </div>
  );
};
