// Enums
import { EntityTypeEnum, LinkStatusEnum, OwnershipEnum, SavedDocumentTypeEnum, StudyStatusEnum, StudyTypeEnum, ReferenceSidebarFilterTypeEnum } from "Enums";
// Helpers
import { LocalStorageHelperSingleton } from "Helpers";
import { TOption } from "Types";

class SavedFiltersHelper {
    private getFilters<T>(key: string): TOption<T>[] {
        // get filters from local storage
        const filters: TOption<T>[] | undefined = LocalStorageHelperSingleton.getItem<TOption<T>[]>(key);

        // if filters not defined, return empty array
        return filters ?? [];
    }

    public getEntitiesFilters(): TOption<(EntityTypeEnum | OwnershipEnum)>[] {
        // get entities filters from local storage
        return this.getFilters<(EntityTypeEnum | OwnershipEnum)>("entitiesFilters");
    }

    public getStudiesFilters(): TOption<(StudyTypeEnum | StudyStatusEnum | OwnershipEnum)>[] {
        // get studies filters from local storage
        return this.getFilters<(StudyStatusEnum | OwnershipEnum)>("studiesFilters");
    }

    public getQueriesFilters(): TOption<OwnershipEnum>[] {
        // get queries filters from local storage
        return this.getFilters<OwnershipEnum>("queriesFilters");
    }

    public getDocumentsFilters(filterToExclude?: SavedDocumentTypeEnum | LinkStatusEnum): TOption<SavedDocumentTypeEnum | LinkStatusEnum>[] {
        // get documents filters from local storage
        let documentsFilters: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[] = this.getFilters<SavedDocumentTypeEnum | LinkStatusEnum>("documentsFilters");

        // filter out the list if needed
        if (filterToExclude) {
            documentsFilters = documentsFilters.filter((filter) => filter.value !== filterToExclude);
        }

        // return documents filters
        return documentsFilters;
    }

    public getReferenceSidebarFilters(): TOption<ReferenceSidebarFilterTypeEnum>[] {
        // get the reference sidebar filters from local storage
        return this.getFilters<ReferenceSidebarFilterTypeEnum>("referenceSidebarFilters");
    }

    private saveFilters<T>(filters: T[], key: string): void {
        // set filters in local storage
        LocalStorageHelperSingleton.setItem<T[]>(filters, key);
    }

    public saveEntitiesFilters(filters: TOption<(EntityTypeEnum | OwnershipEnum)>[]): void {
        // set entities filters in local storage
        this.saveFilters(filters, "entitiesFilters");
    }

    public saveStudiesFilters(filters: TOption<(StudyTypeEnum | StudyStatusEnum | OwnershipEnum)>[]): void {
        // set studies filters in local storage
        this.saveFilters(filters, "studiesFilters");
    }

    public saveDocumentsFilters(filters: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[]): void {
        // set documents filters in local storage
        this.saveFilters(filters, "documentsFilters");
    }

    public saveQueriesFilters(filters: TOption<OwnershipEnum>[]): void {
        // set queries filters in local storage
        this.saveFilters(filters, "queriesFilters");
    }

    public saveReferenceSidebarFilters(filters: TOption<ReferenceSidebarFilterTypeEnum>[]): void {
        // set reference sidebar filters in local storage
        this.saveFilters(filters, "referenceSidebarFilters");
    }
}

// export the SavedFiltersHelper class as a Singleton
export const SavedFiltersHelperSingleton = new SavedFiltersHelper();