// React
import { FC, useEffect, useState } from "react";
// Components
import { FindestButton, FindestTextBox } from "Components";
import { Modal } from "./Modal";
// Styles
import sharedModalStyles from "./modal.module.scss";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type TTextBoxModalProps = {
    title: string,
    textName: string,
    isOpen: boolean,
    placeHolder: string,
    setIsOpen: (isOpen: boolean) => void,
    onSaveButtonClick: (textValue: string) => void,
}

export const TextBoxModal: FC<TTextBoxModalProps> = ({title, textName, isOpen, setIsOpen, 
        onSaveButtonClick}: TTextBoxModalProps) => {

    // State
    const [textValue, setTextValue] = useState<string>("");
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(true);
    
    // Logic
    useEffect(() => {
        // disable create button if no text value is entered
        if (!textValue) {
            setIsCreateButtonDisabled(true);
        } else {
            setIsCreateButtonDisabled(false);
        }
    }, [textValue]);

    const onTextChange = (textValue: string) => {
        setTextValue(textValue);
    };

    const onSaveButtonClickHandler = () => {
        if(!isCreateButtonDisabled) {
            onSaveButtonClick(textValue);
        }
    };

    const resetModalStateAndClose = () => {
        setTextValue("");
        setIsCreateButtonDisabled(true);
        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={resetModalStateAndClose} 
            header={title}>
            <div className={sharedModalStyles.section}>
                <div className={sharedModalStyles.title}>{textName}</div>
                <FindestTextBox onChange={onTextChange} />
            </div>

            <FindestButton
                extraClassName={sharedModalStyles.button}
                leftIconName={faPlus}
                title="Save"
                onClick={onSaveButtonClickHandler}
                isDisabled={isCreateButtonDisabled}
            />
        </Modal>
    );

};