// React
import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
// Font Awesome
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import { FindestButton, LoadingStatusIndicator, MarkdownItComponent, Modal } from "Components";
import { AskAIAssistantLineChats } from "./AskAIAssistantLineChats/AskAIAssistantLineChats";
import { AskAIAssistantModalMenu } from "./AskAIAssistantModalMenu/AskAIAssistantModalMenu";
// Styles
import styles from "./askAiAssistantModal.module.scss";
// Constants
import { AiConstants } from "Constants";
// Enums
import { AskAIAssistantMenuItemEnum, ToastTypeEnum } from "Enums";
// Contexts
import { CollaborationContext, EditorContext } from "Providers";
// Types
import { TAskAIAssistantRequirement } from "Types";
// Helpers
import { ToastHelperSingleton } from "Helpers";

// Component props type
type TAskAIAssistantModalProps = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    inputText: string,
    aiGeneratedText: string,
    setAIGeneratedText: Dispatch<SetStateAction<string>>,
    isGeneratingText: boolean,
    requestAssistanceAsync: (text: string, selectedMenuItem: AskAIAssistantMenuItemEnum) => Promise<void>,
    insertAIGeneratedTextHandler: (aiGeneratedText: string, selectedMenuItem: AskAIAssistantMenuItemEnum) => void,
    onCloseCallback: () => void,
    onCancel: () => void,
    selectedMenuItem: AskAIAssistantMenuItemEnum | undefined,
    setSelectedMenuItem: Dispatch<SetStateAction<AskAIAssistantMenuItemEnum | undefined>>,
    resetAskAIAssistant: () => void
}

export const AskAIAssistantModal: FC<TAskAIAssistantModalProps> = ({
        isOpen, setIsOpen, inputText, aiGeneratedText, 
        setAIGeneratedText, isGeneratingText,
        insertAIGeneratedTextHandler,
        onCloseCallback,
        onCancel, selectedMenuItem, resetAskAIAssistant,
        requestAssistanceAsync, setSelectedMenuItem}: TAskAIAssistantModalProps) => {
    // Context
    const { objectName } = useContext(EditorContext);
    const { objectIdEdited, objectTypeEdited } = useContext(CollaborationContext);

    // State
    const [defaultInput, setDefaultInput] = useState<string>();
    const [shouldAddBoxShadowToFooter, setShouldAddBoxShadowToFooter] = useState<boolean>(false);

    // Memo
    const isAIGeneratedTextEmpty = useMemo(() => !aiGeneratedText, [aiGeneratedText]);
    const textToShowWhileGenerating = useMemo(() => "I am generating the answer, it could take few minutes, please wait...", []);
    const doShowLoadingIndicator = useMemo(() => isGeneratingText && aiGeneratedText.length === 0,
        [isGeneratingText, aiGeneratedText]);
    const doShowText = useMemo(() => aiGeneratedText.length > 0, [aiGeneratedText.length]);
    const didStreamErrorHappen = useMemo(() => aiGeneratedText.startsWith(AiConstants.ERROR_START_MESSAGE), 
        [aiGeneratedText]);
    const aiGeneratedTextWithoutError = useMemo(() => aiGeneratedText.replace(AiConstants.ERROR_START_MESSAGE, ""),
        [aiGeneratedText]);

    // Refs
    const bodyRef = useRef<HTMLDivElement>(null);
    // The observer needs to be a ref, because it's used in the useEffect
    // and we need to be able to disconnect it when the users scrolls
    const observerRef = useRef<MutationObserver | undefined>(undefined);

    // when inputText changes
    useEffect(() => {
        // set default input to input text
        setDefaultInput(inputText);
    }, [inputText]);

    // Scroll to bottom when the bodyRef changes
    const scrollNeededCallback = (mutationList: MutationRecord[]): void => {
        if(!bodyRef.current) return;
        for (const mutation of mutationList) {
            if (mutation.type === "childList") {
                bodyRef.current.scrollTo(0, bodyRef.current.scrollHeight);
            }
        }
    };

    // If the generation is started then start observing the bodyRef
    useEffect(() => {
        // If the generation is started then start observing the bodyRef
        if (isGeneratingText) {
            const bodyElement = bodyRef.current;
            if (bodyElement && !observerRef.current) {
                const observer = new MutationObserver(scrollNeededCallback);
                observer.observe(bodyElement, { childList: true, subtree: true  });
                observerRef.current = observer;
            }
        } else {
            // If the generation is stopped then stop observing the bodyRef
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        }
    }, [isGeneratingText]);

    const onBodyScroll = () => {
        // Stop if the body element is nothing
        const bodyElement = bodyRef.current;
        if(!bodyElement) return;

        // If the scroll position is at the bottom then it's an automatic scroll
        const distanceFromBottom = bodyElement.scrollHeight - bodyElement.scrollTop - bodyElement.clientHeight;

        // If the distance from the bottom is bigger than 5 then it's not an automatic scroll
        // and we should stop observing
        if(distanceFromBottom > 5) {
            if(observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = undefined;
            }
        }
        // If the scroll position is at the bottom then don't add box shadow to the footer
        if (distanceFromBottom <= 1 && shouldAddBoxShadowToFooter) {
            setShouldAddBoxShadowToFooter(false);
        // If the scroll position is not at the bottom then add box shadow to the footer
        } else if (distanceFromBottom > 1 && shouldAddBoxShadowToFooter === false) {
            setShouldAddBoxShadowToFooter(true);
        }
    };

    const onClose = useCallback((): void => {
        // reset ask ai assistant
        resetAskAIAssistant();

        // set selected menu item to undefined
        setSelectedMenuItem(undefined);

        // set is open to false
        setIsOpen(false);

        // reset box shadow on footer
        setShouldAddBoxShadowToFooter(false);

        // disconnect observer
        if(observerRef.current) {
            observerRef.current.disconnect();
            observerRef.current = undefined;
        }

        // call parent prop callback
        onCloseCallback();
    }, [onCloseCallback, resetAskAIAssistant, setIsOpen, setSelectedMenuItem]);

    const onAcceptClickHandler = useCallback((currentAIGeneratedText: string): void => {
        // safety-checks
        if (!currentAIGeneratedText || !selectedMenuItem) {
            // stop execution, return
            return;
        }

        // insert AI generated text into editor
        insertAIGeneratedTextHandler(currentAIGeneratedText, selectedMenuItem);

        // close modal
        onClose();
    }, [insertAIGeneratedTextHandler, onClose, selectedMenuItem]);

    const onSelectedItemUpdateHandler = useCallback((newSelectedItem: AskAIAssistantMenuItemEnum): void => {
        // reset ask ai assistant
        resetAskAIAssistant();

        // set default input to empty string
        setDefaultInput("");

        // reset box shadow on footer
        setShouldAddBoxShadowToFooter(false);

        // set selected menu item to new selected item
        setSelectedMenuItem(newSelectedItem);
    }, [resetAskAIAssistant, setSelectedMenuItem]);

    const onSubmitHandler = useCallback((text: string, requirements?: TAskAIAssistantRequirement[]): void => {
        // safety-checks
        if (!selectedMenuItem) {
            // stop execution, return
            return;
        }

        // if selected menu item is table
        if (selectedMenuItem === AskAIAssistantMenuItemEnum.Table && requirements) {
            // aggregate requirements into text
            // trim each requirement, do not add empty (or null or undefined) requirements, join with comma and trim final text
            text = requirements.map(requirement => 
                requirement.text.trim())
                    .filter(requirement => requirement)
                    .join(", ")
                    .trim();

            // if text is empty
            if (!text) {
                // show error message
                ToastHelperSingleton
                    .showToast(ToastTypeEnum.Error, "Please insert at least one detail.");
                // stop execution, return
                return;
            }
        }

        // reset box shadow on footer
        setShouldAddBoxShadowToFooter(false);
        
        // request assistance
        requestAssistanceAsync(text, selectedMenuItem);
    }, [requestAssistanceAsync, selectedMenuItem]);

    const onClearHandler = useCallback((): void => {
        // set ai generated text to empty string
        setAIGeneratedText("");
        // reset box shadow on footer
        setShouldAddBoxShadowToFooter(false);
    }, [setAIGeneratedText]);

    return (
        <Modal
            isOpen={isOpen}
            extraClassNames={{ container: styles.askAIAssistantModal, header: styles.askAIAssistantModalHeader }}
            onClose={onClose}>
            <div className={styles.leftSection}>
                <AskAIAssistantModalMenu 
                    selectedItem={selectedMenuItem}
                    onSelectedItemUpdate={onSelectedItemUpdateHandler} />
            </div>
            <div className={styles.rightSection}>
                <div className={styles.body} ref={bodyRef} onScroll={onBodyScroll}>
                    {(objectIdEdited && objectTypeEdited) && (
                        <AskAIAssistantLineChats 
                            objectNameEdited={objectName}
                            objectIdEdited={objectIdEdited}
                            objectTypeEdited={objectTypeEdited}
                            selectedMenuItem={selectedMenuItem}
                            isGeneratingText={isGeneratingText}
                            defaultInput={defaultInput}
                            onSubmit={onSubmitHandler}
                            setSelectedMenuItem={setSelectedMenuItem} />
                    )}
                    {doShowLoadingIndicator && (
                        <div className={styles.loadingIndicatorContainer}>
                            <LoadingStatusIndicator size={40} status={1} />
                            {textToShowWhileGenerating && textToShowWhileGenerating.trim().length > 0 && <p>{textToShowWhileGenerating}</p>}
                        </div>
                    )}
                    {(doShowText) && (
                        <div className={styles.generatedContentContainer}>
                            <div className={styles.generatedContentOwner}>
                                <FontAwesomeIcon icon={faMessageBot} />
                                <span>IGOR</span>
                            </div>
                            {didStreamErrorHappen 
                                ?
                                    <div className={`${styles.errorMessage} ${styles.generatedContent}`}>
                                        <h3>Error</h3>
                                        <MarkdownItComponent
                                            source={aiGeneratedTextWithoutError}
                                        />
                                    </div>
                                :
                                    <MarkdownItComponent
                                        extraClassNames={{ container: styles.generatedContent }}
                                        source={aiGeneratedText}
                                    />
                            }
                        </div>
                    )}
                </div>
                {!didStreamErrorHappen && ((!isGeneratingText && !isAIGeneratedTextEmpty) || isGeneratingText) ? 
                    <div className={`${styles.footer} ${shouldAddBoxShadowToFooter ? styles.addBoxShadow : ""}`}>
                        {(!isGeneratingText && !isAIGeneratedTextEmpty) && (
                            <>
                                <FindestButton title="Insert" onClick={() => { onAcceptClickHandler(aiGeneratedText); }} extraClassName={styles.acceptButton} />
                                <FindestButton title="Clear" buttonType="secondary" onClick={onClearHandler} />
                            </>
                        )}
                        {isGeneratingText && <FindestButton buttonType="secondary" title="Cancel" onClick={onCancel} extraClassName={styles.cancelButton} />}
                    </div>
                : null}
            </div>
        </Modal>
    );
};