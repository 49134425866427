// Node modules
import axios from "axios";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
import { IQueryDTO } from "Interfaces";


export class ObjectQueryController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api`;

    public async getConnectedQueriesAsync(objectId: string, objectType: ObjectTypeEnum): Promise<IQueryDTO[]> {
        try {
            let objectPath = "";
            if(objectType === ObjectTypeEnum.Study) {
                objectPath = "study";
            } else if(objectType === ObjectTypeEnum.Entity) {
                objectPath = "entity";
            } else {
                return [];
            }
            const response = await axios.get<IQueryDTO[]>(`${this._resourcePath}/${objectPath}/${objectId}/queries`);

            if(response && response.data) {
                return response.data;
            } else {
                return [];
            }

        } catch {
            return [];
        }
    }
}

export const ObjectQueryControllerSingleton = new ObjectQueryController();