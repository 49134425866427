// Enums
import { OwnershipEnum } from "Enums";
// Types
import { TOption, TOptions } from "Types";

export class OwnershipHelper {
    private ownershipStringToEnumMapping: { [name: string]: OwnershipEnum } = {
        "me": OwnershipEnum.Me
    };

    public getOwnershipDisplayName(ownership: OwnershipEnum): string {
        switch (ownership) {
            case OwnershipEnum.Me:
                return "Me";
            default:
                return "";
        }
    }

    public getOwnershipCustomTitle(ownership: OwnershipEnum): string {
        switch (ownership) {
            case OwnershipEnum.Me:
                return "Started by me";
            default:
                return "";
        }
    }

    private getOwnershipNamePairs(): TOption<OwnershipEnum>[] {
        return Object
            .keys(OwnershipEnum)
            .map((key: string) => ({
                value: this.ownershipStringToEnum(key),
                title: key,
                customTitle: this.getOwnershipCustomTitle(this.ownershipStringToEnum(key)),
            }));
    }

    private ownershipStringToEnum(ownership: string): OwnershipEnum {
        return this.ownershipStringToEnumMapping[ownership.toLowerCase()];
    }

    public ownershipFilterDropdownOptions: TOptions<OwnershipEnum>[] = [
        {
            group: "Started by",
            options: this.getOwnershipNamePairs()
        }
    ];
}

export const OwnershipHelperSingleton = new OwnershipHelper();