// node_modules
import { FC, useCallback, useRef } from "react";
// Styles
import styles from "./maturityLevelInputs.module.scss";
// Constants
import { MaturityRadarConstants } from "Constants";

// Component props type
type TMaturityLevelInputsProps = {
    min: number,
    max: number,
    onChange: (newMin: number, newMax: number) => void
};

export const MaturityLevelInputs: FC<TMaturityLevelInputsProps> = ({min, max, onChange}: TMaturityLevelInputsProps) => {
    // Ref
    const minInputRef = useRef<HTMLInputElement>(null);
    const maxInputRef = useRef<HTMLInputElement>(null);

    // Logic
    const onMinInput = useCallback(() => {
        // safety-checks
        if (!minInputRef.current || !maxInputRef.current) {
            // stop execution, return
            return;
        }

        // get min and max input values
        const minInputValue = parseInt(minInputRef.current.value);
        const maxInputValue = parseInt(maxInputRef.current.value);

        // if min is bigger than max - step, set max to min + step
        if (minInputValue > maxInputValue - MaturityRadarConstants.MATURITY_LEVEL_STEP) {
            maxInputRef.current.value = `${minInputValue + MaturityRadarConstants.MATURITY_LEVEL_STEP}`;

            // if max is equal to max input max, set min to max - step
            if (maxInputValue == parseInt(maxInputRef.current.max)) {
                minInputRef.current.value = `${parseInt(maxInputRef.current.max) - MaturityRadarConstants.MATURITY_LEVEL_STEP}`;
            }
        }

        // call on change with min and max input ref current values
        onChange(parseInt(minInputRef.current.value), parseInt(maxInputRef.current.value));
    }, [onChange]);

    const onMaxInput = useCallback(() => {
        // safety-checks
        if (!minInputRef.current || !maxInputRef.current) {
            // stop execution, return
            return;
        }

        // get min and max input values
        const minInputValue = parseInt(minInputRef.current.value);
        const maxInputValue = parseInt(maxInputRef.current.value);

        // if max is smaller than min + step, set min to max - step
        if (maxInputValue < minInputValue + MaturityRadarConstants.MATURITY_LEVEL_STEP) {
            minInputRef.current.value = `${maxInputValue - MaturityRadarConstants.MATURITY_LEVEL_STEP}`;

            // if min is equal to min input min, set max to default max
            if (minInputValue == parseInt(minInputRef.current.min)) {
                maxInputRef.current.value = `${MaturityRadarConstants.DEFAULT_MAX_MATURITY_LEVEL}`;
            }
        }

        // call on change with min and max input ref current values
        onChange(parseInt(minInputRef.current.value), parseInt(maxInputRef.current.value));
    }, [onChange]);

    // Render
    return (
        <div className={styles.inputsContainer}>
            <input
                ref={minInputRef}
                type="range"
                min={`${MaturityRadarConstants.MIN_MATURITY_LEVEL}`}
                max={`${MaturityRadarConstants.MAX_MATURITY_LEVEL}`}
                step={`${MaturityRadarConstants.MATURITY_LEVEL_STEP}`}
                value={min}
                className={styles.minInput}
                onInput={onMinInput} />
            <input
                ref={maxInputRef}
                type="range"
                min={`${MaturityRadarConstants.MIN_MATURITY_LEVEL}`}
                max={`${MaturityRadarConstants.MAX_MATURITY_LEVEL}`}
                step={`${MaturityRadarConstants.MATURITY_LEVEL_STEP}`}
                value={max}
                className={styles.maxInput}
                onInput={onMaxInput} />
        </div>
    );
};