// node_modules
import { FC, useState, useRef, MouseEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import { ListItemLink, Popover, Tooltip } from "Components";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Custom hooks
import { useObjectReferenceModal, useClickOutsideRef } from "Hooks";
// Enum
import { ObjectTypeEnum } from "Enums";
// Styles
import styles from "../../Topbar/topbar.module.scss";
// Constants
import { GeneralConstants } from "Constants";

// Component props type
type  TTopbarDropdownButtonProps = {
    buttonIcon: IconProp,
    items: TIdNameTypeObjectType[],
    emptyItemsMessage: string,
    tooltipText: string,
    onClickCallback?: () => void,
    displaySubItemType: (item: TIdNameTypeObjectType) => (string | undefined)
}

// Component
export const TopbarDropdownButton: FC<TTopbarDropdownButtonProps> = ({ buttonIcon, items, emptyItemsMessage, tooltipText, onClickCallback, displaySubItemType }: TTopbarDropdownButtonProps) => {
    // State
    const [arePinnedItemsShown, setArePinnedItemsShown] = useState<boolean>(false);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [isPinnedTooltipOpen, setIsPinnedTooltipOpen] = useState<boolean>(false);

    // Ref
    const pinnedContainerElementRef = useRef<HTMLDivElement>(null);

    // Custom Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();
    useClickOutsideRef(pinnedContainerElementRef, () => { hidePinnedItems(); }, [], GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER);

    /** Open related object reference modal  */
    const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
        setReferenceModalProps({
            id: objectId,
            type: objectType,
            isOpen: true,
            doIgnoreIsDeleted: false,
        });
    };

    // OnClick the pinned button will show the pinned item popover and disables the pinned tooltip when onHover
    const onPinnedButtonClickHandler = (callback?: () => void): void => {
        if (arePinnedItemsShown) {
            setArePinnedItemsShown(false);
         } else {
            setArePinnedItemsShown(true);
            setIsPinnedTooltipOpen(false);

            // if callback is passed
            if (callback) {
                // call it
                callback();
            }
        }
    };


    const onMouseEnterHandler = (): void => {
        // Check if the pinned item popover is show otherwise don't show the tooltip onHover
        if (!arePinnedItemsShown) {
            setIsPinnedTooltipOpen(true);
        }
    };

    const hidePinnedItems = (mouseEvent?: MouseEvent): void => {
        // if a mouse event is passed, stop the propagation
        // because we do not want to call on pinned button click handler when calling this method from ListItemLink
        if (mouseEvent) {
            mouseEvent.stopPropagation();
        }

        // hide pinned items
        setArePinnedItemsShown(false);
        // hide pinned tooltip
        setIsPinnedTooltipOpen(false);
    };

    // Render
    return (
        <div ref={pinnedContainerElementRef}>
            <div 
                className={`${styles.navigationButton} ${arePinnedItemsShown ? styles.active : ""}`}
                ref={setReferenceElement}
                onClick={() => { onPinnedButtonClickHandler(onClickCallback); }} 
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={() => setIsPinnedTooltipOpen(false)} >
                <FontAwesomeIcon icon={buttonIcon} />
            </div>
            {(arePinnedItemsShown) && (
                <Popover
                    extraClassName={`${items.length > 0 ? styles.navigationPopoverContainer : null}`}
                    referenceEl={referenceElement}
                    placement="bottom-start">
                    {items.length > 0 ? (
                        <ul> 
                            {items.map((item: TIdNameTypeObjectType) => (
                                <ListItemLink
                                    key={item.id}
                                    onClick={hidePinnedItems}
                                    navigateTo={`/library/${ObjectTypeHelperSingleton.getObjectTypeReactRouteName(item.objectType)}/${item.id}`}
                                    iconHasColor={true}
                                    icon={ObjectTypeHelperSingleton.getObjectTypeIcon(item.objectType)}
                                    object={item}
                                    subItemType={displaySubItemType(item)}
                                    openReferenceModal={openReferenceModal}
                                    moreActionsDropdownPopoverDataIdentifier={GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
                                    moreActionsDropdownOnCloseCallback={() => { hidePinnedItems(); }}
                                />
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.emptyNavigationPopoverContainer}>
                            <p>{emptyItemsMessage}</p>
                        </div>
                    )}
                </Popover>
            )}
            <Tooltip
                referenceEl={referenceElement}
                isOpen={isPinnedTooltipOpen}
                tooltipText={tooltipText}
            />
            {referenceModal}
        </div>
    );
};