// node_moduels
import * as history from "prosemirror-history";
import * as keymap from "prosemirror-keymap";
import { EditorState, Plugin, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { NavigateFunction } from "react-router-dom";
// Components
import { findestKeymaps } from "Components";
// Helpers
import { hasInlineStarsPlugin, intakeSheetConfirmationButtonsPlugin, loadingIndicatorPlugin, openLinkPlugin, openInlineReferencePlugin, pastePlugin, placeholderPlugin, ratingsPopoverPlugin, referencePopoverPlugin, selectedNodeReferencePlugin, tableColumnResizingPlugin } from "Helpers";
// Enums
import { ObjectTypeEnum } from "Enums";
// Constants
import { FeatureToggleConstants } from "Constants";
// Types
import { TAuth } from "Types";

export class ProseMirrorPluginHelper {
    public getDefault(
            auth: TAuth,
            placeholder: string, 
            updateEditorView?: (editorViewToUpdate: EditorView, transaction: Transaction, doCallOnSourceChangeCallback: boolean, callbackBeforeSetState?: (newState: EditorState) => void) => void,
            userEmail?: string,
            studyId?: string,
            showReferencePopover?: (id: string, type: ObjectTypeEnum, referenceElement: HTMLElement) => void,
            hideReferencePopover?: () => void,
            showRatingsPopover?: (forTargetId: string, meterElement: HTMLElement) => void,
            hideRatingsPopover?: () => void,
            navigate?: NavigateFunction) {
        // build plugins array
        const plugins: Plugin[]  = [
            history.history(), 
            keymap.keymap(findestKeymaps),
            placeholderPlugin(placeholder),
            pastePlugin(updateEditorView),
            selectedNodeReferencePlugin(),
            tableColumnResizingPlugin(updateEditorView),
            intakeSheetConfirmationButtonsPlugin(studyId, userEmail, updateEditorView),
            loadingIndicatorPlugin(),
            openLinkPlugin(),
            ratingsPopoverPlugin(showRatingsPopover, hideRatingsPopover),
            hasInlineStarsPlugin()
        ];

        if (navigate) {
            plugins.push(openInlineReferencePlugin(navigate, auth));
        }

        // if FeatureToggleConstants.ReferenceModal is true
        if (FeatureToggleConstants.ReferenceModal) {
            // add reference modal plugin
            plugins.push(referencePopoverPlugin(auth, showReferencePopover, hideReferencePopover));
        }

        // return plugins
        return plugins;
    }
}

export const ProseMirrorPluginHelperSingleton = new ProseMirrorPluginHelper();