// node_modules
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { FindestButton, FindestTextBox, IsFindestAdministrator, LoadingStatusIndicator } from "Components";
// Controllers
import { TenantControllerSingleton } from "Controllers";
// Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Custom hooks
import { useFetch } from "Hooks";
// Types
import { TTenantDTO, TUseFetch } from "Types";
// Styles
import styles from "./tenants.module.scss";

export const Tenants: FC = () => {
    // Custom hooks
    const navigate = useNavigate();

    // Custom hooks
    const { fetchedData: fetchedTenants }: TUseFetch<TTenantDTO[]> = useFetch("api/tenant");

    // State
    const [tenantName, setTenantName] = useState("");
    const [tenants, setTenants] = useState<TTenantDTO[]>([]);
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if(fetchedTenants) {
            setTenants(fetchedTenants);
        } else {
            setTenants([]);
        }
    }, [fetchedTenants]);

    const gotoTenantDetails = (tenantId: string) => {
        navigate(`/admin/tenants/${tenantId}`);
    };

    const createTenant = async () => {
        // set is creating to true
        setIsCreating(true);

        const createdTenant = await TenantControllerSingleton.createAsync(tenantName);

        // set is creating to false
        setIsCreating(false);
        
        if(!createdTenant) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not create tenant.");
            return;
        }

        // show success toast
        ToastHelperSingleton.showToast(ToastTypeEnum.Success, "Tenant created successfully.");

        setTenants([...tenants, createdTenant]);
        setTenantName("");
    };

    return (
        <IsFindestAdministrator>
            <div className={styles.tenants}>
                <div onClick={() => { navigate("/admin"); }}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                <div className={styles.tenantsTitleHeader}>
                    <h1>Tenants</h1>
                    <div className={styles.tenantCreation}>
                        <FindestTextBox placeholder="Tenant name" onChange={setTenantName} value={tenantName} />
                        <FindestButton isDisabled={isCreating} title="Create" onClick={createTenant} />
                        {(isCreating) && (
                            <div>
                                <LoadingStatusIndicator size={30} status={1} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.tenantsTableContainer}>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Last migration</th>
                                <th>New migrations available?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tenants.map((tenant: TTenantDTO) => {
                                return (
                                    <tr key={tenant.id} onClick={() => { gotoTenantDetails(tenant.id); }}>
                                        <td>{tenant.name}</td>
                                        <td>{tenant.lastMigration}</td>
                                        <td>{tenant.areMigrationsAvailable ? "Yes" : "No"}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </IsFindestAdministrator>
    );
};