/* eslint-disable react/no-danger */
// node_modules
import { FC, useEffect, useMemo, useRef, useState } from "react";
// Helpers
import { MarkdownItHelperSingleton } from "Helpers";
// Styles
import markdownStyles from "Styles/markdown.module.scss";

type TMarkdownItComponent = {
    source: string,
    noSourcePlaceholder?: string,
    onDoubleClickHandler?: () => void,
    contentMutationCallback?: (mutations: MutationRecord[]) => void,
    extraClassNames?: { container: string }
}

export const MarkdownItComponent: FC<TMarkdownItComponent> = ({ source, noSourcePlaceholder, onDoubleClickHandler, contentMutationCallback, extraClassNames }: TMarkdownItComponent) => {
    // State
    const initialDangerousHtml = useMemo(() => MarkdownItHelperSingleton.render(source), [source]);
    const [dangerousHtml, setDangerousHtml] = useState(initialDangerousHtml);
    const [mutationObserver, setMutationObserver] = useState<MutationObserver | undefined>(undefined);
    
    // Refs
    const contentContainerElementRef = useRef<HTMLDivElement>(null);

    // Effects
    useEffect(() => {
        // if contentMutationCallback or contentContainerElementRef is undefined
        if (!contentMutationCallback || !contentContainerElementRef.current) {
            // then return, do nothing
            return;
        }
        
        // create new MutationObserver
        const newMutationObserver = new MutationObserver(contentMutationCallback);

        // observe the content container element
        newMutationObserver.observe(contentContainerElementRef.current, {
            childList: true
        });

        // set the mutation observer
        setMutationObserver(newMutationObserver);
    }, [contentMutationCallback]);

    useEffect(() => {
        // if mutationObserver is undefined
        if (!mutationObserver) {
            // then return, do nothing
            return;
        }

        // return a cleanup function
        return () => {
            // disconnect the observer
            mutationObserver.disconnect();
        };
    }, [mutationObserver]);

    // Logic
    useEffect(() => {
        setDangerousHtml(MarkdownItHelperSingleton.render(source));
    }, [source]);
    
    // Render
    return(
        <div className={extraClassNames?.container ?? ""}>
            {source ? 
            
                    <div ref={contentContainerElementRef}
                        dangerouslySetInnerHTML={{__html: dangerousHtml}} 
                        onDoubleClick={onDoubleClickHandler}
                        className={markdownStyles.markdownContainer}>
                    </div>
                :
                    <div 
                        onDoubleClick={onDoubleClickHandler}
                        className={markdownStyles.markdownContainer}>
                            <p style={{color: "#CCCCCC"}}>{noSourcePlaceholder ? noSourcePlaceholder : ""}</p>
                    </div>
            }
        </div>
    );
};