// node_modules
import { FC } from "react";
// Components
import { FindestButton } from "Components";
// Styles
import styles from "./addableSynonym.module.scss";

type TAddableSynonymProps = {
    synonymValue: string,
    isAdded: boolean,
    addSynonymAsync: (synonymText: string) => Promise<void>
}

export const AddableSynonym: FC<TAddableSynonymProps> = ({ synonymValue, addSynonymAsync, isAdded }: TAddableSynonymProps) => {
    // Render
    return (
        <div className={styles.addableSynonym} title="Add as synonym" onClick={async () => { await addSynonymAsync(synonymValue); }}>
            <FindestButton extraClassName={styles.addSynonymButton} title={isAdded ? "Added" : "Add"}/>
            <span>{synonymValue}</span>
        </div>
    );
};