// Helpers
import { ProseMirrorHelperSingleton } from "Helpers";

// Define custom element "reference-link"
export class ReferenceLink extends HTMLElement {
    constructor() {
        super();
        this.addEventListener("click", ProseMirrorHelperSingleton.handleClickEvent);
    }
}
