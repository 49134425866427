// node_modules
import { FC } from "react";
import { faLightbulb, faCircleCheck, faTriangleExclamation, faCircleExclamation, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Styles
import styles from "./informationBox.module.scss";

type TInformationBoxProps = {
    title: string,
    subTitle?: string,
    type: "information" | "success" | "warning" | "error"
}

export const InformationBox: FC<TInformationBoxProps> = ({ title, subTitle, type }: TInformationBoxProps) => {

    const boxTypeIconMapping: { [type: string]: IconDefinition } = {
        "information": faLightbulb,
        "success": faCircleCheck,
        "warning": faTriangleExclamation,
        "error": faCircleExclamation
    };

    // Render
    return (
        <div className={`${styles.informationBox} ${styles[type]}`}>
            <FontAwesomeIcon className={styles.leftIcon} icon={boxTypeIconMapping[type]}/>
            <div className={styles.textContainer}>
                <div className={styles.title}>{title}</div>
                <div className={styles.actionContainer}>
                    {subTitle && <div>{subTitle}</div>}
                </div>
            </div>
        </div>
    );
};