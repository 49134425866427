// Enums
import { QueryPrioritySelectionStyleEnum } from "Enums";
// Helpers
import { LocalStorageHelperSingleton } from "Helpers";

export class QueryPrioritySelectionStyleHelper {
    private readonly storageKey = "QueryPrioritySelectionStyleStored";

    public getActivatedSelectionStyle(): QueryPrioritySelectionStyleEnum {
        // get value from local storage
        const value = LocalStorageHelperSingleton.getItem<QueryPrioritySelectionStyleEnum>(this.storageKey);

        // if value not defined, return default selection style to dropdown
        return value ?? QueryPrioritySelectionStyleEnum.PriorityDropdown;
    }

    public saveActivatedSelectionStyle(value: QueryPrioritySelectionStyleEnum): void {
        // set value in local storage
        LocalStorageHelperSingleton.setItem<QueryPrioritySelectionStyleEnum>(value, this.storageKey);
    }
}

export const QueryPrioritySelectionStyleHelperSingleton = new QueryPrioritySelectionStyleHelper();