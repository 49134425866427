// node_modules
import axios from "axios";
// Types
import { TSpecificationUnitDTO } from "Types";
// Helpers
import { AxiosHelperSingleton } from "Helpers";

export class SpecificationUnitController {

    public async createAsync(queryId: string, specificationUnitToCreate: TSpecificationUnitDTO): Promise<TSpecificationUnitDTO | undefined> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/specificationunits`;

            // make request
            const response = await axios.post<TSpecificationUnitDTO>(url, specificationUnitToCreate);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async updateAsync(queryId: string, specificationUnit: TSpecificationUnitDTO): Promise<boolean> {
        try {
        // build url
        const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/specificationunits/${specificationUnit.id}`;

        // make request
        const response = await axios.put<boolean>(url, specificationUnit);

            // deal with response
            if (response) {
                return response.data;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async deleteAsync(queryId: string, specificationUnitId: number): Promise<boolean> {
        try {
            // build url
            const url = `${AxiosHelperSingleton.getServerBaseURL()}api/query/${queryId}/specificationunits/${specificationUnitId}`;

            // make request
            const response = await axios.delete<void>(url);

            // deal with response
            if (response && response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }
}

export const SpecificationUnitControllerSingleton = new SpecificationUnitController(); 