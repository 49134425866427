// node_modules
import { FC, useContext, useMemo } from "react";
// Components
import { ObjectSearchPopupContent } from "Components";
// Enums
import { LinksWindowTabsEnum } from "Enums";
// Helpers
import { LinksWindowTabsHelperSingleton } from "Helpers";
// Styles
import styles from "../linksWindow.module.scss";
// Types
import { TUseDragAndDrop } from "Types";
// Contexts
import { WindowingContext } from "Providers";

type TLinksWindowSearchBarProps = {
    linksWindowSelectedTab: LinksWindowTabsEnum,
    useDragAndDropProps: TUseDragAndDrop,
    isLinksWindowSearchBarResultsElementActive?: boolean,
    setIsLinksWindowSearchBarResultsElementActive?: (isLinksWindowSearchBarResultsElementActive: boolean) => void,
};

export const LinksWindowSearchBar: FC<TLinksWindowSearchBarProps> = ({ linksWindowSelectedTab, useDragAndDropProps, 
    isLinksWindowSearchBarResultsElementActive, setIsLinksWindowSearchBarResultsElementActive,
}: TLinksWindowSearchBarProps) => {
    // Contexts
    const { minimizeAllWindows } = useContext(WindowingContext);

    // Memo
    // get search input placeholder depending on links window selected tab
    const searchInputPlaceholder = useMemo((): string => {
        // return new search input placeholder
        return `Search for objects to add to the ${LinksWindowTabsHelperSingleton.getLinksWindowTabsDisplayValue(linksWindowSelectedTab)}`;
    }, [linksWindowSelectedTab]);

    return (
        <div className={styles.linksWindowSearchbar}>
            <ObjectSearchPopupContent 
                searchInputPlaceholder={searchInputPlaceholder}
                extraClassNames={{ objectSearchMainElement: styles.objectSearchMainElement, objectSearchInputElement: styles.objectSearchInputElement, searchPopupContentResults: styles.searchPopupContentResults }} 
                dontInputAutoFocus={true} 
                useDragAndDropProps={useDragAndDropProps} 
                doShowResultsContainerOnBlur={true} 
                isObjectSearchResultsElementActive={isLinksWindowSearchBarResultsElementActive}
                setIsObjectResultsElementActive={setIsLinksWindowSearchBarResultsElementActive} 
                isLinksWindowSearchBarResultItem={true} 
                minimizeAllWindows={minimizeAllWindows}
            />
        </div>
    );
};