// node_modules
import axios, { AxiosError } from "axios";
// Enums
import { RolesEnum, UniverseRolesEnum, WebRequestStatusEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TAxiosParams, TClaimTypeDTO, TClaimValueDTO, TCreateUserDTO, TUserClaimDTO, TUserDTO, TUsersDTO } from "Types";

export class UserController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/user`;

    public async createUserAsync(email: string, role: UniverseRolesEnum, permissions: TClaimValueDTO[]): Promise<WebRequestStatusEnum> {
        try {
            const response = await axios.post<TUserDTO[]>(`${this._resourcePath}`, {
                email,
                role,
                permissions
            } as TCreateUserDTO);

            if (response) {
                return WebRequestStatusEnum.Success;
            } else {
                return WebRequestStatusEnum.Failed;
            }
        } catch(error: unknown) {
            // show error message
            AxiosHelperSingleton
                .displayAxiosError(error, "Failed to create user.");

            if(error instanceof AxiosError && error.response) {
                if(error.response.status === 409) {
                    return WebRequestStatusEnum.AlreadyExists;
                }
            }

            return WebRequestStatusEnum.Failed;
        }
    }

    public async getAllUsersAsync(query?: string): Promise<TUserDTO[]> {
        try { 
            // init axios params
            const axiosParams: TAxiosParams = {};

            // if query is provided, add it to params
            if(query) {
                axiosParams.query = query;
            }

            // get all users
            const response = await axios.get<TUserDTO[]>(`${this._resourcePath}/all`, { params: axiosParams });

            // deal with response
            if (response) {
                // return users
                return response.data;
            } else {
                // otherwise, return empty array
                return [];
            }
        } catch {
            // otherwise, return empty array
            return [];
        }
    }
    
    public async addClaimAsync(userId: string, type: TClaimTypeDTO, value: TClaimValueDTO): Promise<TUserClaimDTO | undefined> {
        try {
            // create user claim to ad
            const userClaimToAdd: TUserClaimDTO = {
                type: type,
                value: value
            };

            // call api
            const response = await axios.put<TUserClaimDTO | undefined>(
                `${this._resourcePath}/${userId}/add-claim`, 
                userClaimToAdd
            );

            // deal with response
            if(response.data) {
                return response.data;
            } else {
                // otherwise, return undefined
                return undefined;
            }
        } catch {
            // otherwise, return undefined
            return undefined;
        }
    }

    public async deleteClaimAsync(userId: string, type: TClaimTypeDTO, value: TClaimValueDTO): Promise<boolean> {
        try {
            // create user claim to delete
            const userClaimToDelete: TUserClaimDTO = {
                type: type,
                value: value
            };

            // call api
            const response = await axios.delete<boolean>(
                `${this._resourcePath}/${userId}/delete-claim`, 
                {
                    data: userClaimToDelete
                }
            );

            // deal with response
            if(response) {
                return response.data;
            } else {
                // otherwise, return false
                return false;
            }
        } catch {
            // otherwise, return false
            return false;
        }
    }

    public async deleteUserAsync(userId: string): Promise<boolean> {
        try {
            const response = await axios.delete(`${this._resourcePath}/${userId}`);

            return !!response;

        } catch(error: unknown) {
            // show error message
            AxiosHelperSingleton
                .displayAxiosError(error, "Failed to delete user.");

            return false;
        }
    }

    public async removeRoleAsync(userId: string, roleName: string): Promise<boolean> {
        try {
            const response = await axios.post<RolesEnum[]>(`${this._resourcePath}/${userId}/remove-role`, {
                role: roleName
            });

            return !!response;
        } catch {
            return false;
        }
    }

    public async addRoleAsync(userId: string, roleName: string): Promise<boolean> {
        try {
            const response = await axios.post<RolesEnum[]>(`${this._resourcePath}/${userId}/add-role`, {
                role: roleName
            });

            return !!response;
        } catch {
            return false;
        }
    }

    public async sendMagicLinkToFindestAsync(userId: string): Promise<boolean> {
        try {
            const response = await axios.post(`${this._resourcePath}/${userId}/sendmagictofindest`);

            if (response) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async getUsersAsync(): Promise<TUserDTO[]> {
        try {
            const response = await axios.get<TUsersDTO>(`${this._resourcePath}`);
            if(response?.data) {
                return response.data.users;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }    
}

export const UserControllerSingleton = new UserController();