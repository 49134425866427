// Axios
import axios from "axios";
// Enums
import { ObjectTypeEnum, SharedToSettingsEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TAxiosParams, TIdNameTypeObjectType, TLoginResponseDTO, TSharedPageDTO, TSharedToUserDTO, TShareToUserDTO, TShareToUsersResultDTO, TUnshareDTO, TUserDTO, TUserWithSharedPagesDTO, TValidatedSharedToDTO } from "Types";

export class SharedToController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/share`;

    public async getAllSharedObjectsAsync(fromDate: Date | undefined): Promise<TSharedPageDTO[]> {
        try {
            const axiosParams: TAxiosParams = {};
            // put createdByMe in url if it is defined
            if (fromDate !== undefined) {
                axiosParams.fromDate = fromDate;
            }

            const response = await axios.get<TSharedPageDTO[]>(`${this._resourcePath}`, {
                params: axiosParams
            });

            if (response) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getUserWithSharedObjectsAsync(): Promise<TUserWithSharedPagesDTO[]> {
        try {
            const response = await axios.get<TUserWithSharedPagesDTO[]>(`${this._resourcePath}`);

            if (response) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getAmountOfSharedObjectsAsync(): Promise<number | undefined> {
        try {
            const response = await axios.get<{ count: number }>(`${this._resourcePath}/objects/count`);

            if(response) {
                return response.data.count;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getAmountOfUsersWithAllSharedObjectsAsync(): Promise<number | undefined> {
        try {
            const response = await axios.get<{ count: number }>(`${this._resourcePath}/users/count`);

            if(response) {
                return response.data.count;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getSharedWithMeAsync(objectId: string): Promise<TValidatedSharedToDTO> {
        // init default validated shared to dto
        const validatedSharedToDTO: TValidatedSharedToDTO = {
            isShared: false,
            settings: undefined
        };
        
        try {
            const response = await axios.get<TValidatedSharedToDTO>(`${this._resourcePath}/sharedto/me/${objectId}`);

            if (response) {
                return response.data;
            } else {
                return validatedSharedToDTO;
            }
        } catch {
            return validatedSharedToDTO;
        }
    }

    public async validateShare(email: string, objectId: string): Promise<TValidatedSharedToDTO> {
        // init default validated shared to dto
        const validatedSharedToDTO: TValidatedSharedToDTO = {
            isShared: false,
            settings: undefined
        };

        try{
            const result = await axios.post<TValidatedSharedToDTO>(`${this._resourcePath}/validate`, {
                email,
                objectId
            });
            
            // return the result
            if (result && result.data) {
                return result.data;
            } else {
                return validatedSharedToDTO;
            }
        } catch {
            return validatedSharedToDTO;
        }  
    }

    public async valideSixDigitCode(email: string, code: string): Promise<TLoginResponseDTO | undefined> {
        try{
            const response = await axios.post<TLoginResponseDTO>(`${this._resourcePath}/sixdigitcode`, {
                email,
                objectId: code
            });

            if (response && response.data) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }  
    }

    public async getSharedToUsersAsync(objectId: string): Promise<TSharedToUserDTO[]> {
        try{
            const response = await axios.get<TSharedToUserDTO[]>(`${this._resourcePath}/sharedto/${objectId}`);
            
            if (response) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async shareToUsersAsync(objectId: string, objectType: ObjectTypeEnum, 
            emails: string[], message: string | null = null, settings: SharedToSettingsEnum): Promise<TShareToUsersResultDTO | undefined> {
        try {
            const shareToUser: TShareToUserDTO = { objectId, objectType, emails, message, settings};
            const response = await axios.post<TShareToUsersResultDTO>(`${this._resourcePath}`, shareToUser);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }   
    }

    public async unshareToUserAsync(objectId: string, userId: string): Promise<boolean> {
        try {
            const unshareDto: TUnshareDTO = { objectId, userId };
            await axios.post<boolean>(`${this._resourcePath}/delete`, unshareDto);

            return true;
        } catch {
            return false;
        }
    }

    public async updateSharedToSettingsAsync(objectId: string, settings: SharedToSettingsEnum): Promise<boolean> {
        try {
            // call server to update shared to settings
            await axios.put(`${this._resourcePath}/${objectId}/settings`, { settings });
            
            // update was successful, return true
            return true;
        } catch {
            // update failed, return false
            return false;
        }
    }

    public async searchUsersAsync(objectId: string, searchText: string): Promise<TUserDTO[]> {
        try {
            const response = await axios.get<TUserDTO[]>(`${this._resourcePath}/${objectId}/users`, {
                params: {
                    username: searchText
                }
            });

            if (response) {
                return response.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async anythingSharedWithMeAsync(): Promise<TIdNameTypeObjectType | undefined> {
        try {
            const response = await axios.get<TIdNameTypeObjectType>(`${this._resourcePath}/getany`);

            if (response) {
                return response.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }
}

export const SharedToControllerSingleton = new SharedToController();