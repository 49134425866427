export enum SearchQueryTypeEnum {
    ScienceArticles = 0,
    Patents = 1,
    Partners = 7,
    Technologies = 10,
    QuestionAnsweringSearch = 12,
    QuestionAnsweringInsideTechnology = 13,
    TopicModeling = 14,

    // Universe search types
    UniverseScienceArticles = 100,
    UniversePatents = 101,
}