export enum EntityTypeEnum {
    Undefined = 0,
    Technology = 1,
    Material = 2,
    Process = 3,
    Method = 4,
    Ingredient = 5,
    Chemical = 6,
    Product = 7,
    Partner = 8,
    Supplier = 9,
    Competitor = 10,
    Custom = 11,
    ConvertToStudy = 99,
}
