// node_modules
import { ReactNode, createContext, useContext, useMemo } from "react";
// Controllers
import { WebsocketController, WebsocketControllerSingleton } from "Controllers";
// Contexts
import { AuthContext } from "Providers";

type TWebsocketContext = {
    webSocketController: WebsocketController
}

export const WebsocketContext = createContext<TWebsocketContext>({
    webSocketController: WebsocketControllerSingleton
});

type TWebsocketProviderProps = {
    children?: ReactNode
};

export const WebsocketProvider = ({ children }: TWebsocketProviderProps) => {
    // Contexts
    const { webSocketController } = useContext(WebsocketContext);
    const { isUserConnected } = useContext(AuthContext);

    // Memo
    const memodWebsocketController = useMemo(() => {
        // if user is connected
        if (isUserConnected) {
            // build signalR connection
            webSocketController.buildSignalRConnection();
        }

        return { webSocketController };
    }, [isUserConnected, webSocketController]);

    // Render
    return (
        <WebsocketContext.Provider value={memodWebsocketController}>
            {children}
        </WebsocketContext.Provider>
    );
};