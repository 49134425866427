// React
import { FC } from "react";
// Font awesome
import { faUserGroup } from "@fortawesome/pro-regular-svg-icons";
// Controllers
import { FindestButton } from "Components";

type TShareButtonProps = {
    onClick: () => void
}

export const ShareButton: FC<TShareButtonProps> = ({onClick}: TShareButtonProps) => {
    return (
        <FindestButton
            title="Share"
            leftIconName={faUserGroup}
            onClick={onClick}
            buttonType="secondary"
        />
    );
};